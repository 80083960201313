import React from 'react';
import { HubConnectionBuilder } from '@microsoft/signalr';
import DataGrid, {
    RemoteOperations, Editing, ColumnChooser, GroupPanel, FilterPanel, FilterRow, HeaderFilter, Paging, Pager, Scrolling, Column, SearchPanel, KeyboardNavigation, Export, StateStoring, Sorting, Toolbar, Item
} from 'devextreme-react/data-grid';
import { confirm } from 'devextreme/ui/dialog';
import HxToast from '../../../../custom-components/hx-toast/HxToast';
import { Button } from 'devextreme-react/button';

import ServiceLocations from '../../../../../api/services/ServiceLocations'
import ServiceUsers from '../../../../../api/services/ServiceUsers'
import PopupShuttlesAdminLocationsEdit from './PopupShuttlesAdminLocationsEdit'

import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';

import './ShuttlesAdminLocationsDataGrid.scss'

// callables
//      applyUserSettings
//
// callbacks
//      onInitialized

class ShuttlesAdminLocationsDataGrid extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dataSourceLocations: [],
            locationId: -1,
            useAdvancedFiltering: false,
     }

        this.dataGridInstance = null;

        this.refPopupShuttlesAdminLocationsEdit = React.createRef();
        this.popupShuttlesAdminLocationsEditInstance = null;

        this.hubConnection = null;

        this.showSelectedCell = false;

        this.userSettings = {
            UserId: parseInt(sessionStorage.getItem("currentUserId"), 10),
            DefaultViewId: -1,
            DefaultFilterId: -1,
            ShowSelectedCell: false,
            UseAdvancedFiltering: false,
        }
    }

    // CALLBACKS
    onInitialized = (instance) => {
        if (this.props.onInitialized) {
            this.props.onInitialized(instance);  // callback
        }
    }

    // CALLABLES
    applyUserSettings = (elementId, value) => {
        switch (elementId) {
            case 'checkBoxShowSelectedCell':
                this.showSelectedCell = value;
                this.dataGridInstance.repaint();
                break;
            case 'checkBoxUseAdvancedFiltering':
                this.setState({ useAdvancedFiltering: value });
                this.dataGridInstance.repaint();
                break;
            default:
                break;
        }
    }

    // EVENTS
    componentDidMount = async () => {
        await this.loadDSLocations();
        await this.loadDSUserSettings();

        this.applyUserSettings('checkBoxShowSelectedCell', this.userSettings.ShowSelectedCell);
        this.applyUserSettings('checkBoxUseAdvancedFiltering', this.userSettings.UseAdvancedFiltering);

        this.hubConnection = new HubConnectionBuilder().withUrl("/locationsHub").build();
        await this.hubConnection.start()
            .then(function () {

            })
            .catch(function (err) {
                return console.error(err.toString());
            });
        this.hubConnection.on("ReceiveMessageAddedLocation", await this.updatePushedDataAddedLocation);
        this.hubConnection.on("ReceiveMessageDeletedLocation", await this.updatePushedDataDeletedLocation);
        
    }

    // DATA
    loadDSLocations = async () => {
        var data = await ServiceLocations.getAllLocationsByEventId(parseInt(sessionStorage.getItem('currentEventId'), 10));
        if (data !== null && !data.hasOwnProperty("error")) {
            this.setState({ dataSourceLocations: data });
        } else {
           // this.setErrorHeader(true);
        }
    }

    loadDSUserSettings = async () => {
        var settings = await ServiceUsers.getUserSettingsLocationsByUserId(parseInt(sessionStorage.getItem("currentUserId"), 10));
        settings = (settings !== null ? settings : this.userSettings);
        if (!settings.hasOwnProperty("error")) {
            this.userSettings = settings;
        } else {
            // this.setErrorHeader(true);
        }
    }

    // GRID
    gridOnCellClick = async (e) => {
        if (e.rowType === 'data') {
            if (e.column.name == 'ActionColumnEdit') {
                this.setState({ locationId: e.data.Id });
                this.popupShuttlesAdminLocationsEditInstance.show();
            } else if (e.column.name == 'ActionColumnDelete') {
                let result = await confirm('Are you sure you want to delete ' + e.data.Name + '?', "Confirm delete");
                if (result) {
                    var resultDelete = await ServiceLocations.deleteLocation(e.data.Id);
                    if (resultDelete === true) {
                        HxToast.showToast('Location deleted successfully', 'success', 'bottom right', 'up-stack');
                        this.hubConnection
                            .invoke("SendUpdateInfoDeletedLocation")
                            .catch(function (err) {
                                return console.error(err.toString());
                            });
                    } else {
                        HxToast.showToast('Location delete failed!', 'error', 'bottom right', 'up-stack');
                    }
                }
            }
        }
    }

    gridOnFocusedCellChanging = (e) => {
        if (this.showSelectedCell) {
            let elements = document.getElementsByClassName("hx-datagrid-cell-border");
            if (elements) {
                for (var i = 0; i < elements.length; i++) {
                    elements[i].classList.remove('hx-datagrid-cell-border');
                }
            }

            e.cellElement[0].classList.add('hx-datagrid-cell-border');
        }
    }

    gridOnInitialized = (e) => {
        // set instance
        this.dataGridInstance = e.component;

        // set focused row
        this.dataGridInstance.option('focusedRowIndex', 0);

        this.onInitialized(e.component);
   }

    // EXPORT TO EXCEL
    exportToExcel = async (name) => {

        const workbook = new Workbook();

        var sheet = workbook.addWorksheet("Locations");
        sheet.columns = [
            { width: 30 }, { width: 30 }, { width: 40 }, { width: 25 }, { width: 20 }
        ];
        sheet.pageSetup.margins = {
            left: 0.5, right: 0.5,
            top: 0.5, bottom: 0.5,
            header: 0, footer: 0
        };

        exportDataGrid({
            worksheet: sheet,
            component: this.dataGridInstance,
            customizeCell: this.customizeExcelCell,
            keepColumnWidths: false
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), name);
            });
        })
    };

    customizeExcelCell(options) {
        const { gridCell, excelCell } = options;

        if (gridCell.rowType === 'header' && gridCell.column.name !== 'DummyColumn') {
            excelCell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FF000000' }
            };
            excelCell.font = {
                color: { argb: 'FFFFFFFF' }
            }
        }
    }

    // ADD RELATION BUTTON
    buttonAddLocation_OnClick = () => {
        this.setState({ locationId: -1 });
        this.popupShuttlesAdminLocationsEditInstance.show();
    }

    // POPUP EDIT
    popupShuttlesAdminLocationsEdit_OnInitialized = (instance) => {
        this.popupShuttlesAdminLocationsEditInstance = instance;
    }

    popupShuttlesAdminLocationsEdit_OnHiding = async () => {
        await this.loadDSLocations();
    }

    popupShuttlesAdminLocationsEdit_OnUpdate = async (success) => {
        if (success) {
            HxToast.showToast(this.state.locationId === -1 ? 'Location added successfully' : 'Location updated successfully', 'success', 'bottom right', 'up-stack');
            this.hubConnection
                .invoke("SendUpdateInfoAddedLocation")
                .catch(function (err) {
                    return console.error(err.toString());
                });
        } else {
            HxToast.showToast(this.state.locationId === -1 ? 'Failed to add location' : 'Failed to update location', 'error', 'bottom right', 'up-stack');
        }
    }

    // HUB
    updatePushedDataAddedLocation = async () => {
        await this.loadDSLocations();
    }

    updatePushedDataDeletedLocation = async () => {
        await this.loadDSLocations();
    }

    // RENDERING
    renderEditTemplate = (e) => {
        return <div style={{ display: 'flex', justifyContent: 'start' }}>
            <div style={{ width: '40px' }}><i className='dx-icon-edit' style={{ color: '#FF5722' }} /></div>
        </div>;
    }

    renderDeleteTemplate = (e) => {
        return <div style={{ display: 'flex', justifyContent: 'start' }}>
            <div style={{ width: '40px' }}><i className='dx-icon-trash' style={{ color: '#FF5722' }} /></div>
        </div>;
    }

    render() {
        return (
            <React.Fragment>
                <div className='content-block locations-content-block'>
                    <div className='shuttles-admin-locations-datagrid-buttons'>
                        <span className='shuttles-admin-locations-datagrid-button'>
                            <Button
                                text="Add location"
                                icon="add"
                                onClick={this.buttonAddLocation_OnClick} />
                        </span>
                    </div>
                    <div className='dx-card shuttles-admin-locations-datagrid-grid-card'>
                        <DataGrid
                            dataSource={this.state.dataSourceLocations}
                            keyExpr='Id'
                            height='calc(100% - 1px)'
                            width='100%'
                            allowColumnReordering={false}
                            allowColumnResizing={true}
                            allowGrouping={false}
                            columnAutoWidth={true}
                            columnResizingMode='widget'
                            filterSyncEnabled={true}
                            focusedRowEnabled={false}
                            remoteOperations={true}
                            repaintChangesOnly={true}
                            showBorders={false}
                            showColumnLines={true}
                            showRowLines={true}
                            wordWrapEnabled={false}
                            noDataText='No relations found.'
                            onCellClick={this.gridOnCellClick}
                            onFocusedCellChanging={this.gridOnFocusedCellChanging}
                            onInitialized={this.gridOnInitialized}
                        >

                            <Sorting mode="multiple" />
                            <ColumnChooser enabled={false} />
                            <Paging enabled={false} defaultPageSize={20} />
                            <FilterPanel visible={this.state.useAdvancedFiltering} />
                            <FilterRow visible={true} />
                            <GroupPanel visible={false} />
                            <HeaderFilter visible={true} />
                            <SearchPanel visible={true} />
                            <Scrolling
                                mode="standard"
                                preloadEnabled={true}
                                scrollByContent={true}
                                scrollByThumb={true}
                                showScrollbar='always'
                                useNative='false'
                            />
                            <Column
                                dataField='Id'
                                dataType='number'
                                caption='Id'
                                allowFiltering={false}
                                allowEditing={false}
                                fixed={true}
                                showInColumnChooser={false}
                                visible={false}
                            />
                             <Column
                                dataField='ColorHex'
                                dataType='string'
                                caption='ColorHex'
                                allowFiltering={false}
                                allowEditing={false}
                                fixed={true}
                                showInColumnChooser={false}
                                visible={false}
                            />
                            <Column
                                dataField='ForeColorHex'
                                dataType='string'
                                caption='ForeColorHex'
                                allowFiltering={false}
                                allowEditing={false}
                                fixed={true}
                                showInColumnChooser={false}
                                visible={false}
                            />
                            <Column
                                dataField='PatternColorHex'
                                dataType='string'
                                caption='PatternColorHex'
                                allowFiltering={false}
                                allowEditing={false}
                                fixed={true}
                                showInColumnChooser={false}
                                visible={false}
                            />
                            <Column
                                dataField='UsePattern'
                                dataType='number'
                                caption='UsePattern'
                                allowFiltering={false}
                                allowEditing={false}
                                fixed={true}
                                showInColumnChooser={false}
                                visible={false}
                            />
                            <Column
                                dataField='PatternCssBackGround'
                                dataType='string'
                                caption='PatternCssBackGround'
                                allowFiltering={false}
                                allowEditing={false}
                                fixed={true}
                                showInColumnChooser={false}
                                visible={false}
                            />
                            <Column
                                dataField='PatternCssBackGroundImage'
                                dataType='string'
                                caption='PatternCssBackGroundImage'
                                allowFiltering={false}
                                allowEditing={false}
                                fixed={true}
                                showInColumnChooser={false}
                                visible={false}
                            />
                            <Column
                                dataField='PatternCssBackGroundSize'
                                dataType='string'
                                caption='PatternCssBackGroundSize'
                                allowFiltering={false}
                                allowEditing={false}
                                fixed={true}
                                showInColumnChooser={false}
                                visible={false}
                            />
                            <Column
                                dataField='PatternCssBackGroundPosition'
                                dataType='string'
                                caption='PatternCssBackGroundPosition'
                                allowFiltering={false}
                                allowEditing={false}
                                fixed={true}
                                showInColumnChooser={false}
                                visible={false}
                            />
                            <Column
                                caption=''
                                width='20px'
                                allowFiltering={false}
                                allowEditing={false}
                                allowColumnResizing={false}
                                allowColumnReordering={false}
                                allowExporting={false}
                                fixed={true}
                                showInColumnChooser={false}
                            />
                            <Column
                                name='ActionColumnEdit'
                                caption=''
                                allowFiltering={false}
                                allowEditing={false}
                                allowColumnResizing={false}
                                allowColumnReordering={false}
                                allowExporting={false}
                                fixed={false}
                                showInColumnChooser={false}
                                visible={true}
                                width='40px'
                                alignment='center'
                                cellRender={this.renderEditTemplate}
                            />
                            <Column
                                dataField='Name'
                                dataType='string'
                                caption='Name'
                                minWidth={175}
                                sortIndex={0}
                                sortOrder='Asc'
                                width='auto'
                                allowFiltering={true}
                                allowResizing={true}
                                allowEditing={false}
                                fixed={false}
                                showInColumnChooser={true}
                                visible={true}
                            />
                            <Column
                                dataField='Email'
                                dataType='string'
                                caption='Email'
                                minWidth={200}
                                width='auto'
                                allowFiltering={true}
                                allowResizing={true}
                                allowEditing={false}
                                fixed={false}
                                showInColumnChooser={true}
                                visible={true}
                            />
                            <Column
                                dataField='Tel'
                                dataType='string'
                                caption='Tel'
                                minWidth={150}
                                width='auto'
                                allowFiltering={true}
                                allowResizing={true}
                                allowEditing={false}
                                fixed={false}
                                showInColumnChooser={true}
                                visible={true}
                            />
                            <Column
                                dataField='LocationType'
                                dataType='string'
                                caption='Type'
                                minWidth={150}
                                width='auto'
                                allowFiltering={true}
                                allowResizing={true}
                                allowEditing={false}
                                fixed={false}
                                showInColumnChooser={true}
                                visible={true}
                            />
                            <Column
                                name='ActionColumnDelete'
                                caption=''
                                allowFiltering={false}
                                allowEditing={false}
                                allowColumnResizing={false}
                                allowColumnReordering={false}
                                allowExporting={false}
                                fixed={false}
                                showInColumnChooser={false}
                                visible={true}
                                width='40px'
                                alignment='center'
                                cellRender={this.renderDeleteTemplate}
                            />
                            <Column
                                name='DummyColumn'
                                caption=''
                                width='auto'
                            />
                        </DataGrid>
                    </div>
                </div>

                <PopupShuttlesAdminLocationsEdit
                    ref={this.refPopupShuttlesAdminLocationsEdit}
                    id={this.state.locationId}
                    onInitialized={this.popupShuttlesAdminLocationsEdit_OnInitialized}
                    onHiding={this.popupShuttlesAdminLocationsEdit_OnHiding}
                    onUpdate={this.popupShuttlesAdminLocationsEdit_OnUpdate}
                />
            </React.Fragment>
        );

    }

}

export default ShuttlesAdminLocationsDataGrid

