import React from 'react';
import { Shuttles } from '../../components';

class shuttles extends React.Component {
    render() {
        return (
            <Shuttles />
        );
    }
}

export default shuttles