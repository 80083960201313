import React from 'react';
import DataGrid, {
    RemoteOperations, MasterDetail, Editing, ColumnChooser, GroupPanel, FilterPanel, FilterRow, HeaderFilter, Paging, Pager, Scrolling, Column, Button, SearchPanel, KeyboardNavigation, Export, StateStoring, Sorting, Lookup, Selection
} from 'devextreme-react/data-grid';

import { HubConnectionBuilder } from '@microsoft/signalr';
import ServiceArtists from '../../../../../api/services/ServiceArtists';

import HxToast from '../../../../custom-components/hx-toast/HxToast';
import { Workbook } from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import saveAs from 'file-saver';

import './DataGridArtistsShuttles.scss'

// props
//      onInitialized: callback
//      onExportArtist: callback
//      onConnectionError: callback
//      onEditArtist: callback
//      onDeleteArtist: callback
//
//      setSelectedGigDateIds: callable
//      setSelectedArtistIds: callable
 
class DataGridArtistsShuttles extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dataSourceArtists: [],
            showFilter: false,
        };

        this.dataGridInstance = null;
        
        this.hubConnection = null;
         
        this.viewState = {};
    }

    // CALLBACKS
    onConnectionError = () => {
        if (this.props.onConnectionError) {
            this.props.onConnectionError()
        }
    }

    onExportArtist = () => {
        if (this.props.onExportArtist) {
            this.props.onExportArtist()
        }
    }

    onInitialized = (instance) => {
        if (this.props.onInitialized) {
            this.props.onInitialized(instance)
        }
    }

    onEditArtist = (id, name, notes) => {
        if (this.props.onEditArtist) {
            this.props.onEditArtist(id, name, notes)
        }
    }

    onDeleteArtist = (id, name) => {
        if (this.props.onDeleteArtist) {
            this.props.onDeleteArtist(id, name)
        }
    }

    // EVENTS
    componentDidMount = async () => {

        this.hubConnection = new HubConnectionBuilder().withUrl("/artistsHub").build();

        await this.hubConnection.start()
            .then(function () {

            })
            .catch(function (err) {
                return console.error(err.toString());
            });

        this.hubConnection.on("ReceiveMessageEditedArtistShuttles", await this.updatePushedDataEditedArtist);
        this.hubConnection.on("ReceiveMessageAddedArtistShuttles", await this.updatePushedDataAddedArtist);
        this.hubConnection.on("ReceiveMessageDeletedArtistShuttles", await this.updatePushedDataDeletedArtist);

        await this.loaddataSourceArtists();
    }

    // CALLABLES
    exportToExcel = (name) => {
        const fileName = name;
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Sheet 1');

        exportDataGrid({
            component: this.dataGridInstance,
            worksheet: worksheet
        }).then(function () {
            workbook.xlsx.writeBuffer()
                .then(function (buffer) {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), fileName);
                });
        });
    }

 
    // DATA
    loaddataSourceArtists = async () => {
        var data = await ServiceArtists.getArtistInfoByEventId(parseInt(sessionStorage.getItem("currentEventId"), 10), "shuttles");
        if (data !== null && !data.hasOwnProperty("error")) {
            this.setState({ dataSourceArtists: data })
        } else {
            this.onConnectionError();  // callback
        }
    }

    gridOnCellPrepared = (e) => {
        if (e.rowType === 'data') {
            e.cellElement.style.backgroundColor = '#FFFFFF';
            e.cellElement.style.color = '#000000';

        } else {
            if (e.rowType === 'filter') {
                e.cellElement.style.backgroundColor = '#FFFFFF';
                e.cellElement.style.color = '#000000';
            }
        }
    }

    gridOnFocusedCellChanging = (e) => {
        let elements = document.getElementsByClassName("hx-datagrid-cell-border");
        if (elements) {
            for (var i = 0; i < elements.length; i++) {
                elements[i].classList.remove('hx-datagrid-cell-border');
            }
        }

        e.cellElement[0].classList.add('hx-datagrid-cell-border');
    }

    gridOnCellClick = (e) => {
        if (e.rowType === 'data') {
            e.cellElement.classList.remove('dx-cell-focus-disabled');

            var found = false;
            for (var value of e.cellElement.classList) {
                if (value === 'hx-datagrid-cell-border') {
                    found = true;
                    break;
                }
            }
            if (!found) {
                e.cellElement.classList.add('hx-datagrid-cell-border');
            }
            if (e.column.name == 'ActionColumnEdit') {
                this.onEditArtist(e.data.Id, e.data.Name, e.data.Notes);
            } else if (e.column.name == 'ActionColumnDelete') {
                if (e.data.CanBeDeleted == true) {
                    this.onDeleteArtist(e.data.Id, e.data.Name);
                }
            }
        }
    }

    gridOnEditorPreparing = (e) => {
        if (e.parentType === 'dataRow') {
            if (e.editorName === "dxNumberBox") {
                e.editorOptions.step = 0;
                e.editorElement.parentElement.classList.add('hx-datagrid-cell-border');
            } else if (e.editorName === "dxTextBox") {
                e.editorElement.parentElement.classList.add('hx-datagrid-cell-border');
            } 
        }
    }

    gridOnRowUpdating = async (e) => {
        this.dataGridInstance.beginUpdate();

        for (var key in e.newData) {
            if (e.newData.hasOwnProperty(key)) {
                if (key == "Name") {
                    var model = {
                        Id: e.oldData.Id,
                        EventId: parseInt(sessionStorage.getItem("currentEventId"), 10),
                        ColorId: null,
                        ArtistTypeId: null,
                        Name: e.newData[key].trim(),
                        RiderFinished: false,  // not used but has to have value
                        ArrivalDate: null,
                        DepartureDate: null
                    }

                    var data = await ServiceArtists.updateArtistName(model);
                    if (data !== null) {
                        if (!data.hasOwnProperty("error")) {
                            if (data === true) {
                                this.hubConnection
                                    .invoke("SendUpdateInfoEditedArtistShuttles", model.Id)
                                    .catch(function (err) {
                                        return console.error(err.toString());
                                    });
                                HxToast.showToast('Artist updated.', 'success', 'bottom right', 'up-stack');
                            } else {
                                HxToast.showToast('Update artist failed!', 'error', 'bottom right', 'up-stack');
                            }
                        } else {
                            this.onConnectionError();  // callback
                        }
                    } else {
                        HxToast.showToast('Update artist failed!', 'error', 'bottom right', 'up-stack');
                    }
                }
                break;
            }
        }
        this.dataGridInstance.endUpdate();

    }

    gridOnEditingStart = (e) => {
        if (!e.column || e.column.dataType === 'boolean') {
            return;
        }

        // make sure onrowupdating is always triggered after edit
        var row = this.dataGridInstance.getRowIndexByKey(e.key);
        var value = this.dataGridInstance.cellValue(row, e.column.dataField);

        if (e.column.dataType.toLowerCase() == "string") {
            this.dataGridInstance.cellValue(row, e.column.dataField, (value !== "%___%" ? "%___%" : ""));
        } else {
            this.dataGridInstance.cellValue(row, e.column.dataField, (value !== 999999 ? 999999 : 0));
        }
        this.dataGridInstance.cellValue(row, e.column.dataField, value);
    }

    gridOnEditCanceled = async (e) => {
        var id = this.dataGridInstance.getKeyByRowIndex(this.dataGridInstance.option('focusedRowIndex'));
        if (id) {
            await this.updateRow(id);
        }
    }

    gridOnExporting = (e) => {
        e.cancel = true;
        this.onExportArtist();  // callback
    }

    gridOnInitialized = (e) => {
        // set instance
        this.dataGridInstance = e.component;
        this.onInitialized(e.component);  // callback

        // set focused row
        this.dataGridInstance.option('focusedRowIndex', 0);
    }

    gridOnToolbarPreparing = (e) => {
        let toolbarItems = e.toolbarOptions.items;
        toolbarItems.push({
            widget: 'dxButton',
            options: {
                icon: 'filter',
                onClick: this.switchFilter
            },
            location: 'after'
        });
    }

    gridOnContentReady = (e) => {
         // check filters, 'artist', 'artist' and 'type' input fields become empty if cols added (dx bug)
         if (this.viewState.filters !== undefined) {
            for (let filter in this.viewState.filters) {
                if (this.state.dataSourceArtists !== undefined && this.state.dataSourceArtists.length > 0 && this.state.dataSourceArtists[0].hasOwnProperty(filter)) {
                    if (this.dataGridInstance.columnOption(filter, 'filterValue') !== this.viewState.filters[filter]) {
                        this.dataGridInstance.columnOption(filter, 'filterValue', this.viewState.filters[filter]);
                    }
                }
            }
        }
    }

    gridRefresh = async () => {
        await this.loaddataSourceArtists();
    }

    switchFilter = () => {
        this.setState(prevState => ({
            showFilter: !prevState.showFilter
        }));
    }

    showFilter = (show) => {
        this.setState({ showFilter: show });
    }

    // update cells modified by other users (collaborative editing, SignalR)
    updatePushedDataEditedArtist = async (artistId) => {
        //let isEditing = this.dataGridInstance.element().querySelector(".dx-datagrid-rowsview td.dx-editor-cell.dx-focused") != null;
        //if (!isEditing) {
        //    await this.updateRow(artistId);
        //}
        await this.gridRefresh();
    }

    updateRow = async (id) => {
        this.dataGridInstance.beginUpdate();
        var data = await ServiceArtists.getArtistInfoById(parseInt(sessionStorage.getItem("currentEventId"), 10), id, 'shuttles');
        this.dataGridInstance.endUpdate();
        var dsRow = this.state.dataSourceArtists.find(function (row) {
            return row.Id === id;
        });
        if (dsRow) {
            Object.entries(dsRow).forEach(([key, value]) => {
                dsRow[key] = data[key];
            });
        }
        var rowIndex = this.dataGridInstance.getRowIndexByKey(id);
        this.dataGridInstance.repaintRows(rowIndex);
        setTimeout(this.gridFocus, 10);
    }

    gridFocus = () => {
        this.dataGridInstance.focus();
    }

    updatePushedDataAddedArtist = async (artistId) => {
        await this.gridRefresh();
    }

    updatePushedDataDeletedArtist = async (artistId) => {
        await this.gridRefresh();
    }

    renderEditTemplate = (e) => {
        return <div style={{ display: 'flex', justifyContent: 'start' }}>
            <div style={{ width: '40px' }}><i className='dx-icon-edit' style={{ color: '#FF5722' }} /></div>
        </div>;
    }

    renderDeleteTemplate = (e) => {
        if (e.data.CanBeDeleted == true) {
            return <div style={{ display: 'flex', justifyContent: 'start' }}>
                <div style={{ width: '40px' }}><i className='dx-icon-trash' style={{ color: '#FF5722' }} /></div>
            </div>;
        } else {
            return <div style={{ display: 'flex', justifyContent: 'start' }}>
                <div style={{ width: '40px' }}><i className='dx-icon-trash' style={{ color: '#666666' }} /></div>
            </div>;
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="dx-card saadg-main-div">
                    <DataGrid
                        ref={ref => this.refDataGrid = ref}
                        height='calc(100% - 1px)'
                        width='100%'
                        dataSource={this.state.dataSourceArtists}
                        allowColumnReordering={false}
                        allowColumnResizing={true}
                        allowGrouping={false}
                        columnAutoWidth={true}
                        columnResizingMode='widget'
                        filterSyncEnabled={true}
                        focusedRowEnabled={false}
                        remoteOperations={true}
                        repaintChangesOnly={true}
                        showBorders={false}
                        showColumnLines={true}
                        showRowLines={true}
                        wordWrapEnabled={false}
                        noDataText='No artists found.'
                        onExporting={this.gridOnExporting}
                        onInitialized={this.gridOnInitialized}
                        onCellPrepared={this.gridOnCellPrepared}
                        onToolbarPreparing={this.gridOnToolbarPreparing}
                        onRowUpdating={this.gridOnRowUpdating}
                        onCellClick={this.gridOnCellClick}
                        onFocusedCellChanging={this.gridOnFocusedCellChanging}
                        onEditorPreparing={this.gridOnEditorPreparing}
                        onContentReady={this.gridOnContentReady}
                        onEditCanceled={this.gridOnEditCanceled}
                        onEditingStart={this.gridOnEditingStart}
         >
                        <StateStoring enabled={false} type="localStorage" storageKey="storage" />
                        <KeyboardNavigation
                            enabled={true}
                            editOnKeyPress={true}
                            enterKeyAction='moveFocus'
                            enterKeyDirection='column' />
                        <Editing
                            mode="cell"
                            allowUpdating={true}
                            selectTextOnEditStart={true}
                            startEditAction='dblClick'
                            step={0}
                        />
                        <Sorting mode="multiple" />
                        <ColumnChooser enabled={false} />
                        <FilterPanel visible={false} />
                        <FilterRow visible={this.state.showFilter} />
                        <GroupPanel visible={false} />
                        <SearchPanel visible={true} />
                        <HeaderFilter visible={true} />
                        <Paging enabled={false} defaultPageSize={20} />
                        <Pager
                            showPageSizeSelector={true}
                            allowedPageSizes={[5, 10, 20, 50]}
                            showInfo={true}
                        />
                        <Scrolling
                            mode="standard"
                            useNative='false'
                            scrollByContent={true}
                            scrollByThumb={true}
                            showScrollbar='always'
                        />
                        <Column
                            dataField='Id'
                            dataType='number'
                            caption='Id'
                            allowFiltering={false}
                            allowEditing={false}
                            fixed={true}
                            showInColumnChooser={false}
                            visible={false}
                        />
                        <Column
                            dataField='CanBeDeleted'
                            caption='Finished'
                            width='100px'
                            allowFiltering={false}
                            allowEditing={true}
                            fixed={true}
                            showInColumnChooser={true}
                            visible={false}
                        />
                        <Column
                            name='ActionColumnEdit'
                            caption=''
                            allowFiltering={false}
                            allowEditing={false}
                            allowColumnResizing={false}
                            allowColumnReordering={false}
                            allowExporting={false}
                            fixed={true}
                            showInColumnChooser={false}
                            visible={true}
                            width={40}
                            alignment='center'
                            cellRender={this.renderEditTemplate}
                        />
                        <Column
                            dataField='Name'
                            dataType='string'
                            caption='Artist'
                            width='300px'
                            allowFiltering={true}
                            allowEditing={false}
                            fixed={true}
                            showInColumnChooser={true}
                            visible={true}
                        />
                        <Column
                            name='ActionColumnDelete'
                            caption=''
                            allowFiltering={false}
                            allowEditing={false}
                            allowColumnResizing={false}
                            allowColumnReordering={false}
                            allowExporting={false}
                            fixed={true}
                            showInColumnChooser={false}
                            visible={true}
                            width={40}
                            alignment='center'
                            cellRender={this.renderDeleteTemplate}
                        />
                        <Column key='artistcoldummy' caption='' allowFiltering={false} allowResizing={true} allowExporting={false} width='100%' />
                    </DataGrid>
                </div>
            </React.Fragment>
        );
    }
}

export default DataGridArtistsShuttles

