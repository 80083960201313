import globalConfig from '../../config/global.js';

class ServiceTrips {
    ////////////////////////////////////////////////////
    // TRIPS
    ////////////////////////////////////////////////////

    static getTripsInfoForGanttByEventIdGigDateId = async (eventId, gigDateId, startHour) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Trips/GetTripsInfoForGanttByEventIdGigDateId?eventId=" + encodeURIComponent(eventId) + '&gigDateId=' + encodeURIComponent(gigDateId) + '&startHour=' + encodeURIComponent(startHour)) // return null or data object
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static getTripInfoById = async (id) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Trips/GetTripInfoById?id=" + encodeURIComponent(id)) // return null or data object
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static getTripInfosByArtistId = async (artistId) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Trips/GetTripInfosByArtistId?artistId=" + encodeURIComponent(artistId)) // return null or data object
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }


    static addTrip = async (tripModel, subTripsModel) => {
        let returnValue = {};

        const model = { Trip: tripModel, SubTrips: subTripsModel }

        await fetch(globalConfig.settings.host + "/api/Trips/AddTrip", {
            method: 'POST',
            body: JSON.stringify(model),
            headers: {
                'Content-type': 'application/json; charset=UTF-8'
            }
        })
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(error => {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static updateTrip = async (tripModel, subTripsModel) => {
        let returnValue = {};

        const model = { Trip: tripModel, SubTrips: subTripsModel }

        await fetch(globalConfig.settings.host + "/api/Trips/UpdateTrip", {
            method: 'POST',
            body: JSON.stringify(model),
            headers: {
                'Content-type': 'application/json; charset=UTF-8'
            }
        })
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(error => {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static deleteTrip = async (tripId) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Trips/DeleteTrip?tripId=" + encodeURIComponent(tripId)) // return true or false
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static updateTripStatus = async (tripIdModel, statusNameModel) => {
        let returnValue = {};

        const model = {
            TripId: tripIdModel,
            StatusName: statusNameModel,
        }

        await fetch(globalConfig.settings.host + "/api/Trips/UpdateTripStatus", {
            method: 'POST',
            body: JSON.stringify(model),
            headers: {
                'Content-type': 'application/json; charset=UTF-8'
            }
        })
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(error => {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }
}

export default ServiceTrips;
