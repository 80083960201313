import React from 'react';
import { Popup, Position } from 'devextreme-react/popup';
import Form, { SimpleItem, Item, Label, GroupItem, ButtonItem } from 'devextreme-react/form';
import ServiceUsers from '../../../../../api/services/ServiceUsers'

import './PopupRidersAdminShopsUserSettings.scss'


// callbacks
//      onInitialized: callback
//      onUpdate: callback
//      onLoadError: callback


const defaultSettings  = {
    UserId: sessionStorage.getItem('currentUserId'),
    DefaultViewId: -1,
    DefaultFilterId: -1,
    ExportColors: false,
    UseColorsShops: false,
    ShowSelectedCell: false,
    UseAdvancedFiltering: false,
}

class PopupRidersAdminShopsUserSettings extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            userSettingsShops: {}
        }

        this.popupInstance = null;

        this.checkBoxShowSelectedCellInstance = null;
        this.checkBoxUseAdvancedFilteringInstance = null;

        this.dataLoaded = false;

        this.userSettings = {
            UserId: parseInt(sessionStorage.getItem("currentUserId"), 10),
            DefaultViewId: -1,
            DefaultFilterId: -1,
            ShowSelectedCell: false,
            UseAdvancedFiltering: false,
        }
    } 


    // CALLBACKS
    onInitialized = (instance) => {
        if (this.props.onInitialized) {
            this.props.onInitialized(instance);  // callback
        }
    }

    onLoadError = () => {
        if (this.props.onLoadError) {
            this.props.onLoadError();  // callback
        }
    }

    onUpdate = (elementId, value) => {
        if (this.props.onUpdate) {
            this.props.onUpdate(elementId, value);  // callback
        }
    }

    onHidden = async (e) => {
        if (this.props.onHidden) {
            this.props.onHidden(e);  // callback
        }
    }

    // POPUP
    popup_OnInitialized = (e) => {
        this.popupInstance = e.component;
        this.onInitialized(e.component);
    }

    popup_OnShown = async (e) => {
        await this.loadData();
    }

    popup_OnHidden = async (e) => {
        this.dataLoaded = false;
        this.onHidden(e);
    }

    loadData = async () => {
        var settings = await ServiceUsers.getUserSettingsShopsByUserId(parseInt(sessionStorage.getItem("currentUserId"), 10));
        settings = (settings !== null ? settings : this.userSettings);
        if (!settings.hasOwnProperty("error")) {
            this.setState({ userSettingsShops: settings });
            this.dataLoaded = true;
        } else {
            this.onLoadError();
        }
    }

    saveData = async (elementId, value) => {
        var success = await ServiceUsers.setUserSettingsShops(this.state.userSettingsShops);
        if (!success.hasOwnProperty("error")) {
            if (success === true) {
                // trigger callback
                this.onUpdate(elementId, value);
            } else {
                this.onLoadError();
            }
        } else {
            this.onLoadError();
        }
    }

    userSettingChanged = async (e) => {
        if (this.dataLoaded) {
            await this.saveData(e.element.id, e.value);
        }
    }


    checkBoxUseColorsShops_OnInitialized = (e) => {
        this.checkBoxUseColorsShopsInstance = e.component;
    }

    checkBoxUseColorsShops_OnValueChanged = (e) => {
        this.userSettingChanged(e);
    }

    checkBoxExportColors_OnInitialized = (e) => {
        this.checkBoxExportColorsInstance = e.component;
    }

    checkBoxExportColors_OnValueChanged = (e) => {
        this.userSettingChanged(e);
    }


    checkBoxUseAdvancedFiltering_OnInitialized = (e) => {
        this.checkBoxUseAdvancedFilteringInstance = e.component;
    }

    checkBoxUseAdvancedFiltering_OnValueChanged = (e) => {
        this.userSettingChanged(e);
    }

    checkBoxShowSelectedCell_OnInitialized = (e) => {
        this.checkBoxShowSelectedCellInstance = e.component;
    }

    checkBoxShowSelectedCell_OnValueChanged = (e) => {
        this.userSettingChanged(e);
    }
 
    render() {
        return (
            <React.Fragment>
                <Popup
                    dragEnabled={false}
                    hideOnOutsideClick={true}
                    title='Settings'
                    width={250}
                    height={325}
                    shading={false}
                    showTitle={false}
                    onInitialized={this.popup_OnInitialized}
                    onShown={this.popup_OnShown}
                    onHidden={this.popup_OnHidden}
                >
                    <Position
                        my="right top"
                        at="right bottom"
                        of="#toolBarShops"
                    />
                    <Form
                        id={'form'}
                        defaultFormData={defaultSettings}
                        formData={this.state.userSettingsShops}
                        labelLocation={'right'}
                        colCount={1}
                        validationGroup="validationGroupForm"
                    >
                        <GroupItem caption={'Colors'}>
                            <SimpleItem
                                dataField={"UseColorsShops"}
                                editorType="dxCheckBox"
                                editorOptions={{
                                    text: 'Use color for shops',
                                    elementAttr: { id: 'checkBoxUseColorsShops' },
                                    onValueChanged: this.checkBoxUseColorsShops_OnValueChanged
                                }}
                            >
                                <Label visible={false} />
                            </SimpleItem>
                            <SimpleItem
                                dataField={"ExportColors"}
                                editorType="dxCheckBox"
                                editorOptions={{
                                    text: 'Export colors to excel, pdf',
                                    elementAttr: { id: 'checkBoxExportColors' },
                                    onValueChanged: this.checkBoxExportColors_OnValueChanged
                                }}
                            >
                                <Label visible={false} />
                            </SimpleItem>
                        </GroupItem>
                        <GroupItem caption={'Behaviour'}>
                            <SimpleItem
                                dataField={"ShowSelectedCell"}
                                editorType="dxCheckBox"
                                editorOptions={{
                                    text: 'Highlight selected cell',
                                    elementAttr: { id: 'checkBoxShowSelectedCell' },
                                    onValueChanged: this.checkBoxShowSelectedCell_OnValueChanged
                                }}
                            >
                                <Label visible={false} />
                            </SimpleItem>
                            <SimpleItem
                                dataField={"UseAdvancedFiltering"}
                                editorType="dxCheckBox"
                                editorOptions={{
                                    text: 'Use advanced filtering',
                                    elementAttr: { id: 'checkBoxUseAdvancedFiltering' },
                                    onValueChanged: this.checkBoxUseAdvancedFiltering_OnValueChanged
                                }}
                            >
                                <Label visible={false} />
                            </SimpleItem>
                        </GroupItem>
                    </Form>
                </Popup>
            </React.Fragment>
        );

    }

}

export default PopupRidersAdminShopsUserSettings

