import React from 'react';
import { ShuttlesAdminArtists } from '../../../../components';

class shuttlesAdminArtists extends React.Component {
    render() {
        return (
            <ShuttlesAdminArtists />
        );
    }
}

export default shuttlesAdminArtists