import { Button } from 'devextreme-react/button';
import { CheckBox } from 'devextreme-react/check-box';
import { NumberBox } from 'devextreme-react/number-box';
import { Popup } from 'devextreme-react/popup';
import SelectBox from 'devextreme-react/select-box';
import { ValidationGroup } from 'devextreme-react/validation-group';
import Validator, { ValidationRule, AsyncRule, CustomRule } from 'devextreme-react/validator';
import React from 'react';
import ServiceGeneral from '../../../../../api/services/ServiceGeneral';
import ServiceGeoCoding from '../../../../../api/services/ServiceGeoCoding';
import ServiceLocations from '../../../../../api/services/ServiceLocations';
import HxMap from '../../../../custom-components/hx-map/HxMap';
import './PopupShuttlesAdminTripTimingsEdit.scss';

import Map from 'devextreme-react/map';

import HxToast from '../../../../custom-components/hx-toast/HxToast';

// props
//      locationDepartureId (required): departure id
//      locationArrivalId (required): arrival id
//
//      onInitialized: callback
//      onUpdate: callback
//      onLoadError: callback
//      onHiding: callback

class PopupShuttlesAdminTripTimingsEdit extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            locationsDataSource: [],
            routes: [{
                weight: 3,
                color: 'blue',
                opacity: 0.5,
                mode: 'driving',
                locations: [[51.2325700000, 5.0766200000], [51.3150300000, 4.8882800000]],
            }],
            markers: [{
                location: [51.2325700000, 5.0766200000],
                tooltip: {
                    text: 'Times Square',
                },
            }, {
                location: [51.3150300000, 4.8882800000],
                tooltip: {
                    text: 'Central Park',
                },
            }]
        }
        this.mapInstance = null;
        
        this.locationDepartureId = -1;
        this.locationArrivalId = -1;
       
        this.validationGroupInstance = null;

        this.selectBoxLocationDepartureInstance = null;
        this.selectBoxLocationArrivalInstance = null;
        this.textBoxDistanceInstance = null;
        this.textBoxMinutesMinInstance = null;
        this.textBoxMinutesMaxInstance = null;
        this.textBoxRealDistanceInstance = null;
        this.textBoxRealDurationInstance = null;

        this.refMap = React.createRef();

        this.checkBoxKeepOpenInstance = null;
        this.buttonUpdateInstance = null;

        this.buttonReCalcInstance = null

        this.tripTimingRecord = {};
        this.tripTimingDefaultRecord = {
            LocationDepartureId: -1,
            LocationArrivalId: -1,
            EventId: parseInt(sessionStorage.getItem('currentEventId'), 10),
            Distance: 0,
            MinutesMin: 0,
            MinutesMax: 0,
            RealDistance: 0,
            RealDuration: 0,
            LocationDeparture: '',
            LocationArrival: '',
        }

        this.locationDepartureIdShuttlesEditMode = -1;
        this.locationArrivalIdShuttlesEditMode = -1;
    }

    // CALLBACKS
    onInitialized = (instance) => {
        if (this.props.onInitialized) {
            this.props.onInitialized(instance);  // callback
        }
    }

    onLoadError = () => {
        if (this.props.onLoadError) {
            this.props.onLoadError();  // callback
        }
    }

    onUpdate = (success) => {
        if (this.props.onUpdate) {
            this.props.onUpdate(success);  // callback
        }
    }

    onHiding = () => {
        if (this.props.onHiding) {
            this.props.onHiding();  // callback
        }
    }
    // CALLABLES
    setShuttlesEditMode = (dep, arr) => {
        this.locationDepartureIdShuttlesEditMode = dep;
        this.locationArrivalIdShuttlesEditMode = arr;
    }

    // EVENTS
    componentDidMount = async () => {
    }

    // DATA
    loadDataSourceLocations = async () => {
        var data = await ServiceLocations.getLocationsDropDownForTripTimingByEventId(parseInt(sessionStorage.getItem('currentEventId'), 10));
        if (data !== null && !data.hasOwnProperty("error")) {
            this.setState({ locationsDataSource: data });
        } else {
            this.onLoadError();  // callback
        }
    }

    loadDataSourceTripTiming = async (locationDepartureId, locationArrivalId) => {
        var data = await ServiceGeneral.getTripTimingByEventIdLocationDepartureIdLocationArrivalId(parseInt(sessionStorage.getItem('currentEventId'), 10), locationDepartureId, locationArrivalId);
        if (data !== null && !data.hasOwnProperty("error")) {
            this.tripTimingRecord = data;
        } else {
            this.onLoadError();  // callback
        }
    }


    // POPUP
    popup_OnInitialized = (e) => {
        this.popupInstance = e.component;

        // set callback
        this.onInitialized(e.component);
    }

    popup_OnShowing = async (e) => {
        if (this.props.locationDepartureId !== undefined && this.props.locationArrivalId !== undefined) {
            this.locationDepartureId = this.props.locationDepartureId;
            this.locationArrivalId = this.props.locationArrivalId;
        }
    }

    popup_OnHiding = (e) => {
        this.selectBoxLocationDepartureInstance.option('value', null);
        this.selectBoxLocationArrivalInstance.option('value', null);
        this.textBoxDistanceInstance.option('value', null);
        this.textBoxMinutesMinInstance.option('value', null);
        this.textBoxMinutesMaxInstance.option('value', null);
        this.textBoxRealDistanceInstance.option('value', null);
        this.textBoxRealDurationInstance.option('value', null);

        this.validationGroupInstance.reset();

        this.onHiding();  // callback
    }

    popup_OnShown = async (e) => {
        await this.loadDataSourceLocations();

        if (this.locationDepartureIdShuttlesEditMode !== -1 && this.locationArrivalIdShuttlesEditMode !== -1) {
            // new timing for shuttles edit
            this.tripTimingRecord = this.tripTimingDefaultRecord;

            this.selectBoxLocationDepartureInstance.option('value', this.locationDepartureIdShuttlesEditMode);
            this.selectBoxLocationArrivalInstance.option('value', this.locationArrivalIdShuttlesEditMode);

            this.selectBoxLocationDepartureInstance.option('readOnly', true);
            this.selectBoxLocationArrivalInstance.option('readOnly', true);

            document.getElementById('selectBoxLocationDepartureInput').select();

            this.popupInstance.option('title', 'Add new trip timing');
        } else {
            if (this.locationDepartureId == -1 && this.locationArrivalId == -1) {
                // new timing
                this.tripTimingRecord = this.tripTimingDefaultRecord;

                this.selectBoxLocationDepartureInstance.option('readOnly', false);
                this.selectBoxLocationArrivalInstance.option('readOnly', false);

                this.validationGroupInstance.reset();

                document.getElementById('selectBoxLocationDepartureInput').select();

                this.popupInstance.option('title', 'Add new trip timing');
            } else {
                // edit timing
                await this.loadDataSourceTripTiming(this.locationDepartureId, this.locationArrivalId);

                this.selectBoxLocationDepartureInstance.option('value', this.tripTimingRecord.LocationDepartureId);
                this.selectBoxLocationArrivalInstance.option('value', this.tripTimingRecord.LocationArrivalId);
                this.textBoxDistanceInstance.option('value', this.tripTimingRecord.Distance);
                this.textBoxMinutesMinInstance.option('value', this.tripTimingRecord.MinutesMin);
                this.textBoxMinutesMaxInstance.option('value', this.tripTimingRecord.MinutesMax);
                this.textBoxRealDistanceInstance.option('value', this.tripTimingRecord.RealDistance);
                this.textBoxRealDurationInstance.option('value', this.tripTimingRecord.RealDuration);

                this.selectBoxLocationDepartureInstance.option('readOnly', true);
                this.selectBoxLocationArrivalInstance.option('readOnly', true);

                this.popupInstance.option('title', 'Update ' + this.tripTimingRecord.LocationDeparture + " - " + this.tripTimingRecord.LocationArrival);
            }
        }

        this.checkBoxKeepOpenInstance.option('value', false);
        this.buttonUpdateInstance.option('text', 'Save');
        this.buttonUpdateInstance.option('width', '120px');

    }

    // ALL TEXTBOXES FOCUS IN
    textBox_OnFocusIn = (e) => {
        // select all text
        e.element.querySelector('.dx-texteditor-input').select();
    }

    // TEXTBOX NAME
 
    // SELECTBOX DEPARTURE
    selectBoxLocationDepartureInput = {
        id: 'selectBoxLocationDepartureInput',
    }

    selectBoxLocationDeparture_OnInitialized = (e) => {
        this.selectBoxLocationDepartureInstance = e.component;
    }

    selectBoxLocationDeparture_OnSelectionChanged = async (e) => {
        await this.calculateGeoCoding(true);
    }

    // SELECTBOX ARRIVAL
    selectBoxLocationArrival_OnInitialized = (e) => {
        this.selectBoxLocationArrivalInstance = e.component;
    }

    selectBoxLocationArrival_OnSelectionChanged = async (e) => {
        await this.calculateGeoCoding(true);
    }

    calculateGeoCoding = async (syncMap) => {
        let locations = [[0, 0], [0, 0.01]];
        let coordDep = [0, 0];
        let coordArr = [0, 0.01];
        let dep = null;
        let arr = null;
        if (this.selectBoxLocationDepartureInstance.option('value') !== null && this.selectBoxLocationArrivalInstance.option('value') !== null) {
            if (this.selectBoxLocationDepartureInstance.option('value') !== this.selectBoxLocationArrivalInstance.option('value')) {
                dep = this.state.locationsDataSource.find(x => x.Id == this.selectBoxLocationDepartureInstance.option('value'));
                arr = this.state.locationsDataSource.find(x => x.Id == this.selectBoxLocationArrivalInstance.option('value'));

                if (dep.GPSLatitude !== null && dep.GPSLongitude !== null && arr.GPSLatitude !== null && arr.GPSLongitude !== null) {
                    var data = await ServiceGeoCoding.getRouteByDepartureArrival(dep.GPSLatitude, dep.GPSLongitude, arr.GPSLatitude, arr.GPSLongitude);
                    if (data !== null && !data.hasOwnProperty("error")) {
                        if (data.statusCode == 200) {
                            this.textBoxRealDurationInstance.option("value", Math.floor(data.resourceSets[0].resources[0].travelDuration / 60));
                            this.textBoxRealDistanceInstance.option("value", data.resourceSets[0].resources[0].travelDistance);

                            if (syncMap === true) {
                                let routesData = [];
                                for (var i = 0; i < data.resourceSets[0].resources[0].routeLegs[0].itineraryItems.length; i++) {
                                    routesData.push([data.resourceSets[0].resources[0].routeLegs[0].itineraryItems[i].maneuverPoint.coordinates[0], data.resourceSets[0].resources[0].routeLegs[0].itineraryItems[i].maneuverPoint.coordinates[1]]);
                                }
                                locations = routesData;

                                coordDep = routesData[0];
                                coordArr = routesData[routesData.length - 1];

                                this.setState({
                                    routes: this.state.routes.map((item) => {
                                        item.locations = locations;
                                        return item;
                                    }),
                                });


                                //this.setState({
                                //    markers: [{
                                //        location: coordDep,
                                //        tooltip: {
                                //            text: 'Times Square',
                                //        },
                                //    }, {
                                //        location: coordArr,
                                //        tooltip: {
                                //            text: 'Central',
                                //        },
                                //    }],
                                //});
                            }
                        }
                    }
                }

 
            } 
        } 

        if (syncMap === true && dep !== null && arr !=null && dep.GPSLatitude !== null && dep.GPSLongitude !== null && arr.GPSLatitude !== null && arr.GPSLongitude !== null) {
            this.mapInstance.repaint();
        }     
    }

    // MAP
    mapInstance_OnInitialized = (e) => {
        this.mapInstance = e.component;
    }

    // TEXTBOX DISTANCE
    textBoxDistance_OnInitialized = (e) => {
        this.textBoxDistanceInstance = e.component;
    }

    // TEXTBOX MINUTES MIN
    textBoxMinutesMin_OnInitialized = (e) => {
        this.textBoxMinutesMinInstance = e.component;
    }

    // TEXTBOX MINUTES MAX
    textBoxMinutesMax_OnInitialized = (e) => {
        this.textBoxMinutesMaxInstance = e.component;
    }

    // TEXTBOX REAL DISTANCE
    textBoxRealDistance_OnInitialized = (e) => {
        this.textBoxRealDistanceInstance = e.component;
    }

    // TEXTBOX REAL DURATION
    textBoxRealDuration_OnInitialized = (e) => {
        this.textBoxRealDurationInstance = e.component;
    }
   
    // CHECKBOX KEEP OPEN
    checkBoxKeepOpen_OnInitialized = (e) => {
        this.checkBoxKeepOpenInstance = e.component;
    }

    checkBoxKeepOpen_OnValueChanged = (e) => {
        this.buttonUpdateInstance.option('text', (e.value === true ? 'Save & new' : 'Save'));
        this.buttonUpdateInstance.option('width', (e.value === true ? '140px' : '120px'));
    }
    // BUTTON UPDATE
    buttonUpdate_OnInitialized = (e) => {
        this.buttonUpdateInstance = e.component;
    }

    buttonUpdate_OnClick = async (e) => {
        await this.updateTripTiming();
    }

    // BUTTON RECALC
    buttonReCalc_OnInitialized = (e) => {
        this.buttonReCalcInstance = e.component;
    }

    buttonReCalc_OnClick = async (e) => {
        await this.calculateGeoCoding(true);
    }

    updateTripTiming = async () => {

        this.validationGroupInstance.validate();
        if (this.selectBoxLocationDepartureInstance.option('isValid') === true
            && this.selectBoxLocationArrivalInstance.option('isValid') === true
            && this.textBoxDistanceInstance.option('isValid') === true
            && this.textBoxMinutesMinInstance.option('isValid') === true
            && this.textBoxMinutesMaxInstance.option('isValid') === true
            && this.textBoxRealDistanceInstance.option('isValid') === true
            && this.textBoxRealDurationInstance.option('isValid') === true) {

            this.tripTimingRecord.LocationDepartureId = this.selectBoxLocationDepartureInstance.option('value');
            this.tripTimingRecord.LocationArrivalId = this.selectBoxLocationArrivalInstance.option('value');
            this.tripTimingRecord.EventId = parseInt(sessionStorage.getItem('currentEventId'), 10);
            this.tripTimingRecord.Distance = this.textBoxDistanceInstance.option('value');
            this.tripTimingRecord.MinutesMin = this.textBoxMinutesMinInstance.option('value');
            this.tripTimingRecord.MinutesMax = this.textBoxMinutesMaxInstance.option('value');
            this.tripTimingRecord.RealDistance = this.textBoxRealDistanceInstance.option('value');
            this.tripTimingRecord.RealDuration = this.textBoxRealDurationInstance.option('value');
            this.tripTimingRecord.LocationDeparture = this.selectBoxLocationDepartureInstance.option('display');
            this.tripTimingRecord.LocationArrival = this.selectBoxLocationArrivalInstance.option('display');

            if (this.locationDepartureId == -1 && this.locationArrivalId == -1) {
                // new timing
                var resultTipTiming = await ServiceGeneral.addTripTiming(this.tripTimingRecord);
                if (resultTipTiming !== null && !resultTipTiming.hasOwnProperty("error")) {
                    this.onUpdate(true); // callback
                    if (this.checkBoxKeepOpenInstance.option('value') === false) {
                        this.popupInstance.hide();
                    } else {
                        this.selectBoxLocationDepartureInstance.option('value', null);
                        this.selectBoxLocationArrivalInstance.option('value', null);
                        this.textBoxDistanceInstance.option('value', null);
                        this.textBoxMinutesMinInstance.option('value', null);
                        this.textBoxMinutesMaxInstance.option('value', null);
                        this.textBoxRealDistanceInstance.option('value', null);
                        this.textBoxRealDurationInstance.option('value', null);

                        this.validationGroupInstance.reset();

                        this.popupInstance.option('title', 'Add new location');
                    }
                } else {
                    this.onUpdate(false); // callback
                }
            } else {
                // edit timing
                var resultTipTiming = await ServiceGeneral.updateTripTiming(this.tripTimingRecord);
                if (resultTipTiming != null && !resultTipTiming.hasOwnProperty("error") && resultTipTiming == true) {
                    this.onUpdate(true); // callback
                    if (this.checkBoxKeepOpenInstance.option('value') === false) {
                        this.popupInstance.hide();
                    } else {
                        this.selectBoxLocationDepartureInstance.option('value', null);
                        this.selectBoxLocationArrivalInstance.option('value', null);
                        this.textBoxDistanceInstance.option('value', null);
                        this.textBoxMinutesMinInstance.option('value', null);
                        this.textBoxMinutesMaxInstance.option('value', null);
                        this.textBoxRealDistanceInstance.option('value', null);
                        this.textBoxRealDurationInstance.option('value', null);

                        this.validationGroupInstance.reset();

                        this.popupInstance.option('title', 'Add new trip timing');
                    }
                } else {
                    this.onUpdate(false); // callback
                }
            }
        } else {
            this.selectBoxLocationDepartureInstance.focus();
        }
    }

    // VALIDATION
    validationGroup_OnInitialized = (e) => {
        this.validationGroupInstance = e.component;
    }

    validateExistingTripTimings = async () => {
        if (this.locationDepartureId == -1 && this.locationArrivalId == -1 && this.selectBoxLocationDepartureInstance.option('value') != null && this.selectBoxLocationArrivalInstance.option('value') != null) {
            if (this.selectBoxLocationDepartureInstance.option('value') === this.selectBoxLocationArrivalInstance.option('value')) {
                this.selectBoxLocationDepartureInstance.option('isValid', false);
                this.selectBoxLocationArrivalInstance.option('isValid', false);
                return false;
            }
            var result = await ServiceGeneral.doesTripTimingExist(parseInt(sessionStorage.getItem('currentEventId'), 10), this.selectBoxLocationDepartureInstance.option('value'), this.selectBoxLocationArrivalInstance.option('value'));
            if (result !== null && !result.hasOwnProperty("error")) {
                this.selectBoxLocationDepartureInstance.option('isValid', !result);
                this.selectBoxLocationArrivalInstance.option('isValid', !result);
                return !result;
            } else {
                this.onLoadError();  // callback
                return false;
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <Popup
                    dragEnabled={false}
                    hideOnOutsideClick={false}
                    showTitle={true}
                    width={871}
                    height={440}
                    showCloseButton={true}
                    onInitialized={this.popup_OnInitialized}
                    onShown={this.popup_OnShown}
                    onShowing={this.popup_OnShowing}
                    onHiding={this.popup_OnHiding}
                >
                    <ValidationGroup
                        onInitialized={this.validationGroup_OnInitialized}
                    >
                        <div>
                            <div className="psatte-header-div">Info</div>
                            <div className="dx-card psatte-top-card">
                                <div className="psatte-top-div">
                                    <div className="psatte-top-left-div">
                                        <div className="psatte-dx-field">
                                            <div className="dx-field-label psatte-top-label">Departure*</div>
                                            <div className="dx-field-value psatte-top-input">
                                                <SelectBox
                                                    dataSource={this.state.locationsDataSource}
                                                    valueExpr="Id"
                                                    displayExpr="Name"
                                                    dropDownOptions={{ maxHeight: '200px' }}
                                                    inputAttr={this.selectBoxLocationDepartureInput}
                                                    minSearchLength={0}
                                                    searchEnabled={true}
                                                    showClearButton={true}
                                                    showDataBeforeSearch={false}
                                                    width={250}
                                                    onInitialized={this.selectBoxLocationDeparture_OnInitialized}
                                                    onSelectionChanged={this.state.selectBoxLocationDeparture_OnSelectionChanged}
                                                >
                                                    <Validator>
                                                        <AsyncRule validationCallback={this.validateExistingTripTimings} message="Trip timing exists or invalid trip" />
                                                        <ValidationRule type="required" message="Required" />
                                                    </Validator>
                                                </SelectBox>
                                            </div>
                                        </div>
                                        <div className="psatte-dx-field">
                                            <div className="dx-field-label psatte-top-label">Arrival*</div>
                                            <div className="dx-field-value psatte-top-input">
                                                <SelectBox
                                                    dataSource={this.state.locationsDataSource}
                                                    valueExpr="Id"
                                                    displayExpr="Name"
                                                    dropDownOptions={{ maxHeight: '200px' }}
                                                    minSearchLength={0}
                                                    searchEnabled={true}
                                                    showClearButton={true}
                                                    showDataBeforeSearch={false}
                                                    width={250}
                                                    onInitialized={this.selectBoxLocationArrival_OnInitialized}
                                                    onSelectionChanged={this.selectBoxLocationArrival_OnSelectionChanged}
                                                >
                                                    <Validator>
                                                        <AsyncRule validationCallback={this.validateExistingTripTimings} message="Trip timing exists or invalid trip" />
                                                        <ValidationRule type="required" message="Required" />
                                                    </Validator>
                                                </SelectBox>
                                            </div>
                                        </div>
                                        <div className="psatte-dx-field">
                                            <div className="dx-field-label psatte-top-label">Distance*</div>
                                            <div className="dx-field-value psatte-top-input">
                                                <NumberBox
                                                    width='75px'
                                                    step={0}
                                                    onFocusIn={this.textBox_OnFocusIn}
                                                    onInitialized={this.textBoxDistance_OnInitialized}
                                                >
                                                    <Validator>
                                                        <ValidationRule type="required" message="Required" />
                                                    </Validator>
                                                </NumberBox>
                                            </div>
                                        </div>
                                        <div className="psatte-dx-field-minutes">
                                            <div>
                                                <div className="psatte-dx-field">
                                                    <div className="dx-field-label psatte-top-label">Min minutes*</div>
                                                    <div className="dx-field-value psatte-top-input">
                                                        <NumberBox
                                                            width='75px'
                                                            step={0}
                                                            onFocusIn={this.textBox_OnFocusIn}
                                                            onInitialized={this.textBoxMinutesMin_OnInitialized}
                                                        >
                                                            <Validator>
                                                                <ValidationRule type="required" message="Required" />
                                                            </Validator>
                                                        </NumberBox>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="psatte-minutes-right-div">
                                                <div className="psatte-dx-field">
                                                    <div className="dx-field-label psatte-top-label">Max minutes</div>
                                                    <div className="dx-field-value psatte-top-input">
                                                        <NumberBox
                                                            width='75px'
                                                            step={0}
                                                            onFocusIn={this.textBox_OnFocusIn}
                                                            onInitialized={this.textBoxMinutesMax_OnInitialized}
                                                        >
                                                            <Validator>
                                                                <ValidationRule type="required" message="Required" />
                                                            </Validator>
                                                        </NumberBox>
                                                    </div>
                                                </div>
                                        </div>
                                        </div>
                                        <div className="psatte-dx-field-minutes">
                                            <div className="psatte-minutes-left-div">
                                                <div className="psatte-dx-field">
                                                    <div className="dx-field-label psatte-top-label">Real distance*</div>
                                                    <div className="dx-field-value psatte-top-input">
                                                        <NumberBox
                                                            width='75px'
                                                            step={0}
                                                            readOnly={true}
                                                            onFocusIn={this.textBox_OnFocusIn}
                                                            onInitialized={this.textBoxRealDistance_OnInitialized}
                                                        >
                                                            <Validator>
                                                                <ValidationRule type="required" message="Required" />
                                                            </Validator>
                                                        </NumberBox>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="psatte-real-right-div">
                                                <div className="psatte-dx-field">
                                                    <div className="dx-field-label psatte-top-label">Real duration*</div>
                                                    <div className="dx-field-value psatte-top-input">
                                                        <NumberBox
                                                            width='75px'
                                                            step={0}
                                                            readOnly={true}
                                                            onFocusIn={this.textBox_OnFocusIn}
                                                            onInitialized={this.textBoxRealDuration_OnInitialized}
                                                        >
                                                            <Validator>
                                                                <ValidationRule type="required" message="Required" />
                                                            </Validator>
                                                        </NumberBox>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="psatte-dx-field">
                                            <div className="dx-field-label psatte-top-label"></div>
                                            <div className="dx-field-value psatte-top-input">
                                                <Button
                                                    onInitialized={this.buttonReCalc_OnInitialized}
                                                    text="Recalc"
                                                    onClick={this.buttonReCalc_OnClick}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="psatte-top-right-div">
                                        <div className="psatte-dx-field-map">
                                            <div className="dx-field-value psatte-second-input">
                                                <Map
                                                    onInitialized={this.mapInstance_OnInitialized}
                                                    ref={this.refMap}
                                                    defaultZoom={14}
                                                    height={220}
                                                    width={440}
                                                    controls={false}
                                                    provider="bing"
                                                    routes={this.state.routes}
                                                    markers={this.state.markers}
                                                />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ValidationGroup>

                    <div className="psatte-buttons">
                        <div className="psatte-buttons-checkbox">
                            <CheckBox
                                text="Keep open"
                                onInitialized={this.checkBoxKeepOpen_OnInitialized}
                                onValueChanged={this.checkBoxKeepOpen_OnValueChanged}
                            />
                        </div>
                        <div className='psatte-buttons-update'>
                            <div className="psatte-button-update">
                                <Button
                                    onInitialized={this.buttonUpdate_OnInitialized}
                                    icon="save"
                                    onClick={this.buttonUpdate_OnClick}
                                />
                            </div>
                        </div>
                    </div>
                </Popup>
            </React.Fragment>
        );
    }

}

export default PopupShuttlesAdminTripTimingsEdit

