import globalConfig from '../../config/global.js';

class ServiceFleet {

    static getFleetByEventId = async (eventId) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Fleet/GetFleetByEventId?eventId=" + encodeURIComponent(eventId)) // return null or data object
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static getFleetDropDownByEventId = async (eventId) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Fleet/GetFleetDropDownByEventId?eventId=" + encodeURIComponent(eventId)) // return null or data object
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static getFleetByEventIdFleetId = async (eventId, fleetId) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Fleet/GetFleetByEventIdFleetId?eventId=" + encodeURIComponent(eventId) + "&fleetId=" + encodeURIComponent(fleetId)) // return null or data object
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }
    
    static addFleet = async (model) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Fleet/AddFleet", {
            method: 'POST',
            body: JSON.stringify(model),
            headers: {
                'Content-type': 'application/json; charset=UTF-8'
            }
        })
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(error => {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static updateFleet = async (model) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Fleet/UpdateFleet", {
            method: 'POST',
            body: JSON.stringify(model),
            headers: {
                'Content-type': 'application/json; charset=UTF-8'
            }
        })
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(error => {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static deleteFleet = async (fleetId) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Fleet/DeleteFleet?fleetId=" + encodeURIComponent(fleetId)) // return true or false
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }
}

export default ServiceFleet;
