import React from 'react';

import HXTile from '../../custom-components/hx-tile/HXTile'


import './ShuttlesAdmin.scss';


function ShuttlesAdmin() {
    return (
        <React.Fragment>
            <div className='shuttles-admin-main-div'>
                <HXTile
                    width='100px'
                    height='50px'
                    backgroundColor='#663CB4'
                    color='#FFFFFF'
                    borderColor='#461C94'
                    text='Dashboard'
                />

                <HXTile
                    width='100px'
                    height='50px'
                    backgroundColor='#F99221'
                    color='#FFFFFF'
                    borderColor='#000000'
                    text='Relations'
                />
                <HXTile
                    width='100px'
                    height='50px'
                    backgroundColor='#299888'
                    color='#FFFFFF'
                    borderColor='#000000'
                    text='Locations'
                />
                <HXTile
                    width='100px'
                    height='50px'
                    backgroundColor='#38BFD3'
                    color='#FFFFFF'
                    borderColor='#000000'
                    text='Fleet'
                />
                <HXTile
                    width='100px'
                    height='50px'
                    backgroundColor='#4354B3'
                    color='#FFFFFF'
                    borderColor='#000000'
                    text='Timings'
                />
                <HXTile
                    width='100px'
                    height='50px'
                    backgroundColor='#9823AE'
                    color='#FFFFFF'
                    borderColor='#000000'
                    text='Utilities'
                />
                <HXTile
                    width='100px'
                    height='50px'
                    backgroundColor='#FF00FF'
                    color='#FFFFFF'
                    borderColor='#000000'
                    text='nog iets'
                />
            </div>
        </React.Fragment>
    );
}

export default ShuttlesAdmin