import React from 'react';
import { ShuttlesAdminTripTimings } from '../../../../components';

class shuttlesAdminTripTimings extends React.Component {
    render() {
        return (
            <ShuttlesAdminTripTimings />
        );
    }
}

export default shuttlesAdminTripTimings