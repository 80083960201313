import React from 'react';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { Button } from 'devextreme-react/button';
import { CheckBox } from 'devextreme-react/check-box';
import HxToast from '../../custom-components/hx-toast/HxToast';
import HxTags from '../../custom-components/hx-tags/HxTags'
import PopupShuttlesEdit from './sub-components/PopupShuttlesEdit'
import { confirm } from 'devextreme/ui/dialog';
import ServiceTrips from '../../../api/services/ServiceTrips'
import ServiceEvents from '../../../api/services/ServiceEvents'
import HXUserFilters from '../../custom-components/hx-user-filters/HXUserFilters'
import PopupShuttlesUserSettings from './sub-components/PopupShuttlesUserSettings'

import ShuttlesDataGridTrips from './sub-components/ShuttlesDataGridTrips';

import './Shuttles.scss';

class Shuttles extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            tripId: -1,
            tripsCount: 0
        }

        this.dataGridInstance = null;
        this.popupShuttlesUserSettingsInstance = null;

        this.eventDates = [];
        this.selectedEventDate = -1;

        this.popupShuttlesEditInstance = null;

        this.hubConnection = null;

        // filters
        this.loadedUserFilterValue = [];

        this.checkBoxShowHideTimeLineInstance = null;
        this.checkBoxShowHideStatusWaitingInstance = null;
        this.checkBoxShowHideStatusDrivingInstance = null;
        this.checkBoxShowHideStatusFinishedInstance = null;
        this.checkBoxShowHideStatusCancelledInstance = null;

        this.buttonShowCountInstance = null;

    }

    // EVENTS
    componentDidMount = async () => {
        await this.loadDataSourceEventDates();

        this.hubConnection = new HubConnectionBuilder().withUrl("/shuttlesHub").build();
        await this.hubConnection.start()
            .then(function () {

            })
            .catch(function (err) {
                return console.error(err.toString());
            });
        this.hubConnection.on("ReceiveMessageAddedShuttle", await this.updatePushedDataAddedShuttle);
        this.hubConnection.on("ReceiveMessageEditedShuttle", await this.updatePushedDataEditedShuttle);
        this.hubConnection.on("ReceiveMessageDeletedShuttle", await this.updatePushedDataDeletedShuttle);
    }


    // DATA
    loadDataSourceEventDates = async () => {
        var data = await ServiceEvents.getEventDatesDropDownByEventId(parseInt(sessionStorage.getItem('currentEventId'), 10), 'shuttles');
        if (data !== null && !data.hasOwnProperty("error")) {
            this.eventDates = data;
            this.selectedEventDate = data[0].Id;
            this.refTags.setTags(this.convertEventDates(data));
            await this.refDataGridShuttles.refresh(this.selectedEventDate);
        } else {
          //  this.onLoadError();  // callback
        }
    }

    //  POPUP EDIT
    popupShuttlesEdit_OnInitialized = (instance) => {
        this.popupShuttlesEditInstance = instance;
    }

    popupShuttlesEdit_OnHiding = async () => {
      //  await this.refDataGridShuttles.refresh(this.selectedEventDate);
    }

    popupShuttlesEdit_OnAdd = async (newId, crudDate) => {
        if (newId !== null) {
            HxToast.showToast('Trip added successfully', 'success', 'bottom right', 'up-stack');
            await this.refDataGridShuttles.refresh(this.selectedEventDate);
            this.hubConnection
                .invoke("SendUpdateInfoAddedShuttle", newId, crudDate)
                .catch(function (err) {
                    return console.error(err.toString());
                });
        } else {
            HxToast.showToast('Failed to add trip', 'error', 'bottom right', 'up-stack');
        }
    }

    popupShuttlesEdit_OnUpdate = async (success, crudDate) => {
        if (success) {
            HxToast.showToast('Trip updated successfully', 'success', 'bottom right', 'up-stack');
            await this.refDataGridShuttles.refresh(this.selectedEventDate);
            this.hubConnection
                .invoke("SendUpdateInfoEditedShuttle", this.state.tripId, crudDate)
                .catch(function (err) {
                    return console.error(err.toString());
                });
        } else {
            HxToast.showToast('Failed to update trip', 'error', 'bottom right', 'up-stack');
        }
    }

    // POPUP USERSETTINGS
    popupShuttlesUserSettings_OnInitialized = (instance) => {
        this.popupShuttlesUserSettingsInstance = instance;
    }

    popupShuttlesUserSettings_OnUpdate = (elementId, value) => {
        this.refDataGridShuttles.applyUserSettings(elementId, value)
    }

    // HUB
    updatePushedDataAddedShuttle = async (shuttleId, crudDate) => {
        if (this.refDataGridShuttles) {
            await this.refDataGridShuttles.refresh(this.selectedEventDate);
        }
    }

    updatePushedDataEditedShuttle = async (shuttleId, crudDate) => {
        if (this.refDataGridShuttles) {
            await this.refDataGridShuttles.updateRow(shuttleId, crudDate);
        }
    }

    updatePushedDataDeletedShuttle = async (shuttleId, crudDate) => {
        if (this.refDataGridShuttles) {
            await this.refDataGridShuttles.refresh(this.selectedEventDate);
        }
    }

    // GRID
    gridTrips_OnInitialized = (instance) => {
        this.dataGridInstance = instance;
        this.refUserFilters.setDataGridInstance(instance);
    }

    gridTrips_OnAddTrip = async () => {
        this.setState({ tripId: -1 });
        await this.refPopupShuttlesEdit.preload();
        this.popupShuttlesEditInstance.show();
    }

    gridTrips_OnUpdateTrip = async (id) => {
        this.setState({ tripId: id });
        await this.refPopupShuttlesEdit.preload();
        this.popupShuttlesEditInstance.show();
    }

    gridTrips_OnDeleteTrip = async (ganttripData) => {
        const startDate = new Date(ganttripData.Start);
        const endDate = new Date(ganttripData.End);
        const timing = startDate.getHours().toString().padStart(2, '0') + ':' + startDate.getMinutes().toString().padStart(2, '0') + ' - ' + endDate.getHours().toString().padStart(2, '0') + ':' + endDate.getMinutes().toString().padStart(2, '0')
        let result = await confirm('Are you sure you want to delete ' + ganttripData.Title + ' ' + timing +  '?', "Confirm delete");
        if (result) {
            var resultDelete = await ServiceTrips.deleteTrip(ganttripData.Id);
            if (resultDelete === true) {
                HxToast.showToast('Trip deleted successfully', 'success', 'bottom right', 'up-stack');
                await this.refDataGridShuttles.refresh(this.selectedEventDate);
                this.hubConnection
                    .invoke("SendUpdateInfoDeletedShuttle", ganttripData.Id, startDate)
                    .catch(function (err) {
                        return console.error(err.toString());
                    });
            } else {
                HxToast.showToast('Trip delete failed!', 'error', 'bottom right', 'up-stack');
            }
        }
    }

    gridTrips_OnLoadError = (e) => {
        HxToast.showToast('Something somewhere went terribly wrong!', 'error', 'bottom center', 'up-stack');
    }

    gridTrips_onLoadedData = (gigDateId, count) => {
        this.setState({ tripsCount : count});
    }

    // BUTTON ADD
    buttonAddShuttle_OnClick = async () => {
        this.setState({ tripId: -1 });
        await this.refPopupShuttlesEdit.preload();
        this.popupShuttlesEditInstance.show();
    }

    buttonShowCount_OnClick = async (e) => {
        this.setState({ tripsCount: this.refDataGridShuttles.getRowCount()});
    }

    // BUTTON USERSETTINGS
    buttonUserSettings_OnClick = (e) => {
        this.popupShuttlesUserSettingsInstance.show();
    }

    /// DATE TAGS
    convertEventDates(data) {
        let datesArray = [];

        if (data.length > 0) {
            const weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
            data.forEach((item, index, array) => {
                let dt = new Date(item.GigDate);
                let selected = (this.selectedEventDate == item.Id)
                datesArray.push({ id: item.Id, text: weekday[dt.getDay()] + ' ' + dt.getDate() + '/' + (dt.getMonth() + 1) + '/' + dt.getFullYear(), selected: selected })
            });
        }

        return datesArray;
    }

    tagEventDates_OnClick = async (e, selected) => {
        let id = parseInt(e.currentTarget.id, 10);

        if (id !== -1) {
            if (selected) {
                this.selectedEventDate = id;
            } else {
                this.selectedEventDate = -1;
            }
        }

        this.refTags.setTags(this.convertEventDates(this.eventDates));

        await this.refDataGridShuttles.refresh(this.selectedEventDate);
    }

    // USERFILTER
    userFilterLoaded = async (filterState) => {
        // display loadpanel
        this.dataGridInstance.beginCustomLoading("Loading...");

        this.dataGridInstance.beginUpdate();

        if (filterState.filterValue !== undefined) {
            this.dataGridInstance.option('filterValue', JSON.parse(filterState.filterValue));
            this.loadedUserFilterValue = this.dataGridInstance.option('filterValue');
        } else {
            this.loadedUserFilterValue = [];
        }

        this.dataGridInstance.endUpdate();

        // hide loadpanel
        this.dataGridInstance.endCustomLoading();
    }

    clearUserFilter = () => {
        this.dataGridInstance.clearFilter();
    }

    userFilterError = (error) => {
        //this.setErrorHeader(error);
    }


    // CHECKBOXES
    checkBoxShowHideTimeLine_OnInitialized = (e) => {
        this.checkBoxShowHideTimeLineInstance = e.component;
        this.checkBoxShowHideTimeLineInstance.option('value', true);
    }

    checkBoxShowHideTimeLine_OnValueChanged = (e) => {
        this.refDataGridShuttles.showHideTimeLine(e.value);
    }

    checkBoxShowHideStatusWaiting_OnInitialized = (e) => {
        this.checkBoxShowHideStatusWaitingInstance = e.component;
        this.checkBoxShowHideStatusWaitingInstance.option('value', true);
    }

    checkBoxShowHideStatusWaiting_OnValueChanged = (e) => {
        this.refDataGridShuttles.showHideStatusWaiting(e.value);
    }

    checkBoxShowHideStatusDriving_OnInitialized = (e) => {
        this.checkBoxShowHideStatusDrivingInstance = e.component;
        this.checkBoxShowHideStatusDrivingInstance.option('value', true);
    }

    checkBoxShowHideStatusDriving_OnValueChanged = (e) => {
        this.refDataGridShuttles.showHideStatusDriving(e.value);
    }

    checkBoxShowHideStatusFinished_OnInitialized = (e) => {
        this.checkBoxShowHideStatusFinishedInstance = e.component;
        this.checkBoxShowHideStatusFinishedInstance.option('value', true);
    }

    checkBoxShowHideStatusFinished_OnValueChanged = (e) => {
        this.refDataGridShuttles.showHideStatusFinished(e.value);
    }

    checkBoxShowHideStatusCancelled_OnInitialized = (e) => {
        this.checkBoxShowHideStatusCancelledInstance = e.component;
        this.checkBoxShowHideStatusCancelledInstance.option('value', false);
    }

    checkBoxShowHideStatusCancelled_OnValueChanged = (e) => {
        this.refDataGridShuttles.showHideStatusCancelled(e.value);
    }


    render() {
        return (
            <React.Fragment>
                <div className='shuttles-admin-main-div'>
                    <div className='shuttles-admin-content-block'>
                        <div id='toolBarShuttles' className='hx-toolbar-main-div'>
                            <div className='hx-toolbar-left'>
                                <span>
                                    <CheckBox
                                        defaultValue={false}
                                        text="Timeline"
                                        onInitialized={this.checkBoxShowHideTimeLine_OnInitialized}
                                        onValueChanged={this.checkBoxShowHideTimeLine_OnValueChanged}
                                    />
                                </span>
                                <span className="shuttles-admin-span-statusses">
                                    <span className="shuttles-admin-span-statusses-label">Status:</span>
                                    <span className="shuttles-admin-span-statusses-checkboxes">
                                        <CheckBox
                                            defaultValue={false}
                                            text="W"
                                            onInitialized={this.checkBoxShowHideStatusWaiting_OnInitialized}
                                            onValueChanged={this.checkBoxShowHideStatusWaiting_OnValueChanged}
                                        />
                                        <CheckBox
                                            defaultValue={false}
                                            text="D"
                                            onInitialized={this.checkBoxShowHideStatusDriving_OnInitialized}
                                            onValueChanged={this.checkBoxShowHideStatusDriving_OnValueChanged}
                                        />
                                        <CheckBox
                                            defaultValue={false}
                                            text="F"
                                            onInitialized={this.checkBoxShowHideStatusFinished_OnInitialized}
                                            onValueChanged={this.checkBoxShowHideStatusFinished_OnValueChanged}
                                        />
                                        <CheckBox
                                            defaultValue={false}
                                            text="C"
                                            onInitialized={this.checkBoxShowHideStatusCancelled_OnInitialized}
                                            onValueChanged={this.checkBoxShowHideStatusCancelled_OnValueChanged}
                                        />
                                    </span>
                                </span>
                            </div>
                            <div className='hx-toolbar-right'>
                                <span>
                                    <HXUserFilters
                                        ref={ref => (this.refUserFilters = ref)}
                                        onUserFilterLoaded={this.userFilterLoaded}
                                        onClearFilter={this.clearUserFilter}
                                        onError={this.userFilterError}
                                        filterTypeName='shuttles'
                                    />
                                </span>
                                <span>
                                    <Button
                                        icon='preferences'
                                        stylingMode='text'
                                        text=''
                                        onClick={this.buttonUserSettings_OnClick}
                                    />
                                </span>
                            </div>
                        </div>
                        <div className='shuttles-admin-datagrid-buttons-wrapper'>
                            <div className='shuttles-admin-datagrid-buttons'>
                                <span className='shuttles-admin-datagrid-button'>
                                    <Button
                                        text="Add shuttle"
                                        icon="add"
                                        onClick={this.buttonAddShuttle_OnClick} />
                                </span>
                                <span className='shuttles-admin-datagrid-button'>
                                    <Button
                                        text={this.state.tripsCount}
                                        width='30px'
                                        onClick={this.buttonShowCount_OnClick}
                                    />
                                </span>
                            </div>

                            <div className="shuttles-admin-tags-div">
                                <HxTags
                                    ref={ref => this.refTags = ref}
                                    tags={this.convertEventDates(this.eventDates)}
                                    onClick={this.tagEventDates_OnClick}
                                />
                            </div>
                        </div>

                        <div className='shuttles-admin-gantt-card'>
                            <ShuttlesDataGridTrips
                                ref={ref => this.refDataGridShuttles = ref}
                                onInitialized={this.gridTrips_OnInitialized}
                                onAddTrip={this.gridTrips_OnAddTrip}
                                onUpdateTrip={this.gridTrips_OnUpdateTrip}
                                onDeleteTrip={this.gridTrips_OnDeleteTrip}
                                onLoadError={this.gridTrips_OnLoadError}
                                onLoadedData={this.gridTrips_onLoadedData}
                            />
                        </div>
                    </div>
                </div>

                <PopupShuttlesEdit
                    id={this.state.tripId}
                    ref={ref => this.refPopupShuttlesEdit = ref}
                    onInitialized={this.popupShuttlesEdit_OnInitialized}
                    onHiding={this.popupShuttlesEdit_OnHiding}
                    onAdd={this.popupShuttlesEdit_OnAdd}
                    onUpdate={this.popupShuttlesEdit_OnUpdate}
                />

                <PopupShuttlesUserSettings
                    onInitialized={this.popupShuttlesUserSettings_OnInitialized}
                    onUpdate={this.popupShuttlesUserSettings_OnUpdate}
                />

            </React.Fragment>
        );
    }
}

export default Shuttles
