import ServiceUsers from './services/ServiceUsers'

export async function signIn(email, password) {
    // Send request
    var returnValue = {
        isOk: false,
        message: "Authentication failed"
    };

    var user = await ServiceUsers.getUserByEmail(email);

    if (user !== null && !user.hasOwnProperty("error") && user.Password === password) {
        returnValue = {
            isOk: true,
            data: user
        };
    }

    return returnValue;
}

export async function getUser(id) {
    var returnValue = {
        isOk: false,
        message: "Failed to get user!"
    };

    var user = await ServiceUsers.getUserById(id);

    if (user !== null && !user.hasOwnProperty("error") && user.Id === id) {
        returnValue = {
            isOk: true,
            data: user
        };
    }

    return returnValue;
}

export async function createAccount(email, password) {
  try {
    // Send request
    console.log(email, password);

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to create account"
    };
  }
}

export async function changePassword(email, recoveryCode) {
  try {
    // Send request
    console.log(email, recoveryCode);

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to change password"
    }
  }
}

export async function resetPassword(email) {
  try {
    // Send request
    console.log(email);

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to reset password"
    };
  }
}
