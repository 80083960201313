import globalConfig from '../../config/global.js';

class ServiceStats {
    ////////////////////////////////////////////////////
    // STATS
    ////////////////////////////////////////////////////

    static getTripsStats = async (eventId) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Stats/GetTripsStats?eventId=" + encodeURIComponent(eventId))  // return null or data object
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static getDriversStats = async (eventId) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Stats/GetDriversStats?eventId=" + encodeURIComponent(eventId)) // return null or data object
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }
 
}

export default ServiceStats;
