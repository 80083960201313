import React from 'react';
import { Popup } from 'devextreme-react/popup';
import TextBox from 'devextreme-react/text-box';
import Validator, { CustomRule, ValidationRule } from 'devextreme-react/validator';
import { Button } from 'devextreme-react/button';
import { ValidationGroup } from 'devextreme-react/validation-group';
import * as dayJS from 'dayjs';
import ServiceEvents from '../../../../../api/services/ServiceEvents'
import SelectBox from 'devextreme-react/select-box';

import './PopupSubArtistName.scss'


// props
//      title: popup title (edit/save)
//
//      setValues: callable
//
//      onInitialized: callback
//      onUpdate: callback

class PopupSubArtistName extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dataSourceGigDates: [],
        };

        this.popupInstance = null;

        this.textBoxNameInstance = null;
        this.textBoxTagInstance = null;

        this.selectBoxDateInstance = null;
        this.selectedDateId = -1;

        this.validationGroupInstance = null;

        this.subArtistId = -1;
        this.subArtistEditMode = '';
        this.subArtistName = '';
        this.subArtistTag = '';

        this.buttonUpdateInstance = null;
    }

    // CALLBACKS
    onInitialized = (instance) => {
        if (this.props.onInitialized) {
            this.props.onInitialized(instance);  // callback
        }
    }

    onUpdate = (subArtistId, gigDateId, name, tag, editMode) => {
        if (this.props.onUpdate) {
            this.props.onUpdate(subArtistId, gigDateId, name, tag, editMode);  // callback
        }
    }

    // CALLABLES
    setValues = (id, gigDateId, name, tag, editMode) => {
        this.subArtistId = id;
        this.subArtistName = name;
        this.subArtistTag = tag;
        this.textBoxNameInstance.option('value', name);
        this.textBoxTagInstance.option('value', tag);
        this.subArtistEditMode = editMode.toLowerCase().trim();
        this.selectedDateId = gigDateId;
        this.selectBoxDateInstance.option('value', gigDateId);
    }

    loadDataSourceGigDates = async () => {
        var data = await ServiceEvents.getEventDatesDropDownByEventId(parseInt(sessionStorage.getItem("currentEventId"), 10), 'riders');
        if (data !== null && !data.hasOwnProperty("error")) {
            var convertedDataSourceGigDates = [];
            data.forEach((item, index, array) => {
                convertedDataSourceGigDates.push({ Id: item.Id, GigDate: dayJS(item.GigDate).format('ddd DD/MM/YY') });
            });
            this.setState({ dataSourceGigDates: convertedDataSourceGigDates});
        } else {
           // this.props.onConnectionError();  // callback
        }
    }

    // POPUP
    popup_OnInitialized = (e) => {
        this.popupInstance = e.component;
        this.onInitialized(e.component);
    }

    popup_OnShowing = async (e) => {
        if (this.subArtistEditMode == "save") {
            this.validationGroupInstance.reset();
        }

        await this.loadDataSourceGigDates();
        this.popupInstance.option('title', (this.props.title ? this.props.title : ''))
        this.selectBoxDateInstance.focus();
    }

    popup_OnShown = (e) => {
        switch (this.subArtistEditMode) {
            case 'save':
                this.popupInstance.option('title', 'Save new subartist');
                this.buttonUpdateInstance.option("text", "Save New");
                break;
            case 'update':
                this.popupInstance.option('title', 'Update ' + this.subArtistTag + '-' + this.subArtistName);
                this.buttonUpdateInstance.option("text", "Update");
                break;
            default:
                break;

        }
        this.selectBoxDateInstance.focus();
    }

    // SELECTBOX DATE
    selectBoxDate_OnInitialized = (e) => {
        this.selectBoxDateInstance = e.component;
    }

    selectBoxDate_OnSelectionChanged = async (e) => {
        this.selectedDateId = e.selectedItem !== null ? e.selectedItem.Id : -1;
    }

    // TEXTBOX NAME
    textBoxName_OnInitialized = (e) => {
        this.textBoxNameInstance = e.component;
    }
    // TEXTBOX TAG
    textBoxTag_OnInitialized = (e) => {
        this.textBoxTagInstance = e.component;
    }
    
    // BUTTON
    buttonUpdate_OnInitialized = (e) => {
        this.buttonUpdateInstance = e.component;
    }

    buttonUpdate_OnClick = (e) => {
        this.validationGroupInstance.validate();

        if (this.textBoxNameInstance.option('isValid') === true) {
            this.onUpdate(this.subArtistId, this.selectedDateId, this.textBoxNameInstance.option('value').trim(), this.textBoxTagInstance.option('value').trim(), this.subArtistEditMode);
            this.popupInstance.hide();
        } else {
            this.textBoxNameInstance.focus();
        }
    }


    // VALIDATION GROUP
    validationGroup_OnInitialized = (e) => {
        this.validationGroupInstance = e.component;
    }

    render() {
        return (
            <React.Fragment>
                <Popup
                    dragEnabled={false}
                    hideOnOutsideClick={false}
                    showCloseButton={true}
                    showTitle={true}
                    width={340}
                    height={245}
                    onInitialized={this.popup_OnInitialized}
                    onShown={this.popup_OnShown}
                    onShowing={this.popup_OnShowing}
                >
                    <ValidationGroup onInitialized={this.validationGroup_OnInitialized}>
                        <div>

                            <div className="psan-dx-field">
                                <div className="dx-field-label psan-label">Date*</div>
                                <div className="dx-field-value">
                                    <SelectBox
                                        dataSource={this.state.dataSourceGigDates}
                                        valueExpr="Id"
                                        displayExpr="GigDate"
                                        searchEnabled={true}
                                        showClearButton={true}
                                        showDataBeforeSearch={false}
                                        minSearchLength={0}
                                        width={250}
                                        onInitialized={this.selectBoxDate_OnInitialized}
                                        onSelectionChanged={this.selectBoxDate_OnSelectionChanged}
                                    >
                                        <Validator>
                                            <ValidationRule type="required" message="Required" />
                                        </Validator>
                                    </SelectBox>
                                </div>
                            </div>

                            <div className="psan-dx-field">
                                <div className="dx-field-label psan-label">Name*</div>
                                <div className="dx-field-value">
                                    <TextBox
                                        onInitialized={this.textBoxName_OnInitialized}
                                        defaultValue=''
                                        width={250}
                                        hint='Enter name ...'
                                    >
                                        <Validator>
                                            <ValidationRule type="required" message="Required" />
                                        </Validator>
                                    </TextBox>
                                </div>
                            </div>

                            <div className="psan-dx-field">
                                <div className="dx-field-label psan-label">Tag</div>
                                <div className="dx-field-value">
                                    <TextBox
                                        onInitialized={this.textBoxTag_OnInitialized}
                                        defaultValue=''
                                        width={250}
                                        hint='Enter tag ...'
                                    >
                                    </TextBox>
                                </div>
                            </div>

                            <div className='psan-buttons'>
                                <Button
                                    onInitialized={this.buttonUpdate_OnInitialized}
                                    width={120}
                                    text="Save"
                                    icon="save"
                                    onClick={this.buttonUpdate_OnClick}
                                />
                            </div>
                        </div>
                    </ValidationGroup>
                </Popup>
            </React.Fragment>
        );
    }

}

export default PopupSubArtistName

