import React from 'react';
import { RidersAdminShops } from '../../../../components';

class ridersAdminShops extends React.Component {
    render() {
        return (
            <RidersAdminShops />
        );
    }
}

export default ridersAdminShops