import globalConfig from '../../config/global.js';

class ServiceDrivers {
    ////////////////////////////////////////////////////
    // DRIVERS
    ////////////////////////////////////////////////////
    static getAllDriversDropDownByEventId = async (eventId) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Drivers/GetAllDriversDropDownByEventId?eventId=" + encodeURIComponent(eventId))
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static getDriverOverView = async (eventId) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Drivers/GetDriverOverView?eventId=" + encodeURIComponent(eventId))
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static getDriverAvailabilityByDriverId = async (driverId) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Drivers/GetDriverAvailabilityByDriverId?driverId=" + encodeURIComponent(driverId)) // return null or data object
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static doesShortNameExist = async (eventId, shortName, driverIdToExclude) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Drivers/DoesShortNameExist?eventId=" + encodeURIComponent(eventId) + "&shortName=" + encodeURIComponent(shortName) + "&driverIdToExclude=" + encodeURIComponent(driverIdToExclude) ) // return true or false
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }


    static addDriverAvailabilities = async (model) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Drivers/AddDriverAvailabilities", {
            method: 'POST',
            body: JSON.stringify(model),
            headers: {
                'Content-type': 'application/json; charset=UTF-8'
            }
        })
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(error => {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static updateDriverAvailability = async (driverAvailabilityModelList) => {
        let returnValue = {};
        await fetch(globalConfig.settings.host + "/api/Drivers/UpdateDriverAvailability", {
            method: 'POST',
            body: JSON.stringify(driverAvailabilityModelList),
            headers: {
                'Content-type': 'application/json; charset=UTF-8'
            }
        })
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(error => {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static getDriverColorByRelationId = async (relationId) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Drivers/GetDriverColorByRelationId?relationId=" + encodeURIComponent(relationId)) // return null or data object
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }


    static addDriverColors = async (model) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Drivers/AddDriverColors", {
            method: 'POST',
            body: JSON.stringify(model),
            headers: {
                'Content-type': 'application/json; charset=UTF-8'
            }
        })
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(error => {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static updateDriverColors = async (model) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Drivers/UpdateDriverColors", {
            method: 'POST',
            body: JSON.stringify(model),
            headers: {
                'Content-type': 'application/json; charset=UTF-8'
            }
        })
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(error => {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static addDriver = async (model) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Drivers/AddDriver", {
            method: 'POST',
            body: JSON.stringify(model),
            headers: {
                'Content-type': 'application/json; charset=UTF-8'
            }
        })
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(error => {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static updateDriver = async (model) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Drivers/UpdateDriver", {
            method: 'POST',
            body: JSON.stringify(model),
            headers: {
                'Content-type': 'application/json; charset=UTF-8'
            }
        })
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(error => {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

}

export default ServiceDrivers;
