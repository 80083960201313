import React from 'react';
import { Popup } from 'devextreme-react/popup';
import TextBox from 'devextreme-react/text-box';
import Validator, { CustomRule, ValidationRule } from 'devextreme-react/validator';
import { Button } from 'devextreme-react/button';
import { ValidationGroup } from 'devextreme-react/validation-group';
import SelectBox from 'devextreme-react/select-box';
import ServiceArtists from '../../../../../api/services/ServiceArtists'

import './PopupArtistName.scss'


// props
//      title: popup title (edit/save)
//
//      setValues: callable
//
//      onInitialized: callback
//      onUpdate: callback

class PopupArtistName extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dataSourceArtistTypes: [],
        };

        this.popupInstance = null;

        this.textBoxNameInstance = null;

        this.selectBoxArtistTypesInstance = null;
        this.selectedArtistTypeID = -1;


        this.validationGroupInstance = null;

        this.artistId = -1;
        this.artistEditMode = '';
        this.artistName = '';

        this.buttonUpdateInstance = null;
    }

    // CALLBACKS
    onInitialized = (instance) => {
        if (this.props.onInitialized) {
            this.props.onInitialized(instance);  // callback
        }

    }
    onUpdate = (artistId, name, artistTypeId, editMode) => {
        if (this.props.onUpdate) {
            this.props.onUpdate(artistId, name, artistTypeId, editMode);  // callback
        }
   }

    // CALLABLES
    setValues = (id, artistTypeId, name, editMode) => {
        this.artistId = id;
        this.artistName = name;
        this.textBoxNameInstance.option('value', name);
        this.selectBoxArtistTypesInstance.option('value', artistTypeId);
        this.artistEditMode = editMode.toLowerCase().trim();
    }

    loadDataSourceArtistTypes = async () => {
        var data = await ServiceArtists.getArtistTypesInfo();
        if (data !== null && !data.hasOwnProperty("error")) {
            this.setState({ dataSourceArtistTypes: data })
        } else {
          //  this.props.onConnectionError();  // callback
        }
    }

    // EVENTS
    componentDidMount = async () => {
        this.loadDataSourceArtistTypes()
    }

    // POPUP
    popup_OnInitialized = (e) => {
        this.popupInstance = e.component;
        this.onInitialized(e.component);
    }

    popup_OnShowing = (e) => {
        if (this.artistEditMode == "save") {
            this.validationGroupInstance.reset();
        }
        this.popupInstance.option('title', (this.props.title ? this.props.title : ''))
        this.textBoxNameInstance.focus();
    }

    popup_OnShown = (e) => {
        switch (this.artistEditMode) {
            case 'save':
                this.popupInstance.option('title', 'Add new artist');
                break;
            case 'update':
                this.popupInstance.option('title', 'Update ' + this.artistName);
                break;
            default:
                break;
        }
        this.textBoxNameInstance.focus();
    }


    // TEXTBOX
    textBoxName_OnInitialized = (e) => {
        this.textBoxNameInstance = e.component;
    }

    // SELECTBOX
    selectBoxArtistTypes_OnInitialized = (e) => {
        this.selectBoxArtistTypesInstance = e.component;
    }

    selectBoxArtistTypes_OnSelectionChanged = async (e) => {
        this.selectedArtistTypeID = e.selectedItem !== null ? e.selectedItem.Id : -1;
    }

    // BUTTON
    buttonUpdate_OnInitialized = (e) => {
        this.buttonUpdateInstance = e.component;
    }
    buttonUpdate_OnClick = (e) => {
        this.validationGroupInstance.validate();

        if (this.textBoxNameInstance.option('isValid') === true) {
            this.onUpdate(this.artistId, this.textBoxNameInstance.option('value').trim(), this.selectedArtistTypeID, this.artistEditMode);
            this.popupInstance.hide();
        } else {
            this.textBoxNameInstance.focus();
        }
    }

    // VALIDATION GROUP
    validationGroup_OnInitialized = (e) => {
        this.validationGroupInstance = e.component;
    }

    render() {
        return (
            <React.Fragment>
                <Popup
                    dragEnabled={false}
                    hideOnOutsideClick={false}
                    showCloseButton={true}
                    showTitle={true}
                    width={340}
                    height={205}
                    onInitialized={this.popup_OnInitialized}
                    onShown={this.popup_OnShown}
                    onShowing={this.popup_OnShowing}
                >
                    <ValidationGroup onInitialized={this.validationGroup_OnInitialized}>
                        <div>
                            <div className="pas-dx-field">
                                <div className="dx-field-label pas-label">Name*</div>
                                <div className="dx-field-value">
                                    <TextBox
                                        onInitialized={this.textBoxName_OnInitialized}
                                        width={250}
                                        defaultValue=''
                                        hint='Enter name ...'
                                    >
                                        <Validator>
                                            <ValidationRule type="required" message="Required" />
                                        </Validator>
                                    </TextBox>
                                </div>
                            </div>
                            <div className="pas-dx-field">
                                <div className="dx-field-label pas-label">Type*</div>
                                <div className="dx-field-value">
                                    <SelectBox
                                        dataSource={this.state.dataSourceArtistTypes}
                                        valueExpr="Id"
                                        displayExpr="Name"
                                        width={250}
                                        searchEnabled={false}
                                        showClearButton={false}
                                        showDataBeforeSearch={false}
                                        minSearchLength={0}
                                        onInitialized={this.selectBoxArtistTypes_OnInitialized}
                                        onSelectionChanged={this.selectBoxArtistTypes_OnSelectionChanged}
                                    />
                                </div>
                            </div>

                            <div className='pas-buttons'>
                                <Button
                                    onInitialized={this.buttonUpdate_OnInitialized}
                                    width={120}
                                    text="Save"
                                    icon="save"
                                    onClick={this.buttonUpdate_OnClick}
                                />
                            </div>

                        </div>
                    </ValidationGroup>


                </Popup>
            </React.Fragment>

        );
    }

}

export default PopupArtistName

