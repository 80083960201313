import React from 'react';
import ServiceTrips from '../../../../api/services/ServiceTrips'
import ServiceLocations from '../../../../api/services/ServiceLocations'
import ServiceDrivers from '../../../../api/services/ServiceDrivers'
import ServiceArtists from '../../../../api/services/ServiceArtists'
import ServiceFleet from '../../../../api/services/ServiceFleet'

import globalConfig from '../../../../config/global.js';

import './ShuttlesDriverSheet.scss'


// props
//      subtrips (required): array of subtrips
//
//      onLoadError: callback
//
//      setData: callable

const PageWidth = 1170;

class ShuttlesDriverSheet extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            driverSheetData: [],
        }

        this.dataSourceTrip = [];

        this.dropoffDisplay = 'none';
        this.pickupWaitTimeDisplay = 'none';
        this.dropoffWaitTimeDisplay = 'none';
        this.dropoffWaitTimeDisplay2 = 'none';
        this.notesDisplay = 'none';
    }


    // EVENTS
    componentDidMount = async () => {

    }

    // CALLBACKS
    onLoadError = () => {
        if (this.props.onLoadError) {
            this.props.onLoadError();  // callback
        }
    }

    // CALLABLE
    setData = async (id) => {
        await this.loadDataSourceTrip(id);

        this.setState({ driverSheetData: this.convertTripData() })
    }

    convertTripData = () => {
        let sheetData = {
            Trip: '',
            PickupDate: '',
            PickupLocationName: '',
            PickupLocationAddress: '',
            PickupWaitTime: '',
            DropOffLocationName: '',
            DropOffLocationAddress: '',
            DropOffWaitTime: '',
            DropOffLocationName2: '',
            DropOffLocationAddress2: '',
            DropOffWaitTime2: '',
            Artist: '',
            Driver: '',
            Car: '',
            Pax: '',
            TravelTime: '',
            Notes: '',
    }

        let trip = '';
        let pickupLocationAddress = '';
        for (var i = 0; i < this.dataSourceTrip.SubTrips.length; i++) {
            if (this.dataSourceTrip.SubTrips[i].IsPickupDeparture === true) {
                trip = this.dataSourceTrip.SubTrips[i].LocationDeparture;
                const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
                const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
                let dt = new Date(this.dataSourceTrip.SubTrips[i].DateTimeDeparture);
               // sheetData.PickupDate = weekday[dt.getDay()] + ' ' + dt.getDate().toString().padStart(2, '0') + '-' + month[dt.getMonth()] + '-' + dt.getFullYear() + ' - ' + dt.getHours().toString().padStart(2, '0') + ':' + dt.getMinutes().toString().padStart(2, '0');
                sheetData.PickupDate = weekday[dt.getDay()] + ' ' + dt.getDate().toString().padStart(2, '0') + '/' + (dt.getMonth()+1).toString().padStart(2, '0') + ' ' + dt.getHours().toString().padStart(2, '0') + ':' + dt.getMinutes().toString().padStart(2, '0');
                sheetData.PickupLocationName = this.dataSourceTrip.SubTrips[i].LocationDeparture;
                pickupLocationAddress = (this.dataSourceTrip.SubTrips[i].LocationDepartureStreet ? this.dataSourceTrip.SubTrips[i].LocationDepartureStreet : '');
                pickupLocationAddress = pickupLocationAddress + (pickupLocationAddress !== '' ? ' ' : '') + (this.dataSourceTrip.SubTrips[i].LocationDepartureStreetNumber ? this.dataSourceTrip.SubTrips[i].LocationDepartureStreetNumber : '');
                pickupLocationAddress = pickupLocationAddress + (pickupLocationAddress !== '' ? ', ' : '') + (this.dataSourceTrip.SubTrips[i].LocationDepartureZipCode ? this.dataSourceTrip.SubTrips[i].LocationDepartureZipCode : '');
                pickupLocationAddress = pickupLocationAddress + (pickupLocationAddress !== '' ? ' ' : '') + (this.dataSourceTrip.SubTrips[i].LocationDepartureCity ? this.dataSourceTrip.SubTrips[i].LocationDepartureCity : '');
                sheetData.PickupLocationAddress = pickupLocationAddress;
                break;
            } 
        }
         
        let countDropoff = 0;
        let dropoffLocationAddress = '';
        let dropoffLocationAddress2 = '';
        let tripTimings = '';
        for (var i = 0; i < this.dataSourceTrip.SubTrips.length; i++) {
            if (this.dataSourceTrip.SubTrips[i].IsDropOffArrival === true) {
                this.dropoffDisplay = 'none';
                trip += ' - ' + this.dataSourceTrip.SubTrips[i].LocationArrival;
                countDropoff += 1;
                switch (countDropoff) {
                    case 1:
                        sheetData.DropOffLocationName = this.dataSourceTrip.SubTrips[i].LocationArrival;
                        dropoffLocationAddress = (this.dataSourceTrip.SubTrips[i].LocationArrivalStreet ? this.dataSourceTrip.SubTrips[i].LocationArrivalStreet : '');
                        dropoffLocationAddress = dropoffLocationAddress +(dropoffLocationAddress !== '' ? ' ' : '') +  (this.dataSourceTrip.SubTrips[i].LocationArrivalStreetNumber ? this.dataSourceTrip.SubTrips[i].LocationArrivalStreetNumber : '');
                        dropoffLocationAddress = dropoffLocationAddress +(dropoffLocationAddress !== '' ? ', ' : '') +  (this.dataSourceTrip.SubTrips[i].LocationArrivalZipCode ? this.dataSourceTrip.SubTrips[i].LocationArrivalZipCode : '');
                        dropoffLocationAddress = dropoffLocationAddress + (dropoffLocationAddress !== '' ? ' ' : '') +  (this.dataSourceTrip.SubTrips[i].LocationArrivalCity ? this.dataSourceTrip.SubTrips[i].LocationArrivalCity : '');
                        sheetData.DropOffLocationAddress = dropoffLocationAddress;
                        tripTimings = 'trip 1: ' + (this.dataSourceTrip.SubTrips[i].MinutesMin !== this.dataSourceTrip.SubTrips[i].MinutesMax ? this.dataSourceTrip.SubTrips[i].MinutesMin + ' - ' + this.dataSourceTrip.SubTrips[i].MinutesMax + ' min' : this.dataSourceTrip.SubTrips[i].MinutesMax + ' min');
                        break;
                    case 2:
                        sheetData.DropOffLocationName2 = this.dataSourceTrip.SubTrips[i].LocationArrival;
                        dropoffLocationAddress2 = (this.dataSourceTrip.SubTrips[i].LocationArrivalStreet ? this.dataSourceTrip.SubTrips[i].LocationArrivalStreet : '');
                        dropoffLocationAddress2 = dropoffLocationAddress2 +(dropoffLocationAddress2 !== '' ? ' ' : '') + (this.dataSourceTrip.SubTrips[i].LocationArrivalStreetNumber ? this.dataSourceTrip.SubTrips[i].LocationArrivalStreetNumber : '');
                        dropoffLocationAddress2 = dropoffLocationAddress2 + (dropoffLocationAddress2 !== '' ? ', ' : '') + (this.dataSourceTrip.SubTrips[i].LocationArrivalZipCode ? this.dataSourceTrip.SubTrips[i].LocationArrivalZipCode : '');
                        dropoffLocationAddress2 = dropoffLocationAddress2 +  (dropoffLocationAddress2 !== '' ? ' ' : '') +  (this.dataSourceTrip.SubTrips[i].LocationArrivalCity ? this.dataSourceTrip.SubTrips[i].LocationArrivalCity : '');
                        sheetData.DropOffLocationAddress2 = dropoffLocationAddress2;
                        this.dropoffDisplay = 'flex';
                        tripTimings += ' / trip 2: ' + (this.dataSourceTrip.SubTrips[i].MinutesMin !== this.dataSourceTrip.SubTrips[i].MinutesMax ? this.dataSourceTrip.SubTrips[i].MinutesMin + ' - ' + this.dataSourceTrip.SubTrips[i].MinutesMax + ' min' : this.dataSourceTrip.SubTrips[i].MinutesMax + ' min');
                        break;
                    default:
                        break;
                }
            }
        }

        if (tripTimings.search('trip 2:') === -1) {
            tripTimings = tripTimings.replace('trip 1: ', '');
        }

        let searchStart = false;
        let waitCount = 0;
        this.dropoffWaitTimeDisplay = 'none';
        this.dropoffWaitTimeDisplay2 = 'none';
        this.pickupWaitTimeDisplay = 'none'
        for (var i = 0; i < this.dataSourceTrip.SubTrips.length; i++) {
            if (this.dataSourceTrip.SubTrips[i].IsPickupDeparture === true) {
                searchStart = true;
            }
            if (searchStart === true) {
                waitCount += 1;
                switch (waitCount) {
                    case 1:
                        sheetData.PickupWaitTime = this.dataSourceTrip.SubTrips[i].WaitTimePickup
                        if (this.dataSourceTrip.SubTrips[i].WaitTimePickup > 0) {
                            this.pickupWaitTimeDisplay = 'flex';
                        }
                        break;
                    case 2:
                        sheetData.DropOffWaitTime = this.dataSourceTrip.SubTrips[i].WaitTimePickup
                        if (this.dataSourceTrip.SubTrips[i].WaitTimePickup > 0) {
                            this.dropoffWaitTimeDisplay = 'flex';
                        }
                        break;
                    case 3:
                        sheetData.DropOffWaitTime2 = this.dataSourceTrip.SubTrips[i].WaitTimePickup
                        if (this.dataSourceTrip.SubTrips[i].WaitTimePickup > 0) {
                            this.dropoffWaitTimeDisplay2 = 'flex';
                        }
                        break;
                    default:
                        break;
                }
            }

        }
        sheetData.Trip = trip;
        sheetData.Artist = this.dataSourceTrip.ArtistName;
        sheetData.Driver = this.dataSourceTrip.DriverName;
        sheetData.Car = this.dataSourceTrip.CarName;
        sheetData.Pax = this.dataSourceTrip.Pax;
        sheetData.TravelTime = tripTimings;
        sheetData.Notes = this.dataSourceTrip.Notes;

        this.notesDisplay = (this.dataSourceTrip.Notes !== null && this.dataSourceTrip.Notes !== '' ? 'block' : 'none');

        return sheetData;

    }

    loadDataSourceTrip = async (id) => {
        var data = await ServiceTrips.getTripInfoById(id);
        if (data !== null && !data.hasOwnProperty("error")) {
            this.dataSourceTrip = data;
        } else {
            this.onLoadError();  // callback
        }
    }

    renderNotes = () => {
        return <div dangerouslySetInnerHTML={{ __html: this.state.driverSheetData.Notes }} />;
    }

    render() {
        return (
            <React.Fragment>
                <div className="sds-main-div">
                    <div className="sds-image-div">
                        <img src={process.env.PUBLIC_URL + globalConfig.settings.images.driverSheetImagesPath.replace(/^\+$|"\/+$/g, '') + "/skully_2024.png"} />
                    </div>
                    <div className="sds-data-driver-div">Driver:&nbsp;{this.state.driverSheetData.Driver}</div>
                    <div className="sds-data-div">
                        <div className="sds-left-div">Trip</div>
                        <div className="sds-right-div">{this.state.driverSheetData.Trip}</div>
                    </div>
                    <div className="sds-data-div">
                        <div className="sds-left-div">Artist</div>
                        <div className="sds-right-div">
                            <div className="sds-right-div-artist">{this.state.driverSheetData.Artist}</div>
                            <div className="sds-right-div-pax">Pax: {this.state.driverSheetData.Pax}</div>
                        </div>
                    </div>
                    <div className="sds-data-3-lines-div">
                        <div className="sds-left-div">Pickup</div>
                        <div className="sds-right-div">
                            <div className="sds-right-div-pickup">{this.state.driverSheetData.PickupDate}<br />{this.state.driverSheetData.PickupLocationName}<br />{this.state.driverSheetData.PickupLocationAddress}</div>
                            <div className="sds-right-div-wait" style={{ display: this.pickupWaitTimeDisplay }}>Wait: {this.state.driverSheetData.PickupWaitTime} min</div>
                        </div>
                    </div>
                    <div className="sds-data-2-lines-div">
                        <div className="sds-left-div">Dropoff</div>
                        <div className="sds-right-div">
                            <div className="sds-right-div-dropoff">{this.state.driverSheetData.DropOffLocationName}<br />{this.state.driverSheetData.DropOffLocationAddress}</div>
                            <div className="sds-right-div-wait" style={{ display: this.dropoffWaitTimeDisplay }}>Wait: {this.state.driverSheetData.DropOffWaitTime} min</div>
                        </div>
                    </div>
                    <div className="sds-data-2-lines-div" style={{ display: this.dropoffDisplay }}>
                        <div className="sds-left-div">Dropoff</div>
                        <div className="sds-right-div">
                            <div className="sds-right-div-dropoff">{this.state.driverSheetData.DropOffLocationName2}<br />{this.state.driverSheetData.DropOffLocationAddress2}</div>
                            <div className="sds-right-div-wait" style={{ display: this.dropoffWaitTimeDisplay2 }}>Wait: {this.state.driverSheetData.DropOffWaitTime2} min</div>
                        </div>
                    </div>
                    <div className="sds-data-div">
                        <div className="sds-left-div">Car</div>
                        <div className="sds-right-div">{this.state.driverSheetData.Car}</div>
                    </div>
                    <div className="sds-data-travel-time-div">
                        <div className="sds-left-div">Traveltime</div>
                        <div className="sds-right-div">{this.state.driverSheetData.TravelTime}</div>
                    </div>
                    <div style={{ display: this.notesDisplay }}>
                        <div className="page-break" />
                        {this.renderNotes()}
                    </div>

                </div>
            </React.Fragment>
 


        );
    }
}

export default ShuttlesDriverSheet
