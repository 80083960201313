import { HubConnectionBuilder } from '@microsoft/signalr';
import { Button } from 'devextreme-react/button';
import React from 'react';

import ServiceArtists from '../../../../api/services/ServiceArtists';

import HxToast from '../../../custom-components/hx-toast/HxToast'
import HxDialog from '../../../custom-components/hx-dialog/HxDialog'
import HxPopupExportExcel from '../../../custom-components/hx-popup-excel-export/HxPopupExportExcel';

import HxErrorMessage from '../../../custom-components/hx-error-message/HxErrorMessage';

import DataGridArtists from './sub-components/DataGridArtists';
import PopupArtistName from './sub-components/PopupArtistName';

import './RidersAdminArtists.scss';


class RidersAdminArtists extends React.Component {
    constructor(props) {
        super(props);

        // popup
        this.popupArtistNameInstance = null;

        this.popupExportArtistsInstance = null;

        this.dataGridArtistsInstance = null;
        
        this.hubConnection = null;

    }

    componentDidMount = async () => {
        this.hubConnection = new HubConnectionBuilder().withUrl("/artistsHub").build();

        await this.hubConnection.start()
            .then(function () {
            })
            .catch(function (err) {
                return console.error(err.toString());
            });
    }


    // GRID
    dataGridArtists_OnInitialized = (instance) => {
        this.dataGridArtistsInstance = instance;
    }

    dataGridArtists_OnExportArtist = () => {
        this.exportArtist();
    }

    dataGridArtists_OnConnectionError = () => {
        this.setErrorHeader(true);
    }

    dataGridArtists_OnEditArtist = (id, name, artistTypeId) => {
        this.refPopupArtistName.setValues(id, artistTypeId, name, 'update');
        this.popupArtistNameInstance.show();
    }

    dataGridArtists_OnDeleteArtist = async (id, name) => {
        var dataCanBeDeleted = await ServiceArtists.checkIfArtistCanBeDeleted(id);
        if (dataCanBeDeleted == true) {
            var result = await HxDialog.showDialogYesNo('Delete artist', 'Delete artist ' + name + '?')
            if (result === true) {
                var data = await ServiceArtists.deleteArtist(id);
                if (data !== null) {
                    if (!data.hasOwnProperty("error")) {
                        if (data) {
                            this.refDataGridArtists.gridRefresh();
                            this.hubConnection
                                .invoke("SendUpdateInfoDeletedArtist", id)
                                .catch(function (err) {
                                    return console.error(err.toString());
                                });
                            HxToast.showToast('Artist deleted.', 'success', 'bottom right', 'up-stack');
                        } else {
                            HxToast.showToast('Delete artist failed!', 'error', 'bottom right', 'up-stack');
                        }
                    } else {
                        this.setErrorHeader(true);
                    }
                } else {
                    HxToast.showToast('Delete artist failed!', 'error', 'bottom right', 'up-stack');
               }
            }
        } else {
            HxToast.showToast('Artist can not be deleted.', 'warning', 'bottom right', 'up-stack');
        }
    }

    // POPUP EXPORT
    popupExportArtists_OnInitialized = (instance) => {
        this.popupExportArtistsInstance = instance;
    }

    popupExportArtists_OnSave = async (fileName) => {
        if (fileName) {
            await this.refDataGridArtists.exportToExcel(fileName);
        }
    }

    exportArtist = () => {
        this.popupExportArtistsInstance.show();
    }

    popupArtistName_OnInitialized = (instance) => {
        this.popupArtistNameInstance = instance;
    }

    popupArtistName_OnUpdate = async (id, name, artistTypeId, editMode) => {
        switch (editMode.toLowerCase().trim()) {
            case 'save':
                var model = {
                    Id: -1,
                    EventId: parseInt(sessionStorage.getItem("currentEventId"), 10),
                    ColorId: null,
                    ArtistTypeId: artistTypeId,
                    Name: name,
                    UseInRiders: true,
                    UseInShuttles: false,
                }
                await this.onAddArtistCallBack(model);
                break;
            case 'update':
                var model = {
                    Id: id,
                    EventId: parseInt(sessionStorage.getItem("currentEventId"), 10),
                    ColorId: null,
                    ArtistTypeId: artistTypeId,
                    Name: name,
                    UseInRiders: true,
                    UseInShuttles: false,
                }
                await this.onUpdateArtistCallBack(model);
                break;
            default:
                break;
        }
    }

    onUpdateArtistCallBack = async (artistInfoModel) => {
        await this.updateArtist(artistInfoModel);
        this.refDataGridArtists.gridRefresh();
    }

    onAddArtistCallBack = async (artistInfoModel) => {
        var id = await this.addArtist(artistInfoModel);
        this.refDataGridArtists.gridRefresh();
    }

    updateArtist = async (artistInfoModel) => {
        let resultName = false;
        let resultType = false;
        var data = await ServiceArtists.updateArtistName(artistInfoModel);
        if (data !== null) {
            if (!data.hasOwnProperty("error")) {
                if (data === true) {
                    this.refDataGridArtists.gridRefresh();
                    this.hubConnection
                        .invoke("SendUpdateInfoEditedArtist", artistInfoModel.Id, true)
                        .catch(function (err) {
                            return console.error(err.toString());
                        });
                    resultName = true;
                    resultType = await this.updateArtistArtistType(artistInfoModel);
                } 
            } else {
                this.setErrorHeader(true);
            }
        }

        if (resultName && resultType) {
            HxToast.showToast('Artist updated.', 'success', 'bottom right', 'up-stack');
        } else {
            HxToast.showToast('Update artist failed!', 'error', 'bottom right', 'up-stack');
        }

    }

    updateArtistArtistType = async (artistInfoModel) => {
        let result = false;

        var data = await ServiceArtists.updateArtistArtistType(artistInfoModel);
        if (data !== null) {
            if (!data.hasOwnProperty("error")) {
                if (data === true) {
                    this.refDataGridArtists.gridRefresh();
                    this.hubConnection
                        .invoke("SendUpdateInfoEditedArtist", artistInfoModel.Id, true)
                        .catch(function (err) {
                            return console.error(err.toString());
                        });
                    result = true;
                } 
            } else {
                this.setErrorHeader(true);
            }
        }

        return result;
    }

    addArtist = async (artistInfoModel) => {
        var data = await ServiceArtists.addArtist(artistInfoModel);
        if (data !== null) {
            if (!data.hasOwnProperty("error")) {
                if (data !== -1) {
                    this.refDataGridArtists.gridRefresh();
                    this.hubConnection
                        .invoke("SendUpdateInfoAddedArtist", artistInfoModel.Id)
                        .catch(function (err) {
                            return console.error(err.toString());
                        });
                    HxToast.showToast('Artist saved.', 'success', 'bottom right', 'up-stack');
                } else {
                    HxToast.showToast('Save artist failed!', 'error', 'bottom right', 'up-stack');
                }
            } else {
                this.setErrorHeader(true);
            }
        } else {
            HxToast.showToast('Save artist failed!', 'error', 'bottom right', 'up-stack');
        }

        return data;
    }

    buttonNew_OnClick = () => {
        this.refPopupArtistName.setValues(-1, 1, '', 'save');
        this.popupArtistNameInstance.show();
    }

    // ERROR MESSAGE
    setErrorHeader = (show) => {
        if (show) {
            this.refHxErrorMessage.show();
        } else {
            this.refHxErrorMessage.hide();
        }
    }

    render() {
        return (
            <div>
                <HxErrorMessage
                    ref={ref => this.refHxErrorMessage = ref}
                    id='error-message-artists'
                    message='Could not connect to database!'
                    visible={false}
                />
                <div id='toolBarArtists' className='hx-toolbar-main-div'>
                    <div className='hx-toolbar-left'>
                        <span>
                            <Button
                                icon='xlsxfile'
                                stylingMode='outlined'
                                text='Artists'
                                onClick={this.exportArtist}
                            />
                        </span>
                    </div>
                    <div className='hx-toolbar-right'>
                    </div>
                </div>

                <div className='artists-content-block'>
                    <div>
                        <Button
                            text="Add artist"
                            icon="add"
                            onClick={this.buttonNew_OnClick} />
                    </div>
                    <div className='artists-grid-card'>
                        <DataGridArtists
                            ref={ref => this.refDataGridArtists = ref}
                            onEditArtist={this.dataGridArtists_OnEditArtist}
                            onDeleteArtist={this.dataGridArtists_OnDeleteArtist}
                            onInitialized={this.dataGridArtists_OnInitialized}
                            onExportArtist={this.dataGridArtists_OnExportArtist}
                            onConnectionError={this.dataGridArtists_OnConnectionError}
                        />
                    </div>
                </div>

                <HxPopupExportExcel
                    defaultName='Artists'
                    onInitialized={this.popupExportArtists_OnInitialized}
                    onSave={this.popupExportArtists_OnSave}
                />

                <PopupArtistName
                    ref={ref => this.refPopupArtistName = ref}
                    title='Save artist ...'
                    onInitialized={this.popupArtistName_OnInitialized}
                    onUpdate={this.popupArtistName_OnUpdate}
                />
            </div>
        );
    }
}

export default RidersAdminArtists

