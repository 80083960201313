import React, { useState, useEffect } from 'react';
import './HxButton.scss'

function HxButton({ width, height, action, text, onClick }) {

    const style = () => ({
        width: (width ? width : 20),
        height: (height ? height : 20)
    });


    function button_OnClick(e) {
        if (onClick) {
            onClick(e);
        }
    }

    useEffect(() => {

    });

    if (action) {
        switch (action.toLowerCase()) {
            case 'add':
                return <span className="hx-button dx-button" style={style()} onClick={button_OnClick}>
                            <span className="hx-image dx-icon-add"></span>
                            <span>{text}</span>
                        </span>   
                break;
            case 'edit':
                return <span className="hx-button dx-button" style={style()} onClick={button_OnClick}>
                            <span className="hx-image dx-icon-edit"></span>
                            <span>{text}</span>
                        </span>
                break;
            case 'delete':
                return <span className="hx-button dx-button" style={style()} onClick={button_OnClick}>
                            <span className="hx-image dx-icon-trash"></span>
                            <span>{text}</span>
                        </span>
                 break;
            case 'clear':
                return <span className="hx-button dx-button" style={style()} onClick={button_OnClick}>
                            <span className="hx-image dx-icon-clear"></span>
                            <span>{text}</span>
                        </span>
                 break;
            default:
                return <span className="hx-button-image dx-button" style={style()} onClick={button_OnClick}>{text}</span>
                break;
        }

    } else {
        return <span className="hx-button dx-button" style={style()} onClick={button_OnClick}>{text}</span>
    }

}

export default HxButton

