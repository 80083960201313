import React from 'react';
import { Popup } from 'devextreme-react/popup';
import TextBox from 'devextreme-react/text-box';
import Validator, { CustomRule, ValidationRule } from 'devextreme-react/validator';
import { Button } from 'devextreme-react/button';
import { ValidationGroup } from 'devextreme-react/validation-group';

import './PopupExportExcel.scss'

import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';

import validFilename from 'valid-filename';

// props
//      gridInstance: instance of grid to export
//      subArtistColors
//
//      onInitialized: callback
     


function createNewFileName() {
    let d = new Date();
    return 'Rider_' + d.getDate().toString().padStart(2, '0') + '_' + (d.getMonth() + 1).toString().padStart(2, '0') + '_' + d.getFullYear() + '.xlsx';
}


class PopupExportExcel extends React.Component {
    constructor(props) {
        super(props);

        this.dataGridInstance = null;

        this.popupInstance = null;

        this.textBoxNameInstance = null;
        this.validationGroupInstance = null;

        this.fileName = 'Rider';

        this.userSettings = {
            UserId: parseInt(sessionStorage.getItem("currentUserId"), 10),
            DefaultViewId: -1,
            DefaultFilterId: -1,
            ExportColors: true,
            MultiSelectionGigDates: false,
            MultiSelectionArtists: true,
            UseBlanksForZeroes: true,
            UseColorsProducts: true,
            UseColorsArtists: true,
            ColorOriginProducts: 'shop',
            ColorOriginArtists: 'artisttype',
            ShowSelectedCell: false,
            UseAdvancedFiltering: false,
        }
    }

    // CALLABLES
    setDataSourceSubArtistColors = (dataSource) => {
        this.dataSourceSubArtistColors = dataSource;
    }

    setDataGridInstance = (instance) => {
        this.dataGridInstance = instance;
    }

    setUserSettings = async (settings) => {
        this.userSettings = settings;
    }

    // POPUP
    popup_OnInitialized = (e) => {
        this.popupInstance = e.component;
        this.props.onInitialized(e.component);  // callback
    }

    popup_OnShowing = (e) => {
        this.fileName = createNewFileName();
    }

    popup_OnShown = (e) => {
        this.textBoxNameInstance.option('value', this.fileName);

        document.getElementById('textBoxFileNameInput').select();

        this.textBoxNameInstance.focus();
    }

    // TEXTBOX NAME
    textBoxAttributes = {
        id: 'textBoxFileNameInput',
    }

    textBoxName_OnInitialized = (e) => {
        this.textBoxNameInstance = e.component;
    }

    validationGroup_OnInitialized = (e) => {
        this.validationGroupInstance = e.component;
    }


    buttonSave_OnClick = (e) => {
        this.validationGroupInstance.validate();

        if (this.textBoxNameInstance.option('isValid') === true) {
            this.exportToExcel(this.textBoxNameInstance.option('value').trim().replace(/.xlsx$/gi, '') + '.xlsx');
            this.popupInstance.hide();
        } else {
            this.textBoxNameInstance.focus();
        }
    }

    validateFileName = () => {
        let isValid = false;

        if (validFilename(this.fileName)) {
            isValid = true;
        }

        return isValid;
    }

    exportToExcel = (name) => {
        const fileName = name;
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Sheet 1');

        worksheet.columns = [
            { width: 100 }, { width: 20 }, { width: 20 }, { width: 30 }, { width: 20 }, { width: 30 }, { width: 20 }, { width: 30 }, { width: 20 }, { width: 30 }, { width: 20 }, { width: 30 }, { width: 20 }, { width: 30 }, { width: 20 }, { width: 30 }, { width: 20 }, { width: 30 }, { width: 20 }, { width: 30 }, { width: 20 }, { width: 30 },
            { width: 20 }, { width: 30 }, { width: 20 }, { width: 30 }, { width: 20 }, { width: 30 }, { width: 20 }, { width: 30 }, { width: 20 }, { width: 30 }, { width: 20 }, { width: 30 }, { width: 20 }, { width: 30 }, { width: 20 }, { width: 30 }, { width: 20 }, { width: 30 }, { width: 20 }, { width: 30 }
        ];


        try {
            exportDataGrid({
                component: this.dataGridInstance,
                worksheet: worksheet,
                customizeCell: this.customizeExcelCell,
                keepColumnWidths: false
            })
                .then(function () {
                    workbook.xlsx.writeBuffer()
                        .then(function (buffer) {
                            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), fileName);
                        });
                })
        } catch (e) {
            var xxx = e;
        }

            //.then(function () {
            //    adaptedGrid.filter("_SUBARTIST_1_1_1_Amount", "<>", 10);
            //});
    }



    // set excel cell layout
    customizeExcelCell = (options) => {
        const { gridCell, excelCell } = options;

        if (gridCell.rowType === 'data') {
            if (this.userSettings.ExportColors) {
                if (gridCell.column.dataField === 'Shops' || gridCell.column.dataField === 'ProductName' || gridCell.column.dataField === 'ProductTypeName') {
                    let bg = (gridCell.data.ColorHex !== null ? gridCell.data.ColorHex.replace('#', '').slice(0, 6) : 'FFFFFF');
                    let fg = (gridCell.data.ForeColorHex !== null ? gridCell.data.ForeColorHex.replace('#', '').slice(0, 6) : '000000');
                    excelCell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: bg }
                    };
                    excelCell.font = {
                        color: { argb: fg }
                    }
                    excelCell.border = {
                        bottom: { style: 'thin', color: { argb: 'FF000000' } }
                    };
                    
                } else if (gridCell.column.caption !== '') {
                    excelCell.border = {
                        bottom: { style: 'thin', color: { argb: 'FF000000' } }
                    };
                }
            } else if (gridCell.column.caption !== '') {
                excelCell.border = {
                    bottom: { style: 'thin', color: { argb: 'FF000000' } }
                };
            }
        } else if (gridCell.rowType === 'header') {
            if (this.userSettings.ExportColors) {
                if (gridCell.column.caption !== '' && gridCell.column.dataField !== undefined && gridCell.column.dataField.startsWith('_SUBARTIST') && this.dataSourceSubArtistColors.length > 0) {
                    let bg = 'FFFFFF';
                    let fg = '000000';
                    let saId = parseInt(gridCell.column.dataField.split("_")[2], 10);

                    let index = this.dataSourceSubArtistColors.findIndex(subArtistId => subArtistId.SubArtistId === saId);
                    if (index > -1) {
                        bg = this.dataSourceSubArtistColors[index].ColorHex.replace('#', '').slice(0, 6);
                        fg = this.dataSourceSubArtistColors[index].ForeColorHex.replace('#', '').slice(0, 6);
                    }
                    excelCell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: bg }
                    };
                    excelCell.font = {
                        color: { argb: fg }
                    }
                } else if (gridCell.column.caption !== '') {
                    excelCell.border = {
                        bottom: { style: 'thin', color: { argb: 'FF000000' } }
                    };
                }
            } else if (gridCell.column.caption !== '') {
                excelCell.border = {
                    bottom: { style: 'thin', color: { argb: 'FF000000' } }
                };
            }

            // ownerband means: has band
            //if (gridCell.column.ownerBand) {
            //if (isNaN(gridCell.column.dataField) === false && this.subArtistColors.length > 0) {
            //        let bg = 'FFFFFF';
            //        let fg = '000000';

            //        let index = this.subArtistColors.findIndex(subArtistId => subArtistId.SubArtistId === parseInt(gridCell.column.dataField, 10));
            //        if (index > -1) {
            //            bg = this.subArtistColors[index].ColorHex.replace('#', '');
            //            fg = this.subArtistColors[index].ForeColorHex.replace('#', '');
            //        }

            //        excelCell.fill = {
            //            type: 'pattern',
            //            pattern: 'solid',
            //            fgColor: { argb: bg }
            //        };
            //        excelCell.font = {
            //            color: { argb: fg }
            //        }
            //    }
            //}
        }
    }

    render() {
        return (
            <React.Fragment>
                <Popup
                    dragEnabled={false}
                    hideOnOutsideClick={false}
                    showTitle={true}
                    title='Save as ...'
                    width={300}
                    height={155}
                    showCloseButton={true}
                    onInitialized={this.popup_OnInitialized}
                    onShowing={this.popup_OnShowing}
                    onShown={this.popup_OnShown}
                //onHiding={this.popup_OnHiding}
                >
                    <ValidationGroup onInitialized={this.validationGroup_OnInitialized}>
                        <div>
                            <div>
                                <div>
                                    <TextBox
                                        onInitialized={this.textBoxName_OnInitialized}
                                        defaultValue=''
                                        inputAttr={this.textBoxAttributes}
                                        hint='Enter name ...'
                                    >
                                        <Validator>
                                            <ValidationRule type="required" message="Required" />
                                            <CustomRule validationCallback={this.validateFileName} message="Invalid file name" />
                                        </Validator>
                                    </TextBox>
                                </div>
                            </div>

                            <div className='pee-button'>
                                <Button
                                    width={120}
                                    text="Export"
                                    className='dx-button'
                                    onClick={this.buttonSave_OnClick}
                                />
                            </div>

                        </div>
                    </ValidationGroup>


                </Popup>
            </React.Fragment>
        );
    }
}

export default PopupExportExcel

