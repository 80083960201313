//        host: "https://gmm.crossingcamel.be"
//        host: "https://localhost:44398"

const globalConfig = {
    settings: {
        host: "https://gmm.crossingcamel.be",
        images: {
            userImagesPath: '/images/relations/users/',
            driverImagesPath: '/images/relations/drivers/',
            artistContactImagesPath: '/images/relations/artist-contacts/',
            relationsOtherImagesPath: '/images/relations/other/',
            driverSheetImagesPath: '/images/shuttles/driver-sheet/',
        },
        login: {
            allowCreateAccount: false,
            allowResetPassword: false,
            allowChangePassword: true
        },
        geoCoding: {
            geoCodingHost: "https://dev.virtualearth.net/REST/v1",
            geoCodingMapProvider: 'bing',
            geoCodingMapKey: 'aT1auSQRpZwluq9PAQQc~6YZtMXnPRqzPDVevj7aeSw~AuINK7XZ8EJubeTRmdcB_Jo_kICaeo5sOjv3zcxm-WfZjtDWrkxSuuEF90h4TaKU',
        },
    }
};

export default globalConfig;