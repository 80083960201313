import React from 'react';
import {
    CircularGauge, ValueIndicator, Scale, Label, Geometry, RangeContainer, Range, Export, Title, Font
} from 'devextreme-react/circular-gauge';
import { Chart, Series, Legend } from 'devextreme-react/chart';

import ServiceStats from '../../api/services/ServiceStats';

import './Portal.scss';

class Portal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dataSourceDriversStats: [],
            tripsStatsFinished: 0,
            tripsStatsWaiting: 0,
            tripsStatsDriving: 0,
            tripsStatsCancelled: 0,
            tripsStatsTotal: 0,
        }

        this.customizeText = "Finished trips"
    }

    // EVENTS
    componentDidMount = async () => {
        await this.loadDataSourceTripsStats();
        await this.loadDataSourceDriversStats();
    }

    // DATA
    loadDataSourceTripsStats = async () => {
        var data = await ServiceStats.getTripsStats(parseInt(sessionStorage.getItem("currentEventId"), 10));
        if (!data.hasOwnProperty('error')) {
            this.setState({ tripsStatsFinished: data.filter(t => t.StatusName.toLowerCase() === 'finished').length });
            this.setState({ tripsStatsWaiting: data.filter(t => t.StatusName.toLowerCase() === 'waiting').length });
            this.setState({ tripsStatsDriving: data.filter(t => t.StatusName.toLowerCase() === 'driving').length });
            this.setState({ tripsStatsCancelled: data.filter(t => t.StatusName.toLowerCase() === 'cancelled').length });
            this.setState({ tripsStatsTotal: data.length });
        } else {
            this.onDBError('Database error!');  // callback
        }
    }

    loadDataSourceDriversStats = async () => {
        var data = await ServiceStats.getDriversStats(parseInt(sessionStorage.getItem("currentEventId"), 10));
        if (!data.hasOwnProperty('error')) {
            this.setState({ dataSourceDriversStats: data });
        } else {
            this.onDBError('Database error!');  // callback
        }
    }

    // FUNCTIONS
    render() {

        const showStats = (sessionStorage.getItem('currentUserIsShuttlesAdministrator') == 'true' || sessionStorage.getItem('currentUserIsShuttlesUser') == 'true');


        if (showStats) {
            return (
                <React.Fragment>
                    <div className="stats-main-div">
                        <div className='dx-card stats-grid'>
                            <div className='stats-title stats-title-trips'>Shuttle stats</div>
                            <div className="stats-div">
                                <CircularGauge
                                    id="gauge"
                                    value={this.state.tripsStatsFinished}
                                >
                                    <Scale startValue={0} endValue={this.state.tripsStatsTotal} tickInterval={this.state.tripsStatsTotal >= 10 ? 10 : 1}>
                                        <Label customizeText={this.customizeText} />
                                    </Scale>
                                    <RangeContainer>
                                        <Range startValue={0} endValue={Math.floor(this.state.tripsStatsTotal / 3)} color="#CE2029" />
                                        <Range startValue={Math.floor(this.state.tripsStatsTotal / 3)} endValue={Math.floor(this.state.tripsStatsTotal / 3 * 2)} color="#FFD700" />
                                        <Range startValue={Math.floor(this.state.tripsStatsTotal / 3 * 2)} endValue={this.state.tripsStatsTotal} color="#228B22" />
                                    </RangeContainer>
                                    <Export enabled={true} />
                                    <Title text={"Finished trips (" + this.state.tripsStatsFinished + "/" + this.state.tripsStatsTotal + ")"}>
                                        <Font size={28} />
                                    </Title>
                                    <Export enabled={false} />
                                </CircularGauge>
                            </div>
                            <div className="stats-div stats-text">
                                Waiting: {this.state.tripsStatsWaiting}&nbsp;&nbsp;&nbsp;
                                Driving: {this.state.tripsStatsDriving}&nbsp;&nbsp;&nbsp;
                                Finished: {this.state.tripsStatsFinished}&nbsp;&nbsp;&nbsp;
                                Cancelled: {this.state.tripsStatsCancelled}
                            </div>
                            <div className="stats-div stats-driver-div">
                                <div className="stats-title">Driver stats</div>
                                <Chart id="chart" dataSource={this.state.dataSourceDriversStats}>
                                    <Series
                                        valueField="TripsDone"
                                        argumentField="DriverName"
                                        name=""
                                        type="bar"
                                        color="#ffaa66">
                                        <Label
                                            visible={true}
                                            backgroundColor="#03A9F4"
                                        />
                                    </Series>
                                    <Legend visible={false} />
                                </Chart>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                </React.Fragment>
            );
        }

    }
}

export default Portal