import globalConfig from '../../config/global.js';

class ServiceRelations {
    ////////////////////////////////////////////////////
    // USERS
    ////////////////////////////////////////////////////

    static getAllRelationsByEventId = async (eventId) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Relations/GetAllRelationsByEventId?eventId=" + encodeURIComponent(eventId)) // return null or data object
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static getRelationByEventIdRelationId = async (eventId, relationId) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Relations/GetRelationByEventIdRelationId?eventId=" + encodeURIComponent(eventId) + '&relationId=' + encodeURIComponent(relationId)) // return null or data object
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static getArtistRelationsByRelationId = async (relationId) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Relations/GetArtistRelationsByRelationId?relationId=" + encodeURIComponent(relationId)) // return null or data object
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static getAllArtistRelationsByArtistId = async (artistId) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Relations/GetAllArtistRelationsByArtistId?artistId=" + encodeURIComponent(artistId)) // return null or data object
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static getAllArtistRelationsByEventId = async (eventId) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Relations/GetAllArtistRelationsByEventId?eventId=" + encodeURIComponent(eventId)) // return null or data object
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static addRelation = async (model) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Relations/AddRelation", {
            method: 'POST',
            body: JSON.stringify(model),
            headers: {
                'Content-type': 'application/json; charset=UTF-8'
            }
        })
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(error => {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static updateRelation = async (model) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Relations/UpdateRelation", {
            method: 'POST',
            body: JSON.stringify(model),
            headers: {
                'Content-type': 'application/json; charset=UTF-8'
            }
        })
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(error => {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static deleteRelation = async (relationId, relationType) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Relations/DeleteRelation?relationId=" + encodeURIComponent(relationId) + '&relationType=' + encodeURIComponent(relationType)) // return true or false
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static addArtistRelations = async (model) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Relations/AddArtistRelations", {
            method: 'POST',
            body: JSON.stringify(model),
            headers: {
                'Content-type': 'application/json; charset=UTF-8'
            }
        })
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(error => {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static updateArtistRelations = async (model) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Relations/UpdateArtistRelations", {
            method: 'POST',
            body: JSON.stringify(model),
            headers: {
                'Content-type': 'application/json; charset=UTF-8'
            }
        })
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(error => {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static getRelationTypes = async () => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Relations/GetRelationTypes")
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }
}

export default ServiceRelations;
