import React from 'react';
import { Popup } from 'devextreme-react/popup';
import List from 'devextreme-react/list';
import { confirm, custom } from 'devextreme/ui/dialog';
import TextBox from 'devextreme-react/text-box';
import Validator, { CustomRule, ValidationRule } from 'devextreme-react/validator';
import { Button } from 'devextreme-react/button';
import { ValidationGroup } from 'devextreme-react/validation-group';
import SelectBox from 'devextreme-react/select-box';
import { TagBox } from 'devextreme-react/tag-box';
import { CheckBox } from 'devextreme-react/check-box';
import * as dayJS from 'dayjs';

import DataGrid, {
    RemoteOperations, Editing, ColumnChooser, GroupPanel, FilterPanel, FilterRow, HeaderFilter, Paging, Pager, Scrolling, Column, SearchPanel, KeyboardNavigation, Export, StateStoring, Sorting, Toolbar, Item
} from 'devextreme-react/data-grid';
import ServiceRiders from '../../../../api/services/ServiceRiders'
import ServiceArtists from '../../../../api/services/ServiceArtists'
import ServiceGeneral from '../../../../api/services/ServiceGeneral'
import ServiceLocations from '../../../../api/services/ServiceLocations'
import ServiceProducts from '../../../../api/services/ServiceProducts'

import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';

import validFilename from 'valid-filename';

import './PopupExportRider.scss'

//      onInitialized: callback
//      onConnectionError: callback

function createNewFileName() {
    let d = new Date();
    return 'Rider_' + d.getDate().toString().padStart(2, '0') + '_' + (d.getMonth() + 1).toString().padStart(2, '0') + '_' + d.getFullYear() + '.xlsx';
}


class PopupExportRider extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dataSourceRiders: [],
            dataSourceArtists: [],
            dataSourceSubArtists: [],
            dataSourceGigDates: [],
            dataSourceShops: [],
            dataSourceProducts: [],
            headerText: ''
        };

        this.dataSourceSubArtistColors = [];
        this.dataGridInstance = null;

        this.popupInstance = null;

        this.textBoxNameInstance = null;

        this.checkBoxAutoNameInstance = null;
        
        this.tagBoxGigDatesInstance = null;

        this.selectBoxShopsInstance = null;
        this.selectedShopID = -1;

        this.selectedGigDateIds = [];
        this.selectBoxArtistsInstance = null;
        this.selectedArtistId = -1;

        this.selectBoxSubArtistsInstance = null;
        this.selectedSubArtistId = -1;
     
        this.selectBoxProductsInstance = null;
        this.selectedProductID = -1;

        this.checkBoxExportColorsInstance = null;
        
        this.validationGroupInstance = null;

        this.fileName = 'Rider';

        this.userSettings = {
            UserId: parseInt(sessionStorage.getItem("currentUserId"), 10),
            DefaultViewId: -1,
            DefaultFilterId: -1,
            ExportColors: true,
            MultiSelectionGigDates: false,
            MultiSelectionArtists: true,
            UseBlanksForZeroes: false,
            UseColorsProducts: true,
            UseColorsArtists: true,
            ColorOriginProducts: 'product',
            ColorOriginArtists: 'artist',
            ShowSelectedCell: false,
            UseAdvancedFiltering: false,
        }
    }

    // CALLBACKS
    onInitialized = (instance) => {
        if (this.props.onInitialized) {
            this.props.onInitialized(instance);
        }
    }
    onConnectionError = () => {
        if (this.props.onConnectionError) {
            this.props.onConnectionError();
        }
    }
    // EVENTS
    componentDidUpdate(prevProps, prevState) {
        // Typical usage (don't forget to compare props/states):
        if (this.state.dataSourceGigDates !== prevState.dataSourceGigDates) {
            var newValues = this.state.dataSourceGigDates.map(function (item) {
                return item.Id;
            });
            this.tagBoxGigDatesInstance.option("value", newValues);
        }
    }

    // DATA
    loadDataSourceArtistRider = async () => {
        var data = await ServiceRiders.getArtistRider(parseInt(sessionStorage.getItem("currentEventId"), 10), this.selectedGigDateIds, this.selectedArtistId, this.selectedProductID, true, 'shop');
        if (data !== null && !data.hasOwnProperty("error")) {
            this.setState({ dataSourceRiders: data })
            if (data.length > 0) {
                this.setState({ headerText: data[0].ArtistName });
            }
        } else {
            this.onConnectionError();  // callback
        }
        this.dataGridInstance.columnOption("Comment", "visible", false)
    }

    loadDataSourceArtistShopRider = async () => {
        var data = await ServiceRiders.getArtistShopRider(parseInt(sessionStorage.getItem("currentEventId"), 10), this.selectedGigDateIds, this.selectedArtistId, this.selectedShopID, this.selectedProductID, true, 'shop');
        if (data !== null && !data.hasOwnProperty("error")) {
            this.setState({ dataSourceRiders: data })
            if (data.length > 0) {
                this.setState({ headerText: data[0].ArtistName });
            }
        } else {
            this.onConnectionError();  // callback
        }
        this.dataGridInstance.columnOption("Comment", "visible", false)
    }

    loadDataSourceShopRider = async () => {
        var data = await ServiceRiders.getShopRider(parseInt(sessionStorage.getItem("currentEventId"), 10), this.selectedGigDateIds, this.selectedShopID, this.selectedProductID, true, 'shop');
        if (data !== null && !data.hasOwnProperty("error")) {
            this.setState({ dataSourceRiders: data })
            if (data.length > 0) {
                this.setState({ headerText: data[0].Shops });
            }
        } else {
            this.onConnectionError();  // callback
        }
        this.dataGridInstance.columnOption("Comment", "visible", false)
    }

    loadDataSourceSubArtistRider = async () => {
        var data = await ServiceRiders.getSubArtistRider(parseInt(sessionStorage.getItem("currentEventId"), 10), this.selectedGigDateIds, this.selectedSubArtistId, this.selectedProductID, true, 'shop');
        if (data !== null && !data.hasOwnProperty("error")) {
            this.setState({ dataSourceRiders: data })
            if (data.length > 0) {
                this.setState({ headerText: data[0].ArtistName + ' - ' + data[0].SubArtistName });
            }
        } else {
            this.onConnectionError();  // callback
        }
        this.dataGridInstance.columnOption("Comment", "visible", true)
    }

    loadDataSourceSubArtistShopRider = async () => {
        var data = await ServiceRiders.getSubArtistShopRider(parseInt(sessionStorage.getItem("currentEventId"), 10), this.selectedGigDateIds, this.selectedSubArtistId, this.selectedShopID, this.selectedProductID, true, 'shop');
        if (data !== null && !data.hasOwnProperty("error")) {
            this.setState({ dataSourceRiders: data })
            if (data.length > 0) {
                this.setState({ headerText: data[0].ArtistName });
            }
        } else {
            this.onConnectionError();  // callback
        }
        this.dataGridInstance.columnOption("Comment", "visible", true)
    }

    loadDataSourceArtists = async () => {
        var data = await ServiceArtists.getArtistNamesByGigDateIds(this.selectedGigDateIds);
        if (data !== null && !data.hasOwnProperty("error")) {
            //  data.unshift({ Id: -1, Name: "Select" });
            this.setState({ dataSourceArtists: data })
        } else {
            this.onConnectionError();  // callback
        }
    }

    loadDataSourceSubArtists = async () => {
        var data = await ServiceArtists.getSubArtistNamesByArtistId(this.selectedArtistId);

        if (data !== null && !data.hasOwnProperty("error")) {
            //data.unshift({ Id: -1, Name: "All" });
            this.setState({ dataSourceSubArtists: data })
        } else {
            this.onConnectionError();  // callback
        }
    }

    loadDataSourceGigDates = async () => {
        var data = await ServiceGeneral.getGigDatesByEventId(parseInt(sessionStorage.getItem("currentEventId"), 10), 'riders');
        if (data !== null && !data.hasOwnProperty("error")) {
            var convertedDataSourceGigDates = [];
            data.forEach((item, index, array) => {
                convertedDataSourceGigDates.push({ Id: item.Id, GigDate: dayJS(item.GigDate).format('ddd DD/MM/YY') });
            });
            this.setState({ dataSourceGigDates: convertedDataSourceGigDates })
        } else {
            this.onConnectionError();  // callback
        }
    }

    loadDataSourceShops = async () => {
        var data = await ServiceLocations.getShopsDropDown();
        if (data !== null && !data.hasOwnProperty("error")) {
            this.setState({ dataSourceShops: data })
        } else {
            this.onConnectionError();  // callback
        }
    }

    loadDataSourceProducts = async () => {
        var data = await ServiceProducts.getProductsDropDown();
        if (data !== null && !data.hasOwnProperty("error")) {
            this.setState({ dataSourceProducts: data })
        } else {
            this.onConnectionError();  // callback
        }
    }

    popup_OnInitialized = (e) => {
        this.popupInstance = e.component;
        this.onInitialized(e.component);  // callback
    }

    popup_OnShowing = (e) => {
        this.fileName = createNewFileName();

        this.selectedGigDateIds = [];
        this.selectedArtistId = -1;
        this.selectedSubArtistId = -1;
        this.selectedShopID = -1;
        this.selectedProductID = -1;

        this.selectBoxShopsInstance.option('value', null);
        this.selectBoxArtistsInstance.option('value', null);
        this.selectBoxSubArtistsInstance.option('value', null);
        this.selectBoxProductsInstance.option('value', null);

    }

    popup_OnShown = async (e) => {
        this.textBoxNameInstance.option('value', this.fileName);
        this.checkBoxExportColorsInstance.option('value', this.userSettings.ExportColors)

        document.getElementById('textBoxFileNameInput').select();

        this.tagBoxGigDatesInstance.focus();
        this.textBoxNameInstance.focus();

        await this.loadDataSourceGigDates();  // to prevent display bug
        await this.loadDataSourceShops();
        await this.loadDataSourceProducts();

    }

    // TEXTBOX NAME
    textBoxAttributes = {
        id: 'textBoxFileNameInput',
    }

    textBoxName_OnInitialized = (e) => {
        this.textBoxNameInstance = e.component;
    }

    textBoxName_OnFocusIn = (e) => {
        // select all text
        e.element.querySelector('.dx-texteditor-input').select();
    }

    validationGroup_OnInitialized = (e) => {
        this.validationGroupInstance = e.component;
    }

    // TAGBOX GIGDATES
    tagBoxGigDates_OnInitialized = (e) => {
        this.tagBoxGigDatesInstance = e.component;
    }

    tagBoxGigDates_OnSelectionChanged = async (e) => {
        this.selectedGigDateIds = this.tagBoxGigDatesInstance.option("selectedItems").map(function (item) {
            return item.Id;
        });
        await this.loadDataSourceArtists();
        this.selectedArtistId = -1;
        await this.loadDataSourceSubArtists();
        this.selectedSubArtistId = -1;
        this.selectBoxArtistsInstance.option('value', null);
        this.selectBoxSubArtistsInstance.option('value', null);
 
        if (this.selectedShopID > -1) {
            if (this.selectedArtistId == -1 && this.selectedSubArtistId == -1) {
                await this.loadDataSourceShopRider();
            } else if (this.selectedArtistId > -1 && this.selectedSubArtistId > -1) {
                await this.loadDataSourceSubArtistShopRider();
            } else {
                await this.loadDataSourceArtistShopRider();
            }
        } else {
            if (this.selectedArtistId == -1 && this.selectedSubArtistId == -1) {
                await this.loadDataSourceShopRider();  // clears grid
            } else if (this.selectedArtistId > -1 && this.selectedSubArtistId > -1) {
                await this.loadDataSourceSubArtistRider();
            } else {
                await this.loadDataSourceArtistRider();
            }
        }

        this.textBoxNameInstance.option('value', this.fileName);
        this.setState({ headerText: '' });
     }

    tagBoxGigDates_OnMultiTagPreparing = (args) => {
        const selectedItemsLength = args.selectedItems.length;
        let data = this.tagBoxGigDatesInstance.option('items');
        const totalCount = data.length;
        if (selectedItemsLength == totalCount)  {
            args.text = 'All (' + selectedItemsLength + ')';
        }
    }

    // SELECTBOX ARTISTS
    selectBoxArtists_OnInitialized = (e) => {
        this.selectBoxArtistsInstance = e.component;
    }

    selectBoxArtists_OnSelectionChanged = async (e) => {
        this.selectedArtistId = e.selectedItem !== null ? e.selectedItem.Id : -1;
        this.selectedSubArtistId = -1;
        await this.loadDataSourceSubArtists();
        this.selectBoxSubArtistsInstance.option('value', null);

        if (this.selectedShopID > -1) {
            if (this.selectedArtistId == -1 && this.selectedSubArtistId == -1) {
                await this.loadDataSourceShopRider();
            } else if (this.selectedArtistId > -1 && this.selectedSubArtistId > -1) {
                await this.loadDataSourceSubArtistShopRider();
            } else {
                await this.loadDataSourceArtistShopRider();
            }
        } else {
            if (this.selectedArtistId == -1 && this.selectedSubArtistId == -1) {
                await this.loadDataSourceShopRider();  // clears grid
            } else if (this.selectedArtistId > -1 && this.selectedSubArtistId > -1) {
                await this.loadDataSourceSubArtistRider();
            } else {
                await this.loadDataSourceArtistRider();
            }
        }

        if (this.checkBoxAutoNameInstance.option('value') == true) {
            if (this.selectedArtistId > -1) {
                this.textBoxNameInstance.option('value', this.selectBoxArtistsInstance.option('text') + '.xlsx');
                this.setState({ headerText: this.selectBoxArtistsInstance.option('text') });
            } else {
                this.textBoxNameInstance.option('value', this.fileName);
                this.setState({ headerText: '' });
            }
        }
    }

    selectBoxShops_OnInitialized = (e) => {
        this.selectBoxShopsInstance = e.component;
    }

    selectBoxShops_OnSelectionChanged = async (e) => {
        this.selectedShopID = e.selectedItem !== null ? e.selectedItem.Id : -1;

        if (this.selectedShopID > -1) {
            if (this.selectedArtistId == -1 && this.selectedSubArtistId == -1) {
                await this.loadDataSourceShopRider();
            } else if (this.selectedArtistId > -1 && this.selectedSubArtistId > -1) {
                await this.loadDataSourceSubArtistShopRider();
            } else {
                await this.loadDataSourceArtistShopRider();
            }
        } else {
            if (this.selectedArtistId == -1 && this.selectedSubArtistId == -1) {
                await this.loadDataSourceShopRider();  // clears grid
            } else if (this.selectedArtistId > -1 && this.selectedSubArtistId > -1) {
                await this.loadDataSourceSubArtistRider();
            } else {
                await this.loadDataSourceArtistRider();
            }
        }


    }

    // SELECTBOX SUBARTISTS
    selectBoxSubArtists_OnInitialized = (e) => {
        this.selectBoxSubArtistsInstance = e.component;
    }

    selectBoxSubArtists_OnSelectionChanged = async (e) => {
        this.selectedSubArtistId = e.selectedItem !== null ? e.selectedItem.Id : -1;

        if (this.selectedShopID > -1) {
            if (this.selectedArtistId == -1 && this.selectedSubArtistId == -1) {
                await this.loadDataSourceShopRider();
            } else if (this.selectedArtistId > -1 && this.selectedSubArtistId > -1) {
                await this.loadDataSourceSubArtistShopRider();
            } else {
                await this.loadDataSourceArtistShopRider();
            }
        } else {
            if (this.selectedArtistId == -1 && this.selectedSubArtistId == -1) {
                await this.loadDataSourceShopRider();  // clears grid
            } else if (this.selectedArtistId > -1 && this.selectedSubArtistId > -1) {
                await this.loadDataSourceSubArtistRider();
            } else {
                await this.loadDataSourceArtistRider();
            }
        }

        if (this.checkBoxAutoNameInstance.option('value') == true) {
            this.textBoxNameInstance.option('value', this.selectBoxArtistsInstance.option('text') + (this.selectedSubArtistId == -1 ? '' :  ' - ' + this.selectBoxSubArtistsInstance.option('text')) + '.xlsx');
            this.setState({ headerText: this.selectBoxArtistsInstance.option('text') + (this.selectedSubArtistId == -1 ? '' : ' - ' + this.selectBoxSubArtistsInstance.option('text')) });
        }
    }

    selectBoxProducts_OnInitialized = (e) => {
        this.selectBoxProductsInstance = e.component;
    }

    selectBoxProducts_OnSelectionChanged = async (e) => {
        this.selectedProductID = e.selectedItem !== null ? e.selectedItem.Id : -1;

        if (this.selectedShopID > -1) {
            if (this.selectedArtistId == -1 && this.selectedSubArtistId == -1) {
                await this.loadDataSourceShopRider();
            } else if (this.selectedArtistId > -1 && this.selectedSubArtistId > -1) {
                await this.loadDataSourceSubArtistShopRider();
            } else {
                await this.loadDataSourceArtistShopRider();
            }
        } else {
            if (this.selectedArtistId == -1 && this.selectedSubArtistId == -1) {
                await this.loadDataSourceShopRider();  // clears grid
            } else if (this.selectedArtistId > -1 && this.selectedSubArtistId > -1) {
                await this.loadDataSourceSubArtistRider();
            } else {
                await this.loadDataSourceArtistRider();
            }
        }
    }


    checkBoxExportColors_OnInitialized = (e) => {
        this.checkBoxExportColorsInstance = e.component;
    }

    checkBoxExportColors_OnValueChanged = (e) => {
        this.dataGridInstance.refresh();
    }
    checkBoxAutoName_OnInitialized = (e) => {
        this.checkBoxAutoNameInstance = e.component;
    }

    checkBoxAutoName_OnValueChanged = (e) => {
        if (e.value == true && (this.selectedArtistId > -1 || this.selectedSubArtistId > -1)) {
            this.textBoxNameInstance.option('value', (this.selectedArtistId == -1 ? '' : this.selectBoxArtistsInstance.option('text')) + (this.selectedSubArtistId == -1 ? '' : ' - ' + this.selectBoxSubArtistsInstance.option('text')) + '.xlsx');
        }
    }

    buttonSave_OnClick = (e) => {
        this.validationGroupInstance.validate();

        if (this.textBoxNameInstance.option('isValid') === true) {
            this.exportToExcel(this.textBoxNameInstance.option('value').trim().replace(/.xlsx$/gi, '') + '.xlsx');
        } else {
            this.textBoxNameInstance.focus();
        }
    }

    validateFileName = () => {
        let isValid = false;

        if (validFilename(this.textBoxNameInstance.option('value').replace(/.xlsx$/gi, '') + '.xlsx')) {
            isValid = true;
        }

        return isValid;
    }

    exportToExcel = (name) => {
        const fileName = name;
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Sheet 1');

        worksheet.columns = [
            { width: 35 }, { width: 15 }, { width: 15 }, { width: 15 }, { width: 15 }
        ];

        worksheet.pageSetup.margins = {
            left: 0.5, right: 0.5,
            top: 0.5, bottom: 0.5,
            header: 0, footer: 0
        };

        exportDataGrid({
            component: this.dataGridInstance,
            worksheet: worksheet,
            customizeCell: this.customizeExcelCell,
            keepColumnWidths: false
        })
            .then(function () {
                workbook.xlsx.writeBuffer()
                    .then(function (buffer) {
                        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), fileName);
                    });
            });
    }



    // set excel cell layout
    customizeExcelCell = (options) => {
        const { gridCell, excelCell } = options;

        if (gridCell.rowType === 'data') {
            if (this.checkBoxExportColorsInstance.option('value') == true) {
                if (gridCell.column.dataField === 'Shops' || gridCell.column.dataField === 'ProductName' || gridCell.column.dataField === 'ProductTypeName') {
                    let bg = (gridCell.data.ColorHex !== null ? gridCell.data.ColorHex.replace('#', '').slice(0, 6) : 'FFFFFF');
                    let fg = (gridCell.data.ForeColorHex !== null ? gridCell.data.ForeColorHex.replace('#', '').slice(0, 6) : '000000');
                    excelCell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: bg }
                    };
                    excelCell.font = {
                        color: { argb: fg }
                    }
                }
            }
        } else if (gridCell.rowType === 'header') {
            if (this.checkBoxExportColorsInstance.option('value')) {
                let bg = 'FFFFFF';
                let fg = '000000';
                let s = 12;
                let alignment = { vertical: 'middle', horizontal: 'left' };
                if (gridCell.column.dataField == undefined) {
                    bg = '000000';
                    fg = 'FFFFFF';
                    s = 16;
                } else if (gridCell.column.dataField == 'Amount') {
                    alignment = { vertical: 'middle', horizontal: 'right' };
                }

                excelCell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: bg }
                };

                excelCell.font = {
                    color: { argb: fg },
                    size: s
                }

                excelCell.alignment = alignment;
            }
        }

        excelCell.border = {
            top: { style: 'thin', color: { argb: 'FF000000' } },
            left: { style: 'thin', color: { argb: 'FF000000' } },
            bottom: { style: 'thin', color: { argb: 'FF000000' } },
            right: { style: 'thin', color: { argb: 'FF000000' } }
        };

    }

    gridOnInitialized = async (e) => {
        // set instance
        this.dataGridInstance = e.component;
    }

    gridOnCellPrepared = (e) => {
        if (e.rowType === 'data') {
            if (e.column.dataField === 'Shops' || e.column.dataField === 'ProductName') {
                if (this.checkBoxExportColorsInstance.option('value') == true) {
                    e.cellElement.style.backgroundColor = e.data.ColorHex;
                    e.cellElement.style.color = e.data.ForeColorHex;
                } else {
                    e.cellElement.style.backgroundColor = '#FFFFFF';
                    e.cellElement.style.color = '#000000';
                }
            } else {
                e.cellElement.style.backgroundColor = '#FFFFFF';
                e.cellElement.style.color = '#000000';
            }
        } else if (e.rowType === 'header') {
            if (e.column.dataField == undefined) {
                e.cellElement.style.backgroundColor = '#FF5722';
                e.cellElement.style.color = '#FFFFFF';
            } else {
                e.cellElement.style.backgroundColor = '#FFFFFF';
                e.cellElement.style.color = '#000000';
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <Popup
                    dragEnabled={false}
                    hideOnOutsideClick={false}
                    showTitle={true}
                    title='Export rider ...'
                    showCloseButton={true}
                    width={850}
                    height={710}
                    onInitialized={this.popup_OnInitialized}
                    onShowing={this.popup_OnShowing}
                    onShown={this.popup_OnShown}
                >
                    <ValidationGroup onInitialized={this.validationGroup_OnInitialized}>
                        <div className="dx-card per-top-wrapper">
                            <div className="per-dx-field">
                                <div className="dx-field-label per-label">Name*</div>
                                <div className="per-name-div">
                                    <div className="dx-field-value per-name">
                                        <TextBox
                                            onInitialized={this.textBoxName_OnInitialized}
                                            defaultValue=''
                                            inputAttr={this.textBoxAttributes}
                                            onFocusIn={this.textBoxName_OnFocusIn}
                                            hint='Enter name ...'
                                        >
                                            <Validator>
                                                <ValidationRule type="required" message="Required" />
                                                <CustomRule validationCallback={this.validateFileName} message="Invalid file name" />
                                            </Validator>
                                        </TextBox>
                                    </div>
                                    <div className="per-checkbox-auto">
                                        <CheckBox
                                            defaultValue={true}
                                            text="Auto"
                                            onInitialized={this.checkBoxAutoName_OnInitialized}
                                            onValueChanged={this.checkBoxAutoName_OnValueChanged}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="dx-field per-field-dates">
                                <div className="dx-field-label per-label">Dates *</div>
                                <div className="dx-field-value per-field-value-dates">
                                    <TagBox
                                        dataSource={this.state.dataSourceGigDates}
                                        valueExpr="Id"
                                        displayExpr="GigDate"
                                        multiline={true}
                                        maxDisplayedTags={1}
                                        showMultiTagOnly={true}
                                        showSelectionControls={true}
                                        showClearButton={false}
                                        showDataBeforeSearch={false}
                                        minSearchLength={0}
                                        onInitialized={this.tagBoxGigDates_OnInitialized}
                                        onSelectionChanged={this.tagBoxGigDates_OnSelectionChanged}
                                        onMultiTagPreparing={this.tagBoxGigDates_OnMultiTagPreparing}
                                    />
                                </div>
                                <div className="dx-field-label per-label-shop">Shop</div>
                                <div className="dx-field-value per-field-shop">
                                    <SelectBox
                                        dataSource={this.state.dataSourceShops}
                                        valueExpr="Id"
                                        displayExpr="Name"
                                        searchEnabled={true}
                                        showClearButton={true}
                                        showDataBeforeSearch={false}
                                        minSearchLength={0}
                                        onInitialized={this.selectBoxShops_OnInitialized}
                                        onSelectionChanged={this.selectBoxShops_OnSelectionChanged}
                                    />
                                </div>
                            </div>

                            <div className="dx-field per-field-artist">
                                <div className="dx-field-label per-label">Artist</div>
                                <div className="dx-field-value per-field-value-artist">
                                    <SelectBox
                                        dataSource={this.state.dataSourceArtists}
                                        valueExpr="Id"
                                        displayExpr="Name"
                                        searchEnabled={true}
                                        showClearButton={true}
                                        showDataBeforeSearch={false}
                                        minSearchLength={0}
                                        onInitialized={this.selectBoxArtists_OnInitialized}
                                        onSelectionChanged={this.selectBoxArtists_OnSelectionChanged}
                                    />
                                </div>

                                <div className="dx-field-label per-label-subartist">SubArtist</div>
                                <div className="dx-field-value per-field-value-subartist">
                                    <SelectBox
                                        dataSource={this.state.dataSourceSubArtists}
                                        valueExpr="Id"
                                        displayExpr="Name"
                                        searchEnabled={true}
                                        showClearButton={true}
                                        showDataBeforeSearch={false}
                                        minSearchLength={0}
                                        onInitialized={this.selectBoxSubArtists_OnInitialized}
                                        onSelectionChanged={this.selectBoxSubArtists_OnSelectionChanged}
                                    />
                                </div>
                            </div>

                            <div className="dx-field">
                                <div className="dx-field-label per-label">Products</div>
                                <div className="dx-field-value per-field-value-products">
                                    <SelectBox
                                        dataSource={this.state.dataSourceProducts}
                                        valueExpr="Id"
                                        displayExpr="Name"
                                        searchEnabled={true}
                                        showClearButton={true}
                                        showDataBeforeSearch={false}
                                        minSearchLength={0}
                                        onInitialized={this.selectBoxProducts_OnInitialized}
                                        onSelectionChanged={this.selectBoxProducts_OnSelectionChanged}
                                    />
                                </div>
                            </div>


                        </div>
                    </ValidationGroup>
                    <div className="dx-card per-card-grid">
                        <DataGrid
                            ref={this.refDataGrid}
                            width='100%'
                            dataSource={this.state.dataSourceRiders}
                            remoteOperations={true}
                            wordWrapEnabled={true}
                            onInitialized={this.gridOnInitialized}
                            onCellPrepared={this.gridOnCellPrepared}
                            visible={true}
                        >
                            <ColumnChooser enabled={false} />
                            <FilterPanel visible={false} />
                            <FilterRow visible={false} />
                            <GroupPanel visible={false} />
                            <SearchPanel visible={false} />
                            <HeaderFilter visible={false} />
                            <Scrolling
                                mode="infinite"
                                useNative='false'
                                scrollByContent={true}
                                scrollByThumb={true}
                                showScrollbar='always'
                            />
                            <Column
                                cssClass='grid-header'
                                caption={this.state.headerText}
                            >
                                <Column
                                    dataField='ColorHex'
                                    dataType='string'
                                    caption='ColorHex'
                                    allowFiltering={false}
                                    allowEditing={false}
                                    fixed={true}
                                    showInColumnChooser={false}
                                    visible={false}
                                />
                                <Column
                                    dataField='ForeColorHex'
                                    dataType='string'
                                    caption='ForeColorHex'
                                    allowFiltering={false}
                                    allowEditing={false}
                                    fixed={true}
                                    showInColumnChooser={false}
                                    visible={false}
                                />
                                <Column
                                    dataField='PatternColorHex'
                                    dataType='string'
                                    caption='PatternColorHex'
                                    allowFiltering={false}
                                    allowEditing={false}
                                    fixed={true}
                                    showInColumnChooser={false}
                                    visible={false}
                                />
                                <Column
                                    dataField='UsePattern'
                                    dataType='number'
                                    caption='UsePattern'
                                    allowFiltering={false}
                                    allowEditing={false}
                                    fixed={true}
                                    showInColumnChooser={false}
                                    visible={false}
                                />
                                <Column
                                    dataField='PatternCssBackGround'
                                    dataType='string'
                                    caption='PatternCssBackGround'
                                    allowFiltering={false}
                                    allowEditing={false}
                                    fixed={true}
                                    showInColumnChooser={false}
                                    visible={false}
                                />
                                <Column
                                    dataField='PatternCssBackGroundImage'
                                    dataType='string'
                                    caption='PatternCssBackGroundImage'
                                    allowFiltering={false}
                                    allowEditing={false}
                                    fixed={true}
                                    showInColumnChooser={false}
                                    visible={false}
                                />
                                <Column
                                    dataField='PatternCssBackGroundSize'
                                    dataType='string'
                                    caption='PatternCssBackGroundSize'
                                    allowFiltering={false}
                                    allowEditing={false}
                                    fixed={true}
                                    showInColumnChooser={false}
                                    visible={false}
                                />
                                <Column
                                    dataField='PatternCssBackGroundPosition'
                                    dataType='string'
                                    caption='PatternCssBackGroundPosition'
                                    allowFiltering={false}
                                    allowEditing={false}
                                    fixed={true}
                                    showInColumnChooser={false}
                                    visible={false}
                                />
                                <Column
                                    dataField='PatternTransparency'
                                    dataType='number'
                                    caption='PatternTransparency'
                                    allowFiltering={false}
                                    allowEditing={false}
                                    fixed={true}
                                    showInColumnChooser={false}
                                    visible={false}
                                />
                                <Column
                                    dataField='Shops'
                                    dataType='string'
                                    caption='Shop'
                                    width={150}
                                    allowFiltering={true}
                                    allowResizing={true}
                                    allowEditing={false}
                                    fixed={true}
                                    showInColumnChooser={true}
                                    visible={true}
                                />
                                <Column
                                    dataField='ProductName'
                                    dataType='string'
                                    caption='Product'
                                    sortIndex={0}
                                    sortOrder='Asc'
                                    width={350}
                                    allowFiltering={true}
                                    allowResizing={true}
                                    allowEditing={false}
                                    fixed={true}
                                    showInColumnChooser={true}
                                    visible={true}
                                />
                                <Column
                                    dataField='ArticleNo'
                                    dataType='string'
                                    caption='ArticleNo'
                                    sortIndex={0}
                                    sortOrder='Asc'
                                    width='auto'
                                    allowFiltering={true}
                                    allowResizing={true}
                                    allowEditing={false}
                                    fixed={true}
                                    showInColumnChooser={true}
                                    visible={true}
                                />
                                <Column
                                    dataField='Packaging'
                                    dataType='string'
                                    caption='Packaging'
                                    sortIndex={0}
                                    sortOrder='Asc'
                                    width='auto'
                                    allowFiltering={true}
                                    allowResizing={true}
                                    allowEditing={false}
                                    fixed={true}
                                    showInColumnChooser={true}
                                    visible={true}
                                />
                                <Column
                                    dataField='Amount'
                                    dataType='number'
                                    caption='Amount'
                                    sortIndex={0}
                                    sortOrder='Asc'
                                    width='auto'
                                    allowFiltering={true}
                                    allowResizing={true}
                                    allowEditing={false}
                                    fixed={true}
                                    showInColumnChooser={true}
                                    visible={true}
                                />
                                <Column
                                    dataField='Comment'
                                    dataType='string'
                                    caption='Comment'
                                    sortIndex={0}
                                    sortOrder='Asc'
                                    width='auto'
                                    allowFiltering={true}
                                    allowResizing={true}
                                    allowEditing={false}
                                    fixed={true}
                                    showInColumnChooser={true}
                                    visible={true}
                                />
                            </Column>
                        </DataGrid>
                    </div>

                    <div className="per-export-div">
                        <div className="per-export-checkbox-div">
                            <CheckBox
                                defaultValue={false}
                                text="Export colors"
                                onInitialized={this.checkBoxExportColors_OnInitialized}
                                onValueChanged={this.checkBoxExportColors_OnValueChanged}
                            />
                        </div>
                        <div className="per-export-button-div">
                            <Button
                                width={120}
                                text="Export"
                                className='dx-button'
                                onClick={this.buttonSave_OnClick}
                            />
                        </div>
                    </div>
                </Popup>

            </React.Fragment>

        );

    }

}

export default PopupExportRider

