import React from 'react';
import HXPopupUserFilters from './HXPopupUserFilters'
import HxXmlConvert from '../../helpers/HxXmlConvert';
import ServiceUsers from '../../../api/services/ServiceUsers'
import HxToast from '../hx-toast/HxToast';
import SelectBox from 'devextreme-react/select-box';
import { Button } from 'devextreme-react/button';

import './HXUserFilters.scss'

// callbacks
//      onUserFilterLoaded(filterstate)
//      onClearFilterCallBack
//      onError
// callables
//      setDataGridInstance(instance)
// props
//      filetypeName

class HXUserFilters extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dataSourceUserFilters: [],
            loadedUserFilter: -1,
        };
 
        this.filterState = {};
        this.filterTypeName = this.props.filterTypeName;

        this.popupUserFiltersInstance = null;
        this.dataGridInstance = null;
        this.selectBoxUserFiltersInstance = null;
    }

    // CALLBACKS
    onUserFilterLoadedCallBack = async (filterstate) => {
        this.filterState = filterstate;
        if (this.props.onUserFilterLoaded) {
            await this.props.onUserFilterLoaded(filterstate);
        }
    }

    onClearFilterCallBack = () =>{
        if (this.props.onClearFilter) {
            this.props.onClearFilter();
        }
    }

    onError = (error) => {
        if (this.props.onError) {
            this.props.onError(error);
        }
    }

    // CALLABLES
    setDataGridInstance = async  (instance) => {
        this.dataGridInstance = instance;
    }

    // EVENTS
    componentDidMount = async () => {
        await this.loadDataSourceUserFilters();

        let settings = {
            DefaultFilterId: -1
        };

        settings = await this.loadUserSettings();
        this.setState({ loadedUserFilter: settings.DefaultFilterId });
        await this.loadFilter(settings.DefaultFilterId)
    }

    // DATA
    loadDataSourceUserFilters = async () => {
        let settings = {
            DefaultFilterId: -1
        };

        settings = await this.loadUserSettings();

        var data = await ServiceUsers.getUserFiltersByEventIdUserIdUserFilterTypeName(parseInt(sessionStorage.getItem("currentEventId"), 10), parseInt(sessionStorage.getItem("currentUserId"), 10), this.filterTypeName);
        if (data !== null && !data.hasOwnProperty("error")) {
            data.forEach((userFilterRecord) => {
                userFilterRecord.IsDefault = (userFilterRecord.Id === settings.DefaultFilterId);
            })

            this.setState({ dataSourceUserFilters: data })

        } else {
            this.onError();
        }
    }

    loadUserSettings = async () => {
        let result = false;
        let settings = null;

        switch (this.filterTypeName) {
            case "relations":
                settings = await ServiceUsers.getUserSettingsRelationsByUserId(parseInt(sessionStorage.getItem("currentUserId"), 10));
                break;
            case "locations":
                settings = await ServiceUsers.getUserSettingsLocationsByUserId(parseInt(sessionStorage.getItem("currentUserId"), 10));
                break;
            case "triptimings":
                settings = await ServiceUsers.getUserSettingsTripTimingsByUserId(parseInt(sessionStorage.getItem("currentUserId"), 10));
                break;
            case "shuttles":
                settings = await ServiceUsers.getUserSettingsShuttlesByUserId(parseInt(sessionStorage.getItem("currentUserId"), 10));
                break;
            case 'riders':
                settings = await ServiceUsers.getUserSettingsRidersByUserId(parseInt(sessionStorage.getItem("currentUserId"), 10));
                break;
            case 'products':
                settings = await ServiceUsers.getUserSettingsProductsByUserId(parseInt(sessionStorage.getItem("currentUserId"), 10));
                break;
            case 'shops':
                settings = await ServiceUsers.getUserSettingsShopsByUserId(parseInt(sessionStorage.getItem("currentUserId"), 10));
                break;
            case 'fleet':
                settings = await ServiceUsers.getUserSettingsFleetByUserId(parseInt(sessionStorage.getItem("currentUserId"), 10));
                break;
           default:
                break;
        }

        if (settings !== null && !settings.hasOwnProperty("error")) {
            result = settings;
        } else {
            this.onError();
        }

        return result;
    }

    // TOOLBAR
    toolBarShowFilters_OnClick = (e) => {
        this.popupUserFiltersInstance.show();
    }

    selectBoxUserFilters_OnInitialized = (e) => {
        // set instance
        this.selectBoxUserFiltersInstance = e.component;
    }

    selectBoxUserFilters_OnValueChanged = async (e) => {
        if (e.value !== null) {
            if (this.state.loadedUserFilter !== e.value) {
                await this.loadFilter(e.value);
            }
        } else {
            this.onClearFilterCallBack();
            this.setState({ loadedUserFilter: -1 });
        }
    }

    // POPUP USERFILTERS CALLBACKS 
    popupUserFilters_OnInitialized = (instance) => {
        this.popupUserFiltersInstance = instance;
    }

    popupUserFilters_OnHidden = async (e) => {
        await this.loadDataSourceUserFilters();

        // sync selectbox with popup
        if (this.state.dataSourceUserFilters.length > 0) {

            var state = this.state.dataSourceUserFilters;
            var loaded = this.state.loadedUserFilter;

            var filter = state.find(x => x.Id === loaded);
            
            if (!filter) {
                this.setState({ loadedUserFilter: -1 });
            }
        }
    }

    popupUserFilters_OnItemDblClick = async (id) => {
        await this.loadFilter(id);
    }

    popupUserFilters_OnSaveUserFilter = async (name, isDefault) => {
        var id = await this.saveFilter(name, isDefault);
        return id;
    }

    popupUserFilters_OnUpdateUserFilter = async (id, name, isDefault) => {
        await this.updateFilter(id, name, isDefault);
    }

    popupUserFilters_OnDeleteUserFilter = async (id) => {
        await this.deleteFilter(id);
    }

    popupUserFilters_OnDeleteMultipleUserFilters = async (ids) => {
        await this.deleteMultipleFilters(ids);
    }

    popupUserFilters_OnUpdateUserFilterName = async (id, name) => {
        await this.updateFilterName(id, name);
    }

    popupUserFilters_OnSwitchUserFilterDefault = async (id) => {
        await this.switchFilterDefault(id);
    }

    // FILTERS
    loadFilter = async (id) => {
        if (id !== -1 && id !== null && id !== undefined) {
            var xml = null;
            var data = await ServiceUsers.getUserFilterById(id);
            if (data !== null) {
                if (!data.hasOwnProperty("error")) {
                    xml = data;
                    if (xml !== null && xml.length > 0) {
                        var obj = HxXmlConvert.XmlToObject(xml);
                        if (obj !== null) {
                            await this.onUserFilterLoadedCallBack(obj);
                            this.setState({ loadedUserFilter: id });
                        } else {
                            HxToast.showToast('Filter invalid!', 'warning', 'bottom right', 'up-stack');
                        }
                    } else {
                        HxToast.showToast('Filter invalid!', 'warning', 'bottom right', 'up-stack');
                    }
                } else {
                    this.onError(true);
                }
            } else {
                HxToast.showToast('Filter not found!', 'error', 'bottom right', 'up-stack');
            }
        }
    }

    updateFilter = async (id, name, isDefault) => {
        var success = false;

        this.filterState.filterValue = JSON.stringify(this.dataGridInstance.state().filterValue);

        var xml = HxXmlConvert.ObjectToXml(this.filterState);

        if (xml === null) {
            HxToast.showToast('Update filter failed!', 'error', 'bottom right', 'up-stack');
            return false;
        }

        // remove linefeeds, replace chars, then remove spaces between tags
        xml = xml.replace(/(\r\n|\n|\r)/gm, "").replace(/&/g, "&amp;").replace(/>[\t\s ]*</g, "><");

        var userFilterData = {
            Id: id,
            UserId: parseInt(sessionStorage.getItem("currentUserId"), 10),
            UserFilterTypeId: -1,
            EventId: parseInt(sessionStorage.getItem("currentEventId"), 10),
            Name: name,
            Xml: xml
        }

        var userFilterTypeId = await ServiceUsers.getUserFilterTypeIDByName(this.filterTypeName);
        if (userFilterTypeId !== null) {
            if (!userFilterTypeId.hasOwnProperty("error")) {
                userFilterData.UserFilterTypeId = userFilterTypeId;
                var data = await ServiceUsers.updateUserFilter(userFilterData);
                if (data !== null) {
                    if (!data.hasOwnProperty("error")) {
                        HxToast.showToast('Filter updated.', 'success', 'bottom right', 'up-stack');
                        success = data;
                    } else {
                        this.onError(true);
                    }
                } else {
                    HxToast.showToast('Update filter failed!', 'error', 'bottom right', 'up-stack');
                }
            } else {
                this.onError(true);
            }
        } else {
            HxToast.showToast('Update filter failed!', 'error', 'bottom right', 'up-stack');
        }

        if (success) {
            const settings = await this.loadUserSettings();
            switch (this.filterTypeName) {
                case "relations":
                    await this.saveUserSettingDefaultRelations(id, isDefault, settings);
                    break;
                case "locations":
                    await this.saveUserSettingDefaultLocations(id, isDefault, settings);
                    break;
                case "triptimings":
                    await this.saveUserSettingDefaultTripTimings(id, isDefault, settings);
                    break;
                case "shuttles":
                    await this.saveUserSettingDefaultShuttles(id, isDefault, settings);
                    break;
              case 'riders':
                    await this.saveUserSettingDefaultRiders(id, isDefault, settings);
                    break;
                case 'products':
                    await this.saveUserSettingDefaultProducts(id, isDefault, settings);
                    break;
                case 'shops':
                    await this.saveUserSettingDefaultShops(id, isDefault, settings);
                    break;
                case 'fleet':
                    await this.saveUserSettingDefaultFleet(id, isDefault, settings);
                    break;
               default:
                    break;
            }
        }
    }

    saveFilter = async (name, isDefault) => {
        var newId = -1;

        this.filterState.filterValue = JSON.stringify(this.dataGridInstance.state().filterValue);

        // set xml (string)
        var xml = HxXmlConvert.ObjectToXml(this.filterState);

        if (xml === null) {
            HxToast.showToast('Save filter failed!', 'error', 'bottom right', 'up-stack');
        }

        // remove linefeeds, replace chars, then remove spaces between tags
        xml = xml.replace(/(\r\n|\n|\r)/gm, "").replace(/&/g, "&amp;").replace(/>[\t\s ]*</g, "><");

        var userFilterData = {
            Id: -1,
            UserId: parseInt(sessionStorage.getItem("currentUserId"), 10),
            UserFilterTypeId: -1,
            EventId: parseInt(sessionStorage.getItem("currentEventId"), 10),
            Name: name,
            Xml: xml
        }

        var userFilterTypeId = await ServiceUsers.getUserFilterTypeIDByName(this.filterTypeName);
        if (userFilterTypeId !== null) {
            if (!userFilterTypeId.hasOwnProperty("error")) {
                userFilterData.UserFilterTypeId = userFilterTypeId;
                var data = await ServiceUsers.saveUserFilter(userFilterData);
                if (data !== null) {
                    if (!data.hasOwnProperty("error")) {
                        if (data !== -1) {
                            HxToast.showToast('success', 'Filter saved.', 'bottom-right');
                            newId = data;
                        } else {
                            HxToast.showToast('error', 'Name already exists.', 'bottom-right', false);
                        }
                    } else {
                        this.onError(true);
                    }
                } else {
                    HxToast.showToast('error', 'Save filter failed!', 'bottom-right', false);
                }
            } else {
                this.onError(true);
            }
        } else {
            HxToast.showToast('error', 'Save filter failed!', 'bottom-right', false);
        }

        if (newId !== -1) {
            const settings = await this.loadUserSettings();
            switch (this.filterTypeName) {
                case 'relations':
                    await this.saveUserSettingDefaultRelations(newId, isDefault, settings);
                    break;
                case 'locations':
                    await this.saveUserSettingDefaultLocations(newId, isDefault, settings);
                    break;
                case 'triptimings':
                    await this.saveUserSettingDefaultTripTimings(newId, isDefault, settings);
                    break;
                case 'shuttles':
                    await this.saveUserSettingDefaultShuttles(newId, isDefault, settings);
                    break;
                case 'riders':
                    await this.saveUserSettingDefaultRiders(newId, isDefault, settings);
                    break;
                case 'products':
                    await this.saveUserSettingDefaultProducts(newId, isDefault, settings);
                    break;
                case 'shops':
                    await this.saveUserSettingDefaultShops(newId, isDefault, settings);
                    break;
                case 'fleet':
                    await this.saveUserSettingDefaultFleet(newId, isDefault, settings);
                    break;
              default:
                    break;
            }
        }

        return newId;
    }
 
    deleteFilter = async (id) => {
        var data = await ServiceUsers.deleteUserFilter(id);

        if (data !== null && !data.hasOwnProperty("error")) {
            if (data === true) {
                HxToast.showToast('Filter deleted.', 'success', 'bottom right', 'up-stack');
            } else {
                HxToast.showToast('Delete filter failed!', 'error', 'bottom right', 'up-stack');
            }
        } else {
            this.setErrorHeader(true);
        }

        const settings = await this.loadUserSettings();
        switch (this.filterTypeName) {
            case 'relations':
                await this.saveUserSettingDefaultRelations(id, false, settings);
                break;
            case 'locations':
                await this.saveUserSettingDefaultLocations(id, false, settings);
                break;
            case 'triptimings':
                await this.saveUserSettingDefaultTripTimings(id, false, settings);
                break;
            case 'shuttles':
                await this.saveUserSettingDefaultShuttles(id, false, settings);
                break;
            case 'riders':
                await this.saveUserSettingDefaultRiders(id, false, settings);
                break;
            case 'products':
                await this.saveUserSettingDefaultProducts(id, false, settings);
                break;
            case 'shops':
                await this.saveUserSettingDefaultShops(id, false, settings);
                break;
            case 'fleet':
                await this.saveUserSettingDefaultFleet(id, false, settings);
                break;
        default:
                break;
        }
    }

    deleteMultipleFilters = async (ids) => {

        var data = await ServiceUsers.deleteMultipleUserFilters(ids);
        if (data !== null && !data.hasOwnProperty("error")) {
            if (data === true) {
                HxToast.showToast('Filters deleted.', 'success', 'bottom right', 'up-stack');
            } else {
                HxToast.showToast('Delete filters failed!', 'error', 'bottom right', 'up-stack');
            }
        } else {
            this.setErrorHeader(true);
        }

        const settings = await this.loadUserSettings();
        var found = ids.find(x => x === settings.DefaultFilterId);
        if (found !== undefined) {
            settings.DefaultFilterId = -1;
            switch (this.filterTypeName) {
                case 'relations':
                    await this.saveUserSettingDefaultMultipleRelations(settings);
                    break;
                case 'locations':
                    await this.saveUserSettingDefaultMultipleLocations(settings);
                    break;
                case 'triptimings':
                    await this.saveUserSettingDefaultMultipleTripTimings(settings);
                    break;
                case 'shuttles':
                    await this.saveUserSettingDefaultMultipleShuttles(settings);
                    break;
                case 'riders':
                    await this.saveUserSettingDefaultMultipleRiders(ids, settings);
                    break;
                case 'products':
                    await this.saveUserSettingDefaultMultipleProducts(ids, settings);
                    break;
                case 'shops':
                    await this.saveUserSettingDefaultMultipleShops(ids, settings);
                    break;
                case 'fleet':
                    await this.saveUserSettingDefaultMultipleFleet(ids, settings);
                    break;
                default:
                    break;
            }
        }
    }

    updateFilterName = async (id, name) => {

        let userFilterData = {
            Id: id,
            UserId: parseInt(sessionStorage.getItem("currentUserId"), 10),
            UserFilterTypeId: -1,
            EventId: parseInt(sessionStorage.getItem("currentEventId"), 10),
            Name: name.trim(),
            Xml: ''
        }

        var userFilterTypeId = await ServiceUsers.getUserFilterTypeIDByName(this.filterTypeName);
        if (userFilterTypeId !== null) {
            if (!userFilterTypeId.hasOwnProperty("error")) {
                userFilterData.UserFilterTypeId = userFilterTypeId;
                var data = await ServiceUsers.updateUserFilterName(userFilterData);
                if (data !== null) {
                    if (!data.hasOwnProperty("error")) {
                        HxToast.showToast('Filtername updated.', 'success', 'bottom right', 'up-stack');
                    } else {
                        this.onError(true);
                    }
                } else {
                    HxToast.showToast('Update Filtername failed!', 'error', 'bottom right', 'up-stack');
                }
            } else {
                this.onError(true);
            }
        } else {
            HxToast.showToast('Update Filtername failed!', 'error', 'bottom right', 'up-stack');
        }
       
    }

    switchFilterDefault = async (id) => {
        const settings = await this.loadUserSettings();
        switch (this.filterTypeName) {
            case 'relations':
                await this.saveUserSettingDefaultRelations(id, settings.DefaultFilterId !== id, settings);
                break;
            case 'locations':
                await this.saveUserSettingDefaultLocations(id, settings.DefaultFilterId !== id, settings);
                break;
            case 'triptimings':
                await this.saveUserSettingDefaultTripTimings(id, settings.DefaultFilterId !== id, settings);
                break;
            case 'shuttles':
                await this.saveUserSettingDefaultShuttles(id, settings.DefaultFilterId !== id, settings);
                break;
            case 'riders':
                await this.saveUserSettingDefaultRiders(id, settings.DefaultFilterId !== id, settings);
                break;
            case 'products':
                await this.saveUserSettingDefaultProducts(id, settings.DefaultFilterId !== id, settings);
                break;
            case 'shops':
                await this.saveUserSettingDefaultShops(id, settings.DefaultFilterId !== id, settings);
                break;
            case 'fleet':
                await this.saveUserSettingDefaultFleet(id, settings.DefaultFilterId !== id, settings);
                break;
           default:
                break;
        }
    }

    saveUserSettingDefaultRelations = async (defaultFilterId, isDefault, settings) => {
        if (isDefault) {
            settings.DefaultFilterId = defaultFilterId;
        } else {
            if (settings.DefaultFilterId === defaultFilterId) {
                settings.DefaultFilterId = -1;
            }
        }

        var dataRelations = await ServiceUsers.setUserSettingsRelations(settings);
        if (dataRelations !== null && !dataRelations.hasOwnProperty("error")) {
            if (dataRelations === false) {
                HxToast.showToast('Filter NOT set as default!', 'warning', 'bottom right', 'up-stack');
            }
        } else {
            this.onError(true);
        }
    }

    saveUserSettingDefaultMultipleRelations = async (settings) => {

        var data = await ServiceUsers.setUserSettingsRelations(settings);
        if (data !== null && !data.hasOwnProperty("error")) {
            if (data === false) {
                HxToast.showToast('Filter NOT set as default!', 'warning', 'bottom right', 'up-stack');
            }
        } else {
            this.onError(true);
        }
    }

    saveUserSettingDefaultLocations = async (defaultFilterId, isDefault, settings) => {
        if (isDefault) {
            settings.DefaultFilterId = defaultFilterId;
        } else {
            if (settings.DefaultFilterId === defaultFilterId) {
                settings.DefaultFilterId = -1;
            }
        }

        var dataLocations = await ServiceUsers.setUserSettingsLocations(settings);
        if (dataLocations !== null && !dataLocations.hasOwnProperty("error")) {
            if (dataLocations === false) {
                HxToast.showToast('Filter NOT set as default!', 'warning', 'bottom right', 'up-stack');
            }
        } else {
            this.onError(true);
        }
    }

    saveUserSettingDefaultMultipleLocations = async (settings) => {

        var data = await ServiceUsers.setUserSettingsLocations(settings);
        if (data !== null && !data.hasOwnProperty("error")) {
            if (data === false) {
                HxToast.showToast('Filter NOT set as default!', 'warning', 'bottom right', 'up-stack');
            }
        } else {
            this.onError(true);
        }
    }

    saveUserSettingDefaultTripTimings = async (defaultFilterId, isDefault, settings) => {
        if (isDefault) {
            settings.DefaultFilterId = defaultFilterId;
        } else {
            if (settings.DefaultFilterId === defaultFilterId) {
                settings.DefaultFilterId = -1;
            }
        }

        var dataTripTimings = await ServiceUsers.setUserSettingsTripTimings(settings);
        if (dataTripTimings !== null && !dataTripTimings.hasOwnProperty("error")) {
            if (dataTripTimings === false) {
                HxToast.showToast('Filter NOT set as default!', 'warning', 'bottom right', 'up-stack');
            }
        } else {
            this.onError(true);
        }
    }

    saveUserSettingDefaultMultipleTripTimings = async (settings) => {

        var data = await ServiceUsers.setUserSettingsTripTimings(settings);
        if (data !== null && !data.hasOwnProperty("error")) {
            if (data === false) {
                HxToast.showToast('Filter NOT set as default!', 'warning', 'bottom right', 'up-stack');
            }
        } else {
            this.onError(true);
        }
    }

    saveUserSettingDefaultShuttles = async (defaultFilterId, isDefault, settings) => {
        if (isDefault) {
            settings.DefaultFilterId = defaultFilterId;
        } else {
            if (settings.DefaultFilterId === defaultFilterId) {
                settings.DefaultFilterId = -1;
            }
        }

        var dataShuttles = await ServiceUsers.setUserSettingsShuttles(settings);
        if (dataShuttles !== null && !dataShuttles.hasOwnProperty("error")) {
            if (dataShuttles === false) {
                HxToast.showToast('Filter NOT set as default!', 'warning', 'bottom right', 'up-stack');
            }
        } else {
            this.onError(true);
        }
    }

    saveUserSettingDefaultMultipleShuttles = async (settings) => {

        var data = await ServiceUsers.setUserSettingsShuttles(settings);
        if (data !== null && !data.hasOwnProperty("error")) {
            if (data === false) {
                HxToast.showToast('Filter NOT set as default!', 'warning', 'bottom right', 'up-stack');
            }
        } else {
            this.onError(true);
        }
    }


    saveUserSettingDefaultRiders = async (defaultFilterId, isDefault, settings) => {

        if (isDefault) {
            settings.DefaultFilterId = defaultFilterId;
        } else {
            if (settings.DefaultFilterId === defaultFilterId) {
                settings.DefaultFilterId = -1;
            }
        }

        var dataRiders = await ServiceUsers.setUserSettingsRiders(settings);
        if (dataRiders !== null && !dataRiders.hasOwnProperty("error")) {
            if (dataRiders === false) {
                HxToast.showToast('Filter NOT set as default!', 'warning', 'bottom right', 'up-stack');
            }
        } else {
            this.onError(true);
        }
    }

    saveUserSettingDefaultMultipleRiders = async (defaultFilterIds, settings) => {

        //for (var i = 0; i < defaultFilterIds.length; i++) {
        //    if (isDefault) {
        //        settings.DefaultFilterId = defaultFilterIds[i];
        //    } else {
        //        if (settings.DefaultFilterId === defaultFilterIds[i]) {
        //            settings.DefaultFilterId = -1;
        //        }
        //    }
        //}

        //const userSettingRidersData = {
        //    UserId: sessionStorage.getItem('currentUserId'),
        //    DefaultViewId: JSON.parse(sessionStorage.getItem('data')).riders.defaultViewId,
        //    DefaultFilterId: JSON.parse(sessionStorage.getItem('data')).riders.defaultFilterId,
        //    ExportColors: JSON.parse(sessionStorage.getItem('data')).riders.exportColors,
        //    MultiSelectionGigDates: JSON.parse(sessionStorage.getItem('data')).riders.multiSelectionGigDates,
        //    MultiSelectionArtists: JSON.parse(sessionStorage.getItem('data')).riders.multiSelectionArtists,
        //    UseBlanksForZeroes: JSON.parse(sessionStorage.getItem('data')).riders.useBlanksForZeroes,
        //    UseColorsProducts: JSON.parse(sessionStorage.getItem('data')).riders.useColorsProducts,
        //    UseColorsArtists: JSON.parse(sessionStorage.getItem('data')).riders.useColorsArtists,
        //    ColorOriginProducts: JSON.parse(sessionStorage.getItem('data')).riders.colorOriginProducts,
        //    ColorOriginArtists: JSON.parse(sessionStorage.getItem('data')).riders.colorOriginArtists
        //}
        //var dataRiders = await ServiceUsers.setUserSettingsRiders(userSettingRidersData);
        //if (dataRiders !== null && !dataRiders.hasOwnProperty("error")) {
        //    if (dataRiders === false) {
        //        HxToast.showToast('Filter NOT set as default!', 'warning', 'bottom right', 'up-stack');
        //    }
        //} else {
        //    this.onError(true);
        //}
       
    }


    saveUserSettingDefaultProducts = async (defaultFilterId, isDefault, settings) => {
        if (isDefault) {
            settings.DefaultFilterId = defaultFilterId;
        } else {
            if (settings.DefaultFilterId === defaultFilterId) {
                settings.DefaultFilterId = -1;
            }
        }

        var dataProducts = await ServiceUsers.setUserSettingsProducts(settings);
        if (dataProducts !== null && !dataProducts.hasOwnProperty("error")) {
            if (dataProducts === false) {
                HxToast.showToast('Filter NOT set as default!', 'warning', 'bottom right', 'up-stack');
            }
        } else {
            this.onError(true);
        }
    }

    saveUserSettingDefaultMultipleProducts = async (defaultFilterIds, settings) => {

        //for (var i = 0; i < defaultFilterIds.length; i++) {
        //    if (isDefault) {
        //        var settings = JSON.parse(sessionStorage.getItem('data'));
        //        settings.products.defaultFilterId = defaultFilterIds[i];
        //        sessionStorage.setItem('data', JSON.stringify(settings));
        //    } else {
        //        if (JSON.parse(sessionStorage.getItem('data')).products.defaultFilterId === defaultFilterIds[i]) {
        //            var settings = JSON.parse(sessionStorage.getItem('data'));
        //            settings.products.defaultFilterId = -1;
        //            sessionStorage.setItem('data', JSON.stringify(settings));                }
        //    }
        //}

        //const userSettingProductsData = {
        //    UserId: sessionStorage.getItem('currentUserId'),
        //    DefaultViewId: JSON.parse(sessionStorage.getItem('data')).products.defaultViewId,
        //    DefaultFilterId: JSON.parse(sessionStorage.getItem('data')).products.defaultFilterId,
        //    ExportColors: JSON.parse(sessionStorage.getItem('data')).products.exportColors,
        //    UseBlanksForZeroes: JSON.parse(sessionStorage.getItem('data')).products.useBlanksForZeroes,
        //    UseColorsProducts: JSON.parse(sessionStorage.getItem('data')).products.useColorsProducts,
        //    ColorOriginProducts: JSON.parse(sessionStorage.getItem('data')).products.colorOriginProducts,
        //}
        //var dataProducts = await ServiceUsers.setUserSettingsProducts(userSettingProductsData);
        //if (dataProducts !== null && !dataProducts.hasOwnProperty("error")) {
        //    if (dataProducts === false) {
        //        HxToast.showToast('Filter NOT set as default!', 'warning', 'bottom right', 'up-stack');
        //    }
        //} else {
        //    this.onError(true);
        //}

    }

    saveUserSettingDefaultShops = async (defaultFilterId, isDefault, settings) => {

        if (isDefault) {
            settings.DefaultFilterId = defaultFilterId;
        } else {
            if (settings.DefaultFilterId === defaultFilterId) {
                settings.DefaultFilterId = -1;
            }
        }

        var dataShops = await ServiceUsers.setUserSettingsShops(settings);
        if (dataShops !== null && !dataShops.hasOwnProperty("error")) {
            if (dataShops === false) {
                HxToast.showToast('Filter NOT set as default!', 'warning', 'bottom right', 'up-stack');
            }
        } else {
            this.onError(true);
        }
    }

    saveUserSettingDefaultMultipleShops = async (defaultFilterIds, settings) => {

        //for (var i = 0; i < defaultFilterIds.length; i++) {
        //    if (isDefault) {
        //        var settings = JSON.parse(sessionStorage.getItem('data'));
        //        settings.shops.defaultFilterId = defaultFilterIds[i];
        //        sessionStorage.setItem('data', JSON.stringify(settings));
        //    } else {
        //        if (JSON.parse(sessionStorage.getItem('data')).shops.defaultFilterId === defaultFilterIds[i]) {
        //            var settings = JSON.parse(sessionStorage.getItem('data'));
        //            settings.shops.defaultFilterId = -1;
        //            sessionStorage.setItem('data', JSON.stringify(settings));
        //        }
        //    }
        //}

        //const userSettingShopsData = {
        //    UserId: sessionStorage.getItem('currentUserId'),
        //    DefaultViewId: JSON.parse(sessionStorage.getItem('data')).shops.defaultViewId,
        //    DefaultFilterId: JSON.parse(sessionStorage.getItem('data')).shops.defaultFilterId,
        //    ExportColors: JSON.parse(sessionStorage.getItem('data')).shops.exportColors,
        //    UseBlanksForZeroes: JSON.parse(sessionStorage.getItem('data')).shops.useBlanksForZeroes,
        //    UseColorsProducts: JSON.parse(sessionStorage.getItem('data')).shops.useColorsProducts,
        //    ColorOriginProducts: JSON.parse(sessionStorage.getItem('data')).shops.colorOriginProducts,
        //}
        //var datashops = await ServiceUsers.setUserSettingsShops(userSettingShopsData);
        //if (datashops !== null && !datashops.hasOwnProperty("error")) {
        //    if (datashops === false) {
        //        HxToast.showToast('Filter NOT set as default!', 'warning', 'bottom right', 'up-stack');
        //    }
        //} else {
        //    this.onError(true);
        //}

    }

    saveUserSettingDefaultFleet = async (defaultFilterId, isDefault, settings) => {
        if (isDefault) {
            settings.DefaultFilterId = defaultFilterId;
        } else {
            if (settings.DefaultFilterId === defaultFilterId) {
                settings.DefaultFilterId = -1;
            }
        }

        var dataFleet = await ServiceUsers.setUserSettingsFleet(settings);
        if (dataFleet !== null && !dataFleet.hasOwnProperty("error")) {
            if (dataFleet === false) {
                HxToast.showToast('Filter NOT set as default!', 'warning', 'bottom right', 'up-stack');
            }
        } else {
            this.onError(true);
        }
    }

    saveUserSettingDefaultMultipleFleet = async (settings) => {

        var data = await ServiceUsers.setUserSettingsFleet(settings);
        if (data !== null && !data.hasOwnProperty("error")) {
            if (data === false) {
                HxToast.showToast('Filter NOT set as default!', 'warning', 'bottom right', 'up-stack');
            }
        } else {
            this.onError(true);
        }
    }



    render() {
        return (
            <React.Fragment>
                <div className="huf-main-div">
                    <span>
                        <Button
                            icon='filter'
                            stylingMode='text'
                            text=''
                            onClick={this.toolBarShowFilters_OnClick}
                        />
                    </span>
                    <span className="huf-selectbox">
                        <SelectBox
                            onInitialized={this.selectBoxUserFilters_OnInitialized}
                            onValueChanged={this.selectBoxUserFilters_OnValueChanged}
                            stylingMode='outlined'
                            placeholder='No filter selected'
                            height='30px'
                            width='200px'
                            showClearButton={true}
                            dataSource={this.state.dataSourceUserFilters}
                            valueExpr='Id'
                            displayExpr='Name'
                            searchEnabled={true}
                            noDataText='No filters found'
                            value={this.state.loadedUserFilter}
                        />
                    </span>
                </div>

                <HXPopupUserFilters
                    loadedUserFilter={this.state.loadedUserFilter}
                    onInitialized={this.popupUserFilters_OnInitialized}
                    onItemDblClick={this.popupUserFilters_OnItemDblClick}
                    onHidden={this.popupUserFilters_OnHidden}
                    onSaveUserFilter={this.popupUserFilters_OnSaveUserFilter}
                    onUpdateUserFilter={this.popupUserFilters_OnUpdateUserFilter}
                    onDeleteUserFilter={this.popupUserFilters_OnDeleteUserFilter}
                    onDeleteMultipleUserFilters={this.popupUserFilters_OnDeleteMultipleUserFilters}
                    onRenameUserFilter={this.popupUserFilters_OnUpdateUserFilterName}
                    onSwitchUserFilterDefault={this.popupUserFilters_OnSwitchUserFilterDefault}
                    filterTypeName={this.filterTypeName}
                />
            </React.Fragment>
        );
    }

}

export default HXUserFilters