import React from 'react';
import { Popup, Position } from 'devextreme-react/popup';
import HxErrorMessage from '../../../custom-components/hx-error-message/HxErrorMessage'
import Form, { SimpleItem, Item, Label, GroupItem, ButtonItem } from 'devextreme-react/form';
import ServiceUsers from '../../../../api/services/ServiceUsers'

import './PopupRidersUserSettings.scss'

// callbacks
//      onInitialized
//      onUpdate
// callables


const defaultSettings  = {
    UserId: parseInt(sessionStorage.getItem("currentUserId"), 10),
    DefaultViewId: -1,
    DefaultFilterId: -1,
    ExportColors: false,
    MultiSelectionGigDates: false,
    MultiSelectionArtists: false,
    UseBlankForZeroes: true,
    UseColorsProducts: false,
    UseColorsArtists: false,
    ColorOriginProducts: 'PRODUCTTYPE',
    ColorOriginArtists: 'ARTISTTYPE',
    ShowSelectedCell: false,
    UseAdvancedFiltering: false,
}

const itemsProducts = ['PRODUCT', 'PRODUCTTYPE', 'SHOP'];
const itemsProductsDisplay = ['Product', 'Product type', 'Shop'];
const itemsArtists = ['ARTIST', 'ARTISTTYPE'];
const itemsArtistsDisplay = ['Artist', 'Artist type'];

class PopupRidersUserSettings extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            userSettingsRiders: {}
        }

        this.popupInstance = null;
        
        this.radioGroupProductsInstance = {}
        this.radioGroupProductsDisplayInstance = {}
 
        this.radioGroupArtistsInstance = {}
        this.radioGroupArtistsDisplayInstance = {}

        this.dataLoaded = false;
    } 

    // CALLBACKS
    onInitialized(component) {
        if (this.props.onInitialized) {
            this.props.onInitialized(component);
        }
    }

    onUpdate(elementId, userSettings) {
        if (this.props.onUpdate) {
            this.props.onUpdate(elementId, userSettings);
        }
    }

    // EVENTS
    componentDidMount = async () => {
        await this.loadDataSourecUserSettings();
    }

    // POPUP
    popup_OnInitialized = (e) => {
        this.popupInstance = e.component;
        this.onInitialized(e.component);
    }

    popup_OnShown = async (e) => {
        await this.loadDataSourecUserSettings();
    }

    popup_OnHidden = async (e) => {
        this.dataLoaded = false;
    }

    loadDataSourecUserSettings = async () => {
        var settings = await ServiceUsers.getUserSettingsRidersByUserId(parseInt(sessionStorage.getItem("currentUserId"), 10));
        if (settings !== null && !settings.hasOwnProperty("error")) {
            this.setState({ userSettingsRiders: settings });
            this.dataLoaded = true;
        } else {
            this.setErrorHeader(true);
        }
    }

    saveData = async (elementId) => {
        var success = await ServiceUsers.setUserSettingsRiders(this.state.userSettingsRiders);
        if (!success.hasOwnProperty("error")) {
            if (success === true) {
                // trigger callback
                this.onUpdate(elementId, this.state.userSettingsRiders);
            } else {
                this.setErrorHeader(true);
            }
        } else {
            this.setErrorHeader(true);
        }
    }

    userSettingChanged = async (e) => {
        if (this.dataLoaded) {
            await this.saveData(e.element.id);
        }
    }

    checkBoxUseColorsProducts_OnValueChanged = (e) => {
        this.radioGroupProductsDisplayInstance.option('disabled', !e.value);
        this.userSettingChanged(e);
    }

    radioGroupProducts_OnInitialized = (e) => {
        this.radioGroupProductsInstance = e.component;
    }

    radioGroupProducts_OnValueChanged = (e) => {
        switch (e.value) {
            case "PRODUCT":
                this.radioGroupProductsDisplayInstance.option('value', 'Product');
                break;
            case "PRODUCTTYPE": ;
                this.radioGroupProductsDisplayInstance.option('value', 'Product type');
                break;
            case "SHOP":
                this.radioGroupProductsDisplayInstance.option('value', 'Shop');
                break;
            default:
                break;
        }
        this.userSettingChanged(e);
    }

    radioGroupProductsDisplay_OnInitialized = (e) => {
        this.radioGroupProductsDisplayInstance = e.component;
    }

    radioGroupProductsDisplay_OnValueChanged = (e) => {
        switch (e.value) {
            case "Product":
                this.radioGroupProductsInstance.option('value', 'PRODUCT');
                break;
            case "Product type": ;
                this.radioGroupProductsInstance.option('value', 'PRODUCTTYPE');
                break;
            case "Shop":
                this.radioGroupProductsInstance.option('value', 'SHOP');
                break;
            default:
                break;
        }
    }

    checkBoxUseColorsArtists_OnValueChanged = (e) => {
        this.radioGroupArtistsDisplayInstance.option('disabled', !e.value);
        this.userSettingChanged(e);
    }

    radioGroupArtists_OnInitialized = (e) => {
        this.radioGroupArtistsInstance = e.component;
    }

    radioGroupArtists_OnValueChanged = (e) => {
        switch (e.value) {
            case "ARTIST":
                this.radioGroupArtistsDisplayInstance.option('value', 'Artist');
                break;
            case "ARTISTTYPE":
                this.radioGroupArtistsDisplayInstance.option('value', 'Artist type');
                break;
            default:
                break;
        }
        this.userSettingChanged(e);
    }

    radioGroupArtistsDisplay_OnInitialized = (e) => {
        this.radioGroupArtistsDisplayInstance = e.component;
    }

    radioGroupArtistsDisplay_OnValueChanged = (e) => {
        switch (e.value) {
            case "Artist":
                this.radioGroupArtistsInstance.option('value', 'ARTIST');
                break;
            case "Artist type":
                this.radioGroupArtistsInstance.option('value', 'ARTISTTYPE');
                break;
            default:
                break;
        }
    }

    // ERROR MESSAGE
    setErrorHeader(show) {
        if (show) {
            this.refHxErrorMessage.current.show();
        } else {
            this.refHxErrorMessage.current.hide();
        }
    }

    render() {
        return (
            <React.Fragment>
                <Popup
                    dragEnabled={false}
                    hideOnOutsideClick={true}
                    title='Settings'
                    width={360}
                    height={455}
                    shading={false}
                    showTitle={false}
                    onInitialized={this.popup_OnInitialized}
                    onShowing={this.popup_OnShown}
                    onHidden={this.popup_OnHidden}
                >
                    <Position
                        my="right top"
                        at="right bottom"
                        of="#toolBarRiders"
                    />
                    <HxErrorMessage
                        ref={this.refHxErrorMessage}
                        id='error-message-popup-user-views'
                        message='Could not load views!'
                        visible={false}
                    />
                    <Form
                        id={'form'}
                        defaultFormData={defaultSettings}
                        formData={this.state.userSettingsRiders}
                        //onFieldDataChanged={e => e.dataField === 'Notes' && setNotes(e.value)}
                        labelLocation={'right'}
                        colCount={1}
                        validationGroup="validationGroupForm"
                    >
                        <GroupItem caption={'Behaviour'} >
                            <SimpleItem
                                dataField="MultiSelectionGigDates"
                                editorType="dxCheckBox"
                                editorOptions={{
                                    elementAttr: { id: 'checkBoxMultiSelectionGigDates' },
                                    text: 'Allow multi selection of gig dates',
                                    onValueChanged: this.userSettingChanged
                                }}
                                cssClass="prus-item"
                            >
                                <Label visible={false} />
                            </SimpleItem>
                            <SimpleItem
                                dataField="MultiSelectionArtists"
                                editorType="dxCheckBox"
                                editorOptions={{
                                    elementAttr: { id: 'checkBoxMultiSelectionArtists' },
                                    text: 'Allow multi selection of artists',
                                    onValueChanged: this.userSettingChanged
                                }}
                                cssClass="prus-item"
                            >
                                <Label visible={false} />
                            </SimpleItem>
                            <SimpleItem
                                dataField="UseBlanksForZeroes"
                                editorType="dxCheckBox"
                                editorOptions={{
                                    elementAttr: { id: 'checkBoxUseBlanksForZeroes' },
                                    text: 'Use blanks for zeroes',
                                    onValueChanged: this.userSettingChanged
                                }}
                                cssClass="prus-item"
                            >
                                <Label visible={false} />
                            </SimpleItem>
                            <SimpleItem
                                dataField={"ShowSelectedCell"}
                                editorType="dxCheckBox"
                                editorOptions={{
                                    text: 'Highlight selected cell',
                                    elementAttr: { id: 'checkBoxShowSelectedCell' },
                                    onValueChanged: this.userSettingChanged
                                }}
                                cssClass="prus-item"
                            >
                                <Label visible={false} />
                            </SimpleItem>
                            <SimpleItem
                                dataField={"UseAdvancedFiltering"}
                                editorType="dxCheckBox"
                                editorOptions={{
                                    text: 'Use advanced filtering',
                                    elementAttr: { id: 'checkBoxUseAdvancedFiltering' },
                                    onValueChanged: this.userSettingChanged
                                }}
                                cssClass="prus-item"
                            >
                                <Label visible={false} />
                            </SimpleItem>
                        </GroupItem>

                        <GroupItem caption={'Colors'}>
                            <SimpleItem
                                cssClass="prus-item-product"
                                dataField={"UseColorsProducts"}
                                editorType="dxCheckBox"
                                editorOptions={{
                                    text: 'Use color for products',
                                    elementAttr: { id: 'checkBoxUseColorsProducts' },
                                    onValueChanged: this.checkBoxUseColorsProducts_OnValueChanged
                                }}
                            >
                                <Label visible={false} />
                            </SimpleItem>
                            <SimpleItem
                                cssClass='prus-radio-group-products'
                                editorType="dxRadioGroup"
                                editorOptions={{
                                    items: itemsProductsDisplay,
                                    disabled: !this.state.userSettingsRiders.UseColorsProducts,
                                    layout: 'horizontal',
                                    onInitialized: this.radioGroupProductsDisplay_OnInitialized,
                                    onValueChanged: this.radioGroupProductsDisplay_OnValueChanged
                                }}
                           >
                                <Label visible={false} />
                            </SimpleItem>
                            <SimpleItem
                                cssClass='prus-radio-group-hidden'
                                dataField="ColorOriginProducts"
                                editorType="dxRadioGroup"
                                editorOptions={{
                                    items: itemsProducts,
                                    elementAttr: { id: 'radioButtonColorOriginProducts' },
                                    layout: 'horizontal',
                                    onInitialized: this.radioGroupProducts_OnInitialized,
                                    onValueChanged: this.radioGroupProducts_OnValueChanged
                                }}
                            >
                                <Label visible={false} />
                            </SimpleItem>

                            <SimpleItem
                                cssClass="prus-item-artist"
                                dataField={"UseColorsArtists"}
                                editorType="dxCheckBox"
                                editorOptions={{
                                    text: 'Use color for artists',
                                    elementAttr: { id: 'checkBoxUseColorsArtists' },
                                    onValueChanged: this.checkBoxUseColorsArtists_OnValueChanged
                                }}
                            >
                                <Label visible={false} />
                            </SimpleItem>
                            <SimpleItem
                                cssClass='prus-radio-group-artists'
                                editorType="dxRadioGroup"
                                editorOptions={{
                                    items: itemsArtistsDisplay,
                                    disabled: !this.state.userSettingsRiders.UseColorsArtists,
                                    layout: 'horizontal',
                                    onInitialized: this.radioGroupArtistsDisplay_OnInitialized,
                                    onValueChanged: this.radioGroupArtistsDisplay_OnValueChanged
                                }}
                            >
                                <Label visible={false} />
                            </SimpleItem>
                            <SimpleItem
                                cssClass='prus-radio-group-hidden'
                                dataField="ColorOriginArtists"
                                editorType="dxRadioGroup"
                                editorOptions={{
                                    items: itemsArtists,
                                    elementAttr: { id: 'radioButtonColorOriginArtists' },
                                    layout: 'horizontal',
                                    onInitialized: this.radioGroupArtists_OnInitialized,
                                    onValueChanged: this.radioGroupArtists_OnValueChanged
                                }}
                            >
                                <Label visible={false} />
                            </SimpleItem>
                            <SimpleItem
                                cssClass="prus-checkbox"
                                dataField={"ExportColors"}
                                editorType="dxCheckBox"
                                editorOptions={{
                                    text: 'Export colors to excel, pdf',
                                    onValueChanged: this.userSettingChanged
                                }}
                            >
                                <Label visible={false} />
                            </SimpleItem>
                        </GroupItem>
                    </Form>
                </Popup>
            </React.Fragment>
        );

    }

}

export default PopupRidersUserSettings

