import React from 'react';
import { HubConnectionBuilder } from '@microsoft/signalr';
import DataGrid, {
    RemoteOperations, Editing, ColumnChooser, GroupPanel, FilterPanel, FilterRow, HeaderFilter, Paging, Pager, Scrolling, Column, SearchPanel, KeyboardNavigation, Export, StateStoring, Sorting, Toolbar, Item
} from 'devextreme-react/data-grid';
import { confirm } from 'devextreme/ui/dialog';
import HxToast from '../../../../custom-components/hx-toast/HxToast';
import { Button } from 'devextreme-react/button';

import ServiceGeneral from '../../../../../api/services/ServiceGeneral'
import ServiceUsers from '../../../../../api/services/ServiceUsers'
import PopupShuttlesAdminTripTimingsEdit from './PopupShuttlesAdminTripTimingsEdit'

import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';

import './ShuttlesAdminTripTimingsDataGrid.scss'

// callables
//      applyUserSettings
//
// callbacks
//      onInitialized

class ShuttlesAdminTripTimingsDataGrid extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dataSourceTripTimings: [],
            locationDepartureId: -1,
            locationArrivalId: -1,
            relationId: -1,
            useAdvancedFiltering: false,
        }

        this.dataGridInstance = null;

        this.popupShuttlesAdminTripTimingsEditInstance = null;

        this.hubConnection = null;

        this.showSelectedCell = false;

        this.userSettings = {
            UserId: parseInt(sessionStorage.getItem("currentUserId"), 10),
            DefaultViewId: -1,
            DefaultFilterId: -1,
            ShowSelectedCell: false,
            UseAdvancedFiltering: false,
        }
    }

    // CALLBACKS
    onInitialized = (instance) => {
        if (this.props.onInitialized) {
            this.props.onInitialized(instance);  // callback
        }
    }

    // CALLABLES
    applyUserSettings = (elementId, value) => {
        switch (elementId) {
            case 'checkBoxShowSelectedCell':
                this.showSelectedCell = value;
                this.dataGridInstance.repaint();
                break;
            case 'checkBoxUseAdvancedFiltering':
                this.setState({ useAdvancedFiltering: value });
                this.dataGridInstance.repaint();
                break;
            default:
                break;
        }
    }

    // EVENTS
    componentDidMount = async () => {
        await this.loadDSTripTimings();
        await this.loadDSUserSettings();

        this.applyUserSettings('checkBoxShowSelectedCell', this.userSettings.ShowSelectedCell);
        this.applyUserSettings('checkBoxUseAdvancedFiltering', this.userSettings.UseAdvancedFiltering);

        this.hubConnection = new HubConnectionBuilder().withUrl("/tripTimingsHub").build();
        await this.hubConnection.start()
            .then(function () {

            })
            .catch(function (err) {
                return console.error(err.toString());
            });
        this.hubConnection.on("ReceiveMessageAddedTripTiming", await this.updatePushedDataAddedTripTiming);
        this.hubConnection.on("ReceiveMessageDeletedTripTiming", await this.updatePushedDataDeletedTripTiming);
    }

    // DATA
    loadDSTripTimings = async () => {
        var data = await ServiceGeneral.getAllTripTimingsByEventId(parseInt(sessionStorage.getItem('currentEventId'), 10));
        if (data !== null && !data.hasOwnProperty("error")) {
            this.setState({ dataSourceTripTimings: data });
        } else {
           // this.setErrorHeader(true);
        }
    }

    loadDSUserSettings = async () => {
        var settings = await ServiceUsers.getUserSettingsTripTimingsByUserId(parseInt(sessionStorage.getItem("currentUserId"), 10));
        settings = (settings !== null ? settings : this.userSettings);
        if (!settings.hasOwnProperty("error")) {
            this.userSettings = settings;
        } else {
            // this.setErrorHeader(true);
        }
    }

    // GRID
    gridOnCellClick = async (e) => {
        if (e.rowType === 'data') {
            if (e.column.name == 'ActionColumnEdit') {
                this.setState({ locationDepartureId: e.data.LocationDepartureId });
                this.setState({ locationArrivalId: e.data.LocationArrivalId });
                this.refPopupShuttlesAdminTripTimingsEdit.setShuttlesEditMode(-1, -1);
                this.popupShuttlesAdminTripTimingsEditInstance.show();
            } else if (e.column.name == 'ActionColumnDelete') {
                let result = await confirm('Are you sure you want to delete ' + e.data.LocationDeparture + ' - ' + e.data.LocationArrival + '?', "Confirm delete");
                if (result) {
                    var resultDelete = await ServiceGeneral.deleteTripTiming(parseInt(sessionStorage.getItem('currentEventId'), 10), e.data.LocationDepartureId, e.data.LocationArrivalId);
                    if (resultDelete === true) {
                        HxToast.showToast('Trip timing deleted successfully', 'success', 'bottom right', 'up-stack');
                        this.hubConnection
                            .invoke("SendUpdateInfoDeletedTripTiming")
                            .catch(function (err) {
                                return console.error(err.toString());
                            });
                    } else {
                        HxToast.showToast('Trip timing delete failed!', 'error', 'bottom right', 'up-stack');
                    }
                }
            }
        }
    }

    gridOnFocusedCellChanging = (e) => {
        if (this.showSelectedCell) {
            let elements = document.getElementsByClassName("hx-datagrid-cell-border");
            if (elements) {
                for (var i = 0; i < elements.length; i++) {
                    elements[i].classList.remove('hx-datagrid-cell-border');
                }
            }

            e.cellElement[0].classList.add('hx-datagrid-cell-border');
        }
    }

    gridOnInitialized = (e) => {
        // set instance
        this.dataGridInstance = e.component;

        // set focused row
        this.dataGridInstance.option('focusedRowIndex', 0);

        this.onInitialized(e.component);
    }

    // EXPORT TO EXCEL
    exportToExcel = async (name) => {

        const workbook = new Workbook();

        var sheet = workbook.addWorksheet("Trip Timings");
        sheet.columns = [
            { width: 30 }, { width: 30 }, { width: 40 }, { width: 25 }, { width: 20 }
        ];
        sheet.pageSetup.margins = {
            left: 0.5, right: 0.5,
            top: 0.5, bottom: 0.5,
            header: 0, footer: 0
        };

        exportDataGrid({
            worksheet: sheet,
            component: this.dataGridInstance,
            customizeCell: this.customizeExcelCell,
            keepColumnWidths: false
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), name);
            });
        })
    };

    customizeExcelCell(options) {
        const { gridCell, excelCell } = options;

        if (gridCell.rowType === 'header' && gridCell.column.name !== 'DummyColumn') {
            excelCell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FF000000' }
            };
            excelCell.font = {
                color: { argb: 'FFFFFFFF' }
            }
        }
    }

    // ADD RELATION BUTTON
    buttonAddTripTiming_OnClick = () => {
        this.setState({ locationDepartureId: -1 });
        this.setState({ locationArrivalId: -1 });
        this.refPopupShuttlesAdminTripTimingsEdit.setShuttlesEditMode(-1, -1);
        this.popupShuttlesAdminTripTimingsEditInstance.show();
    }

    // POPUP EDIT
    popupShuttlesAdminTripTimingsEdit_OnInitialized = (instance) => {
        this.popupShuttlesAdminTripTimingsEditInstance = instance;
    }

    popupShuttlesAdminTripTimingsEdit_OnHiding = async () => {
        await this.loadDSTripTimings();
    }

    popupShuttlesAdminTripTimingsEdit_OnUpdate = async (success) => {
        if (success) {
            HxToast.showToast(this.state.locationDepartureId === -1 && this.state.locationArrivalId === -1 ? 'Trip timing added successfully' : 'Trip timing updated successfully', 'success', 'bottom right', 'up-stack');
            this.hubConnection
                .invoke("SendUpdateInfoAddedTripTiming")
                .catch(function (err) {
                    return console.error(err.toString());
                });
        } else {
            HxToast.showToast(this.state.locationDepartureId === -1 && this.state.locationArrivalId === -1 ? 'Failed to add trip timing' : 'Failed to update trip timing', 'error', 'bottom right', 'up-stack');
        }
    }

    // HUB
    updatePushedDataAddedTripTiming = async () => {
        await this.loadDSTripTimings();
    }

    updatePushedDataDeletedTripTiming = async () => {
        await this.loadDSTripTimings();
    }

    // RENDERING
    renderEditTemplate = (e) => {
        return <div style={{ display: 'flex', justifyContent: 'start' }}>
            <div style={{ width: '40px' }}><i className='dx-icon-edit' style={{ color: '#FF5722' }} /></div>
        </div>;
    }

    renderDeleteTemplate = (e) => {
        return <div style={{ display: 'flex', justifyContent: 'start' }}>
            <div style={{ width: '40px' }}><i className='dx-icon-trash' style={{ color: '#FF5722' }} /></div>
        </div>;
    }

    render() {

        return (
            <React.Fragment>
                <div className='content-block trip-timings-content-block'>
                    <div className='shuttles-admin-trip-timings-datagrid-buttons'>
                        <span className='shuttles-admin-trip-timings-datagrid-button'>
                            <Button
                                text="Add trip timing"
                                icon="add"
                                onClick={this.buttonAddTripTiming_OnClick} />
                        </span>
                    </div>
                    <div className='dx-card shuttles-admin-trip-timings-datagrid-grid-card'>
                        <DataGrid
                            dataSource={this.state.dataSourceTripTimings}
                            keyExpr={['LocationDepartureId', 'LocationArrivalId']}
                            height='calc(100% - 1px)'
                            width='100%'
                            allowColumnReordering={false}
                            allowColumnResizing={true}
                            allowGrouping={false}
                            columnAutoWidth={true}
                            columnResizingMode='widget'
                            filterSyncEnabled={true}
                            focusedRowEnabled={false}
                            remoteOperations={true}
                            repaintChangesOnly={true}
                            showBorders={false}
                            showColumnLines={true}
                            showRowLines={true}
                            wordWrapEnabled={false}
                            noDataText='No relations found.'
                            onCellClick={this.gridOnCellClick}
                            onFocusedCellChanging={this.gridOnFocusedCellChanging}
                            onInitialized={this.gridOnInitialized}
                        >

                            <Sorting mode="multiple" />
                            <ColumnChooser enabled={false} />
                            <FilterPanel visible={this.state.useAdvancedFiltering} />
                            <FilterRow visible={true} />
                            <GroupPanel visible={false} />
                            <HeaderFilter visible={true} />
                            <SearchPanel visible={true} />
                            <Paging enabled={false} defaultPageSize={20} />
                            <Scrolling
                                mode="standard"
                                preloadEnabled={true}
                                scrollByContent={true}
                                scrollByThumb={true}
                                showScrollbar='always'
                                useNative='false'
                            />
                            <Column
                                dataField='LocationDepartureId'
                                dataType='number'
                                caption='LocationDepartureId'
                                allowFiltering={false}
                                allowEditing={false}
                                fixed={true}
                                showInColumnChooser={false}
                                visible={false}
                            />
                            <Column
                                dataField='LocationArrivalId'
                                dataType='number'
                                caption='LocationArrivalId'
                                allowFiltering={false}
                                allowEditing={false}
                                fixed={true}
                                showInColumnChooser={false}
                                visible={false}
                            />
                            <Column
                                dataField='EventId'
                                dataType='number'
                                caption='EventId'
                                allowFiltering={false}
                                allowEditing={false}
                                fixed={true}
                                showInColumnChooser={false}
                                visible={false}
                            />
                            <Column
                                caption=''
                                width='20px'
                                allowFiltering={false}
                                allowEditing={false}
                                allowColumnResizing={false}
                                allowColumnReordering={false}
                                allowExporting={false}
                                fixed={true}
                                showInColumnChooser={false}
                            />
                            <Column
                                name='ActionColumnEdit'
                                caption=''
                                allowFiltering={false}
                                allowEditing={false}
                                allowColumnResizing={false}
                                allowColumnReordering={false}
                                allowExporting={false}
                                fixed={false}
                                showInColumnChooser={false}
                                visible={true}
                                width='40px'
                                alignment='center'
                                cellRender={this.renderEditTemplate}
                            />
                            <Column
                                dataField='LocationDeparture'
                                dataType='string'
                                caption='Departure'
                                minWidth={175}
                                sortIndex={0}
                                sortOrder='Asc'
                                width='auto'
                                allowFiltering={true}
                                allowResizing={true}
                                allowEditing={false}
                                fixed={false}
                                showInColumnChooser={true}
                                visible={true}
                            />
                            <Column
                                dataField='LocationArrival'
                                dataType='string'
                                caption='Arrival'
                                minWidth={175}
                                sortIndex={1}
                                sortOrder='Asc'
                                width='auto'
                                allowFiltering={true}
                                allowResizing={true}
                                allowEditing={false}
                                fixed={false}
                                showInColumnChooser={true}
                                visible={true}
                            />
                            <Column
                                dataField='Distance'
                                dataType='number'
                                caption='Distance'
                                minWidth={150}
                                width='auto'
                                allowFiltering={true}
                                allowResizing={true}
                                allowEditing={false}
                                fixed={false}
                                showInColumnChooser={true}
                                visible={true}
                            />
                            <Column
                                dataField='MinutesMin'
                                dataType='number'
                                caption='Minutes Min'
                                minWidth={150}
                                width='auto'
                                allowFiltering={true}
                                allowResizing={true}
                                allowEditing={false}
                                fixed={false}
                                showInColumnChooser={true}
                                visible={true}
                            />
                            <Column
                                dataField='MinutesMax'
                                dataType='number'
                                caption='Minutes Max'
                                minWidth={150}
                                width='auto'
                                allowFiltering={true}
                                allowResizing={true}
                                allowEditing={false}
                                fixed={false}
                                showInColumnChooser={true}
                                visible={true}
                            />
                            <Column
                                name='ActionColumnDelete'
                                caption=''
                                allowFiltering={false}
                                allowEditing={false}
                                allowColumnResizing={false}
                                allowColumnReordering={false}
                                allowExporting={false}
                                fixed={false}
                                showInColumnChooser={false}
                                visible={true}
                                width='40px'
                                alignment='center'
                                cellRender={this.renderDeleteTemplate}
                            />
                            <Column
                                name='DummyColumn'
                                caption=''
                                width='auto'
                            />
                        </DataGrid>
                    </div>
                </div>

                <PopupShuttlesAdminTripTimingsEdit
                    ref={ref => this.refPopupShuttlesAdminTripTimingsEdit = ref}
                    locationDepartureId={this.state.locationDepartureId}
                    locationArrivalId={this.state.locationArrivalId}
                    onInitialized={this.popupShuttlesAdminTripTimingsEdit_OnInitialized}
                    onHiding={this.popupShuttlesAdminTripTimingsEdit_OnHiding}
                    onUpdate={this.popupShuttlesAdminTripTimingsEdit_OnUpdate}
                />
            </React.Fragment>
        );

    }

}

export default ShuttlesAdminTripTimingsDataGrid

