import React from 'react';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { Popup } from 'devextreme-react/popup';
import List from 'devextreme-react/list';
import DataGrid, {
    RemoteOperations, Editing, ColumnChooser, GroupPanel, FilterPanel, FilterRow, HeaderFilter, Paging, Pager, Scrolling, Column, SearchPanel, KeyboardNavigation, Export, StateStoring, Sorting, Toolbar, Item
} from 'devextreme-react/data-grid';
import { Button } from 'devextreme-react/button';

import { confirm } from 'devextreme/ui/dialog';
import HxToast from '../../../../custom-components/hx-toast/HxToast';

import ServiceRelations from '../../../../../api/services/ServiceRelations';
import ServiceArtists from '../../../../../api/services/ServiceArtists';

import './PopupPickContact.scss'


// props
//      artistId (required)
//
//      onInitialized: callback
//      onUpdate: callback

class PopupPickContact extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dataSourceArtistContacts: [],
        };

        this.popupInstance = null;
        this.dataGridInstance = null;
        this.listContactsInstance = null;
    }

    // CALLBACKS
    onInitialized = (instance) => {
        if (this.props.onInitialized) {
            this.props.onInitialized(instance);  // callback
        }

    }
    onUpdate = (relationIds) => {
        if (this.props.onUpdate) {
            this.props.onUpdate(relationIds);  // callback
        }
    }

    // CALLABLES

    // EVENTS
    componentDidMount = async () => {
    }

    // DATA
    loadDataSourceArtistContacts = async () => {
        var data = await ServiceRelations.getAllArtistRelationsByEventId(parseInt(sessionStorage.getItem("currentEventId"), 10));
        if (data !== null && !data.hasOwnProperty("error")) {
            if (this.props.contacts) {
                var dsArtistContacts = [];
                var ignoreIds = [];
                //first get relations to remove
                for (var i = 0; i < data.length; i++) {
                    let found = this.props.contacts.find(x => x.RelationId == data[i].RelationId && x.ArtistId == data[i].ArtistId);
                    if (found !== undefined) {
                        ignoreIds.push(data[i].RelationId);
                    }
                }
                // then add relation only once
                for (var i = 0; i < data.length; i++) {
                    let used = ignoreIds.find(x => x == data[i].RelationId);
                    if (used == undefined) {
                        dsArtistContacts.push(data[i]);
                        ignoreIds.push(data[i].RelationId);
                    }
                }
            }

            this.setState({ dataSourceArtistContacts: dsArtistContacts });
        } else {
            //this.onLoadError();  // callback
        }
    }

    loadDataSourceArtistContactsORG = async () => {
        var data = await ServiceRelations.getAllArtistRelationsByEventId(parseInt(sessionStorage.getItem("currentEventId"), 10));
        if (data !== null && !data.hasOwnProperty("error")) {
            if (this.props.contacts) {
                var dsArtistContacts = [];
                var ignoreIds = [];
                for (var i = 0; i < data.length; i++) {
                    let found = this.props.contacts.find(x => x.RelationId == data[i].RelationId && x.ArtistId == data[i].ArtistId);
                    let foundOther = this.props.contacts.find(x => x.RelationId == data[i].RelationId);



                    if (found == undefined) {
                        let used = ignoreIds.find(x => x == data[i].RelationId);
                        if (used == undefined) {
                            dsArtistContacts.push(data[i]);
                            ignoreIds.push(data[i].RelationId);
                        }
                    } else {
                        ignoreIds.push(data[i].RelationId);
                    }
                }
            }

            this.setState({ dataSourceArtistContacts: dsArtistContacts });
        } else {
            //this.onLoadError();  // callback
        }
    }

    // POPUP
    popup_OnInitialized = (e) => {
        this.popupInstance = e.component;
        this.onInitialized(e.component);
    }

    popup_OnShowing = (e) => {

    }

    popup_OnShown = async (e) => {
        await this.loadDataSourceArtistContacts();
    }

    // BUTTON
    buttonUpdate_OnInitialized = (e) => {
        this.buttonUpdateInstance = e.component;
    }

    buttonUpdate_OnClick = (e) => {
        this.onUpdate(this.listContactsInstance.option('selectedItemKeys'));
        this.popupInstance.hide();
    }

    // LIST CONTACTS
    listContacts_OnInitialized = (e) => {
        this.listContactsInstance = e.component;
    }

    // RENDER
    listItemRender = (item) => {
        return (
            <div>
                <div>{item.RelationLastName} {item.RelationFirstName}</div>
            </div>
        );
    }

    render() {
        return (
            <React.Fragment>
                <Popup
                    dragEnabled={false}
                    hideOnOutsideClick={false}
                    showCloseButton={true}
                    showTitle={true}
                    width={300}
                    height='auto'
                    title="Add existing contact"
                    onInitialized={this.popup_OnInitialized}
                    onShown={this.popup_OnShown}
                    onShowing={this.popup_OnShowing}
                >
                    <div className="pas-dx-field">
                        <div className="dx-field-value pas-field">
                            <List
                                dataSource={this.state.dataSourceArtistContacts}
                                height={400}
                                keyExpr='RelationId'
                                pageLoadMode='scrollBottom'
                                showSelectionControls={true}
                                selectionMode='multiple'
                                selectAllMode='allPages'
                                itemRender={this.listItemRender}
                                onInitialized={this.listContacts_OnInitialized}
                            >
                            </List>

                        </div>
                    </div>
                    <div className='pas-buttons'>
                        <Button
                            onInitialized={this.buttonUpdate_OnInitialized}
                            width={120}
                            text="Save"
                            icon="save"
                            onClick={this.buttonUpdate_OnClick}
                        />
                    </div>

                </Popup>
            </React.Fragment>

        );
    }

}

export default PopupPickContact

