import React from 'react';
import { ShuttlesAdminFleet } from '../../../../components';

class shuttlesAdminFleet extends React.Component {
    render() {
        return (
            <ShuttlesAdminFleet />
        );
    }
}

export default shuttlesAdminFleet