import React from 'react';
import { Riders } from '../../components';

class riders extends React.Component {
    render() {
        return (
            <Riders />
        );
    }
}

export default riders