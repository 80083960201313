import React, { useState, useEffect } from 'react';
import './HXTile.scss'

function HXTile({ width, height, backgroundColor, color, borderColor, text, onClick }) {

    const style = () => ({
        width: (width ? width : 200),
        height: (height ? height : 100),
        backgroundColor: (backgroundColor ? backgroundColor : '#FFFFFF'),
        color: (color ? color : '#000000'),
        border: '2px solid ' + (borderColor ? borderColor : '#000000'),
    });


    function tile_OnClick(e) {
        if (onClick) {
            onClick(e);
        }
    }

    useEffect(() => {

    });

    return <span className="hx-tile" style={style()} onClick={tile_OnClick}>{text}</span>

}

export default HXTile

