import React from 'react';
import { Button } from 'devextreme-react/button';

import HXUserFilters from '../../../custom-components/hx-user-filters/HXUserFilters'
import ShuttlesAdminTripTimingsDataGrid from './sub-components/ShuttlesAdminTripTimingsDataGrid'
import HxPopupExportExcel from '../../../custom-components/hx-popup-excel-export/HxPopupExportExcel';
import PopupShuttlesAdminTripTimingsUserSettings from './sub-components/PopupShuttlesAdminTripTimingsUserSettings'

import './ShuttlesAdminTripTimings.scss';


class ShuttlesAdminTripTimings extends React.Component {
    constructor(props) {
        super(props);

        this.dataGridInstance = null;   

        this.popupExportExcelInstance = null;
        this.popupShuttlesAdminTripTimingsUserSettingsInstance = null;

        // filters
        this.loadedUserFilterValue = [];
  }

    popupExportExcel_OnInitialized = (instance) => {
        this.popupExportExcelInstance = instance;
    }

    popupExportExcel_OnSave = async (fileName) => {
        if (fileName) {
            await this.refDataGrid.exportToExcel(fileName);
        }
    }

    popupShuttlesAdminTripTimingsUserSettings_OnInitialized = (instance) => {
        this.popupShuttlesAdminTripTimingsUserSettingsInstance = instance;
    }

    popupShuttlesAdminTripTimingsUserSettings_OnUpdate = (elementId, value) => {
        this.refDataGrid.applyUserSettings(elementId, value)
    }

    exportTripTimings = async () => {
        this.popupExportExcelInstance.show();
    }

    buttonUserSettings_OnClick = (e) => {
        this.popupShuttlesAdminTripTimingsUserSettingsInstance.show();
    }

    // GRID
    grid_OnInitialized = (instance) => {
        this.dataGridInstance = instance;
        this.refUserFilters.setDataGridInstance(instance);
    }

    // USERFILTER
    userFilterLoaded = async (filterState) => {
        // display loadpanel
        this.dataGridInstance.beginCustomLoading("Loading...");

        this.dataGridInstance.beginUpdate();

        if (filterState.filterValue !== undefined) {
            this.dataGridInstance.option('filterValue', JSON.parse(filterState.filterValue));
            this.loadedUserFilterValue = this.dataGridInstance.option('filterValue');
        } else {
            this.loadedUserFilterValue = [];
        }

        this.dataGridInstance.endUpdate();

        // hide loadpanel
        this.dataGridInstance.endCustomLoading();
    }

    clearUserFilter = () => {
        this.dataGridInstance.clearFilter();
    }

    userFilterError = (error) => {
        //this.setErrorHeader(error);
    }

    render() {
        return (
            <React.Fragment>
                <div className='shuttles-admin-trip-timings-main-div'>
                    <div className='shuttles-admin-trip-timings-content-block'>
                        <div id='toolBarTripTimings' className='hx-toolbar-main-div'>
                            <div className='hx-toolbar-left'>
                                <span>
                                    <Button
                                        icon="xlsxfile"
                                        stylingMode='outlined'
                                        text="Trip Timings"
                                        onClick={this.exportTripTimings} />
                                </span>
                            </div>
                            <div className='hx-toolbar-right'>
                                <span>
                                    <HXUserFilters
                                        ref={ref => (this.refUserFilters = ref)}
                                        onUserFilterLoaded={this.userFilterLoaded}
                                        onClearFilter={this.clearUserFilter}
                                        onError={this.userFilterError}
                                        filterTypeName='triptimings'
                                    />
                                </span>
                                <span>
                                    <Button
                                        icon='preferences'
                                        stylingMode='text'
                                        text=''
                                        onClick={this.buttonUserSettings_OnClick}
                                    />
                                </span>
                            </div>
                        </div>
                        <div className='shuttles-admin-trip-timings-grid-card'>
                            <ShuttlesAdminTripTimingsDataGrid
                                ref={ref => this.refDataGrid = ref}
                                onInitialized={this.grid_OnInitialized}
                            />
                        </div>
                    </div>
                </div>

                <HxPopupExportExcel
                    defaultName='Trip Timings'
                    onInitialized={this.popupExportExcel_OnInitialized}
                    onSave={this.popupExportExcel_OnSave}
                />

                <PopupShuttlesAdminTripTimingsUserSettings
                    onInitialized={this.popupShuttlesAdminTripTimingsUserSettings_OnInitialized}
                    onUpdate={this.popupShuttlesAdminTripTimingsUserSettings_OnUpdate}
                />
            </React.Fragment>
        );
    }
}

export default ShuttlesAdminTripTimings