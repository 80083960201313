import React from 'react';
import { Popup } from 'devextreme-react/popup';
import HxButton from '../hx-button/HxButton'
import DataGrid, { ColumnChooser, GroupPanel, FilterRow, HeaderFilter, Paging, Pager, Scrolling, Column, Button, Selection, Editing, KeyboardNavigation } from 'devextreme-react/data-grid';
import HxErrorMessage from '../hx-error-message/HxErrorMessage'
import HXPopupSaveFilter from './HXPopupSaveFilter';
import ServiceUsers from '../../../api/services/ServiceUsers'
import HxDialog from '../hx-dialog/HxDialog'

import './HXPopupUserFilters.scss'

// props
//      userFilters
// callbacks
//      onInitialized
//      onHiddenCallBack
//      onItemDblClick
//      onSaveUserFilterCallBack
//      onUpdateUserFilter
//      onDeleteUserFilter
//      onUpdateUserFilterName
//      onSwitchUserFilterDefault
// callables


class HXPopupUserFilters extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dataSourceUserFilters: []
        };

        this.loadDataSourceUserFilters = this.loadDataSourceUserFilters.bind(this);

        this.popupInstance = null;

        this.dataGridInstance = null;

        this.setErrorHeader = this.setErrorHeader.bind(this);

        this.popupFilterNameInstance = null;

        this.filterTypeName = this.props.filterTypeName;

        // used to select row after add and other things
        this.idToSelect = -1;

        this.contextMenuMode = 'single';

        this.userSettings = {
            DefaultFilterId: -1
        };
    }

    componentDidMount = async () => {
       // await this.loadDataSourceUserFilters();
    }

    // CALLBACKS
    onInitializedCallBack = (component) => {
        this.props.onInitialized(component);
    }

    onHiddenCallBack = (e) => {
        this.props.onHidden(e);
    }

    onItemDblClickCallBack = (id) => {
        this.props.onItemDblClick(id);
    }

    onSaveUserFilterCallBack = async (name, isDefault) => {
        var id = await this.props.onSaveUserFilter(name, isDefault);
        this.idToSelect = id;

        await this.loadDataSourceUserFilters();
    }

    onUpdateUserFilterCallBack = async (id, name, isDefault) => {
        await this.props.onUpdateUserFilter(id, name, isDefault);;
        this.idToSelect = id;

        await this.loadDataSourceUserFilters();
    }

    onDeleteUserFilterCallBack = async (id) => {
        await this.props.onDeleteUserFilter(id);
        await this.loadDataSourceUserFilters();
    }

    onDeleteMultipleUserFiltersCallBack = async (ids) => {
        await this.props.onDeleteMultipleUserFilters(ids);
        await this.loadDataSourceUserFilters();
    }

    onUpdateUserFilterNameCallBack = async (id, name) => {
        await this.props.onRenameUserFilter(id, name);
        await this.loadDataSourceUserFilters();
    }

    onSwitchUserFilterDefaultCallBack = async (id) => {
        await this.props.onSwitchUserFilterDefault(id);

        this.idToSelect = id;

        await this.loadDataSourceUserFilters();
    }

    // DATA
    loadDataSourceUserFilters = async () => {
        this.userSettings = await this.loadUserSettings();

        var data = await ServiceUsers.getUserFiltersByEventIdUserIdUserFilterTypeName(parseInt(sessionStorage.getItem("currentEventId"), 10), parseInt(sessionStorage.getItem("currentUserId"), 10), this.filterTypeName);
        if (data !== null && !data.hasOwnProperty("error")) {
            data.forEach((userFilterRecord) => {
                userFilterRecord.IsDefault = (userFilterRecord.Id === this.userSettings.DefaultFilterId);
            })

            this.setState({ dataSourceUserFilters: data })
        } else {
            this.setErrorHeader(true);
        }
    }


    loadUserSettings = async () => {
        let result = false;
        let settings = [];

        switch (this.filterTypeName) {
            case "relations":
                settings = await ServiceUsers.getUserSettingsRelationsByUserId(parseInt(sessionStorage.getItem("currentUserId"), 10));
                break;
            case "locations":
                settings = await ServiceUsers.getUserSettingsLocationsByUserId(parseInt(sessionStorage.getItem("currentUserId"), 10));
                break;
            case "triptimings":
                settings = await ServiceUsers.getUserSettingsTripTimingsByUserId(parseInt(sessionStorage.getItem("currentUserId"), 10));
                break;
            case "shuttles":
                settings = await ServiceUsers.getUserSettingsShuttlesByUserId(parseInt(sessionStorage.getItem("currentUserId"), 10));
                break;
            case 'riders':
                settings = await ServiceUsers.getUserSettingsRidersByUserId(parseInt(sessionStorage.getItem("currentUserId"), 10));
                break;
            case 'products':
                settings = await ServiceUsers.getUserSettingsProductsByUserId(parseInt(sessionStorage.getItem("currentUserId"), 10));
                break;
            case 'shops':
                settings = await ServiceUsers.getUserSettingsShopsByUserId(parseInt(sessionStorage.getItem("currentUserId"), 10));
                break;
            default:
                break;
        }

        if (settings !== null && !settings.hasOwnProperty("error")) {
            result = settings;
        } else {
            this.setErrorHeader(true);
        }

        return result;
    }


    // POPUP
    popup_OnInitialized = (e) => {
        this.popupInstance = e.component;
        this.onInitializedCallBack(e.component);
    }

    popup_OnHidden = (e) => {
        this.onHiddenCallBack(e);
    }

    popup_OnShowing = async (e) => {
        this.idToSelect = this.props.loadedUserFilter ?? -1;

        await this.loadDataSourceUserFilters();
    }


    // POPUP SAVE VIEW
    popupSaveFilter_OnInitialized = (instance) => {
        this.popupSaveFilterInstance = instance;
    }

    popupSaveFilter_OnUpdate = async (id, name, isDefault, editMode) => {
        switch (editMode.toLowerCase().trim()) {
            case 'save':
                await this.onSaveUserFilterCallBack(name, isDefault);
                break;
             case 'update':
                await this.onUpdateUserFilterCallBack(id, name, isDefault);
                break;
             case 'rename':
                await this.onUpdateUserFilterNameCallBack(id, name);
                break;
            default:
                break;
        }
    }

    // GRID
    grid_OnInitialized = (e) => {
        this.dataGridInstance = e.component;
    }

    grid_OnRowDblClick = (e) => {
        this.actionSelectFilter(e.data.Id);
    }

    grid_onContentReady = (e) => {
        this.dataGridInstance.clearSelection();
        if (this.idToSelect !== -1) {
            this.dataGridInstance.option("focusedRowKey", this.idToSelect);
            this.dataGridInstance.selectRows(this.idToSelect);
        } else {
            this.dataGridInstance.option("focusedRowIndex", -1);
        }

    }

    grid_onSelectionChanged = (e) => {
       this.contextMenuMode = e.selectedRowKeys.length <= 1 ? 'single' : 'multi';
    }

    grid_OnContextMenuPreparing = (e) => {

        if (e.row.rowType !== 'data') {
            return;
        }

        // update selected and focused row (right click outside selection)
        var selectedKeys = this.dataGridInstance.getSelectedRowKeys();
        var focused = this.dataGridInstance.option("focusedRowKey");
        if (!selectedKeys.includes(focused)) {
            this.dataGridInstance.deselectAll();
            this.dataGridInstance.selectedRowKeys = [focused];
            this.contextMenuMode = 'single';
        }

        switch (this.contextMenuMode.toLowerCase().trim()) {
            case 'single':
                // e.items can be undefined
                if (!e.items) e.items = [];

                e.items.push({
                    text: 'Rename',
                    onItemClick: () => {
                        this.actionRenameFilter(e.row.data.Id, e.row.data.Name, (this.userSettings.DefaultFilterId === e.row.data.Id), 'rename');
                    }
                });

                e.items.push({
                    text: 'Select filter',
                    onItemClick: () => {
                        this.actionSelectFilter(e.row.data.Id);
                    }
                });

                e.items.push({
                    text: 'Delete filter',
                    onItemClick: async () => {
                        await this.actionDeleteFilter(e);
                    }
                });

                e.items.push({
                    text: (this.userSettings.DefaultFilterId === e.row.data.Id ? 'Unmark default' : 'Mark as default'),
                    onItemClick: async () => {
                        await this.actionSwitchDefault(e.row.data.Id)
                    }
                });

                break;
            case 'multi':
                // e.items can be undefined
                if (!e.items) e.items = [];

                e.items.push({
                    text: 'Delete filters',
                    onItemClick: async () => {
                        await this.actionDeleteMultipleFilters(e);
                    }
                });
                break;
            default:
                break;
        }
    }

    buttonUpdate_OnClick = (e) => {
        this.actionUpdateFilter(e.row.data.Id, e.row.data.Name, (this.userSettings.DefaultFilterId === e.row.data.Id), 'update');
    }

    buttonNew_OnClick = (e) => {
        this.actionSaveFilter();
    }

    buttonSetAsDefault_OnClick = async (e) => {
        await this.actionSwitchDefault(e.row.data.Id);
    }

    // ACTIONS
    actionSaveFilter = () => {
        this.refPopupSaveFilter.setValues(-1, '', false, 'save');
        this.popupSaveFilterInstance.show();
    }

    actionUpdateFilter = (id, name, isDefault, editMode) => {
        this.refPopupSaveFilter.setValues(id, name, isDefault, editMode);
        this.popupSaveFilterInstance.show();
    }

    actionDeleteFilter = async (e) => {
        var result = await HxDialog.showDialogYesNo('Delete filter', 'Delete filter ' + e.row.data['Name'] + '?')
        if (result === true) {
            await this.onDeleteUserFilterCallBack(e.row.data['Id']);
        }
    }

    actionDeleteMultipleFilters = async (e) => {
        var rows = this.dataGridInstance.getSelectedRowsData();
        var name = '';
        var ids = [];
        rows.forEach(function (data) {
            name = name + '&nbsp;&nbsp;' + data.Name + '<br />';
            ids.push(data.Id);
        });
        var result = await HxDialog.showDialogYesNo('Delete filters', 'Delete filters?<br /><br />' + name)
        if (result === true) {
            await this.onDeleteMultipleUserFiltersCallBack(ids);
        }
    }

    actionSelectFilter = (id) => {
        this.onItemDblClickCallBack(id);
        this.popupInstance.hide();
    }

    actionRenameFilter = (id, name, isDefault, editMode) => {
        this.refPopupSaveFilter.setValues(id, name, isDefault, editMode);
        this.popupSaveFilterInstance.show();
    }

    actionSwitchDefault = async (id) => {
        await this.onSwitchUserFilterDefaultCallBack(id);
    }

    // ERROR MESSAGE
    setErrorHeader = (show) => {
        if (show) {
            this.refHxErrorMessage.show();
        } else {
            this.refHxErrorMessage.hide();
        }
    }

    // RENDERING
    renderActionHeaderTemplate = () => {
        return <HxButton text="New" width="100px" onClick={this.buttonNew_OnClick} />;
    }

    renderIsDefaultTemplate = (data) => {
        return (data.data.IsDefault ? <i className='dx-icon-favorites' /> : null);
    }

    render() {
        return (
            <React.Fragment>
                <Popup
                    dragEnabled={false}
                    hideOnOutsideClick={false}
                    showTitle={true}
                    showCloseButton={true}
                    title='Filters'
                    width={500}
                    onInitialized={this.popup_OnInitialized}
                    onShowing={this.popup_OnShowing}
                    onHidden={this.popup_OnHidden}
                >
                    <HxErrorMessage
                        ref={ref => (this.refHxErrorMessage = ref)}
                        id='error-message-popup-user-filters'
                        message='Could not load filters!'
                        visible={false}
                    />

                    <DataGrid
                        allowColumnReordering={false}
                        allowColumnResizing={false}
                        dataSource={this.state.dataSourceUserFilters}
                        filterSyncEnabled={true}
                        keyExpr='Id'
                        focusedRowEnabled={true}
                        noDataText='No filters found.'
                        remoteOperations={true}
                        showBorders={true}
                        showColumnLines={false}
                        showRowLines={true}
                        wordWrapEnabled={true}
                        onInitialized={this.grid_OnInitialized}
                        onRowDblClick={this.grid_OnRowDblClick}
                        onContextMenuPreparing={this.grid_OnContextMenuPreparing}
                        onContentReady={this.grid_onContentReady}
                        onSelectionChanged={this.grid_onSelectionChanged}
                    >
                        <ColumnChooser enabled={false} />
                        <FilterRow visible={true} />
                        <GroupPanel visible={false} />
                        <HeaderFilter
                            visible={true}
                            allowSearch={true}
                        />
                        <Selection
                            mode="multiple"
                            selectAllMode="allPages"
                            showCheckBoxesMode="none"
                        />
                        <Scrolling mode="standard" />
                        <Column
                            dataField='IsDefault'
                            allowFiltering={false}
                            width={30}
                            cellRender={this.renderIsDefaultTemplate}
                            caption=''
                        />
                        <Column
                            dataField='Name'
                            caption='Name'
                            sortIndex={0}
                            sortOrder='Asc'
                        />
                        <Column
                            caption='Action'
                            type='buttons'
                            headerCellRender={this.renderActionHeaderTemplate}
                            width={100}
                        >
                            <Button
                                name='buttonSetAsDefault'
                                text='Mark/Unmark Default'
                                icon='favorites'
                                onClick={this.buttonSetAsDefault_OnClick}
                            />
                            <Button
                                name='buttonUpdate'
                                text='Update filter'
                                icon='save'
                                onClick={this.buttonUpdate_OnClick}
                            />
                        </Column>
                    </DataGrid>

                </Popup>
                <HXPopupSaveFilter
                    ref={ref => (this.refPopupSaveFilter = ref)}
                    title='Save filter ...'
                    onInitialized={this.popupSaveFilter_OnInitialized}
                    onUpdate={this.popupSaveFilter_OnUpdate}
                    filterTypeName={this.filterTypeName}
                />
            </React.Fragment>
        );
    }
}

export default HXPopupUserFilters

