import globalConfig from '../../config/global.js';

class ServiceLocations {
    ////////////////////////////////////////////////////
    // LOCATIONS
    ////////////////////////////////////////////////////

    static getAllLocationsByEventId = async (eventId) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Locations/GetAllLocationsByEventId?eventId=" + encodeURIComponent(eventId)) // return null or data object
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static getLocationsDropDownForTripTimingByEventId = async (eventId) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Locations/GetLocationsDropDownForTripTimingByEventId?eventId=" + encodeURIComponent(eventId)) // return null or data object
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }
    
    static getLocationByEventIdLocationId = async (eventId, locationId) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Locations/GetLocationByEventIdLocationId?eventId=" + encodeURIComponent(eventId) + '&locationId=' + encodeURIComponent(locationId)) // return null or data object
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static addLocation = async (model) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Locations/AddLocation", {
            method: 'POST',
            body: JSON.stringify(model),
            headers: {
                'Content-type': 'application/json; charset=UTF-8'
            }
        })
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(error => {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static updateLocation = async (model) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Locations/UpdateLocation", {
            method: 'POST',
            body: JSON.stringify(model),
            headers: {
                'Content-type': 'application/json; charset=UTF-8'
            }
        })
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(error => {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static deleteLocation = async (locationId) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Locations/DeleteLocation?locationId=" + encodeURIComponent(locationId)) // return true or false
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static doesLocationNameExist = async (eventId, name, locationIdToExclude) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Locations/DoesLocationNameExist?eventId=" + encodeURIComponent(eventId) + "&name=" + encodeURIComponent(name) + "&locationIdToExclude=" + encodeURIComponent(locationIdToExclude)) // return true or false
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static checkIfShopCanBeDeleted = async (id) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Locations/CheckIfShopCanBeDeleted?id=" + encodeURIComponent(id)) // return null or data object
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static checkIfShopExists = async (id, name) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Locations/CheckIfShopExists?id=" + encodeURIComponent(id) + "&name=" + encodeURIComponent(name)) // return null or data object
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static getShopById = async (id) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Locations/GetShopInfoById?shopId=" + encodeURIComponent(id)) // return null or data object
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static getShopsInfo = async () => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Locations/GetShopsInfo") // return null or data object
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static updateLocationName = async (id, name) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Locations/UpdateLocationName?id=" + encodeURIComponent(id) + "&name=" + encodeURIComponent(name))
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static saveShop = async (shopsInfoModel) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Locations/SaveShop", {
            method: 'POST',
            body: JSON.stringify(shopsInfoModel),
            headers: {
                'Content-type': 'application/json; charset=UTF-8'
            }
        })
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(error => {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static updateShop = async (model) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Locations/UpdateShop", {
            method: 'POST',
            body: JSON.stringify(model),
            headers: {
                'Content-type': 'application/json; charset=UTF-8'
            }
        })
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(error => {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static deleteShop = async (id) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Locations/DeleteShop", {
            method: 'POST',
            body: JSON.stringify(id),
            headers: {
                'Content-type': 'application/json; charset=UTF-8'
            }
        })
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(error => {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static getAllLocationTypesDropDown = async () => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Locations/GetAllLocationTypesDropDown") // return null or data object
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static getShopsDropDown = async () => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Locations/GetShopsDropDown") // return null or data object
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }




}

export default ServiceLocations;
