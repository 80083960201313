import React from 'react';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import Button from 'devextreme-react/button';
import UserPanel from '../user-panel/UserPanel';
import './Header.scss';
import { Template } from 'devextreme-react/core/template';
import globalIcons from '../../config/globalIcons.js';


export default function Header({ menuToggleEnabled, title, toggleMenu, redirectShuttles, redirectArtists, redirectDrivers, redirectLocations, redirectFleet, redirectPickDriver, redirectStats }) {

     const showIcons = (sessionStorage.getItem('currentUserIsShuttlesAdministrator') == 'true' || sessionStorage.getItem('currentUserIsShuttlesUser') == 'true');

    if (showIcons) {
        return (
            <header className={'header-component'}>
                <Toolbar className={'header-toolbar'}>
                    <Item
                        visible={menuToggleEnabled}
                        location={'before'}
                        widget={'dxButton'}
                        cssClass={'menu-button'}
                    >
                        <Button icon="menu" stylingMode="text" onClick={toggleMenu} />
                    </Item>
                    <Item
                        location={'before'}
                        cssClass={'header-title'}
                        text={title}
                        visible={!!title}
                    />
                    <Item
                        location={'center'}
                        widget={'dxButton'}
                        cssClass={'menu-button'}
                    >
                        <div style={{ display: 'flex' }}>
                            <Button icon={globalIcons.menu_shuttles} stylingMode="text" onClick={redirectShuttles} className="header-icons" />
                            <Button icon={globalIcons.menu_artists} stylingMode="text" onClick={redirectArtists} className="header-icons" />
                            <Button icon={globalIcons.menu_drivers} stylingMode="text" onClick={redirectDrivers} className="header-icons" />
                            <Button icon={globalIcons.menu_locations} stylingMode="text" onClick={redirectLocations} className="header-icons" />
                            <Button icon={globalIcons.menu_fleet} stylingMode="text" onClick={redirectFleet} className="header-icons" />
                            <Button icon={globalIcons.menu_user_pick} stylingMode="text" onClick={redirectPickDriver} className="header-icons" />
                            <Button icon={globalIcons.menu_stats} stylingMode="text" onClick={redirectStats} className="header-icons" />
                        </div>
                    </Item>
                    <Item
                        location={'after'}
                        locateInMenu={'auto'}
                        menuItemTemplate={'userPanelTemplate'}
                    >
                        <Button
                            className={'user-button authorization'}
                            width={210}
                            height={'100%'}
                            stylingMode={'text'}
                        >
                            <UserPanel menuMode={'context'} />
                        </Button>
                    </Item>
                    <Template name={'userPanelTemplate'}>
                        <UserPanel menuMode={'list'} />
                    </Template>
                </Toolbar>
            </header>
        )
    } else {
        return (
            <header className={'header-component'}>
                <Toolbar className={'header-toolbar'}>
                    <Item
                        visible={menuToggleEnabled}
                        location={'before'}
                        widget={'dxButton'}
                        cssClass={'menu-button'}
                    >
                        <Button icon="menu" stylingMode="text" onClick={toggleMenu} />
                    </Item>
                    <Item
                        location={'before'}
                        cssClass={'header-title'}
                        text={title}
                        visible={!!title}
                    />
                    <Item
                        location={'after'}
                        locateInMenu={'auto'}
                        menuItemTemplate={'userPanelTemplate'}
                    >
                        <Button
                            className={'user-button authorization'}
                            width={210}
                            height={'100%'}
                            stylingMode={'text'}
                        >
                            <UserPanel menuMode={'context'} />
                        </Button>
                    </Item>
                    <Template name={'userPanelTemplate'}>
                        <UserPanel menuMode={'list'} />
                    </Template>
                </Toolbar>
            </header>
        )
    }


 
}
