import React from 'react';
import globalConfig from '../../../config/global.js';
import Map from 'devextreme-react/map';
import './HxMap.scss'

const mapHost = globalConfig.settings.geoCoding.geoCodingHost;
const mapKey = globalConfig.settings.geoCoding.geoCodingMapKey;
const mapProvider = globalConfig.settings.geoCoding.geoCodingMapProvider;

const defaultCenter = { lat: 51.2330401000, lng: 5.0641351000 };
const width = '200px';
const height = '200px';
const routes = [51.2330401000,  5.0641351000];

class HxMap extends React.Component { 
    constructor(props) {
        super(props);

        this.state = {
            defaultCenter: (this.props.defaultCenter ? this.props.defaultCenter : defaultCenter),
            width: (this.props.width ? this.props.width : width),
            height: (this.props.height ? this.props.height : height),
            routes: (this.props.routes ? this.props.routes : routes)
        }
    } 

    // CALLABLES
    setCenter = (obj) => {
        this.setState({ defaultCenter: obj });
    }


    // RENDER
    render() {
        console.log("hxmap " + this.state.routes.length);
        return (
            <React.Fragment>
                <div>
                    <Map
                        defaultCenter={this.state.defaultCenter}
                        width={this.state.width}
                        height={this.state.height}
                        routes={this.state.routes}
                        defaultZoom={12}
                        markers={[{ location: this.state.defaultCenter, tooltip: { text: 'Times Square' }}]}
                        apiKey={mapKey}
                        provider={mapProvider}
                        type="roadmap">
                    </Map>
                </div>
            </React.Fragment>
        );
    }


}

export default HxMap;