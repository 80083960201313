import notify from 'devextreme/ui/notify';

class HxToast {

    static showToast(message, type, position, direction) {
        notify({
            message: message,
            height: 60,
            width: 300,
            minWidth: 300,
            type: type,
            displayTime: (type == 'error' ? 10000 : 3000),
            animation: {
                show: {
                    type: 'fade', duration: 700, from: 0, to: 1,
                },
                hide: { type: 'fade', duration: 700, to: 0 },
            },
            closeOnClick: true,
            //onHiding: function (e) {
            //    e.cancel = (type == 'error');
            //},
        }, {
            position,
            direction,
        });

    }
}

export default HxToast;