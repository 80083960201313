import { HomePage, TasksPage, ProfilePage, RidersPage, RidersAdminShops, RidersAdminArtists, RidersAdminProducts, ShuttlesPage, ShuttlesAdminPage, ShuttlesAdminArtists, ShuttlesAdminRelations, ShuttlesAdminLocations, ShuttlesAdminTripTimings, UsersAdminPage, ShuttlesAdminFleet, ShuttlesOverView } from './pages';
import { withNavigationWatcher } from './contexts/navigation';

const routes = [
    {
        path: '/tasks',
        element: TasksPage
    },
    {
        path: '/profile',
        element: ProfilePage
    },
    {
        path: '/home',
        element: HomePage
    },
    {
        path: '/riders',
        element: RidersPage
    },
    {
        path: '/ridersadmin',
        element: RidersPage
    },
    {
        path: '/ridersadminartists',
        element: RidersAdminArtists
    },
    {
        path: '/ridersadminshops',
        element: RidersAdminShops
    },
    {
        path: '/ridersadminproducts',
        element: RidersAdminProducts
    },
    {
        path: '/shuttles',
        element: ShuttlesPage
    },
    {
        path: '/shuttlesadmin',
        element: ShuttlesAdminPage
    },
    {
        path: '/shuttlesadminartists',
        element: ShuttlesAdminArtists
    },
    {
        path: '/shuttlesadminrelations',
        element: ShuttlesAdminRelations
    },
    {
        path: '/shuttlesadminlocations',
        element: ShuttlesAdminLocations
    },
    {
        path: '/shuttlesadmintriptimings',
        element: ShuttlesAdminTripTimings
    },
    {
        path: '/shuttlesadminfleet',
        element: ShuttlesAdminFleet
    },
    {
        path: '/shuttlesoverview',
        element: ShuttlesOverView
    },
    {
        path: '/users',
        element: UsersAdminPage
    }
    
];

export default routes.map(route => {
    return {
        ...route,
        element: withNavigationWatcher(route.element, route.path)
    };
});
