import React from 'react';
import { Popup } from 'devextreme-react/popup';
import TextBox from 'devextreme-react/text-box';
import Validator, { ValidationRule, AsyncRule } from 'devextreme-react/validator';
import { Button } from 'devextreme-react/button';
import { ValidationGroup } from 'devextreme-react/validation-group';
import ServiceProducts from '../../../../../api/services/ServiceProducts'
import ServiceLocations from '../../../../../api/services/ServiceLocations'
import { TagBox } from 'devextreme-react/tag-box';
import SelectBox from 'devextreme-react/select-box';
import ColorBox from 'devextreme-react/color-box';
import { NumberBox } from 'devextreme-react/number-box';

import './PopupAddEditProducts.scss'

// props
//      setValues: callable
//
//      onInitialized: callback
//      onUpdate: callback
//      onError: callback

class PopupAddEditProducts extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dataSourceProductTypes: [],
            dataSourceShops: [],
            product: {},
        };

        this.popupInstance = null;

        this.textBoxNameInstance = null;

        this.selectedShops = [];
        this.tagBoxShopsInstance = null;
         
        this.selectBoxShopsInstance = null;

        this.selectedProductTypeId = -1;
        this.selectBoxProductTypeInstance = null;
 
        this.colorBoxInstance = null;
        this.colorBoxTextInstance = null;

        this.numberBoxEKPInstance = null;
        
        this.textBoxArticleNoInstance = null;

        this.textBoxPackagingInstance = null;

        this.buttonSaveInstance = null

        this.validationGroupInstance = null;

        this.productId = -1;
        this.productName = '';
        this.productEditMode = '';
        this.productsInfoModel = {}

        this.allowMultiShops = false;
      }

    // CALLBACKS
    onInitialized = (instance) => {
        if (this.props.onInitialized) {
            this.props.onInitialized(instance);  // callback
        }
    }

    onUpdate = (productsInfoModel) => {
        if (this.props.onUpdate) {
            this.props.onUpdate(productsInfoModel);  // callback
        }
    }

    onSave = (productsInfoModel) => {
        if (this.props.onSave) {
            this.props.onSave(productsInfoModel);  // callback
        }
    }
    onError = (error) => {
        if (this.props.onError) {
            this.props.onError(error);
        }
    }

    // CALLABLES
    setValues = (id, editMode) => {
        this.productEditMode = editMode.toLowerCase().trim();
        this.productId = this.productEditMode != "new" ? id : -1;
    }

    // EVENTS
    componentDidMount = async () => {
        await this.loadDataSourceProductTypes();
        await this.loadDataSourceShops();
    }

    // DATA
    loadDataSourceProductTypes = async () => {
        var data = await ServiceProducts.getProductTypesDropDown();
        if (data !== null && !data.hasOwnProperty("error")) {
            this.setState({ dataSourceProductTypes: data })
        } else {
            this.onError(true);
        }
    }

    loadDataSourceShops = async () => {
        var data = await ServiceLocations.getShopsDropDown();
        if (data !== null && !data.hasOwnProperty("error")) {
            this.setState({ dataSourceShops: data })
        } else {
            this.onError(true);
        }
    }

    // POPUP
    popup_OnInitialized = (e) => {
        this.popupInstance = e.component;
        this.onInitialized(e.component);
    }

    popup_OnShowing = async(e) => {
        // reset first or textbox is not filled
        this.validationGroupInstance.reset();

        switch (this.productEditMode) {
            case 'new':
                this.popupInstance.option('title', 'Add new product');
                this.textBoxNameInstance.option('value', '');
                this.selectBoxProductTypeInstance.option('value', null)
                this.selectBoxShopsInstance.option('value', null)
                this.numberBoxEKPInstance.option('value', 0);
                this.textBoxArticleNoInstance.option('value', '');
                this.textBoxPackagingInstance.option('value', '');
                this.colorBoxInstance.option('value', null);
                this.colorBoxTextInstance.option('value', null);
                break;
            case 'update':
                await this.getProductInfoById(this.productId);

                this.popupInstance.option('title', 'Update ' + this.product.ProductName);
                this.textBoxNameInstance.option('value', this.product.ProductName);
                this.selectBoxProductTypeInstance.option('value', this.product.ProductTypeId)

                if (this.allowMultiShops) {
                    this.tagBoxShopsInstance.option('value', this.product.ShopIds)
                } else {
                    this.selectBoxShopsInstance.option('value', this.product.ShopIds[0])
                }

                this.numberBoxEKPInstance.option('value', this.product.EKP);
                this.textBoxArticleNoInstance.option('value', this.product.ArticleNo);
                this.textBoxPackagingInstance.option('value', this.product.Packaging);
                this.colorBoxInstance.option('value', this.product.ColorHex);
                this.colorBoxTextInstance.option('value', this.product.ForeColorHex);
                break;
            default:
                break;
        }
    }

    popup_OnShown = (e) => {
        this.textBoxNameInstance.focus();
        document.getElementById('textBoxNameInput').select();
    }

    // TEXTBOX NAME
    textBoxName_OnInitialized = (e) => {
        this.textBoxNameInstance = e.component;
    }
    textBoxName_OnFocusIn = (e) => {
        // select all text
        e.element.querySelector('.dx-texteditor-input').select();
    }
    textBoxAttributes = {
        id: 'textBoxNameInput'
    }

    // TAGBOX
    tagBoxShops_OnInitialized = (e) => {
        this.tagBoxShopsInstance = e.component;
    }
    tagBoxShops_OnSelectionChanged(e) {
        this.selectedShops = this.tagBoxShopsInstance.option("selectedItems").map(function (item) {
            return item.Id;
        });
    }

    // SELECTBOX SHOPS
    selectBoxShops_OnInitialized = (e) => {
        this.selectBoxShopsInstance = e.component;
    }
    selectBoxShops_OnSelectionChanged = (e) => {
        this.selectedShops = [];
        if (this.selectBoxShopsInstance.option("selectedItem") !== null) {
            this.selectedShops.push(this.selectBoxShopsInstance.option("selectedItem").Id);
        }
    }

    // SELECTBOX PRODUCTTYPES
    selectBoxProductType_OnInitialized = (e) => {
        this.selectBoxProductTypeInstance = e.component;
    }
    selectBoxProductType_OnSelectionChanged = (e) => {
        this.selectedProductTypeId = e.selectedItem !== null ? e.selectedItem.Id : -1;
    }

    // TEXTBOX EKP
    numberBoxEKP_OnInitialized = (e) => {
        this.numberBoxEKPInstance = e.component;
    }
    numberBoxEKP_OnFocusIn = (e) => {
        // select all text
        e.element.querySelector('.dx-texteditor-input').select();
    }

    // TEXTBOX Article No
    textBoxArticleNo_OnInitialized = (e) => {
        this.textBoxArticleNoInstance = e.component;
    }
    textBoxArticleNo_OnFocusIn = (e) => {
        // select all text
        e.element.querySelector('.dx-texteditor-input').select();
    }

    // TEXTBOX Packaging
    textBoxPackaging_OnInitialized = (e) => {
        this.textBoxPackagingInstance = e.component;
    }
    textBoxPackaging_OnFocusIn = (e) => {
        // select all text
        e.element.querySelector('.dx-texteditor-input').select();
    }
    

    // COLORBOX
    colorBox_OnInitialized = (e) => {
        this.colorBoxInstance = e.component;
    }
    colorBoxText_OnInitialized = (e) => {
        this.colorBoxTextInstance = e.component;
    }

    // BUTTONS
    buttonSave_OnInitialized = (e) => {
        this.buttonSaveInstance = e.component;
    }

    saveProduct = () => {
        const res = this.validationGroupInstance.validate();
        res.status === "pending" && res.complete.then((r) => {
            if (r.status === 'valid') {
                this.productsInfoModel = {
                    Id: -1,
                    ProductTypeId: -1,
                    //ProductTypeId: this.selectedProductTypeId,
                    ColorId: -1,
                    PatternId: -1,
                    ShopIds: this.selectedShops,
                    ProductName: this.textBoxNameInstance.option("text").trim(),
                    EKP: 0,
                    //EKP: this.numberBoxEKPInstance.option("text"),
                    ArticleNo: this.textBoxArticleNoInstance.option('text').trim(),
                    Packaging: this.textBoxPackagingInstance.option('text').trim(),
                    ProductTypeName: '',
                    Shops: '',
                    ColorHex: this.colorBoxInstance.option("value"),
                    ForeColorHex: this.colorBoxTextInstance.option("value"),
                    PatternColorHex: '',
                    UsePattern: false,
                    PatternCssBackGround: '',
                    PatternCssBackGroundImage: '',
                    PatternCssBackGroundSize: '',
                    PatternCssBackGroundPosition: '',
                    PatternTransparency: 100
                }

                if ((this.colorBoxInstance.option('value') !== null && this.colorBoxTextInstance.option('value') !== null) || (this.colorBoxInstance.option('value') === null && this.colorBoxTextInstance.option('value') === null)) {
                    this.onSave(this.productsInfoModel);
                    this.popupInstance.hide();
                } else {
                    if (this.colorBoxInstance.option('value') === null) {
                        this.colorBoxInstance.focus();
                    } else {
                        this.colorBoxTextInstance.focus();
                    }
                }
            } else {
                this.textBoxNameInstance.focus();
            }
        });
    }

    updateProduct = () => {
        const res = this.validationGroupInstance.validate();
        res.status === "pending" && res.complete.then((r) => {
            if (r.status === 'valid') {
                this.productsInfoModel = {
                    Id: this.productId,
                    ProductTypeId: -1,
                    //ProductTypeId: this.selectedProductTypeId,
                    ColorId: this.product.ColorId,
                    PatternId: this.product.PatternId,
                    ShopIds: this.selectedShops,
                    ProductName: this.textBoxNameInstance.option("text"),
                    EKP: 0,
                    //EKP: this.numberBoxEKPInstance.option("text"),
                    ArticleNo: this.textBoxArticleNoInstance.option('text'),
                    Packaging: this.textBoxPackagingInstance.option('text'),
                    ProductTypeName: '',
                    Shops: '',
                    ColorHex: this.colorBoxInstance.option("value"),
                    ForeColorHex: this.colorBoxTextInstance.option("value"),
                    PatternColorHex: this.product.PatternColorHex,
                    UsePattern: this.product.UsePattern,
                    PatternCssBackGround: this.product.PatternCssBackGround,
                    PatternCssBackGroundImage: this.product.PatternCssBackGroundImage,
                    PatternCssBackGroundSize: this.product.PatternCssBackGroundSize,
                    PatternCssBackGroundPosition: this.product.PatternCssBackGroundPosition,
                    PatternTransparency: this.product.PatternTransparency
                }

                if ((this.colorBoxInstance.option('value') !== null && this.colorBoxTextInstance.option('value') !== null) || (this.colorBoxInstance.option('value') === null && this.colorBoxTextInstance.option('value') === null)) {
                    this.onUpdate(this.productsInfoModel);
                    this.popupInstance.hide();
                } else {
                    if (this.colorBoxInstance.option('value') === null) {
                        this.colorBoxInstance.focus();
                    } else {
                        this.colorBoxTextInstance.focus();
                    }
                }
            } else {
                this.textBoxNameInstance.focus();
            }
        });
    }

    buttonSave_OnClick = (e) => {
        if (this.productEditMode == 'new') {
            this.saveProduct();
        } else if (this.productEditMode == 'update') {
            this.updateProduct();
        }
    }
    buttonSaveAndNew_OnClick = (e) => {
        alert("save and new clicked");
    }

    buttonCancel_OnClick = (e) => {
        this.popupInstance.hide();
    }

    // VALIDATION GROUP
    validationGroup_OnInitialized = (e) => {
        this.validationGroupInstance = e.component;
    }

    validationCallback = async (e) => {
        var exists = await ServiceProducts.checkIfProductExists(this.productId, e.value);
        return !exists;
    }

    // FUNCTIONS
    getProductInfoById = async (id) => {
        var data = await ServiceProducts.getProductInfoById(parseInt(sessionStorage.getItem("currentEventId"), 10) , id, 'shop');
        if (data !== null && !data.hasOwnProperty("error")) {
            this.product = data;
        } else {
            console.log("error getProductInfoById" + id);
            this.onError(true);
        }
    }

    render() {
        let shops;
        if (this.allowMultiShops) {
            shops =
                <div className="paep-dx-field">
                    <div className="dx-field-label paep-label">Shops</div>
                <div className="dx-field-value">
                    <div className="popup-tagbox-shops">
                        <TagBox
                            dataSource={this.state.dataSourceShops}
                            valueExpr="Id"
                            displayExpr="Name"
                            multiline={true}
                            maxDisplayedTags={6}
                            showSelectionControls={true}
                            showClearButton={true}
                            onInitialized={this.tagBoxShops_OnInitialized}
                            onSelectionChanged={this.tagBoxShops_OnSelectionChanged}
                        />
                    </div>
                </div>
            </div>;
        } else {
            shops =
                <div className="paep-dx-field">
                    <div className="dx-field-label paep-label">Shops</div>
                    <div className="dx-field-value">
                        <div className="popup-selectbox-shops">
                            <SelectBox
                                dataSource={this.state.dataSourceShops}
                                valueExpr="Id"
                                displayExpr="Name"
                                searchEnabled={true}
                                showClearButton={true}
                                onInitialized={this.selectBoxShops_OnInitialized}
                                onSelectionChanged={this.selectBoxShops_OnSelectionChanged}
                            />
                        </div>
                    </div>
                </div>;
        }

        return (
            <React.Fragment>
                <Popup
                    dragEnabled={false}
                    hideOnOutsideClick={false}
                    showTitle={true}
                    width={350}
                    height={375}
                    showCloseButton={true}
                    onInitialized={this.popup_OnInitialized}
                    onShown={this.popup_OnShown}
                    onShowing={this.popup_OnShowing}
                >
                    <ValidationGroup onInitialized={this.validationGroup_OnInitialized}>
                        <div>
                            <div className="paep-dx-field">
                                <div className="dx-field-label paep-label">Name*</div>
                                <div className="dx-field-value">
                                    <TextBox
                                        onInitialized={this.textBoxName_OnInitialized}
                                        inputAttr={this.textBoxAttributes}
                                        onFocusIn={this.textBoxName_OnFocusIn}
                                        defaultValue=''
                                        hint='Enter name ...'
                                        width={250}
                                        validationMessageMode='always'
                                    >
                                        <Validator>
                                            <ValidationRule
                                                type="required"
                                                message="Required" />
                                            <AsyncRule
                                                message="Name already exists"
                                                validationCallback={this.validationCallback}
                                            />
                                        </Validator>
                                    </TextBox>
                                </div>
                            </div>

                            {shops}

                            <div className="paep-dx-field" style={{ display: 'none' }}>
                                <div className="dx-field-label paep-label">Type</div>
                                <div className="dx-field-value">
                                    <div className="popup-selectbox-producttypes">
                                        <SelectBox
                                            dataSource={this.state.dataSourceProductTypes}
                                            valueExpr="Id"
                                            displayExpr="Name"
                                            searchEnabled={true}
                                            showClearButton={true}
                                            width={250}
                                            onInitialized={this.selectBoxProductType_OnInitialized}
                                            onSelectionChanged={this.selectBoxProductType_OnSelectionChanged}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="paep-dx-field" style={{ display: 'none' }}>
                                <div className="dx-field-label paep-label">EKP</div>
                                <div className="dx-field-value">
                                    <NumberBox
                                        width={150}
                                        onInitialized={this.numberBoxEKP_OnInitialized}
                                        onFocusIn={this.numberBoxEKP_OnFocusIn}
                                        defaultValue={0}
                                    />
                                </div>
                            </div>

                            <div className="paep-dx-field">
                                <div className="dx-field-label paep-label">Article No</div>
                                <div className="dx-field-value">
                                    <TextBox
                                        width={150}
                                        onInitialized={this.textBoxArticleNo_OnInitialized}
                                        onFocusIn={this.textBoxArticleNo_OnFocusIn}
                                        defaultValue={''}
                                    />
                                </div>
                            </div>
                            <div className="paep-dx-field">
                                <div className="dx-field-label paep-label">Packaging</div>
                                <div className="dx-field-value">
                                    <TextBox
                                        width={150}
                                        onInitialized={this.textBoxPackaging_OnInitialized}
                                        onFocusIn={this.textBoxPackaging_OnFocusIn}
                                        defaultValue={''}
                                    />
                                </div>
                            </div>

                            <div className="paep-dx-field">
                                <div className="dx-field-label paep-label">Color</div>
                                <div className="dx-field-value">
                                    <ColorBox
                                        width={250}
                                        onInitialized={this.colorBox_OnInitialized}
                                        defaultValue='#ffffff'
                                        showClearButton={true}
                                    >
                                    </ColorBox>
                                </div>
                            </div>

                            <div className="paep-dx-field">
                                <div className="dx-field-label paep-label">Textcolor</div>
                                <div className="dx-field-value">
                                    <ColorBox
                                        width={250}
                                        onInitialized={this.colorBoxText_OnInitialized}
                                        defaultValue='#ffffff'
                                        showClearButton={true}
                                    >
                                    </ColorBox>
                                </div>
                            </div>
                        </div>
                    </ValidationGroup>
                    <div className="paep-buttons">
                        <Button
                            onInitialized={this.buttonSave_OnInitialized}
                            text='Save'
                            width={120}
                            icon="save"
                            onClick={this.buttonSave_OnClick}
                        />
                    </div>;
                </Popup>
            </React.Fragment>


        );
    }

}

export default PopupAddEditProducts

