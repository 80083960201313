import React from 'react';
import * as dayJS from 'dayjs';
import HxTags from '../../custom-components/hx-tags/HxTags'
import Toolbar, { Item } from 'devextreme-react/toolbar';
import PopupExportRider from './sub-components/PopupExportRider'
import PopupExportDayOverView from './sub-components/PopupExportDayOverView'
import PopupExportTotalsList from './sub-components/PopupExportTotalsList'
import PopupExportExcel from './sub-components/PopupExportExcel'
import PopupRidersUserSettings from './sub-components/PopupRidersUserSettings'
import HXUserFilters from '../../custom-components/hx-user-filters/HXUserFilters'

import DataGridRiders from './sub-components/DataGridRiders'

import HxErrorMessage from '../../custom-components/hx-error-message/HxErrorMessage'

import ServiceRiders from '../../../api/services/ServiceRiders'
import ServiceArtists from '../../../api/services/ServiceArtists'
import ServiceEvents from '../../../api/services/ServiceEvents'
import ServiceUsers from '../../../api/services/ServiceUsers'

import { Button } from 'devextreme-react/button';

import './Riders.scss'

class Riders extends React.Component {
    constructor(props) {
        super(props);

        this.dataSourceGigDates = [];
        this.dataSourceArtists = [];
        this.dataSourceSubArtistColors = [];

        // gigdates
        this.selectedGigDateIds = [];

        // artist tags
        this.selectedArtistIds = [];

        this.popupExportRiderInstance = null;

        this.popupExportDayOverViewInstance = null;

        this.popupExportExcelInstance = null;

        this.popupExportTotalsListInstance = null;

        this.dataGridRidersInstance = null;

        this.popupUserSettingsRiderInstance = null;

        this.userSettings = {
            UserId: parseInt(sessionStorage.getItem("currentUserId"), 10),
            DefaultViewId: -1,
            DefaultFilterId: -1,
            ExportColors: true,
            MultiSelectionGigDates: false,
            MultiSelectionArtists: true,
            UseBlanksForZeroes: true,
            UseColorsProducts: true,
            UseColorsArtists: true,
            ColorOriginProducts: 'shop',
            ColorOriginArtists: 'artisttype',
            ShowSelectedCell: false,
            UseAdvancedFiltering: false,
        }

        // filters
        this.loadedUserFilterValue = [];

        this.isInitialized = false;
    }

    // EVENTS
    componentDidMount = async () => {
        await this.initializeRider();
    }

    // DATA
    loadDataSourceArtists = async () => {
        var data = await ServiceArtists.getArtistNamesByGigDateIds(this.selectedGigDateIds);
        if (data !== null && !data.hasOwnProperty("error")) {
            this.dataSourceArtists = data;
        } else {
            this.setErrorHeader(true);
        }
    }

    loadDataSourceGigDates = async () => {
        var data = await ServiceEvents.getEventDatesDropDownByEventId(parseInt(sessionStorage.getItem("currentEventId"), 10), 'riders');
        if (data !== null && !data.hasOwnProperty("error")) {
            this.dataSourceGigDates = data;
        } else {
            this.setErrorHeader(true);
        }
    }

    loadDataSourceSubArtistColors = async () => {
        var data = await ServiceRiders.getSubArtistsColorsByEventIdGigDateIds(parseInt(sessionStorage.getItem("currentEventId"), 10), JSON.stringify(this.selectedGigDateIds), this.userSettings.ColorOriginArtists);
        if (data !== null && !data.hasOwnProperty("error")) {
            this.dataSourceSubArtistColors = data;
        } else {
            this.setErrorHeader(true);
        }
    }

    loadDataSourceUserSettings = async () => {
        var settings = await ServiceUsers.getUserSettingsRidersByUserId(parseInt(sessionStorage.getItem("currentUserId"), 10));
        if (settings !== null && !settings.hasOwnProperty("error")) {
            this.userSettings = settings;
        } else {
            this.setErrorHeader(true);
        }
    }

    initializeRider = async () => {
        await this.loadDataSourceUserSettings();

        this.selectedGigDateIds = [];
        this.selectedArtistIds = [];

        await this.loadDataSourceGigDates();

        if (this.dataSourceGigDates.length > 0) {
            this.selectedGigDateIds.push(this.dataSourceGigDates[0].Id);
        } 
        await this.loadDataSourceArtists();

        if (this.refGigDatesTags != null) {
            this.refGigDatesTags.setTags(this.convertGigDates(this.dataSourceGigDates));
        }

        if (this.refArtistsTags != null) {
            this.refArtistsTags.setTags(this.convertArtists(this.dataSourceArtists));
        }

        if (this.refDataGridRiders != null) {
            await this.refDataGridRiders.setSelectedArtistIds(this.selectedArtistIds);
            await this.refDataGridRiders.setSelectedGigDateIds(this.selectedGigDateIds, false);
            this.refDataGridRiders.setUserSettings(this.userSettings);
        }

        this.isInitialized = true;

    }

    //////////////////////////////////////////////////////////////
    // TAGS GIGDATES
    //////////////////////////////////////////////////////////////
    convertGigDates = () => {
        let gigDatesArray = [];

        if (this.dataSourceGigDates.length > 0) {

            this.dataSourceGigDates.forEach((item, index, array) => {
                let myDate = dayJS(this.dataSourceGigDates[index].GigDate).format('ddd DD/MM/YY');
                let selected = (this.selectedGigDateIds.indexOf(item.Id) > -1);
                gigDatesArray.push({ id: item.Id, text: myDate, selected: selected });
            });
        }

        return gigDatesArray;
    }

    tagGigDates_OnClick = async (e, selected) => {
        this.refGigDatesTags.disableTags();
        this.refArtistsTags.disableTags();

        let id = parseInt(e.currentTarget.id, 10);

        if (selected) {
            if (this.userSettings.MultiSelectionGigDates === false) {
                this.selectedGigDateIds.length = 0;
            }
            if (this.selectedGigDateIds.indexOf(id) === -1) {
                this.selectedGigDateIds.push(id);
            }
        } else {
            this.selectedGigDateIds.splice(this.selectedGigDateIds.indexOf(id), 1)
        }

        this.refGigDatesTags.setTags(this.convertGigDates(this.dataSourceGigDates));

        await this.loadDataSourceArtists();

        // remove selected artistids based on new ids
        const artistIds = [];
        for (var artist of this.dataSourceArtists) {
            artistIds.push(artist.Id);
        }
        const loopArtists = this.selectedArtistIds;
        this.selectedArtistIds = [];
        for (const selectedArtistId of loopArtists) {
            if (artistIds.includes(selectedArtistId)) {
                this.selectedArtistIds.push(selectedArtistId);
            }
        }

        this.refArtistsTags.setTags(this.convertArtists(this.dataSourceArtists));

        //this.dataGridRidersInstance.beginCustomLoading("Loading...");
        this.dataGridRidersInstance.beginUpdate();

        await this.refDataGridRiders.setSelectedGigDateIds(this.selectedGigDateIds, false);
        await this.refDataGridRiders.setSelectedArtistIds(this.selectedArtistIds);

        this.dataGridRidersInstance.endUpdate();
        //this.dataGridRidersInstance.endCustomLoading();

        this.refGigDatesTags.enableTags();
        this.refArtistsTags.enableTags();
    }



    //////////////////////////////////////////////////////////////
    // ARTIST TAGS
    //////////////////////////////////////////////////////////////
    convertArtists = () => {
        let artistsArray = [];

        if (this.dataSourceArtists.length > 0) {

            this.dataSourceArtists.forEach((item, index, array) => {
                let selected = (this.selectedArtistIds.indexOf(item.Id) > -1)
                artistsArray.push({ id: item.Id, text: item.Name, selected: selected })
            });

        }

        return artistsArray;
    }

    tagArtists_OnClick = async (e, selected) => {
        this.refGigDatesTags.disableTags();
        this.refArtistsTags.disableTags();

        let id = parseInt(e.currentTarget.id, 10);

        if (id !== -1) {
            if (selected) {
                if (this.userSettings.MultiSelectionArtists === false) {
                    this.selectedArtistIds.length = 0;
                }
                if (this.selectedArtistIds.indexOf(id) === -1) {
                    this.selectedArtistIds.push(id);
                }
            } else {
                this.selectedArtistIds.splice(this.selectedArtistIds.indexOf(id), 1)
            }
        } else {
            if (this.dataSourceArtists.length > 0) {
                this.dataSourceArtists.forEach((item, index, array) => {
                    this.selectedArtistIds.push(this.dataSourceArtists[index].Id);
                });
            }
        }

        this.refArtistsTags.setTags(this.convertArtists(this.dataSourceArtists));

        //this.dataGridRidersInstance.beginCustomLoading("Loading...");
        this.dataGridRidersInstance.beginUpdate();

        await this.refDataGridRiders.setSelectedArtistIds(this.selectedArtistIds);

        this.dataGridRidersInstance.endUpdate();
        //this.dataGridRidersInstance.endCustomLoading();

        this.refGigDatesTags.enableTags();
        this.refArtistsTags.enableTags();
   
    }

    dataGridRiders_OnInitialized = (instance) => {
        this.dataGridRidersInstance = instance;
        this.refUserFilters.setDataGridInstance(instance);
    }

    dataGridRiders_OnExportRider = () => {
        this.exportExcel();
    }

    dataGridRiders_OnConnectionError = () => {
        this.setErrorHeader(true);
    }



    // POPUPS EXCEL
    popupExportExcel_OnInitialized = (instance) => {
        this.popupExportExcelInstance = instance;
    }

    popupExportRider_OnInitialized = (instance) => {
        this.popupExportRiderInstance = instance;
    }

    popupExportDayOverView_OnInitialized = (instance) => {
        this.popupExportDayOverViewInstance = instance;
    }

    popupExportTotalsList_OnInitialized = (instance) => {
        this.popupExportTotalsListInstance = instance;
    }


    exportExcel = async () => {
        await this.loadDataSourceSubArtistColors();
        this.refPopupExportExcel.setDataSourceSubArtistColors(this.dataSourceSubArtistColors);
        this.refPopupExportExcel.setUserSettings(this.userSettings);
        this.refPopupExportExcel.setDataGridInstance(this.dataGridRidersInstance);
        this.popupExportExcelInstance.show();
    }

    exportRider = async () => {
        await this.loadDataSourceSubArtistColors();

        this.popupExportRiderInstance.show();
    }

    exportDayOverView = async () => {
       
        this.popupExportDayOverViewInstance.show();
    }

    exportTotalsList = async () => {
        this.popupExportTotalsListInstance.show();

    }

    openUserSettingsRiders = () => {
        this.popupUserSettingsRiderInstance.show();
    }

    popupUserSettings_OnInitialized = (instance) => {
        this.popupUserSettingsRiderInstance = instance;
    }

    popupUserSettings_OnUpdate = async (elementId, userSettings) => {
        if (!this.isInitialized) {
            return;
        }

        let reload = false;
        let reloadTags = false;
        let reloadGrid = false;

        this.userSettings = userSettings;

        this.refDataGridRiders.setUserSettings(this.userSettings);

        switch (elementId) {
            case 'checkBoxMultiSelectionGigDates':
                if (!this.userSettings.MultiSelectionGigDates && this.selectedGigDateIds.length > 1) {
                    // re initialize, coz some dates are truncated
                    await this.initializeRider();
                    reload = true;
                    reloadTags = true;
                    reloadGrid = false;
                }
                break;
            case 'checkBoxMultiSelectionArtists':
                if (!this.userSettings.MultiSelectionArtists && this.selectedArtistIds.length > 1) {
                    // re initialize, coz some artists are truncated
                    await this.initializeRider();
                    reload = true;
                    reloadTags = true;
                    reloadGrid = false;
                }
                break;
            case 'checkBoxUseBlanksForZeroes':
                reload = true;
                reloadTags = false;
                reloadGrid = true;
                break;
            case 'checkBoxUseColorsProducts':
                reload = true;
                reloadTags = false;
                reloadGrid = true;
                break;
            case 'radioButtonColorOriginProducts':
                reload = true;
                reloadTags = false;
                reloadGrid = true;
                break;
            case 'checkBoxUseColorsArtists':
                reload = true;
                reloadTags = false;
                reloadGrid = true;
                break;
            case 'radioButtonColorOriginArtists':
                reload = true;
                reloadTags = false;
                reloadGrid = true;
                break;
            default:
                break;
        }

        // reload screen
        if (reload) {
            if (reloadTags) {
                this.refGigDatesTags.setTags(this.convertGigDates(this.dataSourceGigDates));
                this.refArtistsTags.setTags(this.convertArtists(this.dataSourceArtists));
            }

            if (reloadGrid) {
                await this.refDataGridRiders.setSelectedGigDateIds(this.selectedGigDateIds, false);
                await this.refDataGridRiders.setSelectedArtistIds(this.selectedArtistIds);
            }
        }
    }


    // ERROR MESSAGE
    setErrorHeader = (show) => {
        if (show) {
            this.refHxErrorMessage.show();
        } else {
            this.refHxErrorMessage.hide();
        }
    }

    // USERFILTER
    userFilterLoaded = async (filterState) => {
        // display loadpanel
        //this.dataGridRidersInstance.beginCustomLoading("Loading...");

        this.dataGridRidersInstance.beginUpdate();

        if (filterState.filterValue !== undefined) {
            this.dataGridRidersInstance.option('filterValue', JSON.parse(filterState.filterValue));
            this.loadedUserFilterValue = this.dataGridRidersInstance.option('filterValue');
        } else {
            this.loadedUserFilterValue = [];
        }

        this.dataGridRidersInstance.endUpdate();

        // hide loadpanel
        //this.dataGridRidersInstance.endCustomLoading();
    }

    clearUserFilter = () => {
        this.dataGridRidersInstance.clearFilter();
    }

    userFilterError = (error) => {
        //this.setErrorHeader(error);
    }

    render() {

        return (

            <div className='riders-main-div'>

                <div className='riders-top-div'>
                    <HxErrorMessage
                        ref={ref => this.refHxErrorMessage = ref}
                        id='error-message-riders'
                        message='Could not connect to database!'
                        visible={false}
                    />

                    <div id='toolBarRiders' className='hx-toolbar-main-div'>
                        <div className='hx-toolbar-left'>
                            <span>
                                <Button
                                    icon='exportxlsx'
                                    stylingMode='outlined'
                                    text='Riders'
                                    visible={true}
                                    onClick={this.exportRider}

                                />
                            </span>
                            <span style={{ marginLeft: '5px' }}>
                                <Button
                                    icon='exportxlsx'
                                    stylingMode='outlined'
                                    text='Shoppinglist'
                                    visible={true}
                                    onClick={this.exportDayOverView}
                                />
                            </span>
                            <span style={{ marginLeft: '5px' }}>
                                <Button
                                    icon='exportxlsx'
                                    stylingMode='outlined'
                                    text='Totals'
                                    visible={true}
                                    onClick={this.exportTotalsList}

                                />
                            </span>
                        </div>
                        <div className='hx-toolbar-right'>
                            <span>
                                <HXUserFilters
                                    ref={ref => (this.refUserFilters = ref)}
                                    onUserFilterLoaded={this.userFilterLoaded}
                                    onClearFilter={this.clearUserFilter}
                                    onError={this.userFilterError}
                                    filterTypeName='riders'
                                />
                            </span>
                            <span>
                                <Button
                                    icon='preferences'
                                    stylingMode='text'
                                    text=''
                                    onClick={this.openUserSettingsRiders}
                                />
                            </span>
                        </div>
                    </div>

                    <div className='riders-content-block'>
                        <div className="riders-gigdates-wrapper">
                            <div className='dx-card riders-gigdates-card'>
                                <HxTags
                                    ref={ref => this.refGigDatesTags = ref}
                                    keyPrefix="dates"
                                    tags={this.convertGigDates(this.dataSourceGigDates)}
                                    title='Dates'
                                    onClick={this.tagGigDates_OnClick}
                                />
                            </div>
                        </div>

                        <div className='dx-card riders-artists-card'>
                            <HxTags
                                ref={ref => this.refArtistsTags = ref}
                                keyPrefix="artists"
                                tags={this.convertArtists(this.dataSourceArtists)}
                                title='Artists'
                                onClick={this.tagArtists_OnClick}
                            />
                        </div>
                    </div>
                </div>
                <div className='riders-bottom-div'>
                    <div className='riders-grid-card'>
                        <DataGridRiders
                            ref={ref => this.refDataGridRiders = ref}
                            onInitialized={this.dataGridRiders_OnInitialized}
                            onExportRider={this.dataGridRiders_OnExportRider}
                            onConnectionError={this.dataGridRiders_OnConnectionError}
                        />
                    </div>
                </div>

                <PopupExportRider
                    ref={this.refPopupExportRider}
                    onInitialized={this.popupExportRider_OnInitialized}
                />
                <PopupExportDayOverView
                    ref={ref => this.refPopupExportDayOverView = ref}
                    onInitialized={this.popupExportDayOverView_OnInitialized}
                />
                <PopupExportExcel
                    ref={ref => this.refPopupExportExcel = ref}
                    onInitialized={this.popupExportExcel_OnInitialized}
                    gridInstance={this.dataGridRidersInstance}
                />
                <PopupExportTotalsList
                    ref={ref => this.refPopupExportTotalsList = ref}
                    onInitialized={this.popupExportTotalsList_OnInitialized}
                />

                <PopupRidersUserSettings
                    onInitialized={this.popupUserSettings_OnInitialized}
                    onUpdate={this.popupUserSettings_OnUpdate}
                />
            </div>


        );
    }


}

export default Riders

