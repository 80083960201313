import React from 'react';
import { RidersAdminProducts } from '../../../../components';

class ridersAdminProducts extends React.Component {
    render() {
        return (
            <RidersAdminProducts />
        );
    }
}

export default ridersAdminProducts