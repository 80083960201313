import React from 'react';
import { RidersAdminArtists } from '../../../../components';

class ridersAdminArtists extends React.Component {
    render() {
        return (
            <RidersAdminArtists />
        );
    }
}

export default ridersAdminArtists