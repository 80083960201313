import globalIcons from './config/globalIcons.js';

export const navigation = [
    {
        text: 'Home',
        path: '/home',
        icon: 'home'
    }
 ];

export const navigationRiders =  {
        text: 'Riders',
        icon: 'food',
        items: [
            {
                text: 'Riders',
                path: '/riders'
            },
            {
                text: 'Admin',
                items: [{
                    text: 'Artists',
                    path: '/ridersadminartists'
                },
                {
                    text: 'Shops',
                    path: '/ridersadminshops'
                },
                {
                    text: 'Products',
                    path: '/ridersadminproducts'
                }
                ]
            }
        ]
};

export const navigationShuttles = {
        text: 'Shuttles',
        icon: 'car',
        items: [
            {
                text: 'Shuttles',
                path: '/shuttles'
            },
            {
                text: 'Admin',
                items: [{
                    text: 'Artists',
                    path: '/shuttlesadminartists'
                },
                {
                    text: 'Relations',
                    path: '/shuttlesadminrelations'
                },
                {
                    text: 'Locations',
                    path: '/shuttlesadminlocations'
                },
                {
                    text: 'Trip Timings',
                    path: '/shuttlesadmintriptimings'
                },
                {
                    text: 'Fleet',
                    path: '/shuttlesadminfleet'
                }]
            },
            {
                text: 'Pick Driver',
                path: '/users'
            },
            {
                text: 'Overview',
                path: '/shuttlesoverview'
            }
        ]
};

export const navigationUsers = {
        text: 'Users',
        icon: globalIcons.menu_usersettings,
        items: [
            {
                text: 'Driver pick',
                path: '/users'
            }
        ]
};
