import React from 'react';
import { Button } from 'devextreme-react/button';

import HXUserFilters from '../../../custom-components/hx-user-filters/HXUserFilters'
import ShuttlesAdminRelationsDataGrid from './sub-components/ShuttlesAdminRelationsDataGrid'
import HxPopupExportExcel from '../../../custom-components/hx-popup-excel-export/HxPopupExportExcel';
import PopupShuttlesAdminRelationsUserSettings from './sub-components/PopupShuttlesAdminRelationsUserSettings'

import './ShuttlesAdminRelations.scss';


class ShuttlesAdminRelations extends React.Component {
    constructor(props) {
        super(props);

        this.dataGridInstance = null;   

        this.popupExportExcelInstance = null;
        this.popupShuttlesAdminRelationsUserSettingsInstance = null;

        // filters
       this.loadedUserFilterValue = [];
  }

    popupExportExcel_OnInitialized = (instance) => {
        this.popupExportExcelInstance = instance;
    }

    popupExportExcel_OnSave = async (fileName) => {
        if (fileName) {
            await this.refDataGrid.exportToExcel(fileName);
        }
    }

    popupShuttlesAdminRelationsUserSettings_OnInitialized = (instance) => {
        this.popupShuttlesAdminRelationsUserSettingsInstance = instance;
    }

    popupShuttlesAdminRelationsUserSettings_OnUpdate = (elementId, value) => {
        this.refDataGrid.applyUserSettings(elementId, value)
    }

    exportRelations = async () => {
        this.popupExportExcelInstance.show();
    }

    buttonUserSettings_OnClick = (e) => {
        this.popupShuttlesAdminRelationsUserSettingsInstance.show();
    }

    // GRID
    grid_OnInitialized = (instance) => {
        this.dataGridInstance = instance;   
        this.refUserFilters.setDataGridInstance(instance);
    }

    // USERFILTER
    userFilterLoaded = async (filterState) => {
        // display loadpanel
        this.dataGridInstance.beginCustomLoading("Loading...");

        this.dataGridInstance.beginUpdate();

        if (filterState.filterValue !== undefined) {
            this.dataGridInstance.option('filterValue', JSON.parse(filterState.filterValue));
            this.loadedUserFilterValue = this.dataGridInstance.option('filterValue');
        } else {
            this.loadedUserFilterValue = [];
        }

        this.dataGridInstance.endUpdate();

        // hide loadpanel
        this.dataGridInstance.endCustomLoading();
    }

    clearUserFilter = () => {
        this.dataGridInstance.clearFilter();
    }

    userFilterError = (error) => {
        //this.setErrorHeader(error);
    }

    render() {
        return (
            <React.Fragment>
                <div className='shuttles-admin-relations-main-div'>
                    <div className='shuttles-admin-relations-content-block'>
                        <div id='toolBarRelations' className='hx-toolbar-main-div'>
                            <div className='hx-toolbar-left'>
                                <span>
                                    <Button
                                        icon="xlsxfile"
                                        stylingMode='outlined'
                                        text="Relations"
                                        onClick={this.exportRelations} />
                                </span>
                            </div>
                            <div className='hx-toolbar-right'>
                                <span>
                                    <HXUserFilters
                                        ref={ref => (this.refUserFilters = ref)}
                                        onUserFilterLoaded={this.userFilterLoaded}
                                        onClearFilter={this.clearUserFilter}
                                        onError={this.userFilterError}
                                        filterTypeName='relations'
                                    />
                                </span>
                                <span>
                                    <Button
                                        icon='preferences'
                                        stylingMode='text'
                                        text=''
                                        onClick={this.buttonUserSettings_OnClick}
                                    />
                                </span>
                            </div>
                        </div>
                        <div className='shuttles-admin-relations-grid-card'>
                            <ShuttlesAdminRelationsDataGrid
                                ref={ref => this.refDataGrid = ref}
                                onInitialized={this.grid_OnInitialized}
                            />
                        </div>
                    </div>
                </div>

                <HxPopupExportExcel
                    defaultName='Relations'
                    onInitialized={this.popupExportExcel_OnInitialized}
                    onSave={this.popupExportExcel_OnSave}
                />

                <PopupShuttlesAdminRelationsUserSettings
                    onInitialized={this.popupShuttlesAdminRelationsUserSettings_OnInitialized}
                    onUpdate={this.popupShuttlesAdminRelationsUserSettings_OnUpdate}
                />
            </React.Fragment>
        );
    }
}

export default ShuttlesAdminRelations
