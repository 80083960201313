import React from 'react';
import { Popup } from 'devextreme-react/popup';
import TextBox from 'devextreme-react/text-box';
import Validator, { AsyncRule, ValidationRule, EmailRule } from 'devextreme-react/validator';
import { Button } from 'devextreme-react/button';
import { ValidationGroup, dxValidationGroupResult } from 'devextreme-react/validation-group';
import HtmlEditor, {
    Toolbar, MediaResizing, ImageUpload, Item,
} from 'devextreme-react/html-editor';
import { CheckBox } from 'devextreme-react/check-box';

import ServiceRelations from '../../../../../api/services/ServiceRelations'
import ServiceColors from '../../../../../api/services/ServiceColors'
import ServiceDrivers from '../../../../../api/services/ServiceDrivers'
import ServiceEvents from '../../../../../api/services/ServiceEvents'

import './PopupShuttlesAdminRelationsEditOther.scss'
import globalConfig from '../../../../../config/global.js';


// props
//      id (required): relation id
//
//      onInitialized: callback
//      onUpdate: callback
//      onLoadError: callback
//      onHiding: callback

class PopupShuttlesAdminRelationsEditOther extends React.Component {
    constructor(props) {
        super(props);

        this.relationId = -1;

        this.relationTypesDataSource = [];

        this.popupInstance = null;
        this.validationGroupInstance = null;

        this.textBoxFirstNameInstance = null;
        this.textBoxLastNameInstance = null;
        this.textBoxEmail1Instance = null;
        this.textBoxTel1Instance = null;
        this.htmlEditorNotesInstance = null;

        this.checkBoxKeepOpenInstance = null;
        this.buttonUpdateInstance = null;

        this.relationRecord = {};
        this.relationDefaultRecord = {
            Id: -1,
            EventId: parseInt(sessionStorage.getItem('currentEventId'), 10),
            ColorId: null,
            PatternId: null,
            RelationTypeId: -1,
            FirstName: '',
            LastName: '',
            Street: null,
            StreetNo: null,
            ZipCode: null,
            City: null,
            CountryCode: null,
            Country: '',
            Tel1: null,
            Tel1Mobile: null,
            Tel2: null,
            Tel2Mobile: null,
            Email1: null,
            Email2: '',
            BirthDate: null,
            Gender: null,
            NationalRefNumber: null,
            RelationImage: null,
            Notes: null,
            RelationType: '',
            ColorHex: '#FFFFFF',
            ColorRgba: 'rgba(255,255,255,1)',
            ForeColorHex: '#000000',
            ForeColorRgba: 'rgba(0,0,0,1)',
            PatternColorHex: '#000000',
            PatternColorRgba: 'rgba(0,0,0,1)',
            UsePattern: false,
            PatternCssBackGround: null,
            PatternCssBackGroundImage: null,
            PatternCssBackGroundSize: null,
            PatternCssBackGroundPosition: null,
        }
    }

    // CALLBACKS
    onInitialized = (instance) => {
        if (this.props.onInitialized) {
            this.props.onInitialized(instance);  // callback
        }
    }

    onLoadError = () => {
        if (this.props.onLoadError) {
            this.props.onLoadError();  // callback
        }
    }

    onUpdate = (success) => {
        if (this.props.onUpdate) {
            this.props.onUpdate(success);  // callback
        }
    }

    onHiding = () => {
        if (this.props.onHiding) {
            this.props.onHiding();  // callback
        }
    }
    // CALLABLES

    // EVENTS
    componentDidMount = async () => {
        await this.loadDataSourceRelationTypes();
    }

    // DATA
    loadDataSourceRelation = async (relationId) => {
        var data = await ServiceRelations.getRelationByEventIdRelationId(parseInt(sessionStorage.getItem('currentEventId'), 10), relationId);
        if (data !== null && !data.hasOwnProperty("error")) {
            this.relationRecord = data;
        } else {
            this.onLoadError();  // callback
        }
    }

    loadDataSourceRelationTypes = async () => {
        var data = await ServiceRelations.getRelationTypes();
        if (data !== null && !data.hasOwnProperty("error")) {
            this.relationTypesDataSource = data;
        } else {
            this.onLoadError();  // callback
        }
    }

    // POPUP
    popup_OnInitialized = (e) => {
        this.popupInstance = e.component;

        // set callback
        this.onInitialized(e.component);
    }

    popup_OnShowing = (e) => {
        if (this.props.id !== undefined) {
            this.relationId = this.props.id;
        }
        this.relationRecord = this.relationDefaultRecord;
    }

    popup_OnHiding = (e) => {
        this.textBoxFirstNameInstance.option('value', '');
        this.textBoxLastNameInstance.option('value', '');
        this.textBoxEmail1Instance.option('value', null);
        this.textBoxTel1Instance.option('value', null);
        this.htmlEditorNotesInstance.option('value', null);

        this.validationGroupInstance.reset();

        this.onHiding();  // callback
    }

    popup_OnShown = async (e) => {
        var type = this.relationTypesDataSource.find(o => o.Name.toString().toUpperCase() === 'OTHER');
        this.relationDefaultRecord.RelationTypeId = (type != null ? type.Id : -1);
        this.relationDefaultRecord.RelationType = (type != null ? type.Name : '');

        if (this.relationId == -1) {
            // new relation
            this.textBoxFirstNameInstance.option('value', '');
            this.textBoxLastNameInstance.option('value', '');
            this.textBoxEmail1Instance.option('value', null);
            this.textBoxTel1Instance.option('value', null);
            this.htmlEditorNotesInstance.option('value', null);

            this.checkBoxKeepOpenInstance.option('visible', true);

            this.validationGroupInstance.reset();

            this.popupInstance.option('title', 'Add new relation');
        } else {
            // edit relation
            await this.loadDataSourceRelation(this.relationId);

            this.textBoxFirstNameInstance.option('value', this.relationRecord.FirstName);
            this.textBoxLastNameInstance.option('value', this.relationRecord.LastName);
            this.textBoxEmail1Instance.option('value', this.relationRecord.Email1);
            this.textBoxTel1Instance.option('value', this.relationRecord.Tel1);
            this.htmlEditorNotesInstance.option('value', this.relationRecord.Notes);

            this.checkBoxKeepOpenInstance.option('visible', false);

            this.popupInstance.option('title', 'Update ' + this.relationRecord.FirstName + ' ' + this.relationRecord.LastName);
        }

        this.checkBoxKeepOpenInstance.option('value', false);
        this.buttonUpdateInstance.option('text', 'Save');
        this.buttonUpdateInstance.option('width', '120px');

       this.textBoxFirstNameInstance.focus();
    }

    
    // ALL TEXTBOXES FOCUS IN
    textBox_OnFocusIn = (e) => {
        // select all text
        e.element.querySelector('.dx-texteditor-input').select();
    }

    // TEXTBOX FIRST NAME
    textBoxFirstNameAttributes = {
        id: 'textBoxFirstNameInput',
    }

    textBoxFirstName_OnInitialized = (e) => {
        this.textBoxFirstNameInstance = e.component;
    }

    // TEXTBOX LAST NAME
    textBoxLastName_OnInitialized = (e) => {
        this.textBoxLastNameInstance = e.component;
    }

    // TEXTBOX EMAIL 1
    textBoxEmail1_OnInitialized = (e) => {
        this.textBoxEmail1Instance = e.component;
    }

    // TEXTBOX TEL 1
    textBoxTel1_OnInitialized = (e) => {
        this.textBoxTel1Instance = e.component;
    }

    // HTMLEDITOR NOTES
    htmlEditorNotes_OnInitialized = (e) => {
        this.htmlEditorNotesInstance = e.component;
    }

    // CHECKBOX KEEP OPEN
    checkBoxKeepOpen_OnInitialized = (e) => {
        this.checkBoxKeepOpenInstance = e.component;
    }

    checkBoxKeepOpen_OnValueChanged = (e) => {
        this.buttonUpdateInstance.option('text', (e.value === true ? 'Save & new' : 'Save'));
        this.buttonUpdateInstance.option('width', (e.value === true ? '140px' : '120px'));
    }

    // BUTTON
    buttonUpdate_OnInitialized = (e) => {
        this.buttonUpdateInstance = e.component;
    }

    buttonUpdate_OnClick = async (e) => {
        await this.updateRelation();
    }

    updateRelation = async () => {
        this.validationGroupInstance.validate();
        if (this.textBoxFirstNameInstance.option('isValid') === true
            && this.textBoxLastNameInstance.option('isValid') === true
            && this.textBoxEmail1Instance.option('isValid') === true) {

            this.relationRecord.FirstName = (this.textBoxFirstNameInstance.option('value') !== null ? this.textBoxFirstNameInstance.option('value').trim() : null);
            this.relationRecord.LastName = (this.textBoxLastNameInstance.option('value') !== null ? this.textBoxLastNameInstance.option('value').trim() : null);
            this.relationRecord.Email1 = (this.textBoxEmail1Instance.option('value') !== null ? this.textBoxEmail1Instance.option('value').trim() : null);
            this.relationRecord.Tel1 = (this.textBoxTel1Instance.option('value') !== null ? this.textBoxTel1Instance.option('value').trim() : null);
            this.relationRecord.Notes = this.htmlEditorNotesInstance.option('value');

            if (this.relationId == -1) {
                // new relation
                var resultRelation = await ServiceRelations.addRelation(this.relationRecord);
                if (resultRelation !== null && !resultRelation.hasOwnProperty("error")) {
                    this.onUpdate(true); // callback
                    if (this.checkBoxKeepOpenInstance.option('value') === false) {
                        this.popupInstance.hide();
                    } else
                    {
                        this.textBoxFirstNameInstance.option('value', '');
                        this.textBoxLastNameInstance.option('value', '');
                        this.textBoxEmail1Instance.option('value', null);
                        this.textBoxTel1Instance.option('value', null);
                        this.htmlEditorNotesInstance.option('value', null);

                        this.validationGroupInstance.reset();

                        this.popupInstance.option('title', 'Add new relation');
                    }
                } else {
                    this.onUpdate(false); // callback
                }
            } else {
                // edit relation
                var data = await ServiceRelations.updateRelation(this.relationRecord);
                if (data !== null && !data.hasOwnProperty("error") && data == true) {
                    this.onUpdate(true); // callback
                    if (this.checkBoxKeepOpenInstance.option('value') === false) {
                        this.popupInstance.hide();
                    } else {
                        this.textBoxFirstNameInstance.option('value', '');
                        this.textBoxLastNameInstance.option('value', '');
                        this.textBoxEmail1Instance.option('value', null);
                        this.textBoxTel1Instance.option('value', null);
                        this.htmlEditorNotesInstance.option('value', null);

                        this.validationGroupInstance.reset();

                        this.popupInstance.option('title', 'Add new relation');
                    }
                } else {
                    this.onUpdate(false); // callback
                }
            }
        } else {
            this.textBoxFirstNameInstance.focus();
        }
    }

    // VALIDATION GROUP
    validationGroup_OnInitialized = (e) => {
        this.validationGroupInstance = e.component;
    }

    // RENDERING
    render() {
        return (
            <React.Fragment>
                <Popup
                    dragEnabled={false}
                    hideOnOutsideClick={false}
                    showTitle={true}
                    width={1000}
                    height={353}
                    showCloseButton={true}
                    onInitialized={this.popup_OnInitialized}
                    onShown={this.popup_OnShown}
                    onShowing={this.popup_OnShowing}
                    onHiding={this.popup_OnHiding}
                >
                    <ValidationGroup
                        onInitialized={this.validationGroup_OnInitialized}
                    >
                        <div>
                            <div className="psareo-header-div">Info</div>
                            <div className="dx-card psareo-top-card">
                                <div className="psareo-top-div">
                                    <div className="psareo-top-left-div">
                                        <img
                                            src={process.env.PUBLIC_URL + globalConfig.settings.images.relationsOtherImagesPath.replace(/^\+$|"\/+$/g, '') + "/default.png"}
                                            id='imageBox'
                                            alt={''}
                                            style={{ height: '150px', width: '150px' }}
                                        />
                                    </div>
                                    <div className="psareo-top-middle-div">
                                        <div className="psareo-dx-field">
                                            <div className="dx-field-label psareo-top-label">First Name*</div>
                                            <div className="dx-field-value psareo-top-input">
                                                <TextBox
                                                    inputAttr={this.textBoxFirstNameAttributes}
                                                    width='250px'
                                                    onInitialized={this.textBoxFirstName_OnInitialized}
                                                >
                                                    <Validator>
                                                        <ValidationRule type="required" message="Required" />
                                                    </Validator>
                                                </TextBox>
                                            </div>
                                        </div>
                                        <div className="psareo-dx-field">
                                            <div className="dx-field-label psareo-top-label">Last Name*</div>
                                            <div className="dx-field-value psareo-top-input">
                                                <TextBox
                                                    width='250px'
                                                    onFocusIn={this.textBox_OnFocusIn}
                                                    onInitialized={this.textBoxLastName_OnInitialized}
                                                >
                                                    <Validator>
                                                        <ValidationRule type="required" message="Required" />
                                                    </Validator>
                                                </TextBox>
                                            </div>
                                        </div>
                                        <div className="psareo-dx-field">
                                            <div className="dx-field-label psareo-top-label">Email</div>
                                            <div className="dx-field-value psareo-top-input">
                                                <TextBox
                                                    width='250px'
                                                    onFocusIn={this.textBox_OnFocusIn}
                                                    onInitialized={this.textBoxEmail1_OnInitialized}
                                                >
                                                    <Validator>
                                                        <EmailRule
                                                            message="Invalid email" />
                                                    </Validator>
                                                </TextBox>
                                            </div>
                                        </div>
                                        <div className="psareo-dx-field">
                                            <div className="dx-field-label psareo-top-label">Tel</div>
                                            <div className="dx-field-value psareo-top-input">
                                                <TextBox
                                                    width='250px'
                                                    onFocusIn={this.textBox_OnFocusIn}
                                                    onInitialized={this.textBoxTel1_OnInitialized}
                                                >
                                                </TextBox>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="psareo-top-right-div">
                                        <div className="psareo-dx-field-notes">
                                            <div className="dx-field-value psareo-top-input-notes">
                                                <HtmlEditor
                                                    width='100%'
                                                    height='158px'
                                                    onInitialized={this.htmlEditorNotes_OnInitialized}
                                                >
                                                </HtmlEditor>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ValidationGroup>

                    <div className="psareo-buttons">
                        <div className="psareo-buttons-checkbox">
                            <CheckBox
                                text="Keep open"
                                onInitialized={this.checkBoxKeepOpen_OnInitialized}
                                onValueChanged={this.checkBoxKeepOpen_OnValueChanged}
                            />
                        </div>
                        <div className='psareo-buttons-update'>
                            <div className="psareo-button-update">
                                <Button
                                    onInitialized={this.buttonUpdate_OnInitialized}
                                    icon="save"
                                    onClick={this.buttonUpdate_OnClick}
                                />
                            </div>
                        </div>
                    </div>
                </Popup>
            </React.Fragment>
        );
    }

}

export default PopupShuttlesAdminRelationsEditOther

