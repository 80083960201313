import React from 'react';
import { ShuttlesAdminRelations } from '../../../../components';

class shuttlesAdminRelations extends React.Component {
    render() {
        return (
            <ShuttlesAdminRelations />
        );
    }
}

export default shuttlesAdminRelations