import globalConfig from '../../config/global.js';

class ServiceGeneral {

    static getGigDatesByEventId = async (id, calledFrom) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/General/GetGigDatesByEventId?eventId=" + encodeURIComponent(id) + "&calledFrom=" + encodeURIComponent(calledFrom)) // return null or data object
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }


    static getAllCountries = async () => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/General/GetAllCountries") // return null or data object
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static getAllTripTimingsByEventId = async (eventId) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/General/GetAllTripTimingsByEventId?eventId=" + encodeURIComponent(eventId)) // return null or data object
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static getTripTimingByEventIdLocationDepartureIdLocationArrivalId = async (eventId,  locationDepartureId, locationArrivalId) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/General/GetTripTimingByEventIdLocationDepartureIdLocationArrivalId?eventId=" + encodeURIComponent(eventId) + '&locationDepartureId=' + encodeURIComponent(locationDepartureId) + '&locationArrivalId=' + encodeURIComponent(locationArrivalId)) // return null or data object
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static addTripTiming = async (model) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/General/AddTripTiming", {
            method: 'POST',
            body: JSON.stringify(model),
            headers: {
                'Content-type': 'application/json; charset=UTF-8'
            }
        })
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(error => {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static updateTripTiming = async (model) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/General/UpdateTripTiming", {
            method: 'POST',
            body: JSON.stringify(model),
            headers: {
                'Content-type': 'application/json; charset=UTF-8'
            }
        })
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(error => {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static deleteTripTiming = async (eventId, locationDepartureId, locationArrivalId) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/General/DeleteTripTiming?eventId=" + encodeURIComponent(eventId) + '&locationDepartureId=' + encodeURIComponent(locationDepartureId) + '&locationArrivalId=' + encodeURIComponent(locationArrivalId)) // return true or false
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static doesTripTimingExist = async (eventId, locationDepartureId, locationArrivalId) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/General/DoesTripTimingExist?eventId=" + encodeURIComponent(eventId) + "&locationDepartureId=" + encodeURIComponent(locationDepartureId) + "&locationArrivalId=" + encodeURIComponent(locationArrivalId)) // return true or false
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static getAllTripStatussesDropDown = async () => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/General/GetAllTripStatussesDropDown") // return null or data object
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }
    
}

export default ServiceGeneral;
