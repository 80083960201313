import React from 'react';

import './HxErrorMessage.scss';

// props
//      message
//      visible
// callables
//      setMessage(tags)
//      show()
//      hide()

class HxErrorMessage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            message: this.props.message,
            visible: this.props.visible
        };
        
        this.setMessage = this.setMessage.bind(this);
        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);
      
        // events
        this.onClick = this.onClick.bind(this);
    }

    show() {
        let element = document.getElementById(this.props.id);
        if (element !== null) {
            element.style.display = 'flex';
        }
    }

    hide() {
        let element = document.getElementById(this.props.id);
        if (element !== null) {
            element.style.display = 'none';
        }
    }

    setMessage(message) {
        this.setState({ message: message });
    }

    
    onClick(e) {
        this.hide();
    }
 

    render() {
        return (
            <React.Fragment>
                <div id={this.props.id} className='hx-error-message' style={{display: this.state.visible ? 'flex' : 'none'}}>
                    <div id={this.props.id + 'ee'} className='hx-error-message-button' onClick={this.onClick}>X</div>
                    <div className='hx-error-message-message'>{this.state.message}</div>
                </div>
                <div></div>
            </React.Fragment>
        );

    }

}

export default HxErrorMessage

