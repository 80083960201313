import React from 'react';
import { HubConnectionBuilder } from '@microsoft/signalr';
import DataGrid, {
    RemoteOperations, Editing, ColumnChooser, GroupPanel, FilterPanel, FilterRow, HeaderFilter, Paging, Pager, Scrolling, Column, SearchPanel, KeyboardNavigation, Export, StateStoring, Sorting, Toolbar, Item
} from 'devextreme-react/data-grid';
import { confirm } from 'devextreme/ui/dialog';
import HxToast from '../../../../custom-components/hx-toast/HxToast';
import { Button } from 'devextreme-react/button';

import ServiceRelations from '../../../../../api/services/ServiceRelations'
import ServiceUsers from '../../../../../api/services/ServiceUsers'
import PopupShuttlesAdminRelationsEditDriver from './PopupShuttlesAdminRelationsEditDriver'
import PopupShuttlesAdminRelationsEditOther from './PopupShuttlesAdminRelationsEditOther'
import PopupShuttlesAdminRelationsEditArtistContact from './PopupShuttlesAdminRelationsEditArtistContact'

import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';

import './ShuttlesAdminRelationsDataGrid.scss'

// callables
//      applyUserSettings
//
// callbacks
//      onInitialized

class ShuttlesAdminRelationsArtistsDataGrid extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dataSourceRelations: [],
            relationId: -1,
            useAdvancedFiltering: false,
        }

        this.dataGridInstance = null;

        this.popupShuttlesAdminRelationsEditInstanceDriver = null;
        this.popupShuttlesAdminRelationsEditInstanceOther = null;
        this.popupShuttlesAdminRelationsEditInstanceArtistContact = null;

        this.hubConnection = null;

        this.showSelectedCell = false;

        this.userSettings = {
            UserId: parseInt(sessionStorage.getItem("currentUserId"), 10),
            DefaultViewId: -1,
            DefaultFilterId: -1,
            ShowSelectedCell: false,
            UseAdvancedFiltering: false,
        }
    } 

    // CALLBACKS
    onInitialized = (instance) => {
        if (this.props.onInitialized) {
            this.props.onInitialized(instance);  // callback
        }
    }

    // CALLABLES
    applyUserSettings = (elementId, value) => {
        switch (elementId) {
            case 'checkBoxShowSelectedCell':
                this.showSelectedCell = value;
                this.dataGridInstance.repaint();
                break;
            case 'checkBoxUseAdvancedFiltering':
                this.setState({ useAdvancedFiltering: value });
                this.dataGridInstance.repaint();
                break;
            default:
                break;
        }
    }

    // EVENTS
    componentDidMount = async () => {
        await this.loadDSRelations();
        await this.loadDSUserSettings();

        this.applyUserSettings('checkBoxShowSelectedCell', this.userSettings.ShowSelectedCell);
        this.applyUserSettings('checkBoxUseAdvancedFiltering', this.userSettings.UseAdvancedFiltering);

        this.hubConnection = new HubConnectionBuilder().withUrl("/relationsHub").build();
        await this.hubConnection.start()
            .then(function () {

            })
            .catch(function (err) {
                return console.error(err.toString());
            });
        this.hubConnection.on("ReceiveMessageAddedRelation", await this.updatePushedDataAddedRelation);
        this.hubConnection.on("ReceiveMessageDeletedRelation", await this.updatePushedDataDeletedRelation);
    }

    // DATA
    loadDSRelations = async () => {
        var data = await ServiceRelations.getAllRelationsByEventId(parseInt(sessionStorage.getItem('currentEventId'), 10));
        if (data !== null && !data.hasOwnProperty("error")) {
            this.setState({ dataSourceRelations: data });
        } else {
           // this.setErrorHeader(true);
        }
    }

    loadDSUserSettings = async () => {
        var settings = await ServiceUsers.getUserSettingsRelationsByUserId(parseInt(sessionStorage.getItem("currentUserId"), 10));
        settings = (settings !== null ? settings : this.userSettings);
        if (!settings.hasOwnProperty("error")) {
            this.userSettings = settings;
        } else {
           // this.setErrorHeader(true);
        }
    }

    // GRID
    gridOnCellClick = async (e) => {
        if (e.rowType === 'data') {
            if (e.column.name == 'ActionColumnEdit') {
                this.setState({ relationId: e.data.Id });

                switch (e.data.RelationType.toString().toUpperCase()) {
                    case 'DRIVER':
                        this.popupShuttlesAdminRelationsEditInstanceDriver.show();
                        break;
                    case 'ARTISTCONTACT':
                        this.popupShuttlesAdminRelationsEditInstanceArtistContact.show();
                        break;
                    case 'OTHER':
                        this.popupShuttlesAdminRelationsEditInstanceOther.show();
                        break;
                    default:
                }
            } else if (e.column.name == 'ActionColumnDelete') {
                let result = await confirm('Are you sure you want to delete ' + e.data.FirstName + ' ' + e.data.LastName + '?', "Confirm delete");
                if (result) {
                    var resultDelete = await ServiceRelations.deleteRelation(e.data.Id, e.data.RelationType.toString().toUpperCase());
                    if (resultDelete === true) {
                        HxToast.showToast('Relation deleted successfully', 'success', 'bottom right', 'up-stack');
                        this.hubConnection
                            .invoke("SendUpdateInfoDeletedRelation")
                            .catch(function (err) {
                                return console.error(err.toString());
                            });
                    } else {
                        HxToast.showToast('Relation delete failed!', 'error', 'bottom right', 'up-stack');
                    }
                }
            }
        }
    }

    gridOnFocusedCellChanging = (e) => {
        if (this.showSelectedCell) {
            let elements = document.getElementsByClassName("hx-datagrid-cell-border");
            if (elements) {
                for (var i = 0; i < elements.length; i++) {
                    elements[i].classList.remove('hx-datagrid-cell-border');
                }
            }

            e.cellElement[0].classList.add('hx-datagrid-cell-border');
        }
    }

    gridOnInitialized = (e) => {
        // set instance
        this.dataGridInstance = e.component;

        // set focused row
        this.dataGridInstance.option('focusedRowIndex', 0);

        this.onInitialized(e.component);
    }

    // EXPORT TO EXCEL
    exportToExcel = async (name) => {

        const workbook = new Workbook();

        var sheet = workbook.addWorksheet("Relations");
        sheet.columns = [
            { width: 30 }, { width: 30 }, { width: 40 }, { width: 25 }, { width: 20 }
        ];
        sheet.pageSetup.margins = {
            left: 0.5, right: 0.5,
            top: 0.5, bottom: 0.5,
            header: 0, footer: 0
        };

        exportDataGrid({
            worksheet: sheet,
            component: this.dataGridInstance,
            customizeCell: this.customizeExcelCell,
            keepColumnWidths: false
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), name);
            });
        })
    };

    customizeExcelCell(options) {
        const { gridCell, excelCell } = options;

        if (gridCell.rowType === 'header' && gridCell.column.name !== 'DummyColumn') {
            excelCell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FF000000' }
            };
            excelCell.font = {
                color: { argb: 'FFFFFFFF' }
            }
        }
    }

    // ADD RELATION BUTTON
    buttonAddDriver_OnClick = () => {
        this.setState({ relationId: -1 });
        this.popupShuttlesAdminRelationsEditInstanceDriver.show();
    }
    buttonAddArtistContact_OnClick = () => {
        this.setState({ relationId: -1 });
        this.popupShuttlesAdminRelationsEditInstanceArtistContact.show();
    }
    buttonAddOtherRelation_OnClick = () => {
        this.setState({ relationId: -1 });
        this.popupShuttlesAdminRelationsEditInstanceOther.show();
    }

    // POPUP EDIT
    popupShuttlesAdminRelationsEditDriver_OnInitialized = (instance) => {
        this.popupShuttlesAdminRelationsEditInstanceDriver = instance;
    }

    popupShuttlesAdminRelationsEditDriver_OnHiding = async () => {
        await this.loadDSRelations();
    }

    popupShuttlesAdminRelationsEditDriver_OnUpdate = async (success) => {
        if (success) {
            HxToast.showToast(this.state.relationId === -1 ? 'Driver added successfully' : 'Driver updated successfully', 'success', 'bottom right', 'up-stack');
            this.hubConnection
                .invoke("SendUpdateInfoAddedRelation")
                .catch(function (err) {
                    return console.error(err.toString());
                });
        } else {
            HxToast.showToast(this.state.relationId === -1 ? 'Failed to add driver' : 'Failed to update driver', 'error', 'bottom right', 'up-stack');
        }
    }

    popupShuttlesAdminRelationsEditOther_OnInitialized = (instance) => {
        this.popupShuttlesAdminRelationsEditInstanceOther = instance;
    }

    popupShuttlesAdminRelationsEditOther_OnHiding = async () => {
        await this.loadDSRelations();
    }

    popupShuttlesAdminRelationsEditOther_OnUpdate = async (success) => {
        if (success) {
            HxToast.showToast(this.state.relationId === -1 ? 'Relation added successfully' : 'Relation updated successfully', 'success', 'bottom right', 'up-stack');
            this.hubConnection
                .invoke("SendUpdateInfoAddedRelation")
                .catch(function (err) {
                    return console.error(err.toString());
                });
        } else {
            HxToast.showToast(this.state.relationId === -1 ? 'Failed to add relation' : 'Failed to update relation', 'error', 'bottom right', 'up-stack');
        }
    }

    popupShuttlesAdminRelationsEditArtistContact_OnInitialized = (instance) => {
        this.popupShuttlesAdminRelationsEditInstanceArtistContact = instance;
    }

    popupShuttlesAdminRelationsEditArtistContact_OnHiding = async () => {
        await this.loadDSRelations();
    }

    popupShuttlesAdminRelationsEditArtistContact_OnUpdate = async (success) => {
        if (success) {
            HxToast.showToast(this.state.relationId === -1 ? 'Artist contact added successfully' : 'Artist contact updated successfully', 'success', 'bottom right', 'up-stack');
            this.hubConnection
                .invoke("SendUpdateInfoAddedRelation")
                .catch(function (err) {
                    return console.error(err.toString());
                });
        } else {
            HxToast.showToast(this.state.relationId === -1 ? 'Failed to add artist contact' : 'Failed to update artist contact', 'error', 'bottom right', 'up-stack');
        }
    }

    // HUB
    updatePushedDataAddedRelation = async () => {
        await this.loadDSRelations();
    }

    updatePushedDataDeletedRelation = async () => {
        await this.loadDSRelations();
    }

    // RENDERING
    renderEditTemplate = (e) => {
        return <div className="shuttles-admin-relations-edit-template">
            <div style={{ width: '40px' }}><i className='dx-icon-edit shuttles-admin-relations-action-columns' /></div>
        </div>;
    }

    renderDeleteTemplate = (e) => {
        return <div className = "shuttles-admin-relations-delete-template">
            <div style={{ width: '40px' }}><i className='dx-icon-trash shuttles-admin-relations-action-columns' /></div>
        </div>;
    }
    
    render() {

        return (
            <React.Fragment>
                <div className='content-block relations-content-block'>
                    <div className='shuttles-admin-relations-datagrid-buttons'>
                        <span className='shuttles-admin-relations-datagrid-button'>
                            <Button
                                text="Add driver"
                                icon="add"
                                onClick={this.buttonAddDriver_OnClick} />
                        </span>
                        <span className='shuttles-admin-relations-datagrid-button'>
                            <Button
                                text="Add artist contact"
                                icon="add"
                                onClick={this.buttonAddArtistContact_OnClick} />
                        </span>
                        <span className='shuttles-admin-relations-datagrid-button'>
                            <Button
                               text="Add other relation"
                                icon="add"
                                onClick={this.buttonAddOtherRelation_OnClick} />
                        </span>
                    </div>
                    <div className='dx-card shuttles-admin-relations-datagrid-grid-card'>
                        <DataGrid
                            dataSource={this.state.dataSourceRelations}
                            keyExpr='Id'
                            height='calc(100% - 1px)'
                            width='100%'
                            allowColumnReordering={false}
                            allowColumnResizing={true}
                            allowGrouping={false}
                            columnAutoWidth={true}
                            columnResizingMode='widget'
                            filterSyncEnabled={true}
                            focusedRowEnabled={false}
                            remoteOperations={true}
                            repaintChangesOnly={true}
                            showBorders={false}
                            showColumnLines={true}
                            showRowLines={true}
                            wordWrapEnabled={false}
                            noDataText='No relations found.'
                            onCellClick={this.gridOnCellClick}
                            onFocusedCellChanging={this.gridOnFocusedCellChanging}
                            onInitialized={this.gridOnInitialized}
                        >

                            <Sorting mode="multiple" />
                            <ColumnChooser enabled={false} />
                            <FilterPanel visible={this.state.useAdvancedFiltering} />
                            <FilterRow visible={true} />
                            <GroupPanel visible={false} />
                            <HeaderFilter visible={true} />
                            <SearchPanel visible={true} />
                            <Paging enabled={false} defaultPageSize={20} />
                            <Scrolling
                                mode="standard"
                                preloadEnabled={true}
                                scrollByContent={true}
                                scrollByThumb={true}
                                showScrollbar='always'
                                useNative='false'
                            />
                            <Column
                                dataField='Id'
                                dataType='number'
                                caption='Id'
                                allowFiltering={false}
                                allowEditing={false}
                                fixed={true}
                                showInColumnChooser={false}
                                visible={false}
                            />
                            <Column
                                dataField='ColorHex'
                                dataType='string'
                                caption='ColorHex'
                                allowFiltering={false}
                                allowEditing={false}
                                fixed={true}
                                showInColumnChooser={false}
                                visible={false}
                            />
                            <Column
                                dataField='ForeColorHex'
                                dataType='string'
                                caption='ForeColorHex'
                                allowFiltering={false}
                                allowEditing={false}
                                fixed={true}
                                showInColumnChooser={false}
                                visible={false}
                            />
                            <Column
                                dataField='PatternColorHex'
                                dataType='string'
                                caption='PatternColorHex'
                                allowFiltering={false}
                                allowEditing={false}
                                fixed={true}
                                showInColumnChooser={false}
                                visible={false}
                            />
                            <Column
                                dataField='UsePattern'
                                dataType='number'
                                caption='UsePattern'
                                allowFiltering={false}
                                allowEditing={false}
                                fixed={true}
                                showInColumnChooser={false}
                                visible={false}
                            />
                            <Column
                                dataField='PatternCssBackGround'
                                dataType='string'
                                caption='PatternCssBackGround'
                                allowFiltering={false}
                                allowEditing={false}
                                fixed={true}
                                showInColumnChooser={false}
                                visible={false}
                            />
                            <Column
                                dataField='PatternCssBackGroundImage'
                                dataType='string'
                                caption='PatternCssBackGroundImage'
                                allowFiltering={false}
                                allowEditing={false}
                                fixed={true}
                                showInColumnChooser={false}
                                visible={false}
                            />
                            <Column
                                dataField='PatternCssBackGroundSize'
                                dataType='string'
                                caption='PatternCssBackGroundSize'
                                allowFiltering={false}
                                allowEditing={false}
                                fixed={true}
                                showInColumnChooser={false}
                                visible={false}
                            />
                            <Column
                                dataField='PatternCssBackGroundPosition'
                                dataType='string'
                                caption='PatternCssBackGroundPosition'
                                allowFiltering={false}
                                allowEditing={false}
                                fixed={true}
                                showInColumnChooser={false}
                                visible={false}
                            />
                            <Column
                                caption=''
                                width='20px'
                                allowFiltering={false}
                                allowEditing={false}
                                allowColumnResizing={false}
                                allowColumnReordering={false}
                                allowExporting={false}
                                fixed={true}
                                showInColumnChooser={false}
                            />
                            <Column
                                name='ActionColumnEdit'
                                caption=''
                                allowFiltering={false}
                                allowEditing={false}
                                allowColumnResizing={false}
                                allowColumnReordering={false}
                                allowExporting={false}
                                fixed={false}
                                showInColumnChooser={false}
                                visible={true}
                                width='40px'
                                alignment='center'
                                cellRender={this.renderEditTemplate}
                            />
                            <Column
                                dataField='LastName'
                                dataType='string'
                                caption='LastName'
                                minWidth={175}
                                sortIndex={0}
                                sortOrder='Asc'
                                width='auto'
                                allowFiltering={true}
                                allowResizing={true}
                                allowEditing={false}
                                fixed={false}
                                showInColumnChooser={true}
                                visible={true}
                            />
                            <Column
                                dataField='FirstName'
                                dataType='string'
                                caption='FirstName'
                                minWidth={175}
                                width='auto'
                                allowFiltering={true}
                                allowResizing={true}
                                allowEditing={false}
                                fixed={false}
                                showInColumnChooser={true}
                                visible={true}
                            />
                            <Column
                                dataField='Email1'
                                dataType='string'
                                caption='Email'
                                minWidth={200}
                                width='auto'
                                allowFiltering={true}
                                allowResizing={true}
                                allowEditing={false}
                                fixed={false}
                                showInColumnChooser={true}
                                visible={true}
                            />
                            <Column
                                dataField='Tel1'
                                dataType='string'
                                caption='Tel'
                                minWidth={150}
                                width='auto'
                                allowFiltering={true}
                                allowResizing={true}
                                allowEditing={false}
                                fixed={false}
                                showInColumnChooser={true}
                                visible={true}
                            />
                            <Column
                                dataField='RelationType'
                                dataType='string'
                                caption='Type'
                                minWidth={150}
                                width='auto'
                                allowFiltering={true}
                                allowResizing={true}
                                allowEditing={false}
                                fixed={false}
                                showInColumnChooser={true}
                                visible={true}
                            />
                            <Column
                                name='ActionColumnDelete'
                                caption=''
                                allowFiltering={false}
                                allowEditing={false}
                                allowColumnResizing={false}
                                allowColumnReordering={false}
                                allowExporting={false}
                                fixed={false}
                                showInColumnChooser={false}
                                visible={true}
                                width='40px'
                                alignment='center'
                                cellRender={this.renderDeleteTemplate}
                            />
                            <Column
                                name='DummyColumn'
                                caption=''
                                width='auto'
                            />
                        </DataGrid>
                    </div>
                </div>

                <PopupShuttlesAdminRelationsEditDriver
                    ref={ref => this.refPopupShuttlesAdminRelationsEditDriver = ref}
                    id={this.state.relationId}
                    onInitialized={this.popupShuttlesAdminRelationsEditDriver_OnInitialized}
                    onHiding={this.popupShuttlesAdminRelationsEditDriver_OnHiding}
                    onUpdate={this.popupShuttlesAdminRelationsEditDriver_OnUpdate}
                />

                <PopupShuttlesAdminRelationsEditOther
                    ref={ref => this.refPopupShuttlesAdminRelationsEditOther = ref}
                    id={this.state.relationId}
                    onInitialized={this.popupShuttlesAdminRelationsEditOther_OnInitialized}
                    onHiding={this.popupShuttlesAdminRelationsEditOther_OnHiding}
                    onUpdate={this.popupShuttlesAdminRelationsEditOther_OnUpdate}
                />
                <PopupShuttlesAdminRelationsEditArtistContact
                    ref={ref => this.refPopupShuttlesAdminRelationsEditArtistContact = ref}
                    id={this.state.relationId}
                    onInitialized={this.popupShuttlesAdminRelationsEditArtistContact_OnInitialized}
                    onHiding={this.popupShuttlesAdminRelationsEditArtistContact_OnHiding}
                    onUpdate={this.popupShuttlesAdminRelationsEditArtistContact_OnUpdate}
                />
            </React.Fragment>
        );

    }

}

export default ShuttlesAdminRelationsArtistsDataGrid

