import globalConfig from '../../config/global.js';

class ServiceUsers {
    ////////////////////////////////////////////////////
    // USERS
    ////////////////////////////////////////////////////

    static addUser = async () => {
        let returnValue = {};

        const model = "";

        await fetch(globalConfig.settings.host + "/api/Users/AddUser", {
            method: 'POST',
            body: JSON.stringify(model),
            headers: {
                'Content-type': 'application/json; charset=UTF-8'
            }
        })
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(error => {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static getUserById = async (id) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Users/GetUserById?id=" + encodeURIComponent(id)) // return null or data object
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static getUserByEmail = async (email) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Users/GetUserByEmail?email=" + encodeURIComponent(email)) // return null or data object
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }


    ///////////////////////////////////
    /// USERSETTINGS
    ///////////////////////////////////
    static getUserSettingsRelationsByUserId = async (userId) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Users/GetUserSettingsRelationsByUserId?userId=" + encodeURIComponent(parseInt(userId, 10)))
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;

    }

    static setUserSettingsRelations = async (userSettingsRelations) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Users/SetUserSettingsRelations", {
            method: 'POST',
            body: JSON.stringify(userSettingsRelations),
            headers: {
                'Content-type': 'application/json; charset=UTF-8'
            }
        })
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(error => {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static getUserSettingsLocationsByUserId = async (userId) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Users/GetUserSettingsLocationsByUserId?userId=" + encodeURIComponent(parseInt(userId, 10)))
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;

    }

    static setUserSettingsLocations = async (userSettingsLocations) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Users/SetUserSettingsLocations", {
            method: 'POST',
            body: JSON.stringify(userSettingsLocations),
            headers: {
                'Content-type': 'application/json; charset=UTF-8'
            }
        })
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(error => {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }


    static getUserSettingsTripTimingsByUserId = async (userId) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Users/GetUserSettingsTripTimingsByUserId?userId=" + encodeURIComponent(parseInt(userId, 10)))
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;

    }

    static setUserSettingsTripTimings = async (userSettingsTripTimings) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Users/SetUserSettingsTripTimings", {
            method: 'POST',
            body: JSON.stringify(userSettingsTripTimings),
            headers: {
                'Content-type': 'application/json; charset=UTF-8'
            }
        })
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(error => {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static getUserSettingsShuttlesByUserId = async (userId) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Users/GetUserSettingsShuttlesByUserId?userId=" + encodeURIComponent(parseInt(userId, 10)))
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;

    }

    static setUserSettingsShuttles = async (userSettingsShuttles) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Users/SetUserSettingsShuttles", {
            method: 'POST',
            body: JSON.stringify(userSettingsShuttles),
            headers: {
                'Content-type': 'application/json; charset=UTF-8'
            }
        })
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(error => {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static getUserSettingsShopsByUserId = async (userId) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Users/GetUserSettingsShopsByUserId?userId=" + encodeURIComponent(parseInt(userId, 10)))
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;

    }

    static setUserSettingsShops = async (userSettingsShops) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Users/SetUserSettingsShops", {
            method: 'POST',
            body: JSON.stringify(userSettingsShops),
            headers: {
                'Content-type': 'application/json; charset=UTF-8'
            }
        })
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(error => {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static getUserSettingsRidersByUserId = async (userId) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Users/GetUserSettingsRidersByUserId?userId=" + encodeURIComponent(parseInt(userId, 10)))
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;

    }

    static setUserSettingsRiders = async (userSettingsRiders) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Users/SetUserSettingsRiders", {
            method: 'POST',
            body: JSON.stringify(userSettingsRiders),
            headers: {
                'Content-type': 'application/json; charset=UTF-8'
            }
        })
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(error => {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static getUserSettingsFleetByUserId = async (userId) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Users/GetUserSettingsFleetByUserId?userId=" + encodeURIComponent(parseInt(userId, 10)))
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;

    }

    static setUserSettingsFleet = async (userSettingsFleet) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Users/SetUserSettingsFleet", {
            method: 'POST',
            body: JSON.stringify(userSettingsFleet),
            headers: {
                'Content-type': 'application/json; charset=UTF-8'
            }
        })
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(error => {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    /// USER FILTERS
    static getUserFilterById = async (id) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Users/GetUserFilterById?id=" + encodeURIComponent(id))
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static getUserFilterTypeIDByName = async (userFilterTypeName) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Users/GetUserFilterTypeIDByName?userFilterTypeName=" + encodeURIComponent(userFilterTypeName))
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static updateUserFilter = async (userFilterData) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Users/UpdateUserFilter", {
            method: 'POST',
            body: JSON.stringify(userFilterData),
            headers: {
                'Content-type': 'application/json; charset=UTF-8'
            }
        })
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(error => {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static saveUserFilter = async (userFilter) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Users/SaveUserFilter", {
            method: 'POST',
            body: JSON.stringify(userFilter),
            headers: {
                'Content-type': 'application/json; charset=UTF-8'
            }
        })
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(error => {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static deleteUserFilter = async (id) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Users/DeleteUserFilter", {
            method: 'POST',
            body: JSON.stringify(id),
            headers: {
                'Content-type': 'application/json; charset=UTF-8'
            }
        })
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(error => {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static deleteMultipleUserFilters = async (ids) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Users/DeleteMultipleUserFilters", {
            method: 'POST',
            body: JSON.stringify(ids),
            headers: {
                'Content-type': 'application/json; charset=UTF-8'
            }
        })
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(error => {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static updateUserFilterName = async (userFilterData) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Users/UpdateUserFilterName", {
            method: 'POST',
            body: JSON.stringify(userFilterData),
            headers: {
                'Content-type': 'application/json; charset=UTF-8'
            }
        })
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(error => {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static getUserFiltersByEventIdUserIdUserFilterTypeName = async (eventId, userId, userFilterTypeName) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Users/GetUserFiltersByEventIdUserIDUserFilterTypeName?eventId=" + encodeURIComponent(eventId) + "&userId=" + encodeURIComponent(userId) + "&userFilterTypeName=" + encodeURIComponent(userFilterTypeName))
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static checkIfUserFilterExists = async (userFilter) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Users/CheckIfUserFilterExists", {
            method: 'POST',
            body: JSON.stringify(userFilter),
            headers: {
                'Content-type': 'application/json; charset=UTF-8'
            }
        })
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(error => {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

}

export default ServiceUsers;
