import React from 'react';
import DataGrid, {
    RemoteOperations, MasterDetail, Editing, ColumnChooser, GroupPanel, FilterPanel, FilterRow, HeaderFilter, Paging, Pager, Scrolling, Column, Button, SearchPanel, KeyboardNavigation, Export, StateStoring, Sorting, Lookup, Selection
} from 'devextreme-react/data-grid';
import { LoadPanel } from 'devextreme-react/load-panel';

import { HubConnectionBuilder } from '@microsoft/signalr';
import ServiceArtists from '../../../../../api/services/ServiceArtists';

import HxToast from '../../../../custom-components/hx-toast/HxToast';
import { Workbook } from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import saveAs from 'file-saver';

import DetailTemplate from './DetailTemplate.js';

import './DataGridArtists.scss'

// props
//      onInitialized: callback
//      onExportArtist: callback
//      onConnectionError: callback
//      onEditArtist: callback
//      onDeleteArtist: callback
//
//      setSelectedGigDateIds: callable
//      setSelectedArtistIds: callable
 
class DataGridArtists extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dataSourceArtists: [],
            dataSourceArtistTypes: [],
            showFilter: false,
        };
        this.useColorsArtists = true;

        this.dataGridInstance = null;
        
        this.hubConnection = null;
         
        this.viewState = {};
   
        this.masterRows = [];

        this.loadPanelInstance = null;
        this.loadingPosition = { of: '#grid' }  }

    // CALLBACKS
    onConnectionError = () => {
        if (this.props.onConnectionError) {
            this.props.onConnectionError()
        }
    }

    onExportArtist = () => {
        if (this.props.onExportArtist) {
            this.props.onExportArtist()
        }
    }

    onInitialized = (instance) => {
        if (this.props.onInitialized) {
            this.props.onInitialized(instance)
        }
    }

    onEditArtist = (id, name, artistTypeId) => {
        if (this.props.onEditArtist) {
            this.props.onEditArtist(id, name, artistTypeId)
        }
    }

    onDeleteArtist = (id, name) => {
        if (this.props.onDeleteArtist) {
            this.props.onDeleteArtist(id, name)
        }
    }

    // EVENTS
    componentDidMount = async () => {
        await this.loadPanelInstance.show()
        this.hubConnection = new HubConnectionBuilder().withUrl("/artistsHub").build();

        await this.hubConnection.start()
            .then(function () {

            })
            .catch(function (err) {
                return console.error(err.toString());
            });

        this.hubConnection.on("ReceiveMessageEditedArtist", await this.updatePushedDataEditedArtist);
        this.hubConnection.on("ReceiveMessageAddedArtist", await this.updatePushedDataAddedArtist);
        this.hubConnection.on("ReceiveMessageDeletedArtist", await this.updatePushedDataDeletedArtist);

        await this.loaddataSourceArtists();
        await this.loadDataSourceArtistTypes()
        await this.loadPanelInstance.hide()
    }

    // CALLABLES
    exportToExcel = (name) => {
        const fileName = name;
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Sheet 1');

        exportDataGrid({
            component: this.dataGridInstance,
            worksheet: worksheet,
            autoFilterEnabled: true,
            topLeftCell: { row: 2, column: 2 },
            customizeCell: ({ gridCell, excelCell }) => {
                if (
                    gridCell.column.dataField === "Name" &&
                    gridCell.rowType === "data"
                ) {
                    this.masterRows.push({
                        rowIndex: excelCell.fullAddress.row + 1,
                        data: gridCell.data
                    });
                }
            }
        })
            .then((cellRange) => {
                const borderStyle = { style: "thin", color: { argb: "FF7E7E7E" } };
                let offset = 0;

                const insertRow = (index, offset, outlineLevel) => {
                    const currentIndex = index + offset;
                    const row = worksheet.insertRow(currentIndex, [], "n");

                    for (var j = worksheet.rowCount + 1; j > currentIndex; j--) {
                        worksheet.getRow(j).outlineLevel = worksheet.getRow(
                            j - 1
                        ).outlineLevel;
                    }

                    row.outlineLevel = outlineLevel;

                    return row;
                };

                for (var i = 0; i < this.masterRows.length; i++) {
                    let row = insertRow(this.masterRows[i].rowIndex + i, offset++, 2);
                    let columnIndex = cellRange.from.column + 1;
                    row.height = 40;

                    const columns = [
                        "GigDate",
                        "Name",
                    ];

                    row = insertRow(this.masterRows[i].rowIndex + i, offset++, 2);
                    columns.forEach((columnName, currentColumnIndex) => {
                        Object.assign(row.getCell(columnIndex + currentColumnIndex), {
                            value: columnName,
                            fill: {
                                type: "pattern",
                                pattern: "solid",
                                fgColor: { argb: "BEDFE6" }
                            },
                            font: { bold: true },
                            border: {
                                bottom: borderStyle,
                                left: borderStyle,
                                right: borderStyle,
                                top: borderStyle
                            }
                        });
                    });
                    offset--;
                }
            })
            .then(() => {
                workbook.xlsx.writeBuffer()
                    .then(function (buffer) {
                        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), fileName);
                    });
            });
    }



    // DATA
    loaddataSourceArtists = async () => {
        var data = await ServiceArtists.getArtistInfoByEventId(parseInt(sessionStorage.getItem("currentEventId"), 10), "riders");
        if (data !== null && !data.hasOwnProperty("error")) {
            this.setState({ dataSourceArtists: data })
        } else {
            this.onConnectionError();  // callback
        }
    }

    loadDataSourceArtistTypes = async () => {
        var data = await ServiceArtists.getArtistTypesInfo();
        if (data !== null && !data.hasOwnProperty("error")) {
            this.setState({ dataSourceArtistTypes: data })
        } else {
            this.onConnectionError();  // callback
        }
    }

    // LOADPANEL
    loadPanelOnInitialized = (e) => {
        // set instance
        this.loadPanelInstance = e.component;
    }

    // GRID
    gridOnCellPrepared = (e) => {
        if (e.rowType === 'data') {
            if (e.column.dataField === 'Name' || e.column.dataField === 'ArtistTypeId') {
                if (this.useColorsArtists) {
                    e.cellElement.style.backgroundColor = e.data.ColorHexArtistType;
                    e.cellElement.style.color = e.data.ForeColorHexArtistType;
                }
            } else {
                e.cellElement.style.backgroundColor = '#FFFFFF';
                e.cellElement.style.color = '#000000';
            }
        } else {
            if (e.rowType === 'filter') {
                e.cellElement.style.backgroundColor = '#FFFFFF';
                e.cellElement.style.color = '#000000';
            }
        }
    }

    gridOnFocusedCellChanging = (e) => {
        let elements = document.getElementsByClassName("hx-datagrid-cell-border");
        if (elements) {
            for (var i = 0; i < elements.length; i++) {
                elements[i].classList.remove('hx-datagrid-cell-border');
            }
        }
        e.cellElement[0].classList.add('hx-datagrid-cell-border');
    }

    gridOnCellClick = (e) => {
        if (e.rowType === 'data') {
            e.cellElement.classList.remove('dx-cell-focus-disabled');

            var found = false;
            for (var value of e.cellElement.classList) {
                if (value === 'hx-datagrid-cell-border') {
                    found = true;
                    break;
                }
            }
            if (!found) {
                e.cellElement.classList.add('hx-datagrid-cell-border');
            }
            if (e.column.name == 'ActionColumnEdit') {
                this.onEditArtist(e.data.Id, e.data.Name, e.data.ArtistTypeId);
            } else if (e.column.name == 'ActionColumnDelete') {
                if (e.data.CanBeDeleted == true) {
                    this.onDeleteArtist(e.data.Id, e.data.Name);
                }
            }
        }
    }

    gridOnEditorPreparing = (e) => {
        if (e.parentType === 'dataRow') {
            if (e.editorName === "dxNumberBox") {
                e.editorOptions.step = 0;
                e.editorElement.parentElement.classList.add('hx-datagrid-cell-border');
            } else if (e.editorName === "dxTextBox") {
                e.editorElement.parentElement.classList.add('hx-datagrid-cell-border');
            } 
        }
    }

    gridOnRowUpdating = async (e) => {
        this.dataGridInstance.beginUpdate();

        for (var key in e.newData) {
            if (e.newData.hasOwnProperty(key)) {
                if (key == "Name") {
                    var model = {
                        Id: e.oldData.Id,
                        EventId: parseInt(sessionStorage.getItem("currentEventId"), 10),
                        ColorId: null,
                        ArtistTypeId: null,
                        Name: e.newData[key].trim(),
                        RiderFinished: false,  // not used but has to have value
                        ArrivalDate: null,
                        DepartureDate: null
                    }

                    var data = await ServiceArtists.updateArtistName(model);
                    if (data !== null) {
                        if (!data.hasOwnProperty("error")) {
                            if (data === true) {
                            //    await this.gridRefresh();
                                this.hubConnection
                                    .invoke("SendUpdateInfoEditedArtist", model.Id, true)
                                    .catch(function (err) {
                                        return console.error(err.toString());
                                    });
                                HxToast.showToast('Artist updated.', 'success', 'bottom right', 'up-stack');
                            } else {
                                HxToast.showToast('Update artist failed!', 'error', 'bottom right', 'up-stack');
                            }
                        } else {
                            this.onConnectionError();  // callback
                        }
                    } else {
                        HxToast.showToast('Update artist failed!', 'error', 'bottom right', 'up-stack');
                    }
                } else if (key == "RiderFinished") {
                    var model = {
                        Id: e.oldData.Id,
                        EventId: parseInt(sessionStorage.getItem("currentEventId"), 10),
                        ColorId: null,
                        ArtistTypeId: null,
                        Name: '', // not used but has to have value
                        RiderFinished: e.newData[key],
                        ArrivalDate: null,
                        DepartureDate: null
                    }

                    var data = await ServiceArtists.updateArtistFinished(model);
                    if (data !== null) {
                        if (!data.hasOwnProperty("error")) {
                            if (data === true) {
                               // await this.gridRefresh();
                                this.hubConnection
                                    .invoke("SendUpdateInfoEditedArtist", model.Id, false)
                                    .catch(function (err) {
                                        return console.error(err.toString());
                                    });
                                HxToast.showToast('Artist updated.', 'success', 'bottom right', 'up-stack');
                            } else {
                                HxToast.showToast('Update artist failed!', 'error', 'bottom right', 'up-stack');
                            }
                        } else {
                            this.onConnectionError();  // callback
                        }
                    } else {
                        HxToast.showToast('Update artist failed!', 'error', 'bottom right', 'up-stack');
                    }
                } else if (key === 'ArtistTypeId') {
                    var model = {
                        Id: e.oldData.Id,
                        EventId: parseInt(sessionStorage.getItem("currentEventId"), 10),
                        ColorId: null,
                        ArtistTypeId: e.newData[key],
                        Name: '', // not used but has to have value
                        RiderFinished: false,  // not used but has to have value
                        ArrivalDate: null,
                        DepartureDate: null
                    }

                    var data = await ServiceArtists.updateArtistArtistType(model);
                    if (data !== null) {
                        if (!data.hasOwnProperty("error")) {
                            if (data === true) {
                                this.hubConnection
                                    .invoke("SendUpdateInfoEditedArtist", model.Id, true)
                                    .catch(function (err) {
                                        return console.error(err.toString());
                                    });
                                await this.gridRefresh();
                                HxToast.showToast('Artist updated.', 'success', 'bottom right', 'up-stack');
                            } else {
                                HxToast.showToast('Update artist failed!', 'error', 'bottom right', 'up-stack');
                            }
                        } else {
                            this.onConnectionError();  // callback
                        }
                    } else {
                        HxToast.showToast('Update artist failed!', 'error', 'bottom right', 'up-stack');
                    }
                }
                break;
            }
        }

        this.dataGridInstance.endUpdate();
    }

    gridOnEditingStart = (e) => {
        if (!e.column || e.column.dataType === 'boolean') {
            return;
        }

        // make sure onrowupdating is always triggered after edit
        var row = this.dataGridInstance.getRowIndexByKey(e.key);
        var value = this.dataGridInstance.cellValue(row, e.column.dataField);

        if (e.column.dataType.toLowerCase() == "string") {
            this.dataGridInstance.cellValue(row, e.column.dataField, (value !== "%___%" ? "%___%" : ""));
        } else {
            this.dataGridInstance.cellValue(row, e.column.dataField, (value !== 999999 ? 999999 : 0));
        }
        this.dataGridInstance.cellValue(row, e.column.dataField, value);
    }

    gridOnEditCanceled = async (e) => {
        var id = this.dataGridInstance.getKeyByRowIndex(this.dataGridInstance.option('focusedRowIndex'));
        if (id) {
            await this.updateRow(id);
        }
    }


    gridOnCellHoverChanged = (e) => {
        if (e.rowType === 'data') {
            //// hide hover color
            //for (let col of this.dataGridInstance.option('columns')) {
            //    if (col.visible && (col.dataField == 'Name' || col.dataField == 'ArtistType')) {
            //        var cell = this.dataGridInstance.getCellElement(e.rowIndex, col.dataField);
            //        if (cell !== undefined) {
            //            if (JSON.parse(sessionStorage.getItem('data')).artists.useColorsArtists) {
            //                cell.style.backgroundColor = (e.row.data.ColorHexArtistType !== null ? e.row.data.ColorHexArtistType : '#FFFFFF');
            //                cell.style.color = (e.row.data.ForeColorHexArtistType !== null ? e.row.data.ForeColorHexArtistType : '#000000');
            //            } else {
            //                cell.style.backgroundColor = '#FFFFFF';
            //                cell.style.color = '#000000';
            //            }
            //        }
            //    }
            //}
        } 
    }

    gridOnRowExpanding = async (e) => {
        this.dataGridInstance.beginUpdate();
        this.dataGridInstance.collapseAll(-1);
        this.dataGridInstance.option('focusedRowKey', -1);
        this.dataGridInstance.clearSelection();
        this.dataGridInstance.endUpdate();
    }

    gridOnRowCollapsing = (e) => {
    //    this.dataGridInstance.option('focusedRowKey', -1);
    //    this.dataGridInstance.clearSelection();
    //    this.dataGridInstance.refresh();
    }

    gridOnExporting = (e) => {
        e.cancel = true;
        this.onExportArtist();  // callback
    }
    gridOnInitialized = (e) => {
        // set instance
        this.dataGridInstance = e.component;
        this.onInitialized(e.component);  // callback

        // set focused row
        this.dataGridInstance.option('focusedRowIndex', 0);
    }

    gridOnToolbarPreparing = (e) => {
        //let toolbarItems = e.toolbarOptions.items;
        //toolbarItems.push({
        //    widget: 'dxButton',
        //    options: {
        //        icon: 'filter',
        //        onClick: this.switchFilter
        //    },
        //    location: 'after'
        //});
    }

    gridOnContentReady = (e) => {
         // check filters, 'artist', 'artist' and 'type' input fields become empty if cols added (dx bug)
         if (this.viewState.filters !== undefined) {
            for (let filter in this.viewState.filters) {
                if (this.state.dataSourceArtists !== undefined && this.state.dataSourceArtists.length > 0 && this.state.dataSourceArtists[0].hasOwnProperty(filter)) {
                    if (this.dataGridInstance.columnOption(filter, 'filterValue') !== this.viewState.filters[filter]) {
                        this.dataGridInstance.columnOption(filter, 'filterValue', this.viewState.filters[filter]);
                    }
                }
            }
        }
    }

    gridOnSelectionChanged = (e) => {
        //for (let key of e.selectedRowKeys) {
        //    var element = this.dataGridInstance.getRowElement(this.dataGridInstance.getRowIndexByKey(key));
        //    element[0].classList.remove('dx-selection');
        //    element[1].classList.remove('dx-selection');
        //    element[0].classList.remove('dx-row-focused');
        //    element[1].classList.remove('dx-row-focused');
        //}
    }

    gridRefresh = async () => {
        await this.loadPanelInstance.show()
        await this.loaddataSourceArtists();
        await this.loadDataSourceArtistTypes;
        await this.loadPanelInstance.hide()
    }


    switchFilter = () => {
        this.setState(prevState => ({
            showFilter: !prevState.showFilter
        }));
    }

    showFilter = (show) => {
        this.setState({ showFilter: show });
    }

    // update cells modified by other users (collaborative editing, SignalR)
    updatePushedDataEditedArtist = async (artistId, checkIsEditing) => {
        if (checkIsEditing) {
            let isEditing = this.dataGridInstance.element().querySelector(".dx-datagrid-rowsview td.dx-editor-cell.dx-focused") != null;
            if (!isEditing) {
                await this.updateRow(artistId);
            }
        } else {
            await this.updateRow(artistId);
        }
    }

    updateRow = async (id) => {
        this.dataGridInstance.beginUpdate();
        var data = await ServiceArtists.getArtistInfoById(parseInt(sessionStorage.getItem("currentEventId"), 10), id, 'riders');
        this.dataGridInstance.endUpdate();
        var dsRow = this.state.dataSourceArtists.find(function (row) {
            return row.Id === id;
        });
        if (dsRow) {
            Object.entries(dsRow).forEach(([key, value]) => {
                dsRow[key] = data[key];
            });
        }
        var rowIndex = this.dataGridInstance.getRowIndexByKey(id);
        this.dataGridInstance.repaintRows(rowIndex);
        setTimeout(this.gridFocus, 10);
    }

    gridFocus = () => {
        this.dataGridInstance.focus();
    }

    updatePushedDataAddedArtist = async (artistId) => {
        await this.gridRefresh();
    }

    updatePushedDataDeletedArtist = async (artistId) => {
        await this.gridRefresh();
    }

    renderEditTemplate = (e) => {
        return <div style={{ display: 'flex', justifyContent: 'start' }}>
            <div style={{ width: '40px' }}><i className='dx-icon-edit' style={{ color: '#FF5722' }} /></div>
        </div>;
    }

    renderDeleteTemplate = (e) => {
        if (e.data.CanBeDeleted == true) {
            return <div style={{ display: 'flex', justifyContent: 'start' }}>
                <div style={{ width: '40px' }}><i className='dx-icon-trash' style={{ color: '#FF5722' }} /></div>
            </div>;
        } else {
            return <div style={{ display: 'flex', justifyContent: 'start' }}>
                <div style={{ width: '40px' }}><i className='dx-icon-trash' style={{ color: '#666666' }} /></div>
            </div>;
        }
    }

    render() {
        return (
            <React.Fragment>
                <div id="grid" className="dx-card raadg-main-div">
                    <DataGrid
                        ref={ref => this.refDataGrid = ref}
                        height='100%'
                        width='100%'
                        dataSource={this.state.dataSourceArtists}
                        keyExpr='Id'
                        allowColumnReordering={false}
                        allowColumnResizing={true}
                        allowGrouping={false}
                        columnAutoWidth={true}
                        columnResizingMode='widget'
                        filterSyncEnabled={true}
                        focusedRowEnabled={false}
                        remoteOperations={true}
                        repaintChangesOnly={true}
                        showBorders={false}
                        showColumnLines={true}
                        showRowLines={true}
                        wordWrapEnabled={false}
                        noDataText='No artists found.'
                        onExporting={this.gridOnExporting}
                        onInitialized={this.gridOnInitialized}
                        onCellPrepared={this.gridOnCellPrepared}
                        onToolbarPreparing={this.gridOnToolbarPreparing}
                        onRowUpdating={this.gridOnRowUpdating}
                        onCellClick={this.gridOnCellClick}
                        onFocusedCellChanging={this.gridOnFocusedCellChanging}
                        onEditorPreparing={this.gridOnEditorPreparing}
                        onContentReady={this.gridOnContentReady}
                        onSelectionChanged={this.gridOnSelectionChanged}
                        onCellHoverChanged={this.gridOnCellHoverChanged}
                        onRowExpanding={this.gridOnRowExpanding}
                        onRowCollapsing={this.gridOnRowCollapsing}
                        onEditCanceled={this.gridOnEditCanceled}
                        onEditingStart={this.gridOnEditingStart}
                    >
                        <StateStoring enabled={false} type="localStorage" storageKey="storage" />
                        <KeyboardNavigation
                            enabled={true}
                            editOnKeyPress={true}
                            enterKeyAction='moveFocus'
                            enterKeyDirection='column' />
                        <Editing
                            mode="cell"
                            allowUpdating={true}
                            selectTextOnEditStart={true}
                            startEditAction='dblClick'
                            step={0}
                        />
                        <LoadPanel enabled={true} />
                        <Sorting mode="multiple" />
                        <ColumnChooser enabled={false} />
                        <FilterPanel visible={false} />
                        <FilterRow visible={this.state.showFilter} />
                        <GroupPanel visible={false} />
                        <SearchPanel visible={true} />
                        <HeaderFilter visible={true} />
                        <Paging enabled={false} defaultPageSize={20} />
                        <Pager
                            showPageSizeSelector={true}
                            allowedPageSizes={[5, 10, 20, 50]}
                            showInfo={true}
                        />
                        <Scrolling
                            mode="virtual"
                            useNative='false'
                            scrollByContent={true}
                            scrollByThumb={true}
                            showScrollbar='always'
                        />
                        <Column
                            dataField='Id'
                            dataType='number'
                            caption='Id'
                            allowFiltering={false}
                            allowEditing={false}
                            fixed={true}
                            showInColumnChooser={false}
                            visible={false}
                        />
                        <Column
                            dataField='UsePattern'
                            dataType='number'
                            caption='UsePattern'
                            allowFiltering={false}
                            allowEditing={false}
                            fixed={true}
                            showInColumnChooser={false}
                            visible={false}
                        />
                        <Column
                            dataField='ColorIdArtistType'
                            dataType='number'
                            caption='ColorId'
                            allowFiltering={false}
                            allowEditing={false}
                            fixed={true}
                            showInColumnChooser={false}
                            visible={false}
                        />
                        <Column
                            dataField='CanBeDeleted'
                            caption='Finished'
                            width='100px'
                            allowFiltering={false}
                            allowEditing={true}
                            fixed={true}
                            showInColumnChooser={true}
                            visible={false}
                        />
                        <Column
                            dataField='PatternIdArtistType'
                            dataType='number'
                            caption='PatternId'
                            allowFiltering={false}
                            allowEditing={false}
                            fixed={true}
                            showInColumnChooser={false}
                            visible={false}
                        />
                        <Column
                            dataField='ColorHexArtistType'
                            dataType='string'
                            caption='ColorHex'
                            allowFiltering={false}
                            allowEditing={false}
                            fixed={true}
                            showInColumnChooser={false}
                            visible={false}
                        />
                        <Column
                            dataField='ForeColorHexArtistType'
                            dataType='string'
                            caption='ForeColorHex'
                            allowFiltering={false}
                            allowEditing={false}
                            fixed={true}
                            showInColumnChooser={false}
                            visible={false}
                        />
                        <Column
                            dataField='PatternColorHexArtistType'
                            dataType='string'
                            caption='PatternColorHex'
                            allowFiltering={false}
                            allowEditing={false}
                            fixed={true}
                            showInColumnChooser={false}
                            visible={false}
                        />
                        <Column
                            dataField='PatternCssBackGroundArtistType'
                            dataType='string'
                            caption='PatternCssBackGround'
                            allowFiltering={false}
                            allowEditing={false}
                            fixed={true}
                            showInColumnChooser={false}
                            visible={false}
                        />
                        <Column
                            dataField='PatternCssBackGroundImageArtistType'
                            dataType='string'
                            caption='PatternCssBackGroundImage'
                            allowFiltering={false}
                            allowEditing={false}
                            fixed={true}
                            showInColumnChooser={false}
                            visible={false}
                        />
                        <Column
                            dataField='PatternCssBackGroundSizeArtistType'
                            dataType='string'
                            caption='PatternCssBackGroundSize'
                            allowFiltering={false}
                            allowEditing={false}
                            fixed={true}
                            showInColumnChooser={false}
                            visible={false}
                        />
                        <Column
                            dataField='PatternCssBackGroundPositionArtistType'
                            dataType='string'
                            caption='PatternCssBackGroundPositionArtistType'
                            allowFiltering={false}
                            allowEditing={false}
                            fixed={true}
                            showInColumnChooser={false}
                            visible={false}
                        />
                        <Column
                            dataField='PatternTransparencyArtistType'
                            dataType='number'
                            caption='PatternTransparency'
                            allowFiltering={false}
                            allowEditing={false}
                            fixed={true}
                            showInColumnChooser={false}
                            visible={false}
                        />
                        <Column
                            name='ActionColumnEdit'
                            caption=''
                            allowFiltering={false}
                            allowEditing={false}
                            allowColumnResizing={false}
                            allowColumnReordering={false}
                            allowExporting={false}
                            fixed={true}
                            showInColumnChooser={false}
                            visible={true}
                            width={40}
                            alignment='center'
                            cellRender={this.renderEditTemplate}
                        />
                        <Column
                            dataField='Name'
                            dataType='string'
                            caption='Artist'
                            width='300px'
                            allowFiltering={true}
                            allowEditing={true}
                            fixed={true}
                            showInColumnChooser={true}
                            visible={true}
                        />
                        <Column
                            dataField='ArtistTypeId'
                            dataType='number'
                            caption='Type'
                            allowFiltering={true}
                            allowEditing={true}
                            fixed={true}
                            width='150px'
                            showInColumnChooser={false}
                            visible={true}
                        >
                            <Lookup
                                dataSource={this.state.dataSourceArtistTypes}
                                displayExpr="Name"
                                valueExpr="Id"
                            />
                        </Column>
                        <Column
                            dataField='RiderFinished'
                            dataType='boolean'
                            caption='Finished'
                            width='100px'
                            allowFiltering={false}
                            allowEditing={true}
                            fixed={true}
                            showInColumnChooser={true}
                            visible={true}
                        />
                        <Column
                            name='ActionColumnDelete'
                            caption=''
                            allowFiltering={false}
                            allowEditing={false}
                            allowColumnResizing={false}
                            allowColumnReordering={false}
                            allowExporting={false}
                            fixed={true}
                            showInColumnChooser={false}
                            visible={true}
                            width={40}
                            alignment='center'
                            cellRender={this.renderDeleteTemplate}
                        />
                        <Column key='artistcoldummy' caption='' allowFiltering={false} allowResizing={true} allowExporting={false} width='100%' />
                        <MasterDetail
                            enabled={true}
                            component={DetailTemplate}
                        />
                    </DataGrid>
                </div>
                <LoadPanel
                    onInitialized={this.loadPanelOnInitialized}
                    shadingColor="rgba(0,0,0,0.4)"
                    position={this.loadingPosition}
                    shading={false}
                />
            </React.Fragment>
        );
    }
}

export default DataGridArtists

