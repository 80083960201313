import React from 'react';
import * as dayJS from 'dayjs';
import { LoadPanel } from 'devextreme-react/load-panel';

import DataGrid, {
    RemoteOperations, Editing, ColumnChooser, GroupPanel, FilterPanel, FilterRow, HeaderFilter, Paging, Pager, Scrolling, Column, SearchPanel, KeyboardNavigation, Export, StateStoring, Sorting, Toolbar, Item
} from 'devextreme-react/data-grid';
import ServiceRiders from '../../../../api/services/ServiceRiders'
import ServiceGeneral from '../../../../api/services/ServiceGeneral'

import './PopupExportDayOverViewDataGrid.scss'


// props
//
//      onInitialized: callback
//      onContentReady: callback
//      onDisposing: callback
//      onConnectionError: callback
     
class PopupExportDayOverViewDataGrid extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dataSourceRiders: [],
            dataSourceGigDates: [],
            headerText: '',
            isLoadPanelVisible: false,
        };

        this.dataSourceSubArtistColors = [];
        this.dataGridInstance = null;

        this.ColsBand = [];
        this.ColsAdded = false;

        this.userSettings = {
            UserId: parseInt(sessionStorage.getItem("currentUserId"), 10),
            DefaultViewId: -1,
            DefaultFilterId: -1,
            ExportColors: true,
            MultiSelectionGigDates: false,
            MultiSelectionArtists: true,
            UseBlanksForZeroes: false,
            UseColorsProducts: true,
            UseColorsArtists: true,
            ColorOriginProducts: 'shop',
            ColorOriginArtists: 'artist',
            ShowSelectedCell: false,
            UseAdvancedFiltering: false,
        }
    }

    // CALLBACKS
    onInitialized = (instance) => {
        if (this.props.onInitialized && this.props.selectedGigDateId) {
            this.props.onInitialized(instance, this.props.selectedGigDateId)
        }
    }

    onContentReady = (instance) => {
        if (this.props.onContentReady && this.props.selectedGigDateId) {
            this.props.onContentReady(instance, this.props.selectedGigDateId);  // callback
        }
    }

    onConnectionError = () => {
        if (this.props.onConnectionError) {
            this.props.onConnectionError();
        }
    }

    onDisposing = (instance) => {
        if (this.props.onDisposing && this.props.selectedGigDateId) {
            this.props.onDisposing(instance, this.props.selectedGigDateId)
        }
    }

    // CALLABLES



    // EVENTS
    componentDidMount = async () => {
            this.setState({ isLoadPanelVisible: true });
            await this.loadDataSourceGigDates();
            await this.loadDataSourceSubArtistColors();
            if (this.props.selectedShopId !== undefined && this.props.selectedShopId > -1) {
                await this.loadDataSourceDayOverViewShop();
            } else {
                await this.loadDataSourceDayOverView();
            }
            this.setState({ isLoadPanelVisible: false });
    }

    componentDidUpdate = (prevProps, prevState) => {
        // Typical usage (don't forget to compare props/states):
        if (this.state.dataSourceRiders !== prevState.dataSourceRiders) {
            this.onContentReady(this.dataGridInstance);  // callback
        }
    }


    // DATA LOADS
    loadDataSourceDayOverView = async () => {
        var data = await ServiceRiders.getDayOverView(parseInt(sessionStorage.getItem("currentEventId"), 10), this.props.selectedGigDateId, this.userSettings.UseBlanksForZeroes, this.userSettings.ColorOriginProducts);
        if (data !== null && !data.hasOwnProperty("error")) {
            this.setState({ dataSourceRiders: data })
        } else {
            this.onConnectionError();  // callback
        }
    }

    loadDataSourceDayOverViewShop = async () => {
        var data = await ServiceRiders.getDayOverViewByShopId(parseInt(sessionStorage.getItem("currentEventId"), 10), this.props.selectedGigDateId, this.props.selectedShopId, this.userSettings.UseBlanksForZeroes, this.userSettings.ColorOriginProducts);
        if (data !== null && !data.hasOwnProperty("error")) {
            this.setState({ dataSourceRiders: data })
        } else {
            this.onConnectionError();  // callback
        }
    }

    loadDataSourceGigDates = async () => {
        var data = await ServiceGeneral.getGigDatesByEventId(parseInt(sessionStorage.getItem("currentEventId"), 10), 'riders');
        if (data !== null && !data.hasOwnProperty("error")) {
            var convertedDataSourceGigDates = [];
            data.forEach((item, index, array) => {
                convertedDataSourceGigDates.push({ Id: item.Id, GigDate: dayJS(item.GigDate).format('ddd DD/MM/YY') });
            });
            this.setState({ dataSourceGigDates: convertedDataSourceGigDates })

            var gigdate = convertedDataSourceGigDates.find(x => x.Id == this.props.selectedGigDateId);
            var header = gigdate !== undefined && gigdate !== null ? gigdate.GigDate : '';
            this.setState({ headerText: header });

        } else {
            this.onConnectionError();  // callback
        }
    }

    loadDataSourceSubArtistColors = async () => {
        var arr = [];
        arr.push(this.props.selectedGigDateId);
        var data = await ServiceRiders.getSubArtistsColorsByEventIdGigDateIds(parseInt(sessionStorage.getItem("currentEventId"), 10), JSON.stringify(arr), this.userSettings.ColorOriginArtists);
        if (data !== null && !data.hasOwnProperty("error")) {
            this.dataSourceSubArtistColors = data;
        } else {
            this.onConnectionError();  // callback
        }
    }

    gridOnInitialized = async (e) => {
        // set instance
        this.onInitialized(e.component);  // callback
        
        this.dataGridInstance = e.component;
    }

    gridOnDisposing = (e) => {
        this.onDisposing(e.component);  // callback
    }

    gridOnContentReady = (e) => {
        //this.onContentReady(e.component);  // callback
    }

    gridOnCellPrepared = (e) => {
        if (e.rowType === 'data') {
            if (e.column.dataField === 'Shops' || e.column.dataField === 'ProductName') {
                if (this.props.exportColors == true) {
                    e.cellElement.style.backgroundColor = e.data.ColorHex;
                    e.cellElement.style.color = e.data.ForeColorHex;
                } else {
                    e.cellElement.style.backgroundColor = '#FFFFFF';
                    e.cellElement.style.color = '#000000';
                }
            } else {
                if (e.column.dataField !== undefined && e.column.dataField.startsWith('_SUBARTIST_')) {
                    let colName = e.column.dataField.replace('_SUBARTIST_', '').replace('_Amount', '').replace('_Comment', '');
                    let saId = parseInt(colName.split("_")[0], 10);
                    e.cellElement.classList.remove('hx-column-style-day-overview-grid-' + saId);
                }
                e.cellElement.style.backgroundColor = '#FFFFFF';
                e.cellElement.style.color = '#000000';
            }
        } else if (e.rowType === 'header') {
            if (e.column.dataField == undefined) {
                e.cellElement.style.backgroundColor = '#000000';
                e.cellElement.style.color = '#FFFFFF';
            } else {
                if (e.column.dataField.startsWith('_SUBARTIST_')) {
                    if (this.props.exportColors == false) {
                        let colName = e.column.dataField.replace('_SUBARTIST_', '').replace('_Amount', '').replace('_Comment', '');
                        let saId = parseInt(colName.split("_")[0], 10);
                        e.cellElement.classList.remove('hx-column-style-day-overview-grid-' + saId);
                    }
                }
                e.cellElement.style.backgroundColor = '#FFFFFF';
                e.cellElement.style.color = '#000000';
            }
        }
    }

    addSubArtistColumns = () => {

        // define columns array (subartists)
        if (this.ColsAdded === false) {
            let productRow = {};
            if (this.state.dataSourceRiders !== undefined && this.state.dataSourceRiders.length > 0 & this.state.dataSourceGigDates !== undefined && this.state.dataSourceGigDates.length > 0) {
                // get subartist column names and colors from first row
                productRow = this.state.dataSourceRiders[0];
                let artistId = -1;
                let artistName = '';
                let cols = [];
                let sheet = document.createElement('style');
                sheet.id = 'style-columns-' + this.props.selectedGigDateId;
                let css = '';
                let cssBand = '';
                let prevFormattedGigDate = '';
                let prevGigDateId = -1;

                for (var key in productRow) {
                    if (productRow.hasOwnProperty(key)) {
                        if (key.startsWith('_SUBARTIST_') === true) {
                            let colName = key.replace('_SUBARTIST_', '').replace('_Amount', '').replace('_Comment', '');
                            let colArtistId = parseInt(key.replace('_SUBARTIST_', '').replace('_Amount', '').replace('_Comment', '').split("_")[1], 10);
                            let gigDateId = parseInt(key.replace('_SUBARTIST_', '').replace('_Amount', '').replace('_Comment', '').split("_")[2], 10);
                            let gigDate = this.state.dataSourceGigDates.find(x => x.Id == gigDateId);
                            let formattedGigDate = gigDate.GigDate;

                            prevGigDateId = gigDateId;

                            if (colArtistId !== artistId) {
                                if (artistId > -1) {
                                   // this.ColsBand.push(<Column key={'band' + artistId + prevGigDateId} caption={artistName + ' ' + prevFormattedGigDate} cssClass={cssBand}>{cols}</Column>);  // key must be used in ES6 (unique)
                                    this.ColsBand.push(<Column key={'band' + artistId + prevGigDateId} caption={artistName} cssClass={cssBand}>{cols}</Column>);  // key must be used in ES6 (unique)
                                }

                                cols = [];
                                artistId = colArtistId;
                                prevFormattedGigDate = formattedGigDate;
                            }

                            let saId = parseInt(colName.split("_")[0], 10);
                            let caption = colName;
                            let bg = '#FFFFFF';
                            let fg = '#000000';

                            if (this.dataSourceSubArtistColors !== undefined && this.dataSourceSubArtistColors.length > 0) {
                                let index = this.dataSourceSubArtistColors.findIndex(subArtistId => subArtistId.SubArtistId === saId);
                                if (index > -1) {
                                    artistName = this.dataSourceSubArtistColors[index].ArtistName;
                                    caption = (this.dataSourceSubArtistColors[index].SubArtistTag ? this.dataSourceSubArtistColors[index].SubArtistTag + "-" : "") + this.dataSourceSubArtistColors[index].SubArtistName;
                                    if (this.props.exportColors == true) {
                                        bg = this.dataSourceSubArtistColors[index].ColorHex != null ? this.dataSourceSubArtistColors[index].ColorHex : bg;
                                        fg = this.dataSourceSubArtistColors[index].ForeColorHex != null ? this.dataSourceSubArtistColors[index].ForeColorHex : fg;
                                     }
                                }
                            }

                            css = 'hx-column-style-day-overview-grid-' + saId;
                            if (sheet.innerHTML.indexOf(css) == -1) {
                                sheet.innerHTML += '.' + css + ' {color: ' + fg + ' !important; background-color: ' + bg + ' !important;}';
                                sheet.innerHTML += '.' + css + ':hover {color: ' + fg + ' !important; background-color: ' + bg + ' !important;}';
                                cssBand = 'hx-column-band-style-day-overview-grid-' + saId;
                                sheet.innerHTML += '.' + cssBand + ' {color: ' + fg + ' !important; background-color: ' + bg + ' !important;}';
                                sheet.innerHTML += '.' + cssBand + ':hover {color: ' + fg + ' !important; background-color: ' + bg + ' !important;}';
                            }

                            if (key.endsWith('_Amount')) {
                                cols.push(<Column key={'col' + colName + 'amount'} dataField={key} dataType='number' caption={caption} visible={true} cssClass={css} allowEditing={true} allowResizing={true} allowFiltering={false} width='auto' minWidth='50px' />);  // key must be used in ES6 (unique)
                            } else if (key.endsWith('_Comment')) {
                                caption += ' Comment'
                                cols.push(<Column key={'col' + colName + 'comment'} dataField={key} dataType='string' caption={caption} visible={true} cssClass={css} allowEditing={true} allowResizing={true} allowFiltering={false} width='auto' minWidth='50px' />);  // key must be used in ES6 (unique)
                            }
                        }
                    }
                }

                if (artistId > -1) {
                    //this.ColsBand.push(<Column key={'band' + artistId + prevGigDateId} caption={artistName + ' ' + prevFormattedGigDate} cssClass={cssBand}>{cols}</Column>);  // key must be used in ES6 (unique)
                    this.ColsBand.push(<Column key={'band' + artistId + prevGigDateId} caption={artistName} cssClass={cssBand}>{cols}</Column>);  // key must be used in ES6 (unique)
                }

                this.ColsAdded = true;

                var child = document.getElementById(sheet.id);
                if (child) {
                    document.body.removeChild(child);
                }
                document.body.appendChild(sheet);

                // if column is removed and filtered => remove filter, otherwise crash
                if (this.dataGridInstance) {
                    let arrFilter = this.dataGridInstance.option('columns').filter(function (col) { return col.hasOwnProperty('columns') })  // if 'columns' is defined we are dealing with artist
                    for (let col of arrFilter) {
                        for (let sa of col.columns) {
                            if (!productRow.hasOwnProperty(sa.dataField)) {
                                this.dataGridInstance.columnOption(sa.dataField, 'filterValue', null);
                            }
                        }
                    }
                }
            } else {
                // if column is removed and filtered => remove filter, otherwise crash
                if (this.dataGridInstance) {
                    let arrFilter = this.dataGridInstance.option('columns').filter(function (col) { return col.hasOwnProperty('columns') })  // if 'columns' is defined we are dealing with artist
                    for (let col of arrFilter) {
                        for (let sa of col.columns) {
                            this.dataGridInstance.columnOption(sa.dataField, 'filterValue', null);
                        }
                    }
                }
            }
        }
    }

    // reset added columns (columns are added back on render)
    resetSubArtistColumns = async () => {
        this.ColsBand = [];
        this.ColsAdded = false;
    }
 
    render() {

        // get subartist cols
        this.addSubArtistColumns();

        return (
            <React.Fragment>
                <div style={{ display: (this.props.visible ? 'block' : 'none') }}>
                    <DataGrid
                        ref={ref => this.refDataGrid = ref}
                        height={385}
                        width='100%'
                        dataSource={this.state.dataSourceRiders}
                        allowColumnReordering={false}
                        allowColumnResizing={true}
                        allowGrouping={false}
                        columnResizingMode='widget'
                        columnAutoWidth={true}
                        remoteOperations={true}
                        wordWrapEnabled={true}
                        onInitialized={this.gridOnInitialized}
                        onCellPrepared={this.gridOnCellPrepared}
                        onDisposing={this.gridOnDisposing}
                        onContentReady={this.gridOnContentReady}
                    >
                        <ColumnChooser enabled={false} />
                        <FilterPanel visible={false} />
                        <FilterRow visible={false} />
                        <GroupPanel visible={false} />
                        <SearchPanel visible={false} />
                        <HeaderFilter visible={false} />
                        <Column
                            caption={this.state.headerText}
                        >
                            <Column
                                dataField='ColorHex'
                                dataType='string'
                                caption='ColorHex'
                                allowFiltering={false}
                                allowEditing={false}
                                fixed={true}
                                showInColumnChooser={false}
                                visible={false}
                            />
                            <Column
                                dataField='ForeColorHex'
                                dataType='string'
                                caption='ForeColorHex'
                                allowFiltering={false}
                                allowEditing={false}
                                fixed={true}
                                showInColumnChooser={false}
                                visible={false}
                            />
                            <Column
                                dataField='PatternColorHex'
                                dataType='string'
                                caption='PatternColorHex'
                                allowFiltering={false}
                                allowEditing={false}
                                fixed={true}
                                showInColumnChooser={false}
                                visible={false}
                            />
                            <Column
                                dataField='UsePattern'
                                dataType='number'
                                caption='UsePattern'
                                allowFiltering={false}
                                allowEditing={false}
                                fixed={true}
                                showInColumnChooser={false}
                                visible={false}
                            />
                            <Column
                                dataField='PatternCssBackGround'
                                dataType='string'
                                caption='PatternCssBackGround'
                                allowFiltering={false}
                                allowEditing={false}
                                fixed={true}
                                showInColumnChooser={false}
                                visible={false}
                            />
                            <Column
                                dataField='PatternCssBackGroundImage'
                                dataType='string'
                                caption='PatternCssBackGroundImage'
                                allowFiltering={false}
                                allowEditing={false}
                                fixed={true}
                                showInColumnChooser={false}
                                visible={false}
                            />
                            <Column
                                dataField='PatternCssBackGroundSize'
                                dataType='string'
                                caption='PatternCssBackGroundSize'
                                allowFiltering={false}
                                allowEditing={false}
                                fixed={true}
                                showInColumnChooser={false}
                                visible={false}
                            />
                            <Column
                                dataField='PatternCssBackGroundPosition'
                                dataType='string'
                                caption='PatternCssBackGroundPosition'
                                allowFiltering={false}
                                allowEditing={false}
                                fixed={true}
                                showInColumnChooser={false}
                                visible={false}
                            />
                            <Column
                                dataField='PatternTransparency'
                                dataType='number'
                                caption='PatternTransparency'
                                allowFiltering={false}
                                allowEditing={false}
                                fixed={true}
                                showInColumnChooser={false}
                                visible={false}
                            />
                            <Column
                                dataField='Shops'
                                dataType='string'
                                caption='Shop'
                                width={150}
                                allowFiltering={true}
                                allowResizing={true}
                                allowEditing={false}
                                fixed={true}
                                showInColumnChooser={true}
                                visible={true}
                            />
                            <Column
                                dataField='ProductName'
                                dataType='string'
                                caption='Product'
                                sortIndex={0}
                                sortOrder='Asc'
                                width={375}
                                allowFiltering={true}
                                allowResizing={true}
                                allowEditing={false}
                                fixed={true}
                                showInColumnChooser={true}
                                visible={true}
                            />
                            <Column
                                dataField='TotalAmount'
                                dataType='number'
                                caption='Total'
                                width={150}
                                allowFiltering={true}
                                allowResizing={true}
                                allowEditing={false}
                                fixed={true}
                                showInColumnChooser={true}
                                visible={true}
                            />
                            {this.ColsBand}
                        </Column>
                    </DataGrid>
                </div>
                <LoadPanel
                    hideOnOutsideClick={true}
                    visible={this.state.isLoadPanelVisible}
                />

            </React.Fragment>

        );

    }

}

export default PopupExportDayOverViewDataGrid

