import React from 'react';
import { Popup } from 'devextreme-react/popup';
import TextBox from 'devextreme-react/text-box';
import Validator, { AsyncRule, ValidationRule, EmailRule } from 'devextreme-react/validator';
import { Button } from 'devextreme-react/button';
import { ValidationGroup, dxValidationGroupResult } from 'devextreme-react/validation-group';
import SelectBox from 'devextreme-react/select-box';
import { CheckBox } from 'devextreme-react/check-box';
import ColorBox from 'devextreme-react/color-box';
import DataGrid, {
    Column,
    FormItem,
    Editing,
    Paging,
    Lookup,
} from 'devextreme-react/data-grid';
import HtmlEditor, {
    Toolbar, MediaResizing, ImageUpload, Item,
} from 'devextreme-react/html-editor';
import HxButton from '../../../../custom-components/hx-button/HxButton'

import ServiceRelations from '../../../../../api/services/ServiceRelations'
import ServiceColors from '../../../../../api/services/ServiceColors'
import ServiceDrivers from '../../../../../api/services/ServiceDrivers'
import ServiceEvents from '../../../../../api/services/ServiceEvents'

import './PopupShuttlesAdminRelationsEditDriver.scss'
import globalConfig from '../../../../../config/global.js';


// props
//      id (required): relation id
//
//      onInitialized: callback
//      onUpdate: callback
//      onLoadError: callback
//      onHiding: callback

class PopupShuttlesAdminRelationsEditDriver extends React.Component {
    constructor(props) {
        super(props);

        this.relationId = -1;

        this.state = {
            driverAvailabilityDataSource: [],
            previewText: '',
        };
        this.relationTypesDataSource = [];

        this.popupInstance = null;
        this.validationGroupInstance = null;

        this.textBoxFirstNameInstance = null;
        this.textBoxLastNameInstance = null;
        this.textBoxEmail1Instance = null;
        this.textBoxTel1Instance = null;
        this.htmlEditorNotesInstance = null;
        this.textBoxShortNameInstance = null;
        this.colorBoxColorHexInstance = null;
        this.colorBoxForeColorHexInstance = null;
        this.selectBoxPatternsInstance = null;
        this.colorBoxPatternColorHexInstance = null;

        this.dataGridInstance = null;

        this.checkBoxKeepOpenInstance = null;
        this.buttonUpdateInstance = null;

        this.relationRecord = {};
        this.relationDefaultRecord = {
            Id: -1,
            EventId: parseInt(sessionStorage.getItem('currentEventId'), 10),
            ColorId: null,
            PatternId: null,
            RelationTypeId: -1,
            FirstName: '',
            LastName: '',
            Street: null,
            StreetNo: null,
            ZipCode: null,
            City: null,
            CountryCode: null,
            Country: '',
            Tel1: null,
            Tel1Mobile: null,
            Tel2: null,
            Tel2Mobile: null,
            Email1: null,
            Email2: '',
            BirthDate: null,
            Gender: null,
            NationalRefNumber: null,
            RelationImage: null,
            Notes: null,
            RelationType: '',
            ColorHex: '#FFFFFF',
            ColorRgba: 'rgba(255,255,255,1)',
            ForeColorHex: '#000000',
            ForeColorRgba: 'rgba(0,0,0,1)',
            PatternColorHex: '#000000',
            PatternColorRgba: 'rgba(0,0,0,1)',
            UsePattern: false,
            PatternCssBackGround: null,
            PatternCssBackGroundImage: null,
            PatternCssBackGroundSize: null,
            PatternCssBackGroundPosition: null,
        }

        this.driverRecord = {};
        this.driverDefaultRecord = {
            Id: -1,
            EventId: parseInt(sessionStorage.getItem('currentEventId'), 10),
            RelationId: -1,
            ColorId: null,
            PatternId: null,
            ShortName: '',
            ColorHex: '#FFFFFF',
            ColorRgba: 'rgba(255,255,255,1)',
            ForeColorHex: '#000000',
            ForeColorRgba: 'rgba(0,0,0,1)',
            PatternColorHex: '#000000',
            PatternColorRgba: 'rgba(0,0,0,1)',
            UsePattern: false,
            PatternCssBackGround: null,
            PatternCssBackGroundImage: null,
            PatternCssBackGroundSize: null,
            PatternCssBackGroundPosition: null,
        }

        this.eventRecord = {};
        this.eventDates = [];

        this.backgroundPatterns = null;

        this.driverAvailabilitiesDS = [];

    }

    // CALLBACKS
    onInitialized = (instance) => {
        if (this.props.onInitialized) {
            this.props.onInitialized(instance);  // callback
        }
    }

    onLoadError = () => {
        if (this.props.onLoadError) {
            this.props.onLoadError();  // callback
        }
    }

    onUpdate = (success) => {
        if (this.props.onUpdate) {
            this.props.onUpdate(success);  // callback
        }
    }

    onHiding = () => {
        if (this.props.onHiding) {
            this.props.onHiding();  // callback
        }
    }
    // CALLABLES

    // EVENTS
    componentDidMount = async () => {
        await this.loadDataSourceBackGroundPatterns();
        await this.loadDataSourceEvent(parseInt(sessionStorage.getItem('currentEventId'), 10));
        await this.loadDataSourceEventDates(parseInt(sessionStorage.getItem('currentEventId'), 10));
        await this.loadDataSourceRelationTypes();

    }

    // DATA
    loadDataSourceRelation = async (relationId) => {
        var data = await ServiceRelations.getRelationByEventIdRelationId(parseInt(sessionStorage.getItem('currentEventId'), 10), relationId);
        if (data !== null && !data.hasOwnProperty("error")) {
            this.relationRecord = data;
        } else {
            this.onLoadError();  // callback
        }
    }

    loadDataSourceColor = async (relationId) => {
        var data = await ServiceDrivers.getDriverColorByRelationId(relationId);
        if (data !== null && !data.hasOwnProperty("error")) {
            this.driverRecord = data;
        } else {
            this.onLoadError();  // callback
        }
    }

    loadDataSourceBackGroundPatterns = async () => {
        var data = await ServiceColors.getAllBackGroundPatterns();
        if (data !== null && !data.hasOwnProperty("error")) {
            var rec = {
                Id: -1,
                Name: 'None',
                CssBackGround: '',
                CssBackGroundImage: '',
                CssBackGroundSize: '',
                CssBackGroundPosition: ''
            }
            data.unshift(rec);
            this.backgroundPatterns = data;
        } else {
            this.onLoadError();  // callback
        }
    }

    loadDataSourceDriverAvailability = async (driverId) => {
        var data = await ServiceDrivers.getDriverAvailabilityByDriverId(driverId);
        if (data !== null && !data.hasOwnProperty("error")) {
            this.driverAvailabilitiesDS = data;
        } else {
            this.onLoadError();  // callback
        }
    }

    loadDataSourceEvent = async (eventId) => {
        var data = await ServiceEvents.getEventByEventId(eventId);
        if (data !== null && !data.hasOwnProperty("error")) {
            this.eventRecord = data;
        } else {
            this.onLoadError();  // callback
        }
    }

    loadDataSourceEventDates = async (eventId) => {
        var data = await ServiceEvents.getEventDatesByEventId(eventId);
        if (data !== null && !data.hasOwnProperty("error")) {
            this.eventDates = data;
        } else {
            this.onLoadError();  // callback
        }
    }

    loadDataSourceRelationTypes = async () => {
        var data = await ServiceRelations.getRelationTypes();
        if (data !== null && !data.hasOwnProperty("error")) {
            this.relationTypesDataSource = data;
        } else {
            this.onLoadError();  // callback
        }
    }

    // POPUP
    popup_OnInitialized = (e) => {
        this.popupInstance = e.component;

        // set callback
        this.onInitialized(e.component);
    }

    popup_OnShowing = (e) => {
        if (this.props.id !== undefined) {
            this.relationId = this.props.id;
        }
        this.relationRecord = this.relationDefaultRecord;
        this.driverRecord = this.driverDefaultRecord;


        //this.popupInstance.option('title', (this.props.title ? this.props.title : ''))
        //this.textBoxNameInstance.focus();

       // document.getElementById('textBoxFirstNameInput').select();

    }

    popup_OnHiding = (e) => {
        this.textBoxFirstNameInstance.option('value', null);
        this.textBoxLastNameInstance.option('value', null);
        this.textBoxEmail1Instance.option('value', null);
        this.textBoxTel1Instance.option('value', null);
        this.htmlEditorNotesInstance.option('value', null);

        this.textBoxShortNameInstance.option('value', '');
        this.colorBoxColorHexInstance.option('value', 'rgba(255, 255, 255, 1)');
        this.colorBoxForeColorHexInstance.option('value', 'rgba(0, 0, 0, 1)');
        this.selectBoxPatternsInstance.option('value', -1);
        this.colorBoxPatternColorHexInstance.option('value', 'rgba(0, 0, 0, 1)');

        this.validationGroupInstance.reset();

        this.onHiding();  // callback

    }

    popup_OnShown = async (e) => {
        var type = this.relationTypesDataSource.find(o => o.Name.toString().toUpperCase() === 'DRIVER');
        this.relationDefaultRecord.RelationTypeId = (type != null ? type.Id : -1);
        this.relationDefaultRecord.RelationType = (type != null ? type.Name : '');
       
        if (this.relationId == -1) {
            // new relation
            this.textBoxFirstNameInstance.option('value', null);
            this.textBoxLastNameInstance.option('value', null);
            this.textBoxEmail1Instance.option('value', null);
            this.textBoxTel1Instance.option('value', null);
            this.htmlEditorNotesInstance.option('value', null);

            this.textBoxShortNameInstance.option('value', '');
            this.colorBoxColorHexInstance.option('value', 'rgba(255, 255, 255, 1)');
            this.colorBoxForeColorHexInstance.option('value', 'rgba(0, 0, 0, 1)');
            this.selectBoxPatternsInstance.option('value', -1);
            this.colorBoxPatternColorHexInstance.option('value', 'rgba(0, 0, 0, 1)');

            this.checkBoxKeepOpenInstance.option('visible', true);

            var ds = [];
            for (var i = 0; i < this.eventDates.length; i++) {
                var rec = {
                    Id: -1,
                    DriverId: -1,
                    Available: true,
                    AvailableDate: this.eventDates[i],
                    AllDay: true,
                    AvailableFrom: null,
                    AvailableTo: null,
                    AvailableFrom2: null,
                    AvailableTo2: null,
                    Reserve: false
                }
                ds.push(rec);
            }

            this.setState({ driverAvailabilityDataSource: ds });

            this.validationGroupInstance.reset();

            this.popupInstance.option('title', 'Add new driver');
        } else {
            // edit relation
            await this.loadDataSourceRelation(this.relationId);
            await this.loadDataSourceColor(this.relationId);
            await this.loadDataSourceDriverAvailability(this.driverRecord.Id);

            var ds = [];
            for (var i = 0; i < this.eventDates.length; i++) {
                var rec = {
                    Id: -1,
                    DriverId: -1,
                    Available: false,
                    AvailableDate: this.eventDates[i],
                    AllDay: true,
                    AvailableFrom: null,
                    AvailableTo: null,
                    AvailableFrom2: null,
                    AvailableTo2: null,
                    Reserve: false,
                }
                if (this.driverAvailabilitiesDS.length > 0) {
                    let recFound = this.driverAvailabilitiesDS.find(d => d.AvailableDate === this.eventDates[i]);
                    if (recFound !== undefined) {
                        rec.Id = recFound.Id;
                        rec.DriverId = recFound.DriverId;
                        rec.Available = true;
                        rec.AvailableDate = recFound.AvailableDate;
                        rec.AllDay = (recFound.AvailableFrom == null && recFound.AvailableTo == null && recFound.AvailableFrom2 == null && recFound.AvailableTo2 == null);
                        rec.AvailableFrom = recFound.AvailableFrom;
                        rec.AvailableTo = recFound.AvailableTo;
                        rec.AvailableFrom2 = recFound.AvailableFrom2;
                        rec.AvailableTo2 = recFound.AvailableTo2;
                        rec.Reserve = recFound.Reserve;
                    }
                }
                ds.push(rec);
            }
            this.setState({ driverAvailabilityDataSource: ds });

            this.textBoxFirstNameInstance.option('value', this.relationRecord.FirstName);
            this.textBoxLastNameInstance.option('value', this.relationRecord.LastName);
            this.textBoxEmail1Instance.option('value', this.relationRecord.Email1);
            this.textBoxTel1Instance.option('value', this.relationRecord.Tel1);
            this.htmlEditorNotesInstance.option('value', this.relationRecord.Notes);

            this.textBoxShortNameInstance.option('value', this.driverRecord.ShortName);
            this.colorBoxColorHexInstance.option('value', this.driverRecord.ColorRgba);
            this.colorBoxForeColorHexInstance.option('value', this.driverRecord.ForeColorRgba);
            this.selectBoxPatternsInstance.option('value', this.driverRecord.PatternId !== null ? this.driverRecord.PatternId : -1);
            this.colorBoxPatternColorHexInstance.option('value', this.driverRecord.PatternColorRgba);

            this.checkBoxKeepOpenInstance.option('visible', false);

            this.popupInstance.option('title', 'Update ' + this.relationRecord.FirstName + ' ' + this.relationRecord.LastName);
        }

        this.checkBoxKeepOpenInstance.option('value', false);
        this.buttonUpdateInstance.option('text', 'Save');
        this.buttonUpdateInstance.option('width', '120px');

        this.textBoxFirstNameInstance.focus();
    }

    
    // ALL TEXTBOXES FOCUS IN
    textBox_OnFocusIn = (e) => {
        // select all text
        e.element.querySelector('.dx-texteditor-input').select();
    }

    // TEXTBOX FIRST NAME
    textBoxFirstNameAttributes = {
        id: 'textBoxFirstNameInput',
    }

    textBoxFirstName_OnInitialized = (e) => {
        this.textBoxFirstNameInstance = e.component;
    }

    // TEXTBOX LAST NAME
    textBoxLastName_OnInitialized = (e) => {
        this.textBoxLastNameInstance = e.component;
    }

    // TEXTBOX EMAIL 1
    textBoxEmail1_OnInitialized = (e) => {
        this.textBoxEmail1Instance = e.component;
    }

    // TEXTBOX TEL 1
    textBoxTel1_OnInitialized = (e) => {
        this.textBoxTel1Instance = e.component;
    }

    // HTML EDITOR
    htmlEditorNotes_OnInitialized = (e) => {
        this.htmlEditorNotesInstance = e.component;
    }
    // TEXTBOX SHORT NAME
    textBoxShortName_OnInitialized = (e) => {
        this.textBoxShortNameInstance = e.component;
    }

    textBoxShortName_OnValueChanged = (e) => {
        this.setState({ previewText: e.value });
    }

    textBoxShortName_OnFocusOut = (e) => {
        this.textBoxShortNameInstance.option('value', this.textBoxShortNameInstance.option('value').toUpperCase());
    }

    buttonAutoShortName_OnClick = async (e) => {

        let shortName = '';

        if (this.textBoxFirstNameInstance.option('value') != null && this.textBoxFirstNameInstance.option('value').trim().length !== 0) {
            shortName = this.textBoxFirstNameInstance.option('value').slice(0, 1).toUpperCase();
        }

        if (this.textBoxLastNameInstance.option('value') != null && this.textBoxLastNameInstance.option('value').trim().length !== 0) {
            shortName += this.textBoxLastNameInstance.option('value').slice(0, 1).toUpperCase();
            if (this.textBoxLastNameInstance.option('value').trim().length > 1) {
                shortName += this.textBoxLastNameInstance.option('value').slice(1, 2).toUpperCase();
            }
        }

        if (shortName.trim().length !== 0) {
            let i = 0;
            let exists = true;
            let err = false;
            let newShortName = shortName;

            while (i < 100 && exists == true) {
                newShortName = shortName + (i > 0 ? i : '');
                var result = await ServiceDrivers.doesShortNameExist(this.driverRecord.EventId, newShortName, this.driverRecord.Id);
                if (result !== null && !result.hasOwnProperty("error")) {
                    exists = result;
                } else {
                    err = true;
                    break;
                }
                i++;
            }

            if (err == false && exists == false) {
                this.textBoxShortNameInstance.option('value', newShortName);
            }
        }
    }

    // COLORBOX COLOR
    colorBoxColorHex_OnInitialized = (e) => {
        this.colorBoxColorHexInstance = e.component;
    }

    colorBoxColorHex_OnValueChanged = (e) => {
        this.setPreviewStyle();
  }

    // COLORBOX FORECOLOR
    colorBoxForeColorHex_OnInitialized = (e) => {
        this.colorBoxForeColorHexInstance = e.component;
    }

    colorBoxForeColorHex_OnValueChanged = (e) => {
        this.setPreviewStyle();
    }

    // COLORBOX FORECOLOR
    colorBoxPatternColorHex_OnInitialized = (e) => {
        this.colorBoxPatternColorHexInstance = e.component;
    }

    colorBoxPatternColorHex_OnValueChanged = (e) => {
        this.setPreviewStyle();
    }

    // SELECTBOX PATTERNS
    selectBoxPatterns_OnInitialized = (e) => {
        this.selectBoxPatternsInstance = e.component;
    }

    selectBoxPatterns_OnValueChanged = (e) => {
        this.setPreviewStyle();
    }


    // DATAGRID
    dataGrid_OnInitialized = (e) => {
        this.dataGridInstance = e.component;
    }

    dataGrid_OnCellClick = (e) => {
        if (e.column.dataField == 'AllDay') {
            if (e.value) {
                this.dataGridInstance.cellValue(e.rowIndex, 'AvailableFrom', null);
                this.dataGridInstance.cellValue(e.rowIndex, 'AvailableTo', null);
                this.dataGridInstance.cellValue(e.rowIndex, 'AvailableFrom2', null);
                this.dataGridInstance.cellValue(e.rowIndex, 'AvailableTo2', null);
            } else {
                var dt = this.dataGridInstance.cellValue(e.rowIndex, 'AvailableDate');
                var dtTo = this.dataGridInstance.cellValue(e.rowIndex, 'AvailableDate');
                dt.setHours(0, 0, 0, 0);
                dtTo.setHours(23, 59, 0, 0);
                this.dataGridInstance.cellValue(e.rowIndex, 'AvailableFrom', dt);
                this.dataGridInstance.cellValue(e.rowIndex, 'AvailableTo', dtTo);
            }
        }
    }

    dataGrid_OnCellPrepared = (e) => {
        if (e.rowType === 'data') {
            if (this.dataGridInstance.cellValue(e.rowIndex, 'Available') == true) {
                e.cellElement.style.backgroundColor = '#FFFFFF';
            } else {
                e.cellElement.style.backgroundColor = '#DDDDDD';
            }
        }
    }

    dataGrid_OnEditorPreparing = (e) => {
        if (this.dataGridInstance.cellValue(e.row.rowIndex, 'Available') == false) {
            if (e.dataField !== 'Available') {
                e.editorOptions.disabled = true;
            }
        } else {
            if (this.dataGridInstance.cellValue(e.row.rowIndex, 'AllDay') == true) {
                if (e.dataField == 'AvailableFrom' || e.dataField == 'AvailableTo' || e.dataField == 'AvailableFrom2' || e.dataField == 'AvailableTo2') {
                    e.editorOptions.disabled = true;
                }
            } else {
                if (this.dataGridInstance.cellValue(e.row.rowIndex, 'AvailableFrom') === null || this.dataGridInstance.cellValue(e.row.rowIndex, 'AvailableTo') === null) {
                    if (e.dataField == 'AvailableFrom2' || e.dataField == 'AvailableTo2') {
                        e.editorOptions.disabled = true;
                    }
                }
            }
        }
    }

    setPreviewStyle = () => {

        var bg = '#FFFFFF';
        var fg = '#000000'

        if (this.colorBoxColorHexInstance.option('value') != null && this.colorBoxColorHexInstance.option('value') != '') {
            bg = this.colorBoxColorHexInstance.option('value');
        }
        if (this.colorBoxForeColorHexInstance.option('value') != null && this.colorBoxForeColorHexInstance.option('value') != '') {
            fg = this.colorBoxForeColorHexInstance.option('value');
        }
        var html = '.hx-preview-style {background-color: ' + bg + '  !important; color: ' + fg + '; ';

        if (this.selectBoxPatternsInstance.option('value') != -1) {
            // pattern
            if (this.colorBoxPatternColorHexInstance.option('value') != null && this.colorBoxPatternColorHexInstance.option('value') != '') {
                // use pattern
                var patternColor = this.colorBoxPatternColorHexInstance.option('value');
                var patternId = this.selectBoxPatternsInstance.option('value');
                var pattern = this.backgroundPatterns.find(x => x.Id === patternId);
                const searchRegExp = /\[rgba\]/gi;

                if (pattern.CssBackGround != null) {
                    html += 'background: ' + pattern.CssBackGround.replace(searchRegExp, patternColor) + '; ';
                }
                if (pattern.CssBackGroundImage != null) {
                    html += 'background-image: ' + pattern.CssBackGroundImage.replace(searchRegExp, patternColor) + '; ';
                }
                if (pattern.CssBackGroundSize != null) {
                    html += 'background-size: ' + pattern.CssBackGroundSize + '; ';
                }
                if (pattern.CssBackGroundPosition != null) {
                    html += 'background-position: ' + pattern.CssBackGroundPosition + '; ';
                }
            }
        }

        html += '}';

        let sheet = document.createElement('style');
        sheet.id = 'style-preview';
        if (sheet.innerHTML.indexOf('hx-preview-style') == -1) {
            sheet.innerHTML = html;
        }

        var child = document.getElementById('style-preview');
        if (child) {
            document.body.removeChild(child);
        }
        document.body.appendChild(sheet);
    };

    // CHECKBOX KEEP OPEN
    checkBoxKeepOpen_OnInitialized = (e) => {
        this.checkBoxKeepOpenInstance = e.component;
    }

    checkBoxKeepOpen_OnValueChanged = (e) => {
        this.buttonUpdateInstance.option('text', (e.value === true ? 'Save & new' : 'Save'));
        this.buttonUpdateInstance.option('width', (e.value === true ? '140px' : '120px'));
    }

    // BUTTON
    buttonUpdate_OnInitialized = (e) => {
        this.buttonUpdateInstance = e.component;
    }

    buttonUpdate_OnClick = async (e) => {
        await this.updateDriver();
    }

    updateDriver = async () => {
        this.validationGroupInstance.validate();
        var valid = this.validateDriverAvailabilities();

        if (this.textBoxFirstNameInstance.option('isValid') === true
            && this.textBoxLastNameInstance.option('isValid') === true
            && this.textBoxEmail1Instance.option('isValid') === true
            && this.textBoxShortNameInstance.option('isValid') === true
            && valid === true) {

            this.relationRecord.FirstName = (this.textBoxFirstNameInstance.option('value') !== null ? this.textBoxFirstNameInstance.option('value').trim() : null);
            this.relationRecord.LastName = (this.textBoxLastNameInstance.option('value') !== null ? this.textBoxLastNameInstance.option('value').trim() : null);
            this.relationRecord.Email1 = (this.textBoxEmail1Instance.option('value') !== null ? this.textBoxEmail1Instance.option('value').trim() : null);
            this.relationRecord.Tel1 = (this.textBoxTel1Instance.option('value') !== null ? this.textBoxTel1Instance.option('value').trim() : null);
            this.relationRecord.Notes = this.htmlEditorNotesInstance.option('value');

            this.driverRecord.ShortName = this.textBoxShortNameInstance.option('value').trim();
            this.driverRecord.ColorHex = this.colorBoxColorHexInstance.option('value');
            this.driverRecord.ColorRgba = this.colorBoxColorHexInstance.option('value');
            this.driverRecord.ForeColorHex = this.colorBoxForeColorHexInstance.option('value');
            this.driverRecord.ForeColorRgba = this.colorBoxForeColorHexInstance.option('value');
            this.driverRecord.UsePattern = (this.selectBoxPatternsInstance.option('value') !== -1);
            this.driverRecord.PatternColorHex = this.colorBoxPatternColorHexInstance.option('value');
            this.driverRecord.PatternColorRgba = this.colorBoxPatternColorHexInstance.option('value');
            this.driverRecord.PatternId = (this.selectBoxPatternsInstance.option('value') !== -1 ? this.selectBoxPatternsInstance.option('value') : null);

            var rows = this.dataGridInstance.getVisibleRows();

            var dsDriverAvailabilities = [];
            for (var i = 0; i < rows.length; i++) {
                if (rows[i].data.Available === true) {
                    if (rows[i].data.AllDay === true) {
                        dsDriverAvailabilities.push({
                            Id: rows[i].data.Id,
                            DriverId: rows[i].data.DriverId,
                            AvailableDate: rows[i].data.AvailableDate,
                            AvailableFrom: null,
                            AvailableTo: null,
                            AvailableFrom2: null,
                            AvailableTo2: null,
                            AvailableFrom3: null,
                            AvailableTo3: null,
                            Reserve: rows[i].data.Reserve
                        })
                    } else {
                        dsDriverAvailabilities.push({
                            Id: rows[i].data.Id,
                            DriverId: rows[i].data.DriverId,
                            AvailableDate: rows[i].data.AvailableDate,
                            AvailableFrom: this.dataGridInstance.cellValue(rows[i].rowIndex, 'AvailableFrom'),
                            AvailableTo: this.dataGridInstance.cellValue(rows[i].rowIndex, 'AvailableTo'),
                            AvailableFrom2: this.dataGridInstance.cellValue(rows[i].rowIndex, 'AvailableFrom2'),
                            AvailableTo2: this.dataGridInstance.cellValue(rows[i].rowIndex, 'AvailableTo2'),
                            AvailableFrom3: this.dataGridInstance.cellValue(rows[i].rowIndex, 'AvailableFrom3'),
                            AvailableTo3: this.dataGridInstance.cellValue(rows[i].rowIndex, 'AvailableTo3'),
                            Reserve: rows[i].data.Reserve
                        })
                    }
                }
            }

            if (this.relationId == -1) {
                // new driver
                var resultRelation = await ServiceRelations.addRelation(this.relationRecord);
                if (resultRelation !== null && !resultRelation.hasOwnProperty("error")) {
                    this.driverRecord.RelationId = resultRelation;
                    var colorId = await ServiceDrivers.addDriverColors(this.driverRecord);
                    if (colorId !== null && !colorId.hasOwnProperty("error")) {
                        this.driverRecord.ColorId = colorId;
                        var driverId = await ServiceDrivers.addDriver(this.driverRecord);
                        if (driverId != null && !driverId.hasOwnProperty("error")) {
                            var resultAvail = await ServiceDrivers.addDriverAvailabilities({ Id: driverId, DriverAvailabilities: dsDriverAvailabilities });
                            if (resultAvail !== null && !resultAvail.hasOwnProperty("error") && resultAvail == true) {
                                this.onUpdate(true); // callback
                                if (this.checkBoxKeepOpenInstance.option('value') === false) {
                                    this.popupInstance.hide();
                                } else {
                                    await this.loadDataSourceBackGroundPatterns();
                                    await this.loadDataSourceEvent(parseInt(sessionStorage.getItem('currentEventId'), 10));
                                    await this.loadDataSourceEventDates(parseInt(sessionStorage.getItem('currentEventId'), 10));
                                    this.textBoxFirstNameInstance.option('value', '');
                                    this.textBoxLastNameInstance.option('value', '');
                                    this.textBoxEmail1Instance.option('value', null);
                                    this.textBoxTel1Instance.option('value', null);
                                    this.htmlEditorNotesInstance.option('value', null);

                                    this.textBoxShortNameInstance.option('value', '');
                                    this.colorBoxColorHexInstance.option('value', 'rgba(255, 255, 255, 1)');
                                    this.colorBoxForeColorHexInstance.option('value', 'rgba(0, 0, 0, 1)');
                                    this.selectBoxPatternsInstance.option('value', -1);
                                    this.colorBoxPatternColorHexInstance.option('value', 'rgba(0, 0, 0, 1)');

                                    var ds = [];
                                    for (var i = 0; i < this.eventDates.length; i++) {
                                        var rec = {
                                            Id: -1,
                                            DriverId: -1,
                                            Available: true,
                                            AvailableDate: this.eventDates[i],
                                            AllDay: true,
                                            AvailableFrom: null,
                                            AvailableTo: null,
                                            AvailableFrom2: null,
                                            AvailableTo2: null,
                                            Reserve: false
                                        }
                                        ds.push(rec);
                                    }

                                    this.setState({ driverAvailabilityDataSource: ds });

                                    this.validationGroupInstance.reset();

                                    this.popupInstance.option('title', 'Add new driver');
                                }
                            }
                        } else {
                            this.onUpdate(false); // callback
                        }
                    } else {
                        this.onUpdate(false); // callback
                    }
                } else {
                    this.onUpdate(false); // callback
                }
            } else {
                // edit driver
                var data = await ServiceDrivers.updateDriverAvailability({ Id: this.driverRecord.Id, DriverAvailabilities: dsDriverAvailabilities });
                if (data !== null && !data.hasOwnProperty("error") && data == true) {
                    var colorId = await ServiceDrivers.updateDriverColors(this.driverRecord);
                    if (colorId != null && !colorId.hasOwnProperty("error")) {
                        this.driverRecord.ColorId = colorId;
                        var dataDriver = await ServiceDrivers.updateDriver(this.driverRecord);
                        if (dataDriver != null && !dataDriver.hasOwnProperty("error") && dataDriver == true) {
                            var dataRelation = await ServiceRelations.updateRelation(this.relationRecord);
                            if (dataRelation != null && !dataRelation.hasOwnProperty("error") && dataRelation == true) {
                                this.onUpdate(true); // callback
                                if (this.checkBoxKeepOpenInstance.option('value') === false) {
                                    this.popupInstance.hide();
                                } else {
                                    await this.loadDataSourceBackGroundPatterns();
                                    await this.loadDataSourceEvent(parseInt(sessionStorage.getItem('currentEventId'), 10));
                                    await this.loadDataSourceEventDates(parseInt(sessionStorage.getItem('currentEventId'), 10));
                                    this.textBoxFirstNameInstance.option('value', '');
                                    this.textBoxLastNameInstance.option('value', '');
                                    this.textBoxEmail1Instance.option('value', null);
                                    this.textBoxTel1Instance.option('value', null);
                                    this.htmlEditorNotesInstance.option('value', null);

                                    this.textBoxShortNameInstance.option('value', '');
                                    this.colorBoxColorHexInstance.option('value', 'rgba(255, 255, 255, 1)');
                                    this.colorBoxForeColorHexInstance.option('value', 'rgba(0, 0, 0, 1)');
                                    this.selectBoxPatternsInstance.option('value', -1);
                                    this.colorBoxPatternColorHexInstance.option('value', 'rgba(0, 0, 0, 1)');

                                    var ds = [];
                                    for (var i = 0; i < this.eventDates.length; i++) {
                                        var rec = {
                                            Id: -1,
                                            DriverId: -1,
                                            Available: true,
                                            AvailableDate: this.eventDates[i],
                                            AllDay: true,
                                            AvailableFrom: null,
                                            AvailableTo: null,
                                            AvailableFrom2: null,
                                            AvailableTo2: null,
                                            Reserve: false
                                        }
                                        ds.push(rec);
                                    }

                                    this.setState({ driverAvailabilityDataSource: ds });

                                    this.validationGroupInstance.reset();

                                    this.popupInstance.option('title', 'Add new driver');
                                }
                            }
                        } else {
                            this.onUpdate(false); // callback
                        }
                    } else {
                        this.onUpdate(false); // callback
                    }
                } else {
                    this.onUpdate(false); // callback
                }
            }
        } else {
            this.textBoxFirstNameInstance.focus();
        }
    }

    // VALIDATION GROUP
    validationGroup_OnInitialized = (e) => {
        this.validationGroupInstance = e.component;
    }

    validateShortName = async (e) => {
        var result = await ServiceDrivers.doesShortNameExist(this.driverRecord.EventId, e.value, this.driverRecord.Id);
        if (result !== null && !result.hasOwnProperty("error")) {
            return !result;
        } else {
            this.onLoadError();  // callback
            return false;
        }
    }

    validateDriverAvailabilities = () => {
      
        var result1 = true;
        var result2 = true;
        var rows = this.dataGridInstance.getVisibleRows();

        for (var i = 0; i < rows.length; i++) {
            if (rows[i].data.Available === true &&rows[i].data.AllDay !== true) {
                if (rows[i].data.AvailableFrom === null) {
                    result1 = false;
                    this.dataGridInstance.getCellElement(rows[i].rowIndex, 'AvailableFrom').classList.add('psared-row-error');
                }
                if (rows[i].data.AvailableTo === null) {
                    result1 = false;
                    this.dataGridInstance.getCellElement(rows[i].rowIndex, 'AvailableTo').classList.add('psared-row-error');
                }

                if ((rows[i].data.AvailableFrom !== null && rows[i].data.AvailableTo !== null) && ((rows[i].data.AvailableFrom2 !== null && rows[i].data.AvailableTo2 === null) || (rows[i].data.AvailableFrom2 === null && rows[i].data.AvailableTo2 !== null))) {
                    if (rows[i].data.AvailableFrom2 === null) {
                        result2 = false;
                        this.dataGridInstance.getCellElement(rows[i].rowIndex, 'AvailableFrom2').classList.add('psared-row-error');
                    }
                    if (rows[i].data.AvailableTo2 === null) {
                        result2 = false;
                        this.dataGridInstance.getCellElement(rows[i].rowIndex, 'AvailableTo2').classList.add('psared-row-error');
                    }
                }

                if (result1 === true) {
                    if (rows[i].data.AvailableFrom > rows[i].data.AvailableTo) {
                        result1 = false;
                        this.dataGridInstance.getCellElement(rows[i].rowIndex, 'AvailableFrom').classList.add('psared-row-error');
                        this.dataGridInstance.getCellElement(rows[i].rowIndex, 'AvailableTo').classList.add('psared-row-error');
                    }
                }

                if (result2 === true) {
                    if (rows[i].data.AvailableFrom2 > rows[i].data.AvailableTo2) {
                        result2 = false;
                        this.dataGridInstance.getCellElement(rows[i].rowIndex, 'AvailableFrom2').classList.add('psared-row-error');
                        this.dataGridInstance.getCellElement(rows[i].rowIndex, 'AvailableTo2').classList.add('psared-row-error');
                    }
                }   
            }
        }

        return (result1 === true && result2 === true);
    }

    // RENDERING
    renderItem = (e) => {
        var style = {};
        const searchRegExp = /\[rgba\]/gi;

        style.width = '40px';
        style.height = '30px';
        style.backgroundColor = '#FFFFFF';
        style.color = '#000000';
        style.marginRight = '10px';
        if (e.CssBackGround != null) {
            style.background = e.CssBackGround.replace(searchRegExp, '#000000');
        }
        if (e.CssBackGroundImage != null) {
            style.backgroundImage = e.CssBackGroundImage.replace(searchRegExp, '#000000');
        }
        if (e.CssBackGroundSize != null) {
            style.backgroundSize = e.CssBackGroundSize;
        }
        if (e.CssBackGroundPosition != null) {
            style.backgroundPosition = e.CssBackGroundPosition;
        }

        return <div style={{ display: 'flex', justifyContent: 'start' }}>
            <div style={style}></div>
            <div style={{ paddingTop: '4px' }}>{e.Name}</div>
        </div>;
    }
 
    render() {
        return (
            <React.Fragment>
                <Popup
                    dragEnabled={false}
                    hideOnOutsideClick={false}
                    showTitle={true}
                    width={1000}
                    height={683}
                    showCloseButton={true}
                    onInitialized={this.popup_OnInitialized}
                    onShown={this.popup_OnShown}
                    onShowing={this.popup_OnShowing}
                    onHiding={this.popup_OnHiding}
                >
                    <ValidationGroup
                        onInitialized={this.validationGroup_OnInitialized}
                    >
                        <div>
                            <div className="psared-header-div">Info</div>
                            <div className="dx-card psared-top-card">
                                <div className="psared-top-div">
                                    <div className="psared-top-left-div">
                                        <img
                                            src={process.env.PUBLIC_URL + globalConfig.settings.images.driverImagesPath.replace(/^\+$|"\/+$/g, '') + "/default.png"}
                                            id='imageBox'
                                            alt={''}
                                            style={{ height: '150px', width: '150px' }}
                                        />
                                    </div>
                                    <div className="psared-top-middle-div">
                                        <div className="psared-dx-field">
                                            <div className="dx-field-label psared-top-label">First Name*</div>
                                            <div className="dx-field-value psared-top-input">
                                                <TextBox
                                                    inputAttr={this.textBoxFirstNameAttributes}
                                                    width='250px'
                                                    onInitialized={this.textBoxFirstName_OnInitialized}
                                                >
                                                    <Validator>
                                                        <ValidationRule type="required" message="Required" />
                                                    </Validator>
                                                </TextBox>
                                            </div>
                                        </div>
                                        <div className="psared-dx-field">
                                            <div className="dx-field-label psared-top-label">Last Name*</div>
                                            <div className="dx-field-value psared-top-input">
                                                <TextBox
                                                    width='250px'
                                                    onFocusIn={this.textBox_OnFocusIn}
                                                    onInitialized={this.textBoxLastName_OnInitialized}
                                                >
                                                    <Validator>
                                                        <ValidationRule type="required" message="Required" />
                                                    </Validator>
                                                </TextBox>
                                            </div>
                                        </div>
                                        <div className="psared-dx-field">
                                            <div className="dx-field-label psared-top-label">Email</div>
                                            <div className="dx-field-value psared-top-input">
                                                <TextBox
                                                    width='250px'
                                                    onFocusIn={this.textBox_OnFocusIn}
                                                    onInitialized={this.textBoxEmail1_OnInitialized}
                                                >
                                                    <Validator>
                                                        <EmailRule
                                                            message="Invalid email" />
                                                    </Validator>
                                                </TextBox>
                                            </div>
                                        </div>
                                        <div className="psared-dx-field">
                                            <div className="dx-field-label psared-top-label">Tel</div>
                                            <div className="dx-field-value psared-top-input">
                                                <TextBox
                                                    width='250px'
                                                    onFocusIn={this.textBox_OnFocusIn}
                                                    onInitialized={this.textBoxTel1_OnInitialized}
                                                >
                                                </TextBox>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="psared-top-right-div">
                                        <div className="psared-dx-field-notes">
                                            <div className="dx-field-value psared-top-input-notes">
                                                <HtmlEditor
                                                    width='100%'
                                                    height='158px'
                                                    onInitialized={this.htmlEditorNotes_OnInitialized}
                                                >
                                                </HtmlEditor>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="psared-second-div">
                                <div className="psared-second-left-div">
                                    <div className="psared-header-div">Display</div>
                                    <div className="dx-card psared-second-left-card">
                                        <div className="psared-dx-field">
                                            <div className='psared-second-preview hx-preview-style'>{this.state.previewText}</div>
                                        </div>
                                        <div className="psared-dx-field">
                                            <div className="dx-field-label psared-second-label">Initials*</div>
                                            <div className="dx-field-value psared-second-input">
                                                <TextBox
                                                    width='100px'
                                                    maxLength={5}
                                                    onFocusIn={this.textBox_OnFocusIn}
                                                    onInitialized={this.textBoxShortName_OnInitialized}
                                                    onValueChanged={this.textBoxShortName_OnValueChanged}
                                                    onFocusOut={this.textBoxShortName_OnFocusOut}
                                                >
                                                    <Validator>
                                                        <AsyncRule validationCallback={this.validateShortName} message="Initials exist" />
                                                        <ValidationRule type="required" message="Required" />
                                                    </Validator>
                                                </TextBox>
                                                <div className='psared-second-auto-button'><HxButton text="Auto" width="50px" height="30px" onClick={this.buttonAutoShortName_OnClick} /></div>
                                            </div>
                                        </div>
                                        <div className="psared-dx-field">
                                            <div className="dx-field-label psared-second-label">Background</div>
                                            <div className="dx-field-value psared-second-input">
                                                <ColorBox
                                                    width='240px'
                                                    onInitialized={this.colorBoxColorHex_OnInitialized}
                                                    onValueChanged={this.colorBoxColorHex_OnValueChanged}
                                                    editAlphaChannel={true}
                                                >
                                                </ColorBox>
                                            </div>
                                        </div>
                                        <div className="psared-dx-field">
                                            <div className="dx-field-label psared-second-label">Text</div>
                                            <div className="dx-field-value psared-second-input">
                                                <ColorBox
                                                    width='240px'
                                                    onInitialized={this.colorBoxForeColorHex_OnInitialized}
                                                    onValueChanged={this.colorBoxForeColorHex_OnValueChanged}
                                                    editAlphaChannel={true}
                                                >
                                                </ColorBox>
                                            </div>
                                        </div>
                                        <div className="psared-dx-field">
                                            <div className="dx-field-label psared-second-label">Pattern</div>
                                            <div className="dx-field-value psared-second-input">
                                                <SelectBox
                                                    dataSource={this.backgroundPatterns}
                                                    displayExpr="Name"
                                                    itemRender={this.renderItem}
                                                    valueExpr="Id"
                                                    width='240px'
                                                    onInitialized={this.selectBoxPatterns_OnInitialized}
                                                    onValueChanged={this.selectBoxPatterns_OnValueChanged}
                                                />
                                            </div>
                                        </div>
                                        <div className="psared-dx-field">
                                            <div className="dx-field-label psared-second-label">Pattern color</div>
                                            <div className="dx-field-value psared-second-input">
                                                <ColorBox
                                                    width='240px'
                                                    onInitialized={this.colorBoxPatternColorHex_OnInitialized}
                                                    onValueChanged={this.colorBoxPatternColorHex_OnValueChanged}
                                                    editAlphaChannel={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="psared-second-right-div">
                                    <div className="psared-header-div">Availability</div>
                                    <div className="dx-card psared-second-right-card">
                                        <DataGrid
                                            dataSource={this.state.driverAvailabilityDataSource}
                                            key='Id'
                                            height='100%'
                                            allowColumnReordering={false}
                                            allowColumnResizing={true}
                                            allowGrouping={false}
                                            columnAutoWidth={false}
                                            columnResizingMode='widget'
                                            noDataText='No availabilities found.'
                                            remoteOperations={false}
                                            repaintChangesOnly={true}
                                            showBorders={true}
                                            showColumnLines={true}
                                            showRowLines={true}
                                            onInitialized={this.dataGrid_OnInitialized}
                                            onCellClick={this.dataGrid_OnCellClick}
                                            onCellPrepared={this.dataGrid_OnCellPrepared}
                                            onEditorPreparing={this.dataGrid_OnEditorPreparing}
                                        >
                                            <Paging enabled={false} />
                                            <Editing
                                                mode="cell"
                                                allowUpdating={true}
                                                allowAdding={false}
                                                allowDeleting={false} />
                                            <Column dataField="Id" dataType="number" visible={false} />
                                            <Column dataField="DriverId" dataType="number" visible={false} />
                                            <Column allowSorting={false} allowResizing={false} dataField="Available" dataType="boolean" caption='' width={40} />
                                            <Column allowSorting={false} allowResizing={false} allowEditing={false} dataField="AvailableDate" dataType="date" caption='Date' format="dd/MM/yyyy" width={85}>
                                                <ValidationRule type="required" message="Required" />
                                            </Column>
                                            <Column allowSorting={false} allowResizing={false} dataField="AllDay" dataType="boolean" width={60} />
                                            <Column allowSorting={false} allowResizing={false} dataField="AvailableFrom" dataType="datetime" format="HH:mm" caption='From' editorOptions={{ type: 'time', displayFormat: 'HH:mm', pickerType: 'native' }} width={77} />
                                            <Column allowSorting={false} allowResizing={false} dataField="AvailableTo" dataType="datetime" format="HH:mm" caption='To' editorOptions={{ type: 'time', displayFormat: 'HH:mm', pickerType: 'native' }} width={77} />
                                            <Column allowSorting={false} allowResizing={false} dataField="AvailableFrom2" dataType="datetime" format="HH:mm" caption='From' editorOptions={{ type: 'time', displayFormat: 'HH:mm', pickerType: 'native' }} width={77} />
                                            <Column allowSorting={false} allowResizing={false} dataField="AvailableTo2" dataType="datetime" format="HH:mm" caption='To' editorOptions={{ type: 'time', displayFormat: 'HH:mm', pickerType: 'native' }} width={77} />
                                            <Column allowSorting={false} allowResizing={false} dataField="Reserve" dataType="boolean" />
                                        </DataGrid>
                                    </div>
                                </div>
                             </div>
                        </div>
                    </ValidationGroup>

                    <div className="psared-buttons">
                        <div className="psared-buttons-checkbox">
                            <CheckBox
                                text="Keep open"
                                onInitialized={this.checkBoxKeepOpen_OnInitialized}
                                onValueChanged={this.checkBoxKeepOpen_OnValueChanged}
                            />
                        </div>
                        <div className='psared-buttons-update'>
                            <div className="psared-button-update">
                                <Button
                                    onInitialized={this.buttonUpdate_OnInitialized}
                                    icon="save"
                                    onClick={this.buttonUpdate_OnClick}
                                />
                            </div>
                        </div>
                    </div>


                </Popup>
            </React.Fragment>
        );
    }

}

export default PopupShuttlesAdminRelationsEditDriver

