import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  EmailRule
} from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import notify from 'devextreme/ui/notify';
import { useAuth } from '../../contexts/auth';
import globalConfig from '../../config/global.js';

import './LoginForm.scss';

export default function LoginForm() {
  const navigate = useNavigate();
  const { signIn } = useAuth();
  const [loading, setLoading] = useState(false);
  const formData = useRef({ email: '', password: '' });

    const [showResetPassword, setShowResetPassword] = useState(globalConfig.settings.login.allowResetPassword);
    const [showCreateAccount, setShowCreateAccount] = useState(globalConfig.settings.login.allowCreateAccount);

  const onSubmit = useCallback(async (e) => {
    e.preventDefault();
    const { email, password } = formData.current;
    setLoading(true);

    const result = await signIn(email, password);
      if (!result.isOk) {
          setLoading(false);
          notify(result.message, 'error', 2000);
      } else {
          if ((sessionStorage.getItem('currentUserIsRidersAdministrator') == 'true' || sessionStorage.getItem('currentUserIsRidersUser') == 'true') && (sessionStorage.getItem('currentUserIsShuttlesAdministrator') == 'false' && sessionStorage.getItem('currentUserIsShuttlesUser') == 'false')) {
              navigate('/riders');
          } else if (sessionStorage.getItem('currentUserIsShuttlesAdministrator') == 'true' || sessionStorage.getItem('currentUserIsShuttlesUser') == 'true') {
              navigate('/shuttles');
          } else {
              navigate('/home');
          }
      }
  }, [signIn]);

  const onCreateAccountClick = useCallback(() => {
    navigate('/create-account');
  }, [navigate]);


    useEffect(() => {
        let el = document.getElementsByName('email')[0];
        if (el) {
            el.focus();
        }
    }, []);


  return (
    <form className={'login-form'} onSubmit={onSubmit}>
          <Form formData={formData.current} disabled={loading}>
        <Item
          dataField={'email'}
          editorType={'dxTextBox'}
          editorOptions={emailEditorOptions}
        >
          <RequiredRule message="Email is required" />
          <EmailRule message="Email is invalid" />
          <Label visible={false} />
        </Item>
        <Item
          dataField={'password'}
          editorType={'dxTextBox'}
          editorOptions={passwordEditorOptions}
        >
          <RequiredRule message="Password is required" />
          <Label visible={false} />
        </Item>
        <Item
                  dataField={'rememberMe'}
                  editorType={'dxCheckBox'}
                  editorOptions={rememberMeEditorOptions}
                  visible={false}
        >
          <Label visible={false} />
        </Item>
        <ButtonItem>
          <ButtonOptions
            width={'100%'}
            type={'default'}
            useSubmitBehavior={true}
          >
            <span>
              {
                loading
                  ? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
                  : ''
              }
            </span>
          </ButtonOptions>
              </ButtonItem>

              {showResetPassword === true && (
                  <Item>
                      <div className={'link'}>
                          <Link to={'/reset-password'}>Forgot password?</Link>
                      </div>
                  </Item>
              )}

              {showCreateAccount === true && (
                  <ButtonItem>
                      <ButtonOptions
                          text={'Create an account'}
                          width={'100%'}
                          onClick={onCreateAccountClick}
                      />
                  </ButtonItem>
              )}

      </Form>
    </form>
  );
}

const emailEditorOptions = { stylingMode: 'filled', placeholder: 'Email', mode: 'email' };
const passwordEditorOptions = { stylingMode: 'filled', placeholder: 'Password', mode: 'password' };
const rememberMeEditorOptions = { text: 'Remember me', elementAttr: { class: 'form-text' } };
