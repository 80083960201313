import React from 'react';
import DataGrid, {
    RemoteOperations, Editing, ColumnChooser, GroupPanel, FilterPanel, FilterRow, HeaderFilter, Paging, Pager, Scrolling, Column, Button, SearchPanel, KeyboardNavigation, Export, StateStoring, Sorting, Toolbar, Item
} from 'devextreme-react/data-grid';
import { LoadPanel } from 'devextreme-react/load-panel';

import { HubConnectionBuilder } from '@microsoft/signalr';
import ServiceGeneral from '../../../../api/services/ServiceGeneral'
import ServiceRiders from '../../../../api/services/ServiceRiders'

import './DataGridRiders.scss'

// TODO filterbuilder popup (viewstate.filterValues)

// props
//      onInitialized: callback
//      onExportRider: callback
//      onConnectionError: callback
//
//      setSelectedGigDateIds: callable
//      setSelectedArtistIds: callable
//      setUserSettings:  callable

class DataGridRiders extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dataSourceRiders: [],
            showFilter: false,
            useAdvancedFiltering: false,
        };

        this.dataSourceSubArtistColors = [];
        this.dataSourceGigDates = [];

        this.ColsBand = [];
        this.ColsAdded = false;

        this.checkBoxShowHideCommentsInstance = null;
        this.ShowCommentsSwitch = true;
        this.checkBoxShowHideZeroesInstance = null;
        this.ShowZeroesSwitch = true;
        this.ShowBlankCommentsSwitch = true;
        this.checkBoxShowHideBlankCommentsInstance = null;

        this.dataGridInstance = null;

        this.selectedGigDateIds = [];
        this.selectedArtistIds = [];
 
        this.hubConnection = null;

        this.viewState = {};

        this.userSettings = {
            UserId: parseInt(sessionStorage.getItem("currentUserId"), 10),
            DefaultViewId: -1,
            DefaultFilterId: -1,
            ExportColors: true,
            MultiSelectionGigDates: false,
            MultiSelectionArtists: true,
            UseBlanksForZeroes: true,
            UseColorsProducts: true,
            UseColorsArtists: true,
            ColorOriginProducts: 'shop',
            ColorOriginArtists: 'artisttype',
            ShowSelectedCell: false,
            UseAdvancedFiltering: false,
        }

        this.showSelectedCell = false;

        this.loadPanelInstance = null;
        this.loadingPosition = { of: '#grid' }
  }

    // CALLBACKS
    onInitialized = (instance) => {
        if (this.props.onInitialized) {
            this.props.onInitialized(instance);
        }
    }

    onConnectionError = () => {
        if (this.props.onConnectionError) {
            this.props.onConnectionError();
        }
    }
    onExportRider = () => {
        if (this.props.onExportRider) {
            this.props.onExportRider();
        }
    }

    // EVENTS
    componentDidMount = async () => {
        this.hubConnection = new HubConnectionBuilder().withUrl("/ridersHub").build();

        this.hubConnection.start()
            .then(function () {

            })
            .catch(function (err) {
                return console.error(err.toString());
            });

        this.hubConnection.on("ReceiveMessageAmount", await this.updatePushedDataAmount);
        this.hubConnection.on("ReceiveMessageComment", await this.updatePushedDataComment);

        await this.loadDataSourceGigDates();
    }

    // DATA
    loadDataSourceRiders = async () => {
        var data = await ServiceRiders.getRidersByEventIdGigDateIdsArtistIds(parseInt(sessionStorage.getItem("currentEventId"), 10), this.selectedGigDateIds, this.selectedArtistIds, this.userSettings.UseBlanksForZeroes, this.userSettings.ColorOriginProducts);
        if (data !== null && !data.hasOwnProperty("error")) {
            this.setState({ dataSourceRiders: data })
        } else {
            this.onConnectionError();  // callback
        }
    }

    loadDataSourceSubArtistColors = async () => {
        var data = await ServiceRiders.getSubArtistsColorsByEventIdGigDateIds(parseInt(sessionStorage.getItem("currentEventId"), 10), JSON.stringify(this.selectedGigDateIds), this.userSettings.ColorOriginArtists);
        if (data !== null && !data.hasOwnProperty("error")) {
            this.dataSourceSubArtistColors = data;
        } else {
            this.onConnectionError();  // callback
        }
    }

    loadDataSourceGigDates = async () => {
        var data = await ServiceGeneral.getGigDatesByEventId(parseInt(sessionStorage.getItem("currentEventId"), 10), 'riders');
        if (data !== null && !data.hasOwnProperty("error")) {
            this.dataSourceGigDates = data;
        } else {
            this.setErrorHeader(true);
        }
    }

    // LOADPANEL
    loadPanelOnInitialized = (e) => {
        // set instance
        this.loadPanelInstance = e.component;
    }

    // GRID
    gridOnCellPrepared = (e) => {
        if (e.rowType === 'data') {
            if (e.column.dataField === 'Shops' || e.column.dataField === 'ProductName' || e.column.dataField === 'ProductTypeName') {
                if (this.userSettings.UseColorsProducts) {
                    e.cellElement.style.backgroundColor = e.data.ColorHex;
                    e.cellElement.style.color = e.data.ForeColorHex;
                }
            } else {
                if (e.column.dataField !== undefined && e.column.dataField.startsWith('_SUBARTIST_')) {
                    let colName = e.column.dataField.replace('_SUBARTIST_', '').replace('_Amount', '').replace('_Comment', '');
                    let saId = parseInt(colName.split("_")[0], 10);
                    for (var i = 0; i < e.cellElement.classList.length; i++) {
                        if (e.cellElement.classList[i].startsWith('hx-column-style-' + saId)) {
                            e.cellElement.classList.remove(e.cellElement.classList[i]);
                            break;
                        }
                    }
                }
                e.cellElement.style.backgroundColor = '#FFFFFF';
                e.cellElement.style.color = '#000000';
            }
        } else {
            if (e.rowType === 'filter') {
                e.cellElement.style.backgroundColor = '#FFFFFF';
                e.cellElement.style.color = '#000000';
                if (e.column.dataField !== undefined && e.column.dataField.startsWith('_SUBARTIST_')) {
                    let colName = e.column.dataField.replace('_SUBARTIST_', '').replace('_Amount', '').replace('_Comment', '');
                    let saId = parseInt(colName.split("_")[0], 10);
                    for (var i = 0; i < e.cellElement.classList.length; i++) {
                        if (e.cellElement.classList[i].startsWith('hx-column-style-' + saId)) {
                            e.cellElement.classList.remove(e.cellElement.classList[i]);
                            break;
                        }
                    }
                }
            }
        }
    }

    gridOnFocusedCellChanging = (e) => {
        if (this.showSelectedCell) {
            let elements = document.getElementsByClassName("hx-datagrid-cell-border");
            if (elements) {
                for (var i = 0; i < elements.length; i++) {
                    elements[i].classList.remove('hx-datagrid-cell-border');
                }
            }

            e.cellElement[0].classList.add('hx-datagrid-cell-border');
        }
    }

    gridOnEditorPreparing = (e) => {
        if (e.parentType === 'dataRow' && e.editorName === "dxNumberBox") {
            e.editorOptions.step = 0;
            e.editorElement.parentElement.classList.add('hx-datagrid-cell-border');
        }
    }

    gridOnRowUpdating = async (e) => {
        this.dataGridInstance.beginUpdate();

        for (var key in e.newData) {
            if (e.newData.hasOwnProperty(key)) {
                if (key.startsWith('_SUBARTIST_')) {
                    let colName = key.replace('_SUBARTIST_', '');
                    let gigDateId = parseInt(colName.split("_")[2], 10);
                    let subArtistId = parseInt(colName.split("_")[0], 10);
                    let productId = e.oldData.ProductId;
                    let amount = e.newData[key] ?? 0;
                    if (key.endsWith('_Amount')) {
                        if (subArtistId > -1) {
                            const modelAmount = {
                                GigDateId: gigDateId,
                                SubArtistId: subArtistId,
                                ProductId: productId,
                                Amount: amount,
                            }
                            var data = await ServiceRiders.updateRiderAmount(modelAmount);
                            if (data !== null && !data.hasOwnProperty("error") && data === true) {
                                this.hubConnection
                                    .invoke("SendUpdateInfoAmount", gigDateId, subArtistId, productId, amount)
                                    .catch(function (err) {
                                        return console.error(err.toString());
                                    });

                            } else {
                                this.onConnectionError();  // callback
                            }
                        }
                    } else if (key.endsWith('_Comment')) {
                        let comment = e.newData[key];

                        if (subArtistId > -1) {
                            const modelComment = {
                                GigDateId: gigDateId,
                                SubArtistId: subArtistId,
                                ProductId: productId,
                                Comment: comment.trim(),
                            }
                            var data = await ServiceRiders.updateRiderComment(modelComment);
                            if (data !== null && !data.hasOwnProperty("error") && data === true) {
                                this.hubConnection
                                    .invoke("SendUpdateInfoComment", gigDateId, subArtistId, productId, comment)
                                    .catch(function (err) {
                                        return console.error(err.toString());
                                    });

                            } else {
                                this.onConnectionError();  // callback
                            }
                        }
                    }
                    
                }
                break;
            }
        }

        this.dataGridInstance.endUpdate();
    }

    gridOnInitialized = (e) => {
        // set instance
        this.dataGridInstance = e.component;
        this.onInitialized(e.component);  // callback

        // set focused row
       // this.dataGridInstance.option('focusedRowIndex', 0);
    }

    gridOnToolbarPreparing = async (e) => {
        let toolbarItems = e.toolbarOptions.items;
        // Modifies an existing item
        //toolbarItems.forEach(function (item) {
        //    if (item.name === "saveButton") {
        //        item.options = {
        //            icon: "custom-save-icon",
        //            onClick: function (e) {
        //                // Implement custom save logic here
        //            }
        //        }
        //    }
        //});

        toolbarItems.push({
            widget: 'dxCheckBox',
            options: {
                icon: 'comment',
                text: 'Hide all comments',
                onValueChanged: this.showHideComments,
                onContentReady: this.checkBoxShowHideCommentsOnContentReady
            },
            location: 'before'
        });
        toolbarItems.push({
            widget: 'dxCheckBox',
            options: {
                text: 'Hide blank comments',
                onValueChanged: this.ShowHideBlankComments,
                onContentReady: this.checkBoxShowHideBlankCommentsOnContentReady
            },
            location: 'before'
        });
        toolbarItems.push({
            widget: 'dxCheckBox',
            options: {
                text: 'Hide blank products',
                onValueChanged: this.ShowHideZeroes,
                onContentReady: this.checkBoxShowHideZeroesOnContentReady
            },
            location: 'before'
        });


        // Adds a new item
        toolbarItems.push({
            widget: 'dxButton',
            options: {
                icon: 'exportxlsx',
                onClick: this.onExportRider
            },
            location: 'after'
        });
        toolbarItems.push({
            widget: 'dxButton',
            options: {
                icon: 'filter',
                onClick: this.switchFilter
            },
            location: 'after'
        });
    }

    gridOnContentReady = (e) => {
         // check filters, 'product', 'shop' and 'type' input fields become empty if cols added (dx bug)
         if (this.viewState.filters !== undefined) {
            for (let filter in this.viewState.filters) {
                if (this.state.dataSourceRiders !== undefined && this.state.dataSourceRiders.length > 0 && this.state.dataSourceRiders[0].hasOwnProperty(filter)) {
                    if (this.dataGridInstance.columnOption(filter, 'filterValue') !== this.viewState.filters[filter]) {
                        this.dataGridInstance.columnOption(filter, 'filterValue', this.viewState.filters[filter]);
                    }
                }
            }
        }
    }

    gridOnEditingStart = (e) => {
        if (!e.column || e.column.dataType === 'boolean') {
            return;
        }

        // make sure onrowupdating is always triggered after edit
        var row = this.dataGridInstance.getRowIndexByKey(e.key);
        var value = this.dataGridInstance.cellValue(row, e.column.dataField);

        if (e.column.dataType.toLowerCase() == "string") {
            this.dataGridInstance.cellValue(row, e.column.dataField, (value !== "%___%" ? "%___%" : ""));
        } else {
            this.dataGridInstance.cellValue(row, e.column.dataField, (value !== 999999 ? 999999 : 0));
        }
        this.dataGridInstance.cellValue(row, e.column.dataField, value);
    }

    gridOnEditCanceled = async (e) => {
        var id = this.dataGridInstance.getKeyByRowIndex(this.dataGridInstance.option('focusedRowIndex'));
        if (id) {
            await this.updateRow(id);
        }
    }

    gridOnCellHoverChanged = (e) => {
        if (e.rowType === 'header') {
            let color = '#000000';
            let band = true;
            for (var i = 0; i < e.cellElement.classList.length; i++) {
                if (e.cellElement.classList[i].startsWith('hx-column-band-style-')) {
                    const el = e.cellElement.classList[i];
                    const ind = el.split('-');
                    if (ind.length > 0) {
                        color = '#' + ind[ind.length - 1];
                    }
                    break;
                } else if (e.cellElement.classList[i].startsWith('hx-column-style-')) {
                    band = false;
                    const el = e.cellElement.classList[i];
                    const ind = el.split('-');
                    if (ind.length > 0) {
                        color = '#' + ind[ind.length - 1];
                    }

                    break;
                }
            }

            if (band) {
                e.cellElement.childNodes[0].classList.remove('dx-datagrid-text-content');
                if (e.eventType == 'mouseover') {
                    if (color.toUpperCase() == '#000000') {
                        e.cellElement.childNodes[0].classList.add('dx-datagrid-text-content-black');
                    } else {
                        e.cellElement.childNodes[0].classList.add('dx-datagrid-text-content-white');
                    }
                }

            } else {
                if (e.eventType == 'mouseover') {
                    e.cellElement.childNodes[1].classList.remove('dx-datagrid-text-content');
                    if (color.toUpperCase() == '#000000') {
                        e.cellElement.childNodes[1].classList.add('dx-datagrid-text-content-black');
                    } else {
                        e.cellElement.childNodes[1].classList.add('dx-datagrid-text-content-white');
                    }
                }
            }
        }
    }

    addSubArtistColumns = () => {
        // define columns array (subartists)
        if (this.ColsAdded === false) {
            let productRow = {};
            if (this.state.dataSourceRiders !== undefined && this.state.dataSourceRiders.length > 0) {
                // get subartist column names and colors from first row
                productRow = this.state.dataSourceRiders[0];
                let artistId = -1;
                let artistName = '';
                let cols = [];
                let sheet = document.createElement('style');
                sheet.id = 'style-columns';
                let css = '';
                let cssBand = '';
                let prevFormattedGigDate = '';
                let prevGigDateId = -1;

                for (var key in productRow) {
                    if (productRow.hasOwnProperty(key)) {
                        if (key.startsWith('_SUBARTIST_') === true) {
                            let colName = key.replace('_SUBARTIST_', '').replace('_Amount', '').replace('_Comment', '');
                            let colArtistId = parseInt(key.replace('_SUBARTIST_', '').replace('_Amount', '').replace('_Comment', '').split("_")[1], 10);
                            let gigDateId = parseInt(key.replace('_SUBARTIST_', '').replace('_Amount', '').replace('_Comment', '').split("_")[2], 10);
                            let gigDate = this.dataSourceGigDates.find(x => x.Id == gigDateId);
                            let d = new Date(gigDate.GigDate);
                            let formattedGigDate = d.getDate().toString().padStart(2, '0') + '/' + (d.getMonth() + 1).toString().padStart(2, '0');

                            prevGigDateId = gigDateId;

                            if (colArtistId !== artistId) {
                                if (artistId > -1) {
                                    //let gigDateId = parseInt(key.replace('_SUBARTIST_', '').replace('_Amount', '').replace('_Comment', '').split("_")[2], 10);
                                    //let gigDate = this.dataSourceGigDates.find(x => x.Id == gigDateId);
                                    //let d = new Date(gigDate.GigDate);
                                    //let formattedGigDate = d.getDate().toString().padStart(2, '0') + '/' + (d.getMonth() + 1).toString().padStart(2, '0');
                                    this.ColsBand.push(<Column key={'band' + artistId + prevGigDateId} caption={artistName + ' ' + prevFormattedGigDate} cssClass={cssBand}>{cols}</Column>);  // key must be used in ES6 (unique)
                                }

                                cols = [];
                                artistId = colArtistId;
                                prevFormattedGigDate = formattedGigDate;
                            }

                            let saId = parseInt(colName.split("_")[0], 10);
                            let caption = colName;
                            let bg = '#FFFFFF';
                            let fg = '#000000';

                            if (this.dataSourceSubArtistColors !== undefined && this.dataSourceSubArtistColors.length > 0) {
                                let index = this.dataSourceSubArtistColors.findIndex(subArtistId => subArtistId.SubArtistId === saId);
                                if (index > -1) {
                                    artistName = this.dataSourceSubArtistColors[index].ArtistName;
                                    caption = (this.dataSourceSubArtistColors[index].SubArtistTag ? this.dataSourceSubArtistColors[index].SubArtistTag + "-" : "") + this.dataSourceSubArtistColors[index].SubArtistName;
                                    if (this.userSettings.UseColorsArtists) {
                                        bg = this.dataSourceSubArtistColors[index].ColorHex != null ? this.dataSourceSubArtistColors[index].ColorHex : bg;
                                        fg = this.dataSourceSubArtistColors[index].ForeColorHex != null ? this.dataSourceSubArtistColors[index].ForeColorHex : fg;
                                    }
                                }
                            }

                            // e.cellElement.classList[0]
                            css = 'hx-column-style-' + saId + '-' + fg.slice(1);
                            if (sheet.innerHTML.indexOf(css) == -1) {
                                sheet.innerHTML += '.' + css + ' {color: ' + fg + ' !important; background-color: ' + bg + ' !important; min-width:50px !important;}';
                                sheet.innerHTML += '.' + css + ':hover {color: ' + fg + ' !important; background-color: ' + bg + ' !important; min-width:50px; !important}';
                                cssBand = 'hx-column-band-style-' + saId + '-' + fg.slice(1);
                                sheet.innerHTML += '.' + cssBand + ' {color: ' + fg + ' !important; background-color: ' + bg + ' !important;}';
                                sheet.innerHTML += '.' + cssBand + ':hover {color: ' + fg + ' !important; background-color: ' + bg + ' !important;}';
                          //      sheet.innerHTML += '.dx-datagrid-headers .dx-datagrid-table .dx-row > td:hover .dx-datagrid-text-content { background-color: white; color: black; }'
                            }
                       
                            if (key.endsWith('_Amount')) {
                                cols.push(<Column key={'col' + colName + 'amount'} dataField={key} dataType='number' caption={caption} visible={true} cssClass={css} allowEditing={true} allowResizing={false} allowFiltering={false} width='auto' minWidth='50px' />);  // key must be used in ES6 (unique)
                            } else if (key.endsWith('_Comment')) {
                                caption += ' Comment'
                                cols.push(<Column key={'col' + colName + 'comment'} dataField={key} dataType='string' caption={caption} visible={true} cssClass={css} allowEditing={true} allowResizing={false} allowFiltering={false} width='auto'  minWidth='50px' />);  // key must be used in ES6 (unique)
                            }
                        }
                    }
                }

                if (artistId > -1) {
                    this.ColsBand.push(<Column key={'band' + artistId + prevGigDateId} caption={artistName + ' ' + prevFormattedGigDate} cssClass={cssBand}>{cols}</Column>);  // key must be used in ES6 (unique)
                }

                //this.ColsBand.push(<Column key='coldummy' caption='' allowFiltering={false} width='100%' visible={true} />);

                this.ColsAdded = true;

                this.styleSheet = sheet;
                
                //var child = document.getElementById('style-columns');
                //if (child) {
                //    document.body.removeChild(child);
                //}
                //document.body.appendChild(sheet);

                // if column is removed and filtered => remove filter, otherwise crash
                if (this.dataGridInstance) {
                    let arrFilter = this.dataGridInstance.option('columns').filter(function (col) { return col.hasOwnProperty('columns') })  // if 'columns' is defined we are dealing with artist
                    for (let col of arrFilter) {
                        for (let sa of col.columns) {
                            if (!productRow.hasOwnProperty(sa.dataField)) {
                                this.dataGridInstance.columnOption(sa.dataField, 'filterValue', null);
                            }
                        }
                    }
                }
            } else {
                // if column is removed and filtered => remove filter, otherwise crash
                if (this.dataGridInstance) {
                    let arrFilter = this.dataGridInstance.option('columns').filter(function (col) { return col.hasOwnProperty('columns') })  // if 'columns' is defined we are dealing with artist
                    for (let col of arrFilter) {
                        for (let sa of col.columns) {
                            this.dataGridInstance.columnOption(sa.dataField, 'filterValue', null);
                        }
                    }
                }
            }
        }
    }

    // reset added columns (columns are added back on render)
    resetSubArtistColumns = async () => {
        this.ColsBand = [];
        this.ColsAdded = false;

        await this.loadPanelInstance.show()
        await this.loadDataSourceRiders();
        await this.loadPanelInstance.hide()

        this.checkBoxShowHideCommentsInstance.option('value', false);
        this.checkBoxShowHideBlankCommentsInstance.option('value', false);
        this.checkBoxShowHideZeroesInstance.option('value', false);
    }

    switchFilter = () => {
        this.setState(prevState => ({
            showFilter: !prevState.showFilter
        }));
    }

    showHideComments = (e) => {
        this.ShowCommentsSwitch = !this.ShowCommentsSwitch;
        let productRow = {};

        if (this.ShowCommentsSwitch) {
            if (!this.ShowBlankCommentsSwitch) {
                var data = this.state.dataSourceRiders;
                var vis = false;
                let productRow = {};
                if (this.state.dataSourceRiders !== undefined && this.state.dataSourceRiders.length > 0) {
                    productRow = this.state.dataSourceRiders[0];
                    for (var key in productRow) {
                        if (productRow.hasOwnProperty(key)) {
                            if (key.endsWith('_Comment') === true) {
                                vis = false;
                                for (let row of data) {
                                    if (row[key] !== null && row[key] !== '') {
                                        vis = true;
                                        break;
                                    };
                                }
                                this.dataGridInstance.columnOption(key, 'visible', vis);
                            }
                        }
                    }
                }
            } else {
                if (this.state.dataSourceRiders !== undefined && this.state.dataSourceRiders.length > 0) {
                    productRow = this.state.dataSourceRiders[0];
                    for (var key in productRow) {
                        if (productRow.hasOwnProperty(key)) {
                            if (key.endsWith('_Comment') === true) {
                                this.dataGridInstance.columnOption(key, 'visible', this.ShowCommentsSwitch)
                            }
                        }
                    }
                }
            }
        } else {
            if (this.state.dataSourceRiders !== undefined && this.state.dataSourceRiders.length > 0) {
                productRow = this.state.dataSourceRiders[0];
                for (var key in productRow) {
                    if (productRow.hasOwnProperty(key)) {
                        if (key.endsWith('_Comment') === true) {
                            this.dataGridInstance.columnOption(key, 'visible', this.ShowCommentsSwitch)
                        }
                    }
                }
            }
        }
    }

    checkBoxShowHideCommentsOnContentReady = (e) => {
        this.checkBoxShowHideCommentsInstance = e.component;
    }

    ShowHideZeroes = async () => {
        this.ShowZeroesSwitch = !this.ShowZeroesSwitch;
        if (this.ShowZeroesSwitch) {
           // await this.store.load();
            await this.loadPanelInstance.show()
            await this.loadDataSourceRiders();
            await this.loadPanelInstance.hide()
        } else {
            var data = this.state.dataSourceRiders;
            var adaptedData = [];
            for (let row of data) {
                for (let col in row) {
                    if (col.endsWith('_Amount')) {
                        if (row[col] !== null && row[col] !== 0) {
                            adaptedData.push(row);
                            break;
                        };
                    } else if (col.endsWith('_Comment')) {
                        if (row[col] !== null && row[col] !== '') {
                            adaptedData.push(row);
                            break;
                        };
                    };
                }
            }
            this.setState({ dataSourceRiders: adaptedData })
        }
    }

    checkBoxShowHideZeroesOnContentReady = (e) => {
        this.checkBoxShowHideZeroesInstance = e.component;
    }

    ShowHideBlankComments = async (e) => {
        this.ShowBlankCommentsSwitch = !this.ShowBlankCommentsSwitch;

        if (this.ShowCommentsSwitch) {
            if (this.ShowBlankCommentsSwitch) {
                let productRow = {};
                if (this.state.dataSourceRiders !== undefined && this.state.dataSourceRiders.length > 0) {
                    productRow = this.state.dataSourceRiders[0];
                    for (var key in productRow) {
                        if (productRow.hasOwnProperty(key)) {
                            if (key.endsWith('_Comment') === true) {
                                this.dataGridInstance.columnOption(key, 'visible', true);
                            }
                        }
                    }
                }
            } else {
                var data = this.state.dataSourceRiders;
                var vis = false;
                let productRow = {};
                if (this.state.dataSourceRiders !== undefined && this.state.dataSourceRiders.length > 0) {
                    productRow = this.state.dataSourceRiders[0];
                    for (var key in productRow) {
                        if (productRow.hasOwnProperty(key)) {
                            if (key.endsWith('_Comment') === true) {
                                vis = false;
                                for (let row of data) {
                                    if (row[key] !== null && row[key] !== '') {
                                        vis = true;
                                        break;
                                    };
                                }
                                this.dataGridInstance.columnOption(key, 'visible', vis);
                            }
                        }
                    }
                }
            }
        }
    }

    checkBoxShowHideBlankCommentsOnContentReady = (e) => {
        this.checkBoxShowHideBlankCommentsInstance = e.component;
    }

    setSelectedGigDateIds = async (ids, render) => {
        this.selectedGigDateIds = ids;

        if (this.userSettings.UseColorsArtists) {
            await this.loadDataSourceSubArtistColors();
        }

        if (render === undefined || render === true) {
            await this.resetSubArtistColumns();
        }
    }

    setSelectedArtistIds = async (ids, render) => {
        this.selectedArtistIds = ids;

        if (render === undefined || render === true) {
            await this.resetSubArtistColumns();
        }
    }

    setUserSettings = async (settings) => {
        this.userSettings = settings;
        this.applyUserSettings('checkBoxShowSelectedCell', this.userSettings.ShowSelectedCell);
        this.applyUserSettings('checkBoxUseAdvancedFiltering', this.userSettings.UseAdvancedFiltering);
    }

    applyUserSettings = (elementId, value) => {
        switch (elementId) {
            case 'checkBoxShowSelectedCell':
                this.showSelectedCell = value;
                this.dataGridInstance.repaint();
                break;
            case 'checkBoxUseAdvancedFiltering':
                this.setState({ useAdvancedFiltering: value });
                this.dataGridInstance.repaint();
                break;
            default:
                break;
        }
    }

    // update cells modified by other users (collaborative editing, SignalR)
    updatePushedDataAmount = async(gigDateId, subArtistId, productId, amount) => {
        let isEditing = this.dataGridInstance.element().querySelector(".dx-datagrid-rowsview td.dx-editor-cell.dx-focused") != null;
        if (!isEditing) {
            var dsRow = this.state.dataSourceRiders.find(function (row) {
                return row.ProductId === productId;
            });
            if (dsRow) {
                for (var key in dsRow) {
                    if (key.startsWith('_SUBARTIST_' + subArtistId) && key.endsWith('_Amount') && dsRow[key] !== amount) {
                        dsRow[key] = amount;

                        let rowIndex = this.dataGridInstance.getRowIndexByKey(productId);
                        this.dataGridInstance.cellValue(rowIndex, key, amount);
                        this.dataGridInstance.repaintRows(rowIndex);
                        let cellElement = this.dataGridInstance.getCellElement(rowIndex, key);
                        if (cellElement !== undefined && cellElement !== null) {
                            cellElement.classList.remove('dx-highlight-outline');
                        }
                        break;
                    }
                }
            }

            setTimeout(this.gridFocus, 10);
        }
    }

    updatePushedDataComment = async (gigDateId, subArtistId, productId, comment) => {
        let isEditing = this.dataGridInstance.element().querySelector(".dx-datagrid-rowsview td.dx-editor-cell.dx-focused") != null;
        if (!isEditing) {
            var dsRow = this.state.dataSourceRiders.find(function (row) {
                return row.ProductId === productId;
            });
            if (dsRow) {
                for (var key in dsRow) {
                    if (key.startsWith('_SUBARTIST_' + subArtistId) && key.endsWith('_Comment') && dsRow[key] !== comment) {
                        dsRow[key] = comment;
                        //this.dataGridInstance.refresh(true);
                        break;
                    }
                }
            }
            var rowIndex = this.dataGridInstance.getRowIndexByKey(productId);
            this.dataGridInstance.repaintRows(rowIndex);
            setTimeout(this.gridFocus, 10);
        }
    }

    updateRow = async (id) => {
        this.dataGridInstance.beginUpdate();
        var data = await ServiceRiders.getRidersByEventIdGigDateIdsArtistIds(parseInt(sessionStorage.getItem("currentEventId"), 10), this.selectedGigDateIds, this.selectedArtistIds, this.userSettings.UseBlanksForZeroes, this.userSettings.ColorOriginProducts);
        var dataRow = data.find(function (row) {
            return row.ProductId === id;
        });
        this.dataGridInstance.endUpdate();
        if (dataRow) {
            var dsRow = this.state.dataSourceRiders.find(function (row) {
                return row.ProductId === id;
            });
            if (dsRow) {
                Object.entries(dsRow).forEach(([key, value]) => {
                    dsRow[key] = dataRow[key];
                });
            }
        }
        
        var rowIndex = this.dataGridInstance.getRowIndexByKey(id);
        this.dataGridInstance.repaintRows(rowIndex);
        setTimeout(this.gridFocus, 10);
    }

    gridFocus = () => {
        this.dataGridInstance.focus();
    }

    endRenderUpdate = () => {
        try {
            var child = document.getElementById('style-columns');
            if (child) {
                document.body.removeChild(child);
            }
            document.body.appendChild(this.styleSheet);
        }
        catch (err) {
        }
        this.dataGridInstance.endUpdate();

    }

    render() {
        if (this.dataGridInstance) {
            this.dataGridInstance.beginUpdate();
        }

        // get subartist cols
        this.addSubArtistColumns();

        if (this.dataGridInstance) {
            setTimeout(this.endRenderUpdate, 300);
        }
        return (
            <React.Fragment>
                <div id="grid" className='dx-card' style={{ height: '100%' }}>
                    <DataGrid
                        ref={ref => this.refDataGrid = ref}
                        height='100%'
                        dataSource={this.state.dataSourceRiders}
                        allowColumnReordering={false}
                        allowColumnResizing={true}
                        allowGrouping={false}
                        cacheEnabled={true}
                        columnResizingMode='widget'
                        columnAutoWidth={true}
                        columnMinWidth={100}
                        keyExpr='ProductId'
                        focusedRowEnabled={false}
                        noDataText='No riders found.'
                        filterSyncEnabled={true}
                        preloadEnabled={true}
                        remoteOperations={true}
                        showBorders={false}
                        showColumnLines={true}
                        showRowLines={true}
                        wordWrapEnabled={true}
                        repaintChangesOnly={true}
                        onInitialized={this.gridOnInitialized}
                        onCellPrepared={this.gridOnCellPrepared}
                        onToolbarPreparing={this.gridOnToolbarPreparing}
                        onRowUpdating={this.gridOnRowUpdating}
                        onFocusedCellChanging={this.gridOnFocusedCellChanging}
                        onEditorPreparing={this.gridOnEditorPreparing}
                        onContentReady={this.gridOnContentReady}
                        onCellHoverChanged={this.gridOnCellHoverChanged}
                        onEditCanceled={this.gridOnEditCanceled}
                        onEditingStart={this.gridOnEditingStart}

                    >
                        <StateStoring enabled={false} type="localStorage" storageKey="storage" />
                        <Export
                            enabled={false}
                        />
                        <KeyboardNavigation
                            enabled={true}
                            editOnKeyPress={true}
                            enterKeyAction='moveFocus'
                            enterKeyDirection='column' />
                        <Editing
                            mode="cell"
                            allowUpdating={true}
                            selectTextOnEditStart={true}
                            startEditAction='dblClick'
                            step={0}
                        />
                        <Sorting mode="multiple" />
                        <ColumnChooser enabled={false} />
                        <FilterPanel visible={this.state.useAdvancedFiltering} />
                        <FilterRow visible={this.state.showFilter} />
                        <GroupPanel visible={false} />
                        <SearchPanel visible={false} />
                        <HeaderFilter visible={true} />
                        <Paging enabled={false} defaultPageSize={20} />
                        <Pager
                            showPageSizeSelector={true}
                            allowedPageSizes={[5, 10, 20, 50]}
                            showInfo={true}
                        />
                        <Scrolling
                            mode="virtual"
                            columnRenderingMode="virtual"
                            rowRenderingMode="virtual"
                        />
                        <Column
                            dataField='Id'
                            dataType='number'
                            caption='Id'
                            allowFiltering={false}
                            allowEditing={false}
                            fixed={true}
                            showInColumnChooser={false}
                            visible={false}
                        />
                        <Column
                            dataField='GigDateId'
                            dataType='number'
                            caption='GigDateId'
                            allowFiltering={false}
                            allowEditing={false}
                            fixed={true}
                            showInColumnChooser={false}
                            visible={false}
                        />
                        <Column
                            dataField='ProductId'
                            dataType='number'
                            caption='ProductId'
                            allowFiltering={false}
                            allowEditing={false}
                            fixed={true}
                            showInColumnChooser={false}
                            visible={false}
                        />
                        <Column
                            dataField='ProductTypeId'
                            dataType='number'
                            caption='ProductTypeId'
                            allowFiltering={false}
                            allowEditing={false}
                            fixed={true}
                            showInColumnChooser={false}
                            visible={false}
                        />
                        <Column
                            dataField='ShopIds'
                            dataType='string'
                            caption='ShopIds'
                            allowFiltering={false}
                            allowEditing={false}
                            fixed={true}
                            showInColumnChooser={false}
                            visible={false}
                        />
                        <Column
                            dataField='ColorId'
                            dataType='number'
                            caption='ColorId'
                            allowFiltering={false}
                            allowEditing={false}
                            fixed={true}
                            showInColumnChooser={false}
                            visible={false}
                        />
                        <Column
                            dataField='PatternId'
                            dataType='number'
                            caption='PatternId'
                            allowFiltering={false}
                            allowEditing={false}
                            fixed={true}
                            showInColumnChooser={false}
                            visible={false}
                        />
                        <Column
                            dataField='ColorHex'
                            dataType='string'
                            caption='ColorHex'
                            allowFiltering={false}
                            allowEditing={false}
                            fixed={true}
                            showInColumnChooser={false}
                            visible={false}
                        />
                        <Column
                            dataField='ForeColorHex'
                            dataType='string'
                            caption='ForeColorHex'
                            allowFiltering={false}
                            allowEditing={false}
                            fixed={true}
                            showInColumnChooser={false}
                            visible={false}
                        />
                        <Column
                            dataField='PatternColorHex'
                            dataType='string'
                            caption='PatternColorHex'
                            allowFiltering={false}
                            allowEditing={false}
                            fixed={true}
                            showInColumnChooser={false}
                            visible={false}
                        />
                        <Column
                            dataField='UsePattern'
                            dataType='number'
                            caption='UsePattern'
                            allowFiltering={false}
                            allowEditing={false}
                            fixed={true}
                            showInColumnChooser={false}
                            visible={false}
                        />
                        <Column
                            dataField='PatternCssBackGround'
                            dataType='string'
                            caption='PatternCssBackGround'
                            allowFiltering={false}
                            allowEditing={false}
                            fixed={true}
                            showInColumnChooser={false}
                            visible={false}
                        />
                        <Column
                            dataField='PatternCssBackGroundImage'
                            dataType='string'
                            caption='PatternCssBackGroundImage'
                            allowFiltering={false}
                            allowEditing={false}
                            fixed={true}
                            showInColumnChooser={false}
                            visible={false}
                        />
                        <Column
                            dataField='PatternCssBackGroundSize'
                            dataType='string'
                            caption='PatternCssBackGroundSize'
                            allowFiltering={false}
                            allowEditing={false}
                            fixed={true}
                            showInColumnChooser={false}
                            visible={false}
                        />
                        <Column
                            dataField='PatternCssBackGroundPosition'
                            dataType='string'
                            caption='PatternCssBackGroundPosition'
                            allowFiltering={false}
                            allowEditing={false}
                            fixed={true}
                            showInColumnChooser={false}
                            visible={false}
                        />
                        <Column
                            dataField='PatternTransparency'
                            dataType='number'
                            caption='PatternTransparency'
                            allowFiltering={false}
                            allowEditing={false}
                            fixed={true}
                            showInColumnChooser={false}
                            visible={false}
                        />
                        <Column
                            dataField='ProductName'
                            dataType='string'
                            caption='Product'
                            sortIndex={0}
                            sortOrder='Asc'
                            width='350px'
                            allowFiltering={true}
                            allowResizing={false}
                            allowEditing={false}
                            fixed={true}
                            showInColumnChooser={true}
                            visible={true}
                            cssClass='wrapped-column-class'
                        />
                        <Column
                            dataField='Shops'
                            dataType='string'
                            caption='Shops'
                            width='150px'
                            allowFiltering={true}
                            allowResizing={false}
                            allowEditing={false}
                            fixed={true}
                            showInColumnChooser={true}
                            visible={true}
                        />
                        <Column
                            dataField='ProductTypeName'
                            dataType='string'
                            caption='Product Type'
                            width='150px'
                            allowFiltering={true}
                            allowResizing={false}
                            allowEditing={false}
                            fixed={false}
                            showInColumnChooser={true}
                            visible={false}
                        />

                        {this.ColsBand}

                        <Column
                            caption=''
                            showInColumnChooser={false}
                            visible={true}
                        />
                    </DataGrid>
                </div>
                <LoadPanel
                    onInitialized={this.loadPanelOnInitialized}
                    shadingColor="rgba(0,0,0,0.4)"
                    position={this.loadingPosition}
                    shading={false}
                />
            </React.Fragment>
        );

    }

}

export default DataGridRiders

