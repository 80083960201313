import React from 'react';
import DataGrid, {
    RemoteOperations, Editing, ColumnChooser, GroupPanel, FilterPanel, FilterRow, HeaderFilter, Paging, Pager, Scrolling, Column, SearchPanel, KeyboardNavigation, Export, StateStoring, Sorting, Toolbar, Item
} from 'devextreme-react/data-grid';
import { Tooltip } from 'devextreme-react/tooltip';
import { on } from "devextreme/events";
import { Button } from 'devextreme-react/button';
import { Popover } from 'devextreme-react/popover';
import RadioGroup from 'devextreme-react/radio-group';

import PopupShuttlesDriverSheet from './PopupShuttlesDriverSheet';
import PopupShuttlesEmail from './PopupShuttlesEmail';

import ServiceTrips from '../../../../api/services/ServiceTrips'
import ServiceUsers from '../../../../api/services/ServiceUsers'

import './ShuttlesDataGridTrips.scss';

const startDateRange = new Date('2022-06-18T05:00:00.000');
const endDateRange = new Date('2022-06-19T05:00:00.000');
const startHour = 5;
const paintOffset = 5;

// props
//      departureDateTime (required): departure datetime of trip
//
//      onInitialized: callback
//      onLoadError: callback
//      onAddTrip: callback
//      onUpdateTrip: callback
//      onDeleteTrip: callback

class ShuttlesDataGridTrips extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dataSourceTrips: [],
            toolTipData: null,
            useAdvancedFiltering: false,
            showFilter: false,
            showEmailCol: false,
        }

        this.selectedGigDate = null;

        this.showSelectedCell = false;

        this.dataGridInstance = null;
        this.refDataGrid = React.createRef();

        this.popupShuttlesDriverSheetInstance = null;
        this.popupShuttlesEmailInstance = null;
        this.popOverStatussesInstance = null;
        this.radioGroupPopOverStatussesInstance = null;

        this.isDrawing = false;

        this.colIdTimings = '';

        this.userSettings = {
            UserId: parseInt(sessionStorage.getItem("currentUserId"), 10),
            DefaultViewId: -1,
            DefaultFilterId: -1,
            ShowSelectedCell: false,
            UseAdvancedFiltering: false,
        }

        window.addEventListener('resize', this.handleResize);
        window.addEventListener('focus', this.handleResize);

        this.timerRepaintGrid = null;

        this.interval = setInterval(() => {
            if (this.dataGridInstance) {
                try {
                    this.dataGridInstance.repaint();
                } catch (e) {
                }
            };
        }, 5 * 60 * 1000); 

        this.tripStatusses = ['Waiting', 'Driving', 'Finished', 'Cancelled'];
        this.processRadiogroupStatussesValueChanged = false;

        // TO DO PUT IN DB
        this.showTimeLineSwitch = true;
        this.showStatusWaiting = true;
        this.showStatusDriving = true;
        this.showStatusFinished = true;
        this.showStatusCancelled = false;
    }

    // CALLBACKS
    onInitialized = (instance) => {
        if (this.props.onInitialized) {
            this.props.onInitialized(instance);  // callback
        }
    }

    onLoadedData = (gigDateId, count) => {
        if (this.props.onLoadedData) {
            this.props.onLoadedData(gigDateId, count);  // callback
        }
    }

    onLoadError = () => {
        if (this.props.onLoadError) {
            this.props.onLoadError();  // callback
        }
    }

    onAddTrip = () => {
        if (this.props.onAddTrip) {
            this.props.onAddTrip();  // callback
        }
    }

    onUpdateTrip = (id) => {
        if (this.props.onUpdateTrip) {
            this.props.onUpdateTrip(id);  // callback
        }
    }

    onDeleteTrip = (data) => {
        if (this.props.onDeleteTrip) {
            this.props.onDeleteTrip(data);  // callback
        }
    }

    //CALLABLES
    refresh = async (gigDateId) => {
        await this.loadDataSourceTrips(gigDateId);
        this.dataGridInstance.refresh();
        this.dataGridInstance.repaint();
    }

    updateRow = async (id) => {
        this.dataGridInstance.beginUpdate();
        var data = await ServiceTrips.getTripsInfoForGanttByEventIdGigDateId(parseInt(sessionStorage.getItem('currentEventId'), 10), this.selectedGigDate, startHour);
        this.dataGridInstance.endUpdate();

        if (data !== null && !data.hasOwnProperty("error")) {
            var dataRow = data.find(function (row) {
                return row.Id === id;
            });
            var dsRow = this.state.dataSourceTrips.find(function (row) {
                return row.Id === id;
            });
            if (dsRow) {
                Object.entries(dsRow).forEach(([key, value]) => {
                    dsRow[key] = dataRow[key];
                });
            }
            var rowIndex = this.dataGridInstance.getRowIndexByKey(id);
            this.dataGridInstance.repaintRows(rowIndex);
            setTimeout(this.gridFocus, 10);

        } else {
            this.refresh();
        }
    }

    getRowCount = () => {
        return this.dataGridInstance.getDataSource().totalCount();
    }

    applyUserSettings = (elementId, value) => {
        switch (elementId) {
            case 'checkBoxShowSelectedCell':
                this.showSelectedCell = value;
                this.dataGridInstance.repaint();
                break;
            case 'checkBoxUseAdvancedFiltering':
                this.setState({ useAdvancedFiltering: value });
                //this.dataGridInstance.repaint();
                break;
            default:
                break;
        }
    }

    showHideTimeLine = (showHide) => {
        this.showTimeLineSwitch = showHide;
        this.dataGridInstance.repaint();
    }

    showHideStatusWaiting = (showHide) => {
        this.showStatusWaiting = showHide;
        this.setStatusFilter();
        this.dataGridInstance.repaint();
    }

    showHideStatusDriving = (showHide) => {
        this.showStatusDriving = showHide;
        this.setStatusFilter();
        this.dataGridInstance.repaint();
    }

    showHideStatusFinished = (showHide) => {
        this.showStatusFinished = showHide;
        this.setStatusFilter();
        this.dataGridInstance.repaint();
    }

    showHideStatusCancelled = (showHide) => {
        this.showStatusCancelled = showHide;
        this.setStatusFilter();
        this.dataGridInstance.repaint();
    }

    setStatusFilter() {
        let filter = [];

        if (this.showStatusWaiting) {
            filter.push(["StatusName", "=", 'Waiting']);
        }

        if (this.showStatusDriving) {
            if (filter.length > 0) {
                filter.push("or");
            }
            filter.push(["StatusName", "=", 'Driving']);
        }

        if (this.showStatusFinished) {
            if (filter.length > 0) {
                filter.push("or");
            }
            filter.push(["StatusName", "=", 'Finished']);
        }

        if (this.showStatusCancelled) {
            if (filter.length > 0) {
                filter.push("or");
            }
            filter.push(["StatusName", "=", 'Cancelled']);
        }

        this.dataGridInstance.filter(filter);  
    }

    // EVENTS
    componentDidMount = async () => {
        await this.loadDSUserSettings();

        this.applyUserSettings('checkBoxShowSelectedCell', this.userSettings.ShowSelectedCell);
        this.applyUserSettings('checkBoxUseAdvancedFiltering', this.userSettings.UseAdvancedFiltering);

        this.setState({ showEmailCol: sessionStorage.getItem('currentUserIsShuttlesAdministrator') == 'true' });

        //this.timerRepaintGrid = setInterval(this.repaintGrid, 5000);

    }

    resetTimerRepaintGrid = () => {
        try {
            this.interval = setInterval(() => {
                if (this.dataGridInstance) {
                    this.dataGridInstance.repaint();
                };
            }, 5 * 60 * 1000);
            clearInterval(this.interval);
        } catch (e) {

        }
    }

    handleResize = () => {
        if (this.isDrawing === false && this.dataGridInstance !== null) {
            this.isDrawing = true;
            try {
                this.dataGridInstance.repaint();
            } catch (e) {
            }
            setTimeout(this.drawHeaderTiming, 200);  // WHY???? not a clue
        }
    }

    // DATA
    loadDataSourceTrips = async (gigDateId) => {
        var data = await ServiceTrips.getTripsInfoForGanttByEventIdGigDateId(parseInt(sessionStorage.getItem('currentEventId'), 10), gigDateId, startHour);
        if (data !== null && !data.hasOwnProperty("error")) {
            this.setState({ dataSourceTrips: this.convertData(data) });
            this.onLoadedData(gigDateId, data.length);  // callback
            this.dataGridInstance.refresh();
        } else {
            this.onLoadError();  // callback
        }

        this.selectedGigDate = gigDateId;
    }

    loadDSUserSettings = async () => {
        var settings = await ServiceUsers.getUserSettingsShuttlesByUserId(parseInt(sessionStorage.getItem("currentUserId"), 10));
        settings = (settings !== null ? settings : this.userSettings);
        if (!settings.hasOwnProperty("error")) {
            this.userSettings = settings;
        } else {
            this.onLoadError();  // callback
        }
    }


    // GRID
    gridOnInitialized = (e) => {
        // set instance
        this.dataGridInstance = e.component;
        this.onInitialized(e.component);

        // set focused row
        this.dataGridInstance.option('focusedRowIndex', 0);
    }

    gridOnOptionChanged = (e) => {
        if (e.fullName.startsWith('columns') && e.fullName.endsWith('width')) {
            if (this.isDrawing === false) {
                this.isDrawing = true;
                this.dataGridInstance.repaint();
                setTimeout(this.drawHeaderTiming, 200);  // WHY???? not a clue
            }
        }
    }

    gridOnContentReady = (e) => {
        setTimeout(this.drawHeaderTiming, 200);  // WHY???? not a clue
    }

    gridOnCellClick = (e) => {
        // if white space is clicked then e.column is undefined
        if (e.column === undefined) {
            return;
        }

        if (e.rowType == 'header') {
            if (e.column.name.toUpperCase() == 'COLUMNTIMINGS') {

                const sortOrder = this.dataGridInstance.columnOption('DateTimeDeparture', 'sortOrder');
                if (sortOrder == undefined) {
                    this.dataGridInstance.columnOption('DateTimeDeparture', 'sortOrder', 'asc');
                } else {
                    switch (sortOrder.toUpperCase()) {
                        case 'ASC':
                            this.dataGridInstance.columnOption('DateTimeDeparture', 'sortOrder', 'desc');
                            break;
                        case 'DESC':
                            this.dataGridInstance.columnOption('DateTimeDeparture', 'sortOrder', undefined);
                            break;
                        default:
                            break;
                    }
                }
                
            }
        } if (e.rowType == 'data') {
            switch (e.column.name.toUpperCase()) {
                case 'ACTIONCOLUMNEDIT':
                    this.onUpdateTrip(e.data.Id);
                    break;
                case 'ACTIONCOLUMNDELETE':
                    this.onDeleteTrip(e.data);
                    break;
                case 'ACTIONCOLUMNCREATEEMAIL':
                    break;
              default:
                    break;
            }
        }
    }

    gridOnContextMenuPreparing = (e) => {

        if (e.row == undefined || e.row.rowType !== 'data') {
            return;
        }

        // e.items can be undefined
        if (!e.items) e.items = [];

        // update selected and focused row (right click outside selection)
        //var selectedKeys = this.dataGridInstance.getSelectedRowKeys();
        //var focused = this.dataGridInstance.option("focusedRowKey");
        //if (!selectedKeys.includes(focused)) {
        //    this.dataGridInstance.deselectAll();
        //    this.dataGridInstance.selectedRowKeys = [focused];
        //}

        e.items.push({
            text: 'Add shuttle',
            onItemClick: () => {
                this.onAddTrip();
            }
        });

        e.items.push({
            text: 'Edit shuttle',
            onItemClick: () => {
                this.onUpdateTrip(e.row.data.Id);
            }
        });

        e.items.push({
            text: 'Delete shuttle',
            onItemClick: () => {
                this.onDeleteTrip(e.row.data);
            }
        });
    }

    gridOnCellPrepared = (e) => {
        e.cellElement.style.paddingTop = 0;
        e.cellElement.style.paddingBottom = 0;

        if (e.rowType === "data" && e.column.name === 'columnTimings') {
            const urgency = this.checkUrgency(e.data);
            if (urgency !== 'NOCOLOR') {
                e.cellElement.style.backgroundColor = urgency;
            }

            on(e.cellElement.children[0].childNodes[0], "mouseover", arg => {
                this.setState({
                    toolTipData: e.data
                });
                this.tooltip.instance.show(arg.target);
            });

            on(e.cellElement.children[0].childNodes[0], "mouseout", arg => {
                this.tooltip.instance.hide();
            });
        }
    }

    gridOnFocusedCellChanging = (e) => {
        if (this.showSelectedCell) {
            let elements = document.getElementsByClassName("hx-datagrid-cell-border");
            if (elements) {
                for (var i = 0; i < elements.length; i++) {
                    elements[i].classList.remove('hx-datagrid-cell-border');
                }
            }

            e.cellElement[0].classList.add('hx-datagrid-cell-border');
        }
    }

    gridOnToolbarPreparing = async (e) => {
        let toolbarItems = e.toolbarOptions.items;
        // Modifies an existing item
        //toolbarItems.forEach(function (item) {
        //    if (item.name === "saveButton") {
        //        item.options = {
        //            icon: "custom-save-icon",
        //            onClick: function (e) {
        //                // Implement custom save logic here
        //            }
        //        }
        //    }
        //});

        toolbarItems.push({
            widget: 'dxButton',
            options: {
                icon: 'filter',
                onClick: this.switchFilter
            },
            location: 'after'
        });
    }

    gridOnRowDblClick = (e) => {
        this.onUpdateTrip(e.data.Id);
    }

    // POPUP
    popupShuttlesDriverSheet_OnInitialized = (instance) => {
        this.popupShuttlesDriverSheetInstance = instance;
    }

    popupShuttlesEmail_OnInitialized = (instance) => {
        this.popupShuttlesEmailInstance = instance;
    }

    popOverStatusses_OnInitialized = (e) => {
        this.popOverStatussesInstance = e.component;
    }

    radioGroupPopOverStatusses_Initialized = (e) => {
        this.radioGroupPopOverStatussesInstance = e.component;
    }

    radioGroupPopOverStatusses_OnValueChanged = async (e) => {
        if (!this.processRadiogroupStatussesValueChanged) {
            this.processRadiogroupStatussesValueChanged = true;
            return;
        }

        const tripId = this.popOverStatussesInstance.option('title');
        const statusName = this.radioGroupPopOverStatussesInstance.option('value');

        var data = await ServiceTrips.updateTripStatus(tripId, statusName);
        if (data !== null && !data.hasOwnProperty("error") && data == true) {
            await this.refresh(this.selectedGigDate);
        } else {
            this.onLoadError();  // callback
        }

        this.popOverStatussesInstance.hide();

    }

    //FUNCTIONS
    convertData = (data) => {
        let converted = [];
        for (var i = 0; i < data.length; i++) {
            let rec = {
                Id: data[i].Id,
                ParentId: 0,
                Title: data[i].ArtistName,
                Start: data[i].DateTimeDeparture,
                End: data[i].DateTimeArrival,
                progress: 0,
                Color: '#FFFF00',
                EventId: data[i].EventId,
                ArtistId: data[i].ArtistId,
                LocationDepartureId: data[i].LocationDepartureId,
                LocationArrivalId: data[i].LocationArrivalId,
                DriverId: data[i].DriverId,
                ColorId: data[i].ColorId,
                PatternId: data[i].PatternId,
                CarId: data[i].CarId,
                StatusId: data[i].StatusId,
                Pax: data[i].Pax,
                Trailer: data[i].Trailer,
                DateTimeDeparture: data[i].DateTimeDeparture,
                DateTimeArrival: data[i].DateTimeArrival,
                ArtistName: data[i].ArtistName,
                LocationDepartureName: data[i].LocationDepartureName,
                LocationArrivalName: data[i].LocationArrivalName,
                DriverName: data[i].DriverName,
                CarName: data[i].CarName,
                CarLicencePlate: data[i].CarLicencePlate,
                StatusName: data[i].StatusName,
                ColorHex: data[i].ColorHex,
                ColorRgba: data[i].ColorRgba,
                ForeColorHex: data[i].ForeColorHex,
                ForeColorRgba: data[i].ForeColorRgba,
                PatternColorHex: data[i].PatternColorHex,
                PatternColorRgba: data[i].PatternColorRgba,
                UsePattern: data[i].UsePattern,
                PatternCssBackGround: data[i].PatternCssBackGround,
                PatternCssBackGroundImage: data[i].PatternCssBackGroundImage,
                PatternCssBackGroundSize: data[i].PatternCssBackGroundSize,
                PatternCssBackGroundPosition: data[i].PatternCssBackGroundPosition,
            }
            converted.push(rec);
        }
        return converted
    }

    drawHeaderTiming = () => {
        this.resetTimerRepaintGrid();
        var canvas = document.getElementById("canvasTimingsHeader");
        if (canvas !== null) {
            const colWidth = (document.getElementById(this.colIdTimings) !== null ? document.getElementById(this.colIdTimings).clientWidth - 16 : canvas.width);
            const diffHours = Math.abs(startDateRange.getTime() - endDateRange.getTime()) / 3600000;
            const hourPixelWidth = (colWidth / diffHours);
            const diffMinutes = Math.abs(startDateRange.getTime() - endDateRange.getTime()) / 60000;
            const minutePixelWidth = (colWidth / diffMinutes);

            let hour = startHour;
            let ctx = canvas.getContext("2d");
            ctx.font = "13px Roboto,RobotoFallback,'Noto Kufi Arabic',Helvetica,Arial,sans-serif";
            ctx.lineWidth = 1;
            ctx.fillStyle = "#999999";
            ctx.strokeStyle = '#999999';

            let offset = paintOffset;
            for (var i = startHour; i < 24; i++) {
                ctx.beginPath();
                ctx.moveTo(offset, 0);
                ctx.lineTo(offset, 8);
                ctx.stroke();
                ctx.fillText(hour, (i < 10 ? offset - 4 : offset - 8), 20);
                ctx.closePath();

                offset += hourPixelWidth;
                hour += 1;
            }

            hour = 0;
            for (var i = 0; i <= startHour; i++) {
                ctx.beginPath();
                ctx.moveTo(offset, 0);
                ctx.lineTo(offset, 8);
                ctx.stroke();
                ctx.fillText(hour, (i < 10 ? offset - 4 : offset - 8), 20);
                ctx.closePath();

                offset += hourPixelWidth;
                hour += 1;
            }

            if (this.showTimeLineSwitch) {
                ctx.beginPath();
                ctx.strokeStyle = '#ff0000';
                var dtNow = new Date();
                const convStartDateBG = new Date(new Date(new Date(new Date(startDateRange).setDate(dtNow.getDate())).setMonth(dtNow.getMonth())).setFullYear(dtNow.getFullYear()));
                const offsetLeftBG = ((Math.abs(dtNow.getTime() - convStartDateBG.getTime()) / 60000) * minutePixelWidth) + paintOffset;
                ctx.moveTo(offsetLeftBG - 3, 0);
                ctx.lineTo(offsetLeftBG + 3, 0);
                ctx.lineTo(offsetLeftBG, 6);
                ctx.lineTo(offsetLeftBG - 3, 0);
               // ctx.lineTo(offsetLeftBG, 36);
                ctx.stroke();
                ctx.fillStyle = "#FF0000";
                ctx.fill();
                ctx.closePath();
            }
        }
        this.isDrawing = false;
    }

    getTiming = () => {
        const startDate = new Date(this.state.toolTipData.DateTimeDeparture);
        const endDate = new Date(this.state.toolTipData.DateTimeArrival);
        return startDate.getHours().toString().padStart(2, '0') + ':' + startDate.getMinutes().toString().padStart(2, '0') + ' - ' + endDate.getHours().toString().padStart(2, '0') + ':' + endDate.getMinutes().toString().padStart(2, '0')
    }

    checkUrgency = (data) => {
        let bg = "NOCOLOR";
        if (data.StatusName.toLowerCase() == 'waiting') {
            const dtDep = new Date(data.DateTimeDeparture);
            const dtNow = new Date();
            dtNow.setMonth(5);
            if (dtDep.getFullYear() == dtNow.getFullYear() && dtDep.getMonth() == dtNow.getMonth() && dtDep.getDate() == dtNow.getDate()) {
                let diffMinutes = (dtDep.getHours() * 60 + dtDep.getMinutes()) - (dtNow.getHours() * 60 + dtNow.getMinutes())
                if (diffMinutes > 0 && diffMinutes <= 15) {
                    if (diffMinutes <= 5) {
                        bg = '#FF0000';
                    } else {
                        bg = '#FFA500';
                    }
                } else if (diffMinutes <= 0) {
                    bg = '#FF0000';
                }
            }
        }

        return bg;
    }

    switchFilter = () => {
        this.setState(prevState => ({
            showFilter: !prevState.showFilter
        }));
    }


    // RENDERING
    renderEditTemplate = (e) => {
        return <div style={{ display: 'flex', justifyContent: 'start' }}>
            <div style={{ width: '40px' }}><i className='dx-icon-edit' style={{ color: '#FF5722' }} /></div>
        </div>;
    }

    renderDeleteTemplate = (e) => {
        return <div style={{ display: 'flex', justifyContent: 'start' }}>
            <div style={{ width: '40px' }}><i className='dx-icon-trash' style={{ color: '#FF5722' }} /></div>
        </div>;
    }

    renderDriverSheetTemplate = (e) => {
        return <Button
            icon='exportpdf'
            stylingMode='text'
            text=''
            onClick={async () => {
                await this.refPopupShuttlesDriverSheet.setData(e.data);
                this.popupShuttlesDriverSheetInstance.show();
            }}
        />;
    }

    renderCreateEmailTemplate = (e) => {
        return <Button
            icon='email'
            stylingMode='text'
            text=''
            onClick={async () => {
                await this.refPopupShuttlesEmail.setData(e.data.Id, e.data.ArtistId);
                this.popupShuttlesEmailInstance.show();
            }}
        />;
    }

    renderTimingsHeader = (e) => {
        if (e.rowType === 'header' && e.column.name === 'columnTimings') {
            this.resetTimerRepaintGrid();

            this.colIdTimings = e.column.headerId;
            document.getElementById(this.colIdTimings).style.padding = 0;
            document.getElementById(this.colIdTimings).style.margin = 0;

            const colWidth = (document.getElementById(e.column.headerId) !== null ? document.getElementById(e.column.headerId).clientWidth - 16 : 600);

            return <div className="sdt-timings-header">
                <canvas id='canvasTimingsHeader' className="sdt-canvas-timings-header" width={colWidth} height='25px'></canvas>
            </div>;
        }
    }

    renderTimingsTemplate = (e) => {
        this.resetTimerRepaintGrid();

        document.getElementById(e.column.headerId).style.padding = 0;
        document.getElementById(e.column.headerId).style.margin = 0;

        const colWidth = (document.getElementById(e.column.headerId) !== null ? document.getElementById(e.column.headerId).clientWidth - 16 : 600);
        const diffHours = Math.abs(startDateRange.getTime() - endDateRange.getTime()) / 3600000;
        const diffMinutes = Math.abs(startDateRange.getTime() - endDateRange.getTime()) / 60000;
        const hourPixelWidth = (colWidth / diffHours);
        const minutePixelWidth = (colWidth / diffMinutes);

        const startTime = new Date(e.data.DateTimeDeparture);
        const endTime = new Date(e.data.DateTimeArrival);

        // nogal rare constructie, maar werkt
        let convStartDate = new Date();
        let offsetLeft = 0;
        let divWidth = 0;

        if (startTime.getHours() >= startHour) {
            convStartDate = new Date(new Date(new Date(new Date(startDateRange).setDate(startTime.getDate())).setMonth(startTime.getMonth())).setFullYear(startTime.getFullYear()));
            offsetLeft = ((Math.abs(startTime.getTime() - convStartDate.getTime()) / 60000) * minutePixelWidth) + paintOffset;
            divWidth = (Math.abs(endTime.getTime() - startTime.getTime()) / 60000) * minutePixelWidth;
        } else {
            convStartDate = new Date(new Date(new Date(new Date(startTime).setHours(0)).setMinutes(0)).setSeconds(0));
            offsetLeft = (hourPixelWidth * (24 - startHour)) +  ((Math.abs(startTime.getTime() - convStartDate.getTime()) / 60000) * minutePixelWidth) + paintOffset;
            divWidth = (Math.abs(endTime.getTime() - startTime.getTime()) / 60000) * minutePixelWidth;
        }

   
        let css = {};
        css.left = offsetLeft;
        css.width = divWidth;
        css.position = 'relative';

        if (e.data.UsePattern) {
            const searchRegExp = /\[rgba\]/gi;
            var patternColor = (e.data.PatternColorHex !== null ? e.data.PatternColorHex : '#00000033');
            if (e.data.PatternCssBackGround !== null) {
                css.background = e.data.PatternCssBackGround.replace(searchRegExp, patternColor);
            }
            if (e.data.PatternCssBackGroundImage !== null) {
                css.backgroundImage = e.data.PatternCssBackGroundImage.replace(searchRegExp, patternColor);
            }
            if (e.data.PatternCssBackGroundSize !== null) {
                css.backgroundSize = e.data.PatternCssBackGroundSize;
            }
            if (e.data.PatternCssBackGroundPosition !== null) {
                css.backgroundPosition = e.data.PatternCssBackGroundPosition;
            }
        }

        if (e.data.ColorHex !== null) {
            css.backgroundColor = e.data.ColorHex;
        }
        if (e.data.ForeColorHex !== null) {
            css.color = e.data.ForeColorHex;
        }

        css.zIndex = 0;
        css.height = '20px';
        css.display = 'flex';
        css.justifyContent = 'center';
        css.paddingTop = '2px';

        let cssStatus = {};
        cssStatus.left = offsetLeft + 10;
        cssStatus.width = '20px';
        cssStatus.position = 'relative';
        cssStatus.zIndex = 0;
        cssStatus.height = '20px';
        cssStatus.display = 'flex';
        cssStatus.justifyContent = 'center';
        cssStatus.paddingTop = '2px';

        let className = "";
        switch (e.data.StatusName.toUpperCase()) {
            case "WAITING":
                className = "dx-icon-clock"
                cssStatus.display = 'flex';
                break;
            case "DRIVING":
                className = "dx-icon-car"
                cssStatus.display = 'flex';
                break;
            case "FINISHED":
                className = "dx-icon-check"
                cssStatus.display = 'flex';
                break;
            case "CANCELLED":
                className = "dx-icon-minus"
                cssStatus.display = 'flex';
                break;
          default:
        }

        const divId = "status-" + e.data.Id;
        if (this.showTimeLineSwitch) {
            var dtNow = new Date();
            const convStartDateBG = new Date(new Date(new Date(new Date(startDateRange).setDate(dtNow.getDate())).setMonth(dtNow.getMonth())).setFullYear(dtNow.getFullYear()));
            const offsetLeftBG = ((Math.abs(dtNow.getTime() - convStartDateBG.getTime()) / 60000) * minutePixelWidth) + paintOffset;
            let cssTimeLine = {};
            cssTimeLine.left = -divWidth + offsetLeftBG;
            cssTimeLine.width = '2px';
            cssTimeLine.position = 'relative';
            cssTimeLine.backgroundColor = '#FF0000AA';
            cssTimeLine.zIndex = 1;
            cssTimeLine.height = '30px'

            return <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                <div style={css}>{e.data.DriverName}</div>
                <div style={cssTimeLine}></div>
                <div id={divId} style={cssStatus} onClick={() => this.showStatussesPopup(divId, e.data.Id, e.data.StatusName)}><span style={{ width: '40px' }}><i className={className} style={{ color: '#000000' }} /></span></div>
            </div>;
        } else {
            return <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                <div style={css}>{e.data.DriverName}</div>
                <div id={divId} style={cssStatus} onClick={() => this.showStatussesPopup(divId, e.data.Id, e.data.StatusName)}><span style={{ width: '40px' }}><i className={className} style={{ color: '#000000' }} /></span></div>
            </div>;
        }

        //if (new Date(e.data.Start).getHours() < 15) {
        //    const startTimeBG = new Date('2022-06-18T06:00:00.000');
        //    const endTimeBG = new Date('2022-06-18T20:00:00.000');
        //    // nogal rare constructie, maar werkt
        //    const convStartDateBG = new Date(new Date(new Date(new Date(startDateRange).setDate(startTimeBG.getDate())).setMonth(startTimeBG.getMonth())).setFullYear(startTimeBG.getFullYear()));
        //    const offsetLeftBG = ((Math.abs(startTimeBG.getTime() - convStartDateBG.getTime()) / 60000) * minutePixelWidth) + paintOffset;
        //    const divWidthBG = (Math.abs(endTimeBG.getTime() - startTimeBG.getTime()) / 60000) * minutePixelWidth;

        //    let css2 = {};
        //    css2.left = -divWidth; //+ offsetLeftBG;
        //    css2.width = colWidth //divWidthBG;
        //    css2.position = 'relative';
        //    css2.backgroundColor = '#FF000080';
        //    css2.zIndex = 0;
        //    css2.height = '28px'
        //    return <div style={{ display: 'flex', alignItems: 'center' }}>
        //        <div style={css}>{e.data.DriverName}</div>
        //        <div style={css2}></div>
        //    </div>;
        //}
    }

    showStatussesPopup = (divId, tripId, statusName) => {
        this.popOverStatussesInstance.option('target', "#" + divId);
        this.popOverStatussesInstance.option('title', tripId);
        this.processRadiogroupStatussesValueChanged = false;
        this.radioGroupPopOverStatussesInstance.option('value', statusName);
        this.processRadiogroupStatussesValueChanged = true;
    }

    render() {
        return (
            <React.Fragment>
                <div className="dx-card sdt-card-grid">
                    <DataGrid
                        ref={ref => (this.refDataGrid = ref)}
                        dataSource={this.state.dataSourceTrips}
                        keyExpr='Id'
                        height='calc(100vh - 159px)'
                        width='100%'
                        allowColumnReordering={false}
                        allowColumnResizing={false}
                        allowGrouping={false}
                        columnAutoWidth={false}
                        columnResizingMode='widget'
                        filterSyncEnabled={true}
                        focusedRowEnabled={false}
                        remoteOperations={true}
                        repaintChangesOnly={true}
                        showBorders={true}
                        showColumnLines={true}
                        showRowLines={true}
                        wordWrapEnabled={false}
                        noDataText='No shuttles found.'
                        onInitialized={this.gridOnInitialized}
                        onOptionChanged={this.gridOnOptionChanged}
                        onCellClick={this.gridOnCellClick}
                        onContentReady={this.gridOnContentReady}
                        onCellPrepared={this.gridOnCellPrepared}
                        onRowDblClick={this.gridOnRowDblClick}
                        onFocusedCellChanging={this.gridOnFocusedCellChanging}
                        onToolbarPreparing={this.gridOnToolbarPreparing}
                    >
                        <Sorting mode="multiple" />
                        <ColumnChooser enabled={true} />
                        <FilterPanel visible={this.state.useAdvancedFiltering} />
                        <FilterRow visible={this.state.showFilter} />
                        <GroupPanel visible={false} />
                        <HeaderFilter visible={true} />
                        <SearchPanel visible={true} />
                        <Paging enabled={false} defaultPageSize={20} />
                        <Scrolling
                            mode="standard"
                            preloadEnabled={true}
                            scrollByContent={true}
                            scrollByThumb={true}
                            showScrollbar='always'
                            useNative='false'
                        />
                        <Column
                            dataField='Id'
                            dataType='number'
                            caption='Id'
                            allowFiltering={false}
                            allowEditing={false}
                            fixed={true}
                            showInColumnChooser={false}
                            visible={false}
                        />
                        <Column
                            dataField='EventId'
                            dataType='number'
                            caption='EventId'
                            allowFiltering={false}
                            allowEditing={false}
                            fixed={true}
                            showInColumnChooser={false}
                            visible={false}
                        />
                        <Column
                            dataField='ArtistId'
                            dataType='number'
                            caption='ArtistId'
                            allowFiltering={false}
                            allowEditing={false}
                            fixed={true}
                            showInColumnChooser={false}
                            visible={false}
                        />
                        <Column
                            dataField='LocationDepartureId'
                            dataType='number'
                            caption='LocationDepartureId'
                            allowFiltering={false}
                            allowEditing={false}
                            fixed={true}
                            showInColumnChooser={false}
                            visible={false}
                        />
                        <Column
                            dataField='LocationArrivalId'
                            dataType='number'
                            caption='LocationArrivalId'
                            allowFiltering={false}
                            allowEditing={false}
                            fixed={true}
                            showInColumnChooser={false}
                            visible={false}
                        />
                        <Column
                            dataField='DriverId'
                            dataType='number'
                            caption='DriverId'
                            allowFiltering={false}
                            allowEditing={false}
                            fixed={true}
                            showInColumnChooser={false}
                            visible={false}
                        />
                        <Column
                            dataField='CarId'
                            dataType='number'
                            caption='CarId'
                            allowFiltering={false}
                            allowEditing={false}
                            fixed={true}
                            showInColumnChooser={false}
                            visible={false}
                        />
                        <Column
                            dataField='StatusId'
                            dataType='number'
                            caption='StatusId'
                            allowFiltering={false}
                            allowEditing={false}
                            fixed={true}
                            showInColumnChooser={false}
                            visible={false}
                        />
                        <Column
                            dataField='ColorId'
                            dataType='number'
                            caption='ColorId'
                            allowFiltering={false}
                            allowEditing={false}
                            fixed={true}
                            showInColumnChooser={false}
                            visible={false}
                        />
                        <Column
                            dataField='PatternId'
                            dataType='number'
                            caption='PatternId'
                            allowFiltering={false}
                            allowEditing={false}
                            fixed={true}
                            showInColumnChooser={false}
                            visible={false}
                        />
                        <Column
                            dataField='ColorHex'
                            dataType='string'
                            caption='ColorHex'
                            allowFiltering={false}
                            allowEditing={false}
                            fixed={true}
                            showInColumnChooser={false}
                            visible={false}
                        />
                        <Column
                            dataField='ForeColorHex'
                            dataType='string'
                            caption='ForeColorHex'
                            allowFiltering={false}
                            allowEditing={false}
                            fixed={true}
                            showInColumnChooser={false}
                            visible={false}
                        />
                        <Column
                            dataField='PatternColorHex'
                            dataType='string'
                            caption='PatternColorHex'
                            allowFiltering={false}
                            allowEditing={false}
                            fixed={true}
                            showInColumnChooser={false}
                            visible={false}
                        />
                        <Column
                            dataField='UsePattern'
                            dataType='number'
                            caption='UsePattern'
                            allowFiltering={false}
                            allowEditing={false}
                            fixed={true}
                            showInColumnChooser={false}
                            visible={false}
                        />
                        <Column
                            dataField='PatternCssBackGround'
                            dataType='string'
                            caption='PatternCssBackGround'
                            allowFiltering={false}
                            allowEditing={false}
                            fixed={true}
                            showInColumnChooser={false}
                            visible={false}
                        />
                        <Column
                            dataField='PatternCssBackGroundImage'
                            dataType='string'
                            caption='PatternCssBackGroundImage'
                            allowFiltering={false}
                            allowEditing={false}
                            fixed={true}
                            showInColumnChooser={false}
                            visible={false}
                        />
                        <Column
                            dataField='PatternCssBackGroundSize'
                            dataType='string'
                            caption='PatternCssBackGroundSize'
                            allowFiltering={false}
                            allowEditing={false}
                            fixed={true}
                            showInColumnChooser={false}
                            visible={false}
                        />
                        <Column
                            dataField='PatternCssBackGroundPosition'
                            dataType='string'
                            caption='PatternCssBackGroundPosition'
                            allowFiltering={false}
                            allowEditing={false}
                            fixed={true}
                            showInColumnChooser={false}
                            visible={false}
                        />
                        <Column
                            dataField='DateTimeDeparture'
                            dataType='datetime'
                            caption='DateTimeDeparture'
                            width={0}
                            allowFiltering={false}
                            allowResizing={false}
                            allowEditing={false}
                            fixed={true}
                            showInColumnChooser={true}
                            visible={true}
                        />
                        <Column
                            caption=''
                            width='20px'
                            allowFiltering={false}
                            allowEditing={false}
                            allowColumnResizing={false}
                            allowColumnReordering={false}
                            allowExporting={false}
                            fixed={true}
                            showInColumnChooser={false}
                        />
                        <Column
                            name='ActionColumnEdit'
                            caption=''
                            allowFiltering={false}
                            allowEditing={false}
                            allowColumnResizing={false}
                            allowColumnReordering={false}
                            allowExporting={false}
                            fixed={false}
                            showInColumnChooser={false}
                            visible={true}
                            width='40px'
                            alignment='center'
                            cellRender={this.renderEditTemplate}
                        />
                        <Column
                            name='ActionColumnDriverSheet'
                            caption=''
                            allowFiltering={false}
                            allowEditing={false}
                            allowColumnResizing={false}
                            allowColumnReordering={false}
                            allowExporting={false}
                            fixed={false}
                            showInColumnChooser={false}
                            visible={true}
                            width='40px'
                            alignment='center'
                            cellRender={this.renderDriverSheetTemplate}
                        />
                        <Column
                            name='ActionColumnCreateEmail'
                            caption=''
                            allowFiltering={false}
                            allowEditing={false}
                            allowColumnResizing={false}
                            allowColumnReordering={false}
                            allowExporting={false}
                            fixed={false}
                            showInColumnChooser={false}
                            visible={this.state.showEmailCol}
                            width='40px'
                            alignment='center'
                            cellRender={this.renderCreateEmailTemplate}
                        />
                        <Column
                            dataField='ArtistName'
                            dataType='string'
                            caption='Artist'
                            minWidth={175}
                            sortIndex={0}
                            sortOrder='Asc'
                            width={175}
                            allowFiltering={true}
                            allowResizing={false}
                            allowEditing={false}
                            fixed={false}
                            showInColumnChooser={true}
                            visible={true}
                        />
                        <Column
                            dataField='LocationDepartureName'
                            dataType='string'
                            caption='Departure'
                            minWidth={175}
                            width={175}
                            allowFiltering={true}
                            allowResizing={false}
                            allowEditing={false}
                            fixed={false}
                            showInColumnChooser={true}
                            visible={true}
                        />
                        <Column
                            dataField='LocationArrivalName'
                            dataType='string'
                            caption='Arrival'
                            minWidth={175}
                            width={175}
                            allowFiltering={true}
                            allowResizing={false}
                            allowEditing={false}
                            fixed={false}
                            showInColumnChooser={true}
                            visible={true}
                        />
                        <Column
                            dataField='CarName'
                            dataType='string'
                            caption='Car'
                            minWidth={150}
                            width={175}
                            allowFiltering={true}
                            allowResizing={false}
                            allowEditing={false}
                            fixed={false}
                            showInColumnChooser={true}
                            visible={false}
                        />
                        <Column
                            dataField='StatusName'
                            dataType='string'
                            caption='Status'
                            minWidth={150}
                            width={175}
                            allowFiltering={true}
                            allowResizing={false}
                            allowEditing={false}
                            fixed={false}
                            showInColumnChooser={true}
                            visible={false}
                        />
                        <Column
                            dataField='Trailer'
                            dataType='boolean'
                            caption='T'
                            minWidth={55}
                            width={55}
                            allowFiltering={true}
                            allowResizing={false}
                            allowEditing={false}
                            fixed={false}
                            showInColumnChooser={true}
                            visible={true}
                        />
                        <Column
                            name='ActionColumnDelete'
                            caption=''
                            allowFiltering={false}
                            allowEditing={false}
                            allowColumnResizing={false}
                            allowColumnReordering={false}
                            allowExporting={false}
                            fixed={false}
                            showInColumnChooser={false}
                            visible={true}
                            width='40px'
                            alignment='center'
                            cellRender={this.renderDeleteTemplate}
                        />
                        <Column
                            name='columnTimings'
                            caption=''
                            allowFiltering={false}
                            allowEditing={false}
                            allowColumnResizing={true}
                            allowColumnReordering={false}
                            allowExporting={false}
                            fixed={false}
                            showInColumnChooser={false}
                            visible={true}
                            alignment='center'
                            width='auto'
                            cellRender={this.renderTimingsTemplate}
                            headerCellRender={this.renderTimingsHeader}
                        />
                    </DataGrid>
                    <Tooltip ref={ref => this.tooltip = ref} position={{ my: 'bottom center', at: 'top center', collision: 'fit flip' }}>
                        <div className='sdt-tooltip-content'>
                            <div className="sdt-tooltip-item">{this.state.toolTipData !== null ? this.state.toolTipData.ArtistName : ''}</div>
                            <div className="sdt-tooltip-item">{this.state.toolTipData !== null ? this.state.toolTipData.LocationDepartureName + ' - ' + this.state.toolTipData.LocationArrivalName : ''}</div>
                            <div className="sdt-tooltip-item">{this.state.toolTipData !== null ? this.getTiming() : ''}</div>
                            <div className="sdt-tooltip-item">{this.state.toolTipData !== null ? this.state.toolTipData.Pax : ''}</div>
                            {/*<div className="sdt-tooltip-item-status">{this.state.toolTipData !== null ? this.state.toolTipData.StatusName : ''}</div>*/}
                        </div>
                    </Tooltip>

                    <PopupShuttlesDriverSheet
                        ref={ref => this.refPopupShuttlesDriverSheet = ref}
                        onInitialized={this.popupShuttlesDriverSheet_OnInitialized}
                    />
                    <PopupShuttlesEmail
                        ref={ref => this.refPopupShuttlesEmail = ref}
                        onInitialized={this.popupShuttlesEmail_OnInitialized}
                    />
                </div>
                <Popover
                    onInitialized={this.popOverStatusses_OnInitialized}
                    showEvent="click"
                    hideEvent="dblclick"
                    position="top"
                    width={150}
                    showTitle={false}
                >
                    <RadioGroup
                        ref={ref => this.refRadioGroup = ref}
                        items={this.tripStatusses}
                        layout="vertical"
                        onInitialized={this.radioGroupPopOverStatusses_Initialized}
                        onValueChanged={this.radioGroupPopOverStatusses_OnValueChanged}
                    />
                </Popover>
            </React.Fragment>
        );
    }

}

export default ShuttlesDataGridTrips;