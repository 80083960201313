import globalConfig from '../../config/global.js';

class ServiceArtists {
    ////////////////////////////////////////////////////
    // ARTISTS
    ////////////////////////////////////////////////////

    static getAllArtistFunctions = async () => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Artists/GetAllArtistFunctions") // return null or data object
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static getArtistNamesByGigDateIds = async (ids) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Artists/GetArtistNamesByGigDateIds?gigDateIds=" + encodeURIComponent(JSON.stringify(ids))) // return null or data object
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }


    static getAllArtistsDropDownByEventId = async (eventId, ridersShuttlesType) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Artists/GetAllArtistsDropDownByEventId?eventId=" + encodeURIComponent(eventId) + "&ridersShuttlesType=" + encodeURIComponent(ridersShuttlesType)) // return null or data object
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static checkIfArtistCanBeDeleted = async (id) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Artists/CheckIfArtistCanBeDeleted?Id=" + encodeURIComponent(id))
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static updateArtistName = async (artistsInfoModel) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Artists/UpdateArtistName", {
            method: 'POST',
            body: JSON.stringify(artistsInfoModel),
            headers: {
                'Content-type': 'application/json; charset=UTF-8'
            }
        })
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(error => {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static updateArtist = async (artistsInfoModel) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Artists/UpdateArtist", {
            method: 'POST',
            body: JSON.stringify(artistsInfoModel),
            headers: {
                'Content-type': 'application/json; charset=UTF-8'
            }
        })
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(error => {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static updateArtistFinished = async (artistsInfoModel) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Artists/UpdateArtistFinished", {
            method: 'POST',
            body: JSON.stringify(artistsInfoModel),
            headers: {
                'Content-type': 'application/json; charset=UTF-8'
            }
        })
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(error => {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static updateArtistArtistType = async (artistsInfoModel) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Artists/UpdateArtistArtistType", {
            method: 'POST',
            body: JSON.stringify(artistsInfoModel),
            headers: {
                'Content-type': 'application/json; charset=UTF-8'
            }
        })
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(error => {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static addArtist = async (artistsInfoModel) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Artists/AddArtist", {
            method: 'POST',
            body: JSON.stringify(artistsInfoModel),
            headers: {
                'Content-type': 'application/json; charset=UTF-8'
            }
        })
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(error => {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static deleteArtist = async (id) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Artists/DeleteArtist", {
            method: 'POST',
            body: JSON.stringify(id),
            headers: {
                'Content-type': 'application/json; charset=UTF-8'
            }
        })
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(error => {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static getArtistInfoByEventId = async (eventId, ridersShuttlesType) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Artists/GetArtistInfoByEventId?eventId=" + encodeURIComponent(eventId) + "&ridersShuttlesType=" + encodeURIComponent(ridersShuttlesType)) // return null or data object
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static getArtistInfoById = async (eventId, id, ridersShuttlesType) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Artists/GetArtistInfoById?eventId=" + encodeURIComponent(eventId) + "&id=" + encodeURIComponent(id) + "&ridersShuttlesType=" + encodeURIComponent(ridersShuttlesType)) // return null or data object
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static getArtistTypesInfo = async () => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Artists/GetArtistTypesInfo")
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static getSubArtistNamesByEventIdArtistId = async (eventId, artistId) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Artists/GetSubArtistNamesByEventIdArtistId?eventId=" + encodeURIComponent(eventId) + "&artistId=" + encodeURIComponent(artistId))
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static updateSubArtistName = async (subArtistsInfoModel) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Artists/UpdateSubArtistName", {
            method: 'POST',
            body: JSON.stringify(subArtistsInfoModel),
            headers: {
                'Content-type': 'application/json; charset=UTF-8'
            }
        })
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(error => {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static addSubArtist = async (subArtistsInfoModel) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Artists/AddSubArtist", {
            method: 'POST',
            body: JSON.stringify(subArtistsInfoModel),
            headers: {
                'Content-type': 'application/json; charset=UTF-8'
            }
        })
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(error => {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static checkIfSubArtistCanBeDeleted = async (id) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Artists/CheckIfSubArtistCanBeDeleted?Id=" + encodeURIComponent(id))
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static deleteSubArtist = async (id) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Artists/DeleteSubArtist", {
            method: 'POST',
            body: JSON.stringify(id),
            headers: {
                'Content-type': 'application/json; charset=UTF-8'
            }
        })
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(error => {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static getSubArtistNamesByArtistId = async (id) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Artists/GetSubArtistNamesByArtistId?artistId=" + encodeURIComponent(id)) // return null or data object
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }
}

export default ServiceArtists;
