import globalConfig from '../../config/global.js';

class ServiceRiders {

    static getSubArtistsColorsByEventIdGigDateIds = async (eventId, gigDateIds, subArtistColorOrigin) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Riders/GetSubArtistsColorsByEventIdGigDateIds?eventId=" + encodeURIComponent(eventId) + "&gigDateIds=" + encodeURIComponent(gigDateIds) + "&subArtistColorOrigin=" + encodeURIComponent(subArtistColorOrigin))
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static getRidersByEventIdGigDateIdsArtistIds = async (eventId, gigDateIds, artistIds, useBlanksForZeroes, productColorOrigin) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Riders/GetRidersByEventIdGigDateIdsArtistIds?eventId=" + encodeURIComponent(eventId) + "&gigDateIds=" + encodeURIComponent(gigDateIds) + "&artistIds=" + encodeURIComponent(artistIds) + "&useBlanksForZeroes=" + encodeURIComponent(useBlanksForZeroes) + "&productColorOrigin=" + encodeURIComponent(productColorOrigin))
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static updateRiderAmount = async (model) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Riders/UpdateRiderAmount", {
            method: 'POST',
            body: JSON.stringify(model),
            headers: {
                'Content-type': 'application/json; charset=UTF-8'
            }
        })
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(error => {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static updateRiderComment = async (model) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Riders/UpdateRiderComment", {
            method: 'POST',
            body: JSON.stringify(model),
            headers: {
                'Content-type': 'application/json; charset=UTF-8'
            }
        })
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(error => {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static getDayOverView = async (eventId, gigDateId, useBlanksForZeroes, productColorOrigin) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Riders/GetDayOverView?eventId=" + encodeURIComponent(eventId) + "&gigDateId=" + encodeURIComponent(gigDateId) + "&useBlanksForZeroes=" + encodeURIComponent(useBlanksForZeroes) + "&productColorOrigin=" + encodeURIComponent(productColorOrigin))
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static getDayOverViewByShopId = async (eventId, gigDateId, shopId, useBlanksForZeroes, productColorOrigin) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Riders/GetDayOverViewByShopId?eventId=" + encodeURIComponent(eventId) + "&gigDateId=" + encodeURIComponent(gigDateId) + "&shopId=" + encodeURIComponent(shopId) + "&useBlanksForZeroes=" + encodeURIComponent(useBlanksForZeroes) + "&productColorOrigin=" + encodeURIComponent(productColorOrigin))
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static getArtistRider = async (eventId, gigDateIds, artistId, productID, useBlanksForZeroes, productColorOrigin) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Riders/GetArtistRider?eventId=" + encodeURIComponent(eventId) + "&gigDateIds=" + encodeURIComponent(gigDateIds) + "&artistId=" + encodeURIComponent(artistId) + "&productID=" + encodeURIComponent(productID) + "&useBlanksForZeroes=" + encodeURIComponent(useBlanksForZeroes) + "&productColorOrigin=" + encodeURIComponent(productColorOrigin))
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static getArtistShopRider = async (eventId, gigDateIds, artistId, shopId, productId, useBlanksForZeroes, productColorOrigin) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Riders/GetArtistShopRider?eventId=" + encodeURIComponent(eventId) + "&gigDateIds=" + encodeURIComponent(gigDateIds) + "&shopId=" + encodeURIComponent(shopId) + "&artistId=" + encodeURIComponent(artistId) + "&productId=" + encodeURIComponent(productId) + "&useBlanksForZeroes=" + encodeURIComponent(useBlanksForZeroes) + "&productColorOrigin=" + encodeURIComponent(productColorOrigin))
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static getShopRider = async (eventId, gigDateIds, shopId, productId, useBlanksForZeroes, productColorOrigin) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Riders/GetShopRider?eventId=" + encodeURIComponent(eventId) + "&gigDateIds=" + encodeURIComponent(gigDateIds) + "&shopId=" + encodeURIComponent(shopId) + "&productId=" + encodeURIComponent(productId) + "&useBlanksForZeroes=" + encodeURIComponent(useBlanksForZeroes) + "&productColorOrigin=" + encodeURIComponent(productColorOrigin))
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static getSubArtistRider = async (eventId, gigDateIds, subArtistId, productID, useBlanksForZeroes, productColorOrigin) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Riders/GetSubArtistRider?eventId=" + encodeURIComponent(eventId) + "&gigDateIds=" + encodeURIComponent(gigDateIds) + "&subArtistId=" + encodeURIComponent(subArtistId) + "&productID=" + encodeURIComponent(productID) + "&useBlanksForZeroes=" + encodeURIComponent(useBlanksForZeroes) + "&productColorOrigin=" + encodeURIComponent(productColorOrigin))
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static getSubArtistShopRider = async (eventId, gigDateIds, subArtistId, shopId, productID, useBlanksForZeroes, productColorOrigin) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Riders/GetSubArtistShopRider?eventId=" + encodeURIComponent(eventId) + "&gigDateIds=" + encodeURIComponent(gigDateIds) + "&shopId=" + encodeURIComponent(shopId) + "&subArtistId=" + encodeURIComponent(subArtistId) + "&productID=" + encodeURIComponent(productID) + "&useBlanksForZeroes=" + encodeURIComponent(useBlanksForZeroes) + "&productColorOrigin=" + encodeURIComponent(productColorOrigin))
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static getTotalsList = async (eventId) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Riders/GetTotalsList?eventId=" + encodeURIComponent(eventId))
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }
}

export default ServiceRiders;
