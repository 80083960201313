import React from 'react';
import ServiceArtists from '../../../../../api/services/ServiceArtists'
import DataGrid, {
    RemoteOperations, MasterDetail, Editing, ColumnChooser, GroupPanel, FilterPanel, FilterRow, HeaderFilter, Button, Paging, Pager, Scrolling, Column, SearchPanel, KeyboardNavigation, Export, StateStoring, Sorting, Lookup, Selection
} from 'devextreme-react/data-grid';
import * as dayJS from 'dayjs';
import HxToast from '../../../../custom-components/hx-toast/HxToast'
import HxDialog from '../../../../custom-components/hx-dialog/HxDialog'
import { HubConnectionBuilder } from '@microsoft/signalr';
import PopupSubArtistName from './PopupSubArtistName';
import { Button as DXButton } from 'devextreme-react/button';

import './DetailTemplate.scss';

class DetailTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSourceSubArtists: [],
        };

        this.gridInstance = null;

        this.buttonNew_OnClick = this.buttonNew_OnClick.bind(this);

        this.popupSubArtistNameInstance = null;

        this.hubConnection = null;

        this.loadDataSourceSubArtists();
    }

    componentDidMount = async () => {

        this.hubConnection = new HubConnectionBuilder().withUrl("/artistsHub").build();

        await this.hubConnection.start()
            .then(function () {

            })
            .catch(function (err) {
                return console.error(err.toString());
            });

        this.hubConnection.on("ReceiveMessageInfoSubArtist", await this.updatePushedDataSubArtistInfo);
        this.hubConnection.on("ReceiveMessageAddedSubArtist", await this.updatePushedDataAddedSubArtist);
        this.hubConnection.on("ReceiveMessageDeletedSubArtist", await this.updatePushedDataDeletedSubArtist);
    }

    loadDataSourceSubArtists = async () => {
        var data = await ServiceArtists.getSubArtistNamesByEventIdArtistId(parseInt(sessionStorage.getItem("currentEventId"), 10), this.props.data.data.Id);
        if (data !== null && !data.hasOwnProperty("error")) {
            this.setState({ dataSourceSubArtists: data })
        } else {
          //  this.props.onConnectionError();  // callback;
        }
    }

    grid_OnInitialized = (e) => {
        this.gridInstance = e.component;
    }

    grid_OnRowDblClick = (e) => {
        this.refPopupSubArtistName.setValues(e.data.Id, e.data.GigDateID, e.data.Name, e.data.Tag, 'update');
        this.popupSubArtistNameInstance.show();
    }

    grid_OnContentReady = (e) => {
        if (this.gridInstance !== undefined && this.state.dataSourceSubArtists.length > 0) {
            this.gridInstance.option('focusedRowIndex', 0);
        }
    }

    gridOnCellPrepared = (e) => {
        if (e.rowType === 'data') {
            if (e.column.dataField === 'GigDate') {
                if (e.cellElement.innerText !== '') {
                    let myDate = dayJS(e.cellElement.innerText).format('ddd DD/MM/YY');
                    e.cellElement.innerText = myDate;
                }
            }
        }
    }

    buttonNew_OnClick = () => {
        this.refPopupSubArtistName.setValues(-1, -1, '', '', 'save');
        this.popupSubArtistNameInstance.show();
    }

    buttonEdit_OnClick = (e) => {
        this.refPopupSubArtistName.setValues(e.row.data.Id, e.row.data.GigDateID, e.row.data.Name, e.row.data.Tag, 'update');
        this.popupSubArtistNameInstance.show();
    }

    buttonDelete_OnClick = async (e) => {
        await this.deleteSubArtist(e.row.data.Id, e.row.data.Name);
        await this.loadDataSourceSubArtists();
    }

    popupSubArtistName_OnInitialized = (instance) => {
        this.popupSubArtistNameInstance = instance;
    }

    popupSubArtistName_OnUpdate = async (id, gigDateId, name, tag, editMode) => {
        switch (editMode.toLowerCase().trim()) {
            case 'save':
                var model = {
                    Id: -1,
                    EventID: parseInt(sessionStorage.getItem("currentEventId"), 10),
                    ArtistID: this.props.data.data.Id,
                    GigDateID: gigDateId,
                    Name: name,
                    Tag: tag,
                }
                await this.onAddSubArtistCallBack(model);
                break;
            case 'update':
                var model = {
                    Id: id,
                    EventID: parseInt(sessionStorage.getItem("currentEventId"), 10),
                    ArtistID: this.props.data.data.Id,
                    GigDateID: gigDateId,
                    Name: name,
                    Tag: tag,
                }
                await this.onUpdateSubArtistNameCallBack(model);
                break;
            default:
                break;
        }
    }

    onUpdateSubArtistNameCallBack = async (subArtistInfoModel) => {
        await this.updateSubArtistName(subArtistInfoModel);
        await this.loadDataSourceSubArtists();
    }

    onAddSubArtistCallBack = async (subArtistInfoModel) => {
        var id = await this.addSubArtist(subArtistInfoModel);
        await this.loadDataSourceSubArtists();
    }

    updateSubArtistName = async (subArtistInfoModel) => {
        var data = await ServiceArtists.updateSubArtistName(subArtistInfoModel);
        if (data !== null) {
            if (!data.hasOwnProperty("error")) {
                if (data === true) {
                    this.hubConnection
                        .invoke("SendUpdateInfoSubArtist", subArtistInfoModel.Id, subArtistInfoModel.GigDateID, subArtistInfoModel.Name)
                        .catch(function (err) {
                            return console.error(err.toString());
                        });
                    HxToast.showToast('Subartist updated.', 'success', 'bottom right', 'up-stack');
               } else {
                    HxToast.showToast('Update subartist failed!', 'error', 'bottom right', 'up-stack');
                }
            } else {
                this.setErrorHeader(true);
            }
        } else {
            HxToast.showToast('Update subartist failed!', 'error', 'bottom right', 'up-stack');
        }
    }

    addSubArtist = async (subArtistInfoModel) => {
        var data = await ServiceArtists.addSubArtist(subArtistInfoModel);
        if (data !== null) {
            if (!data.hasOwnProperty("error")) {
                if (data !== -1) {
                    this.hubConnection
                        .invoke("SendUpdateInfoAddedSubArtist", subArtistInfoModel.Id)
                        .catch(function (err) {
                            return console.error(err.toString());
                        });
                    HxToast.showToast('Subartist saved.', 'success', 'bottom right', 'up-stack');
                } else {
                    HxToast.showToast('Save subartist failed!', 'error', 'bottom right', 'up-stack');
                }
            } else {
                this.setErrorHeader(true);
            }
        } else {
            HxToast.showToast('Save subartist failed!', 'error', 'bottom right', 'up-stack');
        }

        return data;
    }

    deleteSubArtist = async (id, name) => {
        var dataCanBeDeleted = await ServiceArtists.checkIfSubArtistCanBeDeleted(id);
        if (dataCanBeDeleted) {
            var result = await HxDialog.showDialogYesNo('Delete subartist', 'Delete ' + name + '?')
            if (result === true) {
                var data = await ServiceArtists.deleteSubArtist(id);
                if (data !== null) {
                    if (!data.hasOwnProperty("error")) {
                        if (data === true) {
                            this.hubConnection
                                .invoke("SendUpdateInfoDeletedSubArtist", id)
                                .catch(function (err) {
                                    return console.error(err.toString());
                                });
                            HxToast.showToast('Subartist deleted.', 'success', 'bottom right', 'up-stack');
                        } else {
                            HxToast.showToast('Delete subartist failed!', 'error', 'bottom right', 'up-stack');
                        }
                    } else {
                        this.setErrorHeader(true);
                    }
                } else {
                    HxToast.showToast('Delete subartist failed!', 'error', 'bottom right', 'up-stack');
                }
            }
        } else {
            HxToast.showToast('Artist can not be deleted.', 'Warning', 'bottom right', 'up-stack');
        }
    }


    // update cells modified by other users (collaborative editing, SignalR)
    updatePushedDataSubArtistInfo = async (subArtistId, gigDateId, name) => {
        await this.loadDataSourceSubArtists();
    }

    updatePushedDataAddedSubArtist = async (subArtistId) => {
        await this.loadDataSourceSubArtists();
    }

    updatePushedDataDeletedSubArtist = async (subArtistId) => {
        await this.loadDataSourceSubArtists();
    }

    render() {
        return (
            <React.Fragment>
                <div className='detail-button'>
                    <DXButton
                        text="Add sub"
                        icon="add"
                        onClick={this.buttonNew_OnClick} />
                </div>
                <div>
                    <DataGrid
                        allowColumnReordering={false}
                        allowColumnResizing={false}
                        allowFiltering={false}
                        dataSource={this.state.dataSourceSubArtists}
                        keyExpr='Id'
                        focusedRowEnabled={true}
                        noDataText='No subs found.'
                        remoteOperations={true}
                        showBorders={true}
                        showColumnLines={true}
                        showColumnHeaders={false}
                        showRowLines={true}
                        wordWrapEnabled={true}
                        onInitialized={this.grid_OnInitialized}
                        onRowDblClick={this.grid_OnRowDblClick}
                        onContentReady={this.grid_OnContentReady}
                        onCellPrepared={this.gridOnCellPrepared}
                    >
                        <ColumnChooser enabled={false} />
                        <FilterRow visible={false} />
                        <GroupPanel visible={false} />
                        <HeaderFilter
                            visible={false}
                        />
                        <Selection
                            mode="single"
                            selectAllMode="allPages"
                            showCheckBoxesMode={false}
                        />
                        <Scrolling mode="standard" />
                        <Column
                            dataField='Id'
                            caption='Id'
                            allowFiltering={false}
                            allowEditing={false}
                            visible={false}
                        />
                        <Column
                            dataField='GigDate'
                            caption='Date'
                            width={150}
                            sortIndex={0}
                            sortOrder='Asc'
                            allowFiltering={false}
                            allowEditing={false}
                        />
                        <Column
                            dataField='Tag'
                            caption='Tag'
                            sortIndex={0}
                            sortOrder='Asc'
                            width={100}
                            allowFiltering={false}
                            allowEditing={false}
                        />
                        <Column
                            dataField='Name'
                            caption='Name'
                            sortIndex={0}
                            sortOrder='Asc'
                            allowFiltering={false}
                            allowEditing={false}
                        />
                        <Column
                            caption='Action'
                            type='buttons'
                        >
                            <Button
                                name='buttonEdit'
                                text='Edit'
                                icon='edit'
                                onClick={this.buttonEdit_OnClick}
                            />
                            <Button
                                name='buttonDelete'
                                text='Delete sub'
                                icon='remove'
                                onClick={this.buttonDelete_OnClick}
                            />
                        </Column>
                    </DataGrid>
                </div>

                <PopupSubArtistName
                    ref={ref => (this.refPopupSubArtistName = ref)}
                    title='Save subartist ...'
                    onInitialized={this.popupSubArtistName_OnInitialized}
                    onUpdate={this.popupSubArtistName_OnUpdate}
                />
            </React.Fragment>
        );
    }
}

export default DetailTemplate;