import React from 'react';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { Popup } from 'devextreme-react/popup';
import TextBox from 'devextreme-react/text-box';
import Validator, { CustomRule, ValidationRule } from 'devextreme-react/validator';
import HtmlEditor from 'devextreme-react/html-editor';
import DataGrid, {
    RemoteOperations, Editing, ColumnChooser, GroupPanel, FilterPanel, FilterRow, HeaderFilter, Paging, Pager, Scrolling, Column, SearchPanel, KeyboardNavigation, Export, StateStoring, Sorting, Toolbar, Item
} from 'devextreme-react/data-grid';
import { Button } from 'devextreme-react/button';
import { ValidationGroup } from 'devextreme-react/validation-group';
import { confirm } from 'devextreme/ui/dialog';
import HxToast from '../../../../custom-components/hx-toast/HxToast';

import PopupShuttlesAdminRelationsEditArtistContact from '../../shuttles-admin-relations/sub-components/PopupShuttlesAdminRelationsEditArtistContact'
import PopupPickContact from './PopupPickContact'

import ServiceRelations from '../../../../../api/services/ServiceRelations';
import ServiceArtists from '../../../../../api/services/ServiceArtists';

import './PopupArtistNameShuttles.scss'


// props
//      title: popup title (edit/save)
//
//      setValues: callable
//
//      onInitialized: callback
//      onUpdate: callback

class PopupArtistNameShuttles extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dataSourceArtistContacts: [],
        };

        this.popupInstance = null;

        this.textBoxNameInstance = null;
        this.htmlEditorNotesInstance = null;

        this.popupShuttlesAdminRelationsEditArtistContactInstance = null;
        this.popupPickContactInstance = null;
        this.dataGridInstance = null;

        this.validationGroupInstance = null;

        this.artistId = -1;
        this.artistEditMode = '';
        this.artistName = '';

        this.buttonUpdateInstance = null;

        this.hubConnection = null;
        this.hubConnectionArtists = null;
   }

    // CALLBACKS
    onInitialized = (instance) => {
        if (this.props.onInitialized) {
            this.props.onInitialized(instance);  // callback
        }
    }

    onUpdate = (artistId, name, notes, editMode) => {
        if (this.props.onUpdate) {
            this.props.onUpdate(artistId, name, notes, editMode);  // callback
        }
    }

    // CALLABLES
    setValues = (id, name, notes, editMode) => {
        this.artistId = id;
        this.artistName = name;
        this.textBoxNameInstance.option('value', name);
        this.htmlEditorNotesInstance.option('value', notes);
        this.artistEditMode = editMode.toLowerCase().trim();
    }

     // EVENTS
    componentDidMount = async () => {
        this.hubConnection = new HubConnectionBuilder().withUrl("/relationsHub").build();
        await this.hubConnection.start()
            .then(function () {

            })
            .catch(function (err) {
                return console.error(err.toString());
            });

        this.hubConnectionArtists = new HubConnectionBuilder().withUrl("/artistsHub").build();
        await this.hubConnectionArtists.start()
            .then(function () {

            })
            .catch(function (err) {
                return console.error(err.toString());
            });

        this.hubConnection.on("ReceiveMessageAddedRelation", await this.updatePushedDataAddedRelation);
    }

    // DATA
    loadDataSourceArtistContacts = async (artistId) => {
        var data = await ServiceRelations.getAllArtistRelationsByArtistId(artistId);
        if (data !== null && !data.hasOwnProperty("error")) {
            this.setState({ dataSourceArtistContacts : data });
        } else {
            //this.onLoadError();  // callback
        }
    }

    // POPUP
    popup_OnInitialized = (e) => {
        this.popupInstance = e.component;
        this.onInitialized(e.component);
    }

    popup_OnShowing = (e) => {
        if (this.artistEditMode == "save") {
            this.validationGroupInstance.reset();
        }
        this.popupInstance.option('title', (this.props.title ? this.props.title : ''))
        this.textBoxNameInstance.focus();
    }

    popup_OnShown = async (e) => {
        await this.loadDataSourceArtistContacts(this.artistId);
        switch (this.artistEditMode) {
            case 'save':
                this.popupInstance.option('title', 'Add new artist');
                break;
            case 'update':
                this.popupInstance.option('title', 'Update ' + this.artistName);
                break;
            default:
                break;
        }
        this.textBoxNameInstance.focus();
    }


    // TEXTBOX
    textBoxName_OnInitialized = (e) => {
        this.textBoxNameInstance = e.component;
    }

    // HTML EDITOR
    htmlEditorNotes_OnInitialized = (e) => {
        this.htmlEditorNotesInstance = e.component;
    }

    // BUTTON
    buttonUpdate_OnInitialized = (e) => {
        this.buttonUpdateInstance = e.component;
    }

    buttonUpdate_OnClick = (e) => {
        this.validationGroupInstance.validate();

        if (this.textBoxNameInstance.option('isValid') === true) {
            this.onUpdate(this.artistId, this.textBoxNameInstance.option('value').trim(), this.htmlEditorNotesInstance.option('value'), this.artistEditMode);
            this.popupInstance.hide();
        } else {
            this.textBoxNameInstance.focus();
        }
    }

    // VALIDATION GROUP
    validationGroup_OnInitialized = (e) => {
        this.validationGroupInstance = e.component;
    }

    // GRID CONTACTS
    gridOnInitialized = (e) => {
        this.dataGridInstance = e.component;
    }

    // BUTTON ADD CONTACT
    buttonAddExistingArtistContact_OnClick = async (e) => {
        if (this.artistId == -1) {
            const result = await confirm('Artist must be saved first', "");
            if (result) {
                this.validationGroupInstance.validate();

                if (this.textBoxNameInstance.option('isValid') === true) {
                    var model = {
                        Id: -1,
                        EventId: parseInt(sessionStorage.getItem("currentEventId"), 10),
                        ColorId: null,
                        ArtistTypeId: null,
                        Name: this.textBoxNameInstance.option('value').trim(),
                        Notes: this.htmlEditorNotesInstance.option('value'),
                        UseInRiders: false,
                        UseInShuttles: true,
                    }
                    var id = await this.addArtist(model);
                    if (id !== -1) {
                        this.artistId = id;
                        this.artistEditMode = 'update'
                        this.popupInstance.option('title', 'Update ' + model.Name);
                    }
                    this.popupPickContactInstance.show();
                } else {
                    this.textBoxNameInstance.focus();
                }
            }
        } else {
            this.popupPickContactInstance.show();
        }

    }

    buttonAddArtistContact_OnClick = async (e) => {
        if (this.artistId == -1) {
            const result = await confirm('Artist must be saved first', "");
            if (result) {
                this.validationGroupInstance.validate();

                if (this.textBoxNameInstance.option('isValid') === true) {
                    var model = {
                        Id: -1,
                        EventId: parseInt(sessionStorage.getItem("currentEventId"), 10),
                        ColorId: null,
                        ArtistTypeId: null,
                        Name: this.textBoxNameInstance.option('value').trim(),
                        Notes: this.htmlEditorNotesInstance.option('value'),
                        UseInRiders: false,
                        UseInShuttles: true,
                    }
                    var id = await this.addArtist(model);
                    if (id !== -1) {
                        this.artistId = id;
                        this.artistEditMode = 'update'
                        this.popupInstance.option('title', 'Update ' + model.Name);
                    }
                    this.popupShuttlesAdminRelationsEditArtistContactInstance.show();
                } else {
                    this.textBoxNameInstance.focus();
                }
            }
        } else {
            this.popupShuttlesAdminRelationsEditArtistContactInstance.show();
        }
    }

    addArtist = async (artistInfoModel) => {
        let retValue = -1;
        var data = await ServiceArtists.addArtist(artistInfoModel);
        if (data !== null) {
            if (!data.hasOwnProperty("error")) {
                if (data !== -1) {
                    this.hubConnectionArtists
                        .invoke("SendUpdateInfoAddedArtistShuttles", artistInfoModel.Id)
                        .catch(function (err) {
                            return console.error(err.toString());
                        });
                    HxToast.showToast('Artist saved.', 'success', 'bottom right', 'up-stack');
                    retValue = data;
                } else {
                    HxToast.showToast('Save artist failed!', 'error', 'bottom right', 'up-stack');
                }
            } else {
                //this.setErrorHeader(true);
            }
        } else {
            HxToast.showToast('Save artist failed!', 'error', 'bottom right', 'up-stack');
        }

        return retValue;
    }

    // POPUP ARTIST CONTACTS
    popupShuttlesAdminRelationsEditArtistContact_OnInitialized = (instance) => {
        this.popupShuttlesAdminRelationsEditArtistContactInstance = instance;
    }

    popupShuttlesAdminRelationsEditArtistContact_OnUpdate = async (success) => {
        if (success) {
            HxToast.showToast('Artist contact added successfully', 'success', 'bottom right', 'up-stack');
            this.hubConnection
                .invoke("SendUpdateInfoAddedRelation")
                .catch(function (err) {
                    return console.error(err.toString());
                });
        } else {
            HxToast.showToast('Failed to add artist contact', 'error', 'bottom right', 'up-stack');
        }

        await this.loadDataSourceArtistContacts(this.artistId);
    }


    // POPUP PICK CONTACTS
    popupPickContact_OnInitialized = (instance) => {
        this.popupPickContactInstance = instance;
    }

    popupPickContact_OnUpdate = async (relationIds) => {

        //relationIds.forEach((id) => {
        //    let found = this.state.dataSourceArtistContacts.find(x => x.RelationId == id)
        //    if (found) {

        //    }
        //});

        var dsArtistRelations = [];
        for (var i = 0; i < relationIds.length; i++) {
            dsArtistRelations.push({
                ArtistId: this.artistId,
                RelationId: relationIds[i],
                FunctionId: 1,  // TODO REMOVE HARDCODED
                ArtistName: "",
                FunctionName: "",
                EmailTo: false,
                EmailCC: true,
                SortOrder: i,
            })
        }
        var resultArtistRelations = await ServiceRelations.addArtistRelations(dsArtistRelations);

        await this.loadDataSourceArtistContacts(this.artistId);
    }

    // HUB
    updatePushedDataAddedRelation = async () => {
        await this.loadDataSourceArtistContacts(this.artistId);
        this.dataGridInstance.refresh();
    }

    render() {
        return (
            <React.Fragment>
                <Popup
                    dragEnabled={false}
                    hideOnOutsideClick={false}
                    showCloseButton={true}
                    showTitle={true}
                    width={1000}
                    height='auto'
                    onInitialized={this.popup_OnInitialized}
                    onShown={this.popup_OnShown}
                    onShowing={this.popup_OnShowing}
                >
                    <ValidationGroup onInitialized={this.validationGroup_OnInitialized}>
                        <div>
                            <div className="pas-dx-field">
                                <div className="dx-field-label pas-label">Name*</div>
                                <div className="dx-field-value pas-field">
                                    <TextBox
                                        onInitialized={this.textBoxName_OnInitialized}
                                        width={250}
                                        defaultValue=''
                                        hint='Enter name ...'
                                    >
                                        <Validator>
                                            <ValidationRule type="required" message="Required" />
                                        </Validator>
                                    </TextBox>
                                </div>
                            </div>
                            <div className="pas-dx-field">
                                <div className="dx-field-label pas-label"></div>
                                <div className="dx-field-value pas-field">
                                    <Button
                                        text="Add existing contact"
                                        icon="add"
                                        onClick={this.buttonAddExistingArtistContact_OnClick} />
                                    <Button
                                        text="Add contact"
                                        icon="add"
                                        onClick={this.buttonAddArtistContact_OnClick} />
                                </div>
                            </div>

                            <div className="pas-dx-field">
                                <div className="dx-field-label pas-label pas-label-alignment-auto">Contacts</div>
                                <div className="dx-field-value pas-field">
                                    <DataGrid
                                        dataSource={this.state.dataSourceArtistContacts}
                                        key='Id'
                                        height='100%'
                                        allowColumnReordering={false}
                                        allowColumnResizing={false}
                                        allowGrouping={false}
                                        columnAutoWidth={false}
                                        columnResizingMode='widget'
                                        noDataText='No contacts found.'
                                        remoteOperations={false}
                                        repaintChangesOnly={false}
                                        showBorders={true}
                                        showColumnLines={true}
                                        showRowLines={true}
                                        onInitialized={this.gridOnInitialized}
                                    >
                                        <Paging enabled={false} />
                                        <Column dataField="ArtistId" dataType="number" visible={false} />
                                        <Column allowSorting={false} allowResizing={false} allowEditing={false} dataField="RelationFirstName" dataType="string" caption='First name' width={200} />
                                        <Column allowSorting={false} allowResizing={false} allowEditing={false} dataField="RelationLastName" dataType="string" caption='Last name' width={200} />
                                        <Column allowSorting={false} allowResizing={false} allowEditing={false} dataField="RelationEmail1" dataType="string" caption='Email' width={200} />
                                        <Column allowSorting={false} allowResizing={false} allowEditing={false} dataField="RelationTel1" dataType="string" caption='Tel' width={150} />
                                    </DataGrid>
                                </div>
                            </div>
                            
                            <div className="pas-dx-field">
                                <div className="dx-field-label pas-label pas-label-alignment-auto">Notes</div>
                                <div className="dx-field-value pas-field">
                                    <HtmlEditor
                                        width='100%'
                                        height='326px'
                                        onInitialized={this.htmlEditorNotes_OnInitialized}
                                    >
                                    </HtmlEditor>
                                </div>
                            </div>

                            <div className='pas-buttons'>
                                <Button
                                    onInitialized={this.buttonUpdate_OnInitialized}
                                    width={120}
                                    text="Save"
                                    icon="save"
                                    onClick={this.buttonUpdate_OnClick}
                                />
                            </div>
                        </div>
                    </ValidationGroup>

                    <PopupShuttlesAdminRelationsEditArtistContact
                        ref={ref => this.refPopupShuttlesAdminRelationsEditArtistContact = ref}
                        id={-1}
                        onInitialized={this.popupShuttlesAdminRelationsEditArtistContact_OnInitialized}
                        onUpdate={this.popupShuttlesAdminRelationsEditArtistContact_OnUpdate}
                    />
                    <PopupPickContact
                        ref={ref => this.refPopupPickContact = ref}
                        contacts={this.state.dataSourceArtistContacts}
                        onInitialized={this.popupPickContact_OnInitialized}
                        onUpdate={this.popupPickContact_OnUpdate}
                    />
                    
                </Popup>
            </React.Fragment>

        );
    }

}

export default PopupArtistNameShuttles

