const xml2js = require('xml2js');

class HxXmlConvert {
    constructor() {
    }

    static XmlToObject(xml) {
        var result = {};

        let parser = new xml2js.Parser();

        parser.parseString(
            xml, function (err, res) {
            console.log("error: " + err);
            result = res;
        });

        return result;
    }

    static ObjectToXml(obj) {
        try {
            let builder = new xml2js.Builder();
            let xml = builder.buildObject(obj);

            return xml;
        } catch (e) {
            return null;
        }

    }

}
export default HxXmlConvert;
