import globalConfig from '../../config/global.js';

class ServiceProducts {

    static getProductsDropDown = async () => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Products/GetProductsDropDown") // return null or data object
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static checkIfProductExists = async (id, name) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Products/CheckIfProductExists?id=" + encodeURIComponent(id) + "&name=" + encodeURIComponent(name)) // return null or data object
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static checkIfProductCanBeDeleted = async (id, eventId) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Products/CheckIfProductCanBeDeleted?id=" + encodeURIComponent(id) + "&eventId=" + encodeURIComponent(eventId)) // return null or data object
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static deleteProduct = async (id) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Products/DeleteProduct", {
            method: 'POST',
            body: JSON.stringify(id),
            headers: {
                'Content-type': 'application/json; charset=UTF-8'
            }
        })
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(error => {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static saveProduct = async (productsInfoModel) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Products/SaveProduct", {
            method: 'POST',
            body: JSON.stringify(productsInfoModel),
            headers: {
                'Content-type': 'application/json; charset=UTF-8'
            }
        })
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(error => {
                returnValue = { 'error': error.message };
                console.error('Save product failed: ', error.message);
            });

        return returnValue;
    }

    static updateProduct = async (productsInfoModel) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Products/UpdateProduct", {
            method: 'POST',
            body: JSON.stringify(productsInfoModel),
            headers: {
                'Content-type': 'application/json; charset=UTF-8'
            }
        })
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(error => {
                returnValue = { 'error': error.message };
                console.error('Update product failed: ', error.message);
            });

        return returnValue;
    }

    static updateEKP = async (id, ekp) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Products/UpdateEKP?id=" + encodeURIComponent(id) + "&ekp=" + encodeURIComponent(ekp)) 
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static updateArticleNo = async (id, articleNo) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Products/UpdateArticleNo?id=" + encodeURIComponent(id) + "&articleNo=" + encodeURIComponent(articleNo)) 
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static updatePackaging = async (id, packaging) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Products/UpdatePackaging?id=" + encodeURIComponent(id) + "&packaging=" + encodeURIComponent(packaging))
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }



    static updateName = async (id, name) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Products/UpdateName?id=" + encodeURIComponent(id) + "&name=" + encodeURIComponent(name))
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static updateProductTypeId = async (id, productTypeId) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Products/UpdateProductTypeId?id=" + encodeURIComponent(id) + "&productTypeId=" + encodeURIComponent(productTypeId))
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static getProductsInfo = async (eventId, productColorOrigin) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Products/GetProductsInfo?eventId=" + encodeURIComponent(eventId) + "&productColorOrigin=" + encodeURIComponent(productColorOrigin))  // return null or data object
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static getProductTypesDropDown = async () => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Products/GetProductTypesDropDown") // return null or data object
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static getProductInfoById = async (eventId, id, productColorOrigin) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Products/GetProductInfoById?eventId=" + eventId + "&id=" + encodeURIComponent(id) + "&productColorOrigin=" + encodeURIComponent(productColorOrigin)) // return null or data object
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }
}

export default ServiceProducts;
