import React from 'react';
import { Popup } from 'devextreme-react/popup';
import TextBox from 'devextreme-react/text-box';
import Validator, { ValidationRule, AsyncRule } from 'devextreme-react/validator';
import { Button } from 'devextreme-react/button';
import { ValidationGroup } from 'devextreme-react/validation-group';
import ServiceUsers from '../../../api/services/ServiceUsers'
import { CheckBox } from 'devextreme-react/check-box';

import './HXPopupSaveFilter.scss'


// props
//      setValues: callable
//
//      onInitialized: callback
//      onUpdate: callback

class HXPopupSaveFilter extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dataSourceUserFilters: [],
            popupHeight: 210
        };

        this.popupInstance = null;

        this.textBoxNameInstance = null;

        this.checkBoxDefaultInstance = null;

        this.buttonUpdateInstance = null

        this.validationGroupInstance = null;

        this.filterId = -1;
        this.filterName = '';
        this.filterIsDefault = false;
        this.filterEditMode = '';
        this.filterTypeName = this.props.filterTypeName;

    }

    // CALLBACKS
    onInitialized = (instance) => {
        this.props.onInitialized(instance);  // callback

    }

    onUpdate = (filterId, name, isDefault, editMode) => {
        this.props.onUpdate(filterId, name, isDefault, editMode);  // callback
    }

    // CALLABLES
    setValues = (id, name, isDefault, editMode) => {
        this.filterId = id;
        this.filterName = name;
        this.filterIsDefault = isDefault;
        this.filterEditMode = editMode.toLowerCase().trim();
    }

    // POPUP
    popup_OnInitialized = (e) => {
        this.popupInstance = e.component;
        this.onInitialized(e.component);
    }

    popup_OnShowing = (e) => {
        // reset first or textbox is not filled
        this.validationGroupInstance.reset();

        this.textBoxNameInstance.option('value', this.filterName);
        this.checkBoxDefaultInstance.option('value', this.filterIsDefault);
        switch (this.filterEditMode) {
            case 'save':
                this.setState({ popupHeight: 210 });
                this.popupInstance.option('title', 'Save new filter');
                this.checkBoxDefaultInstance.option('visible', true);
                this.buttonUpdateInstance.option("text", "Save New");
                break;
            case 'update':
                this.setState({ popupHeight: 210 });
                this.popupInstance.option('title', 'Update ' + this.filterName);
                this.checkBoxDefaultInstance.option('visible', true);
                this.buttonUpdateInstance.option("text", "Update");
                break;
            case 'rename':
                this.setState({ popupHeight: 190 });
                this.popupInstance.option('title', 'Rename');
                this.checkBoxDefaultInstance.option('visible', false);
                this.buttonUpdateInstance.option("text", "Rename");
                break;
            default:
                break;
        }

    }

    popup_OnShown = (e) => {
        this.textBoxNameInstance.focus();
    }

    // TEXTBOX
    textBoxName_OnInitialized = (e) => {
        this.textBoxNameInstance = e.component;
    }
    textBoxName_OnFocusIn = (e) => {
        // select all text
        e.element.querySelector('.dx-texteditor-input').select();
    }

    // CHECKBOXES
    checkBoxSetDefault_OnInitialized = (e) => {
        this.checkBoxDefaultInstance = e.component;
    }

    // BUTTON
    buttonUpdate_OnInitialized = (e) => {
        this.buttonUpdateInstance = e.component;
    }

    buttonUpdate_OnClick = (e) => {

        const res = this.validationGroupInstance.validate();
        res.status === "pending" && res.complete.then((r) => {
            if (r.status === 'valid') {
                this.onUpdate(this.filterId, this.textBoxNameInstance.option('value').trim(), this.checkBoxDefaultInstance.option('value'), this.filterEditMode);
                this.popupInstance.hide();
            } else {
                this.textBoxNameInstance.focus();
            }
        });
    }

    // VALIDATION GROUP
    validationGroup_OnInitialized = (e) => {
        this.validationGroupInstance = e.component;
    }

    validationCallback = async (e) => {

        var userFilterData = {
            Id: this.filterId,
            UserId: parseInt(sessionStorage.getItem("currentUserId"), 10),
            UserFilterTypeId: -1,
            EventId: parseInt(sessionStorage.getItem("currentEventId"), 10),
            Name: e.value,
            Xml: null
        }

        var userFilterTypeId = await ServiceUsers.getUserFilterTypeIDByName(this.filterTypeName);
        if (userFilterTypeId !== null) {
            if (!userFilterTypeId.hasOwnProperty("error")) {
                userFilterData.UserFilterTypeId = userFilterTypeId;
            } else {
                return false;
            }
        }

        var exists = await ServiceUsers.checkIfUserFilterExists(userFilterData);

        return !exists;
    }

    render() {
        return (
            <React.Fragment>
                <Popup
                    dragEnabled={false}
                    hideOnOutsideClick={false}
                    showTitle={true}
                    showCloseButton={true}
                    width={450}
                    height={this.state.popupHeight}
                    onInitialized={this.popup_OnInitialized}
                    onShown={this.popup_OnShown}
                    onShowing={this.popup_OnShowing}
                >
                    <ValidationGroup onInitialized={this.validationGroup_OnInitialized}>
                        <div>
                            <div>
                                <TextBox
                                    onInitialized={this.textBoxName_OnInitialized}
                                    onFocusIn={this.textBoxName_OnFocusIn}
                                    defaultValue=''
                                    hint='Enter name ...'
                                    validationMessageMode='always'
                                >
                                    <Validator>
                                        <ValidationRule
                                            type="required"
                                            message="Required" />
                                        <AsyncRule
                                            message="Name already exists"
                                            validationCallback={this.validationCallback}
                                        />
                                    </Validator>
                                </TextBox>
                            </div>

                            <div className='popup-save-filter-checkbox-default'>
                                <CheckBox
                                    defaultValue={false}
                                    text="Set as default"
                                    onInitialized={this.checkBoxSetDefault_OnInitialized}
                                />
                            </div>

                            <div className='popup-save-filter-button'>
                                <Button
                                    onInitialized={this.buttonUpdate_OnInitialized}
                                    width={120}
                                    className='dx-button'
                                    onClick={this.buttonUpdate_OnClick}
                                />
                            </div>

                        </div>
                    </ValidationGroup>
                </Popup>
            </React.Fragment>
        );
    }
}

export default HXPopupSaveFilter

