import React, { useState, useEffect, createContext, useContext, useCallback } from 'react';
import { getUser, signIn as sendSignInRequest } from '../api/auth';


function AuthProvider(props) {
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
      (async function () {
        if (sessionStorage.getItem('currentUserId') !== null) {
            const result = await getUser(parseInt(sessionStorage.getItem('currentUserId'), 10));
            if (result.isOk) {
                setUser(result.data);
            }
        }
      setLoading(false);
    })();
  }, []);

  const signIn = useCallback(async (email, password) => {
    const result = await sendSignInRequest(email, password);
      if (result.isOk) {
          setUser(result.data);
          sessionStorage.setItem('currentUserId', result.data.Id);
          sessionStorage.setItem('currentUserIsRidersAdministrator', result.data.IsRidersAdministrator);
          sessionStorage.setItem('currentUserIsRidersUser', result.data.IsRidersUser);
          sessionStorage.setItem('currentUserIsShuttlesAdministrator', result.data.IsShuttlesAdministrator);
          sessionStorage.setItem('currentUserIsShuttlesUser', result.data.IsShuttlesUser);

          sessionStorage.setItem('currentEventId', result.data.CurrentEventId);
      } else {
          sessionStorage.removeItem('currentUserId');
          sessionStorage.removeItem('currentUserIsRidersAdministrator');
          sessionStorage.removeItem('currentUserIsRidersUser');
          sessionStorage.removeItem('currentUserIsShuttlesAdministrator');
          sessionStorage.removeItem('currentUserIsShuttlesUser');

          sessionStorage.removeItem('currentEventId');
      }

    return result;
  }, []);

  const signOut = useCallback(() => {
      setUser(undefined);
      sessionStorage.removeItem('currentUserId');
      sessionStorage.removeItem('currentUserIsRidersAdministrator');
      sessionStorage.removeItem('currentUserIsRidersUser');
      sessionStorage.removeItem('currentUserIsShuttlesAdministrator');
      sessionStorage.removeItem('currentUserIsShuttlesUser');

      sessionStorage.removeItem('currentEventId');
  }, []);


  return (
    <AuthContext.Provider value={{ user, signIn, signOut, loading }} {...props} />
  );
}

const AuthContext = createContext({ loading: false });
const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth }
