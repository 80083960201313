import React from 'react';
import List from 'devextreme-react/list';
import ServiceTrips from '../../../api/services/ServiceTrips'
import ServiceGeneral from '../../../api/services/ServiceGeneral'

import './ShuttlesOverView.scss';


class ShuttlesOverView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dataSourceTrips: [],
        }
        this.dataSourceGigDates = [];

        this.listInstance = null;
    }

    componentDidMount = async () => {
        await this.loadDataSourceGigDates()
    }

    loadDataSourceTrips = async (gigDateId) => {
        let ret = [];
        var data = await ServiceTrips.getTripsInfoForGanttByEventIdGigDateId(parseInt(sessionStorage.getItem('currentEventId'), 10), gigDateId, 5);
        if (data !== null && !data.hasOwnProperty("error")) {
            ret = data;
        } else {
            this.onLoadError();  // callback
        }

        return ret;
    }

    loadDataSourceGigDates = async () => {
        let trips = [];
        var data = await ServiceGeneral.getGigDatesByEventId(parseInt(sessionStorage.getItem("currentEventId"), 10), 'shuttles');
        if (data !== null && !data.hasOwnProperty("error")) {
            this.dataSourceGigDates = data;

            for (var i = 0; i < this.dataSourceGigDates.length; i++) {
                let dayTrips = await this.loadDataSourceTrips(this.dataSourceGigDates[i].Id);

                if (dayTrips.length > 0) {
                    for (var j = 0; j < dayTrips.length; j++) {
                        trips.push(dayTrips[j]);
                    }
                }
            }

            trips = trips.sort(function (a, b) {
                var c = new Date(a.DateTimeDeparture);
                var d = new Date(b.DateTimeDeparture);
                return c - d;
            });

            this.setState({ dataSourceTrips: trips });

        } else {
            this.setErrorHeader(true);
        }
    }

    list_OnInitialized = (e) => {
        this.listInstance = e.Component
    }

    listItemRender = (item) => {
        let dt = new Date(item.DateTimeDeparture);
        const t = dt.getHours().toString().padStart(2, '0') + ':' + dt.getMinutes().toString().padStart(2, '0')
        return (
            <div className="so-main-div">
                <div className="so-time">{t}</div>
                <div className="so-driver-name">{item.DriverName}</div>
                <div className="so-artist-name">{item.ArtistName}</div>
                <div className="so-trip">{item.LocationDepartureName} - {item.LocationArrivalName}</div>
            </div>
        );
    }

    render() {
        return (
            <React.Fragment>
                <div>
                    <List
                        dataSource={this.state.dataSourceTrips}
                        height={400}
                        keyExpr='Id'
                        selectionMode='none'
                        pageLoadMode='scrollBottom'
                        itemRender={this.listItemRender}
                        onInitialized={this.list_OnInitialized}
                    >
                    </List>
                </div>
            </React.Fragment>
        );
    }
}

export default ShuttlesOverView
