import React from 'react';
import ScrollView from 'devextreme-react/scroll-view';
import './single-card.scss';
import globalConfig from '../..//config/global.js';


export default function SingleCard({ title, description, children }) {
    return (
      <React.Fragment>
            <ScrollView height={'100%'} width={'100%'} className={'with-footer single-card'}>
                <div className={'dx-card content'}>
                     <div className={'header'}>
                        <div style={{ height: '426px' }}></div>
                    </div>
                    {children}
                </div>
            </ScrollView>
        </React.Fragment>
)}
