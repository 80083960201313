import React from 'react';
import { Popup } from 'devextreme-react/popup';
import TextBox from 'devextreme-react/text-box';
import Validator, { AsyncRule, ValidationRule, EmailRule } from 'devextreme-react/validator';
import { Button } from 'devextreme-react/button';
import { ValidationGroup, dxValidationGroupResult } from 'devextreme-react/validation-group';
import SelectBox from 'devextreme-react/select-box';
import { CheckBox } from 'devextreme-react/check-box';
import ColorBox from 'devextreme-react/color-box';
import DataGrid, {
    Column,
    FormItem,
    Editing,
    Paging,
    Lookup,
} from 'devextreme-react/data-grid';
import HtmlEditor, {
    Toolbar, MediaResizing, ImageUpload, Item,
} from 'devextreme-react/html-editor';
import HxButton from '../../../../custom-components/hx-button/HxButton'
import HxMap from '../../../../custom-components/hx-map/HxMap';
import { NumberBox } from 'devextreme-react/number-box';

import ServiceRelations from '../../../../../api/services/ServiceRelations'
import ServiceGeoCoding from '../../../../../api/services/ServiceGeoCoding'
import ServiceLocations from '../../../../../api/services/ServiceLocations'
import ServiceGeneral from '../../../../../api/services/ServiceGeneral'

import './PopupShuttlesAdminLocationsEdit.scss'
import globalConfig from '../../../../../config/global.js';


// props
//      id (required): relation id
//
//      onInitialized: callback
//      onUpdate: callback
//      onLoadError: callback
//      onHiding: callback

class PopupShuttlesAdminLocationsEdit extends React.Component {
    constructor(props) {
        super(props);

        this.locationId = -1;

        this.countriesDataSource = [];
        this.locationTypesDataSource = [];

        this.validationGroupInstance = null;

        this.textBoxNameInstance = null;
        this.textBoxStreetInstance = null;
        this.textBoxStreetNumberInstance = null;
        this.textBoxZipCodeInstance = null;
        this.textBoxCityInstance = null;
        this.selectBoxCountriesInstance = null;

        this.textBoxEmailInstance = null;
        this.textBoxTelInstance = null;
        this.textBoxLongitudeInstance = null;
        this.textBoxLatitudeInstance = null;
        this.buttonAutoInstance = null;
        this.selectBoxLocationTypesInstance = null;
        this.checkBoxIsFavouriteInstance = null;
        this.textBoxShortNameInstance = null;



        this.refMap = React.createRef();

        this.checkBoxKeepOpenInstance = null;
        this.buttonUpdateInstance = null;

        this.locationRecord = {};
        this.locationDefaultRecord = {
            Id: -1,
            EventId: parseInt(sessionStorage.getItem('currentEventId'), 10),
            ColorId: null,
            PatternId: null,
            LocationTypeId: null,
            Name: '',
            Street: null,
            StreetNumber: null,
            ZipCode: null,
            City: null,
            CountryCode: null,
            Country: '',
            GPSLongitude: 0,
            GPSLatitude: 0,
            Tel: null,
            Email: null,
            Notes: null,
            IsFavourite: false,
            ShortName: '',
            LocationType: '',
            ColorHex: '#FFFFFF',
            ColorRgba: 'rgba(255,255,255,1)',
            ForeColorHex: '#000000',
            ForeColorRgba: 'rgba(0,0,0,1)',
            PatternColorHex: '#000000',
            PatternColorRgba: 'rgba(0,0,0,1)',
            UsePattern: false,
            PatternCssBackGround: null,
            PatternCssBackGroundImage: null,
            PatternCssBackGroundSize: null,
            PatternCssBackGroundPosition: null,
        }
    }

    // CALLBACKS
    onInitialized = (instance) => {
        if (this.props.onInitialized) {
            this.props.onInitialized(instance);  // callback
        }
    }

    onLoadError = () => {
        if (this.props.onLoadError) {
            this.props.onLoadError();  // callback
        }
    }

    onUpdate = (success) => {
        if (this.props.onUpdate) {
            this.props.onUpdate(success);  // callback
        }
    }

    onHiding = () => {
        if (this.props.onHiding) {
            this.props.onHiding();  // callback
        }
    }
    // CALLABLES

    // EVENTS
    componentDidMount = async () => {
        await this.loadDataSourceCountries();
        await this.loadDataSourceLocationTypes();
 }

    // DATA
    loadDataSourceCountries = async () => {
        var data = await ServiceGeneral.getAllCountries();
        if (data !== null && !data.hasOwnProperty("error")) {
            this.countriesDataSource = data;
        } else {
            this.onLoadError();  // callback
        }
    }

    loadDataSourceLocationTypes = async () => {
        var data = await ServiceLocations.getAllLocationTypesDropDown();
        if (data !== null && !data.hasOwnProperty("error")) {
            this.locationTypesDataSource = data;
        } else {
            this.onLoadError();  // callback
        }
    }

    loadDataSourceLocation = async (locationId) => {
        var data = await ServiceLocations.getLocationByEventIdLocationId(parseInt(sessionStorage.getItem('currentEventId'), 10), locationId);
        if (data !== null && !data.hasOwnProperty("error")) {
            this.locationRecord = data;
        } else {
            this.onLoadError();  // callback
        }
    }


    // POPUP
    popup_OnInitialized = (e) => {
        this.popupInstance = e.component;

        // set callback
        this.onInitialized(e.component);
    }

    popup_OnShowing = (e) => {
        if (this.props.id !== undefined) {
            this.locationId = this.props.id;
        }
    }

    popup_OnHiding = (e) => {
        this.resetPopup();

        this.onHiding();  // callback
    }

    resetPopup = () => {
        var type = this.locationTypesDataSource.find(o => o.Name.toString().toUpperCase() === 'OTHER');

        this.textBoxNameInstance.option('value', null);
        this.textBoxStreetInstance.option('value', null);
        this.textBoxStreetNumberInstance.option('value', null);
        this.textBoxZipCodeInstance.option('value', null);
        this.textBoxCityInstance.option('value', null);
        this.selectBoxCountriesInstance.option('value', null);

        this.textBoxEmailInstance.option('value', null);
        this.textBoxTelInstance.option('value', null);
        this.textBoxLongitudeInstance.option('value', null);
        this.textBoxLatitudeInstance.option('value', null);
        this.selectBoxLocationTypesInstance.option('value', (type != null ? type.Id : -1));
        this.textBoxShortNameInstance.option('value', null)
        this.checkBoxIsFavouriteInstance.option('value', false)

        this.htmlEditorNotesInstance.option('value', null);

        this.validationGroupInstance.reset();
    }

    popup_OnShown = async (e) => {
        var type = this.locationTypesDataSource.find(o => o.Name.toString().toUpperCase() === 'OTHER');
        this.locationDefaultRecord.LocationTypeId = (type != null ? type.Id : -1);
        this.locationDefaultRecord.LocationType = (type != null ? type.Name : '');

        if (this.locationId == -1) {
            // new relation
            this.locationRecord = this.locationDefaultRecord;

            this.resetPopup();

            document.getElementById('textBoxNameInput').select();

            this.popupInstance.option('title', 'Add new location');
        } else {
            // edit location
            await this.loadDataSourceLocation(this.locationId);

            this.textBoxNameInstance.option('value', this.locationRecord.Name);
            this.textBoxStreetInstance.option('value', this.locationRecord.Street);
            this.textBoxStreetNumberInstance.option('value', this.locationRecord.StreetNumber);
            this.textBoxZipCodeInstance.option('value', this.locationRecord.ZipCode);
            this.textBoxCityInstance.option('value', this.locationRecord.City);
            this.selectBoxCountriesInstance.option('value', this.locationRecord.CountryCode);

            this.textBoxEmailInstance.option('value', this.locationRecord.Email);
            this.textBoxTelInstance.option('value', this.locationRecord.Tel);
            this.textBoxLongitudeInstance.option('value', this.locationRecord.GPSLongitude);
            this.textBoxLatitudeInstance.option('value', this.locationRecord.GPSLatitude);
            this.selectBoxLocationTypesInstance.option('value', this.locationRecord.LocationTypeId);
            this.textBoxShortNameInstance.option('value', this.locationRecord.ShortName);
            this.checkBoxIsFavouriteInstance.option('value', this.locationRecord.IsFavourite !== null ? this.locationRecord.IsFavourite : false);

            this.htmlEditorNotesInstance.option('value', this.locationRecord.Notes);

            this.popupInstance.option('title', 'Update ' + this.locationRecord.Name);
        }

        this.checkBoxKeepOpenInstance.option('value', false);
        this.buttonUpdateInstance.option('text', 'Save');
        this.buttonUpdateInstance.option('width', '120px');

    }

    // ALL TEXTBOXES FOCUS IN
    textBox_OnFocusIn = (e) => {
        // select all text
        e.element.querySelector('.dx-texteditor-input').select();
    }

    // TEXTBOX NAME
    textBoxNameAttributes = {
        id: 'textBoxNameInput',
    }

    textBoxName_OnInitialized = (e) => {
        this.textBoxNameInstance = e.component;
    }

    // TEXTBOX STREET
    textBoxStreet_OnInitialized = (e) => {
        this.textBoxStreetInstance = e.component;
    }

    // TEXTBOX STREETNUMBER
    textBoxStreetNumber_OnInitialized = (e) => {
        this.textBoxStreetNumberInstance = e.component;
    }

    // TEXTBOX ZIPCODE
    textBoxZipCode_OnInitialized = (e) => {
        this.textBoxZipCodeInstance = e.component;
    }

    // TEXTBOX CITY
    textBoxCity_OnInitialized = (e) => {
        this.textBoxCityInstance = e.component;
    }

    // SELECTBOX COUNTRIES
    selectBoxCountries_OnInitialized = (e) => {
        this.selectBoxCountriesInstance = e.component;
    }

    // HTML EDITOR NOTES
    htmlEditorNotes_OnInitialized = (e) => {
        this.htmlEditorNotesInstance = e.component;
    }


    // TEXTBOX EMAIL
    textBoxEmail_OnInitialized = (e) => {
        this.textBoxEmailInstance = e.component;
    }

    // TEXTBOX TEL
    textBoxTel_OnInitialized = (e) => {
        this.textBoxTelInstance = e.component;
    }

    // TEXTBOX LONGITUDE
    textBoxLongitude_OnInitialized = (e) => {
        this.textBoxLongitudeInstance = e.component;
    }

    // TEXTBOX LATITUDE
    textBoxLatitude_OnInitialized = (e) => {
        this.textBoxLatitudeInstance = e.component;
    }

    // BUTTON AUTO
    buttonAuto_OnInitialized = (e) => {
        this.buttonAutoInstance = e.component;
    }

    buttonAuto_OnClick = async (e) => {
        await this.setGps();

        let lat = this.textBoxLatitudeInstance.option('value');
        let lng = this.textBoxLongitudeInstance.option('value');
        if (lat != null && lng != null) {
            this.refMap.current.setCenter({ lat: lat, lng: lng });
        }
    }

    
    setGps = async () => {
        const street = this.textBoxStreetInstance.option('value');
        const streetNumber = this.textBoxStreetNumberInstance.option('value');
        const zipCode = this.textBoxZipCodeInstance.option('value');
        const city = this.textBoxCityInstance.option('value');
        const country = this.selectBoxCountriesInstance.option('displayValue');

        const postalCode = (zipCode !== null ? zipCode.toString().trim() : '');
        const locality = (city !== null ? city.toString().trim() : '');
        const countryRegion = (country !== null ? country.toString().trim() : '');

        let address = '';
        if (street !== null) {
            address = street + ' ' + (streetNumber != null ? streetNumber.toString().trim() : '');
        }

        var data = await ServiceGeoCoding.getCoordinatesByAddress(address, postalCode, locality, countryRegion);
        if (data !== null && !data.hasOwnProperty("error")) {
            if (data.statusCode == 200) {
                let lat = data.resourceSets[0].resources[0].point.coordinates[0];
                let lng = data.resourceSets[0].resources[0].point.coordinates[1];
                this.textBoxLatitudeInstance.option('value', lat);
                this.textBoxLongitudeInstance.option('value', lng);
            } else {
                this.textBoxLatitudeInstance.option('value', null);
                this.textBoxLongitudeInstance.option('value', null);
            }
        } 
    }



    // SELECTBOX LOCATION TYPES
    selectBoxLocationTypes_OnInitialized = (e) => {
        this.selectBoxLocationTypesInstance = e.component;
    }

    // CHECKBOX FAVOURITE
    checkBoxIsFavourite_OnInitialized = (e) => {
        this.checkBoxIsFavouriteInstance = e.component;
    }

    // TEXTBOX SHORTNAME
    textBoxShortName_OnInitialized = (e) => {
        this.textBoxShortNameInstance = e.component;
    }
    textBoxShortName_OnFocusOut = (e) => {
        this.textBoxShortNameInstance.option('value', this.textBoxShortNameInstance.option('value').toUpperCase());
    }

    // CHECKBOX KEEP OPEN
    checkBoxKeepOpen_OnInitialized = (e) => {
        this.checkBoxKeepOpenInstance = e.component;
    }

    checkBoxKeepOpen_OnValueChanged = (e) => {
        this.buttonUpdateInstance.option('text', (e.value === true ? 'Save & new' : 'Save'));
        this.buttonUpdateInstance.option('width', (e.value === true ? '140px' : '120px'));
    }

    // BUTTON UPDATE
    buttonUpdate_OnInitialized = (e) => {
        this.buttonUpdateInstance = e.component;
    }

    buttonUpdate_OnClick = async (e) => {
        await this.updateLocation();
    }

    updateLocation = async () => {
        this.validationGroupInstance.validate();
        if (this.textBoxNameInstance.option('isValid') === true
            && this.textBoxEmailInstance.option('isValid') === true
            && this.textBoxNameInstance.option('isValid') === true) {
            this.locationRecord.Name = this.textBoxNameInstance.option('value').trim();
            this.locationRecord.Street = (this.textBoxStreetInstance.option('value') !== null ? this.textBoxStreetInstance.option('value').trim() : null);
            this.locationRecord.StreetNumber = (this.textBoxStreetNumberInstance.option('value') !== null ? this.textBoxStreetNumberInstance.option('value').trim() : null);
            this.locationRecord.ZipCode = (this.textBoxZipCodeInstance.option('value') !== null ? this.textBoxZipCodeInstance.option('value').trim() : null);
            this.locationRecord.City = (this.textBoxCityInstance.option('value') !== null ? this.textBoxCityInstance.option('value').trim() : null);
            this.locationRecord.CountryCode = this.selectBoxCountriesInstance.option('value');
            this.locationRecord.Country = this.selectBoxCountriesInstance.option('display');
            this.locationRecord.Email = (this.textBoxEmailInstance.option('value') !== null ? this.textBoxEmailInstance.option('value').trim() : null);
            this.locationRecord.Tel = (this.textBoxTelInstance.option('value') !== null ? this.textBoxTelInstance.option('value').trim() : null);
            this.locationRecord.GPSLongitude = this.textBoxLongitudeInstance.option('value');
            this.locationRecord.GPSLatitude = this.textBoxLatitudeInstance.option('value');
            this.locationRecord.Notes = this.htmlEditorNotesInstance.option('value');
            this.locationRecord.LocationTypeId = this.selectBoxLocationTypesInstance.option('value');
            this.locationRecord.ShortName = (this.textBoxShortNameInstance.option('value') !== null ? this.textBoxShortNameInstance.option('value').trim() : null);
            this.locationRecord.IsFavourite = this.checkBoxIsFavouriteInstance.option('value');

            if (this.locationId == -1) {
                // new location
                var resultlocation = await ServiceLocations.addLocation(this.locationRecord);
                if (resultlocation !== null && !resultlocation.hasOwnProperty("error")) {
                    this.onUpdate(true); // callback
                    if (this.checkBoxKeepOpenInstance.option('value') === false) {
                        this.popupInstance.hide();
                    } else {
                        this.resetPopup();

                        this.popupInstance.option('title', 'Add new location');
                    }
                } else {
                    this.onUpdate(false); // callback
                }
            } else {
                // edit location
                var resultlocation = await ServiceLocations.updateLocation(this.locationRecord);
                if (resultlocation != null && !resultlocation.hasOwnProperty("error") && resultlocation == true) {
                    this.onUpdate(true); // callback
                    if (this.checkBoxKeepOpenInstance.option('value') === false) {
                        this.popupInstance.hide();
                    } else {
                        this.resetPopup();

                        this.popupInstance.option('title', 'Add new location');
                    }
                } else {
                    this.onUpdate(false); // callback
                }
            }
        } else {
            this.textBoxNameInstance.focus();
        }
    }

    // VALIDATION
    validationGroup_OnInitialized = (e) => {
        this.validationGroupInstance = e.component;
    }

    validateLocationName = async (e) => {
        var result = await ServiceLocations.doesLocationNameExist(this.locationRecord.EventId, e.value, this.locationRecord.Id);
        if (result !== null && !result.hasOwnProperty("error")) {
            return !result;
        } else {
            this.onLoadError();  // callback
            return false;
        }
    }

    render() {
        return (
            <React.Fragment>
                <Popup
                    dragEnabled={false}
                    hideOnOutsideClick={false}
                    showTitle={true}
                    width={871}
                    height={688}
                    showCloseButton={true}
                    onInitialized={this.popup_OnInitialized}
                    onShown={this.popup_OnShown}
                    onShowing={this.popup_OnShowing}
                    onHiding={this.popup_OnHiding}
                >
                    <ValidationGroup
                        onInitialized={this.validationGroup_OnInitialized}
                    >
                        <div>
                            <div className="psale-header-div">Info</div>
                            <div className="dx-card psale-top-card">
                                <div className="psale-top-div">
                                    <div className="psale-top-left-div">
                                        <div className="psale-dx-field">
                                            <div className="dx-field-label psale-top-label">Name*</div>
                                            <div className="dx-field-value psale-top-input">
                                                <TextBox
                                                    inputAttr={this.textBoxNameAttributes}
                                                    width='250px'
                                                    onFocusIn={this.textBox_OnFocusIn}
                                                    onInitialized={this.textBoxName_OnInitialized}
                                                >
                                                    <Validator>
                                                        <AsyncRule validationCallback={this.validateLocationName} message="Location exists" />
                                                        <ValidationRule type="required" message="Required" />
                                                    </Validator>
                                                </TextBox>
                                            </div>
                                        </div>
                                        <div className="psale-dx-field">
                                            <div className="dx-field-label psale-top-label">Street</div>
                                            <div className="dx-field-value psale-top-input">
                                                <TextBox
                                                    width='250px'
                                                    onFocusIn={this.textBox_OnFocusIn}
                                                    onInitialized={this.textBoxStreet_OnInitialized}
                                                />
                                            </div>
                                            <div className="dx-field-label psale-top-label psale-top-label-street-number">No</div>
                                            <div className="dx-field-value psale-top-input">
                                                <TextBox
                                                    width='104px'
                                                    onFocusIn={this.textBox_OnFocusIn}
                                                    onInitialized={this.textBoxStreetNumber_OnInitialized}
                                                />
                                            </div>
                                        </div>
                                        <div className="psale-dx-field">
                                            <div className="dx-field-label psale-top-label">Zipcode</div>
                                            <div className="dx-field-value psale-top-input psale-top-input-zipcode">
                                                <TextBox
                                                    width='100px'
                                                    onFocusIn={this.textBox_OnFocusIn}
                                                    onInitialized={this.textBoxZipCode_OnInitialized}
                                                />
                                            </div>
                                            <div className="dx-field-label psale-top-label psale-top-label-city">City</div>
                                            <div className="dx-field-value psale-top-input">
                                                <TextBox
                                                    width='250px'
                                                    onFocusIn={this.textBox_OnFocusIn}
                                                    onInitialized={this.textBoxCity_OnInitialized}
                                                />
                                            </div>
                                        </div>
                                        <div className="psale-dx-field">
                                            <div className="dx-field-label psale-top-label">Country</div>
                                            <div className="dx-field-value psale-top-input">
                                                <SelectBox
                                                    dataSource={this.countriesDataSource}
                                                    valueExpr="CountryCode"
                                                    displayExpr="EnglishName"
                                                    dropDownOptions={{ maxHeight: '200px' }}
                                                    minSearchLength={0}
                                                    searchEnabled={true}
                                                    showClearButton={true}
                                                    showDataBeforeSearch={false}
                                                    width={250}
                                                    onInitialized={this.selectBoxCountries_OnInitialized}
                                                    onSelectionChanged={this.selectBoxCountries_OnSelectionChanged}
                                                >
                                                </SelectBox>
                                            </div>
                                        </div>
                                        <div className="psale-dx-field">
                                            <div className="dx-field-label psale-top-label">Email</div>
                                            <div className="dx-field-value psale-top-input">
                                                <TextBox
                                                    width='250px'
                                                    onFocusIn={this.textBox_OnFocusIn}
                                                    onInitialized={this.textBoxEmail_OnInitialized}
                                                >
                                                    <Validator>
                                                        <EmailRule
                                                            message="Invalid email" />
                                                    </Validator>
                                                </TextBox>
                                            </div>
                                        </div>
                                        <div className="psale-dx-field">
                                            <div className="dx-field-label psale-top-label">Tel</div>
                                            <div className="dx-field-value psale-top-input">
                                                <TextBox
                                                    width='250px'
                                                    onFocusIn={this.textBox_OnFocusIn}
                                                    onInitialized={this.textBoxTel_OnInitialized}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="psale-top-right-div">
                                        <div className="psale-dx-field-type">
                                            <div className="dx-field-label psale-top-right-label">Type*</div>
                                            <div className="dx-field-value psale-top-input">
                                                <SelectBox
                                                    dataSource={this.locationTypesDataSource}
                                                    valueExpr="Id"
                                                    displayExpr="Name"
                                                    dropDownOptions={{ maxHeight: '200px' }}
                                                    minSearchLength={0}
                                                    searchEnabled={false}
                                                    showClearButton={false}
                                                    showDataBeforeSearch={false}
                                                    width={250}
                                                    onInitialized={this.selectBoxLocationTypes_OnInitialized}
                                                    onSelectionChanged={this.selectBoxLocationTypes_OnSelectionChanged}
                                                >
                                                </SelectBox>
                                            </div>
                                        </div>
                                        <div className="psale-dx-field">
                                            <div className="dx-field-label psale-top-right-label">Short name</div>
                                            <div className="dx-field-value psale-top-input">
                                                <TextBox
                                                    width='100px'
                                                    maxLength={5}
                                                    onFocusIn={this.textBox_OnFocusIn}
                                                    onInitialized={this.textBoxShortName_OnInitialized}
                                                    onFocusOut={this.textBoxShortName_OnFocusOut}
                                                />
                                            </div>
                                        </div>
                                        <div className="psale-dx-field-favourite">
                                            <div className="dx-field-label psale-top-right-label">Favourite</div>
                                            <div className="dx-field-value psale-top-input">
                                                <CheckBox
                                                    text=""
                                                    onInitialized={this.checkBoxIsFavourite_OnInitialized}
                                                />
                                            </div>
                                        </div>
                                        <div className="psale-dx-field-gps">
                                            <div className="psale-gps-left-div">
                                                <div className="psale-dx-field">
                                                    <div className="dx-field-label psale-top-right-label">Latitude</div>
                                                    <div className="dx-field-value psale-top-input">
                                                        <NumberBox
                                                            width='150px'
                                                            step={0}
                                                            onFocusIn={this.textBox_OnFocusIn}
                                                            onInitialized={this.textBoxLatitude_OnInitialized}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="psale-dx-field">
                                                    <div className="dx-field-label psale-top-right-label">Longitude</div>
                                                    <div className="dx-field-value psale-top-input">
                                                        <NumberBox
                                                            width='150px'
                                                            step={0}
                                                            onFocusIn={this.textBox_OnFocusIn}
                                                            onInitialized={this.textBoxLongitude_OnInitialized}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="psale-gps-right-div">
                                                <div className="psale-top-auto-button">
                                                    <Button
                                                        onInitialized={this.buttonAuto_OnInitialized}
                                                        text="Auto"
                                                        onClick={this.buttonAuto_OnClick}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="psale-second-div">
                                <div className="psale-second-left-div">
                                    <div className="psale-dx-field-notes">
                                        <div className="dx-field-value psale-second-input-notes">
                                            <HtmlEditor
                                                width='100%'
                                                height='240px'
                                                onInitialized={this.htmlEditorNotes_OnInitialized}
                                            >
                                            </HtmlEditor>
                                        </div>
                                    </div>
                                </div>
                                <div className="dx-card psale-second-right-div">
                                    <div className="psale-dx-field-map">
                                        <div className="dx-field-value psale-second-input">
                                            <HxMap
                                                ref={this.refMap}
                                                width='290px'
                                                height='233px'
                                            />
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </ValidationGroup>

                    <div className="psale-buttons">
                        <div className="psale-buttons-checkbox">
                            <CheckBox
                                text="Keep open"
                                onInitialized={this.checkBoxKeepOpen_OnInitialized}
                                onValueChanged={this.checkBoxKeepOpen_OnValueChanged}
                            />
                        </div>
                        <div className='psale-buttons-update'>
                            <div className="psale-button-update">
                                <Button
                                    onInitialized={this.buttonUpdate_OnInitialized}
                                    icon="save"
                                    onClick={this.buttonUpdate_OnClick}
                                />
                            </div>
                        </div>
                    </div>
                </Popup>
            </React.Fragment>
        );
    }

}

export default PopupShuttlesAdminLocationsEdit

