import React from 'react';
import DataGrid, {
    RemoteOperations, Editing, ColumnChooser, GroupPanel, FilterPanel, FilterRow, HeaderFilter, Paging, Pager, Scrolling, Column, Button, SearchPanel, KeyboardNavigation, Export, StateStoring, Sorting, Lookup, Selection
} from 'devextreme-react/data-grid';
import { LoadPanel } from 'devextreme-react/load-panel';
import { HubConnectionBuilder } from '@microsoft/signalr';
import ServiceLocations from '../../../../../api/services/ServiceLocations'
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';

import './DataGridShops.scss'

// props
//      onInitialized: callback
//      onExportShop: callback
//      onConnectionError: callback
//      onEditShop: callback
//      onDeleteShop: callback
//
//      setUsersettings: callable
//      exporttoexcel: callable
//      gridRefresh: callable

class DataGridShops extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dataSourceShops: [],
            showFilter: false,
            useAdvancedFiltering: false,
        };

        this.showSelectedCell = false;
        this.UseColorsShops = false;
        this.ExportColors = false;

        this.dataGridInstance = null;

        this.hubConnection = null;

        this.viewState = {};

        this.userSettings = {
            UserId: parseInt(sessionStorage.getItem("currentUserId"), 10),
            DefaultViewId: -1,
            DefaultFilterId: -1,
            ExportColors: false,
            UseColorsShops: false,
            ShowSelectedCell: false,
            UseAdvancedFiltering: false,
        }

        this.loadPanelInstance = null;
        this.loadingPosition = { of: '#grid' }
    }

    // CALLBACKS
    onInitialized = (instance) => {
        if (this.props.onInitialized) {
            this.props.onInitialized(instance)
        }
    }

    onEditShop = (id) => {
        if (this.props.onEditShop) {
            this.props.onEditShop(id);  // callback
        }
    }

    onDeleteShop = async (id, name) => {
        if (this.props.onDeleteShop) {
            await this.props.onDeleteShop(id, name);  // callback
        }
    }

    onExportShop = () => {
        if (this.props.onExportShop) {
            this.props.onExportShop();
        }
    }

    onConnectionError = () => {
        if (this.props.onConnectionError) {
            this.props.onConnectionError();
        }
    }


    //CALLABLES
    gridRefresh = async () => {
        await this.loadPanelInstance.show()
        await this.loadDataSourceShops();
        await this.loadPanelInstance.hide()
    }

    setUsersettings = (settings) => {
        this.userSettings = settings;
        this.applyUserSettings('checkBoxUseColorsShops', this.userSettings.UseColorsShops);
        this.applyUserSettings('checkBoxExportColors', this.userSettings.ExportColors);
        this.applyUserSettings('checkBoxShowSelectedCell', this.userSettings.ShowSelectedCell);
        this.applyUserSettings('checkBoxUseAdvancedFiltering', this.userSettings.UseAdvancedFiltering);
    }

    applyUserSettings = (elementId, value) => {
        switch (elementId) {
            case 'checkBoxUseColorsShops':
                this.UseColorsShops = value;
                this.dataGridInstance.repaint();
                break;
            case 'checkBoxExportColors':
                this.ExportColors = value;
                break;
            case 'checkBoxShowSelectedCell':
                this.showSelectedCell = value;
                this.dataGridInstance.repaint();
                break;
            case 'checkBoxUseAdvancedFiltering':
                this.setState({ useAdvancedFiltering: value });
                this.dataGridInstance.repaint();
                break;
            default:
                break;
        }
    }

    exportToExcel = (name) => {
        const fileName = name;
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Sheet 1');

        if (this.ExportColors) {
            exportDataGrid({
                component: this.dataGridInstance,
                worksheet: worksheet,
                customizeCell: this.customizeExcelCell
            }).then(function () {
                workbook.xlsx.writeBuffer()
                    .then(function (buffer) {
                        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), fileName);
                    });
            });
        } else {
            exportDataGrid({
                component: this.dataGridInstance,
                worksheet: worksheet
            }).then(function () {
                workbook.xlsx.writeBuffer()
                    .then(function (buffer) {
                        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), fileName);
                    });
            });
        }
    }

    // set excel cell layout
    customizeExcelCell = (options) => {
        const { gridCell, excelCell } = options;

        if (gridCell.rowType === 'data') {
            if (gridCell.column.dataField === 'ShopName') {
                let bg = (gridCell.data.ColorHex !== null ? gridCell.data.ColorHex.replace('#', '') : 'FFFFFFFF');
                bg = bg.substr(6, 2) + bg.substr(0, 6);
                let fg = (gridCell.data.ForeColorHex !== null ? gridCell.data.ForeColorHex.replace('#', '') : 'FF000000');
                fg = fg.substr(6, 2) + fg.substr(0, 6);
                excelCell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: bg }
                };
                excelCell.font = {
                    color: { argb: fg }
                }
            }
        }
    }

    // EVENTS
    componentDidMount = async () => {
        await this.loadPanelInstance.show()
        this.hubConnection = new HubConnectionBuilder().withUrl("/shopsHub").build();

        await this.hubConnection.start()
            .then(function () {

            })
            .catch(function (err) {
                return console.error(err.toString());
            });

        this.hubConnection.on("ReceiveMessageAddedShop", await this.updatePushedDataAddedShop);
        this.hubConnection.on("ReceiveMessageEditShop", await this.updatePushedDataEditShop);
        this.hubConnection.on("ReceiveMessageDeletedShop", await this.updatePushedDataDeletedShop);

        await this.loadDataSourceShops();
        await this.loadPanelInstance.hide()
    }


    // DATA
    loadDataSourceShops = async () => {
        var data = await ServiceLocations.getShopsInfo();
        if (data !== null && !data.hasOwnProperty("error")) {
            this.setState({ dataSourceShops: data });
        } else {
            this.onConnectionError();  // callback
        }
    }

    // LOADPANEL
    loadPanelOnInitialized = (e) => {
        // set instance
        this.loadPanelInstance = e.component;
    }

    // GRID
    gridOnCellPrepared = (e) =>{
        if (e.rowType === 'data') {
            if (e.column.dataField === 'ShopName') {
                if (this.UseColorsShops) {
                    e.cellElement.style.backgroundColor = e.data.ColorHex;
                    e.cellElement.style.color = e.data.ForeColorHex;
                }
            } else {
                e.cellElement.style.backgroundColor = '#FFFFFF';
                e.cellElement.style.color = '#000000';
            }
        } else {
            if (e.rowType === 'filter') {
                e.cellElement.style.backgroundColor = '#FFFFFF';
                e.cellElement.style.color = '#000000';
            }
        }
    }

    gridOnFocusedCellChanging = (e) => {
        if (this.showSelectedCell) {
            let elements = document.getElementsByClassName("hx-datagrid-cell-border");
            if (elements) {
                for (var i = 0; i < elements.length; i++) {
                    elements[i].classList.remove('hx-datagrid-cell-border');
                }
            }

            e.cellElement[0].classList.add('hx-datagrid-cell-border');
        }
    }

    gridOnCellClick = (e) => {
        if (e.rowType === 'data') {
            if (e.column.name == 'ActionColumnEdit') {
                this.onEditShop(e.data.Id);
            } else if (e.column.name == 'ActionColumnDelete') {
                if (e.data.CanBeDeleted == true) {
                    this.onDeleteShop(e.data.Id, e.data.ShopName);
                }
            }
        }

    }

    gridOnEditorPreparing = (e) => {
        if (e.parentType === 'dataRow') {
            if (e.editorName === "dxNumberBox") {
                e.editorOptions.step = 0;
                e.editorElement.parentElement.classList.add('hx-datagrid-cell-border');
            } else if (e.editorName === "dxTextBox") {
                e.editorElement.parentElement.classList.add('hx-datagrid-cell-border');
            }
        }
    }

    gridOnRowUpdating = async (e) => {
        for (var key in e.newData) {
            if (e.newData.hasOwnProperty(key)) {
                if (key == "ShopName") {
                    var data = await ServiceLocations.updateLocationName(e.oldData.Id, e.newData[key].trim());
                    if (data !== null && !data.hasOwnProperty("error")) {
                        this.hubConnection
                            .invoke("SendUpdateInfoEditShop", e.oldData.Id)
                            .catch(function (err) {
                                return console.error(err.toString());
                            });
                    } else {
                        this.onConnectionError();  // callback
                    }
                }
                break;
            }
        }
    }

    gridOnEditingStart = (e) => {
        if (!e.column || e.column.dataType === 'boolean') {
            return;
        }

        // make sure onrowupdating is always triggered after edit
        var row = this.dataGridInstance.getRowIndexByKey(e.key);
        var value = this.dataGridInstance.cellValue(row, e.column.dataField);

        if (e.column.dataType.toLowerCase() == "string") {
            this.dataGridInstance.cellValue(row, e.column.dataField, (value !== "%___%" ? "%___%" : ""));
        } else {
            this.dataGridInstance.cellValue(row, e.column.dataField, (value !== 999999 ? 999999 : 0));
        }
        this.dataGridInstance.cellValue(row, e.column.dataField, value);
    }

    gridOnEditCanceled = async (e) => {
        var id = this.dataGridInstance.getKeyByRowIndex(this.dataGridInstance.option('focusedRowIndex'));
        if (id) {
            await this.updateRow(id);
        }
    }
     
    gridOnCellHoverChanged = (e) => {
        if (e.rowType === 'data') {
            // hide hover color
            for (let col of this.dataGridInstance.option('columns')) {
                if (col.visible && (col.dataField == 'ShopName')) {
                    var cell = this.dataGridInstance.getCellElement(e.rowIndex, col.dataField);
                    if (cell !== undefined) {
                        if (this.UseColorsShops) {
                            cell.style.backgroundColor = (e.data.ColorHex !== null ? e.data.ColorHex : '#FFFFFF');
                            cell.style.color = (e.data.ForeColorHex !== null ? e.data.ForeColorHex : '#000000');
                        } else {
                            cell.style.backgroundColor = '#FFFFFF';
                            cell.style.color = '#000000';
                        }
                    }
                }
            }
        } 
    }

    gridOnExporting = (e) => {
        e.cancel = true;
        this.onExportShop();  // callback
    }

    gridOnInitialized = (e) => {
        // set instance
        this.dataGridInstance = e.component;
        this.onInitialized(e.component);  // callback

        // set focused row
        this.dataGridInstance.option('focusedRowIndex', 0);
    }

    gridOnToolbarPreparing = (e) => {
        let toolbarItems = e.toolbarOptions.items;
        // Adds a new item
        toolbarItems.push({
            widget: 'dxButton',
            options: {
                icon: 'filter',
                onClick: this.switchFilter
            },
            location: 'after'
        });
    }

    gridOnContentReady = (e) => {
         // check filters, 'shop', 'shop' and 'type' input fields become empty if cols added (dx bug)
         if (this.viewState.filters !== undefined) {
            for (let filter in this.viewState.filters) {
                if (this.state.dataSourceShops !== undefined && this.state.dataSourceShops.length > 0 && this.state.dataSourceShops[0].hasOwnProperty(filter)) {
                    if (this.dataGridInstance.columnOption(filter, 'filterValue') !== this.viewState.filters[filter]) {
                        this.dataGridInstance.columnOption(filter, 'filterValue', this.viewState.filters[filter]);
                    }
                }
            }
         }
    }

    gridOnContextMenuPreparing = (e) => {

        if (e.row.rowType !== 'data') {
            return;
        }

        // e.items can be undefined
        if (!e.items) e.items = [];

        // update selected and focused row (right click outside selection)
        //var selectedKeys = this.dataGridInstance.getSelectedRowKeys();
        //var focused = this.dataGridInstance.option("focusedRowKey");
        //if (!selectedKeys.includes(focused)) {
        //    this.dataGridInstance.deselectAll();
        //    this.dataGridInstance.selectedRowKeys = [focused];
        //    this.contextMenuMode = 'single';
        //}
        e.items.push({
            text: 'Delete ' + e.row.data.ShopName,
            onItemClick: () => {
                this.onDeleteShop(e.row.data.Id, e.row.data.ShopName);
            }
        });
    }


    switchFilter = () => {
        this.setState(prevState => ({
            showFilter: !prevState.showFilter
        }));
    }


    //HUB
    // update cells modified by other users (collaborative editing, SignalR)
    updatePushedDataEditShop = async (shopId) => {
        let isEditing = this.dataGridInstance.element().querySelector(".dx-datagrid-rowsview td.dx-editor-cell.dx-focused") != null;
        if (!isEditing) {
            await this.updateRow(shopId);
        }
    }

    updateRow = async (id) => {
        this.dataGridInstance.beginUpdate();
        var data = await ServiceLocations.getShopById(id);
        this.dataGridInstance.endUpdate();
        var dsRow = this.state.dataSourceShops.find(function (row) {
            return row.Id === id;
        });
        if (dsRow) {
            Object.entries(dsRow).forEach(([key, value]) => {
                dsRow[key] = data[key];
            });
        }
        var rowIndex = this.dataGridInstance.getRowIndexByKey(id);
        this.dataGridInstance.repaintRows(rowIndex);
        setTimeout(this.gridFocus, 10);
    }

    gridFocus = () => {
        this.dataGridInstance.focus();
    }

    updatePushedDataAddedShop = async (shopId) => {
         await this.gridRefresh();
    }

    updatePushedDataDeletedShop = async (shopId) => {
        await this.gridRefresh();
    }

    // RENDERING
    renderEditTemplate = (e) => {
        return <div style={{ display: 'flex', justifyContent: 'start' }}>
            <div style={{ width: '40px' }}><i className='dx-icon-edit' style={{ color: '#FF5722' }} /></div>
        </div>;
    }

    renderDeleteTemplate = (e) => {
        if (e.data.CanBeDeleted == true) {
            return <div style={{ display: 'flex', justifyContent: 'start' }}>
                <div style={{ width: '40px' }}><i className='dx-icon-trash' style={{ color: '#FF5722' }} /></div>
            </div>;
        } else {
            return <div style={{ display: 'flex', justifyContent: 'start' }}>
                <div style={{ width: '40px' }}><i className='dx-icon-trash' style={{ color: '#666666' }} /></div>
            </div>;
        }
    }

    render() {
        return (
            <React.Fragment>
                <div id="grid" className="rasdg-main-div">
                    <DataGrid
                        ref={ref => (this.refDataGrid = ref)}
                        height='100%'
                        width='100%'
                        dataSource={this.state.dataSourceShops}
                        keyExpr='Id'
                        allowColumnReordering={false}
                        allowColumnResizing={true}
                        allowGrouping={false}
                        columnResizingMode='widget'
                        columnAutoWidth={true}
                        focusedRowEnabled={false}
                        noDataText='No shops found.'
                        filterSyncEnabled={true}
                        preloadEnabled={true}
                        remoteOperations={true}
                        showBorders={false}
                        showColumnLines={true}
                        showRowLines={true}
                        wordWrapEnabled={false}
                        repaintChangesOnly={true}
                        onExporting={this.gridOnExporting}
                        onInitialized={this.gridOnInitialized}
                        onCellPrepared={this.gridOnCellPrepared}
                        onToolbarPreparing={this.gridOnToolbarPreparing}
                        onRowUpdating={this.gridOnRowUpdating}
                        onCellClick={this.gridOnCellClick}
                        onFocusedCellChanging={this.gridOnFocusedCellChanging}
                        onEditorPreparing={this.gridOnEditorPreparing}
                        onContentReady={this.gridOnContentReady}
                        onContextMenuPreparing={this.gridOnContextMenuPreparing}
                        onCellHoverChanged={this.gridOnCellHoverChanged}
                        onEditCanceled={this.gridOnEditCanceled}
                        onEditingStart={this.gridOnEditingStart}
                    >
                        <StateStoring enabled={false} type="localStorage" storageKey="storage" />
                        <Export
                            enabled={false}
                        />
                        <KeyboardNavigation
                            enabled={true}
                            editOnKeyPress={true}
                            enterKeyAction='moveFocus'
                            enterKeyDirection='column' />
                        <Editing
                            mode="cell"
                            allowUpdating={true}
                            selectTextOnEditStart={true}
                            startEditAction='dblClick'
                            step={0}
                        />
                        <Paging enabled={false} defaultPageSize={20} />
                        <Sorting mode="single" />
                        <ColumnChooser enabled={false} />
                        <FilterPanel visible={this.state.useAdvancedFiltering} />
                        <FilterRow visible={this.state.showFilter} />
                        <GroupPanel visible={false} />
                        <HeaderFilter visible={true} />
                        <SearchPanel visible={true} />
                        <Scrolling
                            mode="virtual"
                            preloadEnabled={true}
                            scrollByContent={true}
                            scrollByThumb={true}
                            showScrollbar='always'
                            useNative='false'
                        />
                        <Column
                            dataField='Id'
                            dataType='number'
                            caption='Id'
                            allowFiltering={false}
                            allowEditing={false}
                            fixed={true}
                            showInColumnChooser={false}
                            visible={false}
                        />
                        <Column
                            dataField='UsePattern'
                            dataType='number'
                            caption='UsePattern'
                            allowFiltering={false}
                            allowEditing={false}
                            fixed={true}
                            showInColumnChooser={false}
                            visible={false}
                        />
                        <Column
                            dataField='ColorId'
                            dataType='number'
                            caption='ColorId'
                            allowFiltering={false}
                            allowEditing={false}
                            fixed={true}
                            showInColumnChooser={false}
                            visible={false}
                        />
                        <Column
                            dataField='PatternId'
                            dataType='number'
                            caption='PatternId'
                            allowFiltering={false}
                            allowEditing={false}
                            fixed={true}
                            showInColumnChooser={false}
                            visible={false}
                        />
                        <Column
                            dataField='ColorHex'
                            dataType='string'
                            caption='ColorHex'
                            allowFiltering={false}
                            allowEditing={false}
                            fixed={true}
                            showInColumnChooser={false}
                            visible={false}
                        />
                        <Column
                            dataField='ForeColorHex'
                            dataType='string'
                            caption='ForeColorHex'
                            allowFiltering={false}
                            allowEditing={false}
                            fixed={true}
                            showInColumnChooser={false}
                            visible={false}
                        />
                        <Column
                            dataField='PatternColorHex'
                            dataType='string'
                            caption='PatternColorHex'
                            allowFiltering={false}
                            allowEditing={false}
                            fixed={true}
                            showInColumnChooser={false}
                            visible={false}
                        />
                        <Column
                            dataField='PatternCssBackGround'
                            dataType='string'
                            caption='PatternCssBackGround'
                            allowFiltering={false}
                            allowEditing={false}
                            fixed={true}
                            showInColumnChooser={false}
                            visible={false}
                        />
                        <Column
                            dataField='PatternCssBackGroundImage'
                            dataType='string'
                            caption='PatternCssBackGroundImage'
                            allowFiltering={false}
                            allowEditing={false}
                            fixed={true}
                            showInColumnChooser={false}
                            visible={false}
                        />
                        <Column
                            dataField='PatternCssBackGroundSize'
                            dataType='string'
                            caption='PatternCssBackGroundSize'
                            allowFiltering={false}
                            allowEditing={false}
                            fixed={true}
                            showInColumnChooser={false}
                            visible={false}
                        />
                        <Column
                            dataField='PatternCssBackGroundPosition'
                            dataType='string'
                            caption='PatternCssBackGroundPosition'
                            allowFiltering={false}
                            allowEditing={false}
                            fixed={true}
                            showInColumnChooser={false}
                            visible={false}
                        />
                        <Column
                            dataField='PatternTransparency'
                            dataType='number'
                            caption='PatternTransparency'
                            allowFiltering={false}
                            allowEditing={false}
                            fixed={true}
                            showInColumnChooser={false}
                            visible={false}
                        />
                        <Column
                            name='ActionColumnEdit'
                            caption=''
                            allowFiltering={false}
                            allowEditing={false}
                            allowColumnResizing={false}
                            allowColumnReordering={false}
                            allowExporting={false}
                            fixed={true}
                            showInColumnChooser={false}
                            visible={true}
                            width={40}
                            alignment='center'
                            cellRender={this.renderEditTemplate}
                        />
                        <Column
                            dataField='ShopName'
                            dataType='string'
                            caption='Shop'
                            width='300px'
                            allowFiltering={true}
                            allowEditing={true}
                            fixed={true}
                            showInColumnChooser={true}
                            visible={true}
                        />
                        <Column
                            name='ActionColumnDelete'
                            caption=''
                            allowFiltering={false}
                            allowEditing={false}
                            allowColumnResizing={false}
                            allowColumnReordering={false}
                            allowExporting={false}
                            fixed={true}
                            showInColumnChooser={false}
                            visible={true}
                            width={40}
                            alignment='center'
                            cellRender={this.renderDeleteTemplate}
                        />
                        <Column key='shopcoldummy' caption='' allowFiltering={false} allowResizing={true} allowExporting={false} width='100%' />
                    </DataGrid>
                </div>
                <LoadPanel
                    onInitialized={this.loadPanelOnInitialized}
                    shadingColor="rgba(0,0,0,0.4)"
                    position={this.loadingPosition}
                    shading={false}
                />
            </React.Fragment>
        );
    }
}

export default DataGridShops

