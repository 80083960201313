import React from 'react';
import { UsersAdmin } from '../../components';

class users extends React.Component {
    render() {
        return (
            <UsersAdmin />
        );
    }
}

export default users