import React from 'react';
import { Popup } from 'devextreme-react/popup';
import { Button } from 'devextreme-react/button';
import ReactToPrint from 'react-to-print';
import { LoadPanel } from 'devextreme-react/load-panel';

import ShuttlesDriverSheet from './ShuttlesDriverSheet';

import './PopupShuttlesDriverSheet.scss';

import globalConfig from '../../../../config/global.js';

//import './ShuttlesDriverSheet.scss'


// props
//      subtrips (required): array of subtrips
//
//      onInitialized: callback
//      onUpdate: callback
//      onLoadError: callback
//
//      refresh: callable

const PageWidth = 1170;

class PopupShuttlesDriverSheet extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dataSource: [],
            loadPanelVisible: false,
        }
 
        this.popupInstance = null;
        this.timing = '';
    }

    // EVENTS
    componentDidMount = async () => {
    }

    // CALLBACKS
    onInitialized = (instance) => {
        if (this.props.onInitialized) {
            this.props.onInitialized(instance);  // callback
        }
    }

    // CALLABLE
    setData = async (data) => {
        this.setState({ dataSource: data });

        let start = new Date(data.DateTimeDeparture);
        let end = new Date(data.DateTimeArrival);
        this.timing = start.getHours().toString().padStart(2, '0') + ':' + start.getMinutes().toString().padStart(2, '0') + ' - ' + end.getHours().toString().padStart(2, '0') + ':' + end.getMinutes().toString().padStart(2, '0')
    }

    // POPUP
    popup_OnInitialized = (e) => {
        this.popupInstance = e.component;

        // set callback
        this.onInitialized(e.component);
    }

    popup_OnShown = async (e) => {
        await this.refDriverSheet.setData(this.state.dataSource.Id);
    }

    refButtonPrint_OnClick = (e) => {
        this.popupInstance.hide();
        this.setState({ loadPanelVisible: true });
    }

    refDriverSheet_OnBeforePrint = (e) => {
        this.setState({ loadPanelVisible: false });
    }

    render() {
        return (
            <Popup
                dragEnabled={false}
                hideOnOutsideClick={false}
                showTitle={true}
                height={165}
                width={250}
                showCloseButton={true}
                onInitialized={this.popup_OnInitialized}
                onShown={this.popup_OnShown}
                title='Driversheet'
            >
                <div>
                    <div>{this.state.dataSource.ArtistName}</div>
                    <div>{this.state.dataSource.LocationDepartureName} - {this.state.dataSource.LocationArrivalName}</div>
                    <div>{this.timing}</div>
                    <ReactToPrint
                        trigger={() => <div style={{ display: 'flex', justifyContent: 'end' }}><Button
                            ref={ref => this.refButtonPrint = ref}
                            icon='print'
                            stylingMode='text'
                            text=''
                            onClick={this.refButtonPrint_OnClick}
                        /></div>}
                        content={() => this.refDriverSheet}
                        onBeforePrint={this.refDriverSheet_OnBeforePrint}
                        removeAfterPrint={true}
                    />
                    <div style={{ display: "none" }}>
                        <ShuttlesDriverSheet
                            ref={ref => (this.refDriverSheet = ref)}
                        />
                    </div>

                </div>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={false}
                    showPane={false}
                    hideOnOutsideClick={false}
                />
            </Popup>
        );
    }
}

export default PopupShuttlesDriverSheet
