import globalConfig from '../../config/global.js';

class ServiceColors {

    static getAllBackGroundPatterns = async () => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Colors/GetAllBackGroundPatterns") // return null or data object
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

}

export default ServiceColors;
