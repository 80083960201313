import React from 'react';
import { Popup } from 'devextreme-react/popup';
import TextBox from 'devextreme-react/text-box';
import Validator, { CustomRule, ValidationRule } from 'devextreme-react/validator';
import { Button } from 'devextreme-react/button';
import { ValidationGroup } from 'devextreme-react/validation-group';
import SelectBox from 'devextreme-react/select-box';
import { TagBox } from 'devextreme-react/tag-box';
import { CheckBox } from 'devextreme-react/check-box';
import * as dayJS from 'dayjs';
import Tabs from 'devextreme-react/tabs';

import ServiceGeneral from '../../../../api/services/ServiceGeneral'
import ServiceLocations from '../../../../api/services/ServiceLocations'

import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';

import validFilename from 'valid-filename';

import './PopupExportDayOverView.scss'


import PopupExportDayOverViewDataGrid from './PopupExportDayOverViewDataGrid';

// props
//
//      onInitialized: callback
//      onConnectionError: callback
     

class PopupExportDayOverView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dataSourceGigDates: [],
            dataSourceShops: [],
            obj: {
                previewTabs: [],
                headerText: '',
            },
            exportColors: false,
            gridList: <div></div>
        };

        this.dataGridInstances = [];

        this.popupInstance = null;
        this.textBoxNameInstance = null;

        this.checkBoxAutoNameInstance = null;

        this.tagBoxGigDatesInstance = null;
        this.selectedGigDateIds = [];

        this.selectBoxShopsInstance = null;
        this.selectedShopID = -1;

        this.checkBoxExportColorsInstance = null;
 
        this.buttonSaveInstance = null;
        this.buttonPreviewInstance = null;
        this.validationGroupInstance = null;

        this.previewTabsInstance = null;

        this.myRef = [];
        this.loadedGrids = 0;
        this.gridList = <div></div>;

        this.userSettings = {
            UserId: parseInt(sessionStorage.getItem("currentUserId"), 10),
            DefaultViewId: -1,
            DefaultFilterId: -1,
            ExportColors: true,
            MultiSelectionGigDates: false,
            MultiSelectionArtists: true,
            UseBlanksForZeroes: false,
            UseColorsProducts: true,
            UseColorsArtists: true,
            ColorOriginProducts: 'product',
            ColorOriginArtists: 'artist',
            ShowSelectedCell: false,
            UseAdvancedFiltering: false,
        }
    }


    // CALLBACKS
    onInitialized = (instance) => {
        if (this.props.onInitialized) {
            this.props.onInitialized(instance);
        }
    }

    onConnectionError = () => {
        if (this.props.onConnectionError) {
            this.props.onConnectionError();
        }
    }
    

    // DATA LOADS
    loadDataSourceGigDates = async () => {
        var data = await ServiceGeneral.getGigDatesByEventId(parseInt(sessionStorage.getItem("currentEventId"), 10), 'riders');
        if (data !== null && !data.hasOwnProperty("error")) {
            var convertedDataSourceGigDates = [];
            data.forEach((item, index, array) => {
                convertedDataSourceGigDates.push({ Id: item.Id, GigDate: dayJS(item.GigDate).format('ddd DD/MM/YY') });
            });
            this.setState({ dataSourceGigDates: convertedDataSourceGigDates })
        } else {
            this.onConnectionError();  // callback
        }
    }

    loadDataSourceShops = async () => {
        var data = await ServiceLocations.getShopsDropDown();
        if (data !== null && !data.hasOwnProperty("error")) {
            this.setState({ dataSourceShops: data })
        } else {
            this.onConnectionError();  // callback
        }
    }

    // GRID
    gridOnInitializedExport = async (instance, gigDateId) => {
        var inst = this.dataGridInstances.find(function (gridObject) {
            return gridObject.gigDateId == gigDateId;
        });
        if (inst == undefined) {
            var grid = { grid: instance, gigDateId: gigDateId };
            this.dataGridInstances.push(grid);
        }
    }

    gridOnDisposingExport = async (instance, gigDateId) => {
        for (var i = 0; i < this.dataGridInstances.length; i++) {
            var id = this.dataGridInstances[i].gigDateId;
            if (id === gigDateId) {
                var index = this.dataGridInstances.indexOf(this.dataGridInstances[i]);
                if (index > -1) {
                    this.dataGridInstances.splice(index, 1);
                }
            }
        }
    }

    gridOnContentReadyExport = async (instance, gigDateId) => {
        this.loadedGrids += 1;
        if (this.loadedGrids < this.selectedGigDateIds.length) {
            for (var i = 0; i < this.dataGridInstances.length; i++) {
                if (i === 0) {
                    this.dataGridInstances[i].grid.option('visible', true);
                } else {
                    this.dataGridInstances[i].grid.option('visible', false);
                }
            }
        } else if (this.loadedGrids == this.selectedGigDateIds.length) {
            this.buttonSaveInstance.option('disabled', false);
        }
    }

     // POPUP
    popup_OnInitialized = (e) => {
        this.popupInstance = e.component;
        this.onInitialized(e.component);  // callback
    }

    popup_OnShowing = (e) => {
        this.selectedGigDateIds = [];
        this.selectedShopID = -1;
    }

    popup_OnShown = async (e) => {
        this.textBoxNameInstance.option('value', 'Daglijst.xlsx');
        this.selectBoxShopsInstance.option('value', null);
        this.tagBoxGigDatesInstance.option("value", []);
        this.checkBoxExportColorsInstance.option('value', this.userSettings.ExportColors)

        document.getElementById('textBoxFileNameInput').select();

        this.tagBoxGigDatesInstance.focus();  // to prevent display bug
        this.textBoxNameInstance.focus();

        await this.loadDataSourceGigDates();

        await this.loadDataSourceShops();
    }

    textBoxAttributes = {
        id: 'textBoxFileNameInput',
    }

    // TEXTBOX NAME
    textBoxName_OnInitialized = (e) => {
        this.textBoxNameInstance = e.component;
    }

    textBoxName_OnFocusIn = (e) => {
        // select all text
        e.element.querySelector('.dx-texteditor-input').select();
    }

    // VALIDATIONGROUP
    validationGroup_OnInitialized = (e) => {
        this.validationGroupInstance = e.component;
    }

    // TAGBOX GIGDATES
    tagBoxGigDates_OnInitialized = (e) => {
        this.tagBoxGigDatesInstance = e.component;
    }

    tagBoxGigDates_OnSelectionChanged = async (e) => {
        if (this.buttonSaveInstance != null) {
            this.buttonSaveInstance.option('disabled', true);
        }
        this.loadedGrids = 0;
         
        this.selectedGigDateIds = this.tagBoxGigDatesInstance.option("selectedItems").map(function (item) {
            return item.Id;
        });

        var tabs = [];
        for (var i = 0; i < this.selectedGigDateIds.length; i++) {
            let gigDate = this.state.dataSourceGigDates.find(x => x.Id == this.selectedGigDateIds[i]);
            tabs.push(gigDate.GigDate);
        }

        let header = '';
        if (this.checkBoxAutoNameInstance.option('value') == true) {
            if (this.selectedGigDateIds.length > 0) {
                if (this.selectedGigDateIds.length === 1) {
                    header = this.tagBoxGigDatesInstance.option("selectedItems")[0].GigDate;
                    this.textBoxNameInstance.option('value', 'Daglijst ' + this.tagBoxGigDatesInstance.option("selectedItems")[0].GigDate.replace('/', '-').replace('/', '-') + '.xlsx');
                } else {
                    this.textBoxNameInstance.option('value', 'Daglijsten.xlsx');
                }
            } else {
                this.textBoxNameInstance.option('value', 'Daglijst.xlsx');
            }
        }

        this.setState({ obj: { previewTabs: tabs, headerText: header } });
        this.setState({ gridList: <div></div> });
    }

    tagBoxGigDates_OnMultiTagPreparing = (args) => {
        const selectedItemsLength = args.selectedItems.length;
        let data = this.tagBoxGigDatesInstance.option('items');
        const totalCount = data.length;
        if (selectedItemsLength == totalCount) {
            args.text = 'All (' + selectedItemsLength + ')';
        }
    }

    // SELECTBOX SHOPS
    selectBoxShops_OnInitialized = (e) => {
        this.selectBoxShopsInstance = e.component;
    }

    selectBoxShops_OnSelectionChanged = async (e) => {
        this.selectedShopID = e.selectedItem !== null ? e.selectedItem.Id : -1;

        const selectedItems = this.tagBoxGigDatesInstance.option('value');
        this.tagBoxGigDatesInstance.option('value', []);
        this.tagBoxGigDatesInstance.option('value', selectedItems);

        this.setState({ gridList: <div></div> });
    }


    // CHECKBOX EXPORT COLORS
    checkBoxExportColors_OnInitialized = (e) => {
        this.checkBoxExportColorsInstance = e.component;
    }

    checkBoxExportColors_OnValueChanged = (e) => {
        this.setState({ exportColors: e.value });

        const selectedItems = this.tagBoxGigDatesInstance.option('value');
        this.tagBoxGigDatesInstance.option('value', []);
        this.tagBoxGigDatesInstance.option('value', selectedItems);
    }

    // CHECKBOX AUTO NAME
    checkBoxAutoName_OnInitialized = (e) => {
        this.checkBoxAutoNameInstance = e.component;
    }

    checkBoxAutoName_OnValueChanged = (e) => {
        if (e.value == true) {
            if (this.selectedGigDateIds.length > 0) {
                if (this.selectedGigDateIds.length === 1) {
                    this.textBoxNameInstance.option('value', 'Daglijst ' + this.state.obj.headerText.replace('/', '-').replace('/', '-') + '.xlsx');
                } else {
                    this.textBoxNameInstance.option('value', 'Daglijsten.xlsx');
                }
            } else {
                this.textBoxNameInstance.option('value', 'Daglijst.xlsx');
            }
        }
    }

    // BUTTON SAVE
    buttonSave_OnClick = (e) => {
        this.validationGroupInstance.validate();

        if (this.textBoxNameInstance.option('isValid') === true) {
            this.exportToExcel(this.textBoxNameInstance.option('value').trim().replace(/.xlsx$/gi, '') + '.xlsx');
            //this.popupInstance.hide();
        } else {
            this.textBoxNameInstance.focus();
        }
    }

    buttonSave_OnInitialized = (e) => {
        this.buttonSaveInstance = e.component;
    }

    buttonPreview_OnClick = (e) => {
        this.validationGroupInstance.validate();

        if (this.textBoxNameInstance.option('isValid') === true) {
            this.setState({ gridList: this.createGrids() });
        }
    }

    buttonPreview_OnInitialized = (e) => {
        this.buttonPreviewInstance = e.component;
    }

    previewTabs_OnItemClick = (e) => {
        for (var i = 0; i < this.selectedGigDateIds.length; i++) {
            var id = this.selectedGigDateIds[i];
            var inst = this.dataGridInstances.find(function (grid) {
                return grid.gigDateId == id;
            });
            if (inst !== undefined) {
                inst.grid.option('visible', false);
            }
        }

        var id = this.selectedGigDateIds[e.itemIndex];
        var inst = this.dataGridInstances.find(function (grid) {
            return grid.gigDateId == id;
        });
        if (inst !== undefined) {
            inst.grid.option('visible', true);
        }
    }

    // TREVIEW TABS
    previewTabs_OnInitialized = (e) => {
        this.previewTabsInstance = e.component;
    }

    // EXCEL
    exportToExcel = async (name) => {

        const workbook = new Workbook();

        for (var i = 0; i < this.dataGridInstances.length; i++) {
            let gigDate = this.state.dataSourceGigDates.find(x => x.Id == this.dataGridInstances[i].gigDateId);
            let formattedGigDate = gigDate.GigDate.replace(/\//g, '-');
            var sheet = workbook.addWorksheet(formattedGigDate);
            sheet.columns = [
                { width: 25 }, { width: 50 }, { width: 12 }
            ];
            sheet.pageSetup.margins = {
                left: 0.5, right: 0.5,
                top: 0.5, bottom: 0.5,
                header: 0, footer: 0
            };
            if (i < this.dataGridInstances.length - 1) {
                exportDataGrid({
                    worksheet: sheet,
                    component: this.dataGridInstances[i].grid,
                    customizeCell: this.customizeExcelCell,
                    keepColumnWidths: false
                })
            } else {
                exportDataGrid({
                    worksheet: sheet,
                    component: this.dataGridInstances[i].grid,
                    customizeCell: this.customizeExcelCell,
                    keepColumnWidths: false
                }).then(() => {
                    workbook.xlsx.writeBuffer().then((buffer) => {
                        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), name);
                    });
                })
            }
        }
    };

    // set excel cell layout
    customizeExcelCell = (options) => {
        const { gridCell, excelCell } = options;

        if (gridCell.rowType === 'data') {
            if (this.checkBoxExportColorsInstance.option('value') == true) {
                if (gridCell.column.dataField === 'Shops' || gridCell.column.dataField === 'ProductName') {
                    let bg = (gridCell.data.ColorHex !== null ? gridCell.data.ColorHex.replace('#', '').slice(0,6) : 'FFFFFF');
                    let fg = (gridCell.data.ForeColorHex !== null ? gridCell.data.ForeColorHex.replace('#', '').slice(0, 6) : '000000');
                    excelCell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: bg }
                    };
                    excelCell.font = {
                        color: { argb: fg }
                    }
                }
            }
        } else if (gridCell.rowType === 'header') {
            if (this.checkBoxExportColorsInstance.option('value')) {
                let bg = 'FFFFFF';
                let fg = '000000';
                let s = 12;
                let alignment = { vertical: 'middle', horizontal: 'left' };
                if (gridCell.column.dataField == undefined) {
                    if (gridCell.column.cssClass !== undefined) {
                        const element = document.querySelector('.' + gridCell.column.cssClass);
                        if (element !== null) {
                            const style = getComputedStyle(element);
                            let rgb = style.backgroundColor.replace(/^rgb?\(|\s+|\)$/g, '').split(',');
                            bg = `${((1 << 24) + (parseInt(rgb[0]) << 16) + (parseInt(rgb[1]) << 8) + parseInt(rgb[2])).toString(16).slice(1)}`;
                            rgb = style.color.replace(/^rgb?\(|\s+|\)$/g, '').split(',');
                            fg = `${((1 << 24) + (parseInt(rgb[0]) << 16) + (parseInt(rgb[1]) << 8) + parseInt(rgb[2])).toString(16).slice(1)}`;
                        }
                    } else if (gridCell.column.isBand) {
                        bg = '000000';
                        fg = 'FFFFFF';
                    } 
                    s = 16;
                } else if (gridCell.column.dataField.endsWith('_Amount')) {
                    alignment = { vertical: 'middle', horizontal: 'right' };
                    if (gridCell.column.cssClass !== undefined) {
                        const element = document.querySelector('.' + gridCell.column.cssClass);
                        if (element !== null) {
                            const style = getComputedStyle(element);
                            let rgb = style.backgroundColor.replace(/^rgb?\(|\s+|\)$/g, '').split(',');
                            bg = `${((1 << 24) + (parseInt(rgb[0]) << 16) + (parseInt(rgb[1]) << 8) + parseInt(rgb[2])).toString(16).slice(1)}`;
                            rgb = style.color.replace(/^rgb?\(|\s+|\)$/g, '').split(',');
                            fg = `${((1 << 24) + (parseInt(rgb[0]) << 16) + (parseInt(rgb[1]) << 8) + parseInt(rgb[2])).toString(16).slice(1)}`;
                        }
                    }
                } else if (gridCell.column.dataField.endsWith('_Comment')) {
                    alignment = { vertical: 'middle', horizontal: 'left' };
                    if (gridCell.column.cssClass !== undefined) {
                        const element = document.querySelector('.' + gridCell.column.cssClass);
                        if (element !== null) {
                            const style = getComputedStyle(element);
                            let rgb = style.backgroundColor.replace(/^rgb?\(|\s+|\)$/g, '').split(',');
                            bg = `${((1 << 24) + (parseInt(rgb[0]) << 16) + (parseInt(rgb[1]) << 8) + parseInt(rgb[2])).toString(16).slice(1)}`;
                            rgb = style.color.replace(/^rgb?\(|\s+|\)$/g, '').split(',');
                            fg = `${((1 << 24) + (parseInt(rgb[0]) << 16) + (parseInt(rgb[1]) << 8) + parseInt(rgb[2])).toString(16).slice(1)}`;
                        }
                    }
                }

                excelCell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: bg }
                };

                excelCell.font = {
                    color: { argb: fg },
                    size: s
                }

                excelCell.alignment = alignment;
            }
        }

        excelCell.border = {
            top: { style: 'thin', color: { argb: 'FF000000' } },
            left: { style: 'thin', color: { argb: 'FF000000' } },
            bottom: { style: 'thin', color: { argb: 'FF000000' } },
            right: { style: 'thin', color: { argb: 'FF000000' } }
        };

    }



    validateFileName = () => {
        let isValid = false;

        if (validFilename(this.textBoxNameInstance.option('value').replace(/.xlsx$/gi, '') + '.xlsx')) {
            isValid = true;
        }

        return isValid;
    }

    createGrids = () => {
        var gridList = [];
        var outList = [];
        for (var i = 0; i < this.selectedGigDateIds.length; i++) {
            gridList.push(
                <PopupExportDayOverViewDataGrid
                    //ref={(ref) => { this.myRef[i] = ref; return true; }}
                    key={`grid-child-${this.selectedGigDateIds[i]}`}
                    onInitialized={this.gridOnInitializedExport}
                    onDisposing={this.gridOnDisposingExport}
                    onContentReady={this.gridOnContentReadyExport}
                    exportColors={this.state.exportColors}
                    selectedGigDateId={this.selectedGigDateIds[i]}
                    selectedShopId={this.selectedShopID}
                    visible={true}
                />
            );
        }
        outList.push(gridList);

        return outList;
    };

    render() {
         return (
            <React.Fragment>
                <Popup
                    dragEnabled={false}
                    hideOnOutsideClick={false}
                    showTitle={true}
                    showCloseButton={true}
                    title='Export shoppinglist ...'
                    width={900}
                    height={700}
                    onInitialized={this.popup_OnInitialized}
                    onShowing={this.popup_OnShowing}
                    onShown={this.popup_OnShown}
                 >
                   
                     <ValidationGroup onInitialized={this.validationGroup_OnInitialized}>
                         <div className="dx-card pedo-top-wrapper">
                             <div className="pedo-dx-field">
                                 <div className="dx-field-label pedo-label">Name*</div>
                                 <div className="pedo-name-div">
                                     <div className="dx-field-value pedo-name">
                                         <TextBox
                                             onInitialized={this.textBoxName_OnInitialized}
                                             defaultValue=''
                                             width={200}
                                             inputAttr={this.textBoxAttributes}
                                             onFocusIn={this.textBoxName_OnFocusIn}
                                             hint='Enter name ...'
                                         >
                                             <Validator>
                                                 <ValidationRule type="required" message="Required" />
                                                 <CustomRule validationCallback={this.validateFileName} message="Invalid file name" />
                                             </Validator>
                                         </TextBox>
                                     </div>
                                     <div className="pedo-checkbox-auto">
                                         <CheckBox
                                             defaultValue={true}
                                             text="Auto"
                                             onInitialized={this.checkBoxAutoName_OnInitialized}
                                             onValueChanged={this.checkBoxAutoName_OnValueChanged}
                                         />
                                     </div>
                                 </div>
                             </div>
                             <div className="pedo-dx-field pedo-field-dates-shop">
                                 <div className="dx-field-label pedo-label">Dates *</div>
                                 <div className="dx-field-value pedo-field-value-name">
                                     <TagBox
                                         dataSource={this.state.dataSourceGigDates}
                                         valueExpr="Id"
                                         displayExpr="GigDate"
                                         multiline={true}
                                         maxDisplayedTags={1}
                                         showMultiTagOnly={true}
                                         showSelectionControls={true}
                                         width={175}
                                         showClearButton={false}
                                         onInitialized={this.tagBoxGigDates_OnInitialized}
                                         onSelectionChanged={this.tagBoxGigDates_OnSelectionChanged}
                                         onMultiTagPreparing={this.tagBoxGigDates_OnMultiTagPreparing}
                                     />
                                 </div>
                                 <div className="dx-field-label pedo-label-shops">Shop</div>
                                 <div className="dx-field-value">
                                     <SelectBox
                                         dataSource={this.state.dataSourceShops}
                                         valueExpr="Id"
                                         displayExpr="Name"
                                         searchEnabled={true}
                                         showClearButton={true}
                                         showDataBeforeSearch={false}
                                         minSearchLength={0}
                                         width={200}
                                         onInitialized={this.selectBoxShops_OnInitialized}
                                         onSelectionChanged={this.selectBoxShops_OnSelectionChanged}
                                     />
                                 </div>
                             </div>

                            <div className='pedo-button-preview'>
                                 <Button
                                     width={120}
                                     text="Preview"
                                     className='dx-button'
                                     onClick={this.buttonPreview_OnClick}
                                     onInitialized={this.buttonPreview_OnInitialized}
                                 />
                             </div>
                         </div>
                     </ValidationGroup>

                    <div className="dx-card pedo-tabs">
                        <Tabs
                            dataSource={this.state.obj.previewTabs}
                            scrollByContent={true}
                            showNavButtons={true}
                            onItemClick={this.previewTabs_OnItemClick}
                            onInitialized={this.previewTabs_OnInitialized}
                        />
                        <div>{this.state.gridList}</div>
                    </div>

                     <div className="pedo-export">
                         <div className="pedo-checkbox-colors-export">
                             <CheckBox
                                 defaultValue={false}
                                 text="Export colors"
                                 onInitialized={this.checkBoxExportColors_OnInitialized}
                                 onValueChanged={this.checkBoxExportColors_OnValueChanged}
                             />
                         </div>
                         <div className='pedo-button-export'>
                             <Button
                                 width={120}
                                 text="Export"
                                 className='dx-button'
                                 onClick={this.buttonSave_OnClick}
                                 onInitialized={this.buttonSave_OnInitialized}
                             />
                         </div>
                     </div>
                </Popup>


            </React.Fragment>

        );

    }

}

export default PopupExportDayOverView

