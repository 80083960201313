import React from 'react';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { Popup } from 'devextreme-react/popup';
import TextBox from 'devextreme-react/text-box';
import Validator, { AsyncRule, ValidationRule, EmailRule, RangeRule } from 'devextreme-react/validator';
import { Button } from 'devextreme-react/button';
import { ValidationGroup, dxValidationGroupResult } from 'devextreme-react/validation-group';
import HtmlEditor, {
    Toolbar, MediaResizing, ImageUpload, Item,
} from 'devextreme-react/html-editor';
import { CheckBox } from 'devextreme-react/check-box';
import SelectBox from 'devextreme-react/select-box';
import { LoadPanel } from 'devextreme-react/load-panel';
import { confirm } from 'devextreme/ui/dialog';

import ServiceTrips from '../../../../api/services/ServiceTrips'
import ServiceDrivers from '../../../../api/services/ServiceDrivers'
import ServiceLocations from '../../../../api/services/ServiceLocations'
import ServiceFleet from '../../../../api/services/ServiceFleet'
import ServiceArtists from '../../../../api/services/ServiceArtists'
import ServiceEvents from '../../../../api/services/ServiceEvents'
import ServiceGeneral from '../../../../api/services/ServiceGeneral'

import HxToast from '../../../custom-components/hx-toast/HxToast';

import PopupShuttlesAdminLocationsEdit from '../../shuttles-admin/shuttles-admin-locations/sub-components/PopupShuttlesAdminLocationsEdit'

import { NumberBox } from 'devextreme-react/number-box';

import ShuttlesPreview from './ShuttlesPreview';

import './PopupShuttlesEdit.scss'


// props
//      id (required): shuttle id
//
//      onInitialized: callback
//      onUpdate: callback
//      onLoadError: callback
//      onHiding: callback

class PopupShuttlesEdit extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dataSourceLocations: [],
            dataSourceDrivers: [],
            dataSourceArtists: [],
            dataSourceFleet: [],
            dataSourceTripTimings: [],
            dataSourceTripStatusses: [],
            loadPanelVisible: false,
            popupHeight: '810px',
            locationId: -1,
        }

        this.tripId = -1;
        this.eventLocationId = -1;
        this.formClosing = false;

        this.popupInstance = null;
        this.validationGroupInstance = null;

        this.selectBoxArtistsInstance = null;
        this.selectBoxLocationDepartureInstance = null;
        this.selectBoxLocationArrival1Instance = null;
        this.selectBoxLocationArrival2Instance = null;
        this.selectBoxFleetInstance = null;

        this.textBoxPaxInstance = null;
        this.checkBoxTrailerInstance = null;
        this.selectBoxDriverInstance = null;
        this.selectBoxTripStatussesInstance = null;
        this.htmlEditorNotesInstance = null;

        this.buttonAddArrival2Instance = null;
        this.buttonRemoveArrival2Instance = null;
        this.buttonLocationInstance = null;

        this.checkBoxKeepOpenInstance = null;
        this.buttonUpdateInstance = null;

        this.tripRecord = {};
        this.tripDefaultRecord = {
            Id: -1,
            EventId: parseInt(sessionStorage.getItem('currentEventId'), 10),
            ArtistId: -1,
            DriverId: -1,
            CarId: -1,
            StatusId: -1,
            Pax: 0,
            Trailer: false,
            Notes: '',
            SubTrips: [],
        }

        this.FavouriteLocations = [];
        this.eventDates = [];

        this.refShuttlesPreview = React.createRef();

        this.doNotExecutePrepare = false;

        this.statusWaitingId = null;
        this.driverTbaId = null;
        this.crudDate = null;

        this.popupShuttlesAdminLocationsEditInstance = null;

        this.hubConnection = null;
        this.hubConnectionLocation = null;
    }

    // CALLBACKS
    onInitialized = (instance) => {
        if (this.props.onInitialized) {
            this.props.onInitialized(instance);  // callback
        }
    }

    onLoadError = () => {
        if (this.props.onLoadError) {
            this.props.onLoadError();  // callback
        }
    }

    onAdd = (success, crudDate) => {
        if (this.props.onAdd) {
            this.props.onAdd(success, crudDate);  // callback
        }
    }

    onUpdate = (success, crudDate) => {
        if (this.props.onUpdate) {
            this.props.onUpdate(success, crudDate);  // callback
        }
    }

    onHiding = () => {
        if (this.props.onHiding) {
            this.props.onHiding();  // callback
        }
    }
    // CALLABLES
    preload = async () => {
        await this.loadAllDataSources();

        this.hubConnection = new HubConnectionBuilder().withUrl("/tripTimingsHub").build();
        await this.hubConnection.start()
            .then(function () {

            })
            .catch(function (err) {
                return console.error(err.toString());
            });

        this.hubConnectionLocation = new HubConnectionBuilder().withUrl("/locationsHub").build();
        await this.hubConnectionLocation.start()
            .then(function () {

            })
            .catch(function (err) {
                return console.error(err.toString());
            });

        this.hubConnection.on("ReceiveMessageAddedTripTiming", await this.updatePushedDataAddedTripTiming);
        this.hubConnectionLocation.on("ReceiveMessageAddedLocation", await this.updatePushedDataAddedLocation);
    }

    loadAllDataSources = async () => {
        await this.loadDataSourceEventDates();
        await this.loadDataSourceTripStatusses();

        await this.loadDataSourceTripTimings();
        await this.loadDataSourceEvent();
        await this.loadDataSourceLocations();
        await this.loadDataSourceDrivers();
        await this.loadDataSourceArtists();
        await this.loadDataSourceFleet();
    }

    // EVENTS
    componentDidMount = async () => {
        
   }

    // DATA
    loadDataSourceEvent = async () => {
        var data = await ServiceEvents.getEventByEventId(parseInt(sessionStorage.getItem('currentEventId'), 10));
        if (data !== null && !data.hasOwnProperty("error")) {
            this.eventLocationId = data.LocationId;
        } else {
            this.onLoadError();  // callback
        }
    }

    loadDataSourceEventDates = async () => {
        var data = await ServiceEvents.getEventDatesDropDownByEventId(parseInt(sessionStorage.getItem('currentEventId'), 10), 'shuttles');
        if (data !== null && !data.hasOwnProperty("error")) {
            this.eventDates = data;
        } else {
            this.onLoadError();  // callback
        }
    }

    loadDataSourceLocations = async () => {
        var data = await ServiceLocations.getLocationsDropDownForTripTimingByEventId(parseInt(sessionStorage.getItem('currentEventId'), 10));
        if (data !== null && !data.hasOwnProperty("error")) {
            this.setState({ dataSourceLocations: data });
            this.FavouriteLocations = data.filter((item) => {
                return item.IsFavourite == true;
            });
        } else {
            this.onLoadError();  // callback
        }
    }

    loadDataSourceDrivers = async () => {
        var data = await ServiceDrivers.getAllDriversDropDownByEventId(parseInt(sessionStorage.getItem('currentEventId'), 10));
        if (data !== null && !data.hasOwnProperty("error")) {
            this.setState({ dataSourceDrivers: data });
            let tba = data.find(s => s.Name.toUpperCase() === 'TO BE ANNOUNCED');
            this.driverTbaId = (tba !== undefined ? tba.Id : null);
    } else {
            this.onLoadError();  // callback
        }
    }

    loadDataSourceArtists = async () => {
        var data = await ServiceArtists.getAllArtistsDropDownByEventId(parseInt(sessionStorage.getItem('currentEventId'), 10), 'shuttles');
        if (data !== null && !data.hasOwnProperty("error")) {
            this.setState({ dataSourceArtists: data });
        } else {
            this.onLoadError();  // callback
        }
    }

    loadDataSourceFleet = async () => {
        var data = await ServiceFleet.getFleetDropDownByEventId(parseInt(sessionStorage.getItem('currentEventId'), 10));
        if (data !== null && !data.hasOwnProperty("error")) {
            this.setState({ dataSourceFleet: data });
        } else {
            this.onLoadError();  // callback
        }
    }

    loadDataSourceTrip = async (id) => {
        var data = await ServiceTrips.getTripInfoById(id);
        if (data !== null && !data.hasOwnProperty("error")) {
            this.tripRecord = data;
        } else {
            this.onLoadError();  // callback
        }
    }

    loadDataSourceTripTimings = async () => {
        var data = await ServiceGeneral.getAllTripTimingsByEventId(parseInt(sessionStorage.getItem('currentEventId'), 10));
        if (data !== null && !data.hasOwnProperty("error")) {
            this.setState({ dataSourceTripTimings: data });
        } else {
            this.onLoadError();  // callback
        }
    }

    loadDataSourceTripStatusses = async () => {
        var data = await ServiceGeneral.getAllTripStatussesDropDown();
        if (data !== null && !data.hasOwnProperty("error")) {
            this.setState({ dataSourceTripStatusses: data });
            let waiting = data.find(s => s.Name.toUpperCase() === 'WAITING');
            this.statusWaitingId = (waiting !== undefined ? waiting.Id : null);
        } else {
            this.onLoadError();  // callback
        }
    }

    // POPUP
    popup_OnInitialized = (e) => {
        this.popupInstance = e.component;

        // set callback
        this.onInitialized(e.component);
    }

    popup_OnShowing = async (e) => {
        this.formClosing = false;

        //await this.loadDataSourceEventDates();
        //await this.loadDataSourceTripStatusses();

        if (this.props.id !== undefined) {
            this.tripId = this.props.id;
        }

        this.tripRecord = this.tripDefaultRecord;

        this.refShuttlesPreview.current.clear();
        this.refShuttlesPreview.current.setEventDates(this.eventDates);


    }

    popup_OnHiding = (e) => {
        this.formClosing = true;

        this.selectBoxArtistsInstance.option('value', null);
        this.textBoxPaxInstance.option('value', null);
        this.selectBoxLocationDepartureInstance.option('value', null);
        this.selectBoxLocationArrival1Instance.option('value', null);
        this.buttonAddArrival2Instance.option('disabled', false);
        document.getElementById('arrival2Div').style.display = "none";
        document.getElementById('arrival2ButtonsDiv').style.display = "none";
        this.selectBoxLocationArrival2Instance.option('value', null);
        this.selectBoxFleetInstance.option('value', null);
        this.checkBoxTrailerInstance.option('value', false);
        this.selectBoxDriverInstance.option('value', null);
        this.selectBoxTripStatussesInstance.option('value', null);
        this.htmlEditorNotesInstance.option('value', null);

        this.refShuttlesPreview.current.setSelectedEventDate(null);

        this.setState({ popupHeight: '810px' });

        this.validationGroupInstance.reset();

        this.onHiding();  // callback
    }

    popup_OnShown = async (e) => {
        this.setState({ loadPanelVisible: true });

        //await this.loadDataSourceTripTimings();
        //await this.loadDataSourceEvent();
        //await this.loadDataSourceLocations();
        //await this.loadDataSourceDrivers();
        //await this.loadDataSourceArtists();
        //await this.loadDataSourceFleet();

        this.doNotExecutePrepare = true;

        if (this.tripId == -1) {
            setTimeout(this.setInitialValues, 100);

            this.refShuttlesPreview.current.setSelectedEventDate(null);

            this.validationGroupInstance.reset();

            this.popupInstance.option('title', 'Add new shuttle');

        } else {
            // edit trip
            await this.loadDataSourceTrip(this.tripId);

            this.doNotExecutePrepare = true;

            let depId = -1;
            let arrIds = [];

            for (var i = 0; i < this.tripRecord.SubTrips.length; i++) {
                if (this.tripRecord.SubTrips[i].IsPickupDeparture == true) {
                    depId = this.tripRecord.SubTrips[i].LocationDepartureId;
                    break;
                }
            }

            for (var i = 0; i < this.tripRecord.SubTrips.length; i++) {
                if (this.tripRecord.SubTrips[i].IsDropOffArrival == true) {
                    arrIds.push(this.tripRecord.SubTrips[i].LocationArrivalId);
                }
            }

            this.setState({ popupHeight: (arrIds.length == 1 ? '810px' : '895px') });

            this.selectBoxArtistsInstance.option('value', this.tripRecord.ArtistId);
            this.textBoxPaxInstance.option('value', this.tripRecord.Pax);
            this.selectBoxLocationDepartureInstance.option('value', depId);
            this.selectBoxLocationArrival1Instance.option('value', arrIds[0]);
            if (arrIds.length > 1) {
                document.getElementById('arrival2Div').style.display = "flex";
                document.getElementById('arrival2ButtonsDiv').style.display = "flex";
                this.selectBoxLocationArrival2Instance.option('value', arrIds[1]);

            } else {
                document.getElementById('arrival2Div').style.display = "none";
                document.getElementById('arrival2ButtonsDiv').style.display = "none";
                this.selectBoxLocationArrival2Instance.option('value', null);
            }
            this.selectBoxFleetInstance.option('value', this.tripRecord.CarId);
            this.checkBoxTrailerInstance.option('value', this.tripRecord.Trailer)
            this.selectBoxDriverInstance.option('value', this.tripRecord.DriverId);
            this.selectBoxTripStatussesInstance.option('value', this.tripRecord.StatusId);
            this.htmlEditorNotesInstance.option('value', this.tripRecord.Notes);

            this.refShuttlesPreview.current.refresh(this.tripRecord.SubTrips);
            this.refShuttlesPreview.current.setSelectedEventDate(this.tripRecord.SubTrips[0].DateTimeDeparture);

            this.popupInstance.option('title', 'Update shuttle');
        }

        this.checkBoxKeepOpenInstance.option('value', false);
        this.buttonUpdateInstance.option('text', 'Save');
        this.buttonUpdateInstance.option('width', '120px');

        this.doNotExecutePrepare = false;

        this.selectBoxArtistsInstance.focus();

        this.setState({ loadPanelVisible: false });
    }

    setInitialValues = () => {
        this.selectBoxArtistsInstance.option('value', null);
        this.textBoxPaxInstance.option('value', null);
        this.selectBoxLocationDepartureInstance.option('value', null);
        this.selectBoxLocationArrival1Instance.option('value', null);
        this.buttonAddArrival2Instance.option('disabled', false);
        document.getElementById('arrival2Div').style.display = "none";
        document.getElementById('arrival2ButtonsDiv').style.display = "none";
        this.selectBoxLocationArrival2Instance.option('value', null);
        this.selectBoxFleetInstance.option('value', null);
        this.checkBoxTrailerInstance.option('value', false);
        this.selectBoxDriverInstance.option('value', (this.tripId == -1 ? this.driverTbaId : null));
        this.selectBoxTripStatussesInstance.option('value', this.statusWaitingId);
        this.htmlEditorNotesInstance.option('value', null);

        this.refShuttlesPreview.current.setSelectedEventDate(null);
    }

    getTripTiming = (locationDepartureId, locationArrivalId) => {

        const t = this.state.dataSourceTripTimings.find(element => element.LocationDepartureId == locationDepartureId && element.LocationArrivalId == locationArrivalId);

        return t;
    }

    prepareSubTrips = () => {
        if (this.formClosing) {
            return;
        }
        if (this.doNotExecutePrepare == true) {
            return;
        }

        const locationDepartureId = this.selectBoxLocationDepartureInstance.option('value');
        const locationArrival1Id = this.selectBoxLocationArrival1Instance.option('value');
        const locationArrival2Id = this.selectBoxLocationArrival2Instance.option('value');

        let subTripsArr = [];
        let tripOrder = -1;
        let dt = new Date();
        dt.setHours(0, 0, 0, 0);


        if (locationDepartureId != null && (locationArrival1Id != null || locationArrival2Id != null)) {
            if (locationArrival2Id == null) {
                tripOrder += 1;
                const tripTiming = this.getTripTiming(locationDepartureId, locationArrival1Id);
                const subTrip = {
                    Id: -1,
                    LocationDepartureId: locationDepartureId,
                    LocationArrivalId: locationArrival1Id,
                    DateTimeDeparture: dt,
                    DateTimeArrival: dt,
                    WaitTimePickup: 0,
                    IsPickupDeparture: true,
                    IsDropOffArrival: true,
                    PaxPickup: this.textBoxPaxInstance.option('value'),
                    PaxDropOff: this.textBoxPaxInstance.option('value'),
                    MinutesMin: tripTiming != undefined ? tripTiming.MinutesMin : 0,
                    MinutesMax: tripTiming != undefined ? tripTiming.MinutesMax : 0,
                    TripOrder: tripOrder,
                    LocationDeparture: this.selectBoxLocationDepartureInstance.option('text'),
                    LocationArrival: this.selectBoxLocationArrival1Instance.option('text'),
                }
                subTripsArr.push(subTrip);
            } else {
                tripOrder += 1;
                const tripTiming = this.getTripTiming(locationDepartureId, locationArrival1Id);
                const subTrip = {
                    Id: -1,
                    LocationDepartureId: locationDepartureId,
                    LocationArrivalId: locationArrival1Id,
                    DateTimeDeparture: dt,
                    DateTimeArrival: dt,
                    WaitTimePickup: 0,
                    IsPickupDeparture: true,
                    IsDropOffArrival: true,
                    PaxPickup: this.textBoxPaxInstance.option('value'),
                    PaxDropOff: this.textBoxPaxInstance.option('value'),
                    MinutesMin: tripTiming != undefined ? tripTiming.MinutesMin : 0,
                    MinutesMax: tripTiming != undefined ? tripTiming.MinutesMax : 0,
                    TripOrder: tripOrder,
                    LocationDeparture: this.selectBoxLocationDepartureInstance.option('text'),
                    LocationArrival: this.selectBoxLocationArrival1Instance.option('text'),
                }
                subTripsArr.push(subTrip);

                tripOrder += 1;
                const tripTiming2 = this.getTripTiming(locationArrival1Id, locationArrival2Id);
                const subTrip2 = {
                    Id: -1,
                    LocationDepartureId: locationArrival1Id,
                    LocationArrivalId: locationArrival2Id,
                    DateTimeDeparture: dt,
                    DateTimeArrival: dt,
                    WaitTimePickup: 0,
                    IsPickupDeparture: true,
                    IsDropOffArrival: true,
                    PaxPickup: this.textBoxPaxInstance.option('value'),
                    PaxDropOff: this.textBoxPaxInstance.option('value'),
                    MinutesMin: tripTiming2 != undefined ? tripTiming2.MinutesMin : 0,
                    MinutesMax: tripTiming2 != undefined ? tripTiming2.MinutesMax : 0,
                    TripOrder: tripOrder,
                    LocationDeparture: this.selectBoxLocationArrival1Instance.option('text'),
                    LocationArrival: this.selectBoxLocationArrival2Instance.option('text'),
               }
                subTripsArr.push(subTrip2);
            }

            // First add trip to gmm if necessary
            if (locationArrival2Id == null) {
                if (locationArrival1Id !== this.eventLocationId) {
                    tripOrder += 1;
                    const tripTiming = this.getTripTiming(locationArrival1Id, this.eventLocationId);
                    const subTrip = {
                        Id: -1,
                        LocationDepartureId: locationArrival1Id,
                        LocationArrivalId: this.eventLocationId,
                        DateTimeDeparture: dt,
                        DateTimeArrival: dt,
                        WaitTimePickup: 0,
                        IsPickupDeparture: false,
                        IsDropOffArrival: false,
                        PaxPickup: this.textBoxPaxInstance.option('value'),
                        PaxDropOff: this.textBoxPaxInstance.option('value'),
                        MinutesMin: tripTiming != undefined ? tripTiming.MinutesMin : 0,
                        MinutesMax: tripTiming != undefined ? tripTiming.MinutesMax : 0,
                        TripOrder: tripOrder,
                        LocationDeparture: this.selectBoxLocationArrival1Instance.option('text'),
                        LocationArrival: 'GMM',
                    }
                    subTripsArr.push(subTrip);
                }
            } else {
                if (locationArrival2Id !== this.eventLocationId) {
                    tripOrder += 1;
                    const tripTiming = this.getTripTiming(locationArrival2Id, this.eventLocationId);
                    const subTrip = {
                        Id: -1,
                        LocationDepartureId: locationArrival2Id,
                        LocationArrivalId: this.eventLocationId,
                        DateTimeDeparture: dt,
                        DateTimeArrival: dt,
                        WaitTimePickup: 0,
                        IsPickupDeparture: false,
                        IsDropOffArrival: false,
                        PaxPickup: this.textBoxPaxInstance.option('value'),
                        PaxDropOff: this.textBoxPaxInstance.option('value'),
                        MinutesMin: tripTiming != undefined ? tripTiming.MinutesMin : 0,
                        MinutesMax: tripTiming != undefined ? tripTiming.MinutesMax : 0,
                        TripOrder: tripOrder,
                        LocationDeparture: this.selectBoxLocationArrival2Instance.option('text'),
                        LocationArrival: 'GMM',
                    }
                    subTripsArr.push(subTrip);
                }
            }

            // Then add from gmm if necessary
            if (locationDepartureId !== this.eventLocationId) {
                subTripsArr.forEach((item) => {
                    item.TripOrder += 1;
                });
                const tripTiming = this.getTripTiming(this.eventLocationId, locationDepartureId);
                const subTrip = {
                    Id: -1,
                    LocationDepartureId: this.eventLocationId,
                    LocationArrivalId: locationDepartureId,
                    DateTimeDeparture: dt,
                    DateTimeArrival: dt,
                    WaitTimePickup: 0,
                    IsPickupDeparture: false,
                    IsDropOffArrival: false,
                    PaxPickup: this.textBoxPaxInstance.option('value'),
                    PaxDropOff: this.textBoxPaxInstance.option('value'),
                    MinutesMin: tripTiming != undefined ? tripTiming.MinutesMin : 0,
                    MinutesMax: tripTiming != undefined ? tripTiming.MinutesMax : 0,
                    TripOrder: 0,
                    LocationDeparture: 'GMM',
                    LocationArrival: this.selectBoxLocationDepartureInstance.option('text'),
                }
                subTripsArr.unshift(subTrip);
            }

            this.tripRecord.SubTrips = subTripsArr;

            this.refShuttlesPreview.current.refresh(subTripsArr);
        }

    }

    numberBox_OnFocusIn = (e) => {
        // select all text
        e.element.querySelector('.dx-texteditor-input').select();
    }

    selectBox_OnFocusIn = (e) => {
        // select all text
        e.element.querySelector('.dx-texteditor-input').select();
    }

    htmlEditorNotes_OnFocusIn = (e) => {
        // select all text
        //e.element.querySelector('.dx-htmleditor-content').select();  
    }

    // SELECTBOX ARTISTS
    selectBoxArtistsAttributes = {
        id: 'selectBoxArtistsInstance',
    }
    selectBoxArtists_OnInitialized = (e) => {
        this.selectBoxArtistsInstance = e.component;
    }
    
    // SELECTBOX LOCATION DEPARTURE
    selectBoxLocationDeparture_OnInitialized = (e) => {
        this.selectBoxLocationDepartureInstance = e.component;
    }

    selectBoxLocationDeparture_OnSelectionChanged = (e) => {
        if (this.doNotExecutePrepare == true) {
            return;
        }
        this.prepareSubTrips();
    }

    // SELECTBOX LOCATION ARRIVAL1
    selectBoxLocationArrival1_OnInitialized = (e) => {
        this.selectBoxLocationArrival1Instance = e.component;
    }

    selectBoxLocationArrival1_OnSelectionChanged = (e) => {
        if (this.doNotExecutePrepare == true) {
            return;
        }
        this.prepareSubTrips();
    }

    // SELECTBOX LOCATION ARRIVAL2
    selectBoxLocationArrival2_OnInitialized = (e) => {
        this.selectBoxLocationArrival2Instance = e.component;
    }
    selectBoxLocationArrival2_OnSelectionChanged = (e) => {
        if (this.doNotExecutePrepare == true) {
            return;
        }
        this.prepareSubTrips();
    }

    // TEXTBOX PAX
    textBoxPax_OnInitialized = (e) => {
        this.textBoxPaxInstance = e.component;
    }

    // CHECKBOX TRAILER
    checkBoxTrailer_OnInitialized = (e) => {
        this.checkBoxTrailerInstance = e.component;
    }

    checkBoxTrailer_OnValueChanged = (e) => {
    }

    // SELECTBOX FLEET
    selectBoxFleet_OnInitialized = (e) => {
        this.selectBoxFleetInstance = e.component;
    }

    // SELECTBOX DRIVERS
    selectBoxDriver_OnInitialized = (e) => {
        this.selectBoxDriverInstance = e.component;
    }

    // SELECTBOX TRIP STATUS
    selectBoxTripStatusses_OnInitialized = (e) => {
        this.selectBoxTripStatussesInstance = e.component;
    }
    // HTMLEDITOR NOTES
    htmlEditorNotes_OnInitialized = (e) => {
        this.htmlEditorNotesInstance = e.component;
    }

    // BUTTON ADD ARRIVAL 2
    buttonAddArrival2_OnInitialized = (e) => {
        this.buttonAddArrival2Instance = e.component;
    }

    buttonAddArrival2_OnClick = async (e) => {
        this.selectBoxLocationArrival2Instance.option('value', null);
        document.getElementById('arrival2Div').style.display = "flex";
        document.getElementById('arrival2ButtonsDiv').style.display = "flex";
        this.buttonAddArrival2Instance.option('disabled', true)
        this.setState({ popupHeight: '895px' });

    }

    // BUTTON REMOVE ARRIVAL 2
    buttonRemoveArrival2_OnInitialized = (e) => {
        this.buttonRemoveArrival2Instance = e.component;
    }

    buttonRemoveArrival2_OnClick = async (e) => {
        this.selectBoxLocationArrival2Instance.option('value', null);
        document.getElementById('arrival2Div').style.display = "none";
        document.getElementById('arrival2ButtonsDiv').style.display = "none";
        this.buttonAddArrival2Instance.option('disabled', false)
        this.setState({ popupHeight: '810px'});
    }

    buttonAddLocation__OnInitialized = (e) => {
        this.buttonLocationInstance = e.component;

    }

    buttonAddLocation_OnClick = (e) => {
        this.setState({ locationId: -1 });
        this.popupShuttlesAdminLocationsEditInstance.show();
    }

    // BUTTONS LOCATION
    buttonLocations_OnClick = (e) => {
        var idArr = e.currentTarget.id.split('-');
        if (idArr.length === 3) {
            switch (idArr[0].toUpperCase()) {
                case "DEP":
                    this.selectBoxLocationDepartureInstance.option('value', parseInt(idArr[1], 10))
                    break;
                case "ARR":
                    if (idArr[2] == 1) {
                        this.selectBoxLocationArrival1Instance.option('value', parseInt(idArr[1], 10))
                        break;
                    } else if (idArr[2] == 2) {
                        this.selectBoxLocationArrival2Instance.option('value', parseInt(idArr[1], 10))
                        break;
                    }
                default:
            }
        }
    }

    // CHECKBOX KEEP OPEN
    checkBoxKeepOpen_OnInitialized = (e) => {
        this.checkBoxKeepOpenInstance = e.component;
    }

    checkBoxKeepOpen_OnValueChanged = (e) => {
        this.buttonUpdateInstance.option('text', (e.value === true ? 'Save & new' : 'Save'));
        this.buttonUpdateInstance.option('width', (e.value === true ? '140px' : '120px'));
    }


    // POPUP EDIT LOCATION
    popupShuttlesAdminLocationsEdit_OnInitialized = (instance) => {
        this.popupShuttlesAdminLocationsEditInstance = instance;
    }

    popupShuttlesAdminLocationsEdit_OnUpdate = async (success) => {
        if (success) {
            HxToast.showToast(this.state.locationId === -1 ? 'Location added successfully' : 'Location updated successfully', 'success', 'bottom right', 'up-stack');
            this.hubConnectionLocation
                .invoke("SendUpdateInfoAddedLocation")
                .catch(function (err) {
                    return console.error(err.toString());
                });
        } else {
            HxToast.showToast(this.state.locationId === -1 ? 'Failed to add location' : 'Failed to update location', 'error', 'bottom right', 'up-stack');
        }
    }
    // BUTTON
    buttonUpdate_OnInitialized = (e) => {
        this.buttonUpdateInstance = e.component;
    }

    buttonUpdate_OnClick = async (e) => {
        await this.updateTrip();
    }


    updateTrip = async () => {
        this.validationGroupInstance.validate();
        const isPreviewValid = this.refShuttlesPreview.current.validatePreview();

        if (this.selectBoxArtistsInstance.option('isValid') === true
            && this.selectBoxLocationDepartureInstance.option('isValid') === true
            && this.selectBoxLocationArrival1Instance.option('isValid') === true
            && (document.getElementById('arrival2Div').style.display == "none" ? true : this.selectBoxLocationArrival2Instance.option('isValid') === true)
            && this.textBoxPaxInstance.option('isValid') === true
            && this.selectBoxFleetInstance.option('isValid') === true
            && isPreviewValid === true
        ) {
            const subTrips = this.refShuttlesPreview.current.getSubTrips();

            this.tripRecord.ArtistId = this.selectBoxArtistsInstance.option('value');
            this.tripRecord.DriverId = this.selectBoxDriverInstance.option('value');
            this.tripRecord.CarId = this.selectBoxFleetInstance.option('value');
            this.tripRecord.StatusId = this.selectBoxTripStatussesInstance.option('value');
            this.tripRecord.Pax = this.textBoxPaxInstance.option('value');
            this.tripRecord.Trailer = this.checkBoxTrailerInstance.option('value');
            this.tripRecord.Notes = this.htmlEditorNotesInstance.option('value');
            this.tripRecord.SubTrips = subTrips;

            let tripModel = {
                Id: this.tripRecord.Id,
                EventId: this.tripRecord.EventId,
                ArtistId: this.tripRecord.ArtistId,
                DriverId: this.tripRecord.DriverId,
                CarId: this.tripRecord.CarId,
                StatusId: this.tripRecord.StatusId,
                Pax: this.tripRecord.Pax,
                Trailer: this.tripRecord.Trailer,
                Notes: this.tripRecord.Notes,
            }

            let subTripsModelArray = [];
            for (var i = 0; i < subTrips.length; i++) {
                if (i == 0) {
                    this.crudDate = subTrips[i].DateTimeDeparture;
                }
                let model = {
                    Id: -1,
                    LocationDepartureId: subTrips[i].LocationDepartureId,
                    LocationArrivalId: subTrips[i].LocationArrivalId,
                    DateTimeDeparture: subTrips[i].DateTimeDeparture,
                    DateTimeArrival: subTrips[i].DateTimeArrival,
                    WaitTimePickup: subTrips[i].WaitTimePickup,
                    IsPickupDeparture: subTrips[i].IsPickupDeparture,
                    IsDropOffArrival: subTrips[i].IsDropOffArrival,
                    PaxPickup: this.textBoxPaxInstance.option('value'),
                    PaxDropOff: this.textBoxPaxInstance.option('value'),
                    MinutesMin: subTrips[i].MinutesMin,
                    MinutesMax: subTrips[i].MinutesMax,
                    TripOrder: subTrips[i].TripOrder,
                }
                subTripsModelArray.push(model);
            }

            if (this.tripId == -1) {
                var result = await ServiceTrips.addTrip(tripModel, subTripsModelArray);
                if (result !== null && !result.hasOwnProperty("error")) {
                    this.onAdd(result, this.crudDate); // callback
                    if (this.checkBoxKeepOpenInstance.option('value') === false) {
                        this.popupInstance.hide();
                    } else {
                        this.setInitialValues();

                        this.validationGroupInstance.reset();

                        this.popupInstance.option('title', 'Add new shuttle');
                    }
                } else {
                    this.onAdd(result, this.crudDate); // callback
                }
            } else {
                var result = await ServiceTrips.updateTrip(tripModel, subTripsModelArray);
                if (result !== null && !result.hasOwnProperty("error") && result == true) {
                    this.onUpdate(true, this.crudDate); // callback
                    if (this.checkBoxKeepOpenInstance.option('value') === false) {
                        this.popupInstance.hide();
                    } else {
                        this.setInitialValues();

                        this.validationGroupInstance.reset();

                        this.popupInstance.option('title', 'Update shuttle');
                    }
                } else {
                    this.onUpdate(false, new Date(this.crudDate)); // callback
                }
            }
        } else
        {
            this.selectBoxArtistsInstance.focus();
        }
    }

    // VALIDATION GROUP
    validationGroup_OnInitialized = (e) => {
        this.validationGroupInstance = e.component;
    }

    // HUB
    updatePushedDataAddedTripTiming = async () => {
        await this.loadDataSourceTripTimings();
    }

    updatePushedDataAddedLocation = async () => {
        await this.loadDataSourceLocations();
    }

    // RENDERING
    renderLocationDepartureDivs = () =>{
        let uiItems = [];

        this.FavouriteLocations.forEach((item) => {
            var locKey = "dep-" + item.Id + "-0";
            uiItems.push(
                <div id={locKey} key={locKey} className="pse-button-location" onClick={this.buttonLocations_OnClick}>{item.ShortName}</div>
            )
        });

        return uiItems;
    }

    renderLocationArrivalDivs = (divNo) => {
        let uiItems = [];

        this.FavouriteLocations.forEach((item) => {
            var locKey = "arr-" + item.Id + "-" + divNo;
            uiItems.push(
                <div id={locKey} key={locKey} className="pse-button-location" onClick={this.buttonLocations_OnClick}>{item.ShortName}</div>
            )
        });

        return uiItems;
    }

    render() {
        return (
            <React.Fragment>
                <Popup
                    dragEnabled={false}
                    hideOnOutsideClick={false}
                    showTitle={true}
                    width={1310}
                    height={this.state.popupHeight}
                    showCloseButton={true}
                    onInitialized={this.popup_OnInitialized}
                    onShown={this.popup_OnShown}
                    onShowing={this.popup_OnShowing}
                    onHiding={this.popup_OnHiding}
                >
                    <ValidationGroup
                        onInitialized={this.validationGroup_OnInitialized}
                    >
                        <div>
                            {/*<div className="pse-header-div">Info</div>*/}
                            <div className="dx-card pse-top-card">
                                <div className="pse-top-div">
                                    <div className="dx-card pse-top-left-div">
                                        <div className="pse-dx-field">
                                            <div className="dx-field-label pse-top-label-artist">Artist*</div>
                                            <div className="dx-field-value pse-top-input">
                                                <SelectBox
                                                    dataSource={this.state.dataSourceArtists}
                                                    valueExpr="Id"
                                                    displayExpr="Name"
                                                    dropDownOptions={{ maxHeight: '200px' }}
                                                    minSearchLength={0}
                                                    searchEnabled={true}
                                                    showClearButton={true}
                                                    showDataBeforeSearch={false}
                                                    width={250}
                                                    onInitialized={this.selectBoxArtists_OnInitialized}
                                                    onFocusIn={this.selectBox_OnFocusIn}
                                                >
                                                    <Validator>
                                                        <ValidationRule type="required" message="Required" />
                                                    </Validator>
                                                </SelectBox>
                                            </div>
                                            <div className="dx-field-label pse-top-label-pax">Pax*</div>
                                            <div className="dx-field-value pse-top-input">
                                                <NumberBox
                                                    width='60px'
                                                    step={1}
                                                    onFocusIn={this.numberBox_OnFocusIn}
                                                    onInitialized={this.textBoxPax_OnInitialized}
                                                >
                                                    <Validator>
                                                        <RangeRule min={1} max={8} />
                                                        <ValidationRule type="required" message="Required" />
                                                    </Validator>
                                                </NumberBox>
                                            </div>
                                        </div>
                                        <div className="pse-dx-field pse-dx-field-location-dropdown">
                                            <div className="dx-field-label pse-top-label">Departure*</div>
                                            <div className="dx-field-value pse-top-input">
                                                <div className="pse-selectbox-departure">
                                                    <SelectBox
                                                        dataSource={this.state.dataSourceLocations}
                                                        valueExpr="Id"
                                                        displayExpr="Name"
                                                        dropDownOptions={{ maxHeight: '200px' }}
                                                        minSearchLength={0}
                                                        searchEnabled={true}
                                                        showClearButton={true}
                                                        showDataBeforeSearch={false}
                                                        width={250}
                                                        onInitialized={this.selectBoxLocationDeparture_OnInitialized}
                                                        onFocusIn={this.selectBox_OnFocusIn}
                                                        onValueChanged={this.selectBoxLocationDeparture_OnSelectionChanged}
                                                    >
                                                        <Validator>
                                                            <ValidationRule type="required" message="Required" />
                                                        </Validator>
                                                    </SelectBox>
                                                </div>
                                                <div className="pse-button-add-location">
                                                    <Button
                                                        onInitialized={this.buttonAddLocation_OnInitialized}
                                                        icon="home"
                                                        onClick={this.buttonAddLocation_OnClick}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pse-dx-field">
                                            <div className="dx-field-label pse-top-label-location-divs"></div>
                                            <div className="dx-field-value pse-top-input">
                                                {this.renderLocationDepartureDivs()}
                                            </div>
                                        </div>
                                        <div className="pse-dx-field pse-dx-field-location-dropdown">
                                            <div className="dx-field-label pse-top-label-arrival">Arrival*</div>
                                            <div className="dx-field-value pse-top-input-arrival">
                                                <SelectBox
                                                    dataSource={this.state.dataSourceLocations}
                                                    valueExpr="Id"
                                                    displayExpr="Name"
                                                    dropDownOptions={{ maxHeight: '200px' }}
                                                    minSearchLength={0}
                                                    searchEnabled={true}
                                                    showClearButton={true}
                                                    showDataBeforeSearch={false}
                                                    width={250}
                                                    onInitialized={this.selectBoxLocationArrival1_OnInitialized}
                                                    onFocusIn={this.selectBox_OnFocusIn}
                                                    onValueChanged={this.selectBoxLocationArrival1_OnSelectionChanged}
                                                >
                                                    <Validator>
                                                        <ValidationRule type="required" message="Required" />
                                                    </Validator>
                                                </SelectBox>
                                                <span className="pse-button-add-arrival">
                                                    <Button
                                                    onInitialized={this.buttonAddArrival2_OnInitialized}
                                                    icon="add"
                                                    onClick={this.buttonAddArrival2_OnClick}
                                                    />
                                                </span>
                                                
                                            </div>
                                        </div>
                                        <div className="pse-dx-field">
                                            <div className="dx-field-label pse-top-label-location-divs"></div>
                                            <div className="dx-field-value pse-top-input">
                                                {this.renderLocationArrivalDivs(1)}
                                            </div>
                                        </div>
                                        <div id="arrival2Div" className="pse-dx-field pse-dx-field-location-dropdown" style={{ display: 'none' }}>
                                            <div className="dx-field-label pse-top-label-arrival">Arrival*</div>
                                            <div className="dx-field-value pse-top-input-arrival">
                                                <SelectBox
                                                    dataSource={this.state.dataSourceLocations}
                                                    valueExpr="Id"
                                                    displayExpr="Name"
                                                    dropDownOptions={{ maxHeight: '200px' }}
                                                    minSearchLength={0}
                                                    searchEnabled={true}
                                                    showClearButton={true}
                                                    showDataBeforeSearch={false}
                                                    width={250}
                                                    onInitialized={this.selectBoxLocationArrival2_OnInitialized}
                                                    onFocusIn={this.selectBox_OnFocusIn}
                                                    onValueChanged={this.selectBoxLocationArrival2_OnSelectionChanged}
                                                >
                                                    <Validator>
                                                        <ValidationRule type="required" message="Required" />
                                                    </Validator>
                                                </SelectBox>
                                                <span className="pse-button-remove-arrival">
                                                    <Button
                                                        onInitialized={this.buttonRemoveArrival2_OnInitialized}
                                                        icon="trash"
                                                        onClick={this.buttonRemoveArrival2_OnClick}
                                                    />
                                                </span>
                                            </div>
                                        </div>
                                        <div id="arrival2ButtonsDiv" className="pse-dx-field" style={{ display: 'none' }} >
                                            <div className="dx-field-label pse-top-label-location-divs"></div>
                                            <div className="dx-field-value pse-top-input">
                                                {this.renderLocationArrivalDivs(2)}
                                            </div>
                                        </div>
                                        <div className="pse-dx-field">
                                            <div className="dx-field-label pse-top-label-car">Car*</div>
                                            <div className="dx-field-value pse-top-input-car">
                                                <SelectBox
                                                    dataSource={this.state.dataSourceFleet}
                                                    valueExpr="Id"
                                                    displayExpr="Name"
                                                    dropDownOptions={{ maxHeight: '200px' }}
                                                     minSearchLength={0}
                                                    searchEnabled={true}
                                                    showClearButton={false}
                                                    showDataBeforeSearch={false}
                                                    width={250}
                                                    onInitialized={this.selectBoxFleet_OnInitialized}
                                                    onFocusIn={this.selectBox_OnFocusIn}
                                                >
                                                    <Validator>
                                                        <ValidationRule type="required" message="Required" />
                                                    </Validator>
                                                </SelectBox>
                                                <div className="pse-top-label-checkbox-trailer">
                                                    <CheckBox
                                                        text="Trailer"
                                                        onInitialized={this.checkBoxTrailer_OnInitialized}
                                                        onValueChanged={this.checkBoxTrailer_OnValueChanged}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pse-dx-field">
                                            <div className="dx-field-label pse-top-label">Driver*</div>
                                            <div className="dx-field-value pse-top-input">
                                                <SelectBox
                                                    dataSource={this.state.dataSourceDrivers}
                                                    valueExpr="Id"
                                                    displayExpr="Name"
                                                    dropDownOptions={{ maxHeight: '200px' }}
                                                    minSearchLength={0}
                                                    searchEnabled={true}
                                                    showClearButton={false}
                                                    showDataBeforeSearch={false}
                                                    width={250}
                                                    onInitialized={this.selectBoxDriver_OnInitialized}
                                                    onFocusIn={this.selectBox_OnFocusIn}
                                                >
                                                    <Validator>
                                                        <ValidationRule type="required" message="Required" />
                                                    </Validator>
                                                </SelectBox>
                                            </div>
                                        </div>
                                        <div className="pse-dx-field">
                                            <div className="dx-field-label pse-top-label">Status*</div>
                                            <div className="dx-field-value pse-top-input">
                                                <SelectBox
                                                    dataSource={this.state.dataSourceTripStatusses}
                                                    valueExpr="Id"
                                                    displayExpr="Name"
                                                    dropDownOptions={{ maxHeight: '200px' }}
                                                    minSearchLength={0}
                                                    searchEnabled={true}
                                                    showClearButton={false}
                                                    showDataBeforeSearch={false}
                                                    width={250}
                                                    onInitialized={this.selectBoxTripStatusses_OnInitialized}
                                                    onFocusIn={this.selectBox_OnFocusIn}
                                                >
                                                    <Validator>
                                                        <ValidationRule type="required" message="Required" />
                                                    </Validator>
                                                </SelectBox>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pse-top-right-div">
                                        <div className="pse-dx-field-notes">
                                            <div className="dx-field-value pse-top-input-notes">
                                                <HtmlEditor
                                                    width='100%'
                                                    height='100%'
                                                    onInitialized={this.htmlEditorNotes_OnInitialized}
                                                    onFocusIn={this.htmlEditorNotes_OnFocusIn}
                                                >
                                                </HtmlEditor>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="dx-card pse-bottom-card">
                                <ShuttlesPreview
                                    ref={this.refShuttlesPreview}
                                    tripTimings={this.state.dataSourceTripTimings}
                                />
                            </div>
                        </div>
                    </ValidationGroup>

                    <div className="pse-buttons">
                        <div className="pse-buttons-checkbox">
                            <CheckBox
                                text="Keep open"
                                onInitialized={this.checkBoxKeepOpen_OnInitialized}
                                onValueChanged={this.checkBoxKeepOpen_OnValueChanged}
                            />
                        </div>
                        <div className='pse-buttons-update'>
                            <div className="pse-button-update">
                                <Button
                                    onInitialized={this.buttonUpdate_OnInitialized}
                                    icon="save"
                                    onClick={this.buttonUpdate_OnClick}
                                />
                            </div>
                        </div>
                    </div>

                    <PopupShuttlesAdminLocationsEdit
                        ref={ref => this.refPopupShuttlesAdminLocationsEdit = ref}
                        id={this.state.locationId}
                        onInitialized={this.popupShuttlesAdminLocationsEdit_OnInitialized}
                        onUpdate={this.popupShuttlesAdminLocationsEdit_OnUpdate}
                    />

                    <LoadPanel
                        shadingColor="rgba(0,0,0,0.4)"
                        visible={this.state.loadPanelVisible}
                        showIndicator={true}
                        shading={false}
                        showPane={false}
                        hideOnOutsideClick={false}
                    />
                </Popup>
            </React.Fragment>
        );
    }

}

export default PopupShuttlesEdit

