import globalConfig from '../../config/global.js';

class ServiceEvents {

    static getEventByEventId = async (eventId) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Events/GetEventByEventId?eventId=" + encodeURIComponent(eventId)) // return null or data object
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static getEventDatesByEventId = async (eventId) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Events/GetEventDatesByEventId?eventId=" + encodeURIComponent(eventId)) // return data object
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }

    static getEventDatesDropDownByEventId = async (eventId, calledFrom) => {
        let returnValue = {};

        await fetch(globalConfig.settings.host + "/api/Events/GetEventDatesDropDownByEventId?eventId=" + encodeURIComponent(eventId) + "&calledFrom=" + encodeURIComponent(calledFrom)) // return data object
            .then(response => response.json())
            .then(data => {
                returnValue = data;
            })
            .catch(function (error) {
                returnValue = { 'error': error.message };
            });

        return returnValue;
    }
}

export default ServiceEvents;
