import React from 'react';
import { Popup } from 'devextreme-react/popup';
import TextBox from 'devextreme-react/text-box';
import Validator, { AsyncRule, ValidationRule, EmailRule } from 'devextreme-react/validator';
import { Button } from 'devextreme-react/button';
import { ValidationGroup, dxValidationGroupResult } from 'devextreme-react/validation-group';
import { CheckBox } from 'devextreme-react/check-box';

import ServiceFleet from '../../../../../api/services/ServiceFleet'

import './PopupShuttlesAdminFleetEdit.scss'


// props
//      id (required): vehicle id
//
//      onInitialized: callback
//      onUpdate: callback
//      onLoadError: callback
//      onHiding: callback

class PopupShuttlesAdminFleetEdit extends React.Component {
    constructor(props) {
        super(props);

        this.fleetId = -1;

        this.validationGroupInstance = null;

        this.textBoxTypeInstance = null;
        this.textBoxPaxInstance = null;
        this.textBoxLicencePlateInstance = null;
        this.checkBoxTowBarInstance = null;
        this.textBoxTagInstance = null;

        this.checkBoxKeepOpenInstance = null;
        this.buttonUpdateInstance = null;

        this.fleetRecord = {};
        this.fleetDefaultRecord = {
            Id: -1,
            Type: null,
            Pax: null,
            LicencePlate: '',
            AvailableFrom: null,
            AvailableTo: null,
            TowBar: null,
            Tag: null,
            EventId: parseInt(sessionStorage.getItem('currentEventId'), 10),
            ColorId: null,
        }
    }

    // CALLBACKS
    onInitialized = (instance) => {
        if (this.props.onInitialized) {
            this.props.onInitialized(instance);  // callback
        }
    }

    onLoadError = () => {
        if (this.props.onLoadError) {
            this.props.onLoadError();  // callback
        }
    }

    onUpdate = (success) => {
        if (this.props.onUpdate) {
            this.props.onUpdate(success);  // callback
        }
    }

    onHiding = () => {
        if (this.props.onHiding) {
            this.props.onHiding();  // callback
        }
    }
    // CALLABLES

    // EVENTS
    componentDidMount = async () => {
    }

    // DATA
    loadDataSourceFleet = async (fleetId) => {
        var data = await ServiceFleet.getFleetByEventIdFleetId(parseInt(sessionStorage.getItem('currentEventId'), 10), fleetId);
        if (data !== null && !data.hasOwnProperty("error")) {
            this.fleetRecord = data;
        } else {
            this.onLoadError();  // callback
        }
    }

    // POPUP
    popup_OnInitialized = (e) => {
        this.popupInstance = e.component;

        // set callback
        this.onInitialized(e.component);
    }

    popup_OnShowing = (e) => {
        if (this.props.id !== undefined) {
            this.fleetId = this.props.id;
        }
    }

    popup_OnHiding = (e) => {
        this.resetPopup();

        this.onHiding();  // callback
    }

    resetPopup = () => {
        this.textBoxTypeInstance.option('value', null);
        this.textBoxPaxInstance.option('value', null);
        this.textBoxLicencePlateInstance.option('value', null);
        this.checkBoxTowBarInstance.option('value', false)
        this.textBoxTagInstance.option('value', null);

        this.validationGroupInstance.reset();
    }

    popup_OnShown = async (e) => {
         if (this.fleetId == -1) {
            // new fleet
            this.fleetRecord = this.fleetDefaultRecord;

            this.resetPopup();

            document.getElementById('textBoxTypeInput').select();

            this.checkBoxKeepOpenInstance.option('visible', true);

            this.popupInstance.option('title', 'Add new vehicle');
        } else {
            // edit fleet
            await this.loadDataSourceFleet(this.fleetId);

            this.textBoxTypeInstance.option('value', this.fleetRecord.Type);
            this.textBoxPaxInstance.option('value', this.fleetRecord.Pax);
            this.textBoxLicencePlateInstance.option('value', this.fleetRecord.LicencePlate);
            this.checkBoxTowBarInstance.option('value', this.fleetRecord.TowBar)
            this.textBoxTagInstance.option('value', this.fleetRecord.Tag);

            this.checkBoxKeepOpenInstance.option('visible', false);

            this.popupInstance.option('title', 'Update ' + this.fleetRecord.Type);
        }

        this.checkBoxKeepOpenInstance.option('value', false);
        this.buttonUpdateInstance.option('text', 'Save');
        this.buttonUpdateInstance.option('width', '120px');

    }

    // ALL TEXTBOXES FOCUS IN
    textBox_OnFocusIn = (e) => {
        // select all text
        e.element.querySelector('.dx-texteditor-input').select();
    }

    // TEXTBOX TYPE
    textBoxTypeAttributes = {
        id: 'textBoxTypeInput',
    }

    textBoxType_OnInitialized = (e) => {
        this.textBoxTypeInstance = e.component;
    }

    // TEXTBOX PAX
    textBoxPax_OnInitialized = (e) => {
        this.textBoxPaxInstance = e.component;
    }

    // TEXTBOX LICENCEPLATE
    textBoxLicencePlate_OnInitialized = (e) => {
        this.textBoxLicencePlateInstance = e.component;
    }

    // TEXTBOX TAG
    textBoxTag_OnInitialized = (e) => {
        this.textBoxTagInstance = e.component;
    }
 
    // CHECKBOX TRAILER
    checkBoxTowBar_OnInitialized = (e) => {
        this.checkBoxTowBarInstance = e.component;
    }

    // CHECKBOX KEEP OPEN
    checkBoxKeepOpen_OnInitialized = (e) => {
        this.checkBoxKeepOpenInstance = e.component;
    }

    checkBoxKeepOpen_OnValueChanged = (e) => {
        this.buttonUpdateInstance.option('text', (e.value === true ? 'Save & new' : 'Save'));
        this.buttonUpdateInstance.option('width', (e.value === true ? '140px' : '120px'));
    }

    // BUTTON UPDATE
    buttonUpdate_OnInitialized = (e) => {
        this.buttonUpdateInstance = e.component;
    }

    buttonUpdate_OnClick = async (e) => {
        await this.updateFleet();
    }

    updateFleet = async () => {
        this.validationGroupInstance.validate();
        if (this.textBoxTypeInstance.option('isValid') === true) {

            this.fleetDefaultRecord = {
                Id: -1,
                Type: null,
                Pax: null,
                LicencePlate: '',
                AvailableFrom: null,
                AvailableTo: null,
                TowBar: null,
                Tag: null,
                EventId: parseInt(sessionStorage.getItem('currentEventId'), 10),
                ColorId: null,
            }


            this.fleetRecord.Type = (this.textBoxTypeInstance.option('value') !== null ? this.textBoxTypeInstance.option('value').trim() : null);
            this.fleetRecord.Pax = (this.textBoxPaxInstance.option('value') !== null ? this.textBoxPaxInstance.option('value').trim() : null);
            this.fleetRecord.LicencePlate = (this.textBoxLicencePlateInstance.option('value') !== null ? this.textBoxLicencePlateInstance.option('value').trim() : null);
            this.fleetRecord.TowBar = this.checkBoxTowBarInstance.option('value');
            this.fleetRecord.Tag = (this.textBoxTagInstance.option('value') !== null ? this.textBoxTagInstance.option('value').trim() : null);

            if (this.fleetId == -1) {
                // new fleet
                var resultFleet = await ServiceFleet.addFleet(this.fleetRecord);
                if (resultFleet !== null && !resultFleet.hasOwnProperty("error")) {
                    this.onUpdate(true); // callback
                    if (this.checkBoxKeepOpenInstance.option('value') === false) {
                        this.popupInstance.hide();
                    } else {
                        this.resetPopup();

                        this.popupInstance.option('title', 'Add new vehicle');
                    }
                } else {
                    this.onUpdate(false); // callback
                }
            } else {
                // edit fleet
                var resultFleet = await ServiceFleet.updateFleet(this.fleetRecord);
                if (resultFleet != null && !resultFleet.hasOwnProperty("error") && resultFleet == true) {
                    this.onUpdate(true); // callback
                    if (this.checkBoxKeepOpenInstance.option('value') === false) {
                        this.popupInstance.hide();
                    } else {
                        this.resetPopup();

                        this.popupInstance.option('title', 'Add new vehicle');
                    }
                } else {
                    this.onUpdate(false); // callback
                }
            }
        } else {
            this.textBoxNameInstance.focus();
        }
    }

    // VALIDATION
    validationGroup_OnInitialized = (e) => {
        this.validationGroupInstance = e.component;
    }


    render() {
        return (
            <React.Fragment>
                <Popup
                    dragEnabled={false}
                    hideOnOutsideClick={false}
                    showTitle={true}
                    width={553}
                    height={329}
                    showCloseButton={true}
                    onInitialized={this.popup_OnInitialized}
                    onShown={this.popup_OnShown}
                    onShowing={this.popup_OnShowing}
                    onHiding={this.popup_OnHiding}
                >
                    <ValidationGroup
                        onInitialized={this.validationGroup_OnInitialized}
                    >
                        <div>
                            <div className="psafe-header-div">Info</div>
                            <div className="dx-card psafe-top-card">
                                <div className="psafe-main-div">
                                    <div className="psafe-dx-field">
                                        <div className="dx-field-label psafe-label">Type*</div>
                                        <div className="dx-field-value psafe-input">
                                            <TextBox
                                                inputAttr={this.textBoxTypeAttributes}
                                                width='250px'
                                                onFocusIn={this.textBox_OnFocusIn}
                                                onInitialized={this.textBoxType_OnInitialized}
                                            >
                                                <Validator>
                                                    <ValidationRule type="required" message="Required" />
                                                </Validator>
                                            </TextBox>
                                        </div>
                                        <div className="dx-field-label psafe-label-pax">Pax</div>
                                        <div className="dx-field-value psafe-input">
                                            <TextBox
                                                width='104px'
                                                onFocusIn={this.textBox_OnFocusIn}
                                                onInitialized={this.textBoxPax_OnInitialized}
                                            />
                                        </div>
                                    </div>
                                    <div className="psafe-dx-field">
                                        <div className="dx-field-label psafe-label">Licence Plate</div>
                                        <div className="dx-field-value psafe-input psafe-input-licence-plate">
                                            <TextBox
                                                width='250px'
                                                onFocusIn={this.textBox_OnFocusIn}
                                                onInitialized={this.textBoxLicencePlate_OnInitialized}
                                            />
                                        </div>
                                        <div className="dx-field-label psafe-label psafe-label-trailer">Trailer</div>
                                        <div className="dx-field-value psafe-input">
                                            <CheckBox
                                                text=""
                                                onInitialized={this.checkBoxTowBar_OnInitialized}
                                            />
                                        </div>
                                    </div>
                                    <div className="psafe-dx-field">
                                        <div className="dx-field-label psafe-label">Tag</div>
                                        <div className="dx-field-value psafe-input">
                                            <TextBox
                                                width='250px'
                                                onFocusIn={this.textBox_OnFocusIn}
                                                onInitialized={this.textBoxTag_OnInitialized}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ValidationGroup>

                    <div className="psafe-buttons">
                        <div className="psafe-buttons-checkbox">
                            <CheckBox
                                text="Keep open"
                                onInitialized={this.checkBoxKeepOpen_OnInitialized}
                                onValueChanged={this.checkBoxKeepOpen_OnValueChanged}
                            />
                        </div>
                        <div className='psafe-buttons-update'>
                            <div className="psafe-button-update">
                                <Button
                                    onInitialized={this.buttonUpdate_OnInitialized}
                                    icon="save"
                                    onClick={this.buttonUpdate_OnClick}
                                />
                            </div>
                        </div>
                    </div>
                </Popup>
            </React.Fragment>
        );
    }

}

export default PopupShuttlesAdminFleetEdit

