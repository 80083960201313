import React from 'react';
import * as dayJS from 'dayjs';
import { Popup } from 'devextreme-react/popup';
import TextBox from 'devextreme-react/text-box';
import Validator, { CustomRule, ValidationRule } from 'devextreme-react/validator';
import { Button } from 'devextreme-react/button';
import { ValidationGroup } from 'devextreme-react/validation-group';
import { CheckBox } from 'devextreme-react/check-box';
import { LoadPanel } from 'devextreme-react/load-panel';
import SelectBox from 'devextreme-react/select-box';

import DataGrid, {
    RemoteOperations, Editing, ColumnChooser, GroupPanel, FilterPanel, FilterRow, HeaderFilter, Paging, Pager, Scrolling, Column, SearchPanel, KeyboardNavigation, Export, StateStoring, Sorting, Toolbar, Item
} from 'devextreme-react/data-grid';
import ServiceRiders from '../../../../api/services/ServiceRiders'
import ServiceLocations from '../../../../api/services/ServiceLocations'

import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';

import validFilename from 'valid-filename';

import './PopupExportTotalsList.scss'

// props
//
//      onInitialized: callback
//      onConnectionError: callback



function createNewFileName() {
    let d = new Date();
    return 'Totals_' + d.getDate().toString().padStart(2, '0') + '_' + (d.getMonth() + 1).toString().padStart(2, '0') + '_' + d.getFullYear() + '.xlsx';
}


class PopupExportTotalsList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dataSourceRiders: [],
            dataSourceShops: [],
            headerText: 'Totals',
            isLoadPanelVisible: false,
        };

        this.dataGridInstance = null;
        this.selectBoxShopsInstance = null;

        this.popupInstance = null;

        this.textBoxNameInstance = null;

        this.checkBoxExportColorsInstance = null;

        this.validationGroupInstance = null;

        this.fileName = 'Totals';

        this.userSettings = {
            UserId: parseInt(sessionStorage.getItem("currentUserId"), 10),
            DefaultViewId: -1,
            DefaultFilterId: -1,
            ExportColors: true,
            MultiSelectionGigDates: false,
            MultiSelectionArtists: true,
            UseBlanksForZeroes: false,
            UseColorsProducts: true,
            UseColorsArtists: true,
            ColorOriginProducts: 'product',
            ColorOriginArtists: 'artist',
            ShowSelectedCell: false,
            UseAdvancedFiltering: false,
        }

    }

    // CALLBACKs
    onInitialized = (instance) => {
        if (this.props.onInitialized) {
            this.props.onInitialized(instance);
        }
    }

    onConnectionError = () => {
        if (this.props.onConnectionError) {
            this.props.onConnectionError();
        }
    }

    // DATA
    loadDataSourceTotalsList = async () => {
        var data = await ServiceRiders.getTotalsList(parseInt(sessionStorage.getItem("currentEventId"), 10));
        if (data !== null && !data.hasOwnProperty("error")) {
            this.setState({ dataSourceRiders: data });
        } else {
            this.onConnectionError();  // callback
        }
    }

    loadDataSourceShops = async () => {
        var data = await ServiceLocations.getShopsDropDown();
        if (data !== null && !data.hasOwnProperty("error")) {
            this.setState({ dataSourceShops: data })
        } else {
            this.onConnectionError();  // callback
        }
    }

    popup_OnInitialized = (e) => {
        this.popupInstance = e.component;
        this.onInitialized(e.component);  // callback
    }

    popup_OnShowing = (e) => {
        this.fileName = createNewFileName();
    }

    popup_OnShown = async (e) => {
        this.setState({ isLoadPanelVisible: true });

        this.textBoxNameInstance.option('value', this.fileName);
        this.selectBoxShopsInstance.option('value', null);
        this.checkBoxExportColorsInstance.option('value', this.userSettings.ExportColors)

        document.getElementById('textBoxFileNameInput').select();

        this.textBoxNameInstance.focus();

        await this.loadDataSourceTotalsList();
        await this.loadDataSourceShops();

        this.setState({ isLoadPanelVisible: false });
    }


    // TEXTBOX NAME
    textBoxAttributes = {
        id: 'textBoxFileNameInput',
    }

    textBoxName_OnInitialized = (e) => {
        this.textBoxNameInstance = e.component;
    }

    textBoxName_OnFocusIn = (e) => {
        // select all text
        e.element.querySelector('.dx-texteditor-input').select();
    }

    // SELECTBOX SHOPS
    selectBoxShops_OnInitialized = (e) => {
        this.selectBoxShopsInstance = e.component;
    }

    selectBoxShops_OnSelectionChanged = async (e) => {
        let filter = [];
        if (e.selectedItem !== null) {
            const filter = ["LocationId", "=", e.selectedItem.Id];
            this.dataGridInstance.filter(filter);
        } else {
            this.dataGridInstance.clearFilter();

        }
    }

    // VALIDATIONGROUP
    validationGroup_OnInitialized = (e) => {
        this.validationGroupInstance = e.component;
    }

    // CHECKBOX EXPORT COLORS
    checkBoxExportColors_OnInitialized = (e) => {
        this.checkBoxExportColorsInstance = e.component;
    }

    checkBoxExportColors_OnValueChanged = (e) => {
        this.dataGridInstance.refresh();
    }

    // BUTTON EXPORT
    buttonSave_OnClick = (e) => {
        this.validationGroupInstance.validate();

        if (this.textBoxNameInstance.option('isValid') === true) {
            this.exportToExcel(this.textBoxNameInstance.option('value').trim().replace(/.xlsx$/gi, '') + '.xlsx');
            this.popupInstance.hide();
        } else {
            this.textBoxNameInstance.focus();
        }
    }

    validateFileName = () => {
        let isValid = false;

        if (validFilename(this.textBoxNameInstance.option('value').replace(/.xlsx$/gi, '') + '.xlsx')) {
            isValid = true;
        }

        return isValid;
    }

    exportToExcel = (name) => {
        const fileName = name;
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Sheet 1');

        worksheet.columns = [
            { width: 30 }, { width: 10 }, { width: 10 }, { width: 10 }, { width: 10 }, { width: 10 }, { width: 10 }
        ];

        worksheet.pageSetup.margins = {
            left: 0.5, right: 0.5,
            top: 0.5, bottom: 0.5,
            header: 0, footer: 0
        };

        exportDataGrid({
            component: this.dataGridInstance,
            worksheet: worksheet,
            customizeCell: this.customizeExcelCell,
            keepColumnWidths: false
        })
            .then(function () {
                workbook.xlsx.writeBuffer()
                    .then(function (buffer) {
                        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), fileName);
                    });
            });
    }



    // set excel cell layout
    customizeExcelCell = (options) => {
        const { gridCell, excelCell } = options;

        if (gridCell.rowType === 'data') {
            if (this.checkBoxExportColorsInstance.option('value') == true) {
                if (gridCell.column.dataField === 'ProductName') {
                    let bg = (gridCell.data.ColorHex !== null ? gridCell.data.ColorHex.replace('#', '').slice(0, 6) : 'FFFFFF');
                    let fg = (gridCell.data.ForeColorHex !== null ? gridCell.data.ForeColorHex.replace('#', '').slice(0, 6) : '000000');
                    excelCell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: bg }
                    };
                    excelCell.font = {
                        color: { argb: fg }
                    }
                }
            }
        } else if (gridCell.rowType === 'header') {
            if (this.checkBoxExportColorsInstance.option('value')) {
                let bg = 'FFFFFF';
                let fg = '000000';
                let s = 12;
                let alignment = { vertical: 'middle', horizontal: 'left' };
                if (gridCell.column.dataField == undefined) {
                    bg = '000000';
                    fg = 'FFFFFF';
                    s = 16;
                } else if (gridCell.column.dataField == 'Amount1' || gridCell.column.dataField == 'Amount2' || gridCell.column.dataField == 'Amount3' || gridCell.column.dataField == 'Amount4' || gridCell.column.dataField == 'Amount5' || gridCell.column.dataField == 'TotalAmount') {
                    alignment = { vertical: 'middle', horizontal: 'right' };
                }

                excelCell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: bg }
                };

                excelCell.font = {
                    color: { argb: fg },
                    size: s
                }

                excelCell.alignment = alignment;
            }
        }

        excelCell.border = {
            top: { style: 'thin', color: { argb: 'FF000000' } },
            left: { style: 'thin', color: { argb: 'FF000000' } },
            bottom: { style: 'thin', color: { argb: 'FF000000' } },
            right: { style: 'thin', color: { argb: 'FF000000' } }
        };

    }

    // GRID
    gridOnInitialized = async (e) => {
        // set instance
        this.dataGridInstance = e.component;
    }

    gridOnCellPrepared = (e) => {
        if (e.rowType === 'data') {
            if (e.column.dataField === 'Shops' || e.column.dataField === 'ProductName') {
                if (this.checkBoxExportColorsInstance.option('value') == true) {
                    e.cellElement.style.backgroundColor = e.data.ColorHex;
                    e.cellElement.style.color = e.data.ForeColorHex;
                } else {
                    e.cellElement.style.backgroundColor = '#FFFFFF';
                    e.cellElement.style.color = '#000000';
                }
            } else {
                e.cellElement.style.backgroundColor = '#FFFFFF';
                e.cellElement.style.color = '#000000';
            }
        } else if (e.rowType === 'header') {
            if (e.column.dataField == undefined) {
                e.cellElement.style.backgroundColor = '#FF5722';
                e.cellElement.style.color = '#FFFFFF';
            } else {
                e.cellElement.style.backgroundColor = '#FFFFFF';
                e.cellElement.style.color = '#000000';
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <Popup
                    dragEnabled={false}
                    hideOnOutsideClick={false}
                    showTitle={true}
                    title='Export totals ...'
                    width={850}
                    height={500}
                    showCloseButton={true}
                    onInitialized={this.popup_OnInitialized}
                    onShowing={this.popup_OnShowing}
                    onShown={this.popup_OnShown}
                >
                    <ValidationGroup onInitialized={this.validationGroup_OnInitialized}>
                        <div className="dx-card pet-card-name">
                            <div className="dx-field-name pet-field-name">
                                <div className="dx-field-label pet-label">Name*</div>
                                <div className="dx-field-value pet-name">
                                    <TextBox
                                        onInitialized={this.textBoxName_OnInitialized}
                                        defaultValue=''
                                        inputAttr={this.textBoxAttributes}
                                        onFocusIn={this.textBoxName_OnFocusIn}
                                        hint='Enter name ...'
                                    >
                                        <Validator>
                                            <ValidationRule type="required" message="Required" />
                                            <CustomRule validationCallback={this.validateFileName} message="Invalid file name" />
                                        </Validator>
                                    </TextBox>
                                </div>
                            </div>
                            <div className="dx-field-name pet-field-shops">
                                <div className="dx-field-label pet-label">Shop</div>
                                <div className="dx-field-value pet-shops">
                                    <SelectBox
                                        dataSource={this.state.dataSourceShops}
                                        valueExpr="Id"
                                        displayExpr="Name"
                                        searchEnabled={true}
                                        showClearButton={true}
                                        showDataBeforeSearch={false}
                                        minSearchLength={0}
                                        width={200}
                                        onInitialized={this.selectBoxShops_OnInitialized}
                                        onSelectionChanged={this.selectBoxShops_OnSelectionChanged}
                                    />
                                </div>
                            </div>
                       </div>
                    </ValidationGroup>
                    <div className="dx-card">
                        <DataGrid
                            ref={this.refDataGrid}
                            height={320}
                            width='100%'
                            dataSource={this.state.dataSourceRiders}
                            allowColumnReordering={false}
                            allowColumnResizing={true}
                            allowGrouping={false}
                            columnResizingMode='widget'
                            columnAutoWidth={true}
                            remoteOperations={true}
                            wordWrapEnabled={true}
                            onInitialized={this.gridOnInitialized}
                            onCellPrepared={this.gridOnCellPrepared}
                            visible={true}
                        >
                            <ColumnChooser enabled={false} />
                            <FilterPanel visible={false} />
                            <FilterRow visible={false} />
                            <GroupPanel visible={false} />
                            <SearchPanel visible={false} />
                            <HeaderFilter visible={false} />
                            <Column
                                cssClass='grid-header'
                                caption={this.state.headerText}
                            >
                                <Column
                                    dataField='ProductId'
                                    dataType='number'
                                    caption='Product'
                                    width='auto'
                                    allowFiltering={true}
                                    allowResizing={true}
                                    allowEditing={false}
                                    fixed={true}
                                    showInColumnChooser={true}
                                    visible={false}
                                />
                                <Column
                                    dataField='ColorHex'
                                    dataType='string'
                                    caption='ColorHex'
                                    allowFiltering={false}
                                    allowEditing={false}
                                    fixed={true}
                                    showInColumnChooser={false}
                                    visible={false}
                                />
                                <Column
                                    dataField='ForeColorHex'
                                    dataType='string'
                                    caption='ForeColorHex'
                                    allowFiltering={false}
                                    allowEditing={false}
                                    fixed={true}
                                    showInColumnChooser={false}
                                    visible={false}
                                />
                                <Column
                                    dataField='ProductName'
                                    dataType='string'
                                    caption='Product'
                                    sortIndex={0}
                                    sortOrder='Asc'
                                    width={375}
                                    allowFiltering={true}
                                    allowResizing={true}
                                    allowEditing={false}
                                    fixed={true}
                                    showInColumnChooser={true}
                                    visible={true}
                                />
                                <Column
                                    dataField='Amount1'
                                    dataType='number'
                                    caption='Thu 15/06'
                                    sortIndex={0}
                                    sortOrder='Asc'
                                    width='auto'
                                    allowFiltering={true}
                                    allowResizing={true}
                                    allowEditing={false}
                                    fixed={true}
                                    showInColumnChooser={true}
                                    visible={true}
                                />
                                <Column
                                    dataField='Amount2'
                                    dataType='number'
                                    caption='Fri 16/06'
                                    sortIndex={0}
                                    sortOrder='Asc'
                                    width='auto'
                                    allowFiltering={true}
                                    allowResizing={true}
                                    allowEditing={false}
                                    fixed={true}
                                    showInColumnChooser={true}
                                    visible={true}
                                />
                                <Column
                                    dataField='Amount3'
                                    dataType='number'
                                    caption='Sat 17/06'
                                    sortIndex={0}
                                    sortOrder='Asc'
                                    width='auto'
                                    allowFiltering={true}
                                    allowResizing={true}
                                    allowEditing={false}
                                    fixed={true}
                                    showInColumnChooser={true}
                                    visible={true}
                                />
                                <Column
                                    dataField='Amount4'
                                    dataType='number'
                                    caption='Sun 18/06'
                                    sortIndex={0}
                                    sortOrder='Asc'
                                    width='auto'
                                    allowFiltering={true}
                                    allowResizing={true}
                                    allowEditing={false}
                                    fixed={true}
                                    showInColumnChooser={true}
                                    visible={true}
                                />
                                <Column
                                    dataField='Amount5'
                                    dataType='number'
                                    caption='Mon 20/06'
                                    sortIndex={0}
                                    sortOrder='Asc'
                                    width='auto'
                                    allowFiltering={true}
                                    allowResizing={true}
                                    allowEditing={false}
                                    fixed={true}
                                    showInColumnChooser={true}
                                    visible={false}
                                />
                                <Column
                                    dataField='Amount6'
                                    dataType='number'
                                    caption=''
                                    sortIndex={0}
                                    sortOrder='Asc'
                                    width='auto'
                                    allowFiltering={true}
                                    allowResizing={true}
                                    allowEditing={false}
                                    fixed={true}
                                    showInColumnChooser={true}
                                    visible={false}
                                />
                                <Column
                                    dataField='Amount7'
                                    dataType='number'
                                    caption=''
                                    sortIndex={0}
                                    sortOrder='Asc'
                                    width='auto'
                                    allowFiltering={true}
                                    allowResizing={true}
                                    allowEditing={false}
                                    fixed={true}
                                    showInColumnChooser={true}
                                    visible={false}
                                />
                                <Column
                                    dataField='Amount8'
                                    dataType='number'
                                    caption=''
                                    sortIndex={0}
                                    sortOrder='Asc'
                                    width='auto'
                                    allowFiltering={true}
                                    allowResizing={true}
                                    allowEditing={false}
                                    fixed={true}
                                    showInColumnChooser={true}
                                    visible={false}
                                />
                                <Column
                                    dataField='Amount9'
                                    dataType='number'
                                    caption=''
                                    sortIndex={0}
                                    sortOrder='Asc'
                                    width='auto'
                                    allowFiltering={true}
                                    allowResizing={true}
                                    allowEditing={false}
                                    fixed={true}
                                    showInColumnChooser={true}
                                    visible={false}
                                />
                                <Column
                                    dataField='Amount10'
                                    dataType='number'
                                    caption=''
                                    sortIndex={0}
                                    sortOrder='Asc'
                                    width='auto'
                                    allowFiltering={true}
                                    allowResizing={true}
                                    allowEditing={false}
                                    fixed={true}
                                    showInColumnChooser={true}
                                    visible={false}
                                />


                                <Column
                                    dataField='TotalAmount'
                                    dataType='number'
                                    caption='Total'
                                    sortIndex={0}
                                    sortOrder='Asc'
                                    width='auto'
                                    allowFiltering={true}
                                    allowResizing={true}
                                    allowEditing={false}
                                    fixed={true}
                                    showInColumnChooser={true}
                                    visible={true}
                                />
                            </Column>
                        </DataGrid>
                    </div>

                    <div className="pet-export-div">
                        <div className="pet-export-checkbox-div">
                            <CheckBox
                                defaultValue={false}
                                text="Export colors"
                                onInitialized={this.checkBoxExportColors_OnInitialized}
                                onValueChanged={this.checkBoxExportColors_OnValueChanged}
                            />
                        </div>
                        <div className="pet-export-button-div">
                            <Button
                                width={120}
                                text="Export"
                                className='dx-button'
                                onClick={this.buttonSave_OnClick}
                            />
                        </div>
                    </div>

                    <LoadPanel
                        hideOnOutsideClick={true}
                        visible={this.state.isLoadPanelVisible}
                    />
                </Popup>

            </React.Fragment>

        );

    }

}

export default PopupExportTotalsList

