import React from 'react';
import DataGrid, {
    RemoteOperations, Editing, ColumnChooser, GroupPanel, FilterPanel, FilterRow, HeaderFilter, Paging, Pager, Scrolling, Column, Button, SearchPanel, KeyboardNavigation, Export, StateStoring, Sorting, Lookup, Selection
} from 'devextreme-react/data-grid';
import { LoadPanel } from 'devextreme-react/load-panel';
import ServiceProducts from '../../../../../api/services/ServiceProducts'

import { HubConnectionBuilder } from '@microsoft/signalr';

import './DataGridProducts.scss'

// props
//      onInitialized: callback
//      onExportRider: callback
//      onConnectionError: callback
//      onEditProduct: callback
//      onDeleteProduct: callback
 
class DataGridProducts extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dataSourceProducts: [],
            dataSourceProductTypes: [],
            showFilter: false,
        };
            
        this.dataGridInstance = null;

        this.hubConnection = null;
 
        this.viewState = {};

        this.userSettings = {
            UserId: parseInt(sessionStorage.getItem("currentUserId"), 10),
            DefaultViewId: -1,
            DefaultFilterId: -1,
            ExportColors: false,
            UseBlanksForZeroes: true,
            UseColorsProducts: true,
            ColorOriginProducts: 'SHOP',
            ShowSelectedCell: false,
            UseAdvancedFiltering: false,
        }

        this.loadPanelInstance = null;
        this.loadingPosition = { of: '#grid' }
    }


    // CALLBACKS
    onInitialized = (instance) => {
        if (this.props.onInitialized) {
            this.props.onInitialized(instance)
        }
    }

    onEditProduct = (id) => {
        if (this.props.onEditProduct) {
            this.props.onEditProduct(id)
        }
    }

    onDeleteProduct = (id, name) => {
        if (this.props.onDeleteProduct) {
            this.props.onDeleteProduct(id, name)
        }
    }

    onExportProduct = () => {
        if (this.props.onExportProduct) {
            this.props.onExportProduct()
        }
    }

    onConnectionError = () => {
        if (this.props.onConnectionError) {
            this.props.onConnectionError();
        }
    }

    // CALLABLES
    gridRefresh = async () => {
        await this.loadPanelInstance.show()
        await this.loaddataSourceProducts();
        await this.loaddataSourceProductTypes();
        await this.loadPanelInstance.hide()
    }

    // EVENTS
    componentDidMount = async () => {
        this.hubConnection = new HubConnectionBuilder().withUrl("/productsHub").build();

        await this.hubConnection.start()
            .then(function () {

            })
            .catch(function (err) {
                return console.error(err.toString());
            });

        this.hubConnection.on("ReceiveMessageAddedProduct", await this.updatePushedDataAddedProduct);
        this.hubConnection.on("ReceiveMessageEditedProduct", await this.updatePushedDataEditedProduct);
        this.hubConnection.on("ReceiveMessageDeletedProduct", await this.updatePushedDataDeletedProduct);

        await this.loadPanelInstance.show()
        await this.loaddataSourceProducts();
        await this.loaddataSourceProductTypes();
        await this.loadPanelInstance.hide()
    }

    // DATA
    loaddataSourceProducts = async () => {
        var data = await ServiceProducts.getProductsInfo(parseInt(sessionStorage.getItem("currentEventId"), 10), this.userSettings.ColorOriginProducts);
        if (data !== null && !data.hasOwnProperty("error")) {
            this.setState({ dataSourceProducts: data });
        } else {
            this.onConnectionError();  // callback
        }
    }

    loaddataSourceProductTypes = async () => {
        var data = await ServiceProducts.getProductTypesDropDown();
        if (data !== null && !data.hasOwnProperty("error")) {
            this.setState({ dataSourceProductTypes: data });
        } else {
            this.onConnectionError();  // callback
        }
    }


    // LOADPANEL
    loadPanelOnInitialized = (e) => {
        // set instance
        this.loadPanelInstance = e.component;
    }

    // GRID
    gridOnCellPrepared = (e) => {
        if (e.rowType === 'data') {
            if (e.column.dataField === 'Shops' || e.column.dataField === 'ProductName' || e.column.dataField === 'ProductTypeId') {
                if (this.userSettings.UseColorsProducts) {
                    e.cellElement.style.backgroundColor = e.data.ColorHex;
                    e.cellElement.style.color = e.data.ForeColorHex;
                }
            } else {
                e.cellElement.style.backgroundColor = '#FFFFFF';
                e.cellElement.style.color = '#000000';
            }
        } else {
            if (e.rowType === 'filter') {
                e.cellElement.style.backgroundColor = '#FFFFFF';
                e.cellElement.style.color = '#000000';
            }
        }
    }

    gridOnFocusedCellChanging = async (e) => {
        let elements = document.getElementsByClassName("hx-datagrid-cell-border");
        if (elements) {
            for (var i = 0; i < elements.length; i++) {
                elements[i].classList.remove('hx-datagrid-cell-border');
            }
        }

        e.cellElement[0].classList.add('hx-datagrid-cell-border');
    }

    gridOnCellClick = (e) => {
        if (e.rowType === 'data') {
            if (e.column.name == 'ActionColumnEdit') {
                this.onEditProduct(e.data.Id);
            } else if (e.column.name == 'ActionColumnDelete') {
                if (e.data.CanBeDeleted == true) {
                    this.onDeleteProduct(e.data.Id, e.data.ProductName);
                }
            }
        }
    }

    gridOnEditorPreparing = (e) => {
        if (e.parentType === 'dataRow') {
            if (e.editorName === "dxNumberBox") {
                e.editorOptions.step = 0;
                e.editorElement.parentElement.classList.add('hx-datagrid-cell-border');
            } else if (e.editorName === "dxTextBox") {
                e.editorElement.parentElement.classList.add('hx-datagrid-cell-border');
           }
        }
    }

    gridOnRowUpdating = async (e) => {
        for (var key in e.newData) {
            if (e.newData.hasOwnProperty(key)) {
                if (key === 'EKP') {
                    var data = await ServiceProducts.updateEKP(e.oldData.Id, e.newData[key]);
                    if (data !== null && !data.hasOwnProperty("error")) {
                        this.hubConnection
                            .invoke("SendUpdateInfoEditedProduct", e.oldData.Id)
                            .catch(function (err) {
                                return console.error(err.toString());
                            });
                    } else {
                        this.onConnectionError();  // callback
                    }
                } else if (key === 'ArticleNo') {
                    var data = await ServiceProducts.updateArticleNo(e.oldData.Id, e.newData[key] !== null ? e.newData[key].trim() : '');
                    if (data !== null && !data.hasOwnProperty("error")) {
                        this.hubConnection
                            .invoke("SendUpdateInfoEditedProduct", e.oldData.Id)
                            .catch(function (err) {
                                return console.error(err.toString());
                            });
                    } else {
                        this.onConnectionError();  // callback
                    }
                } else if (key === 'Packaging') {
                    var data = await ServiceProducts.updatePackaging(e.oldData.Id, e.newData[key] !== null ? e.newData[key].trim() : '');
                    if (data !== null && !data.hasOwnProperty("error")) {
                        this.hubConnection
                            .invoke("SendUpdateInfoEditedProduct", e.oldData.Id)
                            .catch(function (err) {
                                return console.error(err.toString());
                            });
                    } else {
                        this.onConnectionError();  // callback
                    }
                } else if (key === 'ProductName') {
                    var data = await ServiceProducts.updateName(e.oldData.Id, e.newData[key] !== null ? e.newData[key].trim() : '');
                    if (data !== null && !data.hasOwnProperty("error")) {
                        this.hubConnection
                            .invoke("SendUpdateInfoEditedProduct", e.oldData.Id)
                            .catch(function (err) {
                                return console.error(err.toString());
                            });
                    } else {
                        this.onConnectionError();  // callback
                    }
                } else if (key === 'ProductTypeId') {
                    var data = await ServiceProducts.updateProductTypeId(e.oldData.Id, e.newData[key]);
                    if (data !== null && !data.hasOwnProperty("error")) {
                        this.hubConnection
                            .invoke("SendUpdateInfoEditedProduct", e.oldData.Id)
                            .catch(function (err) {
                                return console.error(err.toString());
                            });
                    } else {
                        this.onConnectionError();  // callback
                    }
                }

                break;
            }
        }
    }

    gridOnEditingStart = (e) => {
        if (!e.column || e.column.dataType === 'boolean') {
            return;
        }

        // make sure onrowupdating is always triggered after edit
        var row = this.dataGridInstance.getRowIndexByKey(e.key);
        var value = this.dataGridInstance.cellValue(row, e.column.dataField);

        if (e.column.dataType.toLowerCase() == "string") {
            this.dataGridInstance.cellValue(row, e.column.dataField, (value !== "%___%" ? "%___%" : ""));
        } else {
            this.dataGridInstance.cellValue(row, e.column.dataField, (value !== 999999 ? 999999 : 0));
        }
        this.dataGridInstance.cellValue(row, e.column.dataField, value);
    }

    gridOnEditCanceled = async (e) => {
        var id = this.dataGridInstance.getKeyByRowIndex(this.dataGridInstance.option('focusedRowIndex'));

        if (id) {
            await this.updateRow(id);
        }
    }

    gridOnCellHoverChanged = (e) => {
        if (e.rowType === 'data') {
            // hide hover color
            for (let col of this.dataGridInstance.option('columns')) {
                if (col.visible && (col.dataField == 'Shops' || col.dataField == 'ProductName' || col.dataField == 'ProductTypeId')) {
                    var cell = this.dataGridInstance.getCellElement(e.rowIndex, col.dataField);
                    if (cell !== undefined) {
                        if (this.userSettings.UseColorsProducts) {
                            cell.style.backgroundColor = (e.data.ColorHex !== null ? e.data.ColorHex : '#FFFFFF');
                            cell.style.color = (e.data.ForeColorHex !== null ? e.data.ForeColorHex : '#000000');
                        } else {
                            cell.style.backgroundColor = '#FFFFFF';
                            cell.style.color = '#000000';
                        }
                    }
                }
            }
        } 
    }

    gridOnExporting = (e) => {
        e.cancel = true;
        this.onExportProduct();  // callback
    }

    gridOnInitialized = (e) => {
        // set instance
        this.dataGridInstance = e.component;
        this.onInitialized(e.component);  // callback
    }

    gridOnToolbarPreparing = (e) => {
        let toolbarItems = e.toolbarOptions.items;
        // Adds a new item
        toolbarItems.push({
            widget: 'dxButton',
            options: {
                icon: 'filter',
                onClick: this.switchFilter
            },
            location: 'after'
        });
    }

    gridOnContentReady = (e) => {
         // check filters, 'product', 'shop' and 'type' input fields become empty if cols added (dx bug)
         if (this.viewState.filters !== undefined) {
            for (let filter in this.viewState.filters) {
                if (this.state.dataSourceProducts !== undefined && this.state.dataSourceProducts.length > 0 && this.state.dataSourceProducts[0].hasOwnProperty(filter)) {
                    if (this.dataGridInstance.columnOption(filter, 'filterValue') !== this.viewState.filters[filter]) {
                        this.dataGridInstance.columnOption(filter, 'filterValue', this.viewState.filters[filter]);
                    }
                }
            }
        }
    }

    gridOnContextMenuPreparing = (e) => {

        if (e.row.rowType !== 'data') {
            return;
        }

        // e.items can be undefined
        if (!e.items) e.items = [];

        // update selected and focused row (right click outside selection)
        //var selectedKeys = this.dataGridInstance.getSelectedRowKeys();
        //var focused = this.dataGridInstance.option("focusedRowKey");
        //if (!selectedKeys.includes(focused)) {
        //    this.dataGridInstance.deselectAll();
        //    this.dataGridInstance.selectedRowKeys = [focused];
        //    this.contextMenuMode = 'single';
        //}

        e.items.push({
            text: 'Delete ' + e.row.data.ProductName,
            onItemClick: () => {
                this.onDeleteProduct(e.row.data.Id, e.row.data.ProductName);
            }
        });

    }

    switchFilter = () => {
        this.setState(prevState => ({
            showFilter: !prevState.showFilter
        }));
    }



    // update cells modified by other users (collaborative editing, SignalR)
    updatePushedDataAddedProduct = async (productId) => {
        this.gridRefresh();
    }

    updatePushedDataEditedProduct = async (productId) => {
        let isEditing = this.dataGridInstance.element().querySelector(".dx-datagrid-rowsview td.dx-editor-cell.dx-focused") != null;
        if (!isEditing) {
            await this.updateRow(productId);
        }
    }

    updateRow = async (id) => {
        this.dataGridInstance.beginUpdate();
        var data = await ServiceProducts.getProductInfoById(parseInt(sessionStorage.getItem("currentEventId"), 10), id, this.userSettings.ColorOriginProducts);
        this.dataGridInstance.endUpdate();
        var dsRow = this.state.dataSourceProducts.find(function (row) {
            return row.Id === id;
        });
        if (dsRow) {
            Object.entries(dsRow).forEach(([key, value]) => {
                dsRow[key] = data[key];
            });
        }
        var rowIndex = this.dataGridInstance.getRowIndexByKey(id);
        this.dataGridInstance.repaintRows(rowIndex);
        setTimeout(this.gridFocus, 10);
    }

    gridFocus = () => {
        this.dataGridInstance.focus();
    }

    updatePushedDataDeletedProduct = async (productId) => {
        this.gridRefresh();
    }

    // RENDER
    renderEditTemplate = (e) => {
        return <div style={{ display: 'flex', justifyContent: 'start' }}>
            <div style={{ width: '40px' }}><i className='dx-icon-edit' style={{ color: '#FF5722' }} /></div>
        </div>;
    }

    renderDeleteTemplate = (e) => {
        if (e.data.CanBeDeleted == true) {
            return <div style={{ display: 'flex', justifyContent: 'start' }}>
                <div style={{ width: '40px' }}><i className='dx-icon-trash' style={{ color: '#FF5722' }} /></div>
            </div>;
        } else {
            return <div style={{ display: 'flex', justifyContent: 'start' }}>
                <div style={{ width: '40px' }}><i className='dx-icon-trash' style={{ color: '#666666' }} /></div>
            </div>;
        }
    }

    render() {
        return (
            <React.Fragment>
                <div id="grid" className="rapdg-main-div">
                    <DataGrid
                        ref={ref => this.refDataGrid = ref}
                        height='100%'
                        width='100%'
                        dataSource={this.state.dataSourceProducts}
                        keyExpr='Id'
                        allowColumnReordering={false}
                        allowColumnResizing={true}
                        allowGrouping={false}
                        columnResizingMode='widget'
                        columnAutoWidth={true}
                        focusedRowEnabled={false}
                        noDataText='No products found.'
                        filterSyncEnabled={true}
                        preloadEnabled={true}
                        remoteOperations={true}
                        showBorders={false}
                        showColumnLines={true}
                        showRowLines={true}
                        wordWrapEnabled={false}
                        repaintChangesOnly={true}
                        onExporting={this.gridOnExporting}
                        onInitialized={this.gridOnInitialized}
                        onCellPrepared={this.gridOnCellPrepared}
                        onToolbarPreparing={this.gridOnToolbarPreparing}
                        onRowUpdating={this.gridOnRowUpdating}
                        onCellClick={this.gridOnCellClick}
                        onFocusedCellChanging={this.gridOnFocusedCellChanging}
                        onEditorPreparing={this.gridOnEditorPreparing}
                        onContentReady={this.gridOnContentReady}
                        onCellHoverChanged={this.gridOnCellHoverChanged}
                        onEditCanceled={this.gridOnEditCanceled}
                        onEditingStart={this.gridOnEditingStart}
                    >
                        <StateStoring enabled={false} type="localStorage" storageKey="storage" />
                        <Export
                            enabled={false}
                        />
                        <KeyboardNavigation
                            enabled={true}
                            editOnKeyPress={true}
                            enterKeyAction='moveFocus'
                            enterKeyDirection='column' />
                        <Editing
                            mode="cell"
                            allowUpdating={true}
                            selectTextOnEditStart={true}
                            startEditAction='dblClick'
                            step={0}
                        />
                        <Sorting mode="multiple" />
                        <ColumnChooser enabled={false} />
                        <FilterPanel visible={this.state.useAdvancedFiltering} />
                        <FilterRow visible={this.state.showFilter} />
                        <GroupPanel visible={false} />
                        <SearchPanel visible={true} />
                        <HeaderFilter visible={true} />
                        <Paging enabled={false} defaultPageSize={20} />
                        <Pager

                            showPageSizeSelector={true}
                            allowedPageSizes={[5, 10, 20, 50]}
                            showInfo={true}
                        />
                        <Scrolling
                            mode="virtual"
                            useNative='false'
                            scrollByContent={true}
                            scrollByThumb={true}
                            showScrollbar='always'
                        />
                        <Column
                            dataField='Id'
                            dataType='number'
                            caption='Id'
                            allowFiltering={false}
                            allowEditing={false}
                            fixed={true}
                            showInColumnChooser={false}
                            visible={false}
                            width={100}
                        />
                        <Column
                            dataField='ProductTypeName'
                            dataType='string'
                            caption='ProductTypeName'
                            allowFiltering={false}
                            allowEditing={false}
                            fixed={true}
                            showInColumnChooser={true}
                            visible={false}
                        />
                        <Column
                            dataField='ShopIds'
                            dataType='string'
                            caption='ShopIds'
                            allowFiltering={false}
                            allowEditing={false}
                            fixed={true}
                            showInColumnChooser={false}
                            visible={false}
                        />
                        <Column
                            dataField='UsePattern'
                            dataType='number'
                            caption='UsePattern'
                            allowFiltering={false}
                            allowEditing={false}
                            fixed={true}
                            showInColumnChooser={false}
                            visible={false}
                        />
                        <Column
                            dataField='ColorId'
                            dataType='number'
                            caption='ColorId'
                            allowFiltering={false}
                            allowEditing={false}
                            fixed={true}
                            showInColumnChooser={false}
                            visible={false}
                        />
                        <Column
                            dataField='PatternId'
                            dataType='number'
                            caption='PatternId'
                            allowFiltering={false}
                            allowEditing={false}
                            fixed={true}
                            showInColumnChooser={false}
                            visible={false}
                        />
                        <Column
                            dataField='ColorHex'
                            dataType='string'
                            caption='ColorHex'
                            allowFiltering={false}
                            allowEditing={false}
                            fixed={true}
                            showInColumnChooser={false}
                            visible={false}
                        />
                        <Column
                            dataField='ForeColorHex'
                            dataType='string'
                            caption='ForeColorHex'
                            allowFiltering={false}
                            allowEditing={false}
                            fixed={true}
                            showInColumnChooser={false}
                            visible={false}
                        />
                        <Column
                            dataField='PatternColorHex'
                            dataType='string'
                            caption='PatternColorHex'
                            allowFiltering={false}
                            allowEditing={false}
                            fixed={true}
                            showInColumnChooser={false}
                            visible={false}
                        />
                        <Column
                            dataField='PatternCssBackGround'
                            dataType='string'
                            caption='PatternCssBackGround'
                            allowFiltering={false}
                            allowEditing={false}
                            fixed={true}
                            showInColumnChooser={false}
                            visible={false}
                        />
                        <Column
                            dataField='PatternCssBackGroundImage'
                            dataType='string'
                            caption='PatternCssBackGroundImage'
                            allowFiltering={false}
                            allowEditing={false}
                            fixed={true}
                            showInColumnChooser={false}
                            visible={false}
                        />
                        <Column
                            dataField='PatternCssBackGroundSize'
                            dataType='string'
                            caption='PatternCssBackGroundSize'
                            allowFiltering={false}
                            allowEditing={false}
                            fixed={true}
                            showInColumnChooser={false}
                            visible={false}
                        />
                        <Column
                            dataField='PatternCssBackGroundPosition'
                            dataType='string'
                            caption='PatternCssBackGroundPosition'
                            allowFiltering={false}
                            allowEditing={false}
                            fixed={true}
                            showInColumnChooser={false}
                            visible={false}
                        />
                        <Column
                            dataField='PatternTransparency'
                            dataType='number'
                            caption='PatternTransparency'
                            allowFiltering={false}
                            allowEditing={false}
                            fixed={true}
                            showInColumnChooser={false}
                            visible={false}
                        />
                        <Column
                            name='ActionColumnEdit'
                            caption=''
                            allowFiltering={false}
                            allowEditing={false}
                            allowColumnResizing={false}
                            allowColumnReordering={false}
                            allowExporting={false}
                            fixed={true}
                            showInColumnChooser={false}
                            visible={true}
                            width={40}
                            alignment='center'
                            cellRender={this.renderEditTemplate}
                        />

                        <Column
                            dataField='ProductName'
                            dataType='string'
                            caption='Product'
                            width='350px'
                            allowFiltering={true}
                            allowEditing={true}
                            allowResizing={false}
                            fixed={true}
                            showInColumnChooser={true}
                            visible={true}
                            cssClass='wrapped-column-class'
                        />
                        {/*<Column*/}
                        {/*    dataField='ProductTypeId'*/}
                        {/*    dataType='number'*/}
                        {/*    caption='Type'*/}
                        {/*    allowFiltering={true}*/}
                        {/*    allowEditing={true}*/}
                        {/*    fixed={true}*/}
                        {/*    showInColumnChooser={true}*/}
                        {/*    visible={true}*/}
                        {/*    width={100}*/}
                        {/*>*/}
                        {/*    <Lookup*/}
                        {/*        dataSource={this.state.dataSourceProductTypes}*/}
                        {/*        displayExpr="Name"*/}
                        {/*        valueExpr="Id"*/}
                        {/*    />*/}
                        {/*</Column>*/}
                        <Column
                            dataField='Shops'
                            dataType='string'
                            caption='Shop'
                            width='150px'
                            allowFiltering={true}
                            allowResizing={false}
                            allowEditing={false}
                            fixed={true}
                            showInColumnChooser={true}
                            visible={true}
                            cssClass='wrapped-column-class'
                        />
                        <Column
                            dataField='Packaging'
                            dataType='string'
                            caption='Packaging'
                            width={150}
                            allowFiltering={true}
                            allowResizing={false}
                            allowEditing={true}
                            fixed={false}
                            showInColumnChooser={true}
                            visible={true}
                        />
                        <Column
                            dataField='ArticleNo'
                            dataType='string'
                            caption='ArticleNo'
                            width={150}
                            allowFiltering={true}
                            allowResizing={false}
                            allowEditing={true}
                            fixed={false}
                            showInColumnChooser={true}
                            visible={true}
                        />
                        <Column
                            name='ActionColumnDelete'
                            caption=''
                            allowFiltering={false}
                            allowEditing={false}
                            allowColumnResizing={false}
                            allowColumnReordering={false}
                            allowExporting={false}
                            fixed={false}
                            showInColumnChooser={false}
                            visible={true}
                            width={40}
                            alignment='center'
                            cellRender={this.renderDeleteTemplate}
                        />
                        <Column key='productcoldummy' caption='' allowFiltering={false} allowEditing={false} allowResizing={true} allowExporting={false} width='100%' />
                    </DataGrid>
                </div>
                <LoadPanel
                    onInitialized={this.loadPanelOnInitialized}
                    shadingColor="rgba(0,0,0,0.4)"
                    position={this.loadingPosition}
                    shading={false}
                />
            </React.Fragment>


        );

    }

}

export default DataGridProducts

