import React from 'react';
import { ShuttlesAdminLocations } from '../../../../components';

class shuttlesAdminLocations extends React.Component {
    render() {
        return (
            <ShuttlesAdminLocations />
        );
    }
}

export default shuttlesAdminLocations