import React from 'react';
import { Popup } from 'devextreme-react/popup';
import TextBox from 'devextreme-react/text-box';
import Validator, { CustomRule, ValidationRule, AsyncRule } from 'devextreme-react/validator';
import { Button } from 'devextreme-react/button';
import { ValidationGroup } from 'devextreme-react/validation-group';
import ServiceLocations from '../../../../../api/services/ServiceLocations'
import ColorBox from 'devextreme-react/color-box';

import './PopupAddEditShops.scss'

// props
//      setValues: callable
//
//      onInitialized: callback
//      onUpdate: callback
//      onSave: callback
//      onError: callback

class PopupAddEditShops extends React.Component {
    constructor(props) {
        super(props);

        this.shop = {};

        this.popupInstance = null;

        this.textBoxNameInstance = null;

        this.colorBoxInstance = null;
        this.colorBoxTextInstance = null;

        this.buttonSaveInstance = null;

        this.validationGroupInstance = null;
        
        this.shopId = -1;
        this.shopName = '';
        this.shopEditMode = '';
        this.shopsInfoModel = {}

    }

    // CALLBACKS
    onInitialized = (instance) => {
        if (this.props.onInitialized) {
            this.props.onInitialized(instance);  // callback
        }
    }

    onUpdate = (shopsInfoModel) => {
        if (this.props.onUpdate) {
            this.props.onUpdate(shopsInfoModel);  // callback
        }
    }

    onSave = (shopsInfoModel) => {
        if (this.props.onSave) {
            this.props.onSave(shopsInfoModel);  // callback
        }
    }
    onError = (error) => {
        if (this.props.onError) {
            this.props.onError(error);
        }
    }

    // CALLABLES
    setValues = (id, editMode) => {
        this.shopEditMode = editMode.toLowerCase().trim();
        this.shopId = this.shopEditMode != "new" ? id : -1;
    }

    // EVENTS
    componentDidMount = async () => {
    }

    // DATA
    loadDataSourceShop = async (id) => {
        var data = await ServiceLocations.getShopById(id);
        if (data !== null && !data.hasOwnProperty("error")) {
            this.shop = data;
        } else {
            this.onError(true);
        }
    }

    // POPUP
    popup_OnInitialized = (e) => {
        this.popupInstance = e.component;
        this.onInitialized(e.component);
    }

    popup_OnShowing = async(e) => {
        // reset first or textbox is not filled
        this.validationGroupInstance.reset();

        switch (this.shopEditMode) {
            case 'new':
                this.popupInstance.option('title', 'Add new shop');
                this.textBoxNameInstance.option('value', '');
                this.colorBoxInstance.option('value', null);
                this.colorBoxTextInstance.option('value', null);
                break;
            case 'update':
                await this.loadDataSourceShop(this.shopId);

                this.popupInstance.option('title', 'Update ' + this.shop.ShopName);
                this.textBoxNameInstance.option('value', this.shop.ShopName);
                this.colorBoxInstance.option('value', this.shop.ColorRgba);
                this.colorBoxTextInstance.option('value', this.shop.ForeColorRgba);

                break;
            default:
                break;
        }
    }

    popup_OnShown = (e) => {
        this.textBoxNameInstance.focus();
        document.getElementById('textBoxNameInput').select();
    }


    // TEXTBOX NAME
    textBoxName_OnInitialized = (e) => {
        this.textBoxNameInstance = e.component;
    }
    textBoxName_OnFocusIn = (e) => {
        // select all text
        e.element.querySelector('.dx-texteditor-input').select();
    }

    textBoxAttributes = {
        id: 'textBoxNameInput'
    }

    // COLORBOX
    colorBox_OnInitialized = (e) => {
        this.colorBoxInstance = e.component;
    }
    colorBoxText_OnInitialized = (e) => {
        this.colorBoxTextInstance = e.component;
    }

    // BUTTONS
    buttonSave_OnInitialized = (e) => {
        this.buttonSaveInstance = e.component;
    }

    buttonSave_OnClick = (e) => {
        const res = this.validationGroupInstance.validate();
        res.status === "pending" && res.complete.then((r) => {
            if (r.status === 'valid') {
                this.shopsInfoModel = {
                    Id: (this.shopEditMode == 'new' ? -1 : this.shopId),
                    EventId: parseInt(sessionStorage.getItem("currentEventId"), 10),
                    ColorId: this.shop.ColorId,
                    PatternId: this.shop.PatternId,
                    ShopName: this.textBoxNameInstance.option("text").trim(),
                    ColorHex: this.colorBoxInstance.option("value"),
                    ColorRgba: this.colorBoxInstance.option("value"),
                    ForeColorHex: this.colorBoxTextInstance.option("value"),
                    ForeColorRgba: this.colorBoxTextInstance.option("value"),
                    PatternColorHex: this.colorBoxInstance.option("value"),
                    PatternColorRgba: this.colorBoxInstance.option("value"),
                    UsePattern: this.shop.UsePattern,
                    PatternCssBackGround: this.shop.PatternCssBackGround,
                    PatternCssBackGroundImage: this.shop.PatternCssBackGroundImage,
                    PatternCssBackGroundSize: this.shop.PatternCssBackGroundSize,
                    PatternCssBackGroundPosition: this.shop.PatternCssBackGroundPosition,
                    PatternTransparency: this.shop.PatternTransparency
                }
                if ((this.colorBoxInstance.option('value') !== null && this.colorBoxTextInstance.option('value') !== null) || (this.colorBoxInstance.option('value') === null && this.colorBoxTextInstance.option('value') === null)) {
                    if (this.shopEditMode == 'new') {
                        this.onSave(this.shopsInfoModel);
                    } else {
                        this.onUpdate(this.shopsInfoModel);
                    }
                    this.popupInstance.hide();
                } else {
                    if (this.colorBoxInstance.option('value') === null) {
                        this.colorBoxInstance.focus();
                    } else {
                        this.colorBoxTextInstance.focus();
                    }
                }
            } else {
                this.textBoxNameInstance.focus();
            }
        });
    }


    // VALIDATION GROUP
    validationGroup_OnInitialized = (e) => {
        this.validationGroupInstance = e.component;
    }

    validationCallback = async (e) => {
        var exists = await ServiceLocations.checkIfShopExists(this.shopId, e.value);
        return !exists;
    }

    render() {
        let buttons;

        return (
            <React.Fragment>
                <Popup
                    dragEnabled={false}
                    hideOnOutsideClick={false}
                    showTitle={true}
                    width={340}
                    height={250}
                    showCloseButton={true}
                    onInitialized={this.popup_OnInitialized}
                    onShown={this.popup_OnShown}
                    onShowing={this.popup_OnShowing}
                >
                    <ValidationGroup onInitialized={this.validationGroup_OnInitialized}>
                        <div>
                            <div className="paes-dx-field">
                                <div className="dx-field-label paes-label">Name*</div>
                                <div className="dx-field-value">
                                    <TextBox
                                        width={250}
                                        onInitialized={this.textBoxName_OnInitialized}
                                        inputAttr={this.textBoxAttributes}
                                        onFocusIn={this.textBoxName_OnFocusIn}
                                        defaultValue=''
                                        hint='Enter name ...'
                                        validationMessageMode='always'
                                    >
                                        <Validator>
                                            <ValidationRule
                                                type="required"
                                                message="Required" />
                                            <AsyncRule
                                                message="Name already exists"
                                                validationCallback={this.validationCallback}
                                            />
                                        </Validator>
                                    </TextBox>
                                </div>
                            </div>

                            <div className="paes-dx-field">
                                <div className="dx-field-label paes-label">Color</div>
                                <div className="dx-field-value">
                                    <ColorBox
                                        width={250}
                                        onInitialized={this.colorBox_OnInitialized}
                                        showClearButton={true}
                                        editAlphaChannel={true}
                                        validationMessageMode='always'
                                    >
                                    </ColorBox>
                                </div>
                            </div>

                            <div className="paes-dx-field">
                                <div className="dx-field-label paes-label">Textcolor</div>
                                <div className="dx-field-value">
                                    <ColorBox
                                        width={250}
                                        onInitialized={this.colorBoxText_OnInitialized}
                                        showClearButton={true}
                                        editAlphaChannel={true}
                                        validationMessageMode='always'
                                    >
                                    </ColorBox>
                                </div>
                            </div>

                            {buttons}

                        </div>
                    </ValidationGroup>
                    <div className="paes-buttons">
                        <Button
                            onInitialized={this.buttonSave_OnInitialized}
                            text='Save'
                            width={120}
                            icon="save"
                            onClick={this.buttonSave_OnClick}
                        />
                    </div>;
                </Popup>
            </React.Fragment>
        );
    }

}

export default PopupAddEditShops

