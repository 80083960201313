import React from 'react';
import List from 'devextreme-react/list';

import ServiceUsers from '../../api/services/ServiceUsers'
import ServiceDrivers from '../../api/services/ServiceDrivers'

import { Button } from 'devextreme-react/button';

import HxToast from '../custom-components/hx-toast/HxToast'

import './UsersAdmin.scss'

class UsersAdmin extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dataSourceList: [],
        };
    }

    // EVENTS
    componentDidMount = async () => {
        await this.buttonAddUser_OnClick();
    }

    // DATA

    buttonAddUser_OnClick = async () => {
        var data = await ServiceDrivers.getDriverOverView(parseInt(sessionStorage.getItem('currentEventId'), 10));
        if (data !== null) {
            if (!data.hasOwnProperty("error")) {
                this.setState({ dataSourceList : data});
            } else {
                console.log("error getting overview");
            }
        } else {
            HxToast.showToast('Getting overview failed!', 'error', 'bottom right', 'up-stack');
        }
    }

    listItemRender = (item) => {
        return (
            <div className="ua-main-div">
                <div className="ua-name">{item.Name}</div>
                <div className="ua-count">{item.NoOfTrips}</div>
            </div>
        );
    }

    render() {
        return (
            <div>
                <div className="ua-button-div">
                    <Button
                        text="Refresh"
                        onClick={this.buttonAddUser_OnClick} />
                </div>
                <div className="ua-list-div">
                    <List
                        dataSource={this.state.dataSourceList}
                        keyExpr='Id'
                        width='300px'
                        showSelectionControls={true}
                        selectionMode='multiple'
                        selectAllMode='allPages'
                        itemRender={this.listItemRender}
                        pageLoadMode='scrollBottom' >
                    </List>
                </div>
            </div>
        );
    }

}

export default UsersAdmin

