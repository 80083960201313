import { custom } from 'devextreme/ui/dialog';

class HxDialog {

    static showDialogYesNo = async (title, message) => {
        let msg = message;
        let myDialog = custom({
            title: title,
            messageHtml: msg,
            buttons: [{
                text: "Yes",
                onClick: (ev) => {
                    return { cancelled: false };
                }
            },
            {
                text: "No",
                onClick: (ev) => {
                    return { cancelled: true };
                }

            },
            ]
        });

        let returnValue = false;
        await myDialog.show()
            .then((dialogResult) => {
                if (!dialogResult.cancelled) {
                    returnValue = true;
                }
            });

        return returnValue;
    }

}

export default HxDialog;