import React from 'react';
import { Popup } from 'devextreme-react/popup';
import { Button } from 'devextreme-react/button';
import { LoadPanel } from 'devextreme-react/load-panel';
import HtmlEditor, {
    Toolbar, MediaResizing, ImageUpload, Item,
} from 'devextreme-react/html-editor';

import HxTags from '../../../custom-components/hx-tags/HxTags'
import ServiceTrips from '../../../../api/services/ServiceTrips'
import ServiceEvents from '../../../../api/services/ServiceEvents'

import './PopupShuttlesEmail.scss';

// props
//      subtrips (required): array of subtrips
//
//      onInitialized: callback
//      onLoadError: callback

class PopupShuttlesEmail extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loadPanelVisible: false,
        }

        this.emailData = [];

        this.dataSourceTrips = [];

        this.popupInstance = null;

        this.htmlEditorNotesInstance = null;

        this.dropoffDisplay = 'none';
        this.pickupWaitTimeDisplay = 'none';
        this.dropoffWaitTimeDisplay = 'none';
        this.dropoffWaitTimeDisplay2 = 'none';

        this.eventDates = [];
        this.selectedEventDates = [];

    }

    // EVENTS
    componentDidMount = async () => {
    }


    // CALLBACKS
    onInitialized = (instance) => {
        if (this.props.onInitialized) {
            this.props.onInitialized(instance);  // callback
        }
    }

    onLoadError = () => {
        if (this.props.onLoadError) {
            this.props.onLoadError();  // callback
        }
    }
    // CALLABLE
    setData = async (id, artistId) => {
        await this.loadDataSourceEventDates();
        await this.loadDataSourceTrips(artistId);
    }

    // DATA
    loadDataSourceTrips = async (artistId) => {
        var data = await ServiceTrips.getTripInfosByArtistId(artistId);
        if (data !== null && !data.hasOwnProperty("error")) {
            this.dataSourceTrips = data;
        } else {
            this.onLoadError();  // callback
        }
    }

    loadDataSourceEventDates = async () => {
        var data = await ServiceEvents.getEventDatesDropDownByEventId(parseInt(sessionStorage.getItem('currentEventId'), 10), 'shuttles');
        if (data !== null && !data.hasOwnProperty("error")) {
            this.eventDates = data;
        } else {
            this.onLoadError();  // callback
        }
    }

    // POPUP
    popup_OnInitialized = (e) => {
        this.popupInstance = e.component;

        // set callback
        this.onInitialized(e.component);
    }

    popup_OnShowing = async (e) => {
        this.selectedEventDates = [];
        this.eventDates.forEach((d) => {
            this.selectedEventDates.push(d.Id)
        });
        this.refTags.setTags(this.convertEventDates(this.eventDates));
        this.getEmails();

    }

    // HTML EDITOR
    htmlEditorNotes_OnInitialized = (e) => {
        this.htmlEditorNotesInstance = e.component;
    }

    /// DATE TAGS
    convertEventDates(data) {
        let datesArray = [];

        if (data.length > 0) {
            const weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
            data.forEach((item, index, array) => {
                let dt = new Date(item.GigDate);
                const d = this.selectedEventDates.find(x => x == item.Id)
                let selected = (d !== undefined);
                datesArray.push({ id: item.Id, text: weekday[dt.getDay()] + ' ' + dt.getDate() + '/' + (dt.getMonth() + 1) + '/' + dt.getFullYear(), selected: selected })
            });
        }

        return datesArray;
    }

    tagEventDates_OnClick = async (e, selected) => {
        const id = parseInt(e.currentTarget.id, 10);
        const item = this.selectedEventDates.find(x => x == id)

        if (item !== undefined) {
            // remove
            for (var i = 0; i < this.selectedEventDates.length; i++) {
                if (this.selectedEventDates[i] == id) {
                    this.selectedEventDates.splice(i, 1);
                    break;
                }
            } 
        } else {
            // add
            this.selectedEventDates.push(id);
        }

        this.refTags.setTags(this.convertEventDates(this.eventDates));

        this.getEmails();

    }

    getEmails = () => {
        this.emailData = [];
        this.dataSourceTrips.forEach((shuttle) => {
            let dtShuttle = new Date(shuttle.SubTrips[0].DateTimeDeparture);
            this.selectedEventDates.forEach((id) => {
                let event = this.eventDates.find(x => x.Id == id);
                if (event !== undefined) {
                    let dtEvent = new Date(event.GigDate);
                    if (dtEvent.getFullYear() == dtShuttle.getFullYear() && dtEvent.getMonth() == dtShuttle.getMonth() && dtEvent.getDate() == dtShuttle.getDate()) {
                        this.emailData.push(this.convertTripData(shuttle));
                    }
                }
            });
        });
        this.htmlEditorNotesInstance.option('value', this.renderEmailTemplate());
    }

    convertTripData = (shuttle) => {
        let sheetData = {
            Trip: '',
            PickupDate: '',
            PickupLocationName: '',
            PickupLocationAddress: '',
            PickupWaitTime: '',
            DropOffLocationName: '',
            DropOffLocationAddress: '',
            DropOffWaitTime: '',
            DropOffLocationName2: '',
            DropOffLocationAddress2: '',
            DropOffWaitTime2: '',
            Artist: '',
            Driver: '',
            Car: '',
            Pax: '',
            TravelTime: '',
            Notes: '',
        }

        let trip = '';
        let pickupLocationAddress = '';
        for (var i = 0; i < shuttle.SubTrips.length; i++) {
            if (shuttle.SubTrips[i].IsPickupDeparture === true) {
                trip = shuttle.SubTrips[i].LocationDeparture;
                const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
                const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
                let dt = new Date(shuttle.SubTrips[i].DateTimeDeparture);
                //sheetData.PickupDate = weekday[dt.getDay()] + ' ' + dt.getDate().toString().padStart(2, '0') + '-' + month[dt.getMonth()] + '-' + dt.getFullYear() + ' - ' + dt.getHours().toString().padStart(2, '0') + ':' + dt.getMinutes().toString().padStart(2, '0');
                sheetData.PickupDate = weekday[dt.getDay()] + ' ' + dt.getDate().toString().padStart(2, '0') + '/' + (dt.getMonth()+1).toString().padStart(2, '0') + ' - <b>' + dt.getHours().toString().padStart(2, '0') + ':' + dt.getMinutes().toString().padStart(2, '0') + '</b>';
                sheetData.PickupLocationName = shuttle.SubTrips[i].LocationDeparture;
                pickupLocationAddress = (shuttle.SubTrips[i].LocationDepartureStreet ? shuttle.SubTrips[i].LocationDepartureStreet : '');
                pickupLocationAddress = pickupLocationAddress + (pickupLocationAddress !== '' ? ' ' : '') + (shuttle.SubTrips[i].LocationDepartureStreetNumber ? shuttle.SubTrips[i].LocationDepartureStreetNumber : '');
                pickupLocationAddress = pickupLocationAddress + (pickupLocationAddress !== '' ? ', ' : '') + (shuttle.SubTrips[i].LocationDepartureZipCode ? shuttle.SubTrips[i].LocationDepartureZipCode : '');
                pickupLocationAddress = pickupLocationAddress + (pickupLocationAddress !== '' ? ' ' : '') + (shuttle.SubTrips[i].LocationDepartureCity ? shuttle.SubTrips[i].LocationDepartureCity : '');
                sheetData.PickupLocationAddress = pickupLocationAddress;
                break;
            }
        }

        let countDropoff = 0;
        let dropoffLocationAddress = '';
        let dropoffLocationAddress2 = '';
        let tripTimings = '';
        for (var i = 0; i < shuttle.SubTrips.length; i++) {
            if (shuttle.SubTrips[i].IsDropOffArrival === true) {
                this.dropoffDisplay = 'none';
                trip += ' - ' + shuttle.SubTrips[i].LocationArrival;
                countDropoff += 1;
                switch (countDropoff) {
                    case 1:
                        sheetData.DropOffLocationName = shuttle.SubTrips[i].LocationArrival;
                        dropoffLocationAddress = (shuttle.SubTrips[i].LocationArrivalStreet ? shuttle.SubTrips[i].LocationArrivalStreet : '');
                        dropoffLocationAddress = dropoffLocationAddress + (dropoffLocationAddress !== '' ? ' ' : '') + (shuttle.SubTrips[i].LocationArrivalStreetNumber ? shuttle.SubTrips[i].LocationArrivalStreetNumber : '');
                        dropoffLocationAddress = dropoffLocationAddress + (dropoffLocationAddress !== '' ? ', ' : '') + (shuttle.SubTrips[i].LocationArrivalZipCode ? shuttle.SubTrips[i].LocationArrivalZipCode : '');
                        dropoffLocationAddress = dropoffLocationAddress + (dropoffLocationAddress !== '' ? ' ' : '') + (shuttle.SubTrips[i].LocationArrivalCity ? shuttle.SubTrips[i].LocationArrivalCity : '');
                        sheetData.DropOffLocationAddress = dropoffLocationAddress;
                        tripTimings = 'trip 1: ' + (shuttle.SubTrips[i].MinutesMin !== shuttle.SubTrips[i].MinutesMax ? shuttle.SubTrips[i].MinutesMin + ' - ' + shuttle.SubTrips[i].MinutesMax + ' min' : shuttle.SubTrips[i].MinutesMax + ' min') + " (" + shuttle.SubTrips[i].Distance + "km)";
                        break;
                    case 2:
                        sheetData.DropOffLocationName2 = shuttle.SubTrips[i].LocationArrival;
                        dropoffLocationAddress2 = (shuttle.SubTrips[i].LocationArrivalStreet ? shuttle.SubTrips[i].LocationArrivalStreet : '');
                        dropoffLocationAddress2 = dropoffLocationAddress2 + (dropoffLocationAddress2 !== '' ? ' ' : '') + (shuttle.SubTrips[i].LocationArrivalStreetNumber ? shuttle.SubTrips[i].LocationArrivalStreetNumber : '');
                        dropoffLocationAddress2 = dropoffLocationAddress2 + (dropoffLocationAddress2 !== '' ? ', ' : '') + (shuttle.SubTrips[i].LocationArrivalZipCode ? shuttle.SubTrips[i].LocationArrivalZipCode : '');
                        dropoffLocationAddress2 = dropoffLocationAddress2 + (dropoffLocationAddress2 !== '' ? ' ' : '') + (shuttle.SubTrips[i].LocationArrivalCity ? shuttle.SubTrips[i].LocationArrivalCity : '');
                        sheetData.DropOffLocationAddress2 = dropoffLocationAddress2;
                        this.dropoffDisplay = 'flex';
                        tripTimings += ' / trip 2: ' + (shuttle.SubTrips[i].MinutesMin !== shuttle.SubTrips[i].MinutesMax ? shuttle.SubTrips[i].MinutesMin + ' - ' + shuttle.SubTrips[i].MinutesMax + ' min' : shuttle.SubTrips[i].MinutesMax + ' min') + " (" + shuttle.SubTrips[i].Distance + "km)";
                        break;
                    default:
                        break;
                }
            }
        }

        if (tripTimings.search('trip 2:') === -1) {
            tripTimings = tripTimings.replace('trip 1: ', '');
        }

        let searchStart = false;
        let waitCount = 0;
        this.dropoffWaitTimeDisplay = 'none';
        this.dropoffWaitTimeDisplay2 = 'none';
        this.pickupWaitTimeDisplay = 'none'
        for (var i = 0; i < shuttle.SubTrips.length; i++) {
            if (shuttle.SubTrips[i].IsPickupDeparture === true) {
                searchStart = true;
            }
            if (searchStart === true) {
                waitCount += 1;
                switch (waitCount) {
                    case 1:
                        sheetData.PickupWaitTime = shuttle.SubTrips[i].WaitTimePickup
                        if (shuttle.SubTrips[i].WaitTimePickup > 0) {
                            this.pickupWaitTimeDisplay = 'flex';
                        }
                        break;
                    case 2:
                        sheetData.DropOffWaitTime = shuttle.SubTrips[i].WaitTimePickup
                        if (shuttle.SubTrips[i].WaitTimePickup > 0) {
                            this.dropoffWaitTimeDisplay = 'flex';
                        }
                        break;
                    case 3:
                        sheetData.DropOffWaitTime2 = shuttle.SubTrips[i].WaitTimePickup
                        if (shuttle.SubTrips[i].WaitTimePickup > 0) {
                            this.dropoffWaitTimeDisplay2 = 'flex';
                        }
                        break;
                    default:
                        break;
                }
            }

        }
        sheetData.Trip = trip;
        sheetData.Artist = shuttle.ArtistName;
        sheetData.Driver = shuttle.DriverName;
        sheetData.Car = shuttle.CarName;
        sheetData.Pax = shuttle.Pax;
        sheetData.TravelTime = tripTimings;
        sheetData.Notes = shuttle.Notes;

        return sheetData;

    }

    renderEmailTemplate = () => {
        let htmlOut = "Graspop shuttle confirmations " + (this.emailData.length > 0 ? this.emailData[0].Artist : '') + "<br />";
        htmlOut += "Please find below the shuttle confirmations.<br /><br />Please check all info.<br /><br />";

        this.emailData.forEach((shuttle) => {
            let html = "<b>Shuttle:</b>&nbsp;<b><i>" + shuttle.Trip + "</i></b><br />";
            html += "<b>Pickup:</b>&nbsp;" + shuttle.PickupDate + " - " + shuttle.PickupLocationName + "<br />";
            html += "<b>Pax:</b>&nbsp;" + shuttle.Pax + "<br />";
            html += "<b>Driver:</b>&nbsp;" + shuttle.Driver + "<br />";
            html += "<b>Vehicle:</b>&nbsp; Ford Custom<br />";
            html += "<b>Travel time:</b>&nbsp;" + shuttle.TravelTime + "<br /><br /><br />";
            htmlOut += html;
        });
    
        return htmlOut;
    }


    render() {
        return (
            <Popup
                dragEnabled={false}
                hideOnOutsideClick={false}
                showTitle={true}
                height={600}
                width={800}
                showCloseButton={true}
                onInitialized={this.popup_OnInitialized}
                onShowing={this.popup_OnShowing}
                title='Email'
            >
                <HxTags
                    ref={ref => this.refTags = ref}
                    tags={this.convertEventDates(this.eventDates)}
                    onClick={this.tagEventDates_OnClick}
                />

                <HtmlEditor
                    width='100%'
                    height='500px'
                    valueType="html"
                    onInitialized={this.htmlEditorNotes_OnInitialized}
                >
                </HtmlEditor>
                
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    visible={this.state.loadPanelVisible}
                    showIndicator={true}
                    shading={false}
                    showPane={false}
                    hideOnOutsideClick={false}
                />
            </Popup>
        );
    }
}

export default PopupShuttlesEmail
