import React from 'react';
import DataGridProducts from './sub-components/DataGridProducts'
import { HubConnectionBuilder } from '@microsoft/signalr';

import ServiceProducts from '../../../../api/services/ServiceProducts'

import HxErrorMessage from '../../../custom-components/hx-error-message/HxErrorMessage'

import { Button } from 'devextreme-react/button';

import PopupExportProduct from './sub-components/PopupExportProduct'
import PopupAddEditProducts from './sub-components/PopupAddEditProducts';

import HxToast from '../../../custom-components/hx-toast/HxToast'
import HxDialog from '../../../custom-components/hx-dialog/HxDialog'

import './RidersAdminProducts.scss'

class RidersAdminProducts extends React.Component {
    constructor(props) {
        super(props);

        this.popupAddProductInstance = null;

        this.popupExportProductInstance = null;

        this.dataGridProductsInstance = null;

        this.popupUserSettingsProductInstance = null;

        this.hubConnection = null;

        this.userSettings = {
            UserId: parseInt(sessionStorage.getItem("currentUserId"), 10),
            DefaultViewId: -1,
            DefaultFilterId: -1,
            ExportColors: true,
            UseBlanksForZeroes: true,
            UseColorsProducts: true,
            ColorOriginProducts: 'SHOP',
            ShowSelectedCell: true,
            UseAdvancedFiltering: false,
        }
    }

    // EVENTS
    componentDidMount = async () => {
        this.hubConnection = new HubConnectionBuilder().withUrl("/productsHub").build();

        await this.hubConnection.start()
            .then(function () {

            })
            .catch(function (err) {
                return console.error(err.toString());
            });
    }

    dataGridProducts_OnInitialized = (instance) => {
        this.dataGridProductsInstance = instance;
    }

    dataGridProducts_OnExportProduct = () => {
        this.exportProduct();
    }

    dataGridProducts_OnConnectionError = () => {
        this.setErrorHeader(true);
    }

    dataGridProducts_OnEditProduct = (id) => {
        this.refPopupAddEditProduct.setValues(id, 'update');
        this.popupAddProductInstance.show();
    }

    dataGridProducts_OnDeleteProduct = async (id, name) => {

        var dataCanBeDeleted = await ServiceProducts.checkIfProductCanBeDeleted(id, parseInt(sessionStorage.getItem("currentUserId"), 10));
        if (dataCanBeDeleted == true) {
            var result = await HxDialog.showDialogYesNo('Delete product', 'Delete product ' + name + '?')
            if (result === true) {
                var data = await ServiceProducts.deleteProduct(id);
                if (data !== null) {
                    if (!data.hasOwnProperty("error")) {
                        if (data) {
                            this.hubConnection
                                .invoke("SendUpdateInfoDeletedProduct", id)
                                .catch(function (err) {
                                    return console.error(err.toString());
                                });
                            HxToast.showToast('Product deleted.', 'success', 'bottom right', 'up-stack');
                       } else {
                            HxToast.showToast('Delete product failed!', 'error', 'bottom right', 'up-stack');
                        }
                    } else {
                        this.setErrorHeader(true);
                    }
                } else {
                    HxToast.showToast('Delete product failed!', 'error', 'bottom right', 'up-stack');
                }
            }
        } else {
            HxToast.showToast('Product can not be deleted.', 'error', 'bottom right', 'up-stack');
        }
    }

    // POPUP EXPORT
    popupExportProduct_OnInitialized = (instance) => {
        this.popupExportProductInstance = instance;
    }

    exportProduct = () => {
        this.refPopupExportProduct.setUserSettings(this.userSettings);
        this.popupExportProductInstance.show();
    }

    // POPUP ADD
    popupAddEditProduct_OnInitialized = (instance) => {
        this.popupAddProductInstance = instance;
    }

    popupAddEditProduct_OnUpdate = async (productsInfoModel) => {
        var data = await ServiceProducts.updateProduct(productsInfoModel);
        if (data !== null) {
            if (!data.hasOwnProperty("error")) {
                if (data) {
                    this.hubConnection
                        .invoke("SendUpdateInfoEditedProduct", productsInfoModel.Id)
                        .catch(function (err) {
                            return console.error(err.toString());
                        });
                    HxToast.showToast('Product updated.', 'success', 'bottom right', 'up-stack');
                } else {
                    HxToast.showToast('Name already exists.', 'error', 'bottom right', 'up-stack');
                }
            } else {
                this.setErrorHeader(true);
            }
        } else {
            HxToast.showToast('Update product failed!', 'error', 'bottom right', 'up-stack');
        }
        this.dataGridProductsInstance.clearSelection()
    }

    popupAddEditProduct_OnSave = async (productsInfoModel) => {
        var newId = await ServiceProducts.saveProduct(productsInfoModel);
        if (newId !== null) {
            if (!newId.hasOwnProperty("error")) {
                if (newId) {
                    this.hubConnection
                        .invoke("SendUpdateInfoAddedProduct", newId)
                        .catch(function (err) {
                            return console.error(err.toString());
                        });
                    HxToast.showToast('Product saved.', 'success', 'bottom right', 'up-stack');
                } else {
                    HxToast.showToast('Name already exists.', 'error', 'bottom right', 'up-stack');
                }
            } else {
                this.setErrorHeader(true);
            }
        } else {
            HxToast.showToast('Save product failed!', 'error', 'bottom right', 'up-stack');
        }
        this.dataGridProductsInstance.clearSelection()
    }

    // BUTTON NEW
    buttonNew_OnClick = () => {
        this.refPopupAddEditProduct.setValues(-1, 'new');
        this.popupAddProductInstance.show();
    }

    // ERROR MESSAGE
    setErrorHeader = (show) => {
        if (show) {
            this.refHxErrorMessage.show();
        } else {
            this.refHxErrorMessage.hide();
        }
    }

    render() {
        return (
            <div>
                <HxErrorMessage
                    ref={ref => this.refHxErrorMessage = ref}
                    id='error-message-products'
                    message='Could not connect to database!'
                    visible={false}
                />

                <div id='toolBarProducts' className='hx-toolbar-main-div'>
                    <div className='hx-toolbar-left'>
                        <span>
                            <Button
                                icon='xlsxfile'
                                stylingMode='outlined'
                                text='Products'
                                onClick={this.exportProduct}

                            />
                        </span>
                    </div>
                    <div className='hx-toolbar-right'>

                    </div>
                </div>

                <div className='rap-content-block'>
                    <div>
                        <Button
                            text="Add product"
                            icon="add"
                            onClick={this.buttonNew_OnClick} />
                    </div>
                    <div className='dx-card rap-grid-card'>
                        <DataGridProducts
                            ref={ref => this.refDataGridProducts = ref}
                            onEditProduct={this.dataGridProducts_OnEditProduct}
                            onDeleteProduct={this.dataGridProducts_OnDeleteProduct}
                            onInitialized={this.dataGridProducts_OnInitialized}
                            onExportProduct={this.dataGridProducts_OnExportProduct}
                            onConnectionError={this.dataGridProducts_OnConnectionError}
                        />
                    </div>
                </div>

                <PopupExportProduct
                    ref={ref => (this.refPopupExportProduct = ref)}
                    onInitialized={this.popupExportProduct_OnInitialized}
                    gridInstance={this.dataGridProductsInstance}
                />

                <PopupAddEditProducts
                    ref={ref => this.refPopupAddEditProduct = ref}
                    onInitialized={this.popupAddEditProduct_OnInitialized}
                    onUpdate={this.popupAddEditProduct_OnUpdate}
                    onSave={this.popupAddEditProduct_OnSave}
                />
            </div>
        );
    }
}

export default RidersAdminProducts

