import React from 'react';
import { ShuttlesOverView } from '../../../components';

class shuttlesOverView extends React.Component {
    render() {
        return (
            <ShuttlesOverView />
        );
    }
}

export default shuttlesOverView