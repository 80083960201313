import React from 'react';
import { Portal } from '../../components';

class home extends React.Component {
    render() {
        return (
            <Portal />
        );
    }
}

export default home