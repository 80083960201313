import React from 'react';
import HXUserFilters from '../../../custom-components/hx-user-filters/HXUserFilters'
import { HubConnectionBuilder } from '@microsoft/signalr';

import ServiceLocations from '../../../../api/services/ServiceLocations'
import ServiceUsers from '../../../../api/services/ServiceUsers'

import HxErrorMessage from '../../../custom-components/hx-error-message/HxErrorMessage'

import { Button } from 'devextreme-react/button';

import DataGridShops from './sub-components/DataGridShops'
import PopupRidersAdminShopsUserSettings from './sub-components/PopupRidersAdminShopsUserSettings'
import PopupAddEditShops from './sub-components/PopupAddEditShops';
import HxPopupExportExcel from '../../../custom-components/hx-popup-excel-export/HxPopupExportExcel';

import HxToast from '../../../custom-components/hx-toast/HxToast'
import HxDialog from '../../../custom-components/hx-dialog/HxDialog'

import './RidersAdminShops.scss'

class RidersAdminShops extends React.Component {
    constructor(props) {
        super(props);

        // filters
        this.loadedUserFilterValue = [];

        this.refPopupAddEditShop = React.createRef();
        this.popupAddShopInstance = null;

        this.popupExportShopsInstance = null;

        this.dataGridShopsInstance = null;

        this.popupRidersAdminShopsUserSettingsInstance = null;
 
        this.hubConnection = null;

        this.userSettings = {
            UserId: parseInt(sessionStorage.getItem("currentUserId"), 10),
            DefaultViewId: -1,
            DefaultFilterId: -1,
            ExportColors: false,
            UseColorsShops: false,
            ShowSelectedCell: false,
            UseAdvancedFiltering: false,
        }
    }

    // EVENTS
    componentDidMount = async () => {
        await this.loadDSUserSettings();

        this.hubConnection = new HubConnectionBuilder().withUrl("/shopsHub").build();

        await this.hubConnection.start()
            .then(function () {
            })
            .catch(function (err) {
                return console.error(err.toString());
            });

        this.refDataGridShops.setUsersettings(this.userSettings);

    }

    // DATA
    loadDSUserSettings = async () => {
        var settings = await ServiceUsers.getUserSettingsShopsByUserId(parseInt(sessionStorage.getItem("currentUserId"), 10));
        settings = (settings !== null ? settings : this.userSettings);
        if (!settings.hasOwnProperty("error")) {
            this.userSettings = settings;
        } else {
             this.setErrorHeader(true);
        }
    }

    // GRID
    dataGridShops_OnInitialized = (instance) => {
        this.dataGridShopsInstance = instance;
        this.refUserFilters.setDataGridInstance(instance);
    }

    dataGridShops_OnExportShop = () => {
        this.exportShop();
    }

    dataGridShops_OnConnectionError = () => {
        this.setErrorHeader(true);
    }

    dataGridShops_OnEditShop = (id) => {
        this.refPopupAddEditShop.setValues(id, 'update');
        this.popupAddShopInstance.show();
    }

    dataGridShops_OnDeleteShop = async (id, name) => {
        var dataCanBeDeleted = await ServiceLocations.checkIfShopCanBeDeleted(id);
        if (dataCanBeDeleted == true) {
            var result = await HxDialog.showDialogYesNo('Delete shop', 'Delete shop ' + name + '?')
            if (result === true) {
                var data = await ServiceLocations.deleteShop(id);
                if (data !== null) {
                    if (!data.hasOwnProperty("error")) {
                        if (data) {
                            this.hubConnection
                                .invoke("SendUpdateInfoDeletedShop", id)
                                .catch(function (err) {
                                    return console.error(err.toString());
                                });
                            HxToast.showToast('Shop deleted.', 'success', 'bottom right', 'up-stack');
                        } else {
                            HxToast.showToast('Delete shop failed!', 'error', 'bottom right', 'up-stack');
                        }
                    } else {
                        this.setErrorHeader(true);
                    }
                } else {
                    HxToast.showToast('Delete shop failed!', 'error', 'bottom right', 'up-stack');
                }
            }
        } else {
            HxToast.showToast('Shop can not be deleted!', 'error', 'bottom right', 'up-stack');
        }
    }

    // POPUP EXPORT
    popupExportShops_OnInitialized = (instance) => {
        this.popupExportShopsInstance = instance;
    }

    popupExportShops_OnSave = async (fileName) => {
        if (fileName) {
            await this.refDataGridShops.exportToExcel(fileName);
        }
    }

    exportShop = () => {
        this.popupExportShopsInstance.show();
    }

    // POPUP USERSETTINGS
    popupRidersAdminShopsUserSettings_OnInitialized = (instance) => {
        this.popupRidersAdminShopsUserSettingsInstance = instance;
    }

    popupRidersAdminShopsUserSettings_OnUpdate = async (elementId, value) => {
        this.refDataGridShops.applyUserSettings(elementId, value)
    }

    popupRidersAdminShopsUserSettings_OnLoadError = () => {
        this.setErrorHeader(true);
    }

    popupRidersAdminShopsUserSettings_OnHidden = async (e) => {
        await this.loadDSUserSettings();
    }
    // TOOLBAR
    buttonUserSettings_OnClick = () => {
        this.popupRidersAdminShopsUserSettingsInstance.show();
    }

    // POPUP EDIT
    popupAddEditShop_OnInitialized = (instance) => {
        this.popupAddShopInstance = instance;
    }

    popupAddEditShop_OnUpdate = async (shopsInfoModel) => {
        var data = await ServiceLocations.updateShop(shopsInfoModel);
        if (data !== null) {
            if (!data.hasOwnProperty("error")) {
                if (data) {
                    this.hubConnection
                        .invoke("SendUpdateInfoEditShop", shopsInfoModel.Id)
                        .catch(function (err) {
                            return console.error(err.toString());
                        });

                    HxToast.showToast('Shop updated.', 'success', 'bottom right', 'up-stack');
                } else {
                    HxToast.showToast('Update shop failed!', 'error', 'bottom right', 'up-stack');
                }
            } else {
                this.setErrorHeader(true);
            }
        } else {
            HxToast.showToast('Update shop failed!', 'error', 'bottom right', 'up-stack');
        }
        this.dataGridShopsInstance.clearSelection();
    }

    popupAddEditShop_OnSave = async (shopsInfoModel) => {
        var newId = await ServiceLocations.saveShop(shopsInfoModel);
        if (newId !== null) {
            if (!newId.hasOwnProperty("error")) {
                if (newId > -1) {
                    this.hubConnection
                        .invoke("SendUpdateInfoAddedShop", newId)
                        .catch(function (err) {
                            return console.error(err.toString());
                        });

                    HxToast.showToast('Shop saved.', 'success', 'bottom right', 'up-stack');
                } else {
                    HxToast.showToast('Save shop failed!', 'error', 'bottom right', 'up-stack');
                }
            } else {
                this.setErrorHeader(true);
            }
        } else {
            HxToast.showToast('Save shop failed!', 'error', 'bottom right', 'up-stack');
        }
        this.dataGridShopsInstance.clearSelection();
        
    }

    // BUTTON ADD SHOP
    buttonAddShop_OnClick = () => {
        this.refPopupAddEditShop.setValues(-1, 'new');
        this.popupAddShopInstance.show();
    }

    // ERROR MESSAGE
    setErrorHeader = (show) => {
        if (show) {
            this.refHxErrorMessage.show();
        } else {
            this.refHxErrorMessage.hide();
        }
    }


    // USERFILTER
    userFilterLoaded = async (filterState) => {
        // display loadpanel
        //this.dataGridShopsInstance.beginCustomLoading("Loading...");

        this.dataGridShopsInstance.beginUpdate();

        if (filterState.filterValue !== undefined) {
            this.dataGridShopsInstance.option('filterValue', JSON.parse(filterState.filterValue));
            this.loadedUserFilterValue = this.dataGridShopsInstance.option('filterValue');
        } else {
            this.loadedUserFilterValue = [];
        }

        this.dataGridShopsInstance.endUpdate();

        // hide loadpanel
        //this.dataGridShopsInstance.endCustomLoading();
    }

    clearUserFilter = () => {
        this.dataGridShopsInstance.clearFilter();
    }

    userFilterError = (error) => {
        //this.setErrorHeader(error);
    }

    render() {
        return (
            <div>
                <HxErrorMessage
                    ref={ref => (this.refHxErrorMessage = ref)}
                    id='error-message-shops'
                    message='Could not connect to database!'
                    visible={false}
                />

                <div id='toolBarShops' className='hx-toolbar-main-div'>
                    <div className='hx-toolbar-left'>
                        <span>
                            <Button
                                icon='xlsxfile'
                                stylingMode='outlined'
                                text='Shops'
                                onClick={this.exportShop}
                            />
                        </span>
                    </div>
                    <div className='hx-toolbar-right'>
                        <span>
                            <HXUserFilters
                                ref={ref => (this.refUserFilters = ref)}
                                onUserFilterLoaded={this.userFilterLoaded}
                                onClearFilter={this.clearUserFilter}
                                onError={this.userFilterError}
                                filterTypeName='shops'
                            />
                        </span>
                        <span>
                            <Button
                                icon='preferences'
                                stylingMode='text'
                                text=''
                                onClick={this.buttonUserSettings_OnClick}
                            />
                        </span>
                    </div>
                </div>

                <div className='ras-content-block'>
                    <div>
                        <Button
                            text="Add shop"
                            icon="add"
                            onClick={this.buttonAddShop_OnClick} />
                    </div>
                    <div className='dx-card ras-grid-card'>
                        <DataGridShops
                            ref={ref => (this.refDataGridShops = ref)}
                            onEditShop={this.dataGridShops_OnEditShop}
                            onDeleteShop={this.dataGridShops_OnDeleteShop}
                            onInitialized={this.dataGridShops_OnInitialized}
                            onExportShop={this.dataGridShops_OnExportShop}
                            onConnectionError={this.dataGridShops_OnConnectionError}
                        />
                    </div>
                </div>

                <HxPopupExportExcel
                    defaultName='Shops'
                    onInitialized={this.popupExportShops_OnInitialized}
                    onSave={this.popupExportShops_OnSave}
                />

                <PopupRidersAdminShopsUserSettings
                    onInitialized={this.popupRidersAdminShopsUserSettings_OnInitialized}
                    onUpdate={this.popupRidersAdminShopsUserSettings_OnUpdate}
                    onLoadError={this.popupRidersAdminShopsUserSettings_OnLoadError}
                    onHidden={this.popupRidersAdminShopsUserSettings_OnHidden}
                />

                <PopupAddEditShops
                    ref={ref => (this.refPopupAddEditShop = ref)}
                    onInitialized={this.popupAddEditShop_OnInitialized}
                    onUpdate={this.popupAddEditShop_OnUpdate}
                    onSave={this.popupAddEditShop_OnSave}
                />
             
            </div>
        );
    }

}

export default RidersAdminShops

