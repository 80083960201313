import React from 'react';
import { Popup } from 'devextreme-react/popup';
import TextBox from 'devextreme-react/text-box';
import Validator, { AsyncRule, ValidationRule, EmailRule } from 'devextreme-react/validator';
import { Button } from 'devextreme-react/button';
import { ValidationGroup, dxValidationGroupResult } from 'devextreme-react/validation-group';
import SelectBox from 'devextreme-react/select-box';
import { CheckBox } from 'devextreme-react/check-box';
import ColorBox from 'devextreme-react/color-box';
import RadioGroup from 'devextreme-react/radio-group';
import { Lookup as DXLookup } from 'devextreme-react/lookup';

import DataGrid, {
    Column,
    FormItem,
    Editing,
    Paging,
    Lookup,
    Scrolling
} from 'devextreme-react/data-grid';
import HtmlEditor, {
    Toolbar, MediaResizing, ImageUpload, Item,
} from 'devextreme-react/html-editor';
import HxButton from '../../../../custom-components/hx-button/HxButton'

import ServiceRelations from '../../../../../api/services/ServiceRelations'
import ServiceArtists from '../../../../../api/services/ServiceArtists'

import './PopupShuttlesAdminRelationsEditArtistContact.scss'
import globalConfig from '../../../../../config/global.js';


// props
//      id (required): relation id
//
//      onInitialized: callback
//      onUpdate: callback
//      onLoadError: callback
//      onHiding: callback

class PopupShuttlesAdminRelationsEditArtistContact extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            artistRelationsDataSource: [],
        };
        this.artistFunctionsDataSource = [];
        this.artistsDataSource = [];
        this.relationTypesDataSource = [];

        this.relationId = -1;

        this.popupInstance = null;
        this.validationGroupInstance = null;

        this.textBoxFirstNameInstance = null;
        this.textBoxLastNameInstance = null;
        this.textBoxEmail1Instance = null;
        this.textBoxTel1Instance = null;
        this.htmlEditorNotesInstance = null;

        this.selectBoxArtistInstance = null;
        this.selectBoxArtistFunctionInstance = null;
        this.radioGroupEmailInstance = null;

        this.buttonAddArtistRelationInstance = null;
        this.dataGridInstance = null;

        this.checkBoxKeepOpenInstance = null;
        this.buttonUpdateInstance = null;

        this.relationRecord = {};
        this.relationDefaultRecord = {
            Id: -1,
            EventId: parseInt(sessionStorage.getItem('currentEventId'), 10),
            ColorId: null,
            PatternId: null,
            RelationTypeId: -1,
            FirstName: '',
            LastName: '',
            Street: null,
            StreetNo: null,
            ZipCode: null,
            City: null,
            CountryCode: null,
            Country: '',
            Tel1: null,
            Tel1Mobile: null,
            Tel2: null,
            Tel2Mobile: null,
            Email1: null,
            Email2: '',
            BirthDate: null,
            Gender: null,
            NationalRefNumber: null,
            RelationImage: null,
            Notes: null,
            RelationType: '',
            ColorHex: '#FFFFFF',
            ColorRgba: 'rgba(255,255,255,1)',
            ForeColorHex: '#000000',
            ForeColorRgba: 'rgba(0,0,0,1)',
            PatternColorHex: '#000000',
            PatternColorRgba: 'rgba(0,0,0,1)',
            UsePattern: false,
            PatternCssBackGround: null,
            PatternCssBackGroundImage: null,
            PatternCssBackGroundSize: null,
            PatternCssBackGroundPosition: null,
        }

    }

    // CALLBACKS
    onInitialized = (instance) => {
        if (this.props.onInitialized) {
            this.props.onInitialized(instance);  // callback
        }
    }

    onLoadError = () => {
        if (this.props.onLoadError) {
            this.props.onLoadError();  // callback
        }
    }

    onUpdate = (success) => {
        if (this.props.onUpdate) {
            this.props.onUpdate(success);  // callback
        }
    }

    onHiding = () => {
        if (this.props.onHiding) {
            this.props.onHiding();  // callback
        }
    }
    // CALLABLES

    // EVENTS
    componentDidMount = async () => {
    }

    // DATA
    loadDataSourceRelation = async (relationId) => {
        var data = await ServiceRelations.getRelationByEventIdRelationId(parseInt(sessionStorage.getItem('currentEventId'), 10), relationId);
        if (data !== null && !data.hasOwnProperty("error")) {
            this.relationRecord = data;
        } else {
            this.onLoadError();  // callback
        }
    }

    loadDataSourceArtistRelations = async (relationId) => {
        var data = await ServiceRelations.getArtistRelationsByRelationId(relationId);
        if (data !== null && !data.hasOwnProperty("error")) {
            data.sort((a, b) => a.ArtistName.localeCompare(b.ArtistName));
            this.setState({ artistRelationsDataSource: data });
        } else {
            this.onLoadError();  // callback
        }
    }

    loadDataSourceArtistFunctions = async () => {
        var data = await ServiceArtists.getAllArtistFunctions();
        if (data !== null && !data.hasOwnProperty("error")) {
            this.artistFunctionsDataSource = data;
        } else {
            this.onLoadError();  // callback
        }
    }

    loadDataSourceArtists = async () => {
        var data = await ServiceArtists.getAllArtistsDropDownByEventId(parseInt(sessionStorage.getItem('currentEventId'), 10), 'shuttles');
        if (data !== null && !data.hasOwnProperty("error")) {
            this.artistsDataSource = data;
        } else {
            this.onLoadError();  // callback
        }
    }

    loadDataSourceRelationTypes = async () => {
        var data = await ServiceRelations.getRelationTypes();
        if (data !== null && !data.hasOwnProperty("error")) {
            this.relationTypesDataSource = data;
        } else {
            this.onLoadError();  // callback
        }
    }


    // POPUP
    popup_OnInitialized = (e) => {
        this.popupInstance = e.component;

        // set callback
        this.onInitialized(e.component);
    }

    popup_OnShowing = (e) => {
        if (this.props.id !== undefined) {
            this.relationId = this.props.id;
        }
    }

    popup_OnHiding = (e) => {
        this.textBoxFirstNameInstance.option('value', null);
        this.textBoxLastNameInstance.option('value', null);
        this.textBoxEmail1Instance.option('value', null);
        this.textBoxTel1Instance.option('value', null);
        this.htmlEditorNotesInstance.option('value', null);

        this.validationGroupInstance.reset();

        this.onHiding();  // callback
    }

    popup_OnShown = async (e) => {
        await this.loadDataSourceArtistFunctions();
        await this.loadDataSourceArtists();
        await this.loadDataSourceRelationTypes();

        var type = this.relationTypesDataSource.find(o => o.Name.toString().toUpperCase() === 'ARTISTCONTACT');
        this.relationDefaultRecord.RelationTypeId = (type != null ? type.Id : -1);
        this.relationDefaultRecord.RelationType = (type != null ? type.Name : '');

        if (this.relationId == -1) {
            // new relation
            this.relationRecord = this.relationDefaultRecord;

            this.textBoxFirstNameInstance.option('value', null);
            this.textBoxLastNameInstance.option('value', null);
            this.textBoxEmail1Instance.option('value', null);
            this.textBoxTel1Instance.option('value', null);
            this.htmlEditorNotesInstance.option('value', null);

            this.checkBoxKeepOpenInstance.option('visible', true);

            this.setState({ artistRelationsDataSource: [] });

            this.validationGroupInstance.reset();

            this.popupInstance.option('title', 'Add new artist contact');
        } else {
            // edit relation
            await this.loadDataSourceRelation(this.relationId);
            await this.loadDataSourceArtistRelations(this.relationId);

            this.textBoxFirstNameInstance.option('value', this.relationRecord.FirstName);
            this.textBoxLastNameInstance.option('value', this.relationRecord.LastName);
            this.textBoxEmail1Instance.option('value', this.relationRecord.Email1);
            this.textBoxTel1Instance.option('value', this.relationRecord.Tel1);
            this.htmlEditorNotesInstance.option('value', this.relationRecord.Notes);

            this.checkBoxKeepOpenInstance.option('visible', false);

            this.popupInstance.option('title', 'Update ' + this.relationRecord.FirstName + ' ' + this.relationRecord.LastName);
        }

        this.checkBoxKeepOpenInstance.option('value', false);
        this.buttonUpdateInstance.option('text', 'Save');
        this.buttonUpdateInstance.option('width', '120px');

        this.selectBoxArtistInstance.option('value', null);
        this.selectBoxArtistFunctionInstance.option('value', null);
        this.radioGroupEmailInstance.option('value', 'N/A');
        this.buttonAddArtistRelationInstance.option('disabled', true);

        this.textBoxFirstNameInstance.focus();
    }

    
    // ALL TEXTBOXES FOCUS IN
    textBox_OnFocusIn = (e) => {
        // select all text
        e.element.querySelector('.dx-texteditor-input').select();
    }

    // TEXTBOX FIRST NAME
    textBoxFirstNameAttributes = {
        id: 'textBoxFirstNameInput',
    }

    textBoxFirstName_OnInitialized = (e) => {
        this.textBoxFirstNameInstance = e.component;
    }

    // TEXTBOX LAST NAME
    textBoxLastName_OnInitialized = (e) => {
        this.textBoxLastNameInstance = e.component;
    }

    // TEXTBOX EMAIL 1
    textBoxEmail1_OnInitialized = (e) => {
        this.textBoxEmail1Instance = e.component;
    }

    // TEXTBOX TEL 1
    textBoxTel1_OnInitialized = (e) => {
        this.textBoxTel1Instance = e.component;
    }

    // HTML EDITOR NOTES
    htmlEditorNotes_OnInitialized = (e) => {
        this.htmlEditorNotesInstance = e.component;
    }

    // SELECTBOX ARTIST
    selectBoxArtist_OnInitialized = (e) => {
        this.selectBoxArtistInstance = e.component;
    }
    selectBoxArtist_OnSelectionChanged = (e) => {
        this.checkButtonEnable();
    }

    // SELECTBOX ARTISTFUNCTION
    selectBoxArtistFunction_OnInitialized = (e) => {
        this.selectBoxArtistFunctionInstance = e.component;
    }
    selectBoxArtistFunction_OnSelectionChanged = (e) => {
        this.checkButtonEnable();
    }

    // RADIOGROUP EMAIL
    radioGroupEmail_OnInitialized = (e) => {
        this.radioGroupEmailInstance = e.component;
    }


    // BUTTON ADD RELATION
    buttonAddArtistRelation_OnInitialized = (e) => {
        this.buttonAddArtistRelationInstance = e.component;
    }

    buttonAddArtistRelation_OnClick = async (e) => {
        var rec = {
            ArtistId: this.selectBoxArtistInstance.option('value'),
            RelationId: this.relationId,
            FunctionId: this.selectBoxArtistFunctionInstance.option('value'),
            ArtistName: this.selectBoxArtistInstance.option('text'),
            FunctionName: this.selectBoxArtistFunctionInstance.option('text'),
            EmailTo: false,
            EmailCC: false,
            SortOrder: -1,
        }
        switch (this.radioGroupEmailInstance.option('value')) {
            case "EmailTo":
                rec.EmailTo = true;
                break;
            case "EmailCC":
                rec.EmailCC = true;
                break;
            default:
                break;
        }

        var ds = this.state.artistRelationsDataSource;
        ds.push(rec);
        ds.sort((a, b) => a.ArtistName.localeCompare(b.ArtistName));
        this.setState({ artistRelationsDataSource: ds });
        this.dataGridInstance.refresh();

        this.selectBoxArtistInstance.option('value', null);
        this.selectBoxArtistFunctionInstance.option('value', null);
        this.radioGroupEmailInstance.option('value', 'N/A');
    }
    // DATAGRID
    dataGrid_OnInitialized = (e) => {
        this.dataGridInstance = e.component;
    }

    dataGrid_OnCellClick = (e) => {
        if (e.column.name == 'ActionColumnDelete') {
            var ds = this.state.artistRelationsDataSource.filter(x => x.ArtistId !== e.data.ArtistId);
            ds.sort((a, b) => a.ArtistName.localeCompare(b.ArtistName));
            this.setState({ artistRelationsDataSource: ds });
        } else {
            if (e.column.dataField == 'EmailTo') {
                if (e.data.EmailCC === true) {
                    this.dataGridInstance.cellValue(e.rowIndex, 'EmailCC', false);
                }
            } else if (e.column.dataField == 'EmailCC') {
                if (e.data.EmailTo === true) {
                    this.dataGridInstance.cellValue(e.rowIndex, 'EmailTo', false);
                }
            }
        }

    }

    dataGrid_OnCellPrepared = (e) => {
        //if (e.rowType === 'data') {
        //    if (this.dataGridInstance.cellValue(e.rowIndex, 'Available') == true) {
        //        e.cellElement.style.backgroundColor = '#FFFFFF';
        //    } else {
        //        e.cellElement.style.backgroundColor = '#DDDDDD';
        //    }
        //}
    }

    dataGrid_OnEditorPreparing = (e) => {
        //if (this.dataGridInstance.cellValue(e.row.rowIndex, 'Available') == false) {
        //    if (e.dataField !== 'Available') {
        //        e.editorOptions.disabled = true;
        //    }
        //} else {
        //    if (this.dataGridInstance.cellValue(e.row.rowIndex, 'AllDay') == true) {
        //        if (e.dataField == 'AvailableFrom' || e.dataField == 'AvailableTo' || e.dataField == 'AvailableFrom2' || e.dataField == 'AvailableTo2') {
        //            e.editorOptions.disabled = true;
        //        }
        //    } else {
        //        if (this.dataGridInstance.cellValue(e.row.rowIndex, 'AvailableFrom') === null || this.dataGridInstance.cellValue(e.row.rowIndex, 'AvailableTo') === null) {
        //            if (e.dataField == 'AvailableFrom2' || e.dataField == 'AvailableTo2') {
        //                e.editorOptions.disabled = true;
        //            }
        //        }
        //    }
        //}
    }

    // CHECKBOX KEEP OPEN
    checkBoxKeepOpen_OnInitialized = (e) => {
        this.checkBoxKeepOpenInstance = e.component;
    }

    checkBoxKeepOpen_OnValueChanged = (e) => {
        this.buttonUpdateInstance.option('text', (e.value === true ? 'Save & new' : 'Save'));
        this.buttonUpdateInstance.option('width', (e.value === true ? '140px' : '120px'));
    }

    // BUTTON
    buttonUpdate_OnInitialized = (e) => {
        this.buttonUpdateInstance = e.component;
    }

    buttonUpdate_OnClick = async (e) => {
        await this.updateArtistRelation();
    }

    updateArtistRelation = async () => {
        this.validationGroupInstance.validate();
        var valid = this.validateArtistRelations();

        if (this.textBoxFirstNameInstance.option('isValid') === true
            && this.textBoxLastNameInstance.option('isValid') === true
            && this.textBoxEmail1Instance.option('isValid') === true
            && valid === true) {

            this.relationRecord.FirstName = (this.textBoxFirstNameInstance.option('value') !== null ? this.textBoxFirstNameInstance.option('value').trim() : null); 
            this.relationRecord.LastName = (this.textBoxLastNameInstance.option('value') !== null ? this.textBoxLastNameInstance.option('value').trim() : null);
            this.relationRecord.Email1 = (this.textBoxEmail1Instance.option('value') !== null ? this.textBoxEmail1Instance.option('value').trim() : null);
            this.relationRecord.Tel1 = (this.textBoxTel1Instance.option('value') !== null ? this.textBoxTel1Instance.option('value').trim() : null);
            this.relationRecord.Notes = this.htmlEditorNotesInstance.option('value');

            var rows = this.dataGridInstance.getVisibleRows();

            var dsArtistRelations = [];
            for (var i = 0; i < rows.length; i++) {
                dsArtistRelations.push({
                    ArtistId: rows[i].data.ArtistId,
                    RelationId: rows[i].data.RelationId,
                    FunctionId: rows[i].data.FunctionId,
                    ArtistName: "",
                    FunctionName: "",
                    EmailTo: rows[i].data.EmailTo,
                    EmailCC: rows[i].data.EmailCC,
                    SortOrder: i,
                })
            }

            if (this.relationId == -1) {
                // new contact
                var resultRelation = await ServiceRelations.addRelation(this.relationRecord);
                if (resultRelation !== null && !resultRelation.hasOwnProperty("error")) {
                    dsArtistRelations.forEach(function(item) {
                        item.RelationId = resultRelation;
                    });
                    var resultArtistRelations = await ServiceRelations.addArtistRelations(dsArtistRelations);
                    if (resultArtistRelations !== null && !resultArtistRelations.hasOwnProperty("error") && resultArtistRelations == true) {
                        this.onUpdate(true);
                        if (this.checkBoxKeepOpenInstance.option('value') === false) {
                            this.popupInstance.hide();
                        } else {
                            this.textBoxFirstNameInstance.option('value', '');
                            this.textBoxLastNameInstance.option('value', '');
                            this.textBoxEmail1Instance.option('value', null);
                            this.textBoxTel1Instance.option('value', null);
                            this.htmlEditorNotesInstance.option('value', null);

                            this.setState({ artistRelationsDataSource: [] });

                            this.validationGroupInstance.reset();

                            this.popupInstance.option('title', 'Add new artist contact');
                        }
                    } else {
                        this.onUpdate(false); // callback
                    }
                } else {
                    this.onUpdate(false); // callback
                }
            } else {
                // edit contact
                var resultRelation = await ServiceRelations.updateRelation(this.relationRecord);
                if (resultRelation != null && !resultRelation.hasOwnProperty("error") && resultRelation == true) {
                    var resultArtistRelations = await ServiceRelations.updateArtistRelations({ Id: this.relationRecord.Id, ArtistRelations: dsArtistRelations });
                    if (resultArtistRelations !== null && !resultArtistRelations.hasOwnProperty("error") && resultArtistRelations == true) {
                        this.onUpdate(true); // callback
                        if (this.checkBoxKeepOpenInstance.option('value') === false) {
                            this.popupInstance.hide();
                        } else {
                            this.textBoxFirstNameInstance.option('value', '');
                            this.textBoxLastNameInstance.option('value', '');
                            this.textBoxEmail1Instance.option('value', null);
                            this.textBoxTel1Instance.option('value', null);
                            this.htmlEditorNotesInstance.option('value', null);

                            this.setState({ artistRelationsDataSource: [] });

                            this.validationGroupInstance.reset();

                            this.popupInstance.option('title', 'Add new artist contact');
                        }
                    } else {
                        this.onUpdate(false); // callback
                    }
                } else {
                    this.onUpdate(false); // callback
                }
               
            }
        } else {
            this.textBoxFirstNameInstance.focus();
        }
    }

    // VALIDATION GROUP
    validationGroup_OnInitialized = (e) => {
        this.validationGroupInstance = e.component;
    }

    validateArtistRelations = () => {
        //let newSet = new Set();
        //var hasDuplicates = this.state.artistRelationsDataSource.some(function (currentObject) {
        //    return newSet.size === newSet.add(currentObject.ArtistId).size;
        //});
        let hasDuplicates = false;
        var rows = this.dataGridInstance.getVisibleRows();
        for (var i = 0; i < rows.length; i++) {
            for (var j = i + 1; j < rows.length; j++) {
                var value1 = this.dataGridInstance.cellValue(rows[i].rowIndex, 'ArtistId');
                var value2 = this.dataGridInstance.cellValue(rows[j].rowIndex, 'ArtistId');
                if (value1 === value2) {
                    this.dataGridInstance.getCellElement(rows[i].rowIndex, 'ArtistId').classList.add('psareac-row-error');
                    this.dataGridInstance.getCellElement(rows[j].rowIndex, 'ArtistId').classList.add('psareac-row-error');
                    hasDuplicates = true;
                }

            }
        }

        return (hasDuplicates === false);
    }

    checkButtonEnable = () => {
        var result = true;
        if (this.selectBoxArtistInstance.option('value') != null && this.selectBoxArtistFunctionInstance.option('value') != null) {
            if (this.selectBoxArtistInstance.option('value') != null) {
                var rows = this.dataGridInstance.getVisibleRows();
                for (var i = 0; i < rows.length; i++) {
                    if (rows[i].data.ArtistId === this.selectBoxArtistInstance.option('value')) {
                        result = false;
                        break;
                    }
                }
            }
        } else {
            result = false;
        }

        this.buttonAddArtistRelationInstance.option('disabled', !result);
    }


    // RENDERING
    renderItem = (e) => {
        var style = {};
        const searchRegExp = /\[rgba\]/gi;

        style.width = '40px';
        style.height = '30px';
        style.backgroundColor = '#FFFFFF';
        style.color = '#000000';
        style.marginRight = '10px';
        if (e.CssBackGround != null) {
            style.background = e.CssBackGround.replace(searchRegExp, '#000000');
        }
        if (e.CssBackGroundImage != null) {
            style.backgroundImage = e.CssBackGroundImage.replace(searchRegExp, '#000000');
        }
        if (e.CssBackGroundSize != null) {
            style.backgroundSize = e.CssBackGroundSize;
        }
        if (e.CssBackGroundPosition != null) {
            style.backgroundPosition = e.CssBackGroundPosition;
        }

        return <div style={{ display: 'flex', justifyContent: 'start' }}>
            <div style={style}></div>
            <div style={{ paddingTop: '4px' }}>{e.Name}</div>
        </div>;
    }

    renderDeleteTemplate = (e) => {
        return <div style={{ display: 'flex', justifyContent: 'start' }}>
            <div style={{ width: '40px' }}><i className='dx-icon-trash' style={{ color: '#FF5722' }} /></div>
        </div>;
    }

    render() {
        return (
            <React.Fragment>
                <Popup
                    dragEnabled={false}
                    hideOnOutsideClick={false}
                    showTitle={true}
                    width={1000}
                    height={683}
                    showCloseButton={true}
                    onInitialized={this.popup_OnInitialized}
                    onShown={this.popup_OnShown}
                    onShowing={this.popup_OnShowing}
                    onHiding={this.popup_OnHiding}
                >
                    <ValidationGroup
                        onInitialized={this.validationGroup_OnInitialized}
                    >
                        <div>
                            <div className="psareac-header-div">Info</div>
                            <div className="dx-card psareac-top-card">
                                <div className="psareac-top-div">
                                    <div className="psareac-top-left-div">
                                        <img
                                            src={process.env.PUBLIC_URL + globalConfig.settings.images.artistContactImagesPath.replace(/^\+$|"\/+$/g, '') + "/default.png"}
                                            id='imageBox'
                                            alt={''}
                                            style={{ height: '150px', width: '150px' }}
                                        />
                                    </div>
                                    <div className="psareac-top-middle-div">
                                        <div className="psareac-dx-field">
                                            <div className="dx-field-label psareac-top-label">First Name*</div>
                                            <div className="dx-field-value psareac-top-input">
                                                <TextBox
                                                    inputAttr={this.textBoxFirstNameAttributes}
                                                    width='250px'
                                                    onInitialized={this.textBoxFirstName_OnInitialized}
                                                >
                                                    <Validator>
                                                        <ValidationRule type="required" message="Required" />
                                                    </Validator>
                                                </TextBox>
                                            </div>
                                        </div>
                                        <div className="psareac-dx-field">
                                            <div className="dx-field-label psareac-top-label">Last Name*</div>
                                            <div className="dx-field-value psareac-top-input">
                                                <TextBox
                                                    width='250px'
                                                    onFocusIn={this.textBox_OnFocusIn}
                                                    onInitialized={this.textBoxLastName_OnInitialized}
                                                >
                                                    <Validator>
                                                        <ValidationRule type="required" message="Required" />
                                                    </Validator>
                                                </TextBox>
                                            </div>
                                        </div>
                                        <div className="psareac-dx-field">
                                            <div className="dx-field-label psareac-top-label">Email</div>
                                            <div className="dx-field-value psareac-top-input">
                                                <TextBox
                                                    width='250px'
                                                    onFocusIn={this.textBox_OnFocusIn}
                                                    onInitialized={this.textBoxEmail1_OnInitialized}
                                                >
                                                    <Validator>
                                                        <EmailRule
                                                            message="Invalid email" />
                                                    </Validator>
                                                </TextBox>
                                            </div>
                                        </div>
                                        <div className="psareac-dx-field">
                                            <div className="dx-field-label psareac-top-label">Tel</div>
                                            <div className="dx-field-value psareac-top-input">
                                                <TextBox
                                                    width='250px'
                                                    onFocusIn={this.textBox_OnFocusIn}
                                                    onInitialized={this.textBoxTel1_OnInitialized}
                                                >
                                                </TextBox>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="psareac-top-right-div">
                                        <div className="psareac-dx-field-notes">
                                            <div className="dx-field-value psareac-top-input-notes">
                                                <HtmlEditor
                                                    width='100%'
                                                    height='158px'
                                                    onInitialized={this.htmlEditorNotes_OnInitialized}
                                                >
                                                </HtmlEditor>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="psareac-second-div">
                                <div className="psareac-second-left-div">
                                    <div className="psareac-header-div">Artists</div>
                                    <div className="dx-card psareac-second-left-card">
                                        <DataGrid
                                            dataSource={this.state.artistRelationsDataSource}
                                            key='Id'
                                            height='100%'
                                            allowColumnReordering={false}
                                            allowColumnResizing={true}
                                            allowGrouping={false}
                                            columnAutoWidth={false}
                                            columnResizingMode='widget'
                                            noDataText='No artists found.'
                                            remoteOperations={false}
                                            repaintChangesOnly={true}
                                            showBorders={true}
                                            showColumnLines={true}
                                            showRowLines={true}
                                            onInitialized={this.dataGrid_OnInitialized}
                                            onCellClick={this.dataGrid_OnCellClick}
                                            onCellPrepared={this.dataGrid_OnCellPrepared}
                                            onEditorPreparing={this.dataGrid_OnEditorPreparing}
                                        >
                                            <Scrolling
                                                mode="standard"
                                                preloadEnabled={true}
                                                scrollByContent={true}
                                                scrollByThumb={true}
                                                showScrollbar='always'
                                                useNative='false'
                                            />

                                            <Editing
                                                mode="cell"
                                                allowUpdating={true}
                                                allowAdding={false}
                                                allowDeleting={false} />
                                            <Column dataField="RelationId" dataType="number" visible={false} />
                                            <Column
                                                allowSorting={false}
                                                allowResizing={false}
                                                dataField="ArtistId"
                                                dataType="number"
                                                caption="Artist"
                                            >
                                                <Lookup
                                                    dataSource={this.artistsDataSource}
                                                    displayExpr="Name"
                                                    valueExpr="Id"
                                                />
                                            </Column>
                                            <Column
                                                allowSorting={false}
                                                allowResizing={false}
                                                dataField="FunctionId"
                                                dataType="number"
                                                caption="Function"
                                            >
                                                <Lookup
                                                    dataSource={this.artistFunctionsDataSource}
                                                    displayExpr="Name"
                                                    valueExpr="Id"
                                                />
                                            </Column>
                                            <Column allowSorting={false} allowResizing={false} dataField="EmailTo" dataType="boolean" width={75} />
                                            <Column allowSorting={false} allowResizing={false} dataField="EmailCC" dataType="boolean" width={75} />
                                            <Column
                                                name='ActionColumnDelete'
                                                caption=''
                                                allowFiltering={false}
                                                allowEditing={false}
                                                allowColumnResizing={false}
                                                allowColumnReordering={false}
                                                allowExporting={false}
                                                allowSorting={false}
                                                fixed={false}
                                                showInColumnChooser={false}
                                                visible={true}
                                                width='40px'
                                                alignment='center'
                                                cellRender={this.renderDeleteTemplate}
                                            />
                                        </DataGrid>
                                    </div>
                                </div>

                                <div className="psareac-second-right-div">
                                    <div className="psareac-header-div">Add artist</div>
                                    <div className="dx-card psareac-second-right-card">
                                        <div className="psareac-dx-field">
                                            <div className="dx-field-label psareac-second-label">Artist*</div>
                                            <div className="dx-field-value psareac-second-input">
                                                <SelectBox
                                                    dataSource={this.artistsDataSource}
                                                    valueExpr="Id"
                                                    displayExpr="Name"
                                                    dropDownOptions={{ maxHeight: '200px' }}
                                                    minSearchLength={0}
                                                    searchEnabled={true}
                                                    showClearButton={true}
                                                    showDataBeforeSearch={false}
                                                    width={250}
                                                    onInitialized={this.selectBoxArtist_OnInitialized}
                                                    onSelectionChanged={this.selectBoxArtist_OnSelectionChanged }
                                                >
                                                </SelectBox>
                                            </div>
                                        </div>
                                        <div className="psareac-dx-field">
                                            <div className="dx-field-label psareac-second-label">Function*</div>
                                            <div className="dx-field-value psareac-second-input">
                                                <SelectBox
                                                    dataSource={this.artistFunctionsDataSource}
                                                    valueExpr="Id"
                                                    displayExpr="Name"
                                                    dropDownOptions={{ maxHeight: '200px' }}
                                                    minSearchLength={0}
                                                    searchEnabled={true}
                                                    showClearButton={false}
                                                    showDataBeforeSearch={false}
                                                    width={250}
                                                    onInitialized={this.selectBoxArtistFunction_OnInitialized}
                                                    onSelectionChanged={this.selectBoxArtistFunction_OnSelectionChanged }
                                                >
                                                </SelectBox>
                                            </div>
                                        </div>
                                        <div className="psareac-dx-field">
                                            <div className="dx-field-label psareac-second-label">Email</div>
                                            <div className="dx-field-value psareac-second-input">
                                                <RadioGroup
                                                    height={32}
                                                    onInitialized={this.radioGroupEmail_OnInitialized}
                                                    items={['EmailTo', 'EmailCC', 'N/A']}
                                                    defaultValue={'N/A'}
                                                    layout="horizontal" />
                                            </div>
                                        </div>
                                        <div className="psareac-dx-field">
                                            <div className="dx-field-label psareac-second-label"></div>
                                            <div className="dx-field-value psareac-second-input psareac-second-button">
                                                <Button
                                                    onInitialized={this.buttonAddArtistRelation_OnInitialized}
                                                    text="Add"
                                                    onClick={this.buttonAddArtistRelation_OnClick}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                             </div>
                        </div>
                    </ValidationGroup>


                    <div className="psareac-buttons">
                        <div className="psareac-buttons-checkbox">
                            <CheckBox
                                text="Keep open"
                                onInitialized={this.checkBoxKeepOpen_OnInitialized}
                                onValueChanged={this.checkBoxKeepOpen_OnValueChanged}
                            />
                        </div>
                        <div className='psareac-buttons-update'>
                            <div className="psareac-button-update">
                                <Button
                                    onInitialized={this.buttonUpdate_OnInitialized}
                                    icon="save"
                                    onClick={this.buttonUpdate_OnClick}
                                />
                            </div>
                        </div>
                    </div>
                </Popup>
            </React.Fragment>
        );
    }

}

export default PopupShuttlesAdminRelationsEditArtistContact

